import { fetcher } from '@/helpers/fetcher'
import fetch from 'isomorphic-fetch'
import { getCookie } from '../auth'

var API = process.env.API


export const getRequestTemplate = () => {
    return fetch(`${API}/requestsaletemplate`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const getRequestTemplateList = () => {
    return fetch(`${API}/requestsaletemplate/all`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}



export const getRequestTemplateById = (templateid: string) => {
    return fetch(`${API}/requestsaletemplate/${templateid}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const getRequestTemplateAll = () => {
    return fetch(`${API}/requestsaletemplate/all`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getUsersForMod = () => {
    return fetch(`${API}/crm/requestsale/users-mod`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const getRequestSaleList = (contactid) => {
    return fetch(`${API}/crm/requestsale/${contactid}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const getOneRequestSale = (contactid, requestsaleid, isShare = false) => {
    return fetch(`${API}/crm/requestsale/${contactid}/${requestsaleid}?isShare=${isShare}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const saveRequestSale = (contactid, templateid, status, body, agent, forceStatus, lineValues, shared, isShared) => {
    let extra_options: any = {}
    if (agent) {
        extra_options.agent = agent._id
    }
    return fetch(`${API}/crm/requestsale/${contactid}/${templateid}?isShare=${isShared}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        },
        body: JSON.stringify({
            status, body, forceStatus, lineValues,
            shared,
            ...extra_options
        })
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const commentRequestSale = (contactid, presaleid, comment) => {
    return fetch(`${API}/crm/requestsale/${contactid}/${presaleid}/comment`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        },
        body: JSON.stringify({ comment })
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const watchRequestSale = (contactid, presaleid) => {
    return fetch(`${API}/crm/requestsale/${contactid}/${presaleid}/watched-comercial`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        },
        body: JSON.stringify({})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const modifyRequestSale = (contactid, requestsaleid, status, body, agent, forceStatus = undefined, lineValues, shared, isShared) => {
    let extra_options: any = {}
    if (agent) {
        extra_options.agent = agent._id
    }
    return fetch(`${API}/crm/requestsale/${contactid}/${requestsaleid}?isShare=${isShared}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        },
        body: JSON.stringify({ status, body, forceStatus, lineValues, ...extra_options, shared })
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const updateStatusRequestSale = (requestsaleid, status) => {
    return fetch(`${API}/crm/backoffice/requestsale/${requestsaleid}/status`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        },
        body: JSON.stringify({ status })
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const updateCreatorRequestSale = (requestsaleid, user) => {
    return fetch(`${API}/crm/backoffice/requestsale/${requestsaleid}/creator`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        },
        body: JSON.stringify({ user })
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const updateAuditRequestSale = (requestsaleid) => {
    return fetch(`${API}/crm/backoffice/requestsale/${requestsaleid}/audit`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        },
        body: JSON.stringify({})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const updateResponsibleRequestSale = (requestsaleid, user) => {
    return fetcher<{ result: any }>({
        url: `/crm/backoffice/requestsale/${requestsaleid}/auditor`,
        method: 'PATCH',
        body: { user },
        defaultContentType: true,
        throwError: false
    })

}

export const changeStatus = (id: string, status: string, statusType: string) => {
    return fetcher<{ success: boolean }>({
        url: `/requestsale/${id}/changestatus?status=${status}&statusType=${statusType}`,
        defaultContentType: true,
        throwError: false,
        method: 'PUT'
    })
}

export const deleteRequestSaleById = (id: string) => {
    return fetcher<{ success: boolean }>({
        url: `/requestsale/${id}`,
        defaultContentType: true,
        throwError: false,
        method: 'DELETE'
    })
}