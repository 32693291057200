'use client'
import react, { JSXElementConstructor, ReactElement, useEffect, useState } from 'react'
import { authenticate, isAuth, reloadtoken, setCookie } from '../actions/auth'
import { NextRouter, useRouter, withRouter } from 'next/router'
import Loading from './core/Loading'
import _ from 'lodash'

interface PrivateInteface {
    roles?: string[],
    children: any
}

const Private = ({ children, roles }: PrivateInteface) => {
    var [show, setShow] = useState(false)
    var [hasMounted, setHasMounted] = useState(false)

    const router = useRouter()

    useEffect(() => {
        let user = isAuth()

        if (user) {
            setShow(true)
        }
        setHasMounted(true)
    }, [])
    if (hasMounted && !isAuth()) {

        if (!isAuth() && router.query.token && process.browser) {
            setCookie('token', router.query.token)
            setCookie('onApp', 'true')
            reloadtoken().then((res) => {
                if (!res || res.error) return router.replace(`/login?returnurl=${router.asPath}`)
                let newQuery = { ...router.query }
                if (newQuery.token) delete newQuery.token
                authenticate(res, async () => {
                    await router.push({
                        pathname: router.pathname,
                        query: newQuery
                    });
                    router.reload()
                })
            })
            return <Loading />
        } else {
            router.replace({
                pathname: '/login',
                query: {
                    returnurl: router.asPath
                }
            })
            return <Loading />
        }
    }

    if (isAuth() && router.query.token && process.browser) {
        setCookie('token', router.query.token)
        setCookie('onApp', 'true')
        reloadtoken().then((res) => {
            if (!res || res.error) return router.replace(`/login?returnurl=${router.asPath}`)
            let newQuery = { ...router.query }
            if (newQuery.token) delete newQuery.token
            authenticate(res, async () => {
                await router.push({
                    pathname: router.pathname,
                    query: newQuery
                });
                router.reload()
            })
        })
        return <Loading />
    }
    let jailUrl = _.get(isAuth(), 'jailUrl', [])

    if (jailUrl.length > 0) {
        let valid_jail = false
        let path_name = router.pathname.split('/')
        jailUrl.map((url_jail) => {
            let urlNew = url_jail.split('/')
            if (urlNew.length > path_name.length) return ''
            let valid_individiual = true
            urlNew.map((segment, index_segment) => {
                if (segment != path_name[index_segment]) valid_individiual = false
            })
            if (valid_individiual) valid_jail = true
        })
        if (!valid_jail) {
            router.push(jailUrl[0] ?? '/')
            return <Loading />
        }
    }

    if (hasMounted && isAuth() && roles && roles[0] != 'all' && roles.filter((role) => isAuth().roles.indexOf(role) >= 0).length == 0) {
        router.push('/').then(() => {
            router.reload()
        })
        return <Loading />
    }

    if (hasMounted && isAuth() && roles && roles[0] != 'all' && !roles.some((role) => isAuth().roles.includes(role))) {
        router.push('/').then(() => {
            router.reload()
        })
        return <Loading />
    }

    return show ? children : ''
}


export default Private

