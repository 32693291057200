export default function CompanyDescription({
    title,
    description
}: {
    title: string,
    description: null | undefined | string
}) {
    if (!description) return null
    
    return (
        <p className="text-gray-800 text-[14px]">
            <b className="font-medium">{title}</b> {description}
        </p>
    )
}
