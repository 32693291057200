import { AdditionalCompanyField } from "@/interfaces/Interface";
import dayjs from "dayjs";
import { useMemo } from "react";
import CompanyDescription from "./company-description";
import CompanyGroupField from "./company-group-field";
import ContactField from "./contact-field";

interface AdditionalFieldProps {
    field: AdditionalCompanyField,
    value: any
}

export default function AdditionalField({
    field,
    value
}: AdditionalFieldProps) {
    const { type, name, code } = field || {};

    const valueUse = useMemo(() => {
        if (type == 'date') return dayjs(value).format('DD/MM/YYYY HH:mm')
        if (type == 'multiselect') return [value].flat().join(', ')
        return value
    }, [value])

    const label = `${name}:`
    const defaultProps = { code, title: label, values: valueUse }

    switch (type) {
        case 'string':
        case 'select':
        case 'date':
        case 'multiselect':
        case 'number': return (
            <CompanyDescription
                title={label}
                description={valueUse}
            />
        )
        case 'selectcontact':
        case 'multiplecontact': return (
            <ContactField {...defaultProps} />
        )
        case 'multiplecompany':
        case 'selectcompany': return (
            <CompanyGroupField {...defaultProps} />
        )
        default: return null;
    }
}