import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select";
import _ from "lodash";
import { useMemo } from "react";
import currenciesJson from './currencies.json';

const currencies = currenciesJson.currencies.sort((a, b) => a.title.localeCompare(b.title));
export const currenciesToMap = _.keyBy(currenciesJson.currencies, 'code');

interface CurrencySelectProps {
    value: string | undefined,
    onChangeValue: (value: string) => void,
    countryEnabled?: string[]
}

export default function CurrencySelect({
    onChangeValue,
    value,
    countryEnabled = []
}: CurrencySelectProps) {

    const currencieUse = useMemo(() => {
        if (countryEnabled.length == 0) return currencies;
        const discriminateToSet = new Set(countryEnabled.map((country) => country.toUpperCase()));
        return currencies.filter(currency => discriminateToSet.has(currency.country));
    }, [countryEnabled, currencies]);

    return (
        <Select onValueChange={onChangeValue} value={value}>
            <SelectTrigger>
                <SelectValue placeholder="Seleccionar..." />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {
                        currencieUse.map(({ code, title, symbol }) => (
                            <SelectItem value={code} key={code}>
                                {title} ({symbol})
                            </SelectItem>
                        ))
                    }
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}
