import React, { Suspense, useMemo, useState } from 'react'
import DetailAccordionItem from './detail-accordion-item'
import { IShortcut } from '../BottomActions/SelectAction'
import { Separator } from '@/components/ui/separator'
import _ from 'lodash'
import { getCompany, isAuth } from '@/actions/auth'
import { Layers } from 'lucide-react'
import { toast } from '@/components/ui/use-toast'
import { DialogSeeShortcut } from './DialogSeeShortcut'
const IconByName = React.lazy(() => import('../../../components/company/shortcuts/RenderIconByName'))

const replaceVariables = (template, data) => {
    try {
        _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
        const newUrl = _.template(template);
        return newUrl(data);
    } catch (e) {
        return false
    }
}

export default function ShortcutsItem({
    prospect
}) {
    const [openDialog, setOpenDialog] = useState<null | string>(null)
    const groupShortcuts = useMemo(() => _.get(getCompany(), "shortcuts", []), [])
    const user = useMemo(() => isAuth(), [])

    const handleOnClickShortcut = (shortcut: IShortcut) => {
        if (shortcut.redirectionType === "internal") {
            const newUrl = replaceVariables(shortcut.url, { contact: prospect, user: user, group: user.group })
            if (newUrl) {
                setOpenDialog(newUrl)
            } else {
                toast({
                    title: 'Error',
                    description: 'La url no es valida, por favor revisa la configuración del acceso directo.',
                    duration: 2000
                })
            }
        } else if (shortcut.redirectionType === "external") {
            const newUrl = replaceVariables(shortcut.url, { contact: prospect, user: user, group: user.group })
            if (newUrl) {
                window.open(newUrl, "_blank");
            } else {
                toast({
                    title: 'Error',
                    description: 'La url no es valida, por favor revisa la configuración del acceso directo.',
                    duration: 2000
                })
            }
        } else {
            window.alert("Url no especificada");
        }
    }

    return groupShortcuts && groupShortcuts.length > 0
        ? <>
            <DialogSeeShortcut open={openDialog} onClose={() => setOpenDialog(null)} />
            <DetailAccordionItem label="Accesos Directos" value="value-1">
                <div className="space-y-2">
                    {groupShortcuts.map((shortcut: IShortcut, index) => (
                        <>
                            <div key={index} className="flex items-center gap-2 cursor-pointer p-1 hover:bg-slate-100 rounded" onClick={() => handleOnClickShortcut(shortcut)}>
                                {shortcut.avatar?.type === 'link' ? (
                                    <img alt="Logo atajo" src={shortcut.avatar?.url} className="rounded-full w-7 h-7 border" />
                                ) : shortcut.avatar?.type === 'icon' ? (
                                    <Suspense fallback={<span className="rounded-full h-6 w-6 animate-pulse bg-gray-100" />}>
                                        <IconByName name={shortcut.avatar.url} />
                                    </Suspense>
                                ) : (
                                    <Layers size={20} />
                                )}
                                <div className="flex flex-col">
                                    <p className="font-medium">{shortcut.title}</p>
                                    <p className="text-[10px]">{shortcut.redirectionType === 'internal' ? 'Redirección interna' : 'Redirección externa'}</p>
                                </div>
                            </div>
                            {index !== groupShortcuts.length - 1 && <Separator orientation="horizontal" key={index} />}
                        </>
                    ))}
                </div >
            </DetailAccordionItem>
        </>
        : null

}
