import { Avatar } from "@/components/ui/avatar";
import { cn } from "@/lib/utils";

export function ItemAgentForColumns({ agent, className, hiddenWithOutAgent = false }: { agent: any, hiddenWithOutAgent?: boolean, className?: string }) {
    const { name, lastname, photo } = agent || {}

    if (hiddenWithOutAgent && !agent) return null;

    return <div className="flex items-center text gap-2">
        {
            !agent
                ? 'Sin agente'
                : <>
                    <Avatar className={cn('bg-primary  font-medium flex items-center justify-center text-[10px] text-white w-[20px] h-[20px]', className)}>
                        {
                            photo
                                ? <img src={photo} className="w-full h-full object-cover" />
                                : `${name?.[0] || ''}${lastname?.[0] || ''}`
                        }
                    </Avatar>
                    {name} {lastname}
                </>
        }
    </div>
}