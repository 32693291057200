import { isAuth } from "@/actions/auth";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import useGetCurrentUser from "@/hooks/useGetCurrentUser";
import { setUserToGlobalState } from "@/redux/slices/config";
import { zodResolver } from "@hookform/resolvers/zod";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ChangePasswordSchema, changePasswordSchema, defaultValuesChangePasswordSchema } from "../libs/constants";
import FormChangePassword from "./form-change-password";
import PasswordLog, { ChangePasswordTitle } from "./password-log";

export default function ChangePassworld() {
    const user = useGetCurrentUser() || isAuth()
    const [isValidPassword, setIsValid] = useState(false)

    const dispatch = useDispatch()

    const contextForm = useForm<ChangePasswordSchema>({
        resolver: zodResolver(changePasswordSchema),
        defaultValues: defaultValuesChangePasswordSchema,
    })

    const handleChangeUserValue = (value) => dispatch(setUserToGlobalState(value))

    if (!_.get(user, 'forcePasswordChange', false)) return null
    return (
        <Dialog open={true}>
            <DialogContent overlayType={'transparent'} showCloseButton={false} className="bg-white md:w-[80%] p-[20px] lg:p-[50px] w-full md:max-w-[1800px] flex rounded-[18px] shadow-sm h-full md:max-h-[600px] md:h-auto md:py-[60px]">
                <div className="grid w-full grid-cols-5">
                    <div className="lg:flex hidden flex-col pr-10 lg:col-span-[0] lg:col-span-3 gap-[58px] w-full justify-center items-start">
                        <PasswordLog
                            contextForm={contextForm}
                            handleValidPassword={(value) => setIsValid(value)}
                        />
                    </div>
                    <div className="flex flex-col lg:col-span-2 col-span-5 sm:justify-center items-center">
                        <ChangePasswordTitle className="lg:hidden mb-6" />
                        <FormChangePassword
                            handleValidPassword={(value) => setIsValid(value)}
                            contextForm={contextForm}
                            user={user}
                            handleChangeUser={(value) => handleChangeUserValue(value)}
                            isValidPassword={isValidPassword}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

