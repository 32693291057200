import { PhoneMissed } from "@/components/crm/Bubble/Icons/Crm"
import { Archive } from "@/components/crm/Bubble/Icons/Sidebar"
import { DialogTitle } from "@/components/ui/dialog"
import { CustomReminder } from "@/interfaces/Interface"
import { CalendarDays } from "lucide-react"
import { z } from "zod"
import { CardDescription } from "@/components/ui/card"
import { VisitIcon } from "@/icons/AppsToreIcons"

export const formSchema = z.object({
    action: z
        .object({
            code: z.string().optional(),
            label: z.string().optional(),
        })
        .optional(),
    comment: z.string().optional(),
    reminderDate: z
        .object({
            date: z.any(),
            type: z.string(),
        })
        .required(),
})

export const defaultValues = {
    action: {
        code: "",
        label: "",
    },
    comment: "",
    reminderDate: {
        date: "",
        type: "",
    },
}

export type TReminderData = z.infer<typeof formSchema>

export const retryScheduleValues = (): CustomReminder => {
    return {
        activeGoBack: false,
        bgColor: "#FEF2F2",
        icon: <PhoneMissed className="w-[14px] h-[14px]" />,
        label: "Llamada no contestada",
        step: 1,
        textColor: "#EF4444",
        title: <>
            <DialogTitle className="flex items-center [&>svg]:w-[18px] [&>svg]:h-[18px] gap-[6px] font-[500] text-[16px] text-ellipsis">
                <CalendarDays />
                <span>
                    Agendar reintento de
                    <span className="font-bold"> Llamada</span>
                </span>
            </DialogTitle>
        </>,
        type: "phoneCall",
    }
}

export const reminderRecontactValues = ({ label }): CustomReminder => {
    return {
        activeGoBack: false,
        bgColor: "#FEF2F2",
        icon: <Archive className="text-red-500 w-[14px] h-[14px]" />,
        label: label || 'Recordar contacto',
        step: 1,
        textColor: "#EF4444",
        title: <>
            <DialogTitle className="flex items-center [&>svg]:w-[18px] [&>svg]:h-[18px] gap-[6px] font-[500] text-[16px] text-ellipsis">
                <CalendarDays />
                Agendar llamada de recontacto
            </DialogTitle>
        </>,
        type: "phoneCall",
    }
}

export const visitValues = ({ description }): CustomReminder => {
    return {
        activeGoBack: false,
        bgColor: "#F0F9FF",
        icon: <VisitIcon height={'20px'} width={'20px'} />,
        label: "Visita",
        step: 1,
        textColor: "",
        title: <div>
            <DialogTitle className="flex items-center [&>svg]:w-[18px] [&>svg]:h-[18px] gap-[6px] font-[500] text-[16px] text-ellipsis">
                <CalendarDays />
                <span>
                    Agendar recordatorio:
                    <span className="font-bold"> Visita</span>
                </span>
            </DialogTitle>
            <CardDescription>
                {description}
            </CardDescription>
        </div>,
        type: "visit",
    }
}