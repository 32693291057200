import MenuTraining from '@/components/Menues/MenuTraining'
import NotData from '@/components/Svgs/NotData'
import Loading from '@/components/core/Loading'
import ListOfPost, { NotContent } from '@/components/instance/ListOfPost'
import CarrouselOfClassVideo from '@/components/training/CarrouselOfClassVideo'
import { Avatar } from '@/components/ui/avatar'
import { CardTitle } from '@/components/ui/card'
import useGetInstance from '@/hooks/useGetInstance'
import { cn } from '@/lib/utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

enum LIMITS_OF_DOCUMENTS {
    POST = 2,
    INSTANCES = 4
}
const initialQuery = { page: 1, limit: LIMITS_OF_DOCUMENTS.INSTANCES, lastInteraction: true }
type TStateQuery = {
    page: number,
    limit: number,
    lastInteraction?: boolean
}

export default function Training() {
    const [queryInstance, setQueryInstance] = useState<TStateQuery>({ ...initialQuery })
    const [queryTutorials, setQueryTutorials] = useState<TStateQuery>({ ...initialQuery })
    const [lengthOfPosts, setLengthOfPost] = useState(0)
    const manager = useSelector((state: any) => state.header.manager)

    const {
        refetch,
        isLoading,
        items: instances } = useGetInstance({
            queryKey: ['get-lastInteraction-instances'],
            returnNull: true,
            enabledQuery: true,
            ...queryInstance
        })

    const {
        refetch: refetchTutorial,
        isLoading: isLoadingTutorial,
        items: tutorials } = useGetInstance({
            queryKey: ['get-lastInteraction-tutorials'],
            type: 'tutorial',
            returnNull: true,
            enabledQuery: true,
            ...queryTutorials
        })

    useEffect(() => {
        if (tutorials
            && tutorials.length === 0
            && Object.keys(queryTutorials).includes('lastInteraction')) {
            const { lastInteraction, ...newQuery } = queryTutorials;
            setQueryTutorials(newQuery)
        }
    }, [tutorials])

    useEffect(() => {
        if (instances
            && instances.length === 0
            && Object.keys(queryInstance).includes('lastInteraction')) {
            const { lastInteraction, ...newQuery } = queryInstance;
            setQueryInstance(newQuery)
        }
    }, [instances])

    useEffect(() => {
        if (!Object.keys(queryInstance).includes('lastInteraction')) refetch()
        if (!Object.keys(queryTutorials).includes('lastInteraction')) refetchTutorial()
    }, [queryInstance, queryTutorials])

    return <MenuTraining slug={'training'}>
        {
            (isLoading || isLoadingTutorial)
                ? <Loading />
                : <section className='flex flex-grow pb-4  flex-col'>
                    {
                        !isLoadingTutorial && !isLoading && (!tutorials || tutorials?.length === 0)
                        && (!instances || instances?.length === 0)
                        && <NotContent label={!manager ? 'Tu empresa no tiene un entrenador asignado' : 'Tu entrenador aún no ha compartido contenido.'} />
                    }
                    <div className='pt-5 flex flex-col gap-10'>
                        <article className='md:px-8 flex gap-16 flex-col h-full px-4'>
                            <div>
                                {
                                    (Object.keys(queryInstance).includes('lastInteraction')
                                        || Object.keys(queryTutorials).includes('lastInteraction'))
                                        ? manager && ((instances && instances.length > 0) || (tutorials && tutorials?.length > 0)) && <TitleCoach title={'Continuar viendo'} />
                                        : null
                                }
                                <section className='flex flex-col gap-10'>
                                    {
                                        tutorials && tutorials.length > 0 && <CarrouselOfClassVideo
                                            videos={tutorials}
                                            type='tutorials'
                                            hrefViewMore={'/training/tutorials'}
                                            title={'Tutoriales'} />
                                    }
                                    {
                                        instances && instances.length > 0 && <CarrouselOfClassVideo
                                            videos={instances}
                                            type='instances'
                                            hrefViewMore={'/training/instances'}
                                            title={'Clases grabadas'} />
                                    }
                                </section>
                            </div>
                            <ListOfPost
                                coach={manager}
                                queryKey={['list-all-post']}
                                limitOfPost={5}
                                setLengthOfPost={(value) => setLengthOfPost(value)} />
                        </article>
                    </div>
                </section>
        }
    </MenuTraining>
}

export function TitleCoach({ title, className = '' }) {
    return <CardTitle className={cn('text-slate-800 text-xl mb-4', className)}>
        {title}
    </CardTitle>
}

export function AvatarUser({ label, className = '', img, children }: any) {
    return <div className='relative'>
        <Avatar className={cn('bg-primary z-[2] font-medium flex items-center justify-center text-[16px] text-white md:w-[39px] md:h-[39px] w-[30px] h-[30px]', className)}>
            {img ? <img src={img} /> : label}

        </Avatar>
        {children}
    </div>
}

export function AlertNotData() {
    return <div className='flex flex-col mt-10  w-full items-center justify-center gap-4'>
        {/* <NotData /> */}
        <CardTitle className='text-sm'>
            Tu entrenador aún no ha compartido contenido.
        </CardTitle>
    </div>
}