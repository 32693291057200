import AudioPlayer from "./AudioPlayer"

export default function AudioInteraction({
    media,
    children,
    listened = false
}) {
    return (
        <>
            <div className={`justify-center p-2 flex md:w-[var(--width-bubble-media-type)] flex-col flex-grow`}>
                <AudioPlayer
                    listened={listened}
                    url={media as string}
                    interaction={true}
                />
                {children}
            </div>
        </>
    )
}
