import { defaultVariablesOptions } from "@/components/company/shortcuts/VariablesMenu"
import { z } from "zod"

export const SchemaEditReminder = z.object({
    medium: z.enum(['whatsApp', 'email']),
    messageContent: z.object({ message: z.string().optional() })
})

export type SchemaEditReminderType = z.infer<typeof SchemaEditReminder>

export const defaultValues = {
    messageContent: { message: '' }
}

export const variablesOptions = [...defaultVariablesOptions, {
    label: 'Reunión',
    variables: [
        //     {
        //     code: 'meeting.elapsedTime',
        //     name: 'Tiempo restante'
        // }, 
        {
            code: 'meeting.currentDate',
            name: 'Fecha de la reunión (dddd)'
        }, {
            code: 'meeting.meetingHour',
            name: 'Horario de la reunión (HH:mm)'
        }, {
            code: 'meeting.medium',
            name: 'Medio'
        }]
}]
