import { Grid, Typography } from "@mui/material"
import _ from "lodash"
import { isAuth } from "../../../actions/auth"


let maxText = (txt:string) => {
    if(txt.length > 12){
        return txt.slice(0, 12)+"..."
    }else{
        return txt
    }
}

const ActionAgentText = ({agent, actionName}) => {
    let user = isAuth()
    
    if(user && agent && _.get(user, '_id', '') !== _.get(agent, '_id', '') && typeof agent !== 'string' ) {
        return (
            <Grid container item sx={{width: { sm: "60%", xs: "100%"}, minWidth: "300px",}} direction="row" justifyContent={"right"} alignItems="center" mt={0.25}>
                    <Typography fontWeight={400} fontSize={"12px"} color={"gray"} mr={'4px'} mt={"1px"}>{actionName} por </Typography> 
                    <Typography fontWeight={600} fontSize={"12px"} color={"gray"} mt={"1px"}>{maxText(`${agent.name} ${agent.lastname}`)} </Typography> 
            </Grid>
        )
    } 
    return <></>
}

export default ActionAgentText