import React from 'react';
import Linkify from 'react-linkify';
import sanitizeHtml, { IOptions } from 'sanitize-html';
import { splitTextIntoLines } from '@/helpers';

export const formatMessage = (message: string) => {
  const boldRegex = /\*(.+?)\*/g; 
  const italicRegex = /_(.+?)_/g;
  const strikethroughRegex = /~(.+?)~/g;
  const monospaceRegex = /`(.+?)`/g;
  const underlineRegex = /__(.+?)__/g;

  return message
    .replace(boldRegex, '<b>$1</b>')
    .replace(underlineRegex, '<u>$1</u>')
    .replace(italicRegex, '<i>$1</i>')
    .replace(strikethroughRegex, '<s>$1</s>')
    .replace(monospaceRegex, '<code>$1</code>');
};

export const sanitizeTextOptions: IOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'u', 'a', 'code', 's'],
  allowedAttributes: {
    'a': ['href', 'target', 'rel'],
  },
  allowedSchemes: ['http', 'https', 'mailto'],
};

const StyledLink = ({ href, children }) => (
  <a href={href} className="text-blue-500 hover:text-blue-700 transition duration-150 ease-in-out" target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const LinkStyler = (decoratedHref: string, decoratedText: string, key: string | number) => {
  if (decoratedHref.startsWith('mailto:')) {
    const email = decoratedHref.replace('mailto:', '');
    return (
      <StyledLink href={decoratedHref} key={key}>
        {email}
      </StyledLink>
    );
  }

  return (
    <StyledLink href={decoratedHref} key={key}>
      {decoratedText}
    </StyledLink>
  );
};

const StyledLinkifyer = ({ children }: { children: string }) => {
  const processedMessage = formatMessage(children);
  const sanitizedMessage = sanitizeHtml(processedMessage, sanitizeTextOptions);

  return (
    <Linkify componentDecorator={LinkStyler}>
      <span>{parseHtmlAndRenderText(sanitizedMessage)}</span>
    </Linkify>
  );
};

const parseHtmlAndRenderText = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  return Array.from(doc.body.childNodes).map((node, index) => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent; 
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const { tagName, attributes, innerHTML } = node as HTMLElement;
      const props = Array.from(attributes).reduce((acc, attr) => {
        acc[attr.name] = attr.value;
        return acc;
      }, {});
      return React.createElement(tagName.toLowerCase(), { ...props, key: index }, parseHtmlAndRenderText(innerHTML));
    }
    return null;
  });
};

export function StyledLinkifyerLines({ text }: { text: string }) {
  const splittedText: string[] = splitTextIntoLines(text);

  return (
    <>
      {splittedText.map((line, inx) => (
        <React.Fragment key={inx}>
          <StyledLinkifyer>{line}</StyledLinkifyer>
          {inx !== splittedText.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
}

export default StyledLinkifyer;
