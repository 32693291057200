import { activeStatusTags, getStatusTagColor } from '@/helpers/tagColorToPalette'
import { StatusTag } from '@/interfaces/Interface'

import { useState } from 'react'


export default function useGetStatusTag({ code }: { code?: string }) {
    const [tags, setTags] = useState<StatusTag[]>(activeStatusTags)

    const find: StatusTag | undefined = tags.find(tag => tag.code === code)
    const color = getStatusTagColor(find?.code || '', 'primary')
    
    const onChangeStatusTags = (tags: StatusTag[]) => setTags(tags)

    return {
        tags,
        find,
        onChangeStatusTags,
        color
    }
}
