import { cn } from '@/lib/utils';
import * as LucideIcons from 'lucide-react';
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';

type IconComponent = React.FC<React.SVGProps<SVGSVGElement>> | null;

const getIconComponent = (name: string): IconComponent => {
    if (LucideIcons[name]) return LucideIcons[name];
    return null;
};

interface RenderIconByNameProps {
    name: string,
    className?: string
}

const RenderIconByName = ({ name, className }: RenderIconByNameProps) => {
    const IconComponent = getIconComponent(name);
    if (!IconComponent) return null;
    return <IconComponent className={cn(className)} />
};

RenderIconByName.propTypes = {
    name: PropTypes.string.isRequired,
};

export default React.memo(RenderIconByName);
