const interationsToText = (clientName:string, conversations:any[]) => {
    return conversations.map((conv) => {
        const agentName = conv.agent ? `${conv.agent.name} ${conv.agent.lastname}` : "System";
        const content = conv.content.body || conv.content.message || "No message content";
        const type = conv.type || "unknown";
        const createdAt = new Date(conv.createdAt).toLocaleString();
        return `[${createdAt}] ${agentName} (${type}): ${content}`;
    }).join("\n");
}

export default interationsToText
