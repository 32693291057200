import QuestionTitle, { TitleQuestion } from "@/components/QuestionTitle"
import { ItemField } from "@/components/grupos/notification/DialogAddNewNotification"
import { DateTimePicker } from "@/components/ui/Time-picker/date-time-picker"
import { Card, CardDescription, CardTitle } from "@/components/ui/card"
import { FormField } from "@/components/ui/form"
import { DateItem } from "@/interfaces/Interface"
import { setReminderData } from "@/redux/slices/crm"
import dayjs, { Dayjs } from "dayjs"
import { CalendarDays, Clock4 } from "lucide-react"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { BottomSheetEditDate } from "./BottomSheetEditDate"
import ButtonReminderOption from "./ButtonReminderOption"
import { ManualDateButton } from "./ManualDateButton"
import { TReminderData } from "./constant"
import { defaultDates } from "./defaultValuesOfDate"
import { getCompany } from "@/actions/auth"
import { getTimezonesForCountry } from "countries-and-timezones"
import _ from "lodash"

dayjs.locale("es")

export enum TYPES_OF_SELECTION {
  MANUAL = "manual",
  PREDETERMINED = "predetermined",
  CALENDAR = "calendar",
}

export default function StepTwo({
  contextForm,
  disabledQuestion = false,
  handleCreateReminder,
  defaultValues = defaultDates,
  isLoading,
}: {
  contextForm: any,
  isLoading?: boolean
  disabledQuestion: boolean | null | undefined,
  defaultValues?: DateItem[]
  handleCreateReminder: (values: TReminderData) => Promise<boolean | void>
}) {
  const [optionsDate, setOptionsDate] = useState(defaultValues)
  const [isOpenEditDate, setEditDate] = useState<
    [Date, number, number] | null | any
  >(null)
  const [manualValue, setManualValue] = useState<null | Date>(null)
  const [calendarValue, setCalendarValue] = useState(null)

  const typeSelected = contextForm.watch("reminderDate.type")

  useEffect(() => {
    const reminderDate = contextForm.getValues("reminderDate")
    setCalendarValue(
      typeSelected === TYPES_OF_SELECTION.CALENDAR
        ? reminderDate.date
        : calendarValue
    )
  }, [typeSelected])

  const router = useRouter()

  const dispatch = useDispatch()
  const currentDate: Dayjs = useMemo(() => dayjs(), [])

  const handleSelectedDate = useCallback((date: Dayjs | Date, field: any, type) => {
    field.onChange({
      date: dayjs(date).toDate(),
      type,
    })
  }, [])

  const handleChangeDatePerDefault = useCallback(async (value) => {
    if (value) {
      const allValuesDate: any = [...optionsDate]
      const [pathOne, pathTwo]: any = value?.slice(1, value.length)
      const newDate = new Date(dayjs(value[0]) as any)
      allValuesDate[pathOne].options[pathTwo].date = newDate

      contextForm.setValue("reminderDate", {
        date: newDate,
        type: TYPES_OF_SELECTION.PREDETERMINED,
      })

      const isSuccess = await handleCreateReminder(contextForm.getValues())
      setOptionsDate(allValuesDate)

      if (isSuccess) setEditDate(null)
    }
  }, [optionsDate])

  const navigateToCalendar = useCallback(() => {
    dispatch(setReminderData(contextForm.getValues()))
    return router.push(`/contact/SELECTED_DATE/calendar`)
  }, [contextForm])

  const handleSelectedInCalendar = () => {
    if (
      !calendarValue ||
      contextForm.getValues("reminderDate").type === TYPES_OF_SELECTION.CALENDAR
    )
      return navigateToCalendar()

    contextForm.setValue("reminderDate", {
      date: calendarValue,
      type: TYPES_OF_SELECTION.CALENDAR,
    })
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-1 pb-4">
        {!disabledQuestion && <QuestionTitle question={"Selecciona un horario"} step={3} />}
        <FormField
          name="reminderDate"
          control={contextForm.control}
          render={({ field }) => (
            <ItemField>
              <div className="flex flex-col gap-7">
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                  <DateTimePicker
                    date={manualValue || new Date()}
                    setDate={(date) => {
                      handleSelectedDate(dayjs(date), field, TYPES_OF_SELECTION.MANUAL)
                      setManualValue(date)
                    }}
                    elementTigger={
                      <div className="w-full">
                        <ButtonReminderOption onAction={() => {
                          manualValue && handleSelectedDate(
                            manualValue,
                            field,
                            TYPES_OF_SELECTION.MANUAL
                          )
                        }}
                          className={`${field.value.type === TYPES_OF_SELECTION.MANUAL
                            ? "bg-primary hover:bg-primary  text-white hover:text-white"
                            : ""
                            }`
                          }
                        >
                          <CalendarDays />
                          {manualValue
                            ? dayjs(manualValue).format("DD/MM/YYYY HH:mm")
                            : "Asignar manualmente"}
                        </ButtonReminderOption>
                      </div>
                    }
                  />
                  <ButtonReminderOption
                    className={`${field.value.type === TYPES_OF_SELECTION.CALENDAR
                      ? "bg-primary hover:bg-primary  text-white hover:text-white"
                      : ""
                      }`}
                    onAction={() => handleSelectedInCalendar()}
                  >
                    <Clock4 />
                    {calendarValue
                      ? dayjs(calendarValue).format("DD/MM/YYYY HH:mm")
                      : " Asignar en calendario"}
                  </ButtonReminderOption>
                </div>
                <div className="flex flex-col gap-4">
                  <TitleQuestion>
                    Horarios predeterminados
                  </TitleQuestion>
                  <div className="flex flex-col gap-2">
                    {
                      optionsDate.map(({ isCurrentDay, options, title, format }, inxContainer) => (
                        <div className="flex items-center gap-2" key={title}>
                          <Card className="text-center h-[60px] flex flex-col justify-center items-center w-[50px] md:w-[70px] shrink-0 border-none shadow-none">
                            <CardTitle className="font-medium text-[15px] md:leading-[1.2] leading-[1]">
                              {title}
                            </CardTitle>
                            {
                              isCurrentDay && (
                                <CardDescription className="text-[10px]">
                                  {currentDate.format("DD/MM/YYYY")}
                                </CardDescription>
                              )
                            }
                          </Card>
                          <div
                            className="pl-2 grid  gap-2"
                            style={{ gridTemplateColumns: 'repeat(4,1fr)' }}>
                            {
                              options.map(({ date, ...rest }, inxOption) => (
                                <ManualDateButton
                                  format={format}
                                  key={rest.title}
                                  handleEditDate={(date: Date) => setEditDate([date, inxContainer, inxOption])}
                                  className={`${inxOption > 2 ? "sm:flex hidden" : ""}`}
                                  date={date}
                                  currentDate={currentDate}
                                  field={field}
                                  handleSelectedDate={handleSelectedDate}
                                  functionsToApply={rest.functionsToApply}
                                  title={rest.title}
                                  type={TYPES_OF_SELECTION.PREDETERMINED}
                                />
                              ))
                            }
                          </div>
                        </div>
                      )
                      )
                    }
                  </div>
                </div>
              </div>
            </ItemField>
          )}
        />
        <BottomSheetEditDate
          isLoadingBtn={isLoading}
          isOpen={isOpenEditDate}
          onChange={(value) => setEditDate(value)}
          onConfirmChange={handleChangeDatePerDefault}
        />
      </div>
    </div>
  )
}