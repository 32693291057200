import { CustomFormField } from "@/components/ui/custom-form-field"
import {
  FormLabel
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { getOriginsFiltered } from "@/helpers/crm"
import tagColorToPalette, { getFilteredStatusTags } from "@/helpers/tagColorToPalette"
import { Feature, isFeatureEnabled } from "../../../helpers/featureFlagHelpers"
import { Contact } from "../../../interfaces/crm/contact"
import AddressInput from "../Inputs/AddressInput"
import { AditionalDataContact } from "./AdditionalDataForm"
import CompanyGroupsField from "./companyField"
import ContactPhoneInput from "./ContactPhoneInput"
import { CreateContactFormType } from "./CreateContactDrawer"
import { GetOriginItem } from "./GetOrigin"
import PhoneList from "./PhoneList"

type TPhoneOrNumber = "phones" | "emails"


interface FieldByReferenceProps {
  contextForm,
  contact: Contact,
  onSetError: (value: string) => void,
  additionalDataFieldsToMap: Record<string, any>
  isModifyContact: boolean,
  enableInternalPhoneNumbers?: boolean,
  onCLoseSheet: (value: boolean) => void
  defaultPhoneMetadata: CreateContactFormType['phonesMetadata'],
  markAsUpdatedFields: () => void,
  channels: any[]
  field: {
    index: number,
    title?: string,
    description?: string,
    placeholder?: string,
    reference: string
  }
}

export function FieldByReference({
  contextForm,
  field,
  contact,
  onCLoseSheet,
  onSetError,
  isModifyContact,
  additionalDataFieldsToMap,
  channels,
  enableInternalPhoneNumbers,
  markAsUpdatedFields,
  defaultPhoneMetadata
}: FieldByReferenceProps) {
  const { title, reference, placeholder } = field

  const withCopyContext = (name, fn) => {
    const newArray = [...contextForm.getValues(name)]
    fn(newArray)
    markAsUpdatedFields();
    contextForm.setValue(name, newArray)
  }

  const handleAddArray = (name: TPhoneOrNumber) => () => {
    withCopyContext(name, (arrayToModify: Array<string>) =>
      arrayToModify.push("")
    )
  }

  const handleRemoveArray = (name: TPhoneOrNumber, index: number) => () => {
    withCopyContext(name, (arrayToModify: Array<string>) =>
      arrayToModify.splice(index, 1)
    )
  }

  if (reference.startsWith('additionalData')) {
    const additionalDataCode = reference.split('.')[1]
    const additionalField: Record<string, any> | undefined = additionalDataFieldsToMap[additionalDataCode]

    if (!additionalField) return null;

    if (title) additionalField['name'] = title
    if (placeholder) additionalField['placeholder'] = placeholder

    return (
      <AditionalDataContact
        id={isModifyContact ? contact?._id || '' : ""}
        control={contextForm.control}
        markAsUpdatedFields={markAsUpdatedFields}
        additionalDataFields={[additionalField]}
        contextForm={contextForm}
        onClose={() => onCLoseSheet(false)}
      />
    )
  }

  switch (reference) {
    case 'firstName': return (
      <CustomFormField
        name="firstName"
        label={title || 'Nombre'}
        isRequired
        control={contextForm.control}
        fnElement={({ field }) => (
          <Input
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              markAsUpdatedFields()
            }}
            placeholder={placeholder || 'Nombre'}
            autoFocus
          />
        )}
      />
    )
    case 'lastName': {
      return (
        !isFeatureEnabled(Feature.DISABLE_LASTNAME)) && (
          <CustomFormField
            name="lastName"
            label={title || 'Apellido'}
            control={contextForm.control}
            fnElement={({ field }) => (
              <Input
                placeholder={placeholder || 'Apellido'}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  markAsUpdatedFields()
                }}
              />
            )}
          />
        )
    }
    case 'phones': {

      const contactPhonesMetadata = contact?.phonesMetadata || [];
      const useDefaultPhoneMetadata = (defaultPhoneMetadata.length == 0 && contactPhonesMetadata.length > 0)
        ? contact?.phonesMetadata
        : defaultPhoneMetadata

      return (
        <PhoneList
          contact={contact}
          placeholder={placeholder}
          title={title}
          enableInternalPhoneNumbers={enableInternalPhoneNumbers}
          defaultPhones={(!contact?.phones || contact.phones.length == 0) ? [""] : contact.phones}
          defaultPhoneMetadata={useDefaultPhoneMetadata}
          handleAddArray={handleAddArray}
          handleRemoveArray={handleRemoveArray}
          markAsUpdatedFields={markAsUpdatedFields}
          isModifyMode={isModifyContact}
          handleSetMessageError={(e) => onSetError(e)}
          onCLoseSheet={() => onCLoseSheet(false)}
          contextForm={contextForm}
          channels={channels}
        />
      )
    }
    case 'emails': {
      let emails = contextForm.watch("emails") || [''];
      if (emails.length == 0) emails = ['']

      return emails.map((_, inx) => (
        <div key={`emails${inx}`}>
          <CustomFormField
            name={`emails.${inx}`}
            control={contextForm.control}
            fnElement={({ field }) => (
              <div className="flex flex-col gap-2">
                {inx == 0 && <FormLabel>{title || 'Emails'}</FormLabel>}
                <div>
                  <ContactPhoneInput
                    required={false}
                    label={
                      inx == 0
                        ? `${placeholder || 'Email'} principal`
                        : `${placeholder || 'Email'} ${inx}`
                    }
                    value={field.value || ""}
                    removeIcon={inx !== 0}
                    onChange={(value) => {
                      field.onChange(value);
                      markAsUpdatedFields()
                    }}
                    onIconPress={
                      inx === 0
                        ? handleAddArray("emails")
                        : handleRemoveArray("emails", inx)
                    }
                  />
                </div>
              </div>
            )}
          />
        </div>
      ))
    }
    case 'govId': return (
      isFeatureEnabled(Feature.DISABLE_LASTNAME) && (
        <CustomFormField
          name="govId"
          label={title || 'Documento de identidad'}
          control={contextForm.control}
          fnElement={({ field }) => (
            <Input
              placeholder={placeholder || 'Documento de identidad'}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                markAsUpdatedFields()
              }}
            />
          )}
        />
      )
    )
    case 'statusTag': {
      const statusTags = getFilteredStatusTags();

      return (
        <CustomFormField
          name="statusTag"
          label={title || 'Etiqueta'}
          control={contextForm.control}
          fnElement={({ field }) => (
            <Select
              value={field?.value}
              onValueChange={(value) => {
                field.onChange(value);
                markAsUpdatedFields()
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder={placeholder || 'Seleccionar'} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {
                    statusTags
                      .filter((tag) => tag.active)
                      .map(({ code, name, color }) => (
                        <SelectItem
                          value={code}
                          key={code}
                        >
                          <div className="flex gap-2 items-center">
                            <div
                              style={{
                                backgroundColor:
                                  tagColorToPalette[color]
                                    .primary,
                                color:
                                  tagColorToPalette[color]
                                    .secondary,
                              }}
                              className="w-[20px] h-[20px] rounded-full flex justify-center items-center relative text-[#fff]"
                            >
                            </div>
                            <span>{name}</span>
                          </div>
                        </SelectItem>
                      ))
                  }
                </SelectGroup>
              </SelectContent>
            </Select>
          )}
        />
      )
    }
    case 'contactLocation': {
      const isLocationRequired = isFeatureEnabled(Feature.LOCATION_REQUIRED)
      return (
        isFeatureEnabled(Feature.LOCATION_FIELD) && (
          <CustomFormField
            name="contactLocation"
            label={title || 'Dirección'}
            control={contextForm.control}
            isRequired={isLocationRequired}
            fnElement={({ field }) => (
              <AddressInput
                address={field.value}
                onAddressChange={(value) => {
                  field.onChange(value);
                  markAsUpdatedFields()
                }}
                placeholder={placeholder || 'Selecciona una dirección...'}
                askExtraInfo={true}
                required={isLocationRequired}
              />
            )}
          />
        )
      )
    }
    case 'origin': {
      const origins = getOriginsFiltered();

      return (
        <CustomFormField
          name="origin"
          label={title || 'Origen'}
          control={contextForm.control}
          isRequired={true}
          fnElement={({ field }) => (
            <Select
              onValueChange={(value) => {
                field.onChange(value);
                markAsUpdatedFields()
              }}
              defaultValue={field.value}
              value={field.value}
              disabled={isModifyContact}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder={placeholder || 'Seleccionar'} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {isModifyContact ? (
                    <GetOriginItem origin={field.value} />
                  ) : (
                    origins.map((o) => (
                      <SelectItem key={o.code} value={o.code}>
                        {o.name}
                      </SelectItem>
                    ))
                  )}
                </SelectGroup>
              </SelectContent>
            </Select>
          )}
        />
      )
    }
    case 'affiliatedCompanyGroup': {
      if (!isFeatureEnabled(Feature.ENABLE_BUSINESS_SALES_MODEL)) return null;

      return (
        <CustomFormField
          name="affiliatedCompanyGroup"
          control={contextForm.control}
          fnElement={({ field }) => (
            <CompanyGroupsField
              value={field.value}
              contact={contact}
              placeholder={placeholder}
              onChange={(value) => {
                field.onChange(value);
                markAsUpdatedFields()
              }}
            />
          )}
        />
      )
    }
    case 'detail': return (
      <CustomFormField
        name="detail"
        label={title || 'Comentario'}
        control={contextForm.control}
        fnElement={({ field }) => (
          <Textarea
            placeholder={placeholder || 'Comentario'}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              markAsUpdatedFields()
            }}
          />
        )}
      />
    )
    default: return 'Campo no reconocido'
  }
}