import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import useDebounce from "../core/functions/useDebouce";

export interface InputSearch
    extends React.InputHTMLAttributes<HTMLInputElement> {
    onChangeWithDebounce?: (value: string) => void
    delay?: number
    onChangeValue?: (value: string) => void
    classNameContent?: string,
    hiddenIcon?: boolean
}

export const InputSearch = React.forwardRef<HTMLInputElement, InputSearch>(({
    className,
    onChangeValue,
    hiddenIcon = false,
    ...props
}, ref) => {
    const [value, setValue] = useState<string>((props.value as string) || '');
    const valueWithDebounce = useDebounce(value, props.delay || 500)

    const valueToUse = typeof props.value === 'undefined' ? value : props.value

    useEffect(() => {
        if (props.onChangeWithDebounce) {
            props.onChangeWithDebounce((valueWithDebounce as string).trim())
        }
    }, [valueWithDebounce])

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(e)
        const { value } = e.target
        onChangeValue && onChangeValue(value)
        setValue(value)
    }

    return <div className={cn('relative', props.classNameContent)}>
        {
            !hiddenIcon && <Search
                className="absolute left-3.5 text-gray-600 placeholder:text-slate-500 top-0 bottom-0 m-auto"
                size={17}
            />
        }
        <Input
            ref={ref}
            className={cn("w-full h-[34px] pl-10", className)}
            placeholder="Ingrese nombre de la campaña que busca"
            {...props}
            onChange={handleChangeValue}
            value={valueToUse}
        />
    </div>
})