import { getRequestTemplateAll } from '@/actions/crm/request-sale';
import { Feature, getPersonalFeature, isFeatureEnabled, PersonalFeature } from '@/helpers/featureFlagHelpers';
import { setRequestSale } from '@/redux/slices/crm';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useGetRequestSaleTemplates() {
    const { onceFetching, templates = [], active } = useSelector((state: any) => state.crm.requestSale) || {};
    const enableRequestSale = (isFeatureEnabled(Feature.ENABLE_REQUEST_SALE) &&
        !getPersonalFeature<boolean>(PersonalFeature.DISABLE_REQUEST_SALE, false));

    const dispatch = useDispatch();

    const { data, ...rest } = useQuery({
        queryKey: ['request-sale-templates-list'],
        queryFn: () => getRequestTemplateAll(),
        enabled: enableRequestSale && !onceFetching
    })

    useEffect(() => {
        if (!data) return;
        const templates = data || [];

        dispatch(setRequestSale({
            active: templates.length > 0,
            onceFetching: true,
            isLoading: false,
            templates
        }))
    }, [data])

    return {
        templates,
        onceFetching,
        active,
        ...rest
    }
}
