import { cn } from "@/lib/utils"
import { motion, useAnimation } from 'framer-motion'
import { useEffect, useState } from "react"

export default function ProgressBar({ className, progress = 0 }: { className?: string, progress: number }) {
    const [completed, setCompleted] = useState(false);
    const controls = useAnimation();

    useEffect(() => {
        if (progress === 100) {
            controls.start({ opacity: 0 }).then(() => setCompleted(true));
        } else {
            controls.start({ opacity: 1 }).then(() => setCompleted(false));
        }
    }, [progress, controls]);

    if (completed) return null

    return (
        <motion.div
            className={cn('w-full mt-1 rounded-md overflow-hidden h-[4px] bg-gray-100 relative', className)}
            initial={{ opacity: 1 }}
            animate={controls}
            transition={{ duration: 0.5 }}
        >
            <span
                className="bg-primary h-full left-0 absolute top-0 rounded-r-md"
                style={{
                    width: `${progress}%`
                }}
            />
        </motion.div>
    );
}