import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

export default function ButtonReminderOption({ onAction, className, children }: any) {
    return (
      <Button
        onClick={onAction}
        variant={"ghost"}
        type="button"
        style={{ boxShadow: "0px 1px 5px rgba(0 0 0 / 14%)" }}
        className={cn(
          `flex items-center hover:bg-neutral-50 w-full h-[43px] rounded-[10px] text-[13px] gap-2 text-slate-600 hover:text-slate-700 [&>svg]:w-[15px] [&>svg]:h-[15px]  sm:[&>svg]:w-[18px] sm:[&>svg]:h-[18px]`,
          className
        )}
      >
        {children}
      </Button>
    )
  }
  