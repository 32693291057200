

import { Button } from "@/components/ui/button";
import { CustomCheckbox } from "@/components/ui/checkbox";
import InfiniteScroll from "@/components/ui/infinite-scroll";
import { InputSearch } from "@/components/ui/input-search";
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle
} from "@/components/ui/sheet";
import { Skeleton } from "@/components/ui/skeleton";
import useGetContacts from "@/hooks/crm/useGetContacts";
import { ModalPropsBase } from '@/interfaces/Interface';
import { Frown } from "lucide-react";
import { useEffect, useState } from "react";
import { ContactAvatar } from "../Prospect";

interface SelecteableContactsSheetProps extends ModalPropsBase {
    onSelected?: (contacts: string[], role?: string) => void,
    companyGroupId: string
};

export default function SelecteableContactsSheet(props: SelecteableContactsSheetProps) {
    const { onOpenChange, open, onSelected, companyGroupId } = props;

    const [contactsSelected, setContactsSelected] = useState<Set<string>>(new Set());

    const { contacts, hasMore, onChangePage, searchParams, isLoading, onChangeFilters, isLoadingFirstPage } = useGetContacts({
        queryKey: ['selecteabled-contact-list', companyGroupId, `${open}`],
        enabled: (!!companyGroupId && open),
        forceSearchParams: {
            'notaffiliatedCompanyGroup.companyGroup': companyGroupId
        }
    });

    const onSelectContact = (id: string) => {
        let newValue = new Set(Array.from(contactsSelected.values()));

        if (newValue.has(id)) {
            newValue.delete(id)
        } else {
            newValue.add(id)
        };

        setContactsSelected(newValue);
    };

    const onSubmit = () => {
        onSelected && onSelected(Array.from(contactsSelected.values()));
        onOpenChange(false);
    };

    useEffect(() => {
        setContactsSelected(new Set());
    }, [open])

    return (
        <Sheet open={open} onOpenChange={onOpenChange} >
            <SheetContent
                className='w-full shadow-none gap-0 rounded-l-xl p-0 flex-col flex'
                classNameCloseButton="[&>svg]:h-5 [&>svg]:w-5"
                classNameOverlay='bg-black/50'
            >
                <SheetHeader className="px-6 pt-6">
                    <SheetTitle>
                        Seleccionar Contactos
                    </SheetTitle>
                </SheetHeader>
                <div className="px-6 my-2">
                    <InputSearch
                        placeholder="Buscar contacto..."
                        onChangeWithDebounce={(search) => onChangeFilters({ search })}
                    />
                </div>
                <div className='flex flex-col flex-grow w-full relative'>
                    <InfiniteScroll
                        className="absolute top-0 left-0 w-full h-full px-6 pb-2 overflow-y-auto"
                        next={() => onChangePage(searchParams.page + 1)}
                        loading={isLoadingFirstPage}
                        dataLength={contacts.length}
                        loader={<Loader />}
                        hasMore={hasMore}
                    >
                        {
                            (contacts.length == 0 && !isLoading)
                                ? <EmptyContact />
                                : contacts.map((contact) => {
                                    const fullName = [contact.firstName, contact.lastName].filter(Boolean).join(' ');
                                    const isSelected = contactsSelected.has(contact._id);

                                    return (
                                        <button
                                            onClick={() => onSelectContact(contact._id)}
                                            className={`flex py-1.5 hover:bg-gray-50 ${isSelected ? 'bg-gray-50' : ''} duration-100 items-center gap-3`}
                                            key={contact._id}
                                        >
                                            <CustomCheckbox
                                                className="w-[22px] rounded-md h-[22px]"
                                                checked={isSelected}
                                            />
                                            <div className="flex items-center gap-2">
                                                <ContactAvatar
                                                    lastName={contact.lastName}
                                                    statusTag={contact.statusTag}
                                                    firstName={contact.firstName}
                                                    className="w-[29px] text-[13px] shrink-0 h-[29px]"
                                                />
                                                <h5
                                                    title={fullName}
                                                    className="text-[13.5px] truncate max-w-[140px] font-medium text-gray-700">
                                                    {fullName}
                                                </h5>
                                            </div>
                                        </button>
                                    )
                                })
                        }
                    </InfiniteScroll>
                </div>
                <div className="py-2 px-3 border-t rounded-t-md">
                    <Button
                        type="button"
                        className="w-full"
                        disabled={contactsSelected.size == 0}
                        onClick={onSubmit}
                    >
                        Agregar
                    </Button>
                </div>
            </SheetContent>
        </Sheet>
    )
};

export function Loader() {
    return (
        <ul className="flex flex-col ">
            {
                Array.from({ length: 10 }).fill(null).map((__, inx) => (
                    <li key={inx} className="flex items-center py-1.5 gap-3">
                        <Skeleton className="w-[23px] h-[23px] rounded-md" />
                        <Skeleton className="w-[29px] h-[29px] rounded-full shrink-0" />
                        <Skeleton className="w-[70px] h-2 rounded-md " />
                        <Skeleton className="w-[50px] h-2 rounded-md " />
                    </li>
                ))
            }
        </ul>
    )
};

export function EmptyContact() {
    return (
        <div className="flex items-center border rounded-md p-7 justify-center">
            <p className="text-gray-700 text-[14px]">
                <Frown size={18} className="mr-2 inline" strokeWidth={1.5} />
                Sin resultados
            </p>
        </div>
    )
}