import { isAuth } from "@/actions/auth"
import { Button } from "@/components/ui/button"
import { CardDescription } from "@/components/ui/card"
import { Command, CommandGroup, CommandItem } from "@/components/ui/command"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import _ from "lodash"
import mixpanel from "mixpanel-browser"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { transfer } from "../../../actions/crm/contact"
import { getTransferableTo } from "../../../actions/group"
import { BtnNextStep } from "@/components/buttons/BtnNextStep"
import { useSelector } from "react-redux"
import { ROLES, isTheRolEnabled } from "@/helpers/featureFlagHelpers"
import { useQuery, useQueryClient } from "@tanstack/react-query"

moment.locale("es")

interface TransferModalInterface {
  open: any
  handleClose: () => void
  handleSuccess?: () => void
}

export enum FetchStates {
  LOADING = 'loading',
  ERROR = 'error',
  NOTHING = 'nothing'
}

const TransferModal = ({
  open,
  handleClose,
  handleSuccess,
}: TransferModalInterface) => {
  const [stateOfFetch, setStateOffetch] = useState(FetchStates.NOTHING)
  const [userToTransfer, setUserToTransfer] = useState<string>("")
  const isMasiveIDs = useMemo(() => open && open.length > 1, [open])

  const userSelected = useSelector((state: any) => state.crm.userInbox)

  const { data = { users: [] } } = useQuery({
    queryKey: ['list-of-sellers'],
    queryFn: getTransferableTo
  })

  const users = useMemo(() => {
    const userId = _.get(isAuth(), "_id", "")
    const inboxUser = userSelected === 'me' ? userId : userSelected

    return data.users?.filter((user) => {
      return isTheRolEnabled([ROLES.COMERCIAL, ROLES.SUPER])
        ? user._id !== inboxUser
        : userId
    })
  },
    [data, userSelected])

    const queryCliente = useQueryClient()

  const handleSubmit = () => {
    if (open.length === 0 || !open || typeof open === "boolean") return
    setStateOffetch(FetchStates.LOADING)
    const allPromises =
      open &&
      [open].flat().map(
        (id) =>
          new Promise((resolve, reject) => {
            transfer(id, userToTransfer).then((response) => {
              resolve(response)
              if (response.error) {
                mixpanel.track("Transfer created Error", {
                  contactId: open,
                  receiverId: userToTransfer,
                  error: response.error,
                })
              } else {
                mixpanel.track("Transfer created", {
                  contactId: open,
                  receiverId: userToTransfer,
                })
                reject(false)
              }
            })
          })
      )

    Promise.all(allPromises)
      .then(() => {
        queryCliente.invalidateQueries({
          queryKey: ["table-contacts"]
        })
        setStateOffetch(FetchStates.NOTHING)
        handleSuccess && handleSuccess()
        handleClose()
      })
      .catch(() => {
        setStateOffetch(FetchStates.ERROR)
      })
  }

  const [isError, isLoading] = [stateOfFetch == FetchStates.ERROR, stateOfFetch == FetchStates.LOADING]

  return (
    <Dialog open={!!open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px] flex flex-col px-0 max-h-[70vh]">
        <DialogHeader className="px-5">
          <DialogTitle>
            Elige a quien transferir{" "}
            {isMasiveIDs ? "los contacto" : "el contacto"}
          </DialogTitle>
          <CardDescription></CardDescription>
        </DialogHeader>
        <div className="flex-grow modifyScrollChatCrm px-2 overflow-y-auto relative">
          <RadioGroup
            value={userToTransfer}
            onValueChange={(e) => setUserToTransfer(e)}
            defaultValue={userToTransfer}
          >
            <Command>
              <CommandGroup>
                {users?.map((a) => (
                  <CommandItem
                    key={a.value}
                    className="flex items-center gap-2 px-4"
                    onSelect={() => setUserToTransfer(a._id)}
                  >
                    <RadioGroupItem
                      value={a._id}
                      className="w-[18px] h-[18px]"
                    />
                    <Label className="text-[16px] font-normal">
                      {a.name + " " + a.lastname}
                    </Label>
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </RadioGroup>
        </div>
        <DialogFooter className="px-5">
          <BtnNextStep
            hiddenArrow
            onClick={handleSubmit}
            variant={'default'}
            disabled={userToTransfer === ""}
            loading={isLoading}
          >
            Transferir
          </BtnNextStep>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default TransferModal
