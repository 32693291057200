import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

export default function ButtonBack({
    onClick
}) {
    return (
        <Button
            type='button'
            variant={'ghost'}
            className="flex font-medium border  rounded-full h-[30px] px-3 text-gray-700 items-center text-[15px] gap-1"
            onClick={onClick}
        >
            <ArrowLeft size={17} />
            Volver
        </Button>
    )
}