import { fetcher } from "@/helpers/fetcher"
import { formatSearchParams } from "@/helpers/formatSearchParams"
import { getCookie } from "../auth"
const API = process.env.API

export const getCompanyGroups = (params: Record<string, any>) => {
    const queryParams = formatSearchParams(params)
    return fetcher<Array<any>>({
        url: `/comercial/companyGroup?${queryParams}`,
        defaultContentType: true
    })
}

export const getOneCompanyGroup = (id: string) => {
    return fetcher({
        url: `/comercial/companyGroup/${id}`,
        defaultContentType: true
    })
}

export const deleteCompanyGroup = (companyGroupsIds) => {
    return fetch(`${API}/companyGroup/massiveAction/delete`, {
        method: "POST",
        body: JSON.stringify({
            companies: companyGroupsIds,
        }),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "JWT " + getCookie("token"),
        },
    })
        .then((response) => {
            return response.json();
        })
        .catch((err) => console.log(err));
};