import { changeScheduleMessage } from '@/actions/crm/contact'
import FormatHtml from '@/components/format-html'
import { StyledLinkifyerLines } from '@/components/StyledLinkifyer'
import { ScheduledMessage as IScheduledMessage, ScheduleMessageStatus } from '@/interfaces/crm/contact'
import { useMutation } from '@tanstack/react-query'
import { Ban, CalendarClock, Mail } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import AudioPlayer from '../AudioPlayer'
import FileInsideBubble from '../FileInsideBubble'
import ImageInsideBubble from '../ImageInsideBubble'
import InteractiveMessageCard from '../InteractiveMessageCard'
import VideoInsideBubble from '../VideoInsideBubble'

interface ScheduledMessageProps {
    interaction: IScheduledMessage,
    profile: string
}

const imageByInteractionType = {
    'unofficial-whatsapp': 'channelLogos/unofficial_wpp.png',
    "email": ""
}

export default function ScheduledMessage({
    interaction,
    profile
}: ScheduledMessageProps) {
    const [scheduleStatus, setScheduleStatus] = useState<ScheduleMessageStatus>(interaction?.content?.status || 'active')
    const [lastScheduleStatus, setLastScheduleStatus] = useState<ScheduleMessageStatus>(interaction?.content?.status || 'active')

    const { content, agent } = interaction
    const { date, interactionType, messageContent, emailContent, status, channel } = content || {}
    const { message } = messageContent || {};

    const { mutate } = useMutation({
        mutationFn: (status: ScheduleMessageStatus) => changeScheduleMessage(interaction._id, status),
        onSuccess: () => setLastScheduleStatus(scheduleStatus),
        onError: () => setScheduleStatus(lastScheduleStatus)
    })

    const onHandleChangeScheduleStatus = (status: ScheduleMessageStatus) => () => {
        setScheduleStatus(status)
        mutate(status)
    };

    const [isActive, isError, isSuccess, isProcessing] = [scheduleStatus === 'active', scheduleStatus == 'error', scheduleStatus == 'success',scheduleStatus == 'processing']
    const srcInteractionImage = imageByInteractionType[interactionType];
    const isEmail = (interactionType == 'email');

    return (
        <InteractiveMessageCard
            profile={profile}
            origin={'agent'}
            defaultStyles={false}
            agent={agent}
            className={`border bg-white flex ${(!isSuccess && isError) ? 'border-red-600' : ''} md:min-w-[380px] min-w-auto md:max-w-[380px] max-w-[90%] overflow-hidden  flex-col relative p-3.5`}
            customCircle={(
                <div className='w-[30px] h-[30px] flex items-center justify-center bg-primary shrink-0 rounded-full overflow-hidden relative'>
                    {
                        interactionType == 'email'
                            ? <Mail size={18} className="text-white" />
                            : <img src={srcInteractionImage} className='w-full h-full object-cover' />
                    }
                </div>
            )}
        >
            <div className='w-full flex mb-2 bg-gray-100 border-l-4 border-l-primary gap-2 px-4 items-center rounded-md  py-3'>
                <CalendarClock size={18} className='text-primary' />
                {
                    date && (
                        <p className='text-[12px] font-medium text-gray-800'>
                            {formatScheduledDate(new Date(date))}
                        </p>
                    )
                }
            </div>
            <div className='flex flex-col gap-2'>
                <div className="flex flex-col gap-1">
                    <MediaContent messageContent={messageContent} />
                    {
                        isEmail && <div className='mb-2 border-b pb-2'>
                            <h4 className='text-[14px] font-medium text-base text-gray-700'>
                                {emailContent?.subject || ''}
                            </h4>
                            <p className='text-[12.5px] text-gray-500'>
                                {emailContent?.recipients?.[0]?.email}
                            </p>
                        </div>
                    }
                    {
                        isEmail
                            ? (
                                <FormatHtml className='text-[13px] font-medium text-gray-700 h-auto'>
                                    {emailContent?.body || ''}
                                </FormatHtml>
                            )
                            : (
                                <p className='text-[13px] font-medium text-gray-700'>
                                    <StyledLinkifyerLines text={message || ''} />
                                </p>
                            )
                    }
                </div>
                {
                    isActive
                        ? <button
                            className="h-7 text-red-600 font-medium gap-1 flex items-center decoration-l text-[13px]"
                            onClick={onHandleChangeScheduleStatus('cancel')}
                        >
                            <Ban strokeWidth={2} size={17} />
                            <span className="underline">
                                Cancelar envío.
                            </span>
                        </button>
                        : isError
                            ? <DestructiveDescription >
                                Error al enviar el mensaje programado
                            </DestructiveDescription>
                            : (!isSuccess && !isProcessing) && <div className="flex gap-1 items-center">
                                <DestructiveDescription >
                                    <Ban strokeWidth={2} size={17} />
                                    <span>
                                        El envío del mensaje fue cancelado.
                                    </span>
                                </DestructiveDescription>
                                <button className="h-7 text-red-600 font-bold gap-1 flex items-center decoration-l text-[12px]" onClick={onHandleChangeScheduleStatus('active')}>
                                    <span className="underline">
                                        Deshacer.
                                    </span>
                                </button>
                            </div>
                }
            </div>
        </InteractiveMessageCard>

    )
}

interface MediaContentProps {
    messageContent: IScheduledMessage['content']['messageContent']
}

export function MediaContent({
    messageContent
}: MediaContentProps) {
    const { mediaType, media = '', filename = 'file' } = messageContent || {}
    if (!mediaType) return null

    return (
        <div className="border rounded-md">
            {
                mediaType === 'image'
                    ? <ImageInsideBubble media={media} />
                    : mediaType === 'video'
                        ? <VideoInsideBubble media={media} className="bg-black max-h-[300px] rounded-sm w-full" />
                        : mediaType === 'file'
                            ? <FileInsideBubble media={media} fileName={filename} origin={'agent'} />
                            : mediaType === 'audio'
                                ? <AudioPlayer url={media} />
                                : null
            }
        </div>
    )
}

const formatScheduledDate = (date: Date): string => {
    const today = moment().startOf('day');
    const tomorrow = moment().startOf('day').add(1, 'day');
    const targetDate = moment(date);

    if (targetDate.isSame(today, 'day')) {
        return `Programado para hoy a las ${targetDate.format('HH:mm')} Hs`;
    } else if (targetDate.isSame(tomorrow, 'day')) {
        return `Programado para mañana a las ${targetDate.format('HH:mm')}Hs`;
    }

    return `Programado para el ${targetDate.format('DD [de] MMMM [de] YYYY')} a las ${targetDate.format('HH:mm')} Hs`;
}

export function DestructiveDescription({
    children
}) {
    return (
        <p className="h-7 text-red-600 font-medium gap-1 flex items-center  text-[12px]">
            {children}
        </p>
    )
}