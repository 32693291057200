import { CardTitle } from "@/components/ui/card"
import { Error } from "../crm/Bubble/Icons/Crm"

export interface CardErrorProps {
    title?: string,
    description?: string
}

export default function CardError({
    title,
    description
}: CardErrorProps) {
    return <div className="flex gap-2 items-start">
        <Error className="w-[30px] shrink-0 h-[30px] relative " />
        <div className="flex flex-col">
            <CardTitle className="text-[15px]">
                {title}
            </CardTitle>
            <p className="text-[15px] text-gray-600">
                {description}
            </p>
        </div>
    </div>
}
