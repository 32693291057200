import phoneValidator, { CountryCode, PhoneNumber } from 'libphonenumber-js';

type PhoneNumberMeta = PhoneNumber & {  valid: boolean}

export const validatePhoneNumberByFormats = (phone, phoneCode = 'UY', formats: string[]): PhoneNumberMeta | undefined => {
    const phoneTrim: string = `${phone}`.trim()
    const phoneCodeToUppercase: string = phoneCode.toUpperCase()
    let valid: PhoneNumberMeta | undefined;

    for (let format of formats) {
        const phoneNumber = format.replace(/\{phone\}/g, phoneTrim);

        const phoneNumberValidation = phoneValidator(phoneNumber, phoneCodeToUppercase as CountryCode)
        if (phoneNumberValidation && phoneNumberValidation.isValid()) {

            valid = phoneNumberValidation as PhoneNumberMeta
            valid.valid = phoneNumberValidation && phoneNumberValidation.isValid()
            break;
        }
    }
 
    return valid
}

const validatePhoneNumber = (phone: string | number, phoneCode: string = 'UY'): PhoneNumberMeta | undefined => {
    let returnData = validatePhoneNumberByFormats(phone, phoneCode, ['{phone}', '0{phone}', '+{phone}'])
    return returnData
}

export default validatePhoneNumber