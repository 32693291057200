
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

export default function ContactSkeleton() {
    const randomNumber = Math.floor(Math.random() * 5) + 2;

    return (
        <Card className="p-4 rounded-lg bg-gray-50 h-full">
            <CardHeader className="p-0 ">
                <div className="flex overflow-hidden flex-grow w-full items-center">
                    <Skeleton className="w-[33px] h-[33px] mr-2 rounded-full shrink-0" />
                    <Skeleton className="w-[125px] h-2" />
                </div>
            </CardHeader>
            <CardContent className="p-0 mt-4">
                <ul className="grid gap-2.5">
                    {
                        Array.from({ length: randomNumber })
                            .map((__, inx) => (
                                <li className="gap-2 grid grid-cols-2">
                                    <div className="flex items-center gap-2">
                                        <Skeleton className="w-3 h-3 rounded-full shrink-0" />
                                        <Skeleton className="h-2 " style={{ width: `${120 - (inx * 1.3)}px` }} />
                                    </div>
                                    <Skeleton className="h-2 " style={{ width: `${120 - (inx * 1.3)}px` }} />
                                </li>
                            ))
                    }
                </ul>
            </CardContent>
        </Card >
    )
}