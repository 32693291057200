import { ROLES, isTheRolEnabled } from "@/helpers/featureFlagHelpers"
import useActiveSharedInbox from "@/hooks/crm/useActiveSharedInbox"
import useGetCurrentUser from "@/hooks/useGetCurrentUser"
import useGetDashboardChartByUser from "@/hooks/useGetDashboardChartByUser"
import useGetManager from "@/hooks/useGetManager"
import useHandlerNotification from "@/hooks/useHandlerNotification"
import { useToggle } from "@/hooks/useToggle"
import useGetUnopenedNotifications from "@/hooks/user/useGetUnopenedNotifications"
import { cn } from "@/lib/utils"
import {
  setChatIsVisible,
  setOpenMainMenu
} from "@/redux/slices/headers"
import '@/styles/scrollModify.css'
import { useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { PanelLeft, PanelRight } from "lucide-react"
import moment from "moment"
import "moment/locale/es"
import dynamic from 'next/dynamic'
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCompany, getCookie, isAuth } from "../actions/auth"
import NavBar from "./Header/NavBar"
import UserProfile from "./Header/UserProfile"
import useMenu from "./Header/libs/hooks/useMenu"
import { IPropsHeader } from "./Header/libs/interfaces-enums"
import ChangePassworld from "./Header/modals/changePassword"
import MenuSellers from "./Menues/MenuSellers"
import ReusableSubMenu from "./Menues/ReusableSubMenu"
import useGetUnassignedCount from "./crm/UnAssigned/hooks/useGetUnassignedCount"
import NotificationModal from "./notification/NotificationModal"
import NotificationsContainer from "./notification/NotificationsContainer"
import Presentation from "./presentations/Presentation"
import BackgroundSheets from "./ui/background-sheets"

const ChatIntercom = dynamic(() => import('./chat-Intercom'), { ssr: false })


moment.locale("es")
dayjs.locale("es")

export default function Header({
  children,
  collapsedMenu,
  classNameResponsiveHeader = "",
  containerStyle = "",
  chat = true,
  slug,
  overlayMode = false,
  widthOfSubMenu,
  SubMenu
}: IPropsHeader) {
  const [group, setGroup] = useState(false)
  const company = useMemo(() => getCompany(), [children, chat])
  const [notificationModal, setNotification] = useState()
  const [openMenuSeller, toggleOpenSeller, setOpenSeller] = useToggle<boolean>(true)

  useGetManager()
  useHandlerNotification()
  useGetUnassignedCount()
  useGetDashboardChartByUser()
  useGetCurrentUser()
  useActiveSharedInbox();


  const { handleCollapsedMenu, isCollapsed } = useMenu()
  const isMobile = useMediaQuery("(max-width: 1025px)")
  const router = useRouter()

  const dispatch = useDispatch()
  const user = useMemo(() => isAuth(), [])
  const isGhostAdmin = useMemo(() => getCookie('admin_token'), [])
  const openMenu = useSelector((state: any) => state.header.openMenuMain)
  const countNotification = useSelector((state: any) => state.header.countNotififcation)
  const chatVisible = useSelector((state: any) => state.header.chatIsVisible)

  const isSubMenuActive = !!SubMenu || (openMenuSeller && isTheRolEnabled([ROLES.COMERCIAL, ROLES.SUPER]) && router.pathname === '/')
  const paddingToUse = widthOfSubMenu || (openMenuSeller && 225)

  useEffect(() => {
    if (isMobile) handleCollapsedMenu(false)
  }, [isMobile])

  useEffect(() => {
    if (!openMenu) setOpenSeller(true)
  }, [openMenu])

  useEffect(() => {
    handleOpenMenu(false)
  }, [router.pathname])

  useEffect(() => {
    if (typeof collapsedMenu === 'boolean') handleCollapsedMenu(collapsedMenu)
  }, [collapsedMenu])

  useEffect(() => {
    if (!user) return () => { }
    let group = user.group
    if (group.parent) {
      group = group.ancestors[0]
    };

    let nav = group.nav
    setGroup(group);
  }, [])

  useEffect(() => {
    if (isGhostAdmin) {
      dispatch(setChatIsVisible(false))
    } else {
      dispatch(setChatIsVisible(chat))
    }
  }, [isGhostAdmin])

  const handleOpenMenu = (value: boolean) => dispatch(setOpenMainMenu(value))

  return (
    <>
      <div className="flex flex-col min-h-screen relative m-auto">
        <header
          className={cn(
            "w-full z-10 flex border-b lg:hidden top-0 fixed items-center h-[50px] bg-white justify-between px-5 shrink-0",
            classNameResponsiveHeader
          )}
        >
          <ButtonToOpenMenu onClick={() => handleOpenMenu(!openMenu)} />
        </header>
        <BackgroundSheets active={openMenu} onClick={() => handleOpenMenu(false)} />
        <main
          className={`bg-white duration-75 flex flex-grow`}
          style={{
            paddingLeft: isMobile ? '0px' : isCollapsed ? !!isSubMenuActive ? !isMobile ? `calc(68px + ${paddingToUse}px)` : '0px' : '68px' : !!isSubMenuActive && !isMobile ? `calc(68px + ${paddingToUse}px)` : '210px'
          }}
        >
          <div className={
            `flex w-max lg:left-0 ${openMenu ? "left-0" : "-left-full"} duration-200 ease-in-out bg-white top-0 fixed z-40 h-[100vh]`
          }>
            <div className={`${isSubMenuActive ? 'block' : ''} w-full`}>
              <aside
                style={{
                  boxShadow: (overlayMode || isSubMenuActive) && !isCollapsed ? '2px 1px 45px rgba(0 0 0 / 5%)' : ''
                }}
                className={`flex flex-col lg:left-0  h-[100vh] z-10 modifyScroll absolute bg-white top-0 left-0 overflow-y-auto  border-r border-r-slate-200`}>
                <div className={`w-[210px] ${isCollapsed ? "lg:w-[68px]" : "lg:w-[210px]"
                  } h-full shrink-0 relative duration-100 bg-white  ease-out`}>
                  <div className="flex flex-col shrink-0 w-full">
                    <Link href={'/'}>
                      <div className="flex border-b border-b-slate-200 h-[64px] justify-center items-center ">
                        <img
                          src="/logo-min.png"
                          alt="Ventia logo minimized"
                          className={`w-7 transition-transform duration-100 ${isCollapsed ? "lg:flex hidden" : "scale-0 hidden"}`}
                        />
                        <img
                          src="/logo.png"
                          alt="Ventia logo"
                          className={`w-[115px] transition-transform duration-100 ${isCollapsed ? "lg:scale-0 lg:hidden" : ""} flex`}
                        />
                      </div>
                    </Link>
                    <header className={`w-full gap-2  ${isCollapsed ? 'px-[7px]' : 'px-1'} flex shrink-0 items-center justify-between h-[55px] border-b border-b-slate-200`}>
                      <div className={`shrink-0 ${!isCollapsed ? 'px-3' : 'px-[4px]'} w-full flex`}>
                        <UserProfile
                          slug={slug}
                          isCollapsedMenu={isCollapsed}
                          activeNotification={countNotification > 0}
                          user={user}
                          userName={`${user?.name ?? ""}  ${user?.lastname ?? ""}`.trim()}
                          companyName={company?.name}
                        />
                      </div>
                    </header>
                    <ButtonToOpenMenu
                      onClick={() => handleCollapsedMenu()}
                      className={`absolute ${isCollapsed ? 'lg:hidden' : 'right-2 top-[19px] lg:flex hidden'} z-[11] `}
                    />
                  </div>
                  <NavBar
                    slug={slug}
                    user={user}
                    handleOpenMenu={() => handleCollapsedMenu()}
                    group={group}
                  />
                </div>
              </aside>
            </div>
            <div className={`lg:z-0 z-30 bg-white ${isSubMenuActive ? 'lg:pl-[68px] ' : ''}`}>
              {
                <div
                  className={`${isTheRolEnabled([ROLES.COMERCIAL, ROLES.SUPER]) && router.pathname === '/' ? `block absolute lg:relative duration-200 ease-in-out bg-white ${openMenuSeller ? 'left-0' : 'lg:left-0 -left-[600px]'}` : 'hidden'}`}
                >
                  <ReusableSubMenu
                    title={'Vendedores'}
                    isActiveGoBack
                    onCloseMenu={() => toggleOpenSeller()}
                    ListOfItems={
                      <>
                        <MenuSellers
                          active={isTheRolEnabled(['comercial', 'super'])}
                        />
                      </>
                    }
                  />
                </div>
              }
              {!!SubMenu && SubMenu}
            </div>
          </div>
          <section className="flex-grow relative flex flex-col">
            <div className={cn("flex-grow flex relative flex-col pt-[50px] lg:pt-0", containerStyle)}>
              {children}
            </div>
          </section>
        </main>
      </div>
      <ChatIntercom active={chatVisible} />
      <NotificationModal
        open={notificationModal}
        setOpen={setNotification}
      />
      <ChangePassworld />
      <Presentation />
      <NotificationsContainer />
    </>
  )
}

export function ButtonToOpenMenu({ right = false, onClick, className = "" }) {
  return (
    <button
      className={cn(
        "text-[#6B7280] hover:text-[#8f95a3] z-20 p-1.5 relative [&>svg]:w-[15px] [&>svg]:h-[15px]",
        className
      )}
      onClick={onClick}
    >
      {right ? <PanelRight strokeWidth={1.5} /> : <PanelLeft strokeWidth={1.5} />}
    </button>
  )
}
