import InformationMedia from "./information-media"
import VideoInsideBubble from "./VideoInsideBubble"

export default function InteractionVideo({
    description,
    media,
    children,
    date,
    channel,
    status
}) {
    return (
        <div
            className={`justify-center flex md:w-[var(--width-bubble-media-type)] flex-col flex-grow`}
            style={{
                padding: children ? 2 : 0
            }}
        >
            <div className="relative">
                <VideoInsideBubble media={media} className="bg-black max-h-[300px] rounded-sm w-full" />
                <InformationMedia
                    channel={channel}
                    date={date}
                    description={description}
                    status={status}
                />
            </div>
            {children}
        </div>
    )
}
