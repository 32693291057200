import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";


const ChatInputContext = createContext<any>({})

export const ChatInputProvider = ({ children }) => {
    const prospectId = useSelector((state: any) => state.crm.contact._id)
    const staticText = useSelector((state: any) => state.crm.staticTexts[prospectId])
    const [textValue, setTextValue] = useState(staticText ?? '')

    return (
        <ChatInputContext.Provider value={{
            textValue,
            setTextValue,
            staticText
        }}>
            {children}
        </ChatInputContext.Provider>
    )
}

export const useChatInputContext = () => {
    const context = useContext(ChatInputContext)

    if (!context) {
        throw new Error('useChatInputContext must be used within ChatInputProvider')
    }

    return context
}