import { fetcher } from "@/helpers/fetcher"
import axios from "axios"
import { getCookie } from "../auth"
const API_NEXT = process.env.API_NEXT


export const getUserTemplates = async () => {
    return axios.get(`${API_NEXT}/settings/templates`, {
        headers: {
            'Authorization': `JWT ${getCookie('token')}`
        }
    })
        .then(res => res.data)
        .catch(err => console.log(err))
}

export const createTemplate = async (data) => {
    return axios.post(`${API_NEXT}/settings/templates`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${getCookie('token')}`
        }
    })
}

export const deleteTemplate = async (templateId) => {
    return axios.delete(`${API_NEXT}/settings/templates/${templateId}`, {
        headers: {
            'Authorization': `JWT ${getCookie('token')}`
        }
    })
}

export const updateTemplate = async (data, templateId) => {
    return axios.patch(`${API_NEXT}/settings/templates/${templateId}`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${getCookie('token')}`
        }
    })
}

export const orderPersonalTemplates = (source: number, destination: number) => {
    return fetcher({
        url: `/settings/templates/order?source=${source}&destination=${destination}`,
        defaultContentType: true,
        method: "PATCH",
        throwError: false
    })
}