import CustomCard from '@/components/ui/custom-card';
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from '@/lib/utils';

export function OptionsChannelResponse({ value, onValueChange }: { value: string | boolean, onValueChange?: (value: string) => void }) {
    return <RadioGroupWithSelectItem
        allValue={[['Continuar secuencia', false], ['Detener secuencia', true]]}
        onValueChange={onValueChange}
        value={value}
    />
}

export function RadioGroupWithSelectItem({
    onValueChange,
    value,
    allValue,
    classNameRadioGroup,
    classNameRadioItem
}: {
    onValueChange: any,
    value: any,
    allValue: Array<[string, any]>,
    classNameRadioGroup?: string,
    classNameRadioItem?: string
}) {
    return <RadioGroup
        onValueChange={onValueChange}
        value={value as string}
        className={cn('flex items-center gap-2', classNameRadioGroup)}
    >
        {allValue.map(([label, code]) => (
            <RadioGroupCardWithItem
                label={label}
                key={code + ""}
                classNameRadioCard={classNameRadioItem}
                value={code}
            />
        ))}
    </RadioGroup>
}

export function RadioGroupCardWithItem({
    label,
    value,
    classNameRadioCard,
    classNameContent
}) {
    const id = label.concat(JSON.stringify(value)).concat(`${Math.random() * 5000000}`)

    return <div className={cn('relative w-auto cursor-pointer', classNameContent)}>
        <label htmlFor={id} className='w-full h-full cursor-pointer absolute top-0 left-0'></label>
        <CustomCard className={cn('flex   py-2  px-3 items-center gap-2 rounded-lg w-max', classNameRadioCard)}>
            <RadioGroupItem value={value} id={id} className='bg-primary md:w-[18px] w-[14px] h-[14px] md:h-[18px]' classNameCircle='fill-white' />
            <p className='cursor-pointer font-medium sm:text-[13px] text-[11px]'>{label}</p>
        </CustomCard>
    </div>


}