import { Feature, isFeatureEnabled } from "@/helpers/featureFlagHelpers";
import { setViewCompanyGroup } from "@/redux/slices/crm";
import { Building2, Eye, Plus } from "lucide-react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import BadgePill from "./BadgePill";

const B2bPill = ({ contact, onUpdateContact }: { contact: any, onUpdateContact: () => void }) => {

    const enableBusinessSalesModel = isFeatureEnabled(Feature.ENABLE_BUSINESS_SALES_MODEL)

    const dispatch = useDispatch();
    const onViewCompanyGroup = (id: string) => dispatch(setViewCompanyGroup(id));

    const companyGroups = useMemo(() => {
        return contact?.affiliatedCompanyGroup?.map((company) => {
            const { companyGroup } = company || {};
            if (!companyGroup) return null;
            const { businessName, _id } = companyGroup || {};
            if (!businessName || !_id) return null;
            return {
                companyName: businessName,
                companyGroupId: _id,
                role: company.role
            }
        }).filter((companyGroup) => companyGroup)
    }, [contact]);

    if (!enableBusinessSalesModel) return null;

    if (!companyGroups || !companyGroups.length) return (
        <button onClick={onUpdateContact}>
            <BadgePill>
                <p>
                    <Plus size={14} className="inline mr-1" />
                    Agregar empresa
                </p>
            </BadgePill>
        </button>
    );

    return (
        companyGroups.map((companyGroup) => (
            <button onClick={() => onViewCompanyGroup(companyGroup.companyGroupId)}>
                <BadgePill className="group">
                    <div className="flex flex-row items-center gap-1">
                        <Building2 size={12} className="group-hover:hidden block" />
                        <Eye size={12} className="group-hover:block hidden" />
                        <p>{companyGroup.companyName} {companyGroup.role ? `- ${companyGroup.role}` : ''}</p>
                    </div>
                </BadgePill>
            </button>
        ))
    )
}

export default B2bPill