import { cn } from "@/lib/utils";
import { Avatar } from "../ui/avatar";
import { CardTitle } from "../ui/card";

export default function QuestionTitle({ question, step }) {
    return <div className="flex items-center mb-2 gap-2">
        {
            step && <Avatar className="md:w-[19px] md:h-[19px] w-[18px] h-[18px] bg-primary md:text-[13px] text-[12px] font-semibold text-white flex items-center justify-center">
                {step}
            </Avatar>
        }
        <TitleQuestion>
            {question}
        </TitleQuestion>
    </div>
}

export function TitleQuestion({ children, className}: { children: JSX.Element | string | null, className?: string }) {
    return <CardTitle className={cn("md:text-[15px] font-medium text-[14px]", className)}>
        {children}
    </CardTitle>
}