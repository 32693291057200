import useGetContacts from "@/hooks/crm/useGetContacts"
import { ContactAvatar } from "../../Prospect"
import LoaderMultipleSkeleton from "./loader-multiple-skeleton"

export default function ContactField({
    title,
    values,
    code
}) {
    const valueToArray = [values].flat()

    const { contacts, isLoading } = useGetContacts({
        enabled: valueToArray.length > 0,
        queryKey: [`contacts-list-${code}`],
        externalSearchParams: {
            _id: valueToArray,
            onCompany: true
        }
    })

    if (valueToArray.length == 0) return null;

    return (
        <div className="flex flex-col gap-1">
            <p className="text-gray-800 text-[14px]">
                <b className="font-medium">{title}</b>
            </p>
            <ul className="flex flex-col gap-2">
                {
                    isLoading
                        ? <LoaderMultipleSkeleton />
                        : contacts.flat().map(({ firstName, statusTag, lastName, _id: contactId }) => {
                            return (
                                <li key={contactId}>
                                    <div className="flex items-center gap-2">
                                        <ContactAvatar
                                            firstName={firstName}
                                            lastName={lastName}
                                            className="w-[20px] h-[20px]"
                                            statusTag={statusTag}
                                        />
                                        <h4 className="text-[12px] font-medium text-gray-700">
                                            {firstName} {lastName}
                                        </h4>
                                    </div>
                                </li>
                            )
                        })
                }
            </ul>
        </div>
    )
}

