import { Avatar } from "@/components/ui/avatar"
import { Badge } from "@/components/ui/badge"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import {
    CheckIcon,
    PlusCircle,
    XIcon
} from "lucide-react"
import { ChangeEvent, useRef, useState } from "react"
import EmailFieldContent from "./EmailFieldContent"

import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList
} from "@/components/ui/command"
import { colorsByAlphabet } from "@/helpers/getColorsFromTheAlphabet"
import { schemaSendTransmiter } from "./index"

export type TypesInputEmail = 'cc' | 'to' | 'bcc'

type EmailType = { name: string, email: string }
type WithCopyActive = {
    cco: boolean
    cc: boolean
}

interface Props {
    contextForm: any
    type: TypesInputEmail
    label: string
    prospectEmails?: any
    handleSelectedEmail?: (type: TypesInputEmail, value: string) => void
    emails?: Array<EmailType>
    inputValue: string,
    onChangeInputValue: (value: string) => void,
    onChangeWithCopyActive?: (value: WithCopyActive) => void
    withCopyActive?: WithCopyActive
}

export default function InputEmail({
    contextForm,
    type,
    label,
    prospectEmails,
    emails = [],
    handleSelectedEmail,
    inputValue,
    onChangeInputValue,
    onChangeWithCopyActive,
    withCopyActive = {
        cc: false,
        cco: false
    }
}: Props) {
    const [focus, setFocus] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement | null>(null)

    const { cc, cco } = withCopyActive

    const handleDeleteInput = (e) => {
        if (e.code == "Semicolon" || e.code == 'Enter' || e.code === 'Tab') {
            e.stopPropagation()
            e.preventDefault()
            const deleteLastValue = inputValue.substring(0, inputValue.length)
            const emailToAdd = { email: deleteLastValue, name: '' }

            if (schemaSendTransmiter.safeParse(emailToAdd).success) {
                contextForm.setValue(type, [...(contextForm?.getValues(type) ?? []), emailToAdd])
                onChangeInputValue('')
            }
        }

        if (e.code === "Backspace" && inputValue === '') {
            const contentEmailsAvailable = [...(contextForm?.getValues(type) ?? [])]
            const deleteEmailFn = () => {
                contentEmailsAvailable.splice(contentEmailsAvailable.length - 1, 1)
                contextForm.setValue(type, contentEmailsAvailable)
            }

            if (type !== 'to') {
                deleteEmailFn()
            } else if (contentEmailsAvailable && contentEmailsAvailable.length > 1) {
                deleteEmailFn()
            }
        }
    }

    const onHandleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        onChangeInputValue(value)
    }

    const handleBlur = (e) => {
        e.stopPropagation()
        e.preventDefault()

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const onHandleActiveCc = (name: 'cc' | 'cco') => () => {
        if (!withCopyActive || !onChangeWithCopyActive) return;

        onChangeWithCopyActive({
            ...withCopyActive,
            [name]: false
        })
    }

    const onHandleDeleteEmail = (index: number) => {
        const allEmails = [...emails]
        allEmails.splice(index, 1)
        contextForm.setValue(type, allEmails)
    }

    return (
        <EmailFieldContent label={label} classNameContenChildren={`w-full cursor-text flex border-b ${focus ? 'border-b-primary' : 'border-b-gray-200'} duration-200 h-full  flex-grow`} onMouseDown={handleBlur}>
            <div className="flex items-start gap-2 flex-grow h-full w-full">
                <div className="flex items-center gap-2 flex-wrap  flex-grow h-full w-full">
                    {
                        emails.map((emailData, index) => (
                            <EmailItem
                                emailData={emailData}
                                key={`${emailData.email}-${index}`}
                                hiddenButton={emails.length == 1}
                                onClickButton={() => onHandleDeleteEmail(index)}
                            />
                        ))
                    }
                    <input
                        className="outline-none text-[14.5px] font-medium text-gray-800 flex-1 focus:outline-none border-none flex-grow h-full"
                        onChange={onHandleChangeValue}
                        ref={inputRef}
                        onKeyDown={handleDeleteInput}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        value={inputValue}
                        type="email"
                    />
                </div>
                {
                    (type === 'to' && (cc || cco)) && (
                        <div className="h-[100%] relative">
                            <div className="h-full flex items-center gap-1 ">
                                {
                                    prospectEmails.length > 1 && (
                                        <Popover>
                                            <PopoverTrigger asChild>
                                                <button>
                                                    <Badge className="bg-gray-50 cursor-pointer hover:bg-gray-200 duration-150 text-[12px] border-gray-300 text-gray-500 rounded-[7px]">
                                                        <PlusCircle className="mr-1 h-3 w-3" />
                                                        Emails
                                                    </Badge>
                                                </button>
                                            </PopoverTrigger>
                                            <PopoverContent className="w-64 p-1">
                                                <Command className="p-0">
                                                    <CommandList>
                                                        <CommandGroup className="p-0">
                                                            {
                                                                prospectEmails.map((email, inx) => {
                                                                    const isSelected = emails && emails.map(email => email.email).includes(email)
                                                                    return <CommandItem
                                                                        onSelect={() => handleSelectedEmail && handleSelectedEmail(type, email)}
                                                                        key={`${email.email}${inx}`}
                                                                    >
                                                                        <div
                                                                            className={`mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary ${isSelected
                                                                                ? "bg-primary text-primary-foreground"
                                                                                : "opacity-50 [&_svg]:invisible"}`}
                                                                        >
                                                                            <CheckIcon size={16} />
                                                                        </div>
                                                                        <span>{email}</span>
                                                                    </CommandItem>
                                                                })
                                                            }
                                                        </CommandGroup>
                                                    </CommandList>
                                                </Command>
                                            </PopoverContent>
                                        </Popover>
                                    )
                                }
                                <CCItem
                                    label="CC"
                                    active={cc}
                                    onClick={onHandleActiveCc('cc')}
                                />
                                <CCItem
                                    label="CCO"
                                    active={cco}
                                    onClick={onHandleActiveCc('cco')}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        </EmailFieldContent>
    )
}

interface EmailItemProps {
    emailData: EmailType,
    hiddenButton: boolean
    onClickButton?: () => void
}

export function EmailItem({
    emailData,
    onClickButton,
    hiddenButton = false
}: EmailItemProps) {
    const { email = [] } = emailData || {}
    const firstLetter = email[0] || ''
    const avatarColor = colorsByAlphabet[String(firstLetter).toUpperCase()]

    return (
        <Badge className="px-[3px] py-[3px] pr-1 hover:bg-gray-100 hover:text-gray-700 duration-150 bg-gray-50 border-gray-200 text-gray-800  text-[12px] flex items-center gap-1">
            <Avatar
                style={{
                    backgroundColor: avatarColor || '#0080FF'
                }}
                className="flex uppercase items-center text-[10px] justify-center text-white font-medium w-[19px] h-[19px]">
                {firstLetter}
            </Avatar>
            <p>{email}</p>
            {
                !hiddenButton && (
                    <button
                        type={'button'}
                        onClick={() => onClickButton && onClickButton()}
                        className="bg-white rounded-full shrink-0 mr-1 flex items-center justify-center w-[15px] h-[15px]"
                    >
                        <XIcon size={13} />
                    </button>
                )
            }
        </Badge>
    )
}

interface CCItemProps {
    onClick: () => void
    active: boolean
    label: string
}

export function CCItem({
    onClick,
    active = true,
    label
}: CCItemProps) {
    if (!active) return null;
    return (
        <button onClick={() => onClick()}>
            <Badge className="bg-gray-50 cursor-pointer hover:bg-gray-200 duration-150 text-[12px] border-gray-300 text-gray-500 rounded-[7px]">
                {label}
            </Badge>
        </button>
    )
}

