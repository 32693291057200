import { useState } from "react";
import Portal from "./Portal";
import StartStep from "./steps/StartStep";
import VideoStep from './steps/VideoStep';

enum Steps {
    START = 'start',
    VIDEO = 'video'
}

export default function PresentationOfNewVentia() {
    const [step, setStep] = useState(Steps.START)
    const handleNextStep = (step) => () => setStep(step)

    return <Portal>
        <div className="w-full h-[100vh] md:p-0 p-2 flex items-center justify-center" style={{
            background: `linear-gradient(150deg, rgb(217,236,255,100), rgba(0,81,255, 0) 70.71%),
            linear-gradient(90deg, rgb(0,128,255,0.2), rgb(255,255,255) 100.71%),
            linear-gradient(336deg, rgb(0,255,255), rgb(0,128,255) 70.71%)`
        }}>
            <div className="bg-white md:w-[72%] w-full md:max-w-[1500px] flex rounded-[18px] shadow-sm h-full md:max-h-[600px] md:h-[80vh]">
                {
                    step === Steps.START && <StartStep handleNextStep={handleNextStep(Steps.VIDEO)} />
                }
                {
                    step === Steps.VIDEO && <VideoStep goBack={handleNextStep(Steps.START)} />
                }
            </div>
        </div>
    </Portal>
}
