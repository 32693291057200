import { ReactNode } from "react";
import InformationLabel from "./InformationLabel";
import InformationValue from "./InformationValue";
import { cn } from '../../../lib/utils';

interface InformationItemProps {
    title: string,
    value?: string | number,
    customValueElement?: ReactNode,
    classNameContainer?: string
}

export default function InformationItem({
    title,
    value,
    customValueElement,
    classNameContainer
}: InformationItemProps) {
    if (!customValueElement && !value) return null;

    return <li className={cn('w-full flex gap-4 items-center justify-between', classNameContainer)}>
        <InformationLabel title={title} />
        {customValueElement || <InformationValue value={value as string} />}
    </li>
}