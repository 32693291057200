import { getDashboardChartByUser } from "@/actions/company/charts"
import { setDashboardCharts, setLoadingFolderList } from "@/redux/slices/config"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

export default function useGetDashboardChartByUser() {
    const dashboardCharts = useSelector((state: any) => state.config.dashboardCharts)
    const dispatch = useDispatch()

    const { data, ...rest } = useQuery({
        queryKey: ['user-dashboard-chart'],
        queryFn: () => getDashboardChartByUser(),
        enabled: dashboardCharts.length === 0
    })

    useEffect(() => {
        dispatch(setLoadingFolderList(rest.isLoading))
    }, [rest.isLoading])

    const handleSortCharts = (charts: any[] = []) => {
        if (charts.length < 2) return charts

        const pivotIndex = Math.ceil(charts.length / 2)
        const pivotElement = charts[pivotIndex]

        let right: any[] = []
        let left: any[] = []

        for (let i = 0; i < charts.length; i++) {
            if (i === pivotIndex) continue;
            const currentElement = charts[i]
            // if (!currentElement.show) continue;

            if (currentElement.index < pivotElement.index) {
                left.push(currentElement)
            } else {
                right.push(currentElement)
            }
        }

        return [
            ...handleSortCharts(left),
            pivotElement,
            ...handleSortCharts(right)
        ]
    }

    useEffect(() => {
        const chartsConfig = data?.dashboardCharts
        if (!chartsConfig) return;

        let folders = _.cloneDeep(chartsConfig)

        for (let i = 0; i < folders.length; i++) {
            const currentCharts = folders[i].charts
            folders[i].charts = handleSortCharts(currentCharts)
        }

        dispatch(setDashboardCharts(folders))
    }, [data])

    return {
        dashboardCharts,
        ...rest
    }
}
