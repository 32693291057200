import AlertError from "@/components/AlertError"
import { BtnNextStep } from "@/components/buttons/BtnNextStep"
import { Button } from "@/components/ui/button"
import { CustomFormField } from "@/components/ui/custom-form-field"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog"
import { Form } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { InternalServerError } from "@/constants/errores"
import { SectorMessageErrorByType } from "@/constants/errores/descriptions"
import { ModalPropsBase } from '@/interfaces/Interface'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useForm, UseFormReturn } from "react-hook-form"
import { z } from "zod"
import useCreateSector from "./utils/hooks/useCreateSector"

interface ModalAddSectorsProps extends ModalPropsBase {
    companyId: string,
    onSuccess?: (value: any) => void
}

export const sectorSchema = z.object({
    name: z.string().min(1, {
        message: 'El nombre debe de contener por lo menos 1 caracter'
    }).max(60, {
        message: 'El nombre no debe de superar los 60 caracteres de longitud'
    })
})

export type SectorSchemaType = z.infer<typeof sectorSchema>


export default function ModalAddSectors({
    open,
    onOpenChange,
    companyId,
    onSuccess
}: ModalAddSectorsProps) {
    const [messageError, setMessageError] = useState<string>('')
    const contextForm = useForm<SectorSchemaType>({
        resolver: zodResolver(sectorSchema),
        defaultValues: {
            name: "",
        }
    })

    const queryClient = useQueryClient()
    const isValidForm = contextForm.formState.isValid

    const onSuccessCreate = (value: Record<string, any> = {}) => {
        queryClient.invalidateQueries({ queryKey: ['sectors-of-company'] })
        onOpenChange(false)
        onSuccess && onSuccess(value.sector || {})
    }

    const { isPending, onHandleSubmit } = useCreateSector({
        companyId,
        onSuccess: onSuccessCreate,
        onError: (error: any) => {
            const errorCode = error?.body?.errorCode || InternalServerError
            setMessageError(SectorMessageErrorByType[errorCode])
        }
    })

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[425px] p-0">
                <DialogHeader className="p-5 pb-0">
                    <DialogTitle>
                        Crear sector
                    </DialogTitle>
                    <DialogDescription>
                        Ingrese el nombre del sector donde podrá agrupar a los contactos de la empresa.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-2 px-5">
                    <AlertError
                        title="Se ha producido un error al querer crear el sector"
                        description={messageError}
                    />
                    <CreateSectorForm
                        contextForm={contextForm}
                        onHandleSubmit={(values) => {
                            setMessageError('')
                            onHandleSubmit(values)
                        }}
                    />
                </div>
                <DialogFooter className="bg-gray-100 border-t border-t-gray-300 py-3 px-5">
                    <Button
                        variant={'outline-primary'}
                        size="xs"
                        onClick={() => onOpenChange(false)}
                    >
                        Cancelar
                    </Button>
                    <BtnNextStep
                        disabled={!isValidForm}
                        variant={'california'}
                        form="formAddSector"
                        loading={isPending}
                        type="submit"
                        size={'xs'}
                        hiddenArrow
                    >
                        Guardar
                    </BtnNextStep>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

interface CreateSectorFormProps {
    contextForm: UseFormReturn<SectorSchemaType>,
    onHandleSubmit: (values: SectorSchemaType) => void
}

export function CreateSectorForm({
    onHandleSubmit,
    contextForm
}: CreateSectorFormProps) {
    return (
        <Form {...contextForm}>
            <form
                id={'formAddSector'}
                onSubmit={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    contextForm.handleSubmit(onHandleSubmit)(e)
                }}
            >
                <CustomFormField
                    control={contextForm.control}
                    label="Nombre del sector"
                    name="name"
                    isRequired
                    fnElement={({ field }) => (
                        <Input {...field} placeholder="Marketing..." autoComplete="off" />
                    )}
                />
            </form>
        </Form>
    )
}