import { Separator } from "@/components/ui/separator";
import { ProductLine as ProductLineInterface } from "@/interfaces/Interface";
import InformationItem from "./InformationItem";

interface ProductLineProps {
    product: ProductLineInterface,
    isLastElement?: boolean,
    index: number
};

export default function ProductLine({
    isLastElement,
    product,
    index
}: ProductLineProps) {

    const fields = [
        { title: 'Nombre:', value: product.name },
        { title: 'Código:', value: product.code },
        { title: 'Categoría:', value: product.category },
        { title: 'Cantidad:', value: product.quantity },
        { title: 'Valor:', value: product.value },
    ];

    return (
        <>
            <li className="w-full">
                <div className="w-full">
                    <h5 className="text-[14px] font-medium text-gray-700 mb-2">
                        {index + 1}. Producto:
                    </h5>
                    <div className="flex flex-col w-full gap-0.5">
                        {
                            (product.productImage) && (
                                <div className="w-[100px] h-[60px] overflow-hidden rounded-sm relative border mb-1">
                                    <img
                                        className="w-full h-full object-cover"
                                        src={product.productImage}
                                    />
                                </div>
                            )
                        }
                        {
                            fields.map(({ title, value }) => (
                                <InformationItem
                                    title={title}
                                    value={value}
                                    key={title}
                                />
                            ))
                        }
                    </div>
                </div>
                {!isLastElement && <Separator className="mt-2" />}
            </li>
        </>
    )
};