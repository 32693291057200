import { ContactService } from "@/actions/crm/contact-controller";
import { CommandGroup } from "@/components/ui/command";
import PupoverWithSelectItem, { CustomCommandItem } from "@/components/ui/pupover-with-select-item";
import { useToast } from "@/components/ui/use-toast";
import { useMutation } from "@tanstack/react-query";
import { ChevronDown, Loader2, Trash2 } from "lucide-react";
import { useMemo, useState } from "react";
import { Badge } from "../../ui/badge";

interface Props {
    field: any | null | undefined
    contactId: string | null | undefined
    currentValue: string | null | undefined
}

const AdditionalDataPill = ({
    field,
    contactId,
    currentValue
}: Props) => {
    const { toast } = useToast()
    const [searchValue, setSearchValue] = useState("")
    const [value, setValue] = useState(currentValue)
    const fieldValues = useMemo(() => field?.options || [], [field])

    const { mutate: modifyAdditionalData, isPending: isLoadingModify } = useMutation({
        mutationFn: () => ContactService.modifyAdditionalDataField({ id: contactId, field_key: field.code, value }),
        onError: () => {
            setValue(currentValue)
            toast({
                title: 'Error al modificar el campo',
                description: 'Intenta nuevamente. Si el problema persiste, contacta a soporte.',
                variant: 'destructive',
                duration: 2000
            })
        }
    })

    const handleChangeAdditionalDataField = (value: string) => {
        setValue(value)
        modifyAdditionalData()
    }

    return <PupoverWithSelectItem
        handleSearch={(value: string) => setSearchValue(value)}
        searchValue={searchValue}
        align={'center'}
        emptyMessage={`Campo ${field?.name}`}
        placeholder='Buscar...'
        Trigger={
            <button>
                <Badge variant={'outline'} className="relative z-[1] bg-white">
                    <div className="flex items-center gap-2 py-1">
                        <div className="flex items-center shrink-0 gap-1">
                            <span>{value || `${field?.name}`}</span>
                        </div>
                        {
                            isLoadingModify ?
                                <Loader2 className="w-3 h-3 animate-spin text-primary" />
                                : <span>
                                    <ChevronDown size={13} className="transition-transform" />
                                </span>
                        }
                    </div>
                </Badge>
            </button>
        }
        CommandListContent={
            <CommandGroup className="px-0">
                {value && !field?.required && (
                    <CustomCommandItem
                        itemLeft={
                            <span>
                                <Trash2 size={14} />
                            </span>
                        }
                        onChange={() => setValue('')}
                        label={'Eliminar valor'}
                        value={'delete-status-tag-button-99234'}
                        isChecked={false}
                    />
                )}
                {
                    fieldValues.filter((field) => field.toLowerCase().includes(searchValue.toLowerCase()))
                        .map((fieldValue, index) => (
                            <CustomCommandItem
                                value={fieldValue}
                                onChange={() => handleChangeAdditionalDataField(fieldValue)}
                                label={fieldValue}
                                key={index}
                                isChecked={fieldValue == value}
                            />
                        ))
                }
            </CommandGroup>
        }
    />
}

export default AdditionalDataPill