
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

export default function SectionTitle({ children, className }: PropsWithChildren & { className?: string }) {
    return (
        <h4 className={cn('text-gray-700 font-medium mb-1 text-[15.8px]', className)}>
            {children}
        </h4>
    )
}