import useDebounce from "@/components/core/functions/useDebouce";
import { Avatar } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import InputWithSelect from "@/components/ui/input-with-select";
import useGetCompanyGroups, { IPreviewCompanyGroup } from "@/hooks/crm/useGetCompanyGroups";
import { XIcon } from "lucide-react";
import { useMemo, useState } from "react";
import Link from 'next/link';
import { Routes } from "@/routes";

const contructorKeyMap = {
    'notEqualTo': 'not',
    'greaterThan': 'gt',
    'lessThan': 'lt'
}

export default function MultipleCompanyGroupField({
    onChangeValue,
    value,
    multiple = true,
    disabled,
    field
}) {
    const [searchValue, setSearchValue] = useState<string>('');
    const searchWithDebounce = useDebounce(searchValue, 500)

    const { optionsFilters = [] } = field || {}

    const externalSearchParams = useMemo(() => {
        const params = {
            search: searchWithDebounce,
            onCompany: true,
            limit: 35,
            page: 1
        }

        for (let { operator, value, key } of optionsFilters) {
            if (!value || value === 'all' || Array.isArray(value) && value[0] == 'all') continue;
            const contructorKey = `${contructorKeyMap[operator] || ''}additionalData.${key}`
            params[contructorKey] = value
        }

        return params
    }, [field, searchWithDebounce, optionsFilters])

    const { companyGroups, isLoading } = useGetCompanyGroups({
        externalSearchParams
    })

    const localValue = value ? [value].flat() : []
    const valuesSelected = localValue.length
    const companyGroupsList = companyGroups

    const getFormatCompanyGroup = (companyGroup: IPreviewCompanyGroup) => {
        const { businessName } = companyGroup || {}
        return {
            value: companyGroup,
            label: businessName
        }
    }
    const onHandleChangeValue = (values: IPreviewCompanyGroup[]) => {
        const newValues = [...localValue, ...values];
        const uniqueValues = Array.from(new Map(newValues.map(item => [item._id, item])).values());
        onChangeValue(!multiple ? values[0] : uniqueValues);
    }

    const onHandleDeleteValue = (inx: number) => {
        const allValue = [...localValue]
        allValue.splice(inx, 1);
        onChangeValue(multiple ? allValue : null)
    }

    return (
        <div className={'grid gap-2'}>
            <InputWithSelect
                multiple
                strict={true}
                value={[]}
                disabled={disabled}
                isLoading={isLoading}
                placeholder="Buscar empresa.."
                options={companyGroupsList.map(getFormatCompanyGroup)}
                onChangeInputSearch={(value) => setSearchValue(value)}
                emptyIndicator={'No se encontró resultado para la búsqueda...'}
                onChangeValue={(values) => onHandleChangeValue(values as any[])}
                badgeClassName="hover:bg-blue-50/90 hover:text-blue-700 bg-blue-50 text-blue-800"
            />
            {
                localValue.length > 0 && (
                    <ul className="grid gap-3 px-2">
                        {
                            multiple && (
                                <li>
                                    <h2 className="text-[13px] text-gray-500">
                                        {valuesSelected} {valuesSelected > 1 ? 'Empresa seleccionada' : 'Empresas seleccionadas'}
                                    </h2>
                                </li>
                            )
                        }
                        {
                            localValue.map(({ _id: companyGroupId, displayName, businessName, taxCode }, index) => {
                                const nameToUse = displayName || businessName || taxCode
                                return (
                                    <li key={companyGroupId}>
                                        <div className={`flex w-full justify-between ${index === localValue.length - 1 ? 'mb-2' : ''}`}>
                                            <Link href={`${Routes.BUSSINES}/${companyGroupId}`} className="hover:text-blue-400 text-gray-700">
                                                <div className="flex items-center gap-2">
                                                    {
                                                        nameToUse && (
                                                            <Avatar className="w-[25px] h-[25px] bg-primary flex items-center justify-center">
                                                                <span className="font-medium text-[13px] text-white">
                                                                    {String(nameToUse[0] || '').toUpperCase()}
                                                                </span>
                                                            </Avatar>
                                                        )
                                                    }
                                                    <h4 className="text-[14px] font-medium ">
                                                        {nameToUse}
                                                    </h4>
                                                </div>
                                            </Link>
                                            {
                                                !disabled && (
                                                    <Button
                                                        size={'icon'}
                                                        variant={'ghost'}
                                                        className="w-[26px] h-[26px]"
                                                        onClick={() => onHandleDeleteValue(index)}
                                                    >
                                                        <XIcon size={14} />
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                )
            }
        </div>
    )
}