import { Dialog, DialogContent } from '@/components/ui/dialog';
import { ModalPropsBase } from '@/interfaces/Interface';

interface ModalViewIframeProps extends ModalPropsBase {
    contentUrl: string
}

export default function ModalViewIframe({ contentUrl, onOpenChange, open }: ModalViewIframeProps) {
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="bg-none p-0 sm:max-w-max  flex items-center justify-center w-max  shadow-none " style={{ background: 'transparent' }}>
                <div className="rounded-md h-[85vh] w-[91vw] overflow-hidden">
                    <iframe src={contentUrl} className="w-full h-full" title="YouTube  video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
                    </iframe>
                </div>
            </DialogContent>
        </Dialog>
    )
};