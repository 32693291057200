import { fetcher } from "@/helpers/fetcher"
import { ISharedContactsInbox } from "@/interfaces/Interface"

const getSharedInboxex = (timeZone: string = '') => {
    return fetcher<{ sharedInboxes: ISharedContactsInbox[] }>({
        url: `/user/sharedInbox/list?timeZone=${timeZone}`,
        defaultContentType: true,
    })
}

const getSharedInboxById = (id: string, timeZone: string = '') => {
    return fetcher<{ sharedInbox: ISharedContactsInbox }>({
        url: `/user/sharedInbox/${id}?timeZone=${timeZone}`,
        defaultContentType: true,
    })
}

const isActiveSharedInbox = (timeZone: string = '') => {
    return fetcher<{ active: boolean, activeNow: boolean }>({
        url: `/user/sharedInbox/active?timeZone=${timeZone}`,
        defaultContentType: true,
    })
}

export { getSharedInboxById, getSharedInboxex, isActiveSharedInbox }

