import {
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import dayjs from "dayjs"
import "dayjs/locale/es"
import { Clock } from "../Icons/Crm"

export function HeaderReminderCard({ isNotCreated, via, date, type }) {
  return (
    <CardHeader
      className={`w-full ${isNotCreated ? "bg-white" : "bg-[#E5E7EB]"
        } py-1.5 px-2`}
    >
      <div
        className={`flex ${isNotCreated ? "text-red-500 py-1" : "text-slate-600"
          } items-center text-center justify-center sm:gap-4 gap-3 px-4`}
      >
        <Clock
          className={`${isNotCreated ? "stroke-red-500" : "stroke-slate-600"
            } sm:w-[15px] shrink-0 sm:h-[15px] w-3 h-3`}
        />
        <CardTitle
          className={`md:text-[14px]  text-[12px] ${!isNotCreated ? "text-slate-700" : ""}`}
        >
          {type === "created"
            ? "Recordatorio agendado"
            : `Recordatorio ${via === "server" ? "vencido" : `cancelado`
            }: ${dayjs(date).format("dddd DD/MM/YY, HH:mm")}`}
        </CardTitle>
        <span className="md:text-[14px]  text-[12px] font-medium">
          {dayjs(date).format("HH:mm")}
        </span>
      </div>
    </CardHeader>
  )
}


