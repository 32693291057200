import { setFilterInteraction, setOpenMenuInformation, setSectionInformationContact } from '@/redux/slices/crm'
import { ListFilter } from 'lucide-react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BadgePill from './BadgePill'

export default function FiltersPill() {
    const interactionFilters = useSelector((state: any) => state.crm.activeInteractionFilters)
    const filtersList = useMemo(() => Object.entries(interactionFilters), [interactionFilters])
    const filtersCode = filtersList.map(arr => arr[1]).flat()
    if (filtersCode.length === 0) return null

    const dispatch = useDispatch()

    const handleDeleteFilter = () => dispatch(setFilterInteraction({}))

    const openFilterMenu = () => {
        dispatch(setOpenMenuInformation(true))
        dispatch(setSectionInformationContact('filters'))
    }

    return (
        <>
            <BadgePill
                onClickBtn={handleDeleteFilter}
                className='cursor-pointer'
                onClick={openFilterMenu}
                withActionButton={true
                }
            >
                <ListFilter size={16} className='mr-[2px]' />
                Filtros
            </BadgePill>
        </>
    )
}

// import React, { useMemo } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import BadgePill from './BadgePill'
// import { ListFilter } from 'lucide-react'
// import { setFilterInteraction } from '@/redux/slices/crm'
// import { filtersType } from '../Prospect/FiltersSection'

// export default function FiltersPill() {
//     const interactionFilters = useSelector((state: any) => state.crm.activeInteractionFilters)
//     const filtersList = useMemo(() => Object.entries(interactionFilters), [interactionFilters])

//     const dispatch = useDispatch()

//     const handleDeleteFilter = (key: string, index: number) => () => {
//         let filterType = [...interactionFilters[key]]
//         filterType.splice(index, 1)

//         const updateFilters = { ...interactionFilters, [key]: filterType }
//         dispatch(setFilterInteraction(updateFilters))
//     }

//     return (
//         <>
//             {
//                 filtersList.map(([key, value]: [string, string[]]) => (
//                     <>
//                         {
//                             value.map((type: string, index: number) => (
//                                 <BadgePill
//                                     withActionButton={true}
//                                     key={`${type}-${key}`}
//                                     onClick={handleDeleteFilter(key, index)}
//                                 >
//                                     <ListFilter size={16} className='mr-[2px]' />
//                                     {filtersType[type]}
//                                 </BadgePill>
//                             ))
//                         }
//                     </>
//                 ))
//             }
//         </>
//     )
// }
