import { DoorOpen, Note } from "@/components/crm/Bubble/Icons/Crm"
import { Facebook, WhatsApp } from "@mui/icons-material"

export const channelConfig = {
  whatsapp: {
    backgroundHeader: "#22C55E ",
    backgroundContent: "#bbf7d0",
    icon: <WhatsApp />,
    label: "Nota de whatsapp",
  },
  facebook: {
    backgroundHeader: "#4b99ff",
    backgroundContent: "#d1e5ff",
    label: "Nota de facebook",
    icon: <Facebook />,
  },
  visit: {
    backgroundHeader: "#67E8F9",
    backgroundContent: "#CFFAFE",
    label: "Visita",
    icon: <DoorOpen className="w-[19px] h-[19px]" />,
  },
  default: {
    backgroundHeader: "#FDE047",
    backgroundContent: "#FEF3C7",
    icon: <Note />,
    label: "Nota",
  },
}
