

import { Skeleton } from '@/components/ui/skeleton'
import ContactSkeleton from '../ContactSkeleton'

export default function ContactsAndSectorLoader() {
    return (
        <div className="mt-5">
            <Skeleton className="w-[130px] h-3 rounded-md mb-4" />
            <div className="flex flex-col gap-5">
                <div className="flex items-center justify-between">
                    <Skeleton className="w-[115px] h-3 rounded-md" />
                    <Skeleton className="w-[100px] h-3 rounded-md" />
                </div>
                <ul className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    {Array.from({ length: 3 }).map((__, inx) => <ContactSkeleton key={inx} />)}
                </ul>
            </div>
        </div>
    )
}
