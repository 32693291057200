import QuestionTitle from "@/components/QuestionTitle"
import { ItemField } from "@/components/grupos/notification/DialogAddNewNotification"
import { FormField } from "@/components/ui/form"
import { Textarea } from "@/components/ui/textarea"
import { optionsForRecorder } from "@/constants/modalReminder"
import ButtonReminderOption from "./ButtonReminderOption"
import { defaultValues } from "./constant"

export default function StepOne({ contextForm }: any) {

    return <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-1">
            <QuestionTitle
                question={'¿Qué acción querés recordar?'}
                step={1} />
            <FormField
                name="action"
                control={contextForm.control}
                render={({ field }) => (
                    <ItemField>
                        <div className="grid grid-cols-2 md:gap-3 gap-2">
                            {
                                optionsForRecorder.map(({ icon, label, actionCode },inx) => (
                                    <ButtonReminderOption
                                        key={label}
                                        onAction={() => field.onChange(actionCode === field.value.code
                                            ? defaultValues.action
                                            : { code: actionCode, label: label })}
                                        className={`${actionCode === field.value.code ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''} ${inx === optionsForRecorder.length-1 ? 'md:col-span-1 col-span-2' : ''}`}>
                                        {icon}
                                        {label}
                                    </ButtonReminderOption>
                                ))
                            }
                        </div>
                    </ItemField>
                )}
            />
        </div>
        <div className="flex flex-col gap-1">
            <QuestionTitle
                question={'Escribe un comentario o aclaración sobre el recordatorio'}
                step={2} />
            <FormField
                name="comment"
                control={contextForm.control}
                render={({ field }) => (
                    <ItemField>
                        <Textarea
                            {...field}
                            placeholder="Escribe aquí..."
                            className="resize-none bg-neutral-100 border-none min-h-[140px]" />
                    </ItemField>
                )} />
        </div>
    </div>
}