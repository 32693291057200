import { Button } from '@/components/ui/button'
import { Form, FormField } from '@/components/ui/form'
import { Loader2, SendHorizontal, X } from 'lucide-react'
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'
import { ItemField } from '../grupos/notification/DialogAddNewNotification'
import SimpleTooltip from '../ui/simple-tooltip'
import dynamic from 'next/dynamic'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CardTitle } from '../ui/card'
import { ICommentReply } from '@/actions/training'
import { IAccountManager } from '@/models/AccountManager'
import { DivToSetInnerHtml } from '../ui/div-innerhtml'
const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],

        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['link', 'image', 'video', 'icon']
    ],
};

const formats = [
    'header',
    'font',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link',
    'image'
];

const schameFormComment = z.object({
    content: z.string()
})


interface Props {
    handleComment: (value: z.infer<typeof schameFormComment>) => void,
    isLoading: boolean | undefined | unknown
    onClose: VoidFunction
    isReply?: ICommentReply
    coach?: IAccountManager
}

export function InputComment({ handleComment, isLoading, onClose, isReply, coach }: Props) {
    const contextForm = useForm({
        resolver: zodResolver(schameFormComment),
        defaultValues: {
            content: ''
        }
    })

    return <>
        {
            isReply && typeof isReply !== 'boolean' && <div>
                <CardTitle className='text-[15px]'>
                    Responder a: <span className='text-primary text-[14px]'>
                        {
                            coach && isReply.coach
                                ? `@${coach.name} ${coach.lastname}`
                                : `@${isReply.user.name} ${isReply.user.lastname}`
                        }
                    </span>
                </CardTitle>
                <DivToSetInnerHtml content={isReply.content} />
            </div>
        }
        <Form {...contextForm}>
            <form
                className='flex gap-2 items-end mt-2'
                onSubmit={contextForm.handleSubmit((values) => handleComment(values))}
            >
                <div className="flex-grow lg:h-[250px] bg-white sm:h-[250px] h-[250px] border-b border-b-neutral-300 pb-1 relative overflow-hidden">
                    <FormField
                        control={contextForm.control}
                        name='content'
                        render={({ field }) => (
                            <ItemField >
                                <div className="flex-grow w-full h-full flex absolute overflow-y-hidden">
                                    <ReactQuill
                                        {...field}
                                        modules={modules}
                                        style={{ width: '100%' }}
                                        formats={formats}
                                    />
                                </div>
                            </ItemField>
                        )} />
                </div>
                <div className='flex flex-col gap-1'>
                    <SimpleTooltip content={'Destacar'}>
                        <Button
                            size={'icon'}
                            variant={'outline'}
                            onClick={onClose}
                            className='[&>svg]:h-4 [&>svg]:w-4'
                            type='button'>
                            <X />
                        </Button>
                    </SimpleTooltip>
                    <SimpleTooltip content={'Comentar'}>
                        <Button
                            disabled={!!isLoading}
                            size={'icon'}
                            variant={'outline'}
                            className='[&>svg]:h-4 [&>svg]:w-4'
                            type='submit'>
                            {
                                isLoading
                                    ? <Loader2 className="animate-spin" />
                                    : <SendHorizontal />
                            }
                        </Button>
                    </SimpleTooltip>
                </div>
            </form>
        </Form>
    </>
}