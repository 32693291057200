import {
    AccordionContent,
    AccordionItem,
    AccordionTrigger
} from "@/components/ui/accordion"
import { ReactNode } from "react"

export default function DetailAccordionItem({
    children,
    label,
    value
}: {
    children: ReactNode
    label: string
    value: string
}) {
    return <AccordionItem
        className="border-none [&>svg]:text-slate-600"
        value={value}
    >
        <AccordionTrigger className="border-b py-2.5 text-slate-600 text-[14px]">
            {label}
        </AccordionTrigger>
        <AccordionContent className="border-none">
            {children}
        </AccordionContent>
    </AccordionItem>
}