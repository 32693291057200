import { unifyContact } from "@/actions/crm/contact"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog"
import { useMutation } from "@tanstack/react-query"
import { Info, Loader2 } from "lucide-react"
import { useState } from "react"
import { useSelector } from "react-redux"

interface Props {
    open: string | false
    onClose: () => void
}

export function UnifyContactAlert({ open, onClose }: Props) {
    const currentContact = useSelector((state: any) => state.crm.contact)?._id
    const [error, setError] = useState<string | null>(null)

    const { mutate, isPending } = useMutation({
        mutationFn: () => unifyContact(currentContact, open as string),
        onSuccess: () => {
            onClose()
        },
        onError: (error) => {
            setError('Intenta nuevamente. Si el problema persiste, contacta a soporte.')
        },
    })
    return (
        <AlertDialog open={!!open} onOpenChange={onClose}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    {error && <Alert className="border-red-500">
                        <Info className="h-4 w-4" color="red" />
                        <AlertTitle className="text-red-500">Ocurrió un error</AlertTitle>
                        <AlertDescription className="text-red-500">
                            {error}
                        </AlertDescription>
                    </Alert>
                    }
                    <AlertDialogTitle>Unificar contacto</AlertDialogTitle>
                    <AlertDialogDescription>
                        Recuerda que esta acción no se puede deshacer. ¿Estás seguro que deseas unificar el contacto?
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancelar</AlertDialogCancel>
                    <AlertDialogAction className="bg-primary hover:bg-primary"
                        onClick={(e) => {
                            e.preventDefault()
                            mutate()
                        }}>
                        {isPending ? <Loader2 className="w-4 h-4 animate-spin" /> : 'Unificar'}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
