import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { formatFileSize } from '@/helpers/formatFileSize';
import { useMediaQuery } from '@mui/material';
import { defaultValueForEditor } from './constants/index';

interface ImageEditorProps {
  fnDebounce: (position: any) => void;
  values: Record<string, any>;
  indexElement?: number;
  refContentImage?: {
    current: HTMLImageElement | null
  }
}

const ImageEditor = React.forwardRef<AvatarEditor, ImageEditorProps>(({
  fnDebounce,
  values,
  refContentImage
}, ref): AvatarEditor => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [widthAndHeight, setWidthAndHeigh] = useState({
    width: 0,
    height: 0
  })
  const [currentMultimediaChange, setCurrentMultimediaChange] = useState<ImageEditorProps['values']>({
    ...defaultValueForEditor, path: ''
  });

  useEffect(() => {
    if (refContentImage?.current) {
      const { width, height } = refContentImage.current.getBoundingClientRect();
      setWidthAndHeigh({ width, height })
    }
  }, [refContentImage, currentMultimediaChange]);

  useEffect(() => {
    setCurrentMultimediaChange(values);
  }, [values]);

  const delayedDebouncePosition = useCallback(_.debounce(fnDebounce, 400), [values]);

  const handlePositionChange = useCallback((position) => {
    const currentValue = { ...currentMultimediaChange };
    currentValue.position = position;

    setCurrentMultimediaChange(currentValue);

    delayedDebouncePosition(currentValue.position);
  }, [currentMultimediaChange, delayedDebouncePosition, values]);

  return <div className='relative flex items-center justify-center h-full' >
    <div style={{ boxShadow: '0 3px 12px rgba(11,20,26,.16)' }}>
      <AvatarEditor
        ref={ref}
        {...currentMultimediaChange}
        image={currentMultimediaChange?.path}
        onPositionChange={handlePositionChange}
        rotate={currentMultimediaChange?.rotate}
        {...widthAndHeight}
        border={5}
        disableCanvasRotation={true}
      />
    </div>

  </div>
});

export default React.memo(ImageEditor);
