import { getCookie, reloadtoken, setLocalStorage } from '@/actions/auth'
import { defaultFormContactFields } from '@/constants/crm/contactForm'
import { setUserToGlobalState } from '@/redux/slices/config'
import { setContactForm } from '@/redux/slices/crm'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function useGetCurrentUser() {
    const user = useSelector((state: any) => state.config.user)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!user) return;
        const token = getCookie('token')

        if (token) {

            reloadtoken().then((res) => {
                if (!res || res.error) return
                const user = res.user;
                const { contactForm, ...rest } = user;

                setLocalStorage('user', rest)
                dispatch(setContactForm(contactForm || defaultFormContactFields))
                dispatch(setUserToGlobalState(user))
                return user
            })
        }
    }, [user])

    return user
}
