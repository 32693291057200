
import { PostService } from '@/actions/training'
import useLoadingAndError from '@/hooks/useLoadingAndError'
import { useCallback, useState } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'

export default function usePostCoach({
    activePost = false,
    company,
    offset = 1,
    queryKey = ['post-by-coacher'],
    limit = 2,
}: any = {}) {
    const [isErrorOfReaction, setErrorReaction] = useState<null | boolean>(false)
    const { isError, isLoading, setError, setIsLoading: setIsLoadingComment } = useLoadingAndError()
    const { isError: isErrorDelete, isLoading: isLoadingDelete, setError: setErrorDelete, setIsLoading: setIsLoadingDelete } = useLoadingAndError()

    const { data: allPost, refetch, ...restOfPost } = useQuery({
        queryKey,
        queryFn: () => PostService.getByCoacher({ offset, limit, company }),
        enabled: activePost
    })

    const { mutate, status, isError: isErrorMutate } = useMutation({
        mutationFn: ({ id, action }: any) => {
            const payload = action.payload
            const actions = {
                delete_comment: () => {
                    return PostService.deleteComment({ id })
                },
                reaction: () => PostService.reaction({ reactionType: payload, id }),
                create: () => PostService.createOrUpdate({ body: payload, id }),
                delete: () => PostService.delete(id as string)
            }

            return actions[action.type]()
        },
        onSuccess: function (data: any, { action }) {
            const onSuccess = action.onSuccess
            onSuccess && onSuccess(data)
        },
        onError: function (_, { action }) {
            if (action.type === 'reaction') {
                setErrorReaction(true)
            }
            setError('Error')
        }
    })

    const isLoadingMutate = status === 'pending'

    const handleCreate = useCallback((body, onSuccess: any, id?: string | null | undefined) => {
        mutate({
            id,
            action: {
                type: 'create',
                payload: body,
                onSuccess
            },

        })
    }, [])

    const handleDeletePost = useCallback((id: string, onSuccess: any) => {
        mutate({
            id,
            action: {
                type: 'delete',
                onSuccess
            }
        })
    }, [])

    return {
        handleDeletePost,
        handleCreate,
        isLoading,
        isErrorOfReaction,
        mutate,
        isError,
        posts: allPost?.posts || [],
        countDocuments: allPost?.countDocuments || 0,
        isLoadingGetPost: restOfPost.isLoading,
        isErrorGetPost: restOfPost.isError,
        refetch,
        isLoadingDelete,
        isLoadingMutate,
        isErrorMutate,
        isErrorDelete,
    }
}
