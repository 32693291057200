

import { Skeleton } from '@/components/ui/skeleton'
import React from 'react'

export default function CompanyGroupInformationLoader() {
    return (
        <div>
            <Skeleton className="w-[130px] h-3 rounded-md mb-4" />
            <ul className="grid gap-4">
                {
                    Array.from({ length: 7 }).map((__, inx) => (
                        <li key={inx} className="w-full grid grid-cols-8 gap-1">
                            <div className="col-span-2">
                                <Skeleton className="w-[100px] h-2 rounded-md" />
                            </div>
                            <div className="col-span-6  w-full">
                                <Skeleton className="w-[120px] h-2 rounded-md" />
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}
