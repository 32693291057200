import { cn } from "@/lib/utils"
import React, { ReactNode } from "react"

export interface ISubMenuCRM {
    children: JSX.Element | JSX.Element[],
    title?: string | ReactNode,
    classNameHeader?: string
    titleElement?: string | ReactNode,
    headerActive?: boolean
    className?: string,
    rightButton?: ReactNode | string | number
}

 export const SubMenuCrm = React.forwardRef<HTMLDivElement, ISubMenuCRM>((
    {
        children,
        title,
        rightButton,
        className = '',
        titleElement,
        headerActive = true,
        classNameHeader = ''
    }, ref) => {
    return <div ref={ref} className={cn(`border-r flex flex-col w-full border-slate-200 md:flex md:w-[260px] relative`, className)}>
        <div className={cn(`flex w-full shrink-0 overflow-hidden justify-between items-center ${headerActive ? 'h-[64px]  px-4 sm:py-3  shrink-0 py-2 border-b border-slate-200' : ''}`, classNameHeader)}>
            {
                titleElement
                    ? titleElement
                    : <h3 className="font-semibold text-[18px]">
                        {title}
                    </h3>
            }
            <div>
                {rightButton}
            </div>
        </div>
        {children}
    </div>
})