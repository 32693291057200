import { fetcher } from '@/helpers/fetcher'
import { formatSearchParams } from '@/helpers/formatSearchParams'
import axios from 'axios'
import { getCookie } from '../auth'
const API = process.env.API
const API_NEXT = process.env.API_NEXT


export const getCompanyUsers = (id) => {
    return axios.get(`${API_NEXT}/admin/changeaccount/company/${id}`, {
        headers: {
            'Authorization': 'JWT ' + (getCookie('admin_token') ?? getCookie('token'))
        }
    })
}

export const getAllCompanies = () => {
    return axios.get(`${API_NEXT}/admin/changeaccount/othercompany`, {
        headers: {
            'Authorization': 'JWT ' + (getCookie('admin_token') ?? getCookie('token'))
        }
    })
}

export const getOtherCompanyUsers = (id) => {
    return axios.get(`${API_NEXT}/admin/changeaccount/othercompany/${id}`, {
        headers: {
            'Authorization': 'JWT ' + (getCookie('admin_token') ?? getCookie('token'))
        }
    })
}

export const getUserByLink = (userid, apiKey) => {
    return axios.get(`${API_NEXT}/admin/changeaccount/${userid}?apiKey=${apiKey}`)
}

export const removeDuplicateTrancingFromUser = (
    userId: string,
    dateRange: { gte: string, lte: string }
) => {
    const searchParams = formatSearchParams(dateRange);

    return fetcher<{ documentsRemoved: number, success: boolean }>({
        url: `/admin/user/${userId}/removeduplicatetracing?${searchParams}`,
        defaultContentType: true,
        throwError: false,
        method: "POST"
    })
}

export const regenerateTracings = (user: string, dateRange: { gte: string, lte: string }) => {
    const searchParams = formatSearchParams(dateRange);

    return fetcher<{ success: boolean }>({
        url: `/admin/user/${user}/regenerateTracings?${searchParams}`,
        defaultContentType: true,
        method: "POST"
    })
}