"use client"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { ProductLine, Receipt, SaleBubbleTemplate } from "@/interfaces/Interface";
import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import * as React from "react";

interface MultilineTableProps {
    sale: Receipt,
    template?: SaleBubbleTemplate | null
}

export default function MultilineTable(props: MultilineTableProps) {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
    const [rowSelection, setRowSelection] = React.useState({});

    const productLines = props?.sale?.productLines || [];

    const table = useReactTable({
        data: productLines,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });

    const totalValue = productLines.reduce((p, c) => {
        return p + ((c.value || 0) * (c.quantity || 1))
    }, 0);

    const hiddenFields = props.template?.hiddenFields || []

    React.useEffect(() => {
        setColumnVisibility(Object.fromEntries(hiddenFields.map((field) => [field, false])))
    }, [hiddenFields])

    return <div className="w-full">
        <Table>
            <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                            return (
                                <TableHead key={header.id} className="text-[12.4px] px-2 h-10">
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </TableHead>
                            )
                        })}
                    </TableRow>
                ))}
            </TableHeader>
            <TableBody>
                {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                        <TableRow
                            key={row.id}
                            data-state={row.getIsSelected() && "selected"}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} className="p-3 text-[12.8px] font-medium text-gray-800">
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell
                            colSpan={columns.length}
                            className="h-24 text-center"
                        >
                            Sin resultados
                        </TableCell>
                    </TableRow>
                )}
                <TableRow>
                    <TableCell
                        colSpan={columns.length}
                        className="h-8 p-3"
                    >
                        <div className="flex items-center w-full justify-between">
                            <span className="font-medium text-gray-800 text-[14px]">
                                Total
                            </span>
                            <span className="font-medium text-gray-800 text-[14px]">
                                ${totalValue.toFixed(2)}
                            </span>
                        </div>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </div>
}

export const columns: ColumnDef<ProductLine>[] = [
    {
        accessorKey: "code",
        header: "ID",
        cell: ({ row }) => (
            <div className="capitalize">
                {row.getValue("code")}
            </div>
        ),
    },
    {
        accessorKey: "name",
        header: "Producto",
        cell: ({ row }) => (
            <div className="capitalize">
                {row.getValue("name")}
            </div>
        ),
    },
    {
        accessorKey: "quantity",
        header: "Cantidad",
        cell: ({ row }) => (
            <div className="capitalize">
                {row.getValue("quantity")}
            </div>
        ),
    },
    {
        accessorKey: "value",
        header: "Precio unitario",
        cell: ({ row }) => (
            <div className="capitalize font-medium">
                ${row.getValue("value")}
            </div>
        ),
    },
    {
        accessorKey: "total",
        header: "Total",
        cell: ({ row }) => (
            <div className="capitalize font-medium">
                ${(row?.original?.quantity || 1) * (row.original?.value || 0)}
            </div>
        ),
    }
];