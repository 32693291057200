

import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog"
import { ModalPropsBase } from '@/interfaces/Interface'

interface ForceSaveAlertProps extends ModalPropsBase {
    onContinue: () => void
}

export default function ForceSaveAlert({
    onOpenChange,
    open,
    onContinue
}: ForceSaveAlertProps) {
    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent className="z-[999]">
                <AlertDialogHeader>
                    <AlertDialogTitle>Existen campos obligatorios sin completar</AlertDialogTitle>
                    <AlertDialogDescription>
                        Algunos campos requeridos no han sido llenados. ¿Estás seguro de que deseas continuar sin completar esta información?
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel type="button">
                        Cancelar
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={onContinue} type="button">
                        Continuar
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
