import { getCompany } from '@/actions/auth';
import UsersSelect from '@/components/selects/users-select';
import CurrencySelect from '@/components/ui/CurrencySelect';
import { CustomFormField } from '@/components/ui/custom-form-field';
import { Input } from '@/components/ui/input';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select";
import { Textarea } from '@/components/ui/textarea';
import useGetUsersList from '@/hooks/useGetUsersList';
import { ComissionType } from '@/interfaces/Interface';
import _ from 'lodash';
import { useMemo } from 'react';
import { SectionPropsBase } from '.';

interface GeneralProps extends SectionPropsBase { }

export const commisionStates = [
    {
        label: 'Pendiente',
        code: 'pending',
        color: '#fde047'
    },
    {
        label: 'Rechazado',
        code: 'rejected',
        color: '#ef4444'
    },
    {
        label: 'Confirmado',
        code: 'confirmed',
        color: '#22c55e'
    }
];

export default function General({ contextForm }: GeneralProps) {
    const status = contextForm.watch('status');
    const onResetRejectionReason = () => contextForm.setValue('rejectionReason', '');

    const { users, isLoading: isLoadingGetUsersList } = useGetUsersList({ enabled: true });
    const sellers = useMemo(() => users.filter(({ roles = [] }) => roles.includes('user')), [users]);

    const categories: ComissionType[] = _.get(getCompany(), 'comissions.types', []).filter(({ active }) => active);
    const categoriesSetCode = new Set(categories.map(({ code }) => code));
    const companyCountry = _.get(getCompany(), 'country', 'UY');

    const usersValues = contextForm.watch('splitDeal');

    const onChangeFirstAgent = (users: string[]) => {
        let updatedSplitDeal = [...usersValues];
        const userId = users[users.length - 1];

        if (users.length == 0) {
            updatedSplitDeal = [];
        } else {
            const existUser = updatedSplitDeal.findIndex(({ user }) => user == userId);

            if (existUser !== -1) updatedSplitDeal.splice(existUser, 1)

            updatedSplitDeal[0] = {
                user: users[users.length - 1],
                percentage: updatedSplitDeal.length == 1
                    ? 100
                    : updatedSplitDeal?.[0]?.percentage || 100
            };
        };

        contextForm.setValue('splitDeal', updatedSplitDeal);
    }

    return (
        <div className={'grid gap-4 w-full'}>
            <CustomFormField
                isRequired
                name="bounty"
                label='Comisión base'
                control={contextForm.control}
                fnElement={({ field }) => (
                    <Input
                        type="text"
                        value={String(field.value)}
                        onChange={(e) => {
                            let { value } = e.target;
                            value = value.replace(/[^0-9]/g, '');

                            if (field.value === 0 && value !== '') return field.onChange(Number(value));
                            field.onChange(Number(value.replace(/^0+/, '') || '0'));
                        }}
                    />
                )}
            />

            <CustomFormField
                isRequired
                label='Divisa'
                name="bountyCurrency"
                control={contextForm.control}
                fnElement={({ field }) => (
                    <CurrencySelect
                        value={field.value}
                        onChangeValue={field.onChange}
                        countryEnabled={[companyCountry, 'US']}
                    />
                )}
            />

            <CustomFormField
                name="splitDeal"
                className='h-max flex-[0]'
                label="Quien recibe comisión"
                control={contextForm.control}
                fnElement={({ field }) => (
                    <div>
                        <UsersSelect
                            value={field.value[0] ? [field.value[0]].map(({ user }) => user) : []}
                            externalLoading={isLoadingGetUsersList}
                            onChangeValue={onChangeFirstAgent}
                            enableFetchDefaultData={false}
                            externalUsers={sellers}
                            maxElements={1}
                        />
                    </div>
                )}
            />

            <CustomFormField
                name="status"
                label='Estado'
                control={contextForm.control}
                fnElement={({ field }) => (
                    <Select
                        value={field.value}
                        onValueChange={(value) => {
                            field.onChange(value);
                            onResetRejectionReason();
                        }}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Seleccionar..." />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    commisionStates.map(({ code, color, label }) => (
                                        <SelectItem value={code} key={code}>
                                            <div className="flex items-center">
                                                <div
                                                    className="w-[10px] relative mr-2 rounded-full shrink-0 h-[10px]"
                                                    style={{ backgroundColor: color }}
                                                />
                                                {label}
                                            </div>
                                        </SelectItem>
                                    ))
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                )}
            />

            {
                categories.length > 0 && (
                    <CustomFormField
                        name="category"
                        label='Categoría'
                        control={contextForm.control}
                        fnElement={({ field }) => (
                            <Select
                                value={!categoriesSetCode[field.value] ? undefined : field.value || undefined}
                                onValueChange={(value) => {
                                    field.onChange(value);
                                    onResetRejectionReason();
                                }}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Seleccionar..." />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {
                                            categories.map(({ code, color, title }) => (
                                                <SelectItem value={code} key={code}>
                                                    <div className="flex items-center">
                                                        <div
                                                            className="w-[10px] relative mr-2 rounded-full shrink-0 h-[10px]"
                                                            style={{ backgroundColor: color }}
                                                        />
                                                        {title}
                                                    </div>
                                                </SelectItem>
                                            ))
                                        }
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        )}
                    />
                )
            }
            {
                status == 'rejected' && (
                    <CustomFormField
                        isRequired
                        name="rejectionReason"
                        label='Razón del rechazo'
                        control={contextForm.control}
                        fnElement={({ field }) => (
                            <Textarea
                                value={field.value}
                                onChange={field.onChange}
                                className="h-[90px]"
                                placeholder='Escribe la razón aquí...'
                            />
                        )}
                    />
                )
            }
        </div>
    )
};
