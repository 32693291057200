import { getCookie, isAuth } from "@/actions/auth"
import _ from "lodash"
import { RefreshCcw } from "lucide-react"
import "moment/locale/es"
import { Command } from "../../ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover"
import { SideBarTooltip } from '../NavLink'
import ButtonHeader from "../button-header"
import ListOfLinkedAccount from "./list-of-linked-account"
import { useToggle } from "@/hooks/useToggle"
import useChangeAccount from "@/hooks/useChangeAccount"

export default function ChangeLinkedAccounts({ isCollapsedMenu }) {
    const [openLinkedAccount, toggleOpenLinkedAccount] = useToggle<boolean>(false)
    const isActiveAccountChange = _.get(isAuth(), 'linkedAccounts.active', false) || !!getCookie('real_user_token')
    const { accounts, handleChangeAccount, isLoadingFetch } = useChangeAccount(!!isActiveAccountChange)

    const handleChange = (id: string) => {
        handleChangeAccount(id)
        toggleOpenLinkedAccount()
    }
    if (!isActiveAccountChange) return null;


    return (
        <SideBarTooltip content={'Cambiar cuenta'} active={isCollapsedMenu}>
            <Popover open={!!openLinkedAccount} onOpenChange={toggleOpenLinkedAccount}>
                <PopoverTrigger className="w-full">
                    <ButtonHeader
                        isCollapsedMenu={isCollapsedMenu}
                        label="Cambiar cuenta"
                        icon={<RefreshCcw />}
                    />
                </PopoverTrigger>
                <PopoverContent className="w-auto max-w-[220px] min-w-[200px] p-0 py-2" side="right">
                    <Command>
                        <ListOfLinkedAccount
                            accounts={accounts}
                            handleChangeAccount={handleChange}
                            isLoadingFetch={isLoadingFetch}
                        />
                    </Command>
                </PopoverContent>
            </Popover>
        </SideBarTooltip>
    )
}

