import { Building2 } from "lucide-react"
import "moment/locale/es"
import { Avatar } from "../../ui/avatar"
import { CommandItem } from "../../ui/command"
import { useMemo } from "react"
import { isAuth } from "@/actions/auth"

export default function AccountItem({
    onSelect,
    account
}) {
    const { company } = account || {}
    const { logo, businessName } = company || {}

    const sameCompany = useMemo(() => {
        return isAuth()?.group?._id === company?._id
    }, [company])

    const fullName = useMemo(() => {
        return `${account.name} ${account.lastname}`
    }, [account])

    return (
        <CommandItem className="w-full cursor-pointer flex items-center gap-2 text-start" onSelect={onSelect}>
            {
                logo && typeof logo === 'string'
                    ? <Avatar className=" text-white font-medium flex items-center justify-center w-[18px] h-[18px] text-xl">
                        <img
                            src={logo}
                            className="w-full h-full object-fill"
                            alt="User profile"
                        />
                    </Avatar>
                    : <Building2 size={17} className="shrink-0 text-gra-800" />
            }
            <span className="truncate text-[13px] font-medium">
                {sameCompany ? fullName : businessName}
            </span>
        </CommandItem>
    )
}