import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Grid } from "@mui/material"
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from "moment"
import { useEffect, useState } from "react"
import { getCompany } from "../../../actions/auth"
import DatePicker from './DatePicker'
import { cn } from "@/lib/utils"

moment.locale('es')

const ManualReminder = ({ handleChangeValues, values, className = '' }) => {
    const [date, setDate] = useState<Date>(new Date());
    const [time, setTime] = useState<Date | null>(new Date());
    const [error, setError] = useState<string>('');
    const [company, setCompany] = useState(getCompany());

    const handleAddDate = (data: Date) => {
        if (!data) return
        handleChangeValues && handleChangeValues(data)
        setDate(data)
    }

    const handleAddTime = (value: any) => {
        if (!value || !date) return;

        const newDate = new Date(date);
        newDate.setHours(value.getHours());
        newDate.setMinutes(value.getMinutes());
        console.log(newDate)
        console.log(newDate instanceof Date)

        if (!isNaN(newDate)) {
            setTime(value);
            setDate(newDate);
            handleChangeValues && handleChangeValues(newDate)
        } else {
            console.error("La fecha seleccionada es inválida.");
        }
    }

    useEffect(() => {
        if (values) {
            setDate(values)
            setTime(values)
        }
    }, [values])

    return <>
        <div className={cn(className)}>
            <div className="flex-1">
                <Label className="text-slate-600">Fecha</Label>
                <DatePicker date={date} setDate={handleAddDate} />
            </div>
            <div className="flex-1">
                <Label className="text-slate-600">Hora</Label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        label="Horario"
                        value={time}
                        onChange={(value) => {
                            handleAddTime(value)
                            // setTime(value);
                        }}
                        renderInput={({ inputRef, inputProps, InputProps, }) => (
                            <div className="flex flex-row columns-2 items-center">
                                <Input ref={inputRef} {...inputProps} />
                                <div className="w-0 ml-[-50px]">
                                    {InputProps?.endAdornment}
                                </div>
                            </div>
                        )
                        }
                    />
                </LocalizationProvider>
            </div>
        </div>
    </>
}

export default ManualReminder