import { cn } from "@/lib/utils";
import { Howl } from 'howler';
import React, { useEffect, useState } from "react";
import { IoPause, IoPlay } from "react-icons/io5";

type AudioState = {
    progress: number,
    duration: number
}

interface VoiceAudioPlayerProps {
    url: string;
    format?: string[];
    classNameContent?: string
}

export default function VoiceAudioPlayer({
    url,
    format = ['mp3'],
    classNameContent
}: VoiceAudioPlayerProps) {
    const [isPlaying, setPlaying] = useState<boolean>(false);
    const [audioControls, setAudioControls] = useState<Howl | null>(null);
    const [audioState, setAudioState] = useState<AudioState>({
        progress: 0,
        duration: 0
    });

    const { duration, progress } = audioState;

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    useEffect(() => {
        const loadAudio = async () => {
            if (url) {
                const sound = new Howl({
                    src: [url],
                    format,
                    onload: () => {
                        const duration = sound.duration();
                        setAudioState(prevState => ({
                            ...prevState,
                            duration,
                        }));
                    },
                    onplay: () => setPlaying(true),
                    onpause: () => setPlaying(false),
                    onend: () => setPlaying(false),
                    onstop: () => setPlaying(false),
                });

                setAudioControls(sound);
            }
        };

        loadAudio();
    }, [url]);

    useEffect(() => {
        if (!isPlaying || !audioControls) return;

        const interval = setInterval(() => {
            const currentPlayTime = audioControls.seek();
            setAudioState(prevState => ({
                ...prevState,
                progress: currentPlayTime,
            }));
        }, 50);

        return () => {
            clearInterval(interval);
        };
    }, [isPlaying]);


    const onToggleAction = () => {
        if (!audioControls) return;

        if (isPlaying) {
            audioControls.pause();
        } else {
            audioControls.play();
        }
    };

    const onSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!audioControls) return;

        const seekTime = parseFloat(e.target.value);
        audioControls.seek(seekTime);
        setAudioState(prevState => ({
            ...prevState,
            progress: seekTime
        }));
    };

    return (
        <div className={cn('flex bg-white items-center w-[200px] h-[34px]  justify-center gap-1 py-1.5 rounded-full border border-gray-200 px-2.5', classNameContent)}>
            <button
                type="button"
                onClick={onToggleAction}
                className="shrink-0 h-7 w-7 rounded-full"
            >
                {
                    isPlaying
                        ? <IoPause size={29} />
                        : <IoPlay size={29} />
                }
            </button>
            <input
                type="range"
                className="flex-grow h-1 w-[100px] border-none outline-none"
                min={0}
                max={duration}
                step="0.01"
                value={progress}
                onChange={onSeekChange}
            />
            <span className={'text-gray-800 text-[16px] font-medium'}>
                {`${formatTime(progress)}`}
            </span>
        </div>
    );
}
