import { Calendar } from "@/components/ui/calendar"
import { Input } from '@/components/ui/input'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from '@/lib/utils'
import dayjs from "dayjs"
import { CalendarIcon } from 'lucide-react'
import moment from "moment"
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

moment.locale('es');

export default function InputDate({
    date,
    setDate,
    handleInvalidDate,
    className = '',
    errorHandler = true,
    format = 'DD/MM/YYYY',
    classNameInput = '',
    disabled = false
}) {
    const [errorDate, setErrorDate] = useState('')
    const [inputValue, setInputValue] = useState('')

    const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        const dateFormat = format;

        if (!/^[0-9/]*$/.test(value)) return;

        if (moment(value, dateFormat, true).isValid()) {
            const dateSplit: any = value.split('/')

            let valueDate = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0])

            if (format.toLowerCase() === 'yyyy') { //Solo por este momento, luego sera cambiado para que sea mas proclive a usarse con otros formatos
                const date = new Date()
                date.setFullYear(Number(dateSplit[0]), 1, 1)
                valueDate = date
            }

            setDate(valueDate)
            errorHandler && setErrorDate('')
            handleInvalidDate(false)
        } else {
            errorHandler && setErrorDate(`La fecha tiene que ser válida con el formato ${format.toLowerCase()}`)
            handleInvalidDate(true)
        }

        setInputValue(value)
    }

    const handleCalendarChange = useCallback((value: Date | undefined | null) => {
        if (value) setInputValue(dayjs(value).format(format))
        setDate(!value ? date : value)
    }, [date])

    useEffect(() => {
        const strDate = moment(date).format(format)
        if (date && strDate !== inputValue && moment(date).isValid() && strDate) {
            setInputValue(strDate)
            errorHandler && setErrorDate('')
            handleInvalidDate(false)
        }
    }, [date])

    return (
        <div className={cn("flex flex-col gap-1 w-full", className)}>
            <div className='flex items-center relative'>
                <Popover>
                    <PopoverTrigger asChild>
                        <button className='absolute h-full bg-red w-[40px] flex items-center justify-center  left-0'>
                            <CalendarIcon className="h-[15px] w-[15px]" />
                        </button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <Calendar
                            mode="single"
                            selected={date}
                            onSelect={handleCalendarChange}
                            initialFocus
                        />
                    </PopoverContent>
                </Popover>
                <Input
                    value={inputValue}
                    onChange={handleChangeValue}
                    placeholder="DD/MM/YYYY"
                    className={cn(
                        "w-full justify-start text-left pl-9 font-normal",
                        classNameInput
                    )}
                    disabled={disabled}
                />
            </div >
            {
                errorHandler && (errorDate !== '') && <p className="text-[12px] font-medium text-destructive mt-1">
                    {errorDate}
                </p>
            }
        </div>
    )
}

