import { CommandGroup } from "@/components/ui/command";
import PupoverWithSelectItem, { CustomCommandItem } from "@/components/ui/pupover-with-select-item";
import { getFilteredStatusTags } from "@/helpers/tagColorToPalette";
import { Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import StatusTagCircle from "./status-tag-circle";

interface ChangeStatusTagElementProps {
    onChangeTag: (code: string) => void,
    trigger: JSX.Element,
    statusTag?: string,
}

export default function ChangeStatusTagElement(props: ChangeStatusTagElementProps) {
    const { trigger, statusTag, onChangeTag } = props;

    const [searchValue, setSearchValue] = useState("")
    const [lastTag, setLastTag] = useState<string | undefined>(statusTag);

    const tags = getFilteredStatusTags();

    useEffect(() => {
        if (!lastTag) setLastTag(statusTag)
    }, [statusTag])

    return (
        <PupoverWithSelectItem
            handleSearch={(value: string) => setSearchValue(value)}
            searchValue={searchValue}
            emptyMessage='Sin etiquetas'
            placeholder='Etiqueta...'
            Trigger={trigger}
            CommandListContent={
                <CommandGroup className="px-0">
                    {
                        statusTag && (
                            <CustomCommandItem
                                itemLeft={<Trash2 size={14} />}
                                onChange={() => onChangeTag('')}
                                label={'Eliminar etiqueta'}
                                value={'delete-status-tag-button-99234'}
                                isChecked={false}
                            />
                        )
                    }
                    {
                        tags.filter((tag) => tag.name.toLowerCase().includes(searchValue.toLowerCase()))
                            .map(({ code, name }) => (
                                <CustomCommandItem
                                    key={code}
                                    value={code}
                                    label={name}
                                    isChecked={statusTag == code}
                                    onChange={() => onChangeTag(code)}
                                    itemLeft={<StatusTagCircle code={code} />}
                                />
                            ))
                    }
                </CommandGroup>
            }
        />
    )
}