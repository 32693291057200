import { DateItem } from "@/interfaces/Interface";
import dayjs, { Dayjs } from "dayjs";

const handleSetCurrentHour = (date: Dayjs) => {
    const currentDate = dayjs()

    const updateDate = new Date(date.toISOString())
    updateDate.setHours(currentDate.hour());
    updateDate.setMinutes(currentDate.minute());

    return dayjs(updateDate.toISOString())
}

export const defaultDates: DateItem[] = [
    {
        title: 'Hoy',
        format: 'HH:mm',
        isCurrentDay: true,
        options: [
            {
                date: [30, 'minutes'],
                title: '30 min'
            },
            {
                date: [1, 'hours'],
                title: '1 horas'
            },
            {
                date: [3, 'hours'],
                title: '3 horas'
            }
        ]
    },
    {
        title: 'Esta semana',
        format: 'dddd DD/MM HH:mm',
        options: [
            {
                date: [1, 'days'],
                title: '1 día'
            },
            {
                date: [2, 'days'],
                title: '2 días'
            },
            {
                date: [3, 'days'],
                title: '3 días'
            },
            {
                date: [4, 'days'],
                title: '4 días'
            }
        ]
    },
    {
        title: 'Siguiente semana',
        format: 'DD/MM/YYYY HH:mm',
        options: [
            {
                date: [1, 'week'],
                functionsToApply: [(date: Dayjs) => date.startOf('week'), (date: Dayjs) => handleSetCurrentHour(date)],
                title: 'LUNES'
            },
            {
                date: [1, 'week'],
                functionsToApply: [(date: Dayjs) => date.startOf('week').add(1, 'day'), (date: Dayjs) => handleSetCurrentHour(date)],
                title: 'MARTES'
            },
            {
                date: [1, 'week'],
                functionsToApply: [(date: Dayjs) => date.startOf('week').add(2, 'day'), (date: Dayjs) => handleSetCurrentHour(date)],
                title: 'MIÉRCOLES'
            }
        ]
    }
];

export const defaultDatesOfArchive: DateItem[] = [
    {
        title: 'Siguiente mes',
        format: 'dddd DD/MM HH:mm',
        options: [
            {
                date: [1, 'month'],
                functionsToApply: [(date: Dayjs) => dayjs(date).startOf('month'), (date: Dayjs) => handleSetCurrentHour(date)],
                title: '1er día del mes'
            },
            {
                date: [1, 'month'],
                functionsToApply: [(date: Dayjs) => date.date(15), (date: Dayjs) => handleSetCurrentHour(date)],
                title: '15 día mes'
            },
            {
                date: [1, 'month'],
                functionsToApply: [(date: Dayjs) => date.endOf('month'), (date: Dayjs) => handleSetCurrentHour(date)],
                title: 'Fin de mes'
            }
        ]
    },
    {
        title: 'Meses',
        format: 'dddd DD/MM HH:mm',
        options: [
            {
                date: [2, 'months'],
                title: '2 meses'
            },
            {
                date: [3, 'months'],
                title: '3 meses'
            },
            {
                date: [6, 'months'],
                title: '6 meses'
            },
            {
                date: [9, 'months'],
                title: '9 meses'
            }
        ]
    },
    {
        title: 'Años',
        format: 'DD/MM/YYYY HH:mm',
        options: [
            {
                date: [1, 'years'],
                title: '1 Año'
            },
            {
                date: [2, 'years'],
                title: '2 Años'
            },
            {
                date: [3, 'years'],
                title: '3 Años'
            }
        ]
    }
];
