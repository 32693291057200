import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card";
import { cn } from "@/lib/utils";

interface CustomHoverCardProps {
    trigger: JSX.Element
    children: JSX.Element | JSX.Element[]
    openDelay?: number
    closeDelay?: number
    className?: string
}

export default function CustomHoverCard({ trigger, children, className, openDelay = 0.9, closeDelay = 0.9 }: CustomHoverCardProps) {
    return <HoverCard openDelay={openDelay} closeDelay={closeDelay}>
        <HoverCardTrigger asChild>
            {trigger}
        </HoverCardTrigger>
        <HoverCardContent className={cn(className)}>
            {children}
        </HoverCardContent>
    </HoverCard >
}