import { setLocalStorage } from "@/actions/auth";
import { BtnNextStep } from "@/components/buttons/BtnNextStep";
import InputPassword from "@/components/crm/Inputs/input-password";
import CardSuccessful from "@/components/toaster/card-successful";
import { CustomFormField } from "@/components/ui/custom-form-field";
import { Form } from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { User } from "@/models/User";
import { UseFormReturn } from "react-hook-form";
import { ChangePasswordSchema, changePasswordSchema, messageServerError } from "../libs/constants";
import useChangePassword from "../libs/hooks/useChangePassword";
import { ListOfPasswordCondition } from "./password-log";

export default function FormChangePassword({
    contextForm,
    isValidPassword,
    handleChangeUser,
    handleValidPassword,
    user
}: {
    contextForm: UseFormReturn<ChangePasswordSchema>,
    isValidPassword: boolean,
    handleChangeUser: (value: (User & { _id: string })) => void
    user: (User & { _id: string }),
    handleValidPassword: (value: boolean) => void
}) {
    const { errors } = contextForm.formState;
    const withErrors = Object.keys(errors).length > 0
    const { handleChangePassword, isLoading } = useChangePassword()

    const handleError = (error = {}) => {
        if ('errorCode' in error) {
            const { errorCode } = error
            if (errorCode === 2) {
                return contextForm.setError('currentPassword', {
                    message: messageServerError[errorCode]
                })
            } else {
                return contextForm.setError('newPassword', {
                    message: messageServerError[errorCode as any] || ''
                })
            }
        }
    }

    const handleSuccess = () => {
        const { forcePasswordChange, ...rest } = user
        setLocalStorage('user', rest)
        handleChangeUser(rest)

        toast({
            customElement: <CardSuccessful
                title='La contraseña se cambio correctamente'
                description={'La contraseña ha sido actualizada exitosamente en su cuenta. Ahora puede acceder con su nueva contraseña para disfrutar de una mayor seguridad.'}
            />
        })
    }

    const handleSubmit = (values: ChangePasswordSchema) => {
        const { repeatPassword, newPassword, currentPassword } = values

        if (repeatPassword.trim() !== newPassword.trim()) {
            return contextForm.setError('repeatPassword', {
                message: 'Las contraseñas deben ser iguales'
            })
        }

        if (currentPassword === newPassword) {
            return contextForm.setError('newPassword', {
                message: messageServerError[3]
            })
        }

        handleChangePassword({
            onError: handleError,
            onSuccess: (data) => data.success && handleSuccess(),
            body: {
                currentPassword,
                newPassword
            }
        })
    }

    return <Form {...contextForm}>
        <form onSubmit={contextForm.handleSubmit(handleSubmit)} className="w-full flex flex-col gap-5">
            <InputPasswordChange
                control={contextForm.control}
                name="currentPassword"
                label="Contraseña actual"
            />
            <InputPasswordChange
                control={contextForm.control}
                name="newPassword"
                label="Contraseña nueva"
            />
            <div>
                <InputPasswordChange
                    control={contextForm.control}
                    name="repeatPassword"
                    label="Repite la contraseña"
                />
                <ListOfPasswordCondition
                    className={'flex-row flex-wrap lg:hidden mt-2'}
                    classNameLi={'text-[12px]'}
                    contextForm={contextForm}
                    handleValidPassword={handleValidPassword}
                />
            </div>
            <BtnNextStep
                type="submit"
                hiddenArrow={true}
                disabled={!isValidPassword || !changePasswordSchema.safeParse(contextForm.getValues()).success || withErrors}
                loading={isLoading}
                className="mt-3"
                variant={'california'}
            >
                Cambiar
            </BtnNextStep>
        </form>
    </Form>
}

export function InputPasswordChange({ control, name, label }: {
    control: UseFormReturn<ChangePasswordSchema>['control'], 
    name: string, 
    label: string
}) {
    return <CustomFormField
        control={control}
        name={name}
        label={label}
        classNameLabel="md:text-[14px] text-[12px] text-gray-500"
        fnElement={({ field }) => (
            <InputPassword
                placeholder="●●●●●●●●●●●●"
                className="w-full placeholder:text-[10px]"
                {...field}
                required
            />
        )}
    />
}

