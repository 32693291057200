import { useState } from 'react'

export default function usePagination({ initialPage = 1 } = {}) {
    const [limitOfPage, setLimitPage] = useState<number>(-1)
    const [page, setPage] = useState(initialPage)

    const handlePrevPage = (): void => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const handleNextPage = (): void => {
        if (limitOfPage !== -1 && limitOfPage > page) {
            setPage(page + 1)
        }
    }

    const handleResetValues = () => {
        setLimitPage(-1)
        setPage(initialPage)
    }

    const handleSetMaxPage = (countDocuments: number, limit: number) => {
        setLimitPage(Math.ceil(countDocuments / limit))
    }

    return {
        page,
        handlePrevPage,
        handleNextPage,
        setLimitPage,
        limitOfPage,
        handleResetValues,
        handleSetMaxPage
    }
}
