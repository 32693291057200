import { Button } from "@/components/ui/button";
import { setInputProperty } from "@/redux/slices/crm";
import "@/styles/scrollModify.css";
import { UnfoldVertical } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function ButtonMoveInputHeight() {
    const [isGrabbing, setGrabbing] = useState<boolean>(false);
    const { height: inputHeight } = useSelector((state: any) => state.crm.inputProperties);
    const [startY, setStartY] = useState<number | null>(null);
    const [initialHeight, setInitialHeight] = useState<number>(0);
    const dispatch = useDispatch();

    const handleMouseMove = (event: MouseEvent) => {
        if (!isGrabbing || startY === null) return;
        const deltaY = startY - event.clientY;
        const newHeight = initialHeight + deltaY;
        const maxHeight = window.innerHeight - 128;

        if (newHeight < 0 || newHeight > maxHeight) return;

        dispatch(setInputProperty({
            height: newHeight
        }));
    };

    const handleMouseUp = () => {
        if (isGrabbing) {
            setGrabbing(false);
        }
    };

    useEffect(() => {
        if (isGrabbing) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isGrabbing]);

    const handleMouseDown = (event: React.MouseEvent) => {
        setStartY(event.clientY);
        setInitialHeight(inputHeight);
        setGrabbing(true);
    };

    return (
        <Button
            className={`${isGrabbing ? 'cursor-grabbing' : 'cursor-grab'} w-[28px] h-[28px]`}
            variant={'outline'}
            size={'icon'}
            onMouseDown={handleMouseDown}
        >
            <UnfoldVertical size={18} />
        </Button>
    );
}