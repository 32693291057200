"use client"
import { getCompany, isAuth } from '@/actions/auth'
import { LoadingButton } from "@/components/buttons/loading-button"
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion"
import Chip from '@/components/ui/chip'
import { InputSearch } from "@/components/ui/input-search"
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheets"
import { toast } from '@/components/ui/use-toast'
import { searchRegex } from "@/helpers/regex"
import { ArchiveDrawerInterface, ArchivingReason, ArchivingReasonsGroup, NextActionsBeforeArchiveType } from "@/interfaces/Interface"
import { handleUpdate } from '@/redux/slices/crm'
import '@/styles/scrollModify.css'
import _ from "lodash"
import mixpanel from "mixpanel-browser"
import moment from "moment"
import { ReactNode, useEffect, useMemo, useState } from "react"
import { archive } from "../../../actions/crm/contact"
import { SheetFooter } from "../../ui/sheets"
import { ModalAlertOnSale } from "../ArchiveDrawer/ModalAlertOnSale"
import ModalReminder from "../BottomActions/Reminder/ModalReminder"
import { reminderRecontactValues } from '../BottomActions/Reminder/constant/index'
import { defaultDatesOfArchive } from "../BottomActions/Reminder/defaultValuesOfDate"
import ChangeStatusTag from '../Modal/ChangeStatusTag'
import TransferContactDialog from '../Modal/TransferContactDialog'
import { FetchStates } from "../Modal/TransferModal"
import AddMasiveRequestSale from '../requestsale/AddMasiveRequestSale'

moment.locale('es')


const ArchiveDrawer = ({
    open,
    onClose,
    handleSuccess,
    reminder = true,
    disableArchivingReasons = [],
    currentArchivingReason = null
}: ArchiveDrawerInterface) => {
    const [nextAction, setNextAction] = useState<NextActionsBeforeArchiveType>('noAction');
    const [stateOfFetch, setStateOffetch] = useState(FetchStates.NOTHING)
    const [searchValue, setSearchValue] = useState<string>('')
    const [archivingReason, setArchivingReason] = useState<string>('');
    const [requiredFields, setRequiredFields] = useState<string[]>([]);
    const [notFilledFields, setNotFilledFields] = useState<string[]>([]);
    const [openModalAlertOnSale, setOpenModalAlertOnSale] = useState<boolean | string[]>(false);
    const [openModalReminder, setOpenModalRemider] = useState<false | string>(false)
    const [openCloseRequestSale, setOpenRequestSale] = useState<string[] | false>(false)

    const group = useMemo(() => isAuth().group, [open])
    const userRoles = useMemo(() => isAuth().roles, [open])
    const disableArchivingReasonsToSet = new Set(disableArchivingReasons)

    const archivingReasonsGroup = useMemo(() => _.get(getCompany(), 'archivingReasonsGroup', []), [group])

    const archivingReasons = useMemo(() => _.get(group, 'archivingReasons', [])
        .filter((archiving) => {
            if (disableArchivingReasonsToSet.has(archiving.code)) return false;

            if (archiving.usersFilters && Object.keys(archiving.usersFilters).length > 0) {
                let hasFilter = false
                let hasFound = false
                let userData = isAuth()?.additionalData || {};

                archiving.usersFilters.map((filter) => {
                    if (filter?.values && filter.values.length > 0) hasFilter = true
                    if (filter?.fieldCode && filter.values?.includes((userData[filter.fieldCode]))) {
                        hasFound = true
                    }
                })
                if (!hasFound && hasFilter) {
                    return false
                }
            }
            if(archiving.limitedToRoles.length > 0 && !userRoles.some(role => archiving.limitedToRoles?.includes(role))) {
                return false
            }
            return archiving.active
        }), [group, disableArchivingReasons, disableArchivingReasonsToSet])

    const archivinReasonList = useMemo(() => archivingReasons.filter((archiving) =>
        searchRegex(searchValue).test(archiving.name)), [archivingReasons, searchValue])

    const [archivingReasonsWithoutGroup, archivingReasonsGroups] = useMemo(() => {
        let list: any[] = []
        const archivingReasonsGroupToMap = {}

        archivingReasonsGroup.forEach((group) => {
            archivingReasonsGroupToMap[group.code] = {
                ...group,
                archivingReasons: []
            }
        })

        archivingReasons.forEach((reason) => {
            const { groupCode } = reason
            if (groupCode && archivingReasonsGroupToMap[groupCode]) {
                archivingReasonsGroupToMap[groupCode].archivingReasons.push(reason)
                return;
            }

            list.push(reason)
        })

        return [list, Object.values(archivingReasonsGroupToMap)]
    }, [archivingReasons])

    useEffect(() => {
        if (open) {
            const requiredOnSaleFields = group.additionalDataFields.filter((e) => e.requiredOnSale)
            setRequiredFields(requiredOnSaleFields.map(field => field.name));

            if (!open.additionalData) {
                setNotFilledFields(requiredOnSaleFields.map(field => field.name));
            } else {
                const notFilledFields = requiredOnSaleFields.filter(field => !open.additionalData[field.name])
                setNotFilledFields(notFilledFields.map(field => field.name));
            }

            setStateOffetch(FetchStates.NOTHING);
            setArchivingReason('')
        }
    }, [group, open]);

    const handleSubmit = async (values = false) => {
        const isSingleContact = !Array.isArray(open)
        const findArchivingReason = group.archivingReasons.find((a) => a.code === archivingReason)
        const nextAction = findArchivingReason?.nextAction || 'persistentReminder';

        if (isSingleContact) {
            if (!values && findArchivingReason && findArchivingReason.type === 'sale' && requiredFields.length > 0) {
                if (notFilledFields.length > 0) {
                    return setOpenModalAlertOnSale(notFilledFields)
                }
            }

            setStateOffetch(FetchStates.LOADING)

            try {
                const result = await archive(open?._id, archivingReason);
                if ('events' in result) result.events.forEach((event) => handleUpdate(event))
                setStateOffetch(FetchStates.NOTHING);
                if (reminder && (nextAction !== 'noAction')) return setNextAction(nextAction as NextActionsBeforeArchiveType);
            } catch (e) {
                setStateOffetch(FetchStates.ERROR);

                if (e?.body?.error?.errorCode == 'closingRequestSale') {
                    const requestSales = e?.body?.error?.requestSales || []
                    setOpenRequestSale(requestSales);

                    toast({
                        title: `${requestSales.length == 1
                            ? 'Existe una solicitud activa'
                            : `Existen ${requestSales.length} solicitudes activas`}`,
                        description: "Por favor, cierre o cancele las solicitudes activas para poder realizar esta acción.",
                        variant: 'destructive'
                    })
                }
                return;
            }

            return onClose();
        }

        setStateOffetch(FetchStates.LOADING)

        const allPromises = open && [open].flat().map((id) => {
            return new Promise((resolve, _) => {
                let realId = typeof id === 'string' ? id : id._id
                archive(realId, archivingReason).then((response) => {
                    resolve(response);
                    if (response.error) {
                        mixpanel.track('Archive created Error', { contactId: id, reason: archivingReason, error: response.error });
                    } else {
                        mixpanel.track('Archive created', { contactId: id, reason: archivingReason });
                    }
                });
            });
        });

        Promise.all(allPromises)
            .then(() => {
                setStateOffetch(FetchStates.NOTHING)
                handleSuccess && handleSuccess();
                onClose();
            })
            .catch(() => {
                setStateOffetch(FetchStates.ERROR)
            });
    };

    const [isLoading] = [stateOfFetch == FetchStates.LOADING];

    if (openCloseRequestSale) return (
        <AddMasiveRequestSale
            onOpenChange={() => setOpenRequestSale(false)}
            requestSales={openCloseRequestSale}
            contactId={open._id as string}
            open={!!openCloseRequestSale}
        />
    )

    const onCloseNextAction = () => {
        setNextAction('noAction')
        onClose()
    }

    if (nextAction && nextAction !== 'noAction') return <NextAction
        archivingReasons={group.archingReasons || []}
        currentStatusTag={open?.statusTag || ''}
        onCloseNextAction={onCloseNextAction}
        archivingReason={archivingReason}
        contactId={open?._id || ''}
        nextActionType={nextAction}
        agent={open?.agent}
    />

    return <>
        <Sheet open={!!open} onOpenChange={onClose}>
            <ModalAlertOnSale
                open={openModalAlertOnSale}
                onClose={(archived) => {
                    if (archived) handleSubmit(true)
                    setOpenModalAlertOnSale(false)
                }}
                contact={open}
            />
            <SheetContent className="flex flex-col gap-0 p-0 py-2 pt-4">
                <SheetHeader className=" p-0 px-4 pb-2">
                    <SheetTitle>Seleccione la razón de archivado</SheetTitle>
                </SheetHeader>
                <div className="flex flex-col px-4 gap-2 flex-grow">
                    <div className="relative">
                        <InputSearch
                            placeholder="Buscar razón de archivado..."
                            onChangeValue={setSearchValue}
                            value={searchValue}
                        />
                    </div>
                    <div className="flex-grow  relative">
                        <RadioGroup
                            value={archivingReason}
                            onValueChange={(e) => setArchivingReason(e)}
                            defaultValue={archivingReason}
                        >
                            <ul className="p-0 m-0 absolute top-0 gap-1 flex flex-col left-0 modifyScroll overflow-y-auto w-full h-full">
                                {
                                    archivingReasons.length === 0
                                        ? <WithoutResultDescription>
                                            Sin razones de archivado
                                        </WithoutResultDescription>
                                        : searchValue && archivinReasonList.length == 0
                                            ? <WithoutResultDescription>
                                                No se encontraron resultados para la busqueda
                                            </WithoutResultDescription>
                                            : searchValue
                                                ? archivinReasonList.map((reason) => (
                                                    <ArchivingReasonItem
                                                        disabled={disableArchivingReasonsToSet.has(reason.code)}
                                                        isCurrent={currentArchivingReason == reason.code}
                                                        onSelect={(code) => setArchivingReason(code)}
                                                        selected={reason.code == archivingReason}
                                                        key={reason.code}
                                                        reason={reason}
                                                    />
                                                ))
                                                : <>
                                                    <ArchivingReasonsGroupsList
                                                        archivingReason={archivingReason}
                                                        groups={archivingReasonsGroups as ArchivingReasonsGroupWithReasons[]}
                                                        onSelect={(code) => setArchivingReason(code)}
                                                    />
                                                    {
                                                        archivingReasonsWithoutGroup.map((reason) => (
                                                            <ArchivingReasonItem
                                                                disabled={disableArchivingReasonsToSet.has(reason.code)}
                                                                isCurrent={currentArchivingReason == reason.code}
                                                                onSelect={(code) => setArchivingReason(code)}
                                                                selected={reason.code == archivingReason}
                                                                reason={reason as ArchivingReason}
                                                                key={reason.code}
                                                            />
                                                        ))
                                                    }
                                                </>
                                }
                            </ul>
                        </RadioGroup>
                    </div>
                </div>
                <SheetFooter className="px-4 mt-2 py-0">
                    <div className="flex flex-col w-full">
                        <LoadingButton
                            loading={isLoading}
                            className="w-full"
                            onClick={() => handleSubmit()}
                            disabled={archivingReason == ""}
                        >
                            Archivar
                        </LoadingButton>
                    </div>
                </SheetFooter>
            </SheetContent>
        </Sheet>

    </>
}

export function WithoutResultDescription({ children }: { children: ReactNode }) {
    return <p className="text-[14px]  text-gray-600">
        {children}
    </p>
}

interface ArchivingReasonItemProps {
    reason: ArchivingReason,
    selected?: boolean,
    onSelect: (code: string) => void,
    disabled?: boolean,
    isCurrent?: boolean
}

export function ArchivingReasonItem({
    reason,
    selected,
    onSelect,
    disabled = false,
    isCurrent = false
}: ArchivingReasonItemProps) {
    const { code, name } = reason || {}

    const isDisabled = disabled || isCurrent
    return (
        <div
            className={`flex p-2 py-2.5 relative justify-between ${isDisabled ? 'select-none pointer-events-none' : 'hover:bg-gray-50'} items-center ${selected ? 'bg-gray-50' : ''} cursor-pointer  gap-2`}
            onClick={() => !isCurrent && onSelect(code)}
        >
            <div className="flex flex- items-center gap-2 max-w-[100%] relative overflow-hidden">
                <RadioGroupItem
                    value={code}
                    disabled={isDisabled || isCurrent}
                    className="w-[20px] h-[20px]"
                />
                <Label className={`text-[14px] ${isDisabled ? 'text-gray-400 cursor-pointer' : 'text-gray-600'} truncate max-w-[100%] select-none font-medium `}>
                    {name}
                </Label>
            </div>
            {
                isCurrent && (
                    <div className={'opacity-90'}>
                        <Chip withCircle={false} color="primary">
                            Actual
                        </Chip>
                    </div>
                )
            }
        </div>
    )
}

type ArchivingReasonsGroupWithReasons = (ArchivingReasonsGroup) & ({ archivingReasons: ArchivingReason[] })
interface ArchivingReasonsGroupsListProps {
    groups: ArchivingReasonsGroupWithReasons[]
    onSelect: (code: string) => void,
    archivingReason: string | null
}

export function ArchivingReasonsGroupsList({
    groups,
    onSelect,
    archivingReason
}: ArchivingReasonsGroupsListProps) {

    return (
        <Accordion type="single" collapsible className="w-full border-none">
            {
                groups.map(({ name, code, archivingReasons }) => {
                    return (
                        <AccordionItem value={code} key={code} className='border-b-transparent mb-0'>
                            <AccordionTrigger className='px-2 py-2'>
                                <div className='flex gap-2 items-center'>
                                    <span className='font-medium text-gray-800'>
                                        {name}
                                    </span>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent className='border-b-none p-1 pb-0 mb-0'>
                                {
                                    archivingReasons.map((reason) => (
                                        <ArchivingReasonItem
                                            key={reason.code}
                                            onSelect={(code) => onSelect(code)}
                                            selected={reason.code == archivingReason}
                                            reason={reason as ArchivingReason}
                                        />
                                    ))
                                }
                            </AccordionContent>
                        </AccordionItem>
                    )
                })
            }
        </Accordion>
    )
}

interface NextActionProps {
    nextActionType: NextActionsBeforeArchiveType,
    archivingReasons: ArchivingReason[],
    onCloseNextAction: () => void,
    currentStatusTag?: string,
    archivingReason?: string,
    contactId: string,
    agent: string
}

export function NextAction({
    nextActionType,
    contactId,
    onCloseNextAction,
    archivingReasons,
    archivingReason,
    agent,
    currentStatusTag
}: NextActionProps) {

    if (nextActionType == 'persistentReminder' || nextActionType == 'standardReminder') {
        const isStandardReminder = nextActionType == 'standardReminder';

        return (
            <ModalReminder
                open={contactId}
                onCLose={onCloseNextAction}
                dateOptions={!isStandardReminder ? defaultDatesOfArchive : null}
                customReminder={!isStandardReminder ? {
                    ...reminderRecontactValues({
                        label: archivingReasons.find((a) => a.code === archivingReason)?.name
                    })
                } : null}
            />
        )
    }

    if (nextActionType == 'transfer') {
        return (
            <TransferContactDialog
                onClose={onCloseNextAction}
                agentProspect={agent}
                type={'transfer'}
                open={contactId}
            />
        )
    }

    if (nextActionType == 'changeStatusTag') {
        return (
            <ChangeStatusTag
                discriminateStatusTag={currentStatusTag}
                onOpenChange={onCloseNextAction}
                contactId={contactId}
                open={true}
            />
        )
    }

    return null;
}

export default ArchiveDrawer