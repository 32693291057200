export function formatFileSize(sizeInBytes: number): string {
    const units: Array<string> = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let i: number = 0;
    while (sizeInBytes >= 1024 && i < units.length - 1) {
        sizeInBytes /= 1024;
        i++;
    }

    return sizeInBytes.toFixed(2) + ' ' + units[i];
}