

import { Routes } from "@/routes";
import { Pencil } from 'lucide-react';

export default function RedirectToEditCompanyButton({ companyId }: { companyId: string }) {
    return (
        <a
            href={`${Routes.BUSSINES}/${companyId}`}
            className="text-gray-700 w-[26px] h-[26px] hover:text-gray-500 duration-100 rounded-full  flex items-center justify-center"
        >
            <Pencil size={13} />
        </a>
    )
}
