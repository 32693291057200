import useGetArchivingReason from "@/hooks/useGetArchivingReason"
import { LocalStatus } from "@/redux/libs/ts/interfaces"
import { Archive } from "lucide-react"
import { useDispatch } from "react-redux"
import { createInteraction } from "../Prospect/libs/helpers/interaction"
import { DividerProspectAction } from "./TransferBubble"
interface ArchiveBubble {
    interaction: any
}

const ArchiveBubble = ({ interaction }: ArchiveBubble) => {
    const { createdAt, localStatus, contact, _id: interactionId } = interaction || {}
    const { to, from } = interaction.content || {}
    const { reason } = useGetArchivingReason({ code: interaction.content.to })
    const reasonName = reason.name || ''

    const isError = localStatus === LocalStatus.ERROR
    const isUnarchive = reasonName === ''
    const colorUnarchive = isError ? 'text-red-600' : isUnarchive ? 'text-gray-400' : ''

    const dispatch = useDispatch()

    const handleRetry = () => {
        createInteraction({
            dispatch,
            isRetry: true,
            type: 'archiving',
            data: {
                id: contact,
                localId: interactionId,
                values: {
                    to,
                    from,
                }
            }
        });
    }

    return <>
        <DividerProspectAction
            date={new Date(createdAt)}
            messageError={'Ocurrió un error al querer archivar al contacto'}
            retryFn={handleRetry}
            status={localStatus}
            className={`${colorUnarchive}`}>
            <Archive size={18} />
            <h4 className={`md:text-[14px] ${colorUnarchive} text-sm`}>
                {
                    reasonName !== ''
                        ? `Archivado como ${reasonName}`
                        : 'Desarchivado'
                }
            </h4>
        </DividerProspectAction>
    </>
}

export default ArchiveBubble