import { cn } from "@/lib/utils";
import { Check, X } from "lucide-react";
import { useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { ChangePasswordSchema } from "../libs/constants";
import { analyzePassword } from "../libs/helpers/analyzePassword";

export default function PasswordLog({ contextForm, handleValidPassword }: {
    contextForm: UseFormReturn<ChangePasswordSchema>,
    handleValidPassword: (value: boolean) => void,
}) {
    return <>
        <ChangePasswordTitle />
        <div className="flex flex-col ">
            <h4 className="font-normal mb-4 text-[18px] text-gray-400">La contraseña debe contener:</h4>
            <ListOfPasswordCondition
                contextForm={contextForm}
                handleValidPassword={handleValidPassword}
            />
        </div>
    </>
}

export function ListOfPasswordCondition({
    contextForm,
    handleValidPassword,
    className,
    classNameLi
}: {
    contextForm: UseFormReturn<ChangePasswordSchema>,
    handleValidPassword: (value: boolean) => void,
    className?: string,
    classNameLi?: string
}) {
    const newPassword = contextForm.watch('newPassword')

    const {
        hasLengthGreaterThan,
        hasLowerCase,
        hasNumber,
        hasUpperCase,
        isValid
    } = analyzePassword(newPassword)

    useEffect(() => {
        handleValidPassword(isValid)
    }, [isValid])

    const analyzePasswordElementData = useMemo(() => [
        {
            label: 'Al menos 6 caracteres',
            condition: hasLengthGreaterThan
        },
        {
            label: 'Al menos 1 letra mayúscula (A - Z)',
            condition: hasUpperCase
        },
        {
            label: 'Al menos 1 letra minúscula (a - z)',
            condition: hasLowerCase
        },
        {
            label: 'Al menos 1 número (0 - 9)',
            condition: hasNumber
        }
    ], [isValid, hasUpperCase, hasLowerCase, hasNumber, hasLengthGreaterThan])

    return <ul className={cn("flex flex-col gap-2 ", className)}>
        {
            analyzePasswordElementData.map(({ label, condition }) => (
                <PasswordContainLi
                    label={label}
                    className={cn(classNameLi)}
                    key={label}
                    state={condition}
                />
            ))
        }
    </ul>
}

export function ChangePasswordTitle({ className }: { className?: string }) {
    return <div className={cn("flex flex-col", className)}>
        <h5 className="md:text-[16px] text-[14px] text-primary">Acción necesaria</h5>
        <h2 className="font-normal  text-gray-700 flex-col flex  truncate text-[27px] md:text-[33px]">
            Cambio de contraseña
        </h2>
        <p className="text-slate-500 text-[13px] md:text-[15px] font-normal">
            Para garantizar la seguridad de tu cuenta, se recomienda cambiar la contraseña. Esto ayuda a proteger tus datos personales.
        </p>
    </div>
}

export function PasswordContainLi({ label, state = false, className }: { label: string, state: boolean, className?: string }) {
    return <li className={cn("flex text-[15px] text-gray-600 items-center gap-2", className)}>
        {
            !state
                ? <X className="text-destructive w-[13px] h-[13px]" />
                : <Check className="text-green-400 w-[15px] h-[15px]" />
        }
        {label}
    </li>
}