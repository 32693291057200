import { isAuth, setLocalStorage } from "@/actions/auth";
import { deleteFieldPresentation } from "@/actions/user";
import { Button } from "@/components/ui/button";
import { setOpenPresentation } from "@/redux/slices/headers";
import { CardMedia } from "@mui/material";
import { ArrowLeft } from "lucide-react";
import { useDispatch } from "react-redux";

export default function VideoStep({ goBack }) {
    const dispatch = useDispatch()

    const handleContinueToPlatform = () => {
        dispatch(setOpenPresentation(false))
        const { showNewVersionPresentantion, ...rest } = isAuth()
        setLocalStorage('user', rest)
        document.body.style.overflow = 'auto'

        deleteFieldPresentation()
            .then(() => null)
            .catch(() => null)

    }

    return <div className="w-full flex flex-col h-full p-10 py-7">
        <div className="mb-2">
            <button className="flex items-center text-[17px] group text-primary font-medium" onClick={() => goBack()}>
                <ArrowLeft className={"w-[18px] mt-0.5 group-hover:-translate-x-1 transition-transform h-[18px] mr-1"} />
                Volver
            </button>
        </div>
        <div className="flex-grow h-full flex items-center justify-center mb-4">
                <iframe
                    src="https://www.youtube.com/embed/Cs3814Iu8nU?si=j3P7uf6cNdavtZ3h&autoplay=1&vq=hd1080&rel=0&modestbranding=1&autohide=1&showinfo=0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen 
                    className="w-full h-full"
                    style={{aspectRatio: '16/9'}}
                    />
        </div>
        <div className="flex justify-end">
            <Button onClick={handleContinueToPlatform}>
                Continuar a la plataforma
            </Button>
        </div>
    </div>
}
