import { cn } from "@/lib/utils";
import React from "react";

const CustomCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "border flex items-center justify-between rounded-md border-gray-300 w-full px-6 py-3",
      className
    )}
    {...props}
  />
))

export default CustomCard