import { useEffect } from 'react';
import ReactDOM from 'react-dom';

export default function Portal({ children }: { children: JSX.Element }) {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, []);

    return ReactDOM.createPortal(<div className='z-[9999] fixed top-0 left-0 w-full'>{children}</div>,
        document.getElementById('portal-presentations'))
}
