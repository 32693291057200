import { takeContactFromOther } from "@/actions/crm/contact"
import { AlertDialogActivate } from "@/components/AlertDialogActivate"
import { toast } from "@/components/ui/use-toast"
import { handleUpdate } from "@/redux/slices/crm"
import { useMutation } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"

interface DialogTakeContactInterface {
    open: boolean,
    onClose: () => void
    userId: string
    contactId: string
}
export const DialogTakeContact = ({
    contactId,
    userId,
    ...rest
}: DialogTakeContactInterface) => {
    const router = useRouter()
    const dispatch = useDispatch();

    const { mutate, status } = useMutation({
        mutationFn: () => takeContactFromOther(userId, contactId),
        onSuccess: (data) => {

            if (Array.isArray(data.events)) {
                data.events.forEach(event => dispatch(handleUpdate(event)))
            }

            if (data.success) {
                toast({
                    title: "Contacto obtenido",
                    description: "El contacto se ha transferido correctamente a tu cartera",
                    variant: 'default'
                })

                rest.onClose()
                router.push(`contact?inbox=${userId}&id=${contactId}`)
            }
        },
        onError: () => {
            rest.onClose()
            toast({
                title: "Error al obtener el contacto",
                description: "Ocurrió un error al obtener el contacto, por favor intenta nuevamente",
                variant: 'destructive'
            })
        }
    })

    const isLoading = status === 'pending'
    const handleTakeContactFromOther = () => mutate()

    return (
        <AlertDialogActivate
            description='¿Estas seguro que deseas obtener este contacto?'
            handleSubmit={handleTakeContactFromOther}
            loading={isLoading}
            title='Tomar contacto'
            {...rest}
        />
    )
}
