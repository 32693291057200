import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'

export default function ModalDisregarded({ open, close }) {
    return !!open && (
        <Dialog onOpenChange={close} open={!!open}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Contacto Desatendido</DialogTitle>
                    <DialogDescription>
                        Los contactos que tienen más de 24 horas sin ningún tipo de interacción se consideran desatendidos.
                    </DialogDescription>
                    {/* <DialogDescription className='text-xs'>
                        Utiliza recordatorios y cierres para reducir los desatendidos, intenta llamar o enviar un whatsapp a estos clientes.
                    </DialogDescription> */}
                </DialogHeader>
                <DialogFooter>
                    <Button variant={'default'} type="button" onClick={close}>Cerrar</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
