
import { ICompanyGroup } from '@/interfaces/Interface';
import RedirectToEditCompanyButton from './RedirectToEditCompanyButton';

interface AdditionalFieldPhoneNumberProps {
    value: string[],
    company: ICompanyGroup | undefined,
}

export default function AdditionalFieldPhoneNumber(props: AdditionalFieldPhoneNumberProps) {
    const { value = [], company } = props;

    return (
        <ul className="flex items-center flex-wrap gap-x-4 gap-1.5">
            {
                value.map((phone, inx) => {
                    return (
                        <li className="flex items-center" key={inx}>
                            <p className="text-[13.5px] text-gray-700">
                                {phone}
                            </p>
                        </li>
                    )
                })
            }
            <RedirectToEditCompanyButton companyId={company?._id || ''} />
        </ul>
    )
}
