import {
  DoorOpen,
  Laptop,
  Mail,
  MessageSquare,
  MoreHorizontal,
  Phone,
} from "lucide-react";
import { FaWhatsapp } from "react-icons/fa";

export const namesOfOptions = {
  phoneCall: {
    name: "Realizar Llamada",
    icon: <Phone />,
  },
  message: {
    name: "Enviar Mensaje",
    icon: <MessageSquare />,
  },
  visit: {
    name: "Realizar visita",
    icon: <DoorOpen />,
  },
  meeting: {
    name: "Reunión",
    icon: <Laptop />,
  },
  sendMail: {
    name: "Enviar correo",
    icon: <Mail />,
  },
  sendWhatsapp: {
    name: "Enviar WhatsApp",
    icon: <FaWhatsapp />,
  },
  other: {
    name: "Otro",
    icon: <MoreHorizontal />,
  },
}

export const optionsForRecorder = [
  {
    label: "Realizar Llamada",
    icon: <Phone />,
    actionCode: "phoneCall",
  },
  {
    label: "Enviar Mensaje",
    icon: <MessageSquare />,
    actionCode: "message",
  },
  {
    label: "Realizar visita",
    icon: <DoorOpen />,
    actionCode: "visit",
  },
  {
    label: "Reunión",
    icon: <Laptop />,
    actionCode: "meeting",
  },
  {
    label: "Enviar correo",
    icon: <Mail />,
    actionCode: "sendMail",
  },
  {
    label: "Enviar WhatsApp",
    icon: <FaWhatsapp />,
    actionCode: "sendWhatsapp",
  },
  {
    label: "Otra acción",
    icon: <MoreHorizontal />,
    actionCode: "other",
  },
]

