
import ModalAddSectors from "@/components/comercial/company-groups/company/modal-add-sectors"
import useGetSectors from "@/components/comercial/company-groups/company/utils/hooks/useGetSectors"
import InputWithSelect from "@/components/ui/input-with-select"
import { isEnabledRolForCreateCompanyGroup } from "@/helpers/featureFlagHelpers"
import { searchRegex } from "@/helpers/regex"
import { useToggle } from "@/hooks/useToggle"
import { Plus } from "lucide-react"
import { useMemo, useState } from "react"

export interface SectorSelectorProps {
    companyGroup: any,
    sectorSelected?: any,
    onHandleChangeSector: (value: any) => void
}

export default function SectorSelector({
    companyGroup,
    sectorSelected,
    onHandleChangeSector
}: SectorSelectorProps) {
    if (!companyGroup?.sectors) return null
    const [localValue, setLocalValue] = useState<any>(null)
    const [searchValue, setSearchValue] = useState<any>('')
    const [openAdd, toggleAdd] = useToggle<boolean>(false)
    const { _id: companyId } = companyGroup || {}
    const { sectors, isLoading, onHandleChangeSectors } = useGetSectors(companyGroup?._id, !!companyGroup?.sectors)

    const realValue = sectorSelected || localValue

    const addSectorOption = useMemo(() => isEnabledRolForCreateCompanyGroup() && [
        {
            onSelectAction: toggleAdd,
            render: (
                <div
                    className='text-primary text-[13px] w-full h-full flex items-center font-medium'
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        toggleAdd()
                    }}
                >
                    <Plus className='mr-1' size={17} />
                    Añadir sector
                </div>
            )
        }
    ], [])

    const { name } = realValue || {}
    const sectorsFiltered = useMemo(() => sectors.filter(sector => searchRegex(searchValue).test(sector.name)),
        [searchValue, sectors])

    const onChangeSector = (value: any) => {
        onHandleChangeSector(value)
        setLocalValue(value)
    }

    return (
        <>
            <div className="grid gap-1.5">
                <InputWithSelect
                    multiple
                    strict={true}
                    maxElements={1}
                    isLoading={isLoading}
                    identifySelectedBy="_id"
                    placeholder="Seleccionar una opción"
                    downElements={addSectorOption}
                    onChangeInputSearch={(value) => setSearchValue(value)}
                    onChangeValue={(value) => onChangeSector((value || [])[0])}
                    value={!!realValue ? [{ label: name, value: realValue }] : []}
                    badgeClassName="hover:bg-blue-50/90 hover:text-blue-700 bg-blue-50 text-blue-800"
                    options={sectorsFiltered.map((sector) => ({ label: sector.name, value: sector }))}
                />
            </div>
            <ModalAddSectors
                onOpenChange={toggleAdd}
                open={openAdd as boolean}
                companyId={companyId}
                onSuccess={(value) => {
                    onHandleChangeSectors([value, ...sectors])
                    setSearchValue('')
                    onHandleChangeSector(value)
                }}
            />
        </>
    )
}
