import { isAuth } from "@/actions/auth"
import { TCustomStyles } from "@/interfaces/Interface"
import { LoaderForInfinityScroll } from "@/pages/training/instances"
import "@/styles/scrollModify.css"
import moment from "moment"
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useDispatch, useSelector } from "react-redux"
import { getInteractions, setRedirectToInteraction } from "../../redux/slices/crm"
import useOnScreen from "../core/useOnScreen"
import ActionsToRemember from "./ActionsToRemember"
import Bubble from "./Bubble"
import EndMesageComponent from "./Bubble/EndMesageComponent"
import FloatingButtons from "./UnAssigned/FloatingButtons"

moment.locale("es")

interface ConversationProps {
  nextProspect: () => void
  refScrollConversation: any
  customStyles?: TCustomStyles | null
  enableNextProspect?: boolean,
  downChatFc: any
  scrolleableDivRef: MutableRefObject<HTMLDivElement | null>
}

const Conversation = ({
  nextProspect,
  enableNextProspect,
  refScrollConversation,
  customStyles,
  downChatFc,
  scrolleableDivRef
}: ConversationProps) => {
  const prospect = useSelector((state: any) => state.crm.contact)
  const interactionsBase = useSelector((state: any) => state.crm.contact.interactions)
  const moreChats = useSelector((state: any) => state.crm.moreChats)
  const agent = useMemo(() => isAuth(), [])
  // const scrolleableDivRef = useRef<HTMLDivElement | null>(null);

  const activeFilters = useSelector((state: any) => state.crm.activeInteractionFilters)
  const searchValue = useSelector((state: any) => state.crm.searchValue)

  const dispatch = useDispatch()

  const secondInteractionRef = useRef(null)
  const isVisibleSecondInteraction = useOnScreen(secondInteractionRef, [interactionsBase.length, secondInteractionRef])
  const isVisibleFirstInteraction = useOnScreen(refScrollConversation, [interactionsBase.length, refScrollConversation])

  const interactions = useMemo(() => {
    if (!interactionsBase || interactionsBase?.length == 0) return []
    let fecha = moment([...interactionsBase].reverse()[0].createdAt)

    let list = [...interactionsBase]
      .reverse()
      .map((interaction, i) => {
        let result = [interaction]
        if (
          i == 0 ||
          fecha.startOf("day").diff(interaction.createdAt) <= -86400000
        )
          result = [
            {
              _id: moment(interaction.createdAt).format("L"),
              type: "date",
              createdAt: interaction.createdAt,
            },
            ...result,
          ]

        fecha = moment(interaction.createdAt)
        return result.reverse()
      })
      .reverse()
      .flat(1)

    list.splice(1, 0, { type: 'refSecond' })
    return list
  }, [interactionsBase, prospect])

  if (!interactions || interactions.length === 0) return <EndMesageComponent prospect={prospect} />

  const handleLoadMoreInteraction = () => {
    dispatch(
      getInteractions({
        id: prospect._id,
        filters: activeFilters,
        searchValue,
        isLoadMore: true,
        date: new Date(
          interactions[interactions.length - 1].createdAt
        ).toISOString(),
      })
    )
  }

  // const scrollToBottom = useCallback(() => {
  //   if (scrolleableDivRef.current) {
  //     const scrollableElement = scrolleableDivRef.current;

  //     scrollableElement.scrollTo({
  //       top: (scrollableElement.scrollHeight - scrollableElement.clientHeight) * -1,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [scrolleableDivRef])

  // useEffect(() => {
  //   if (
  //     redirectToInteraction?.interaction &&
  //     redirectToInteraction?.contact === prospect._id &&
  //     interactions.length > 0
  //   ) {
  //     const documentInteraction = document.getElementById(redirectToInteraction.interaction);

  //     if (!scrolleableDivRef.current) return;

  //     if (!documentInteraction) {
  //       if (scrolleableDivRef.current) scrollToBottom();
  //     } else {
  //       if (scrolleableDivRef.current) {
  //         const { top: elementTop, height: elementHeight } = documentInteraction.getBoundingClientRect();
  //         const { top: containerTop, height: containerHeight } = scrolleableDivRef.current.getBoundingClientRect();

  //         const offset = elementTop - containerTop + (elementHeight / 2) - (containerHeight / 2);

  //         scrolleableDivRef.current.scrollTo({
  //           top: offset + scrolleableDivRef.current.scrollTop,
  //           behavior: "smooth",
  //         });

  //         const checkScroll = setInterval(() => {
  //           if (!scrolleableDivRef.current) return;

  //           const currentScrollTop = scrolleableDivRef.current.scrollTop;
  //           const containerScrollHeight = scrolleableDivRef.current.scrollHeight;
  //           const containerClientHeight = scrolleableDivRef.current.clientHeight;

  //           const isElementInView = () => {
  //             if (!scrolleableDivRef.current) return false;

  //             const { top } = documentInteraction.getBoundingClientRect();
  //             const { top: containerTop } = scrolleableDivRef.current.getBoundingClientRect();
  //             return top >= containerTop && top <= (containerTop + containerClientHeight);
  //           };

  //           if (isElementInView() || currentScrollTop >= containerScrollHeight - containerClientHeight) {
  //             clearInterval(checkScroll);
  //             documentInteraction.classList.add('highlight');

  //             setTimeout(() => {
  //               documentInteraction.classList.remove('highlight');
  //             }, 1000);

  //             dispatch(setRedirectToInteraction({ contact: null, interaction: null }));
  //           }
  //         }, 200);
  //       }
  //     }
  //   }
  // }, [redirectToInteraction?.interaction, interactions]);

  return (
    <>
      <div
        className="overflow-y-auto modifyScroll absolute top-0 left-0 w-full bg-crm h-full flex flex-col-reverse"
        ref={scrolleableDivRef}
        id="scorllableChat"
      >
        <InfiniteScroll
          dataLength={interactions.length}
          next={handleLoadMoreInteraction}
          endMessage={<EndMesageComponent prospect={prospect} />}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            gap: "26px",
            padding: "0 15px 35px 15px",
            overflowY: "hidden",
          }}
          inverse={true}
          hasMore={moreChats}
          loader={<LoaderForInfinityScroll />}
          scrollableTarget="scorllableChat"
        >
          <div
            ref={refScrollConversation}
            id="scrollableChat"
            className="h-[2px] absolute bottom-0 w-max"
            key="refScrollConversation"
          ></div>

          <ActionsToRemember
            archivingReason={prospect.archivingReason}
            prospectID={prospect._id}
            reminder={prospect.reminder}
            status={prospect.status}
            interactions={interactions}
          />
          {interactions.map((interaction) =>
            interaction.type == 'refSecond'
              ? (
                <div
                  style={{ height: 0, marginTop: '-20px' }}
                  key={"refSecond"}
                  ref={secondInteractionRef}></div>
              ) : (
                <span id={interaction._id}>
                  <Bubble
                    customStyles={customStyles}
                    interaction={interaction}
                    key={interaction._id}
                    agent={agent}
                  />
                </span>
              ))}
        </InfiniteScroll>
      </div>
      {
        (!isVisibleSecondInteraction || !isVisibleFirstInteraction || !!enableNextProspect) && <div className="bottom-0 right-0 absolute">
          <FloatingButtons
            bajarChat={() => {
              downChatFc({ behavior: "smooth" })
            }}
            isVisible={isVisibleSecondInteraction || isVisibleFirstInteraction}
            nextContactVisible={enableNextProspect as boolean}
            nextConctact={nextProspect}
          />
        </div>
      }

    </>
  )
}

export default Conversation