import { fetcher } from "@/helpers/fetcher"
import { IActiveOnlineRoundRobbin, IContactForm } from "@/interfaces/Interface"
import _ from "lodash"
import { getCookie, isAuth } from "../auth"

export const deleteFieldPresentation = () => {
    const userId = _.get(isAuth(), "_id", "")

    return fetcher<{ ok: boolean } | { error: string }>({
        url: `/user/${userId}/deletePresentation`,
        options: {
            method: 'PATCH'
        }
    })
}

export const changePassword = (body: {
    currentPassword: string,
    newPassword: string
}) => {
    return fetcher<any>({
        url: `/auth/user/changePassword`,
        defaultContentType: true,
        withTryCatch: false,
        method: 'POST',
        body
    })
}

export const getLinkedAccounts = () => {
    return fetcher<{ accounts: any[], sucess: boolean }>({
        url: `/user/linkedAccounts`,
        defaultContentType: true,
        withToken: false,
        options: {
            headers: {
                'Authorization': `JWT ${getCookie('real_user_token') || getCookie('token')}`
            }
        }
    })
}

export const changeAccount = (id: string) => {
    return fetcher<{ accounts: any[], sucess: boolean, isRealUser: boolean }>({
        url: `/user/linkedAccounts/${id}/change`,
        defaultContentType: true,
        method: 'POST',
        withToken: false,
        options: {
            headers: {
                'Authorization': `JWT ${getCookie('real_user_token') || getCookie('token')}`
            }
        }
    })
}

export const getContactForm = () => {
    return fetcher<{ contactForm: IContactForm }>({
        url: '/user/config/contactForm',
        defaultContentType: true
    })
}

export const invalidateAllTokens = () => {
    return fetcher<{ token: string }>({
        url: '/user/invalidatealltokens',
        defaultContentType: true,
        method: 'POST'
    })
}

export const getOneRoundRobbinOnline = () => {
    return fetcher<{ success: boolean, onlineRoundRobbin: IActiveOnlineRoundRobbin | null }>({
        url: '/user/roundrobbin/online',
        defaultContentType: true
    })
}

interface OnlineRoundRobbinState {
    queuedUsersCount: number,
    userPosition: number,
    inQueued: boolean
}

export const joinToOnlineRoundRobbin = (id: string) => {
    return fetcher<{
        success: boolean,
        onlineRoundRobbinState: OnlineRoundRobbinState
    }>({
        url: `/user/roundrobbin/online/${id}/join`,
        defaultContentType: true,
        method: 'POST'
    })
}

export const disconnetOnlineRoundRobbin = (id: string) => {
    return fetcher<{ success: boolean }>({
        url: `/user/roundrobbin/online/${id}/disconnect`,
        defaultContentType: true,
        method: 'POST'
    })
}

export const takeContactFromOnlineRoundRobbin = (id: string) => {
    return fetcher<{ success: boolean, contactId: string }>({
        url: `/user/roundrobbin/online/${id}/takecontact`,
        defaultContentType: true,
        method: 'POST'
    })
}

export const saveFcmToken = (token: string) => {
    return fetcher<{ success: boolean }>({
        defaultContentType: true,
        url: `/user/fcmtoken`,
        body: { token },
        method: 'PATCH'
    });
}