import { Loader2 } from "lucide-react"

const Loading = () => {
    return <div className="justify-center flex items-center flex-grow">
        <div>
            <Loader2 className="h-7 w-7 animate-spin text-primary text-3xl" />
        </div>
    </div>
}

export default Loading