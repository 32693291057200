import {
    Avatar
} from "@/components/ui/avatar";
import { getStatusTagColor } from "@/helpers/tagColorToPalette";
import { cn } from "@/lib/utils";
import { Bot } from "lucide-react";
import { InteractionProfileUserProps } from "./libs/ts/interfaces";

export function InteractionProfileUser({
    statusTag,
    isAgent,
    profile,
    ItemChannel,
    className,
    classNameImage
}: InteractionProfileUserProps) {
    return <Avatar
        style={{
            backgroundColor: isAgent ? '#0080ff' : (origin !== 'agent' && statusTag)
                ? getStatusTagColor(statusTag, "primary")
                : "#0080ff",
            color: (origin !== 'agent' && statusTag)
                ? getStatusTagColor(statusTag, "secondary")
                : "#fff",
        }}
        className={cn(`text-[11px] shrink-0 font-semibold w-[30px] flex items-center justify-center h-[30px] overflow-visible relative  ${isAgent ? 'shadow-bubble-right' : 'shadow-bubble-left'}`, className)}>
        {ItemChannel}
        <ProfileContent
            classNameImage={classNameImage}
            profile={profile}
        />
    </Avatar>
}

export function ProfileContent({ profile, classNameImage }) {
    if (!profile) return ''
    if (profile == 'bot') return <Bot size={22} />
    if (profile.length > 2) return <img src={profile} className={cn("w-[30px] h-[30px] rounded-full", classNameImage)} />

    return profile
}