import { Button } from "@/components/ui/button"
import { FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import { FormControl } from "@mui/material"
import { AlertCircleIcon, CheckCircle2, Loader2, MinusCircle, PlusCircle } from "lucide-react"

export type TStates = 'loading' | 'error' | 'success'
interface ContactPhoneInputInterface {
  helperText?: string | false,
  error?: boolean,
  label: string,
  value: string,
  onChange: any,
  className?: string
  onIconPress?: () => void,
  required?: boolean,
  removeIcon?: boolean,
  activeState?: boolean
  classNameState?: string
  isActiveButton?: boolean
  classNameActiveState?: string
  state?: TStates | null,
  formControl?: boolean
}

const ContactPhoneInput = ({
  helperText,
  error,
  label,
  value,
  onChange,
  onIconPress,
  required,
  removeIcon,
  className,
  activeState = false,
  isActiveButton = true,
  classNameState,
  classNameActiveState = "pl-8",
  state = null,
  formControl = false
}: ContactPhoneInputInterface) => {

  const states = {
    'success': <CheckCircle2 className="text-green-400 w-[15px] h-[15px]" />,
    'error': <AlertCircleIcon className="text-destructive w-[13px] h-[13px]" />,
    'loading': <Loader2 className="h-[15px] w-[15px] animate-spin" />
  }

  return (
    <Container error={error} formControl={formControl} helperText={helperText} >
      <div className="w-full flex items-center justify-end relative">
        {
          isActiveButton && <Button
            onClick={onIconPress}
            variant={'ghost'}
            type="button"
            className="absolute flex justify-center w-[30px] h-[30px] shrink-0 right-1 [&>svg]:w-[30px] [&>svg]:h-[30px] items-center  px-2 text-neutral-400 rounded-full cursor-pointer"
          >
            {removeIcon
              ? <MinusCircle />
              : <PlusCircle />}
          </Button>
        }
        <Input
          placeholder={label}
          type="text"
          onChange={onChange}
          required={required}
          value={value}
          className={cn(className, `pr-10 ${activeState && state !== null ? classNameActiveState : ''}`)} />
        {
          activeState && state !== null && <div className={cn('absolute left-[10px]', classNameState)}>
            {
              states[state as TStates] || null
            }
          </div>
        }
      </div>
    </Container>
  )
}

function Container({
  children,
  formControl = false,
  helperText,
  error
}) {

  if (formControl) {
    return (
      <FormControl className="w-full h-auto">
        {children}
        <FormMessage className="text-[12px] mt-1">{error && helperText ? helperText : ''}</FormMessage>
      </FormControl>
    )
  }

  return children;
}

export default ContactPhoneInput