import { Button } from '@/components/ui/button';
import SimpleTooltip from '@/components/ui/simple-tooltip';
import { formatFileSize } from '@/helpers/formatFileSize';
import { setUploadMultimediaType } from '@/redux/slices/crm';
import { ArrowLeft, Check, ZoomIn, ZoomOut } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Cut, Turn } from '../Bubble/Icons/Crm';
import { ROTATE_OPTIONS, ZOOM_VALUES } from './constants';
import _ from 'lodash';

interface HeaderMultimediaProps {
  values: Array<Record<string, any>>
  onChangeValue: (values: Array<Record<string, any>>) => void
  inxOfFile: number
  isEditMode?: boolean | null
  handleEditMode: () => void
  handleSaveChanges: () => void
}

export default function HeaderMultimedia({
  values,
  inxOfFile,
  onChangeValue,
  isEditMode = false,
  handleEditMode,
  handleSaveChanges
}: HeaderMultimediaProps) {
  const dispatch = useDispatch()
  const currentFile = useMemo(() => values[inxOfFile], [inxOfFile, values])

  const extension = useMemo(() => {
    const strSplit = currentFile?.name?.split('.') || ''
    return strSplit?.[strSplit.length - 1]
  }, [currentFile])

  const handleRotateImage = useCallback((inx) => {
    const allValues = _.cloneDeep(values)
    const media = allValues[inx]

    const keyRotate = media.rotateKey >= 3 ? 0 : media.rotateKey + 1
    allValues[inx].rotateKey = keyRotate
    allValues[inx].rotate = ROTATE_OPTIONS[keyRotate]

    onChangeValue(allValues)
  }, [values])

  const handleZoom = useCallback((inx, isZoomMode) => {
    const allValues = _.cloneDeep(values)
    let value = allValues[inx].scale


    if (isZoomMode) {
      if (value <= ZOOM_VALUES.MAX_ZOOM) {
        value = value + ZOOM_VALUES.ZOOM_VALUE
      }
    } else {
      if (value > ZOOM_VALUES.MIN_ZOOM) {
        value = value - ZOOM_VALUES.ZOOM_VALUE
      }
    }

    if (value == 0 || value >= 7) return

    allValues[inx].scale = value
    onChangeValue(allValues)
  }, [values])

  const handleCLose = () => {
    dispatch(setUploadMultimediaType({
      type: null,
      multimedia: []
    }))
  }

  return <header className='bg-[#EFF6FF] shrink-0 justify-between w-full flex items-center md:px-6 px-4 h-[52px]'>
    <div className='flex-1'>
      <ButtonAction
        content='Volver'
        icon={<ArrowLeft strokeWidth={1.5} className='md:w-7 md:h-7 w-[25px] h-[25px]' />}
        action={handleCLose}
      />
    </div>
    {
      values[inxOfFile]?.type?.includes('image')
        ? <ul className='flex items-center gap-4 flex-grow shrink-0 justify-center'>
          <li>
            <ButtonAction
              content='Cortar'
              icon={<Cut className='md:w-[22px] w-[20px] h-[20px] md:h-[22px]' />}
              action={() => handleEditMode()}
            />
          </li>
          <li>
            <ButtonAction
              disabled={!isEditMode}
              content='Girar'
              icon={<Turn className='md:w-[23px] w-[20px] h-[20px] md:h-[23px]' />}
              action={() => handleRotateImage(inxOfFile)}
            />
          </li>
          <li>
            <ButtonAction
              disabled={!isEditMode}
              content='Agrandar'
              icon={<ZoomIn className='md:w-[23px] w-[20px] h-[20px] md:h-[23px]' />}
              action={() => handleZoom(inxOfFile, true)}
            />
          </li>
          <li>
            <ButtonAction
              content='Minimizar'
              disabled={!isEditMode}
              icon={<ZoomOut className='md:w-[23px] w-[20px] h-[20px] md:h-[23px]' />}
              action={() => handleZoom(inxOfFile, false)}
            />
          </li>
        </ul>
        : !!currentFile?.size && <div className='flex flex-col w-full items-center'>
          <h3 className='text-slate-600 max-w-[90%] truncate leading-4 md:text-[15px] text-[13px] font-medium'>
            {currentFile?.name}
          </h3>
          <p className='text-slate-500 text-[12px]'>
            {formatFileSize(currentFile?.size || 0)} - <span className='uppercase'>
              {extension}
            </span>
          </p>
        </div>
    }
    <div className='flex-1 flex justify-end'>
      {
        isEditMode && <ButtonAction
          content='Guardar'
          icon={<Check className='md:w-[23px] w-[20px] h-[20px] md:h-[23px]' />}
          action={() => handleSaveChanges()}
        />
      }
    </div>
  </header>
}

export function ButtonAction({ icon, action, disabled = false, content = '' }) {
  return <SimpleTooltip content={content}>
    <Button size={'icon'} variant={'ghost'} disabled={disabled} className='hover:bg-slate-200 hover:text-slate-600 text-slate-600 flex h-9 w-9 justify-center items-center' onClick={() => action()}>
      {icon}
    </Button>
  </SimpleTooltip>
}