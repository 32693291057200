

import { Button } from '@/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent, PopoverTrigger
} from '@/components/ui/popover';
import { Plus, XIcon } from 'lucide-react';
import { useMemo, useState } from 'react';

interface AdditionalFieldMultiSelectProps {
    onChangeValue: (value: string[]) => void
    value: string[],
    options: string[],
}

export default function AdditionalFieldMultiSelect(props: AdditionalFieldMultiSelectProps) {
    const [open, setOpen] = useState<boolean>(false);

    const { value, options = [], onChangeValue } = props;
    const valueToSet = new Set(value);

    const filteredOptions = useMemo(() => {
        return options.filter(op => !valueToSet.has(op))
    }, [valueToSet, value])

    const onDeleteValue = (value: string) => {
        const newValues = valueToSet;
        newValues.delete(value);
        onChangeValue(Array.from(valueToSet.values()));
    };

    const addValue = (key: string) => {
        const allValues = value.filter(Boolean);
        allValues.push(key);
        onChangeValue(allValues);
    }

    return (
        <ul className="flex items-center h-max flex-wrap gap-x-2 gap-1.5">
            {
                value.filter(Boolean).map((value, inx) => {
                    return (
                        <li className="flex border rounded-full px-2 py-[1px] items-center" key={inx}>
                            <p className="text-[13.5px] text-gray-700">
                                {value}
                            </p>
                            <button
                                className='ml-3 hover:text-gray-500 duration-100 rounded-full flex items-center justify-center'
                                onClick={() => onDeleteValue(value)}
                            >
                                <XIcon size={14} />
                            </button>
                        </li>
                    )
                })
            }
            {
                (filteredOptions.length > 0) && (
                    <div className="flex">
                        <Popover open={open} onOpenChange={setOpen}>
                            <PopoverTrigger asChild>
                                <Button
                                    className='rounded-full w-6 h-6 shrink-0 flex items-center justify-center'
                                    variant={'outline'}
                                    type="button"
                                    size={'icon'}
                                >
                                    <Plus size={14} className='shrink-0' />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[200px] p-0">
                                <Command>
                                    <CommandInput
                                        placeholder="Buscar opción..."
                                        className="h-[35px]"
                                    />
                                    <CommandList>
                                        <CommandEmpty>No se encontraron resultados.</CommandEmpty>
                                        <CommandGroup>
                                            {filteredOptions.map((op) => (
                                                <CommandItem
                                                    key={op}
                                                    value={op}
                                                    className='h-[30px]'
                                                    onSelect={() => addValue(op)}
                                                >
                                                    {op}
                                                </CommandItem>
                                            ))}
                                        </CommandGroup>
                                    </CommandList>
                                </Command>
                            </PopoverContent>
                        </Popover>
                    </div>
                )
            }
        </ul >
    )
}
