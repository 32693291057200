import { getCompany } from "@/actions/auth"
import { Card } from "@/components/ui/card"
import { cn } from "@/lib/utils"
import { CardContent } from "@mui/material"
import _ from "lodash"
import moment from "moment"
import { useMemo } from "react"
import { DividerProspectAction } from "./TransferBubble"

export default function EndMesageComponent({ prospect, customStyle }: any) {
  const { createdAt, subConversation } = prospect || {}
  const { createdBy, description } = subConversation || {}

  return (
    <div className="flex flex-col flex-grow gap-0">
      <div className="w-full ">
        <DividerProspectAction>
          {`Creado ${moment(createdAt).format("L")} ${moment(
            createdAt
          ).format("LT")}`}
        </DividerProspectAction>
      </div>
      <InformationContact
        customStyle={customStyle}
        prospect={prospect}
      />
    </div>
  )
}

function InformationContact({ customStyle, prospect }) {
  const { detail, firstAgentFullName, origin } = prospect || {}
  const origins = useMemo(() => _.get(getCompany(), "origins", []), [])

  return <div className="w-full flex items-center flex-col justify-center">
    <Card
      className={cn(
        "bg-transparent px-4 py-0 max-w-[100%] sm:max-w-[60%] shadow-none border-none",
        customStyle
      )}
    >
      <CardContent className="p-0">
        {prospect && (
          <div className="flex flex-col border-[#cacfda] border-l text-[15px] text-slate-600 pl-3">
            <p>
              <span className="font-medium text-slate-600">Origen: </span>
              {origins.filter((o) => o.code == origin)[0]
                ? origins.filter((o) => o.code == origin)[0].name
                : origin}
            </p>
            {detail && (
              <p>
                <span className="font-medium text-slate-600">
                  Comentario:{" "}
                </span>
                {detail}
              </p>
            )}
            {firstAgentFullName && (
              <p>
                <span className="font-medium text-slate-600">
                  {" "}
                  Primer agente:{" "}
                </span>
                {firstAgentFullName}
              </p>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  </div>
}