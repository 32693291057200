import { getCookie, isAuth } from '@/actions/auth'
import { setOpenPresentation } from '@/redux/slices/headers'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PresentationOfNewVentia from './PresentationOfNewVentia'

export default function Presentation() {
    const isOpenPresentation = useSelector((state: any) => state.header.openPresentation)
    const dispatch = useDispatch()

    useEffect(() => {
        if(!getCookie("admin_token")){
            dispatch(setOpenPresentation(_.get(isAuth(), 'showNewVersionPresentantion', false)))
        }
    }, [isAuth()])

    return isOpenPresentation
        ? <PresentationOfNewVentia />
        : null
}
