
import moment from "moment";
import TickStatus from "./TickStatus";
import InteractionChannel from "./interaction-channel";

export default function InformationMedia({
    date,
    status,
    description,
    channel
}) {
    if (!(!description || (`${description || ''}`.trim() === ''))) return null;

    return (
        <div className="flex items-center gap-1 bg-black/60 p-1.5 py-[2px] text-white bottom-[4px] right-[4px] absolute text-[12px] rounded-sm">
            <InteractionChannel channel={channel} />
            <span>
                {moment(date).format("LT")}
            </span>
            {status && <TickStatus status={status} />}
        </div>
    )
}

