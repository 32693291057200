import { getCompany, isAuth } from "@/actions/auth";
import { SelectItem } from "@/components/ui/select";


export const GetOriginItem = ({origin}: {origin:string}) => {

    let listOrigins = isAuth() ? [...isAuth().group.origins] : [];
    let originUse = listOrigins.filter((o) => o.code == origin);

    return (
        <SelectItem key={origin} value={origin}>
            {originUse.length > 0 ? originUse[0].name : origin}
        </SelectItem>
    );
};