import { Avatar } from "@/components/ui/avatar"
import useGetCompanyGroups from "./hooks/useGetCompanyGroups";
import LoaderMultipleSkeleton from "./loader-multiple-skeleton";

export default function CompanyGroupField({
    title,
    values,
    code
}) {
    const valueToArray = [values].flat()

    const { companies, isLoading } = useGetCompanyGroups({
        enabled: valueToArray.length > 0,
        queryKey: [`companygroup-list-${code}`],
        externalSearchParams: {
            _id: valueToArray,
            onCompany: true
        }
    })

    if (valueToArray.length == 0) return null;

    return (
        <div className="flex flex-col gap-1">
            <p className="text-gray-800 text-[14px]">
                <b className="font-medium">{title}</b>
            </p>
            {
                isLoading
                    ? <LoaderMultipleSkeleton />
                    : (
                        <ul className="flex flex-col gap-2">
                            {
                                companies.flat().map(({ _id: companyGroupId, displayName, businessName, taxCode }) => {
                                    const nameToUse = displayName || businessName || taxCode
                                    return (
                                        <li key={companyGroupId}>
                                            <div className="flex items-center gap-2">
                                                {
                                                    nameToUse && (
                                                        <Avatar className="w-[20px] h-[20px] bg-primary flex items-center justify-center">
                                                            <span className="font-medium text-[11px] text-white">
                                                                {String(nameToUse[0] || '').toUpperCase()}
                                                            </span>
                                                        </Avatar>
                                                    )
                                                }
                                                <h4 className="text-[12px] font-medium text-gray-700">
                                                    {nameToUse}
                                                </h4>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    )
            }
        </div>
    )
}