import { cn } from '@/lib/utils'
import '@/styles/scrollModify.css'
import { useRouter } from 'next/router'
import { ButtonToOpenMenu } from '../Header'
import TitleAndGoBack from '../TitleAndGoBack'
import { ReactNode } from 'react'

interface Props {
    title: string
    className?: string
    ListOfItems: ReactNode
    urlToReturn?: string
    isActiveGoBack?: boolean
    onCloseMenu?: null | (() => void)
}

export default function ReusableSubMenu({
    title,
    className = '',
    ListOfItems,
    isActiveGoBack = true,
    onCloseMenu = null
}: Props) {
    const router = useRouter()

    const handleRediretToHome = () => router.push('/')

    return <div className={cn("w-[225px] h-[100vh] modifyScroll overflow-y-auto flex flex-col gap-1  border-r-slate-200 border-r ", className)}>
        <div className='h-[64px] uppercase justify-between flex shrink-0 items-center px-4 border-b border-b-slate-200'>
            <TitleAndGoBack
                onGoBack={onCloseMenu || handleRediretToHome}
                isActiveGoBack={true}
                className={`border-none [&>svg]:w-[21px] mt-1 hover:bg-transparent  ${isActiveGoBack ? 'lg:hidden' : ''}  [&>svg]:h-[21px]`}
                title={title}
            />
        </div>
        <ul className='flex flex-col gap-2 pb-4 flex-grow px-2 mt-2'>
            {ListOfItems}
        </ul>
    </div>
}
