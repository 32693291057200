import { getCompany, isAuth } from "@/actions/auth"
import { BtnNextStep } from "@/components/buttons/BtnNextStep"
import { Button } from "@/components/ui/button"
import { CustomFormField } from "@/components/ui/custom-form-field"
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Form } from "@/components/ui/form"
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Textarea } from '@/components/ui/textarea'
import { Feature, getFeatureCompany, isFeatureEnabled } from "@/helpers/featureFlagHelpers"
import { VisitIcon } from "@/icons/AppsToreIcons"
import { setAction } from "@/redux/slices/crm"
import { zodResolver } from "@hookform/resolvers/zod"
import { Alert } from "@mui/material"
import _ from "lodash"
import { CalendarDays } from "lucide-react"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { z } from "zod"
import ModalReminder from "../BottomActions/Reminder/ModalReminder"
import { visitValues } from '../BottomActions/Reminder/constant/index'
import StatusTagSelect from '../ContactDrawer/StatusTagSelect'
import { createInteraction } from "../Prospect/libs/helpers/interaction"
import { useQuery } from "@tanstack/react-query"
import { getSellersList } from "@/actions/comercial/sale"
import { Command, CommandGroup, CommandItem } from "@/components/ui/command"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { UserAvatar } from "@/components/ui/user-avatar"
import Chip, { TailwindColor } from "@/components/ui/chip"
import { searchRegex } from "@/helpers/regex"
import InputWithSelect from "@/components/ui/input-with-select"
import { ShareSelector } from "../Visits/ShareSelecto"

moment.locale('es')

interface TransferModalInterface {
    modal: string | boolean;
    setModal: (value: (string | false), visited?: boolean) => void;
}

const visitSchema = z.object({
    state: z.string().min(1, { message: 'El resultado de la visita es requerido' }),
    comment: z.string().optional(),
    tag: z.string().optional(),
    shared: z.array(z.string()).optional()
})

export type VisitSchemaType = z.infer<typeof visitSchema>

const emptyValue = {
    state: '',
    comment: '',
    tag: '',
    shared: []
}

const VisitModal = ({ modal, setModal }: TransferModalInterface) => {
    const { statusTag, firstName, lastName } = useSelector((state: any) => state.crm.contact) || {}
    const [isCreatedReminder, setCreatedReminder] = useState(false)
    const [openReminderVisit, setOpenReminderVisit] = useState<boolean | string>(false)
    const [error, setError] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>('')

    const { comment: featureComment } = getFeatureCompany(Feature.VISIT_INTERACTION)
    const { commentWrite = 'required', maxChars = 0, minChars = 0 } = featureComment || {}

    const dispatch = useDispatch()

    const contextForm = useForm<VisitSchemaType>({
        resolver: zodResolver(visitSchema),
        defaultValues: emptyValue
    })

    const { data = { users: [] } } = useQuery({
        queryFn: getSellersList,
        queryKey: ['list-visit-users']
    })

    const isValidForm = (!contextForm.formState.isValid) || (commentWrite === 'required' && !(contextForm.getValues('comment') || '').trim())

    useEffect(() => {
        if (modal) contextForm.setValue('tag', statusTag || undefined)
        if (!modal) contextForm.reset(emptyValue)
    }, [modal])

    const handleClose = () => {
        dispatch(setAction('note'))
        setCreatedReminder(false)
        setModal(false, false)
    }

    const onHandleSubmit = async (values: VisitSchemaType) => {
        if (!modal) return setError('Error en el contacto')
        const { comment = '' } = values

        let error: string | null = null;

        if (!comment && commentWrite === 'required') {
            error = `El comentario de la visita es requerido`
        } else if (minChars > 0 && comment.length < minChars) {
            error = `El comentario debe de tener por lo menos ${minChars} ${minChars === 1 ? 'carácter' : 'caracteres'}`
        } else if (maxChars > 1 && comment.length > maxChars) {
            error = `El comentario no debe de superar los ${maxChars} caracteres`
        }

        if (error) return contextForm.setError('comment', {
            message: error
        })

        handleClose && handleClose()

        await createInteraction({
            dispatch,
            type: 'visit',
            data: {
                values,
                id: modal,
                onSuccess: (result) => {
                    if (!result || result.error) return setError((result?.error && typeof result.error == "string") ? result.error : 'Hubo un error al guardar visita')
                }
            }
        })
    }

    const company = getCompany()
    const statusTags = useMemo(() => _.get(company, 'statusTags', []).filter((s) => s.active), [])
    const visitResults = useMemo(() => _.get(company, 'visitResults', []).filter(v => v.active), [])

    return <>
        <Dialog open={!!modal} onOpenChange={handleClose}>
            <DialogContent className="sm:max-w-[425px] p-0">
                <div className="bg-[#F0F9FF] p-3 flex items-center gap-2 pl-4">
                    <VisitIcon height={'20px'} width={'20px'} />
                    <p className="text-sm font-semibold">Visita</p>
                </div>
                <div className="p-4">
                    <DialogHeader className="pb-4">
                        <DialogTitle>Describe tu visita</DialogTitle>
                    </DialogHeader>
                    <Form {...contextForm}>
                        <form onSubmit={contextForm.handleSubmit(onHandleSubmit)} id="form-visit">
                            <div className="flex flex-col gap-3">
                                {error && <Alert severity="error">{error}</Alert>}
                                <CustomFormField
                                    control={contextForm.control}
                                    label="Resultado"
                                    name="state"
                                    fnElement={({ field }) => (
                                        <Select
                                            value={field.value || undefined}
                                            onValueChange={field.onChange}
                                        >
                                            <SelectTrigger className="bg-[#F1F3F4] border-none">
                                                <SelectValue placeholder="Selecciona una opción" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        visitResults.map(({ code, name }) => (
                                                            <SelectItem value={code} key={code}>
                                                                {name}
                                                            </SelectItem>
                                                        ))
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    )}
                                />
                                <CustomFormField
                                    control={contextForm.control}
                                    label="Comentario"
                                    name="comment"
                                    fnElement={({ field }) => (
                                        <Textarea
                                            placeholder="Escribe aquí..."
                                            className="resize-none h-[175px] bg-[#F1F3F4] border-none"
                                            rows={4}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                {
                                    statusTags && (
                                        <CustomFormField
                                            control={contextForm.control}
                                            label="Etiqueta contacto"
                                            name="tag"
                                            fnElement={({ field }) => (
                                                <StatusTagSelect
                                                    value={field.value || undefined}
                                                    onValueChange={field.onChange}
                                                    name="statusTag"
                                                    list={statusTags}
                                                />
                                            )}
                                        />
                                    )
                                }
                                {isFeatureEnabled(Feature.ENABLE_SHARED_VISITS) && (
                                    <CustomFormField
                                        control={contextForm.control}
                                        label="Participantes"
                                        name="shared"
                                        fnElement={({ field }) => (
                                            <ShareSelector contextForm={contextForm} users={data.users} fieldValue={field.value} />
                                        )}
                                    />
                                )}
                                <DialogFooter className="flex w-full items-center sm:justify-between">
                                    <Button
                                        variant={'link'}
                                        className="min-w-fit"
                                        disabled={isValidForm || isCreatedReminder}
                                        onClick={() => setOpenReminderVisit(modal)}>
                                        <span className="flex items-center gap-2 shrink-0">
                                            <CalendarDays size={14} />
                                            {
                                                isCreatedReminder
                                                    ? 'Recordatorio agendado'
                                                    : 'Agendar recordatorio'
                                            }
                                        </span>
                                    </Button>
                                    <BtnNextStep
                                        hiddenArrow
                                        variant={'link'}
                                        form="form-visit"
                                        disabled={isValidForm}
                                        type="submit"
                                    >
                                        Marcar Visita
                                    </BtnNextStep>
                                </DialogFooter>
                            </div>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
        <ModalReminder
            open={openReminderVisit}
            onCLose={() => setOpenReminderVisit(false)}
            handleSuccess={() => setCreatedReminder(true)}
            customReminder={{ ...visitValues({ description: `${firstName || ''} ${lastName || ''}` }) }}
            className={'[&>button]:text-slate-800'}
        />
    </>

}

export default VisitModal