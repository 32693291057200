import { isAuth } from "@/actions/auth";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { Check, ChevronDown } from "lucide-react";
import { useState } from "react";

export interface ChangeSaleStatus {
    _id: string,
    status: string,
    title: string
};

interface StatusSelectProps {
    value?: string,
    onChangeValue: (status: ChangeSaleStatus) => void,
    editMode?: boolean
}

export function StatusSelect({ onChangeValue, value, editMode = true }: StatusSelectProps) {
    const [open, setOpen] = useState(false);
    const saleStatus = isAuth()?.group?.sale_status || [];

    const GetTrigger = () => {
        return (
            <button
                role="combobox"
                aria-expanded={open}
                className="max-w-full flex items-center hover:text-gray-800 relative text-start text-[13.5px] group text-gray-700"
            >
                {value || 'Seleccionar...'}
                {editMode && <ChevronDown size={16} className="opacity-50 ml-2 text-gray-800" />}
            </button>
        )
    };

    if (!editMode) return GetTrigger();

    return <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
            {GetTrigger()}
        </PopoverTrigger>
        <PopoverContent className="w-[250px] p-0">
            <Command>
                <CommandInput placeholder="Buscar estado..." />
                <CommandList className="h-max overflow-y-auto max-h-[220px] relative flex flex-col">
                    <CommandEmpty>No se encontro el estado.</CommandEmpty>
                    <CommandGroup>
                        {
                            saleStatus.map((status) => {
                                return (
                                    <CommandItem
                                        key={status._id}
                                        value={status._id}
                                        className="py-1"
                                        onSelect={() => onChangeValue(status)}
                                    >
                                        {status.title}
                                        <Check
                                            size={16}
                                            className={cn(
                                                "ml-auto",
                                                (value && (value == status._id || value == status.title))
                                                    ? "opacity-100"
                                                    : "opacity-0"
                                            )}
                                        />
                                    </CommandItem>
                                )
                            })
                        }
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
}