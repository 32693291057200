import { Badge } from "@/components/ui/badge"
import { CardDescription } from "@/components/ui/card"
import { ReactNode } from "react"

interface ContactDetailLiProps {
    icon?: null | ReactNode
    title: string
    data: any[]
    fieldColors?: any[]
}

export function ContactDetailLi({
    data,
    title,
    icon = null,
    fieldColors
}: ContactDetailLiProps) {
    return (
        <li>
            <CardDescription className="flex flex-wrap gap-2 line-clamp-4 text-[13px]">
                <span className="text-slate-800 text-[14px] flex items-center shrink-0 gap-1 font-medium">
                    {icon}
                    {title}
                </span>
                {data && data.map((value, index) => (
                    <Badge key={index} className="text-xs" style={{ backgroundColor: fieldColors ? fieldColors.find(f => f.name === value)?.color : undefined }}>
                        {value}
                    </Badge>
                ))}
            </CardDescription>
        </li>
    )
}
