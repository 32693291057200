

import { Button } from '@/components/ui/button';
import { SaleInteraction } from '@/interfaces/crm/contact';
import { Routes } from '@/routes';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import SaleBubbleLayout from './SaleBubbleLayout';

interface CancellationViewProps {
    interaction: SaleInteraction,
}

export default function CancellationView(props: CancellationViewProps) {
    const { interaction } = props;
    const sale = interaction?.content?.sale || {};
    const router = useRouter();

    const title = useMemo(() => {
        if (sale.saleStructureType === 'multiline') return 'Venta de múltiples productos ha sido cancelada.';
        return `La venta con ID ${sale.ticket_id || sale.ticket_sale_id} ha sido cancelada.`;
    }, [sale]);

    const redirectToSale = () => {
        return router.push(Routes.SALE_REPORT + `?id=${sale._id}`)
    };

    return (
        <SaleBubbleLayout
            createdAt={interaction.createdAt}
            classNameHeaderContent='bg-red-100 text-red-600'
            title='Venta cancelada'
            classNameContent="max-w-[440px]"
        >
            <div>
                <h3 className='text-[16px] font-medium mb-2 text-gray-700'>
                    {title}
                </h3>
                <p className='text-gray-500 text-[13px]'>
                    {interaction?.content?.cancelReason || 'Sin razón especificada'}
                </p>
                <Button
                    variant={'link'}
                    onClick={redirectToSale}
                    className="mt-1 px-0 h-max"
                >
                    Ver venta
                </Button>
            </div>
        </SaleBubbleLayout>
    )
}
