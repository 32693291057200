import React, { useState } from "react";
import {Avatar, AvatarTypeMap, Box, Button, Chip, Grid, IconButton, SvgIconTypeMap, Theme, Typography} from "@mui/material";
import moment from 'moment'
import { SxProps } from "@mui/system";
import { InstanceUser } from "../../interfaces/Interface";
import { Chat, Visibility, VisibilityOff, Voicemail } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";


interface TitleButtonInterfaceProps {
    title: string | JSX.Element,
    subtitle: string | JSX.Element,
    sx?: SxProps<Theme>,
    onClick?: () => void
}

const TitleButton = (props:TitleButtonInterfaceProps) => {
    let {title, subtitle, sx, onClick} = props
    return(
        <Grid onClick={onClick} component={Button} item container direction={"column"} sx={{...sx}} pt={2} alignItems="center">
           <Grid item> 
                <Typography fontSize={"24px"} fontWeight="600" sx={{textTransform: 'none'}}>{title}</Typography>
            </Grid>
            <Grid item><Typography fontSize={"12px"} color="#000" sx={{textTransform: "none"}} textAlign={"center"}>{subtitle}</Typography></Grid>
        </Grid>
)
}

TitleButton.defaultProps = {
    sx: {},
    onClick: () => {}
}

export default TitleButton;
