import { NotificationItem } from "@/components/Header/NavLink"
import { Button } from "@/components/ui/button"
import Loading from "@/components/ui/loading"
import { ListFilter } from "lucide-react"
import { SearchIcon } from "../Bubble/Icons/Crm"

interface IProps {
  isLoading: boolean
  count: number
  onAction: VoidFunction
  onChange: (value: string) => void
  value: string
}

export default function InputSearchInteraction({
  isLoading,
  count,
  onAction,
  onChange,
  value,
}: IProps) {
  return (
    <div className="relative">
      {isLoading ? (
        <Loading className="w-[13px] h-[13px] absolute left-3 top-0 bottom-0 m-auto" />
      ) : (
        <SearchIcon className="w-[13px] h-[13px] absolute left-3 top-0 bottom-0 m-auto" />
      )}
      <input
        value={value}
        className="flex-1  outline-none h-[35px] bg-[#F6F6F6] w-full py-2 text-slate-600 text-sm rounded-md placeholder:text-[11px] placeholder:text-slate-600 pr-12 pl-8"
        autoComplete="off"
        placeholder={`Buscar en conversación...`}
        onChange={(e) => onChange(e.target.value)}
      />
      <Button
        id="basic-button"
        variant={"ghost"}
        onClick={() => onAction()}
        className="  hover:bg-slate-200/50 rounded-l-none absolute w-[40px] 
bg-blue-100 hover:bg-blue-200 hover:text-primary text-primary right-0 -top-1 h-full  px-2 my-1"
      >
        <ListFilter size={19} />
        {count > 0 && (
          <NotificationItem
            notification={count}
            className="bg-primary absolute -top-1 -right-1"
          />
        )}
      </Button>
    </div>
  )
}
