import IconButtonTitle from "@/components/core/IconButtonTitle"
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogFooter, DialogTitle } from "@/components/ui/dialog"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Separator } from "@/components/ui/separator"
import { UserAvatar } from "@/components/ui/user-avatar"
import { Feature, PersonalFeature, isFeatureEnabled, isPersonalFeatureEnables } from "@/helpers/featureFlagHelpers"
import tagColorToPalette from '@/helpers/tagColorToPalette'
import { MessageCircleMore } from "@/icons"
import { ChannelsChatIcon, OriginalWppIcon } from "@/icons/AppsToreIcons"
import { ErrorOutline, Group, Person, Reply, WhatsApp } from "@mui/icons-material"
import Facebook from "@mui/icons-material/Facebook"
import Instagram from "@mui/icons-material/Instagram"
import { Badge } from "@mui/material"
import _ from "lodash"
import { Check, ChevronDown, ChevronUp, Mail, Plus } from "lucide-react"
import Link from "next/link"
import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

const channelsData = {
    "unofficial-whatsapp": {
        icon: <OriginalWppIcon height={'26px'} width={'26px'} />,
        name: 'WhatsApp'
    },
    'unofficial_wpp': {
        icon: <OriginalWppIcon height={'26px'} width={'26px'} />,
        name: 'WhatsApp'
    },
    'whatsapp_business': {
        icon: <OriginalWppIcon height={'26px'} width={'26px'} />,
        name: 'WhatsApp Business'
    },
    'wpp': {
        icon: <WhatsApp sx={{ height: "22px", width: "22px", color: '#5eb75b' }} />,
        name: 'WhatsApp'
    },
    'fb': {
        icon: <Facebook sx={{ height: "22px", width: "22px", color: '#1890ff' }} />,
        name: 'Facebook'
    },
    'ig': {
        icon: <Instagram sx={{ height: "22px", width: "22px", color: '#ba6b6c' }} />,
        name: 'Instagram'
    },
    'sms': {
        icon: <MessageCircleMore className="w-[18px] h-[18px] text-[#40DF4D]" />,
        name: 'SMS'
    },
    'whatsapp_360_dialog': {
        icon: <WhatsApp sx={{ height: "22px", width: "22px", color: '#5eb75b' }} />,
        name: '360 Dialog'
    }
}

export function SelectorBottomActions({ setOpenUpdateContact, handleAction, prospect, user, isActiveIcon, isFromSharedInbox }) {
    const [action, setAction] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [icon, setIcon] = useState<any>(null);

    const isEnabledWhatsappLink = !isFeatureEnabled(Feature.DISABLE_WHATSAPP_LINK)
    const handleOpenPopover = () => setOpen(!open);
    const extraChannelsFiltered = useMemo(() => prospect?.channelsList || [], [prospect])

    useEffect(() => {
        switch (user.appearanceConfig?.defaultChatAction ?? "channel") {
            case 'channel': {
                const invalidChannels = ['sms', 'mail', 'leads', 'whatsapp_360_dialog'];
                let eligibleChannels = [...prospect.channels, ...(prospect?.channelsList || [])]
                    .filter(channel => !invalidChannels.includes(channel.type))
                    .sort((a: any, b: any) => (a?.lastInteractionToContact + "").localeCompare(b?.lastInteractionToContact))

                let selectedChannel: Record<string, any> = {};
                if (eligibleChannels.length === 0) {
                    if (isFeatureEnabled(Feature.UNOFFICAL_WHATSAPP) || isPersonalFeatureEnables(PersonalFeature.PERSONAL_WHATSAPP)) {
                        setAction('WhatsApp Personal')
                        setIcon(<OriginalWppIcon height={'26px'} width={'26px'} />)
                        return handleAction("unofficial-whatsapp")()
                    }

                    return handleAction("note")()
                } else if (prospect.phones.length === 0) {
                    const validContactChannels = ['ig', 'fb'];
                    const filteredChannels = eligibleChannels.filter(channel => validContactChannels.includes(channel.type)); selectedChannel = filteredChannels[0];
                } else {
                    selectedChannel = eligibleChannels[0];
                }

                if (selectedChannel) {
                    let data = channelsData[selectedChannel.type] || channelsData['wpp']
                    setIcon(data.icon)
                    setAction(selectedChannel?.name || data.name)
                    handleAction("channel-chat", selectedChannel)()
                }
                break;
            }
            case 'personal_wpp': {
                handleAction("unofficial-whatsapp")()
                setAction('WhatsApp Personal')
                setIcon(<OriginalWppIcon height={'26px'} width={'26px'} />)
                break;
            }
            case 'note': {
                handleAction("note")()
                break;
            }
        }
    }, [prospect?._id])

    const handleRedirectToWhatsApp = () => {
        handleAction('whatsapp-note')()
        setAction('WhatsApp Link')
        return setIcon(channelsData['unofficial-whatsapp'].icon)
    }

    const handleClickTrigerChannel = () => {
        const { channels = [], channelsList = [], phones = [] } = prospect || {}
        const channelsLength = channels.length
        const channelListLengh = channelsList.length

        if (channelsLength == 0 && channelListLengh == 0 && phones.length == 0 && !(isFeatureEnabled(Feature.UNOFFICAL_WHATSAPP) || isPersonalFeatureEnables(PersonalFeature.PERSONAL_WHATSAPP))) return null

        if (channelsLength == 0 && channelListLengh == 0 && !(isFeatureEnabled(Feature.UNOFFICAL_WHATSAPP) || isPersonalFeatureEnables(PersonalFeature.PERSONAL_WHATSAPP)) && !isEnabledWhatsappLink) return setOpenUpdateContact()

        return handleOpenPopover()
    }

    return (
        <SelectPopover setOpenUpdateContact={setOpenUpdateContact} open={open} handleOpenPopover={handleClickTrigerChannel} setAction={setAction} setIcon={setIcon} user={user} extraChannelsFiltered={extraChannelsFiltered} handleAction={handleAction} prospect={prospect} action={action} handleRedirectToWhatsApp={handleRedirectToWhatsApp} isFromSharedInbox={isFromSharedInbox}
            trigger={
                <div
                    className={`cursor-pointer px-4 h-full flex space-x-2 items-center hover:bg-slate-50 shrink-0 overflow-hidden max-w-[150px]`}
                    style={{
                        boxShadow: `${isActiveIcon === 'email' || isActiveIcon === 'channel-chat' || isActiveIcon === 'unofficial-whatsapp' || isActiveIcon == "unofficial-personal-whatsapp" ? 'inset 0px 0px 6px -2px rgba(0, 0, 0, 0.8)' : 'inset 0px 0px 0px 0px rgba(0, 0, 0, 0)'}`
                    }}
                >
                    <div>
                        {
                            (prospect?.channels?.length == 0 && prospect?.channelsList?.length === 0 && prospect?.phones.length == 0)
                                ? <Plus />
                                : icon
                                    ? React.cloneElement(icon, { height: '24px', width: '24px' })
                                    : prospect?.channels?.length == 0 && prospect?.channelsList?.length && prospect?.phones.length
                                        ? <Plus className="text-[#ccc]" />
                                        : <ChannelsChatIcon height={'22px'} width={'22px'} /> //?
                        }
                    </div>
                    <div className="truncate">
                        <p className={prospect?.channels?.length == 0 && prospect?.channelsList?.length == 0 && !(isFeatureEnabled(Feature.UNOFFICAL_WHATSAPP) || isPersonalFeatureEnables(PersonalFeature.PERSONAL_WHATSAPP)) ? `text-[#ccc] text-[14px] text-center truncate` : `text-[#666] text-[14px] text-center truncate`}>
                            {
                                action
                                    ? action
                                    : prospect?.channels?.length == 0 && prospect?.channelsList?.length == 0 && !isEnabledWhatsappLink
                                        ? 'Sin Canales'
                                        : prospect?.channels?.length == 0 && prospect?.channelsList?.length && prospect?.phones.length == 0 && !isEnabledWhatsappLink
                                            ? 'Teléfono'
                                            : 'Canales'
                            }
                        </p>
                    </div>
                    {open ? <ChevronUp /> : <ChevronDown />}
                </div>
            }
        />
    );
}

const SelectPopover = ({ setOpenUpdateContact, trigger, handleAction, prospect, extraChannelsFiltered, user, setAction, setIcon, handleOpenPopover, open, action, handleRedirectToWhatsApp, isFromSharedInbox }) => {
    const sharedInboxUser = useSelector((state: any) => state.crm.sharedInboxUser);
    const showPersonalWhastappOfRealAgent = isFromSharedInbox && sharedInboxUser && (sharedInboxUser?.config?.enablePersonalWhatsapp || sharedInboxUser?.config?.enableUnofficalWhatsapp)

    return (
        <Popover open={open} onOpenChange={handleOpenPopover}>
            <PopoverTrigger className="h-full">
                {trigger}
            </PopoverTrigger>
            <PopoverContent
                className="flex flex-col items-center relative justify-center w-full p-1"
                align="start"
            >
                <div className="gap-2 overflow-y-auto popoverScroll max-h-[240px] ">
                    {
                        showPersonalWhastappOfRealAgent && (
                            <div className="relative flex items-center">
                                {action === 'WhatsApp Personal del agente' && <Check size={14} className="absolute left-2" />}
                                <IconButtonTitle
                                    onClick={() => {
                                        setIcon(<UserAvatar user={sharedInboxUser} className="w-[24px] h-[24px]" />)
                                        handleAction("unofficial-whatsapp-from-shared-inbox")()
                                        setAction('WhatsApp Personal del agente')
                                    }}
                                    inverse
                                    title="WhatsApp Personal del agente"
                                    sx={{ width: "120px" }}
                                    icon={<Badge
                                        className="relative"
                                        badgeContent={<Person sx={{ color: "#333", fontSize: '16px' }} />}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <UserAvatar user={sharedInboxUser} className="w-[23px] h-[23px]" />
                                    </Badge>
                                    }
                                />
                                <Separator className="my-1" orientation="horizontal" />
                            </div>
                        )
                    }
                    <UnOffcialWhatsappChannel
                        handleRedirectToWhatsApp={handleRedirectToWhatsApp}
                        extraChannelsFiltered={extraChannelsFiltered}
                        handleOpenPopoverChannel={handleOpenPopover}
                        handleAction={handleAction}
                        onChangeAction={setAction}
                        onChangeIcon={setIcon}
                        prospect={prospect}
                        action={action}
                    />

                    {prospect.channels &&
                        prospect.channels.map((channel, index) => (
                            <div className="relative items-center" key={index}
                                onClick={() => {
                                    if (channel.type == 'whatsapp_360_dialog') return;

                                    handleOpenPopover()
                                    setAction(channel?.name || channelsData[channel.type]?.name)
                                    channelsData[channel.type] && setIcon(channelsData[channel.type].icon)
                                }}
                            >
                                {action == channel.name && <Check className="absolute top-[50%] translate-y-[-50%] left-2" size={14} />}
                                {renderChannelOption(channel, handleAction, prospect)}
                                {index < prospect.channels.length - 1 || extraChannelsFiltered?.length > 0 && <Separator className="my-1" orientation="horizontal" />}
                            </div>
                        ))}
                    {extraChannelsFiltered?.map((channel, index) => (
                        <div className="relative items-center" key={index} onClick={() => { handleOpenPopover(), setAction(channel?.name), setIcon(channelsData?.[channel?.type]?.icon) }}>
                            {action == channel.name && <Check key={index} className="absolute top-[50%] translate-y-[-50%] left-2" size={14} />}
                            {renderExtraChannelOption(channel, handleAction, prospect, tagColorToPalette)}
                            {index < extraChannelsFiltered.length - 1 && <Separator className="my-1" orientation="horizontal" />}
                        </div>
                    ))}

                    {user && user?.emailProviders && user?.emailProviders?.length > 0 && user.config.enablePersonalEmail == true && (
                        <div className="relative items-center"
                            onClick={() => {
                                handleOpenPopover();
                                if (prospect?.emails?.length > 0) {
                                    setAction('Email');
                                    setIcon(<Mail className='text-primary w-[22px]' />);
                                }
                            }}>
                            <Separator className="my-1" orientation="horizontal" />
                            {action == 'Email' && <Check className="absolute top-[50%] translate-y-[-50%] left-2" size={14} />}
                            <IconButtonTitle
                                onClick={prospect?.emails.length > 0 ? handleAction("email") : () => setOpenUpdateContact()}
                                inverse
                                iconColor="#000"
                                title={prospect?.emails.length > 0 ? "Email" : "Añadir mail"}
                                sx={{ width: "120px" }}
                                icon={prospect?.emails.length > 0 ? <Mail className='text-primary w-[22px]' /> : <Plus className='text-primary w-[22px]' />}
                            />
                        </div>
                    )}
                </div>
            </PopoverContent>
        </Popover >
    );
};

const renderChannelOption = (channel, handleAction, prospect) => {
    switch (channel.type) {
        case "fb":
            return (
                <IconButtonTitle
                    key={channel.id}
                    onClick={handleAction("channel-chat", channel)}
                    inverse
                    iconColor="#1890ff"
                    title={channel.name || 'Facebook'}
                    sx={{ width: "120px" }}
                    icon={<Facebook sx={{ height: "22px", width: "22px" }} />}
                />
            );
        case "ig":
            return (
                <IconButtonTitle
                    key={channel.id}
                    onClick={handleAction("channel-chat", channel)}
                    inverse
                    iconColor="#ba6b6c"
                    title={channel.name || 'Instagram'}
                    sx={{ width: "120px" }}
                    icon={<Instagram sx={{ height: "22px", width: "22px" }} />}
                />
            );
        case "wpp":
            return (
                <IconButtonTitle
                    key={channel.id}
                    onClick={handleAction("channel-chat", channel)}
                    inverse
                    iconColor="#5eb75b"
                    title={channel.name || 'WhatsApp'}
                    sx={{ width: "120px" }}
                    icon={<WhatsApp sx={{ height: "22px", width: "22px" }} />}
                />
            );
        case 'whatsapp_business': {
            return (
                <IconButtonTitle
                    key={channel.id}
                    inverse
                    iconColor="#5eb75b"
                    sx={{ width: "120px" }}
                    title={channel?.name || 'WhatsApp Oficial'}
                    onClick={handleAction("channel-chat", channel)}
                    icon={<WhatsApp sx={{ height: "22px", width: "22px" }} />}
                />
            );
        }
        case 'whatsapp_360_dialog': {
            return (
                <IconButtonTitle
                    key={channel.id}
                    inverse
                    iconColor="#5eb75b"
                    sx={{ width: "120px" }}
                    title={channel?.name || '360 Dialog'}
                    // onClick={()=>handleAction("channel-chat", channel)}
                    disabled={true}
                    icon={<WhatsApp sx={{ height: "22px", width: "22px", color: '#5eb75b' }} />}
                />
            );
        }
        default:
            return null;
    }
};

const renderExtraChannelOption = (channel, handleAction, prospect, tagColorToPalette) => {
    const { phones } = prospect || {}
    const phonesEmpty = phones.length === 0 || phones[0] === ""

    switch (channel.type) {
        case "sms":
            return (
                <IconButtonTitle
                    onClick={handleAction("channel-sms", channel)}
                    inverse
                    iconColor="#40DF4D"
                    disabled={phonesEmpty}
                    title={channel.name || 'Sms'}
                    sx={{ width: "120px" }}
                    icon={<MessageCircleMore className="w-[18px] h-[18px]" />}
                />
            );
        default:
            return <IconButtonTitle
                disabled={prospect.phones.length === 0 || prospect.phones[0] === ""}
                onClick={handleAction("channel-chat", channel)}
                inverse
                iconColor="#5eb75b"
                title={channel.name}
                sx={{ width: "120px" }}
                icon={
                    <Badge
                        badgeContent={
                            channel.status === "available" ? (
                                <Group
                                    sx={{
                                        fontSize: "16px",
                                        color:
                                            prospect.phones.length === 0 ||
                                                prospect.phones[0] === ""
                                                ? "#ccc"
                                                : tagColorToPalette[
                                                    _.get(channel, "config.color", "")
                                                ].primary,
                                    }}
                                />
                            ) : (
                                <ErrorOutline sx={{ color: "#fbbf24", fontSize: '16px' }} />
                            )
                        }
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <OriginalWppIcon height={"26px"} width={"26px"} />
                    </Badge>
                }
            />
    }
};

const PersonalWhatsappButton = ({ prospect, handleAction }) => {
    const [open, setOpen] = useState(false)

    const isConnected = useMemo(() => {
        return prospect?.wpSession?.status == 'connected'
    }, [prospect?.wpSession?.status])

    if (prospect.wpSession) {
        return (<>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent>
                    <DialogTitle>
                        Cuenta de whatsapp desconectada
                    </DialogTitle>
                    <p className="text-stone-500">
                        Para poder enviar mensajes a este contacto por whatsapp, debes conectar tu cuenta de whatsapp personal.
                    </p>
                    <DialogFooter>
                        <Link href="/settings/new-whatsapp">
                            <Button>
                                Contactar
                            </Button>
                        </Link>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <IconButtonTitle
                onClick={isConnected ? handleAction("unofficial-whatsapp") : () => setOpen(true)}
                disabled={
                    prospect.phones.length === 0 || prospect.phones[0] === ""
                }
                inverse
                iconColor="#5eb75b"
                title="WhatsApp Personal"
                sx={{ width: "120px" }}
                icon={<Badge
                    badgeContent={isConnected ? <Person sx={{ color: "#333", fontSize: '16px' }} /> : <ErrorOutline sx={{ color: "#facc15", fontSize: '16px' }} />}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <OriginalWppIcon height={'26px'} width={'26px'} />
                </Badge>}
            />
        </>
        )
    }

    return (
        <IconButtonTitle
            onClick={handleAction(
                isFeatureEnabled(Feature.UNOFFICAL_WHATSAPP) || isPersonalFeatureEnables(PersonalFeature.PERSONAL_WHATSAPP)
                    ? "unofficial-whatsapp"
                    : "whatsapp-note"
            )}
            disabled={
                prospect.phones.length === 0 || prospect.phones[0] === ""
            }
            inverse
            iconColor="#5eb75b"
            title="WhatsApp Personal"
            sx={{ width: "120px" }}
            icon={<Badge
                badgeContent={isFeatureEnabled(Feature.UNOFFICAL_WHATSAPP) || isPersonalFeatureEnables(PersonalFeature.PERSONAL_WHATSAPP) ? <Person sx={{ color: "#333", fontSize: '16px' }} /> : <Reply sx={{ color: "#333", fontSize: '16px' }} />}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <OriginalWppIcon height={'26px'} width={'26px'} />
            </Badge>}
        />
    )
}


export function UnOffcialWhatsappChannel({
    handleRedirectToWhatsApp,
    handleOpenPopoverChannel,
    extraChannelsFiltered,
    onChangeAction,
    onChangeIcon,
    handleAction,
    prospect,
    action,
}) {
    const { channels = [] } = prospect || {}

    const handleClickChannel = () => {
        handleOpenPopoverChannel()
        onChangeAction('WhatsApp Personal')
        onChangeIcon(<OriginalWppIcon height={'26px'} width={'26px'} />)
    }

    if (isFeatureEnabled(Feature.UNOFFICAL_WHATSAPP) || isPersonalFeatureEnables(PersonalFeature.PERSONAL_WHATSAPP)) return (
        <div
            className="relative items-center"
            onClick={handleClickChannel}
        >
            {action == 'WhatsApp Personal' && <Check className="absolute top-[50%] translate-y-[-50%] left-2" size={14} />}
            <PersonalWhatsappButton prospect={prospect} handleAction={handleAction} />
            {channels && channels.length > 0 || extraChannelsFiltered?.length > 0 && <Separator className="my-1" orientation="horizontal" />}
        </div>
    )

    if (isFeatureEnabled(Feature.DISABLE_WHATSAPP_LINK)) return null

    return (
        <div className="relative items-center"
        >
            {action == 'whatsapp-note' && <Check className="absolute top-[50%] translate-y-[-50%] left-2" size={14} />}
            <IconButtonTitle
                inverse
                onClick={handleRedirectToWhatsApp}
                disabled={false}
                title={'WhatsApp Link'}
                sx={{ width: "300px" }}
                icon={channelsData['unofficial_wpp'].icon}
            />
            {channels && channels.length > 0 || extraChannelsFiltered?.length > 0 && <Separator className="my-1" orientation="horizontal" />}
        </div>
    )
}