import { cn } from "@/lib/utils"
import { ChevronRight } from "lucide-react"
import "moment/locale/es"
import Link from "next/link"

import { useRouter } from "next/router"
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger

} from "../ui/accordion"
import { SideBarTooltip } from "./NavLink"
import { NavSubMenuProps } from "./libs/interfaces-enums"
import { useEffect } from "react"
import useMenu from "./libs/hooks/useMenu"

export default function NavSubMenuItem({
  label,
  notification,
  children,
  open,
  isExpandable = true,
  value,
  icon,
  contentSlugs,
  slug,
  iconLabel,
  href,
  toolTipContent,
  isFirstSelect,
  isCollapsedMenu,
}: NavSubMenuProps) {
  const router = useRouter()
  const { handleChangeValue } = useMenu()

  useEffect(() => {
    if (contentSlugs?.includes(slug) && !open.includes(value)) {
      const newValue = [...open]
      newValue.push(value)
      handleChangeValue(newValue)
    }
  }, [isCollapsedMenu, slug, value])

  return <AccordionItem
    value={value}
    className={`border-none m-0 pb-0 w-full ${isCollapsedMenu ? `lg:border-b ${open?.includes(value) && isExpandable ? 'lg:bg-gray-100 lg:rounded-b-[4px] lg:rounded-t-[4px]' : 'duration-500'} ` : ""} `}
  >
    <div className={`flex `}>
      <SideBarTooltip content={toolTipContent || label} active={isCollapsedMenu}>
        <div className={`relative w-full items-center group flex ${isCollapsedMenu ? 'lg:w-[42px] h-[38px]' : 'gap-2'}`}>
          {
            isExpandable && <AccordionTrigger className={`absolute ${isCollapsedMenu ? '[&>span>svg]:w-[13px] [&>span>svg]:h-[13px] -left-[13px]' : '-left-[10px] w-4 [&>span>svg]:w-[12px] [&>span>svg]:h-[12px]'}  bottom-0 m-auto top-0 [&>svg]:hidden [&[data-state=open]>span>svg]:rotate-90 text-neutral-600`}>
              <span>
                <ChevronRight className="transition-transform" />
              </span>
            </AccordionTrigger>
          }
          <Link
            onClick={() => router.push(href)}
            href={href}
            className={cn(
              `border-b-none m-0 w-full duration-200 relative ${isCollapsedMenu
                ? `lg:px-0 lg:justify-center ${isFirstSelect ? 'lg:bg-primary lg:text-white hover:bg-primary/80' : 'lg:hover:bg-blue-100 '} flex lg:h-[38px] lg:items-center`
                : `hover:bg-blue-100 h-[30px] rounded-[4px] px-2 ${isFirstSelect ? 'bg-blue-100' : ''}`
              } [&[data-state=open]>div>div>div>span>svg]:rotate-90 py-[4px] lg:rounded-[4px] [&>svg]:hidden
             ${isCollapsedMenu ? isFirstSelect ? 'lg:bg-primary' : open?.includes(value) && isExpandable ? 'lg:bg-gray-200 lg:hover:bg-gray-200/60 h-[42px] w-full ' : '' : ''}`
            )}>
            <div className={`flex items-center w-full gap-3 ${!isCollapsedMenu ? '' : 'lg:justify-center lg:h-full'} text-slate-500 `}>
              <div className={`text-start ${isCollapsedMenu ? 'lg:group-hover:text-slate-500 ' : ``} flex items-center text-[13px] gap-1 font-medium`}>
                <div className={`${!isCollapsedMenu ? 'flex items-center gap-2' : 'lg:hidden'}`}>
                  {iconLabel || icon}
                  <span>
                    {label}
                  </span>
                </div>
                <div className={`flex group items-center ${isFirstSelect ? 'lg:[&>div>svg]:text-white' : ''} [&>svg]:w-[17px]  [&>svg]:h-[17px] `}>
                  <div className={`hidden ${isCollapsedMenu ? 'lg:flex' : ''}`}>
                    {icon}
                  </div>
                </div>
              </div>
            </div>
            {
              (notification || 0) > 0
                ? <div>
                  <div className="bg-[#6B7280] text-white w-[17px] flex items-center justify-center h-[17px]  text-[11px] ">
                    {notification}
                  </div>
                </div>
                : ""
            }
          </Link>
        </div>
      </SideBarTooltip >
    </div>
    <AccordionContent
      className={`border-none p-0 m-0 ${isExpandable ? ' ' : 'lg:hidden'}`}>
      <div className={`flex-col flex  ${isCollapsedMenu ? 'lg:bg-gray-100 w-[42px] relative lg:rounded-b-[4px]' : ''} gap-[2px]  mt-[2px] `}>
        {children}
      </div>
    </AccordionContent>
  </AccordionItem>
}
