import { createComission, updateComission } from "@/actions/comission";
import { LoadingButton } from "@/components/buttons/loading-button";
import CustomTabs from "@/components/tabs/CustomTabs";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import useGetUsersList from "@/hooks/useGetUsersList";
import { ComissionInterface, commisionsStatus, ModalPropsBase } from '@/interfaces/Interface';
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import Sections from "./sections";

interface CommisionModalProps extends ModalPropsBase {
    comissionToUpdate?: ComissionInterface | (Omit<ComissionInterface, 'splitDeal'> & {
        splitDeal: {
            user: string,
            percentage: number
        }[]
    }),
    receiptId?: string,
    onSucessAction?: (value: ComissionInterface) => void
}

export const CommisionSections = {
    General: 'general',
    SplitDeal: 'splitDeal'
} as const

export type CommisionSectionsType = typeof CommisionSections[keyof typeof CommisionSections]

const ComissionSchema = z.object({
    category: z.string(),
    bounty: z.number(),
    bountyCurrency: z.string(),
    status: z.enum(commisionsStatus),
    rejectionReason: z.string(),
    source: z.string(),
    splitDeal: z.array(z.object({
        user: z.string(),
        percentage: z.number()
    }))
});

export type ComissionSchemaType = z.infer<typeof ComissionSchema>;
export type UseFormReturnComission = UseFormReturn<ComissionSchemaType>;

const defaultComissionValues: ComissionSchemaType = {
    category: '',
    bounty: 0,
    bountyCurrency: 'USD',
    status: 'pending',
    rejectionReason: '',
    source: 'Carga manual',
    splitDeal: []
};

export default function ComissionModal({
    open,
    onOpenChange,
    comissionToUpdate,
    onSucessAction,
    receiptId
}: CommisionModalProps) {
    const [sectionSelected, setSectionSelected] = useState<CommisionSectionsType>(CommisionSections.General);
    const { usersToMap } = useGetUsersList({ enabled: true });

    const contextForm = useForm<ComissionSchemaType>({
        resolver: zodResolver(ComissionSchema),
        defaultValues: defaultComissionValues
    });

    const status = contextForm.watch('status')
    const rejectionReason = contextForm.watch('rejectionReason');
    const usersValues = contextForm.watch('splitDeal');

    const isModify = !!comissionToUpdate;
    const isValidForm = contextForm.formState.isValid && ((status == 'rejected' && rejectionReason.trim() != '') || status !== 'rejected')

    const { isPending, mutate } = useMutation({
        mutationFn: ({ type, body, id }: { type: 'update' | 'create', body: ComissionSchemaType, id?: string }) => {
            if (type == 'update') return updateComission(id as string, body);
            return createComission(body, receiptId);
        },
        onSuccess: ({ success, comission }: { success: boolean, comission?: ComissionSchemaType }, { body }) => {
            if (success && comission) {
                onSucessAction && onSucessAction({
                    ...comission,
                    splitDeal: comission.splitDeal.map(({ user, percentage }) => ({ user: usersToMap[user as string], percentage }))
                } as any);
            } else if (comissionToUpdate) {
                onSucessAction && onSucessAction({
                    ...comissionToUpdate,
                    ...body,
                    splitDeal: body.splitDeal.map(({ user, percentage }) => ({ user: usersToMap[user], percentage }))
                } as ComissionInterface)
            };

            onOpenChange(false);
        },
        onError: toastActionError
    })

    const onChangeScreen = (screen: CommisionSectionsType) => setSectionSelected(screen);

    const onSubmit = (values: ComissionSchemaType) => {
        mutate({
            type: isModify ? 'update' : 'create',
            id: comissionToUpdate?._id || '',
            body: values
        })
    };

    useEffect(() => {
        contextForm.reset({
            ...defaultComissionValues,
            ...comissionToUpdate,
            splitDeal: comissionToUpdate?.splitDeal
                ? comissionToUpdate.splitDeal.map(({ user, percentage }) => ({
                    user: typeof user == 'string' ? user : user._id,
                    percentage
                }))
                : []
        } as ComissionSchemaType);
    }, [comissionToUpdate]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className="sm:max-w-[550px] pb-0 px-0"
            >
                <DialogHeader className="px-5">
                    <DialogTitle>
                        {
                            isModify
                                ? 'Modificar comisión'
                                : 'Crear comisión'
                        }
                    </DialogTitle>
                </DialogHeader>
                <div className="h-[40px] shrink-0 mt-2 border-b border-gray-200">
                    <CustomTabs
                        value={sectionSelected}
                        classNameTab='flex-1 w-full'
                        onChangeValue={(value: CommisionSectionsType) => onChangeScreen(value)}
                        classNameButton='w-full items-center text-[17px] flex justify-center'
                        optionsList={[
                            ['General', CommisionSections.General],
                            ['División de comisión', CommisionSections.SplitDeal, {
                                disabled: usersValues.length == 0
                            }],
                        ]}
                    />
                </div>
                <div className="grid px-5 min-h-[50px] overflow-y-auto max-h-[450px] pb-2 pt-1">
                    <Form {...contextForm}>
                        <form
                            onSubmit={contextForm.handleSubmit(onSubmit)}
                            className="grid gap-5"
                            id="comissionForm"
                        >
                            <Sections
                                contextForm={contextForm}
                                sectionSelected={sectionSelected}
                            />
                        </form>
                    </Form>
                </div>
                <DialogFooter className='flex px-5 bg-gray-100 border-t-gray-200 border-t py-4'>
                    <LoadingButton
                        type="submit"
                        loading={isPending}
                        disabled={!isValidForm}
                        form="comissionForm"
                    >
                        {
                            isModify
                                ? 'Guardar cambios'
                                : 'Crear'
                        }
                    </LoadingButton>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export const toastActionError = () => {
    toast({
        title: 'Error',
        description: 'Hubo un problema al procesar la acción. Inténtalo de nuevo más tarde. Si el problema continúa, contacta al soporte técnico.',
        variant: 'destructive'
    });
}