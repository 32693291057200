import { ComissionSchemaType } from "@/components/comercial/comercialManagment/comissions/CommisionModal";
import { ComissionTypeSchemaType } from "@/components/company/comissions/ModalComissionType";
import { fetcher } from "@/helpers/fetcher";
import { formatSearchParams } from "@/helpers/formatSearchParams";
import { ComisionsStatusType, ComissionInterface, ComissionPolicy, ComissionType } from "@/interfaces/Interface";
import { ComissionPolicySchemaType } from "@/pages/company/comissions/calculus";

export const createComission = (body: ComissionSchemaType, receiptId?: string) => {
    console.log(`/comissions?receiptId=${receiptId}`);

    return fetcher<{ success: boolean, comission: ComissionInterface }>({
        url: `/comissions?receiptId=${receiptId}`,
        defaultContentType: true,
        method: 'POST',
        isApi: true,
        body
    })
};

export const deleteComission = (id: string) => {
    return fetcher<{ success: boolean }>({
        url: `/comercial/comissions/${id}`,
        defaultContentType: true,
        method: 'DELETE'
    });
};

export const updateComission = (id: string, body: Partial<ComissionSchemaType>) => {
    return fetcher<{ success: boolean }>({
        url: `/comissions/${id}`,
        defaultContentType: true,
        method: 'PATCH',
        isApi: true,
        body
    });
};

export const getComissions = (params: { [key: string]: any }) => {
    const searchParams = formatSearchParams(params);
    return fetcher<{ success: boolean, comissions: ComissionInterface[], countDocuments: number }>({
        url: `/comissions?${searchParams}`,
        isApi: true
    })
}

export const changeComissionStatus = (
    id: string,
    body: { status: ComisionsStatusType, rejectionReason?: string }
) => {
    return fetcher<{ success: boolean }>({
        url: `/comercial/comissions/${id}/changeStatus`,
        defaultContentType: true,
        method: 'PATCH',
        body
    })
}

export const createComissionType = (body: ComissionTypeSchemaType) => {
    return fetcher<{ success: boolean, comissionType: ComissionType }>({
        url: `/comercial/comissions/type`,
        defaultContentType: true,
        method: 'POST',
        body
    })
}

export const updateComissionType = (code: string, body: ComissionTypeSchemaType) => {
    return fetcher<{ success: boolean }>({
        url: `/comercial/comissions/type/${encodeURIComponent(code)}`,
        defaultContentType: true,
        method: 'PATCH',
        body
    })
};

export const deleteComissionType = (code: string) => {
    return fetcher<{ success: boolean }>({
        url: `/comercial/comissions/type/${encodeURIComponent(code)}`,
        defaultContentType: true,
        method: 'DELETE'
    })
}

export const createComissionPolicy = (body: ComissionPolicySchemaType) => {
    return fetcher<{ success: boolean, comissionPolicy: ComissionPolicy }>({
        url: `/comercial/comissions/policy`,
        defaultContentType: true,
        method: 'POST',
        body
    })
};

export const updateComissionPolicy = (id: string, body: ComissionPolicySchemaType) => {
    return fetcher<{ success: boolean }>({
        url: `/comercial/comissions/policy/${id}`,
        defaultContentType: true,
        method: 'PATCH',
        body
    })
};

export const getComissionPolicy = () => {
    return fetcher<{ success: boolean, comissionPolicy: ComissionPolicy }>({ url: `/comercial/comissions/policy` })
};

export const saveComissionToReceipts = (
    receiptId: string,
    comissionsIds: string[]
) => {
    return fetcher<{ success: true }>({
        url: `/receipt/${receiptId}/comissions`,
        defaultContentType: true,
        body: comissionsIds,
        method: 'PATCH'
    })
};


export type TotalBountyByCurrency = {
    pending: { [key: string]: number }, confirmed: { [key: string]: number } 
};

export const getComissionByPeriod = async (params: {
    page: number,
    limit: number,
    user?: string | undefined
    group?: string | undefined
}) => {
    const searchParams = formatSearchParams(params);
    return fetcher<{
        success: boolean,
        comissions: ComissionInterface[],
        totalBountyByCurrency: TotalBountyByCurrency,
        countDocuments: number
    }>({
        url: `/comissions/period?${searchParams}`,
    });
};

export const deleteComissionFromReceipt = (receiptId: string, comissionId: string) => {
    const url = `/comercial/sale/${receiptId}/${comissionId}`;
    return fetcher({ url, method: 'DELETE' });
}