import { BtnNextStep } from "@/components/buttons/BtnNextStep";
import { CardDescription } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { arrayFunctions } from "@/helpers/arrayFunctions";
import { setFilterInteraction } from "@/redux/slices/crm";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

export const filtersType = {
    'phoneCall': 'Llamadas',
    'note': 'Notas',
    'archiving': 'Cierres',
    'reminder': 'Recordatorios',
    'visit': 'Visitas',
    'tag': 'Cambios de etiquetas',
    'message': 'Mensajes',
    'saleupdate': 'Solicitudes'
} as const

//['label','code']
const filters = Object.entries(filtersType)

export default function FiltersSection({
    handleChangeSection,
    sectionActive,
    activeFilters,
    isLoading
}) {
    const [isSaveFilters, setSaveFilters] = useState<boolean>(false)
    const [stageOfFilters, setStageOfFilters] = useState<Record<string, null | string[]>>({})
    const countActiveFiltersInStage = useMemo(() => Object.values(stageOfFilters)?.flat().length, [stageOfFilters])

    const dispatch = useDispatch()

    useEffect(() => {
        if (sectionActive == 'filters') {
            setStageOfFilters(activeFilters)
        }
    }, [sectionActive])

    useEffect(() => {
        if (!isLoading && sectionActive === 'filters' && isSaveFilters) {
            handleChangeSection('information')
            setSaveFilters(false)
        }
    }, [isLoading, isSaveFilters])

    useEffect(() => {
        if (isLoading && sectionActive === 'filters') {
            setSaveFilters(true)
        }
    }, [isLoading])

    const handleSelectFilter = useCallback((type: string, code: string) => {
        const allFilters = { ...stageOfFilters }
        const filters = allFilters?.[type]

        if (!filters) {
            allFilters[type] = [code]
        } else {
            allFilters[type] = arrayFunctions.deleteOrAddElementByID(code, filters)
        }

        setStageOfFilters(allFilters)
    }, [stageOfFilters])

    const handleSelectAll = () => {
        const allCodes = filters.map(filter => filter[0])
        const allFilters = { ...stageOfFilters }
        allFilters['type'] = allFilters['type']?.length === allCodes.length
            ? []
            : allCodes
        setStageOfFilters(allFilters)
    }

    const handleConfirmFilters = () => {
        dispatch(setFilterInteraction(stageOfFilters))
    }

    return <div>
        <div className="flex items-center justify-between mb-2">
            <button className="text-primary text-[13px] font-medium" onClick={() => handleSelectAll()}>
                Seleccionar todo
            </button>
            {
                countActiveFiltersInStage > 0 && <button
                    onClick={() => setStageOfFilters({ 'type': [] })}
                    className="text-primary text-[13px] font-medium">
                    Limpiar
                </button>
            }
        </div>
        <ul>
            {
                filters.map(([code, label]) => (
                    <li className="cursor-pointer" onClick={() => handleSelectFilter('type', code)}>
                        <div className="border-b w-full flex justify-between py-[11px]">
                            <CardDescription className="font-medium text-[13px] text-slate-400">
                                {label}
                            </CardDescription>
                            <div>
                                <Checkbox
                                    checked={stageOfFilters?.['type']?.includes(code)}
                                    onClick={() => handleSelectFilter('type', code)}
                                />
                            </div>
                        </div>
                    </li>
                ))
            }
        </ul>

        <BtnNextStep
            onClick={() => handleConfirmFilters()}
            hiddenArrow
            variant={'default'}
            disabled={isLoading}
            className="w-full mt-8 h-9 rounded-sm uppercase text-[13px]"
            loading={isLoading}
        >
            Aplicar filtros
        </BtnNextStep>
    </div>
}