import { Close } from "@mui/icons-material";
import { Button, Drawer, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";

interface DrawerClose {
    children: ReactNode;
    open: boolean;
    setOpen: (toSet: boolean) => void;
    title?: string;
    bottomChildren?: ReactNode;
}

const DrawerClose = ({
    children,
    open,
    setOpen,
    title,
    bottomChildren,
}: DrawerClose) => {
    return (
        <Drawer anchor={"right"} open={open} onClose={() => setOpen(!open)}>
            <Grid
                px={2}
                mt={1}
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                direction="row"
                sx={{maxWidth: '400px', width: '100vw'}}
            >
                <Grid item>
                    <Typography fontSize={"28px"} fontWeight={100}>
                        {title}
                    </Typography>
                </Grid>
                <Grid
                    item
                    sx={{
                        cursor: "pointer",
                        color: "#0080ff",
                        "&:hover": {
                            color: "#438bd7",
                        },
                    }}
                    onClick={() => setOpen(false)}
                >
                    <Close sx={{ fontSize: "32px" }} />
                </Grid>
            </Grid>
            <Grid container p={2} sx={{ overflowY: "auto", overflowX: 'hidden', maxWidth: '400px', width: '100vw'}} >
                {children}
            </Grid>
            <Grid px={2} p={1} sx={{maxWidth: '400px', width: '100vw'}}>
                {bottomChildren ? bottomChildren : ""}
            </Grid>
        </Drawer>
    );
};

export default DrawerClose;
