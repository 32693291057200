import { ContactService } from '@/actions/crm/contact-controller'
import { setSubConversations } from '@/redux/slices/crm'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'

export default function useGetSubConversations() {
    const [lastContactId, setLastContactId] = useState('')
    const prospect = useSelector((state: any) => state.crm.contact)
    const subConversations = useSelector((state: any) => state.crm.subConversations)
    const contactId = prospect._id

    const dispatch = useDispatch()

    let requestList = useQuery({
        queryKey: ['sub-conversations-list', contactId],
        queryFn: () => ContactService.getSubConversations(contactId),
        enabled: (lastContactId !== contactId) || subConversations.length === 0
    })
    

    useEffect(() => {
        const subConversationsData = requestList.data?.subConversations
        if (!!subConversationsData) {
            dispatch(setSubConversations(subConversationsData))
        }
    }, [requestList])

    useEffect(() => {
        setLastContactId(prospect._id)
    }, [prospect])

    return {
        subConversations: subConversations || [],
        ...requestList
    }
}
