import useDebounce from "@/components/core/functions/useDebouce";
import { ContactAvatar } from "@/components/crm/Prospect";
import { Button } from "@/components/ui/button";
import InputWithSelect from "@/components/ui/input-with-select";
import useGetContacts, { IPreviewContact } from "@/hooks/crm/useGetContacts";
import { XIcon } from "lucide-react";
import { useState } from "react";

export default function MultipleContactField({
    onChangeValue,
    value,
    disabled,
    multiple = true,
}) {
    const [searchValue, setSearchValue] = useState<string>('');
    const searchWithDebounce = useDebounce(searchValue, 500)

    const { contacts, isLoading } = useGetContacts({
        enabled: !!((searchWithDebounce as string).trim()),
        externalSearchParams: {
            search: searchWithDebounce,
            onCompany: true,
            limit: 15,
            page: 1
        }
    })

    const localValue = value ? [value].flat() : []
    const valuesSelected = localValue.length
    const contactList = !searchValue ? [] : contacts

    const getFormatContact = (contact: IPreviewContact) => {
        const { lastName, firstName } = contact || {}
        return {
            value: contact,
            label: lastName ? `${firstName} ${lastName}` : firstName
        }
    }

    const onHandleChangeValue = (values: IPreviewContact[]) => {
        const newValues = [...localValue, ...values];
        const uniqueValues = Array.from(new Map(newValues.map(item => [item._id, item])).values());
        onChangeValue(!multiple ? values[0] : uniqueValues);
    }

    const onHandleDeleteValue = (inx: number) => {
        const allValue = [...localValue]
        allValue.splice(inx, 1);
        onChangeValue(multiple ? allValue : null)
    }

    return (
        <div className={'grid gap-2'}>
            <InputWithSelect
                multiple
                strict={true}
                value={[]}
                isLoading={isLoading}
                disabled={disabled}
                placeholder="Buscar contacto.."
                options={contactList.map(getFormatContact)}
                onChangeInputSearch={(value) => setSearchValue(value)}
                emptyIndicator={'No se encontró resultado para la búsqueda...'}
                onChangeValue={(values) => onHandleChangeValue(values as any[])}
                badgeClassName="hover:bg-blue-50/90 hover:text-blue-700 bg-blue-50 text-blue-800"
            />
            {
                localValue.length > 0 && (
                    <ul className="grid gap-3 px-2">
                        {
                            multiple && (
                                <li>
                                    <h2 className="text-[13px] text-gray-500">
                                        {valuesSelected} {valuesSelected > 1 ? 'Contacto seleccionado' : 'Contactos seleccionados'}
                                    </h2>
                                </li>
                            )
                        }
                        {
                            localValue.map(({ lastName, _id: contactId, firstName, statusTag }, index) => (
                                <li key={contactId}>
                                    <div className={`flex w-full justify-between ${index === localValue.length - 1 ? 'mb-2' : ''}`}>
                                        <div className="flex items-center gap-2">
                                            <ContactAvatar
                                                firstName={firstName}
                                                lastName={lastName}
                                                className="w-[25px] h-[25px]"
                                                statusTag={statusTag}
                                            />
                                            <h4 className="text-[14px] font-medium text-gray-700">
                                                {firstName} {lastName}
                                            </h4>
                                        </div>
                                        {
                                            !disabled && (
                                                <Button
                                                    size={'icon'}
                                                    variant={'ghost'}
                                                    className="w-[26px] h-[26px]"
                                                    onClick={() => onHandleDeleteValue(index)}
                                                >
                                                    <XIcon size={14} />
                                                </Button>
                                            )
                                        }
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                )
            }
        </div>
    )
}