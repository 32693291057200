import React from 'react';
import { Sheet, SheetContent } from '../ui/sheets'
import Prospect from './Prospect'
import { customStyleBubbles } from './Prospect/libs/constants/index';

export default function ProspectSubMenu({ open, onClose }) {
    const isActive = !!open

    return (
        <>
            <Sheet open={isActive} onOpenChange={() => onClose()}>
                <SheetContent
                    overlayType='transparent'
                    className='data-[state=closed]:duration-100 shadow-none w-w-[500px] data-[state=open]:duration-300'
                    hiddenBtnClose
                >
                    <div className='absolute   w-full h-full top-0 left-0'>
                        <Prospect
                            id={open}
                            className="w-full"
                            customStylesBubbles={{ ...customStyleBubbles }}
                            onClose={() => onClose()}
                            forceBack
                        />
                    </div>
                </SheetContent>
            </Sheet>
        </>
    )
}
