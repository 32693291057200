import { Card, CardContent, CardTitle } from "@/components/ui/card";
import { CustomFormField } from "@/components/ui/custom-form-field";
import { GoogleMeetIcon, PersonMeetingIcon, ZoomIcon } from "@/icons";
import { SectionPropsBase } from "./AddMeetingModal";

interface SelectMeetingTypeProps extends SectionPropsBase { }

export const meetingTypes = {
    zoom: {
        icon: <ZoomIcon />,
        label: 'Zoom',
    },
    meet: {
        icon: <GoogleMeetIcon />,
        label: 'Google Meet',
    },
    person: {
        icon: <PersonMeetingIcon />,
        label: 'Presencial',
    }
}

export function SelectMeetingType({ contextForm }: SelectMeetingTypeProps) {
    const meetingType = contextForm.watch('meetingType')

    const onHandleSelectMeetingType = (type: string) => {
        let value = type
        if (value === meetingType) value = ''
        contextForm.setValue('meetingType', value)
    }

    return (
        <div className="flex flex-col">
            <CustomFormField
                name="meetingType"
                isRequired={true}
                control={contextForm.control}
                label="Medio de la reunión"
                fnElement={() => (
                    <ul className="grid grid-cols-1 md:grid-cols-3 mt-1 gap-2">
                        {
                            Object.entries(meetingTypes).map(([key, { icon, label }]) => {
                                const isSelected: boolean = meetingType == key
                                return (
                                    <Card
                                        key={key}
                                        onClick={() => onHandleSelectMeetingType(key)}
                                        className={`flex flex-col justify-center items-center cursor-pointer w-full h-[100px] ${isSelected ? 'border-2 border-primary shadow-primary' : 'border'}`}
                                        style={{
                                            boxShadow: isSelected
                                                ? '0 0 10px 2px rgba(0, 128, 255, 0.25)'
                                                : 'none'
                                        }}
                                    >
                                        <CardContent className='p-4 gap-3 flex flex-col items-center justify-center'>
                                            {icon}
                                            <CardTitle className="text-[14px] font-medium text-gray-600">
                                                {label}
                                            </CardTitle>
                                        </CardContent>
                                    </Card>
                                )
                            })
                        }
                    </ul>
                )}
            />
        </div>
    )
}