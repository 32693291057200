import { Box, Grid, Modal, Skeleton, Stack } from "@mui/material"
import { useEffect, useState } from "react"


const ImageInsideBubble = ({ media }: { media: string }) => {
    const [fullScreen, setFullScreen] = useState(false)
    const [imageIsLoaded, setImageIsLoaded] = useState(false)

    useEffect(() => {
        setImageIsLoaded(false)
    }, [media])

    return (
        <Stack>
            <Modal open={fullScreen}>
                <Grid container onClick={() => setFullScreen(false)} sx={{ width: '100vw', height: '100vh' }} alignContent="center" justifyContent={"center"}>
                    <Grid item sx={{ maxWidth: '100%', maxHeight: '100%' }}>
                        <img src={media} style={{ maxWidth: '100vw', maxHeight: '100vh' }} loading="lazy" />
                    </Grid>
                </Grid>
            </Modal>
            <Box onClick={() => setFullScreen(true)}  sx={{ maxHeight: '250px', background: '#ffffff73', overflow: 'hidden', display: 'flex', textAlign: 'center', justifyContent: "center", borderRadius: '6px' }}>
                {media && <img src={media} style={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                }} onLoad={() => {
                    setImageIsLoaded(true)
                }}
                />
                }
                {(!media || !imageIsLoaded) &&
                    <Skeleton variant="rectangular" sx={{
                        maxWidth: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                        borderRadius: '5px'
                    }}
                        height={200}
                        width={250}
                    />
                }
            </Box>
        </Stack>
    )
}

export default ImageInsideBubble