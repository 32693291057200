import { Skeleton } from "@/components/ui/skeleton";

export default function SplitDealLoaderContent() {
    return (
        <div className="grid gap-2">
            <Skeleton className="w-[160px] mb-1 h-3" />
            <Skeleton className="w-[250px] h-2.5" />
            <Skeleton className="w-[220px] h-2.5" />
            <Skeleton className="w-[210px] h-2.5" />
            <Skeleton className="w-[200px] h-2.5" />
        </div>
    )
}