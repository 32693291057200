import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

export default function PrimaryButton({
    children,
    className = "",
    onAction = null,
    type = "button",
    form = "",
    disabled = false,
  }: any) {
    return (
      <Button
        form={form}
        disabled={disabled}
        variant={"ghost"}
        type={type as "button" | "submit"}
        onClick={onAction}
        className={cn(
          `text-primary hover:bg-slate-50 hover:text-primary flex [&>svg]:w-[15px] [&>svg]:h-[15px]   sm:[&>svg]:w-[18px] sm:[&>svg]:h-[18px]  items-center gap-2`,
          className
        )}
      >
        {children}
      </Button>
    )
  }