import { Card, CardContent, CardTitle } from '@/components/ui/card'
import Chip, { TailwindColor } from '@/components/ui/chip'

export default function SubConversationCard({ subConversation, onSelect }) {
    const { firstName, lastName, agent, status } = subConversation || {}
    const { name, lastname } = agent || {}

    const fullNameContact = `${firstName} ${lastName}`
    const agentFullName = `${name} ${lastname}`

    const { label, color } = generateSubConversationState({ state: status })

    return (
        <Card onClick={() => onSelect(subConversation)} className='flex  hover:bg-accent  cursor-pointer items-center w-full hadow-none rounded-sm pb-0 mb-0 m-0 p-0'>
            <CardContent className="flex flex-col p-2 px-3 w-full">
                <div className="flex items-center justify-between w-full gap-2">
                    <CardTitle className='text-[14px] truncate text-gray-800'>
                        {fullNameContact}
                    </CardTitle>
                    <Chip className='text-[10px] px-2 h-5 shrink-0' color={color as TailwindColor}>
                        <span className="shrink-0 truncate">
                            {label}
                        </span>
                    </Chip>
                </div>
                <p className="text-[12px] text-gray-400">
                    {agentFullName}
                </p>
            </CardContent>
        </Card>
    )
}

const generateSubConversationState = ({ state }: { state: 'new' | 'closed' | 'disregarded' | 'sold' | 'reminder' | 'active' | 'overdue_reminder' }): { label: string, color: TailwindColor } => {
    const mapState: Record<string, { label: string, color: TailwindColor }> = {
        new: {
            label: 'Nuevo',
            color: 'blue'
        },
        closed: {
            label: 'Cerrado',
            color: 'orange'
        },
        disregarded: {
            label: 'Desatendido',
            color: 'red'
        },
        sold: {
            label: 'Vendido',
            color: 'green'
        },
        reminder: {
            label: 'Recordatorio',
            color: 'blue'
        },
        active: {
            label: 'Activo',
            color: 'green'
        },
        overdue_reminder: {
            label: 'Recordatorio vencido',
            color: 'amber'
        }
    }

    const data = mapState[state] || mapState['new']
    return data
}