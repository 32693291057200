import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isAuth } from '../../actions/auth';
import { getUsersGroup } from '../../actions/group';
import { setMenuOpen, setSelectedUser, setUsers, setUsersGroup } from '../../redux/actions/main';
import UserList from '../core/UserList';
import { Input } from '../ui/input';


function MenuSellers({ children, usersGroup, setUsersGroup, users, setSelectedUser, active }: any) {
    let [user, setUser] = useState({})
    let [loading, setLoading] = useState(false)
    let [openSearch, setOpenSearch] = useState(false)
    let [search, setSearch] = useState("")

    useEffect(() => {
        if (active) {
            setUser(isAuth())
            if (!users || users.length > 0) setLoading(true)
            getUsersGroup().then((u) => {
                if (!u) return
                setUsersGroup(u)
            })
            setSelectedUser(null)
        }
    }, [])

    return !active ? <div>{children}</div> : (
        <div>
            <div className='flex flex-col w-full'>
                <div className='mb-3'>
                    <div className='flex w-full items-center'>
                        <Input
                            placeholder='Buscar...'
                            className='w-full bg-slate-100 border'
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                            autoComplete='off'
                            autoCorrect='off'
                            autoCapitalize='off'
                            spellCheck='false'
                            autoFocus={true}
                        />
                    </div>
                </div>
                <UserList groups={usersGroup} search={search} loading={loading} />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    open: state.main.open,
    users: state.main.users,
    selectedUser: state.main.selected_user,
    usersGroup: state.main.usersGroup
})

const mapDispatchToProps = {
    setOpen: setMenuOpen,
    setUsers: setUsers,
    setUsersGroup: setUsersGroup,
    setSelectedUser: setSelectedUser
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuSellers)