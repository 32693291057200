import ImageInsideBubble from "./ImageInsideBubble"
import InformationMedia from "./information-media"

export default function ImageInteractionChannel({
    media,
    children,
    description,
    status,
    date,
    channel
}) {
    return (
        <div
            className={`justify-center relative flex md:w-[var(--width-bubble-media-type)] flex-col flex-grow`}
            style={{ padding: 8 }}
        >
            <div className="relative">
                <ImageInsideBubble media={media} />
                <InformationMedia
                    date={date}
                    channel={channel}
                    description={description}
                    status={status}
                />
            </div>
            {children}
        </div>
    )
}

