import { Button } from '@/components/ui/button'
import { FormField } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import SimpleTooltip from '@/components/ui/simple-tooltip'
import { useToggle } from '@/hooks/useToggle'
import { cn } from '@/lib/utils'
import { AlarmClock, PencilLine, Plus, Trash2 } from 'lucide-react'
import EditReminderModal from './EditReminderModal'
import { SchemaEditReminderType } from './utils/constants'

const defaultReminder = {
    unitAndNumber: {
        number: 30,
        unit: 'minutes'
    },
    messageContent: {
        message: ''
    }
}

const unitOptions = [
    {
        label: 'Minutos',
        singular: 'Minuto',
        code: 'minutes'
    },
    {
        label: 'Horas',
        singular: 'Hora',
        code: 'hours'
    },
    {
        label: 'Días',
        singular: 'Día',
        code: 'days'
    }
]

export default function CreateReminders({
    contextForm,
    label = 'Recordatorio',
    classNameLabel,
    maxReminders = 3
}: {
    contextForm: any,
    label?: string,
    maxReminders?: number
    classNameLabel?: string
}) {
    const reminders = contextForm.watch('reminders') || []

    const onHandleAddReminder = () => {
        contextForm.setValue('reminders', [...reminders, defaultReminder])
    }

    const onHandleDeleteReminder = (inx: number) => {
        const allValues = [...reminders]
        allValues.splice(inx, 1)
        contextForm.setValue('reminders', allValues)
    }

    return (
        <div className='flex flex-col gap-1'>
            <div className="flex items-center pr-2 mb-2 justify-between">
                <Label className={cn(classNameLabel)}>
                    {label}
                </Label>
                {
                    reminders.length < maxReminders && (
                        <button
                            className="font-medium text-[13px] h-[20px] hover:text-primary/80 duration-100 text-primary flex items-center"
                            onClick={onHandleAddReminder}
                            type='button'
                        >
                            <Plus size={16} />
                            Agregar recordatorio
                        </button>
                    )
                }
            </div>

            <ul className='grid gap-2'>
                {
                    reminders.map((__, inx: number) => (
                        <li key={`reminder-item-${inx}`}>
                            <ReminderItem
                                index={inx}
                                contextForm={contextForm}
                                onHandleDeleteReminder={onHandleDeleteReminder}
                            />
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export function ReminderItem({
    contextForm,
    onHandleDeleteReminder,
    index
}) {
    const [openEditReminder, toggleEditReminder] = useToggle<boolean>(false)
    const values = contextForm.watch(`reminders.${index}`)

    const onChangeMessage = (newValues: SchemaEditReminderType) => {
        contextForm.setValue(`reminders.${index}`, { ...values, ...newValues })
    }

    const onHandleChangeUnit = (unit: string) => {
        contextForm.setValue(`reminders.${index}.unitAndNumber`, { number: 1, unit })
    }

    const onHandleChangeReminderValue = (value: string) => {
        let formatValue = Number(value.replace(/[^\d+]/g, ''));
        let unit = values.unitAndNumber.unit;

        if (unit === 'minutos' && formatValue > 60) {
            formatValue = 1;
        } else if (unit === 'hours' && formatValue > 24) {
            formatValue = 1;
            unit = 'days'
        } else if (unit === 'days' && formatValue > 10) {
            formatValue = 10;
        }

        contextForm.setValue(`reminders.${index}.unitAndNumber.number`, formatValue);
    }

    return (
        <div className='w-full group'>
            <EditReminderModal
                onOpenChange={toggleEditReminder}
                onSaveChange={onChangeMessage}
                open={!!openEditReminder}
                defaultValue={values}
            />
            <FormField
                control={contextForm.control}
                name={`reminders.${index}`}
                render={({ field }) => {
                    const value = field.value

                    return (
                        <div className="flex items-center gap-3">
                            <div className="flex flex-grow items-center gap-4">
                                <AlarmClock
                                    size={17}
                                    className='shrink-0 text-gray-600'
                                />
                                <div className="flex items-center gap-1">
                                    <DurationPicker
                                        number={value.unitAndNumber.number}
                                        onChangeNumber={(newNumber) => onHandleChangeReminderValue(newNumber)}
                                        onChangeUnit={(newUnit) => onHandleChangeUnit(newUnit)}
                                        unit={value.unitAndNumber.unit}
                                        options={unitOptions}
                                    />
                                    <SimpleTooltip content="Editar">
                                        <Button
                                            size={'icon'}
                                            variant="outline"
                                            className='relative'
                                            onClick={toggleEditReminder}
                                            type='button'
                                        >
                                            {
                                                !!(value.messageContent.message) && (
                                                    <span className={'w-2 h-2 absolute bg-primary rounded-full -top-1 -right-1'}></span>
                                                )
                                            }
                                            <PencilLine size={17} />
                                        </Button>
                                    </SimpleTooltip>
                                </div>
                            </div>
                            <p className="text-gray-700 md:block hidden font-medium text-[13px] ml-2">
                                antes de la reunión
                            </p>
                            <SimpleTooltip content={'Eliminar recordatorio'}>
                                <Button
                                    type='button'
                                    size={'icon'}
                                    variant={'ghost'}
                                    onClick={() => onHandleDeleteReminder(index)}
                                >
                                    <Trash2 size={17} className='text-red-500' />
                                </Button>
                            </SimpleTooltip>
                        </div>
                    )
                }}
            />
        </div>
    )
}


interface DurationPickerProps {
    number: number,
    unit: string,
    onChangeUnit: (value: string) => void,
    onChangeNumber: (value: string) => void,
    className?: string
    options: {
        label: string,
        singular: string,
        code: string
    }[]
}

export function DurationPicker({
    number,
    unit,
    onChangeUnit,
    onChangeNumber,
    options,
    className
}: DurationPickerProps) {
    return (
        <div className={cn('flex items-center h-[35px]', className)}>
            <Input
                onChange={(e) => onChangeNumber(e.target.value)}
                value={String(number)}
                className={'w-[70px] h-full rounded-r-none'}
            />
            <Select
                value={unit}
                onValueChange={onChangeUnit}
            >
                <SelectTrigger className="w-[100px] h-full rounded-l-none">
                    <SelectValue defaultValue={'minutes'} />
                </SelectTrigger>
                <SelectContent>
                    {
                        options.map(({ code, label, singular }) => (
                            <SelectItem value={code} key={code}>
                                {
                                    (number == 1)
                                        ? singular
                                        : label
                                }
                            </SelectItem>
                        ))
                    }
                </SelectContent>
            </Select>
        </div>
    )
}