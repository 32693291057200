


import { getCompany, isAuth } from '@/actions/auth';
import { addContactsToCompanyGroup, BodyForAddContacts } from '@/actions/comercial/companygroup';
import useGetCompanyGroup from '@/components/comercial/company-groups/company/utils/hooks/useGetCompanyGroup';
import { useToggle } from '@/hooks/useToggle';
import { Contact } from '@/interfaces/crm/contact';
import { AdditionalCompanyField, CompanyGroupContact, ICompanyGroup } from '@/interfaces/Interface';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { SectorSelected } from '../..';

export default function useCompanyGroupDrawer(id: string) {
    const [sectorSelectedForCreation, setSectorSelectedForCreation] = useState<undefined | SectorSelected>();
    const [addedContactLoader, setAddedContactLoader] = useState<[string | undefined, number] | undefined>();

    const [openAskAddContact, toggleAskContact] = useToggle<boolean>();
    const [addExistingContact, toggleAddExistingContact] = useToggle<boolean>();
    const [addNewContact, toggleAddNewContact] = useToggle<boolean>();

    const { company, isLoading, onHandleChangeCompany } = useGetCompanyGroup(id, true);

    const currentUser = isAuth();

    const additionalCompanyFields: AdditionalCompanyField[] = useMemo(() => {
        return _.get(getCompany(), "additionalCompanyFields", [])
            .filter(field => field.active) as AdditionalCompanyField[]
    }, []);

    const serializeCompanyGroupValue = useMemo(() => {
        if (!company) return {};
        let additionalData = {};

        additionalCompanyFields.forEach((field) => {
            const value = (company?.additionalData || {})[field.code];
            if (!value) return;

            switch (field.type) {
                case 'multiplecompany':
                case 'multiplecontact': {
                    additionalData[field.code] = (Array.isArray(value) ? value : []).filter(Boolean).map((value) => value?._id).filter(Boolean);
                    break;
                }
                case 'selectcompany':
                case 'selectcontact': {
                    additionalData[field.code] = value?._id;
                    break;
                }
                default: {
                    additionalData[field.code] = value;
                    break;
                }
            }
        })

        return {
            displayName: company.displayName,
            _id: company._id,
            businessName: company.businessName,
            taxCode: company.taxCode,
            origin: company.origin,
            sectors: company.sectors,
            direction: company.direction,
            description: company.description,
            company: company.company,
            status: company.status,
            additionalData
        }
    }, [company]);

    const onOpenAddContact = (sector?: SectorSelected | undefined) => {
        setSectorSelectedForCreation(sector);
        toggleAskContact();
    };

    const onAddContacts = (contacts: CompanyGroupContact[]) => {
        const companyValue: ICompanyGroup = company || {} as ICompanyGroup;
        const newContacts: CompanyGroupContact[] = (companyValue?.contacts || []).concat(contacts);

        companyValue.contacts = newContacts;
        onHandleChangeCompany(companyValue);
    }

    const { mutate } = useMutation({
        mutationFn: ({ body }: { body: BodyForAddContacts }) => addContactsToCompanyGroup(id, body, true),
        onSuccess: ({ contacts = [] }) => {
            onAddContacts(contacts);
            setAddedContactLoader(undefined);
        }
    });

    const onSaveContactToCompany = (contacts: string[], role?: string) => {
        setAddedContactLoader([sectorSelectedForCreation?.id, contacts.length])
        mutate({
            body: {
                role,
                contacts,
                sector: sectorSelectedForCreation?.id
            }
        })
    };

    const onCloseContactCreationModal = () => {
        toggleAddNewContact();
        toggleAskContact();
    }

    const onSaveContactFromContactDrawer = (contact: Contact) => {
        const currentAffiliatedCompanyGroup = (contact?.affiliatedCompanyGroup || [])
            .find((cg) => cg.companyGroup == company?._id);

        if (!currentAffiliatedCompanyGroup) return;

        if (contact.affiliatedCompanyGroup)
            onAddContacts([{
                ...contact,
                proximity: 0,
                affiliatedCompanyGroup: currentAffiliatedCompanyGroup,
                agent: {
                    _id: currentUser._id,
                    email: currentUser.email,
                    lastname: currentUser.lastname,
                    name: currentUser.name,
                    photo: currentUser.photo
                }
            } as CompanyGroupContact])

        onCloseContactCreationModal()
    }

    return {
        onCloseContactCreationModal,
        onSaveContactFromContactDrawer,
        serializeCompanyGroupValue,
        onSaveContactToCompany,
        addedContactLoader,
        toggleAskContact,
        toggleAddNewContact,
        openAskAddContact,
        onOpenAddContact,
        currentUser,
        addExistingContact,
        toggleAddExistingContact,
        company,
        addNewContact,
        sectorSelectedForCreation,
        onAddContacts,
        additionalCompanyFields,
        onHandleChangeCompany,
        isLoading
    }
}
