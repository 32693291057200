import { Button } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"
import { cn } from "@/lib/utils"
import { PlayArrow } from "@mui/icons-material"
import { Grid, Modal } from "@mui/material"
import { useState } from "react"

const VideoInsideBubble = ({
  thumbnail = undefined,
  media,
  className
}: {
  thumbnail?: string | undefined
  media: string,
  className?: string
}) => {
  const [imageIsLoaded, setImageIsLoaded] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const handleReload = () => setImageIsLoaded(true)

  return (
    <>
      <Modal open={fullScreen}>
        <Grid
          container
          onClick={() => setFullScreen(false)}
          sx={{ width: "100vw", height: "100vh" }}
          alignContent="center"
          justifyContent={"center"}
        >
          <div className="max-w-full max-h-full">
            <video
              src={media}
              style={{ maxWidth: "100vw", maxHeight: "100vh" }}
              controls
            />
          </div>
        </Grid>
      </Modal>
      <div
        onClick={() => setFullScreen(true)}
        className={cn('relative overflow-hidden w-full', className)}
      >
        {(media || thumbnail) && (
          <>
            {!thumbnail && media ? (
              <video className="rounded-sm w-full h-max " src={media} key={media} />
            ) : (
              <img
                src={thumbnail}
                className="w-full h-full rounded-sm object-cover"
                onLoad={handleReload}
                onError={handleReload}
              />
            )}
            <Button
              size={"icon"}
              variant={"secondary"}
              className="top-0 left-0 hover:bg-input rounded-full shrink-0 w-[55px] h-[55px] right-0 z-[2] absolute bottom-0 m-auto"
            >
              <PlayArrow />
            </Button>
          </>
        )}
        {((!media && !thumbnail) || (thumbnail && !imageIsLoaded)) && (
          <Skeleton className="w-full h-full" />
        )}
      </div>
    </>
  )
}

export default VideoInsideBubble
