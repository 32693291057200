
import { Button } from '@/components/ui/button';
import SimpleTooltip from '@/components/ui/simple-tooltip';
import { API_WPP } from '@/httpConfig';
import { UnOfficialWhatsAppMediaType } from '@/interfaces/crm/contact';
import { cn } from '@/lib/utils';
import { setRedirectToInteraction, setReply } from '@/redux/slices/crm';
import { motion } from 'framer-motion';
import { File, FileVideo, Image, Mic, Sticker, XIcon } from 'lucide-react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ReplyMessageCardProps {
    isReplied?: boolean,
    classNameContainer?: string,
    interaction: {
        id: string;
        message?: string;
        mediaType?: UnOfficialWhatsAppMediaType
        media?: string,
        mediaURL?: string
        fileName?: string
        localId?: string,
        thumbnail?: string,
        channel?: string,
        sticker?: { url: string, isAnimated: boolean }
    }
};

const messageDataByType = {
    'image': {
        icon: <Image />,
        label: 'Imagen'
    },
    'audio': {
        icon: <Mic />,
        label: 'Mensaje de audio'
    },
    'file': {
        icon: <File />,
        label: 'Documento'
    },
    'video': {
        icon: <FileVideo />,
        label: 'Video'
    },
    'sticker': {
        icon: <Sticker />,
        label: 'Sticker'
    }
}

export default function ReplyMessageCard({ interaction, isReplied, classNameContainer }: ReplyMessageCardProps) {
    if (!interaction) return null;
    const { id, fileName, media, mediaType, mediaURL, message, sticker } = interaction || {};

    const contact = useSelector((state: any) => state.crm.contact);

    const dispatch = useDispatch();
    const onCancelReply = () => dispatch(setReply(null));
    const { icon, label } = messageDataByType[mediaType || ''] || {};

    const handleViewInteraction = () => {
        dispatch(setRedirectToInteraction({
            contact: contact._id,
            interaction: id
        }));
    };

    return (
        <motion.div
            className={cn(
                `p-2.5 flex justify-between bg-white items-center rounded-lg border border-l-blue-600 border-l-2 cursor-pointer relative hover:bg-gray-50 duration-100`,
                classNameContainer
            )}
            onClick={handleViewInteraction}
            whileTap={{ scale: 0.98 }}
        >
            <div className='flex items-center gap-4'>
                <ElementByMediaType
                    mediaType={mediaType}
                    mediaURL={mediaURL}
                    sticker={sticker}
                    media={media}
                />
                <div>
                    <h5 className="font-medium text-gray-900 text-[11px]">
                        {isReplied ? 'Respondido:' : 'Responder:'}
                    </h5>
                    <p className="text-[12px] max-w-[500px] [&>svg]:w-[15px] [&>svg]:inline [&>svg]:h-[15px] [&>svg]:mr-1.5 font-medium text-gray-600 line-clamp-3">
                        {icon}
                        {message || fileName || label}
                    </p>
                </div>
            </div>
            {
                !isReplied && (
                    <SimpleTooltip content="Descartar">
                        <Button variant="outline" size={'icon'} className="w-7 h-7" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onCancelReply();
                        }}>
                            <XIcon size={14} />
                        </Button>
                    </SimpleTooltip>
                )
            }
        </motion.div>
    );
}


interface ElementByMediaTypeProps {
    mediaType?: UnOfficialWhatsAppMediaType,
    mediaURL?: string,
    media?: string,
    sticker?: { url: string, isAnimated: boolean }
}

export function ElementByMediaType({ mediaType, media = '', mediaURL = '', sticker }: ElementByMediaTypeProps) {
    if (!mediaType) return null;
    const { icon } = messageDataByType[mediaType];

    const mediaUrl = useMemo(() => {
        if (!media) return mediaURL;
        if (media && media.includes("https://")) return media
        if (media) return API_WPP + "/crm/wppimg/" + media
    }, [media])

    return <div className="w-[45px] flex items-center overflow-hidden justify-center text-gray-800 [&>svg]:w-[20px] [&>svg]:h-[20px] h-[45px] border rounded-md relative">
        {
            (['sticker', 'image'].includes(mediaType))
                ? <img
                    src={sticker?.url || mediaUrl}
                    style={{ animationPlayState: 'paused' }}
                    className="w-full h-full object-cover"
                    loading="lazy"
                />
                : icon
        }
    </div>
}