import { Feature, isFeatureEnabled } from '@/helpers/featureFlagHelpers'
import useGetSubConversations from '@/hooks/requests/useGetSubConversations'
import { useToggle } from '@/hooks/useToggle'
import { Collapse } from '@mui/material'
import { Plus } from 'lucide-react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import TransferContactDialog from '../Modal/TransferContactDialog'
import DetailAccordionItem from './detail-accordion-item'
import SubConversationCard from './sub-conversation-card'

export default function SubConversationsList({ prospect }) {
    const { subConversation } = prospect || {}
    if (!isFeatureEnabled(Feature.ENABLE_SUB_CONVERSATIONS) || !!subConversation.contact) return null
    const [onCreateSubConvarsation, setCreateSubConversation] = useState<false | string>(false)
    const [subConversationActive, setSubConversationActive] = useState<string | false>(false)
    const [showAll, toggleShowAll] = useToggle()
    const { subConversations } = useGetSubConversations()
    const router = useRouter()

    const handleSelectSubConversation = (id: string) => {
        const { query: existingQuery } = router;
        const newQuery = { id: id }

        router.push({
            pathname: '/contact',
            query: { ...existingQuery, ...newQuery },
        });
    }

    return <>
        <DetailAccordionItem label="Sub Conversaciones" value="subConversations">
            <div className='py-2 w-full'>
                <div className="flex mb-2 items-center w-full justify-end">
                    <PlusButton onClick={() => setCreateSubConversation(prospect._id)} />
                </div>
                <ul className='mb-2 flex flex-col gap-2'>
                    {
                        subConversations.slice(0, 3).map((subConversation) => (
                            <li key={subConversation._id}>
                                <SubConversationCard
                                    onSelect={(value) => handleSelectSubConversation(value._id)}
                                    subConversation={subConversation}
                                />
                            </li>
                        ))
                    }
                    <Collapse in={!!showAll} style={{ marginTop: 0 }}>
                        <div className='flex flex-col gap-2'>
                            {
                                subConversations.slice(3, subConversation.length).map((subConversation) => (
                                    <li key={subConversation._id}>
                                        <SubConversationCard
                                            onSelect={(value) => handleSelectSubConversation(value._id)}
                                            subConversation={subConversation}
                                        />
                                    </li>
                                ))
                            }
                        </div>
                    </Collapse>
                </ul>
                <div>
                    {
                        subConversations.length > 3 && <button onClick={toggleShowAll} className='w-full mb-2 text-[13px] text-primary'>
                            {
                                showAll
                                    ? 'Ver menos'
                                    : ' Ver mas'
                            }
                        </button>
                    }
                </div>
            </div>
        </DetailAccordionItem >
        <TransferContactDialog
            type={'clone'}
            open={onCreateSubConvarsation}
            onClose={() => setCreateSubConversation(false)}
        />
    </>
}

export function PlusButton({ onClick }) {
    return <button onClick={onClick} className='flex hover:text-primamy/60 items-center gap-1 font-medium text-[14px] text-primary'>
        <Plus size={16} />
        Nuevo
    </button>
}