
import { CloudUpload } from '@mui/icons-material'
import { Box, Button, Divider, Grid, Modal, Typography } from '@mui/material'
import axios from 'axios'
import _ from 'lodash'
import { uploadFileToS3, uploadFileToS3ByObject } from '@/helpers/uploadFileToS3'
import { useCallback, useState } from 'react'
import {useDropzone} from 'react-dropzone'
import { getCookie } from '../../../actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { addFileName } from '../../../redux/slices/crm'
import mime from 'mime-types'
import { setEnableSaveRequestSale } from '@/redux/slices/crm';

interface FileUploadInput {
    onChange: (value:string) => void, 
    value: string | null,
    title: string,
    disabled?: boolean,
    required?: boolean,
    requestData?: {requestsaleid: string, contactid:string, field:string, updatedAt:string}
}

const API = process.env.API

const FileUploadInput = ({onChange, value, title, disabled, required, requestData}) => {
    let [error, setError] = useState(null)
    let [fullScreen, setFullScreen] = useState(false)
    const fileNames = useSelector((state:any) => state?.crm?.fileNames)
    const dispatch = useDispatch()

    const onDrop = useCallback(acceptedFile => {
        dispatch(setEnableSaveRequestSale(false))
        var reader = new FileReader()
        if (acceptedFile.length == 0) return ''
        reader.readAsDataURL(acceptedFile[0])
        reader.onload = async function () {
            const url = await uploadFileToS3(acceptedFile[0])
            let extencion = acceptedFile[0].name
            dispatch(addFileName({ name: title, value: extencion }))
            onChange(url)
        }

        reader.onerror = function(err){
            setError(err)
        }

        dispatch(setEnableSaveRequestSale(true))
    }, [fileNames, dispatch, addFileName, title])

    const img_url = value?.includes("https://")
        ? value
        : `${API}/crm/requestsaleimage/${_.get(requestData, 'contactid', '')}/${_.get(requestData, 'requestsaleid', '')}/${_.get(requestData, 'field', '')}/${getCookie('token')}?updatedAt=${_.get(requestData, 'updatedAt', '')}`

    var deleteFile = () => {
        onChange("")
    }

    let startDownload = async () => {
        if(value == 's3' ){
            let imageReq = await axios.get(img_url, {
                responseType: 'arraybuffer'
              })
            var a = document.createElement("a"); //Create <a>
            let link = `data:${imageReq.headers['content-type']};base64,${Buffer.from(imageReq.data).toString('base64')}`
            a.href = link; //Image Base64 Goes here

            let extencion = mime.extension(imageReq.headers['content-type'])


            a.download = fileNames[title] ?? title+'-'+requestData.updatedAt+"."+extencion; //File name Here
            a.click(); //Downloaded file
        }else{
            var a = document.createElement("a");
            a.href = value; //Image Base64 Goes here
            a.download = fileNames[title] ?? title+'.jpeg'; //File name Here
            a.click(); 
        }

    }

    const {getRootProps, getInputProps, isDragActive, open} = useDropzone({onDrop
        // , accept: ['image/png', 'image/jpg', 'image/jpeg']
    })

    return <Grid container>
            <Grid item>
                <Typography fontSize={12} mb={0.5} color="#555">{title}{required ? '*' : ''}</Typography>
                <Modal open={fullScreen}>
                    <Grid container onClick={() => setFullScreen(false)} sx={{width: '100vw', height: '100vh'}} alignContent="center" justifyContent={"center"}>
                        <Grid item sx={{maxWidth: '100%', maxHeight: '100%'}}>
                            {(
                                value == 's3' ? 
                                    <img src={img_url} style={{maxWidth: '100vw', maxHeight: '100vh'}} loading="lazy"/>
                                :
                                    <img src={value} style={{maxWidth: '100vw', maxHeight: '100vh'}} loading="lazy"/>
                            )}
                     </Grid>
                    </Grid>
                </Modal>
            </Grid>

            {!disabled ? <Grid item {...getRootProps()} sx={{width: '100%'}}>
                <Grid sx={{borderStyle: "dashed", border: '2px dashed #ccc', py:1, textAlign: 'center', width: '100%'}}>

                    <input {...getInputProps()}/>
                    {
                    value ? 
                    !fileNames[title] ?
                    (
                        value == 's3' ? 
                            <img src={img_url} height={"150px"} loading="lazy"/>
                        :
                            <img src={value} height={"150px"} loading="lazy"/>
                    ) :
                    <p>{fileNames[title]}</p>
                    :
                    <CloudUpload sx={{fontSize: '50px', mb: 0.5}} />
                    }
                    {
                        isDragActive ?
                        <p>Arrastra el archivo aqui ...</p> :
                        <Box>
                            <Typography fontSize={14}>Arrastre una imagen/archivo o presiona para seleccionar una</Typography>
                            {/* <Typography variant="subtitle1" color="#999">Recuerde que tienes que subir la plantilla del mes corespondiente</Typography> */}
                        </Box>
                    }
                </Grid>
            </Grid>
            : 
            <Grid item xs={12}>
                {
                    fileNames[title] ?
                    <p>{fileNames[title]}</p>
                    :
                    value == 's3' ? 
                    <img src={img_url} height={"150px"} onClick={() => setFullScreen(true)}/> 
                    :
                        <img src={value} height={"150px"} onClick={() => setFullScreen(true)}/> 
                }
            </Grid>
            }
            
            <Grid container item justifyContent={disabled ? "left" : "center"}>
                {
                value ?
                (
                    <Grid item>
                        {!disabled &&<Button onClick={() => {deleteFile(); open()}} >Cambiar archivo </Button>}
                        <Button onClick={startDownload}>Descargar </Button>
                        <Button onClick={() => setFullScreen(true)}>Ver </Button>
                    </Grid>
                ) :
                ' '
                }
            </Grid>
    </Grid>

}

export default FileUploadInput