import { ArrowLeft } from "lucide-react";
import { CardTitle } from "../ui/card";
import SimpleTooltip from "../ui/simple-tooltip";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";

export default function TitleAndGoBack({ isActiveGoBack = true, onGoBack, title, className = '', fontSize = 18, classNameContainer }: any) {
    return <div className={cn('flex max-w-[100%] overflow-hidden relative gap-2 items-center', classNameContainer)}>
        {
            isActiveGoBack && <SimpleTooltip content={'Volver'} delayDuration={50}>
                <Button variant={'outline'} size={'icon'} className={cn("rounded-full w-[30px] h-[30px]", className)}
                    onClick={() => onGoBack()}>
                    <ArrowLeft size={18} />
                </Button>
            </SimpleTooltip>
        }
        <CardTitle className="flex justify-between max-w-[100%] truncate relative items-center gap-4 text-xl">
            <div className="flex items-center truncate  gap-4" style={{
                fontSize: `${fontSize}px`
            }}>
                {title}
            </div>
        </CardTitle>
    </div>
}