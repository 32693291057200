import { cn } from "@/lib/utils"
import { LocalStatus } from "@/redux/libs/ts/interfaces"
import { ReactNode, useMemo } from "react"
import { useSelector } from "react-redux"
import CardHoverReactions from "../card-hover-reactions"
import { InteractionProfileUser } from "./interaction-profile-user"
import { InteractionStatus } from "./interaction-status"

interface IInteractiveMessageCard {
    children: ReactNode,
    origin: 'agent' | 'contact' | null | undefined | string,
    agent: any,
    withBorder?: boolean
    profile: string | null | undefined,
    classNameInteractionStatus?: string,
    messageError?: string
    styleContent?: Record<string, any>
    mediumIcon?: JSX.Element,
    retryFn?: () => void
    customCircle?: ReactNode
    extraContent?: ReactNode
    defaultStyles?: boolean
    status?: InteractonStatusType,
    className?: string
}

export default function InteractiveMessageCard({
    origin = 'agent',
    status = 'idle',
    mediumIcon,
    className,
    children,
    customCircle,
    withBorder = true,
    styleContent = {},
    classNameInteractionStatus,
    retryFn,
    messageError,
    profile,
    agent,
    defaultStyles = true,
    extraContent
}: IInteractiveMessageCard) {
    const prospect = useSelector((state: any) => state.crm.contact)
    const isAgent = useMemo(() => origin === 'agent', [origin])
    const { statusTag } = prospect || {}
    const { className: statusStyle } = getDataByStatus(status as InteractonStatusType)

    return (
        <div className={cn(`flex gap-1  max-w-[100%] relative w-full ${isAgent ? "justify-end" : "justify-start"}`)}>
            <div className={`relative flex justify-end ${isAgent ? 'order-2 items-end ' : 'order-[0] items-start'}`}>
                {
                    customCircle || <>
                        <CardHoverReactions
                            isBot={profile === 'bot'}
                            isAgent={isAgent}
                            profile={profile}
                            agent={agent}
                            trigger={
                                <InteractionProfileUser
                                    statusTag={statusTag}
                                    isAgent={isAgent}
                                    profile={profile}
                                    ItemChannel={
                                        mediumIcon && <span className={`z-[1] -bottom-[4px] ${isAgent || profile === 'bot' ? '-right-[3px]' : '-left-[3px]'} absolute`}>
                                            {mediumIcon}
                                        </span>
                                    }
                                />
                            }
                        />
                    </>
                }

            </div>
            <div
                className={cn(`${defaultStyles ? 'max-w-[83%] sm:max-w-[65%] min-w-[180px] md:max-w-[50%] lg:max-w-[60%] 2xl:max-w-[60%] ' : ''} relative ${isAgent
                    ? 'shadow-bubble-right bg-blue-100 mr-2'
                    : 'shadow-bubble-left bg-neutral-100 ml-2'} text-gray-800 w-max overflow-hidden  ${statusStyle}`, className)}
                style={{
                    borderRadius: withBorder ? (isAgent ? '8px 8px 2px 8px' : '2px 8px 8px 8px') : '',
                    overflowWrap: 'break-word',
                    ...styleContent
                }}
            >
                {children}
                {
                    !!retryFn && !!messageError && <InteractionStatus
                        className={cn("pb-2 mt-[-5px] px-4", classNameInteractionStatus)}
                        status={status as LocalStatus}
                        handleRetry={retryFn}
                        messageError={messageError}
                    />
                }
            </div>
            {extraContent}
        </div>
    )
}

export type InteractonStatusType = 'error' | 'idle' | 'success'
const getDataByStatus = (status: InteractonStatusType) => {
    const map = {
        idle: {
            className: ''
        },
        success: {
            className: ''
        },
        error: {
            className: 'border-red-600 border'
        }
    }

    return map[status] || map['idle']
}