import userNotification from '@/actions/userNotification';
import { Button } from '@/components/ui/button';
import { useToggle } from '@/hooks/useToggle';
import { ActionToTrack, UserRightBottomNotification } from '@/interfaces/Interface';
import { useMutation } from '@tanstack/react-query';
import { AudioLines, BookOpen, FileText, Image, Newspaper, Video, XIcon } from 'lucide-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import ModalViewIframe from './ModalViewIframe';

interface RightBottomNotificationProps {
    notification: UserRightBottomNotification;
    onCloseNotification: () => void;
    isPreviewMode?: boolean
}

export const multimediaTypesData = new Map(
    [
        ['video', { icon: <Video size={18} />, btnLabel: 'Ver video' }],
        ['article', { icon: <Newspaper size={18} />, btnLabel: 'Ver articulo' }],
        ['image', { icon: <Image size={18} />, btnLabel: 'Ver imagen' }],
        ['tutorial', { icon: <BookOpen size={18} />, btnLabel: 'Ver tutorial' }],
        ['document', { icon: <FileText size={18} />, btnLabel: 'Ver documento' }],
        ['audio', { icon: <AudioLines size={18} />, btnLabel: 'Escuchar audio' }],
        ['text', { icon: null, btnLabel: 'Ver notificación' }],
    ]
);

export default function RightBottomNotification(props: RightBottomNotificationProps) {
    const [openViewContentNotification, toggleOpenViewContentNotification] = useToggle<boolean>(false);

    const router = useRouter();

    const { isPreviewMode = false, notification } = props;
    const { setting, viewed } = notification;
    const { metadata, action } = setting;
    const { title, description, multimediaType, thumbnailURL } = metadata;
    const { redirectType, redirectURL } = action;

    const { mutate } = useMutation({
        mutationFn: (actions: ActionToTrack[]) =>
            userNotification.trackAction(props.notification._id, actions),
    });

    useEffect(() => {
        !isPreviewMode && !viewed && mutate(['viewed']);
    }, [])

    const handleViewNotification = () => {
        !isPreviewMode && mutate(['opened']);

        if (redirectType === 'redirect') {
            router.push(redirectURL);
            return props.onCloseNotification();
        }

        toggleOpenViewContentNotification();
    };

    const handleCloseNotification = () => {
        !isPreviewMode && mutate(['closed']);
        props.onCloseNotification();
    };

    const handleCloseIframeModal = () => {
        toggleOpenViewContentNotification();
        props.onCloseNotification();
    };

    const { btnLabel, icon } = multimediaTypesData.get(multimediaType || 'text') || {};

    return (
        <>
            <ModalViewIframe
                contentUrl={redirectURL}
                open={openViewContentNotification}
                onOpenChange={handleCloseIframeModal}
            />
            <div className="p-4 w-full max-w-[300px] sm:w-[320px] h-max bg-white rounded-lg border relative shadow-sm">
                <button
                    onClick={handleCloseNotification}
                    className={
                        'absolute right-2.5 top-2.5 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'
                    }
                >
                    <XIcon className="h-4 w-4" />
                    <span className="sr-only">Close</span>
                </button>
                <div className="flex flex-col gap-2.5 mb-5">
                    <h3 className="sm:text-[15.5px] text-[13px] text-center font-bold text-gray-800">
                        {title}
                    </h3>
                    {description && (
                        <p className="sm:text-[12.5px] text-[11px] text-gray-600">{description}</p>
                    )}
                </div>
                {thumbnailURL && (
                    <div className="w-full relative overflow-hidden h-[100px] sm:h-[150px] bg-white rounded-[3px] border">
                        <img src={thumbnailURL} className="w-full h-full object-cover" />
                    </div>
                )}
                <div className="flex items-center mt-8 justify-center">
                    <Button
                        className="flex items-center gap-1.5 text-[11px] sm:text-[12px] px-3.5 h-8"
                        onClick={handleViewNotification}
                    >
                        {icon}
                        {btnLabel}
                    </Button>
                </div>
            </div>
        </>
    );
}