import { Tag } from "lucide-react"
import moment from "moment"
import { DividerProspectAction, IAgentData } from "./TransferBubble"
import { isAuth } from "@/actions/auth"
import { getStatusTagByCode } from '@/helpers/tagColorToPalette';

moment.locale("es")

interface TagModifiedBubble {
  from?: string
  to: string
  date: Date
  agent: IAgentData
}

const TagModifiedBubble = ({ from, to, date, agent }: TagModifiedBubble) => {
  const fromRealNameTag = getStatusTagByCode(from ?? '')?.name
  const toRealNameTag = getStatusTagByCode(to)?.name
  return (
    <DividerProspectAction
      date={date}
      description={(agent?.name && agent?._id != isAuth()._id) ? `Realizado por ${agent?.name} ${agent?.lastname}` : ""}
    >
      <Tag size={20} />
      {from == '' && to == '' ? (
        <p>
          Cambio de etiqueta invalido
        </p>
      ) : from != '' && to != '' ? (
        <p>
          Cambio de etiqueta de <b className="text-[#0F172A]">{fromRealNameTag ?? from}</b> a{" "}
          <b className="text-[#0F172A]">{toRealNameTag ?? to}</b>{" "}
        </p>
      ) : to == '' ? (
        <p>
          Etiqueta{" "}<b className="text-[#0F172A]">{fromRealNameTag ?? from}</b>{" "}eliminada
        </p>
      ) : (
        <p>
          Etiqueta{" "}<b className="text-[#0F172A]">{toRealNameTag ?? to}</b>{" "}agregada
        </p>
      )}
    </DividerProspectAction>
  )
}

export default TagModifiedBubble
