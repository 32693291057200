import { SchemaFolderChartType } from "@/components/company/charts/constants/chart-folder"
import { fetcher } from "@/helpers/fetcher"
import { formatSearchParams } from "@/helpers/formatSearchParams"
import { IDashboardChart } from "@/interfaces/Interface"

export const createDashboardChart = ({
    body
}: {
    body: SchemaFolderChartType
}) => {
    return fetcher({
        url: '/dashboardChart/folder',
        method: 'POST',
        defaultContentType: true,
        body
    })
}

export const modifyDashboardChart = ({
    body,
    id
}: {
    body: SchemaFolderChartType,
    id: string
}) => {
    return fetcher({
        url: `/dashboardChart/folder/${id}`,
        method: 'PATCH',
        defaultContentType: true,
        body
    })
}

export const getDashboardCharts = ({
    params = {}
}: {
    params?: Record<string, any>
}) => {
    const queryParams = formatSearchParams(params)

    return fetcher<{ countDocuments: number, dashboardCharts: IDashboardChart[] }>({
        url: `/dashboardChart/folder?${queryParams}`,
        method: 'GET',
        defaultContentType: true
    })
}

export const deleteDashboardCharts = (id: string) => {
    return fetcher({
        url: `/dashboardChart/folder/${id}`,
        method: 'DELETE',
        defaultContentType: true
    })
}

export const getDashboardChart = (id: string) => {
    return fetcher<{ dashboardChart: any }>({
        url: `/dashboardChart/folder/${id}`,
        method: 'GET',
        defaultContentType: true
    })
}

export const getDashboardChartByUser = () => {
    return fetcher<{ dashboardCharts: any }>({
        url: `/dashboardChart`,
        method: 'GET',
        defaultContentType: true
    })
}

export interface HiddenDefaultChartProps {
    id?: string,
}

export const hiddenDefaultChart = ({ id }: HiddenDefaultChartProps) => {
    return fetcher<{ folderId: string }>({
        url: `/dashboardChart/folder/${id}/toggleShow`,
        defaultContentType: true,
        method: 'PUT'
    })
}