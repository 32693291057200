import { upadteMeetingStatus } from "@/actions/crm/contact"
import StyledLinkifyer from "@/components/StyledLinkifyer"
import { Button } from "@/components/ui/button"
import SimpleTooltip from "@/components/ui/simple-tooltip"
import { toast } from "@/components/ui/use-toast"
import { useMutation } from "@tanstack/react-query"
import dayjs, { Dayjs } from "dayjs"
import 'dayjs/locale/es'
import { CheckCircle2, ExternalLink, Laptop, Loader, Presentation, XCircle } from "lucide-react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { meetingTypes } from "../Modal/meeting/select-meeting-type"
import { createInteraction } from "../Prospect/libs/helpers/interaction"
import { GoogleMeet, PersonalMeet, Zoom } from "./Icons"

dayjs.locale('es')

interface MeetingBubble {
    interaction: any
    contactId: string
}

export const iconsMeeting = {
    meet: <GoogleMeet />,
    zoom: <Zoom />,
    person: <PersonalMeet />,
}

export const meetingStatusData = {
    'expired': {
        title: 'Reunión vencida',
        icon: <Laptop size={18} />,
        textColor: '#6b7280',
        backgroundColor: '#f3f4f6',
        statusToChange: ''
    },
    'done': {
        title: 'Reunión realizada',
        icon: <CheckCircle2 size={18} />,
        textColor: '#3b82f6',
        backgroundColor: '#dbeafe',
        statusToChange: ''
    },
    'cancel': {
        title: 'Reunión cancelada',
        icon: <XCircle size={18} />,
        textColor: '#ef4444',
        backgroundColor: '#fee2e2',
        statusToChange: ''
    },
    'error': {
        title: 'Error al crear la reunión',
        icon: <XCircle size={18} />,
        textColor: '#ef4444',
        backgroundColor: '#fee2e2',
        statusToChange: ''
    },
    'inProgress': {
        title: 'Reunión en proceso',
        icon: <Laptop size={18} />,
        textColor: '#6366f1',
        backgroundColor: '#e0e7ff',
        statusToChange: ''
    },
    'active': {
        title: 'Reunión activa',
        icon: <Laptop size={18} />,
        textColor: '#22c55e',
        backgroundColor: '#dcfce7',
        statusToChange: 'cancel'
    }
}


const MeetingBubble = ({
    contactId,
    interaction
}: MeetingBubble) => {
    const { createdAt, _id: meetingId, localStatus, contact } = interaction || {}
    const { comment, meetingType, schedule, status, link, summary, endMeeting, isGenerateLink = false } = interaction.content || {}
    const [lastStatus, setLastStatus] = useState(status)
    const [newStatus, setNewStatus] = useState<string | null>(status)

    const { isPending: isLoading, mutate } = useMutation({
        mutationFn: ({ status }: { status: string }) => upadteMeetingStatus(status, meetingId, contactId),
        onSuccess: (res) => {
            const newStatusResult = res.result.content.status
            setNewStatus(newStatusResult)
            setLastStatus(newStatusResult)
        },
        onError: () => setNewStatus(lastStatus)
    })

    const { title, icon, textColor, backgroundColor } = meetingStatusData[newStatus || 'active'] || {}
    const { icon: iconMedium, label: mediumLabel } = meetingTypes[meetingType]

    const handleUpdateMeeting = async (status: string) => {
        setNewStatus(status)
        mutate({ status })
    }

    const dispatch = useDispatch()

    const onHandleJoinToMeet = () => {
        const a = document.createElement('a')
        a.href = link
        a.target = '_blank'
        a.click()
    }

    const onShareMeetingLink = () => {
        navigator.clipboard.writeText(link)
        toast({
            title: "Guardado en portapapeles",
            description: link,
        })
    }

    return (
        <div className="w-full justify-center  flex items-center">
            <div className="bg-white shadow-bubble-phone flex rounded-md md:min-w-[380px] min-w-auto max-w-[380px] overflow-hidden border flex-col relative">
                <div className="flex items-center px-4  h-[35px] w-full" style={{ backgroundColor }}>
                    <h6 className={"font-medium  flex items-cente gap-2 text-[13px]"} style={{ color: textColor }}>
                        {icon}
                        {title}
                    </h6>
                </div>
                <div className="flex p-4  flex-col gap-2 w-full">
                    <p className="text-[13px] text-gray-500">
                        {formatDate(dayjs(schedule))}
                    </p>
                    <h4 className="text-[17px] truncate line-clamp-2 font-medium text-gray-800">
                        {summary}
                    </h4>
                    <ul className={"flex flex-col relative overflow-hidden max-w-[100%] gap-3"}>
                        <li>
                            <div className="flex items-start gap-2">
                                <span className="font-medium text-[14px] text-gray-600">
                                    Medio:
                                </span>
                                <span className="flex items-center text-[13px] font-medium text-gray-500 gap-1">
                                    <span className="[&>svg]:w-[15px] [&>svg]:h-[15px]">
                                        {iconMedium}
                                    </span>
                                    {mediumLabel}
                                </span>
                            </div>
                        </li>
                        {
                            comment && (
                                <li>
                                    <div className="flex items-start gap-2">
                                        <span className="font-medium text-[14px] text-gray-600">
                                            Comentario:
                                        </span>
                                        <span className="flex items-center text-[13px]  font-medium text-gray-500 gap-1">
                                            <StyledLinkifyer>
                                                {comment}
                                            </StyledLinkifyer>
                                        </span>
                                    </div>
                                </li>
                            )
                        }
                        <li>
                            <div className="flex items-center justify-between mt-1">
                                <div>
                                    {
                                        (localStatus !== 'error' && isGenerateLink) && (
                                            <div className="flex items-center w-full gap-2 text-primary">
                                                <Loader className="shrink-0 animate-spin" size={15} />
                                                <p className={'text-[12px] font-medium'}>Generando Link...</p>
                                            </div>
                                        )
                                    }
                                    {
                                        link && (
                                            <div className="flex items-center gap-2">
                                                <SimpleTooltip content={"Copiar Link"}>
                                                    <Button variant="outline" size={'icon'} className="w-8 h-8" onClick={onShareMeetingLink}>
                                                        <ExternalLink size={16} className="shrink-0" />
                                                    </Button>
                                                </SimpleTooltip>
                                                <SimpleTooltip content={"Unirse a la reunión"}>
                                                    <Button variant="outline" size={'icon'} className="w-8 h-8" onClick={onHandleJoinToMeet}>
                                                        <Presentation size={16} className="shrink-0" />
                                                    </Button>
                                                </SimpleTooltip>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="flex items-center gap-1.5">
                                    {
                                        newStatus == 'active'
                                            ? <ButtonChangeStatus
                                                label={'Cancelar reunión'}
                                                onClick={() => handleUpdateMeeting('cancel')}
                                            />
                                            : (newStatus == 'expired' || newStatus == 'inProgress')
                                                ? <>
                                                    <ButtonChangeStatus
                                                        label={'Realizada'}
                                                        onClick={() => handleUpdateMeeting('done')}
                                                    />
                                                    <ButtonChangeStatus
                                                        label={'Cancelada'}
                                                        onClick={() => handleUpdateMeeting('cancel')}
                                                    />
                                                </>
                                                : (newStatus == 'done')
                                                    ? <ButtonChangeStatus
                                                        label={'Reunión cancelada'}
                                                        onClick={() => handleUpdateMeeting('cancel')}
                                                    />
                                                    : <ButtonChangeStatus
                                                        label={'Reunión realizada'}
                                                        onClick={() => handleUpdateMeeting('done')}
                                                    />
                                    }
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    )
}

export const formatDate = (date: Dayjs) => {
    dayjs.locale('es');

    const dayOfWeek = date.format('dddd');
    const dayOfMonth = date.format('D');
    const month = date.format('MMM');
    const year = date.format('YYYY');

    const time = date.format('HH:mm') + 'Hs';
    const formattedDate = `${dayOfWeek} ${dayOfMonth} ${month}. ${year} . ${time}`;

    return formattedDate;
}

export function ButtonChangeStatus({
    label,
    onClick
}) {
    return (
        <Button variant="outline" className="h-8 px-2 text-[12px]" onClick={onClick}>
            {label}
        </Button>
    )
}


export default MeetingBubble