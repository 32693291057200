import { Skeleton } from '@/components/ui/skeleton'
import React from 'react'

export default function HeaderLoader() {
    return (
        <div className="flex items-center gap-2">
            <Skeleton className="w-[140px] h-3 rounded-md" />
            <Skeleton className="w-[90px] h-3 rounded-md" />
            <Skeleton className="w-[60px] h-2 rounded-md" />
        </div>
    )
}
