import { ReactNode } from 'react';

interface ContactInformationItemProps {
    icon: ReactNode,
    title: string,
    data: ReactNode
}

export default function ContactInformationItem(props: ContactInformationItemProps) {
    const { icon, title, data } = props;

    return (
        <li className="gap-2 grid grid-cols-2">
            <p className="text-gray-700 text-[13px]">
                {icon}
                {title}
            </p>
            {data}
        </li>
    )
}