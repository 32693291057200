import { ContactService } from "@/actions/crm/contact-controller";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { UserAvatar } from "@/components/ui/user-avatar";
import { namesOfOptions } from "@/constants/modalReminder";
import { getStatusTagColor } from "@/helpers/tagColorToPalette";
import useGetArchivingReason from "@/hooks/useGetArchivingReason";
import useGetStatusTag from "@/hooks/useGetStatusTag";
import { CompanyGroupContact } from "@/interfaces/Interface";
import { formatChatDate } from "@/lib/time";
import { setViewCompanyGroup } from "@/redux/slices/crm";
import { useMutation } from "@tanstack/react-query";
import { Archive, ChevronDown, LandPlot, MessageCircleMore, Tag, Timer, User } from "lucide-react";
import moment from "moment";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ContactAvatar } from "../Prospect";
import ChangeStatusTagElement from "../StatusTag/ChangeStatusTagElement";
import ContactInfoDescription from "./ContactInfoDescription";
import ContactInformationItem from "./ContactInformationItem";
import { getProximityData } from "./utils/constants";

import ProximityItem from "./ProximityItem";

interface ContactItemProps {
    onChangeContactValue: (value: CompanyGroupContact) => void
    contact: CompanyGroupContact,
    currentUser: any
}

const getInteractionLabelByType = (type: string) => {

    const interactionLabel: Record<string, string> = {
        'archiving': 'Último cierre:',
        'unofficial-whatsapp': 'Último WhatsApp:',
        'email': 'Último correo:',
        'meeting': 'Última reunión:',
        'note': 'Última nota:',
        'reminder': 'Último recordatorio:',
        'saleupdate': 'Última actualización de venta:',
        'scheduled-message': 'Último mensaje programado:',
        'smsMessage': 'Último SMS:',
        'tag': 'Último cambio de etiqueta:',
        'tagChange': 'Último cambio de etiqueta:',
        'transfer': 'Última transferencia:',
        'visit': 'Última visita:',
        'ig': 'Último mensaje de Instagram:',
        'fb': 'Último mensaje de Facebook:',
        'wpp': 'Último WhatsApp:',
    };

    return interactionLabel[type] || 'Última interacción'
};

export default function ContactItem(props: ContactItemProps) {
    const { contact, currentUser, onChangeContactValue } = props;
    const [currentStatusTag, setCurrentStatusTag] = useState<string | undefined>(contact.statusTag);

    const {
        firstName,
        lastName,
        lastInteraction,
        reminder,
        photo,
        agent,
        proximity,
        remiderDetail,
        archivingReason,
        archivingReasonText,
        lastInteractionContent,
        affiliatedCompanyGroup
    } = contact;

    const { reason: reasonData } = useGetArchivingReason({ code: archivingReason })
    const { find: findedStatusTag } = useGetStatusTag({ code: currentStatusTag || '' })

    const fullName = [firstName, lastName].filter(Boolean).join(' ');
    const iAmAgentOfContact = agent?._id == currentUser._id;
    const roleOnCompany = affiliatedCompanyGroup?.role || '';

    const agentFullName = useMemo(() => {
        if (iAmAgentOfContact) return 'Vos';
        return [agent?.name, agent?.lastname].filter(Boolean).join(' ')
    }, [agent, currentUser, iAmAgentOfContact]);

    const { actionType } = remiderDetail || {};
    const dataOfReminder = useMemo(() => namesOfOptions?.[actionType as any], [reminder, namesOfOptions, actionType]);

    const { mutate } = useMutation({
        mutationFn: (statusTag: string) => ContactService.modifyTag({ id: contact._id, statusTag })
    });

    const dispatch = useDispatch();

    const proximityData = useMemo(() => {
        return getProximityData(proximity);
    }, [proximity])

    const onChangeStatusTag = (code: string) => {
        setCurrentStatusTag(code);
        mutate(code);
        onChangeContactValue({ ...contact, statusTag: code });
    };

    const lastInteractionLabel: string = useMemo(() => {
        return getInteractionLabelByType(lastInteractionContent?.type || '')
    }, [lastInteractionContent]);

    const GetContactTitle = () => {
        return (
            <CardTitle
                title={fullName}
                className="text-[14px] truncate max-w-[100%] text-gray-700"
            >
                {fullName}
            </CardTitle>
        )
    }

    const onUnViewCompanyGroup = () => dispatch(setViewCompanyGroup(undefined));
    const useLastInteractionDate = (lastInteractionContent?.createdAt || lastInteraction);

    return (
        <Card className="p-3 rounded-lg bg-gray-50 h-full">
            <CardHeader className="p-0">
                <div className="flex flex-grow w-full items-center">
                    <ContactAvatar
                        className="w-[32px] mr-2 h-[32px] border"
                        statusTag={currentStatusTag}
                        firstName={firstName}
                        lastName={lastName}
                        photo={photo}
                    />
                    <div className='flex flex-col flex-grow overflow-hidden relative'>
                        {
                            iAmAgentOfContact
                                ? <a
                                    href={`/contact?id=${contact._id}`}
                                    onClick={onUnViewCompanyGroup}
                                    className="hover:text-primary cursor-pointer hover:[&>h3]:text-primary hover:underline"
                                >
                                    {GetContactTitle()}
                                </a>
                                : GetContactTitle()
                        }

                        {
                            roleOnCompany && (
                                <p className="text-[13px] text-gray-500">
                                    {roleOnCompany}
                                </p>
                            )
                        }
                    </div>
                </div>
            </CardHeader>
            <CardContent className="p-0 mt-4">
                <ul className="grid gap-2.5">
                    {
                        agent && (
                            <ContactInformationItem
                                icon={<User size={18} className="inline mr-1.5" />}
                                title="Asesor:"
                                data={
                                    <ContactInfoDescription content={
                                        <>
                                            <UserAvatar user={agent} className="w-[22px] h-[22px] mr-1" />
                                            {agentFullName}
                                        </>
                                    } />
                                }
                            />
                        )
                    }

                    <ContactInformationItem
                        icon={<LandPlot size={18} className="inline mr-1.5" />}
                        title="Cercanía:"
                        data={<ProximityItem proximityData={proximityData} />}
                    />

                    {
                        useLastInteractionDate && (
                            <ContactInformationItem
                                data={<ContactInfoDescription content={formatChatDate(new Date(useLastInteractionDate))} />}
                                icon={<MessageCircleMore size={18} className="inline mr-1.5" />}
                                title={lastInteractionLabel}
                            />
                        )
                    }

                    <ContactInformationItem
                        icon={<Tag size={17} className="inline mr-1.5" />}
                        title="Etiqueta:"
                        data={(
                            <ChangeStatusTagElement
                                onChangeTag={onChangeStatusTag}
                                statusTag={currentStatusTag}
                                trigger={(
                                    <div className="w-max">
                                        <button
                                            disabled={!iAmAgentOfContact}
                                            className="w-max flex hover:text-gray-800 duration-100 group items-center disabled:cursor-not-allowed"
                                        >
                                            {
                                                currentStatusTag && (
                                                    <div
                                                        className="w-2 h-2 mr-2 rounded-full shrink-0"
                                                        style={{
                                                            backgroundColor: currentStatusTag
                                                                ? getStatusTagColor(currentStatusTag, "primary")
                                                                : "#f7f9fb"
                                                        }}
                                                    />
                                                )
                                            }
                                            <p className="text-gray-700 max-w-[80px] truncate  group text-[13px]">
                                                {findedStatusTag?.name || "Sin etiqueta"}
                                            </p>
                                            {
                                                iAmAgentOfContact && (
                                                    <ChevronDown size={13} className="ml-1 group-hover:-rotate-90 duration-100" />
                                                )
                                            }
                                        </button>
                                    </div>
                                )}
                            />
                        )}
                    />

                    {
                        reminder && (
                            <ContactInformationItem
                                data={<ContactInfoDescription content={<>
                                    {
                                        (dataOfReminder && actionType !== "other") && (
                                            <SimpleTooltip content={dataOfReminder.name}>
                                                <span className="[&>svg]:w-3 shrink-0 [&>svg]:h-3">
                                                    {dataOfReminder?.icon}
                                                </span>
                                            </SimpleTooltip>
                                        )
                                    }
                                    {(moment(reminder).format('DD/MM/YYYY HH:mm[hs]'))}
                                </>} />}
                                icon={<Timer size={18} className="inline mr-1.5" />}
                                title="Recordatorio:"
                            />
                        )
                    }

                    {
                        (archivingReason) && (
                            <ContactInformationItem
                                icon={<Archive size={17} className="inline mr-1.5" />}
                                title="Cierre:"
                                data={
                                    <div className="text-white w-max rounded-md text-[11px] px-2 py-0.5 flex items-center justify-center bg-cyan-700 ">
                                        {reasonData?.name || archivingReason || archivingReasonText}
                                    </div>
                                }
                            />
                        )
                    }
                </ul>
            </CardContent>
        </Card >
    )
};

