import axios from "axios"
import { parsePhoneNumber } from "libphonenumber-js"
import _ from "lodash"
import { getCompany, getCookie, isAuth } from "../actions/auth"
import { startCallAircall, uploadCallsFromAircall } from "../actions/config/aircall"
import { callByExternalApi } from "@/actions/crm/contact"
import { useDispatch } from "react-redux"
import { setOpenUContact } from "@/redux/slices/headers"
const API = process.env.API
const API_WPP = process.env.API_WPP ?? "https://api.getventia.com";


type callPhoneHandlerFunction = (phone: string, contactid?: string) => Promise<void>

export const callPhoneHandler = async (phone: string, contactid = undefined, dispatch, isOpenUContact) => {

    let phonesProviders = _.get(isAuth(), 'phoneProviders', [])
    let country = _.get(getCompany(), 'country', []).toUpperCase()

    let validatePhone = parsePhoneNumber(phone)

    let nationalNumber: string | undefined = undefined
    if (validatePhone.isValid() && validatePhone.country === country) {
        nationalNumber = validatePhone.nationalNumber
        if (country == 'UY') {
            if (nationalNumber.startsWith('8') || nationalNumber.startsWith('9')) {
                nationalNumber = `0${nationalNumber}`
            }
        } else if (country == 'PY') {
            if (nationalNumber.startsWith('9')) {
                nationalNumber = `0${nationalNumber}`
            }
        }
    }


    let handled = false

    if (phonesProviders && phonesProviders.length > 0) {
        for (let provider of phonesProviders) {
            if (handled) break
            if (provider.provider === 'aircall') {
                if (provider.countryCodes.includes(validatePhone.country)) {
                    await startCallAircall(provider.config, phone)
                    handled = true
                    uploadCallsFromAircall(provider.config)
                }
            }
            else if (provider.provider === 'external_api') {
                if (provider.countryCodes.includes(validatePhone.country)) {
                    try {
                        await callByExternalApi(contactid, provider?.provider, provider?.config?.providerId)
                    } catch (e) {
                        throw e
                    }
                    handled = true
                }
            } else if (provider.provider === 'ucontact' && provider.config.enablePressToCall) {
                if (!isOpenUContact) {
                    dispatch(setOpenUContact(true))
                }

                const iframe = document.getElementById('ucontact-iframe') as HTMLIFrameElement;
                if (iframe && iframe.contentWindow) {
                    const call = {
                        action: 'makecall',
                        params: {
                            number: nationalNumber ?? phone,
                            campaign: provider.config.defaultChannel
                        }
                    };

                    try {
                        iframe.contentWindow.postMessage(call, '*');
                        handled = true;
                    } catch (e) {
                        console.error('Error al enviar el mensaje al iframe:', e);
                    }
                } else {
                    console.error('Iframe no encontrado o no está listo');
                }
            }

        }
    }

    if (!handled) {
        if (_.get(isAuth(), 'personalConfig.callOnPhone', false)) {
            let result = await axios.get(`${API_WPP}/crm/contact/${contactid}/callonapp/${nationalNumber ?? phone}`,
                { headers: { Authorization: `JWT ${getCookie('token')}` } })
        } else {
            window.open(`tel:${nationalNumber ?? phone}`, '_blank')
        }
    }
    // if(!handled) window.open(`tel:${phone}`, '_blank')
}