import { LocalOffer } from "@mui/icons-material";
import { FormControl, InputLabel, Stack, ListItemAvatar, MenuItem, Typography, ListItemText } from "@mui/material";
import tagColorToPalette, { contactStatusTagColorArray } from "../../../helpers/tagColorToPalette";
import { contactStatusTag } from "../../../pages/company/tags";
import { Avatar } from "@/components/ui/avatar";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const StatusTagSelect = ({ value, onValueChange, name, required = false, list }) => {
    const safeList = Array.isArray(list) ? list : [];

    return (

        <div className="flex flex-col gap-2 w-full">
            <Select
                value={value}
                name={name}
                required={required}
                onValueChange={onValueChange}
            >
                <SelectTrigger className="bg-[#F1F3F4] border-none">
                    <SelectValue placeholder="Selecciona una opción" />
                </SelectTrigger>
                <SelectContent>
                    {safeList.filter((item) => item.active).map((item) => (
                        <SelectItem key={item.code} value={item.code}>
                            <div className="flex gap-2">

                                <Avatar style={{ backgroundColor: tagColorToPalette[item.color].primary, color: tagColorToPalette[item.color].secondary, width: '22px', height: '22px' }}>
                                </Avatar>
                                <p>
                                    {item.name}
                                </p>
                            </div>
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
}

export default StatusTagSelect