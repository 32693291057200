'use client'
import { getCompany } from "@/actions/auth";
import { update } from "@/actions/crm/contact";
import { LoadingButton } from "@/components/buttons/loading-button";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { Contact } from "@/interfaces/crm/contact";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AditionalDataContact } from "../ContactDrawer/AdditionalDataForm";


interface ModalAlertOnSaleProps {
    open: string[] | boolean,
    contact: any,
    onClose: (archived: boolean) => void
}

const schemaForm = z.object({
    additionalData: z.any()
});

export const ModalAlertOnSale = ({ open, onClose, contact }: ModalAlertOnSaleProps) => {

    const requiredOnSaleFields = _.get(getCompany(), 'additionalDataFields', []).filter(field => field.active && field.requiredOnSale);

    const contextForm = useForm<z.infer<typeof schemaForm>>({
        resolver: zodResolver(schemaForm),
        defaultValues: {
            additionalData: {},
        }
    });

    const isValidForm = contextForm.formState.isValid;

    const { mutate, isPending } = useMutation({
        mutationFn: (updateProperties: Contact) => update(contact._id as string, updateProperties),
        onSuccess: () => onClose(true)
    })

    const handleSubmit = async (values: z.infer<typeof schemaForm>) => {
        const contactUpdate = { ...contact, additionalData: values.additionalData }
        let withError = false;

        requiredOnSaleFields.forEach((field) => {
            const additionalDataValue = contactUpdate.additionalData[field.code];

            if (!additionalDataValue) {
                return contextForm.setError(`additionalData.${field.code}` as any, {
                    message: `Campo requerido`
                })
            };

            const maxChars = field?.maxChars || 0;
            const minChars = field?.minChars || 0;

            if ((maxChars > 0 || minChars > 0)) {
                const valueLength = `${additionalDataValue || ''}`.length;

                if ((minChars > 0 && valueLength < minChars) || (maxChars > 0 && valueLength > maxChars)) {
                    withError = true;
                    contextForm.setError(`additionalData.${field.code}` as any, {
                        message: `El valor debe tener entre ${minChars} y ${maxChars} caracteres.`
                    })
                }
            }
        });

        if (withError) return;
        mutate(contactUpdate);
    };

    useEffect(() => {
        if (contact?.additionalData) {
            contextForm.setValue('additionalData', contact?.additionalData)
        }
    }, [contact])

    return (
        <Dialog open={!!open} onOpenChange={() => onClose(false)} >
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Campos requeridos en venta</DialogTitle>
                    <DialogDescription className="flex">
                        <p>¡Completa los siguientes campos requeridos a la hora de realizar una venta!</p>
                    </DialogDescription>
                </DialogHeader>
                <Form  {...contextForm}>
                    <form onSubmit={contextForm.handleSubmit(handleSubmit)} className="space-y-2">
                        <div className="pb-4 flex flex-col gap-4">
                            <AditionalDataContact
                                additionalDataFields={requiredOnSaleFields}
                                contextForm={contextForm}
                                requiredOnSale={true}
                                id={contact?._id}
                            />
                        </div>
                        <DialogFooter className="flex flex-row items-center justify-center">
                            <Button
                                type="button"
                                variant={'outline'}
                                onClick={() => onClose(false)}
                            >
                                Cancelar
                            </Button>
                            <LoadingButton
                                disabled={!isValidForm}
                                loading={isPending}
                                type={"submit"}
                            >
                                Guardar y archivar
                            </LoadingButton>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog >
    )
}
