import useGetStatusTag from "@/hooks/useGetStatusTag";
import { changeStatusTag } from "@/redux/slices/thunks/crm";
import { ChevronDown, XIcon } from "lucide-react";
import { useDispatch } from "react-redux";
import { Badge } from "../../ui/badge";
import PuppoverStatusTagChange from "../StatusTag/puppover-change";

interface Props {
    statusTag: string | null | undefined
    id: string | null | undefined
}

const StatusTagPill = ({
    statusTag,
    id,
}: Props) => {
    const { find, color, tags } = useGetStatusTag({ code: statusTag || '' })
    
    const isMatchedTag = (statusTag && find)

    return tags?.length > 0 ? (
        <PuppoverStatusTagChange
            id={id || ''}
            statusTag={statusTag || ''}
            align="center"
            CustomPopoverTrigger={
                <button>
                    <Badge variant={'outline'} className="relative z-[1] bg-white">
                        <div className="flex items-center gap-2 py-1">
                            <div className="flex items-center shrink-0 gap-1">
                                {isMatchedTag && (
                                    <span
                                        style={{ backgroundColor: color }}
                                        className="w-[13px] h-[13px] rounded-full shrink-0"></span>
                                )}
                                <span>{isMatchedTag ? find?.name : "Agregar Etiqueta"}</span>
                            </div>
                            {
                                isMatchedTag
                                    ? <DeleteStatusTagButton contactId={id as string} />
                                    : <span>
                                        <ChevronDown size={13} className="transition-transform" />
                                    </span>
                            }
                        </div>
                    </Badge>
                </button>
            }
        />
    ) : null
}


export function DeleteStatusTagButton({ contactId }: { contactId: string }) {

    const dispatch = useDispatch()
    const onDeleteStatusTag = () => dispatch(changeStatusTag({ id: contactId, status: '' }))

    const onHandleDelete = (e) => {
        e.stopPropagation()
        e.preventDefault()
        onDeleteStatusTag()
    }

    return (
        <button onClick={onHandleDelete}>
            <XIcon size={13} />
        </button>
    )
}

export default StatusTagPill