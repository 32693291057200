import { getCompanyGroupById } from "@/actions/comercial/companygroup"
import { ICompanyGroup } from "@/interfaces/Interface"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"

export default function useGetCompanyGroup(companyGroupId: string | null, allInformation: boolean = false) {
    const [company, setCompany] = useState<ICompanyGroup | undefined>(undefined)

    const { data, ...rest } = useQuery({
        queryKey: [`company-group-${companyGroupId}`, companyGroupId],
        queryFn: () => getCompanyGroupById(companyGroupId as string, allInformation),
        enabled: !!companyGroupId && typeof companyGroupId === 'string'
    })

    useEffect(() => {
        setCompany(data)
    }, [data])

    const onHandleChangeCompany = (values: ICompanyGroup) => setCompany(values)

    return {
        company,
        onHandleChangeCompany,
        ...rest,

    }
}
