import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/components/ui/alert";
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import { AlertCircle } from "lucide-react";
import { LoadingButton } from "../buttons/loading-button";

interface ModalDeleteTagProps {
    open: any,
    onClose: () => void,
    description: string
    error?: string | boolean
    handleSubmit: () => void
    loading: boolean
    title: string
    deleteText?: string
}

export const AlertDialogDelete = ({
    open,
    onClose,
    description,
    handleSubmit,
    loading = false,
    title,
    error,
    deleteText
}: ModalDeleteTagProps) => {
    if (!open) return null
    return (
        <AlertDialog open={open} onOpenChange={onClose}>
            <AlertDialogContent>
                {
                    error && <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertTitle>Error</AlertTitle>
                        <AlertDescription>
                            {error}
                        </AlertDescription>
                    </Alert>
                }
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription className="flex flex-col gap-3">
                        {description}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={onClose}>Cancelar</AlertDialogCancel>
                    <LoadingButton variant={'destructive'} onClick={handleSubmit} loading={loading} type="button">
                        {deleteText ?? "Eliminar"}
                    </LoadingButton>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
