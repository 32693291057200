import SaleDetailModal from "@/components/sale/SaleDetailModal";
import useGetSaleOfContact from "@/hooks/crm/useGetSaleOfContact";
import { useToggle } from "@/hooks/useToggle";
import { Receipt } from "@/interfaces/Interface";
import { Collapse } from "@mui/material";
import { useState } from "react";
import DetailAccordionItem from "./detail-accordion-item";
import SaleItem from "./sale-item";

interface SalesItemsProps { contactId: string }

export default function SalesItems({ contactId }: SalesItemsProps) {
    const [receiptSelected, setReceiptSelected] = useState<Receipt | null>(null);
    const { receipts } = useGetSaleOfContact({ contactId });

    const [showAll, toggleShowAll] = useToggle<boolean>();

    const renderReceipts = (receipts: Receipt[]) =>
        receipts.map((receipt) => (
            <li key={receipt._id}>
                <SaleItem
                    onSelect={() => setReceiptSelected(receipt)}
                    receipt={receipt}
                />
            </li>
        ));

    if (receipts.length == 0) return null;

    return (
        <>
            <DetailAccordionItem label="Ventas" value="sales">
                <div className="py-2">
                    <ul className='w-full flex flex-col gap-2'>
                        {renderReceipts(receipts.slice(0, 3))}
                    </ul>
                    <Collapse in={!!showAll} style={{ marginTop: 0 }}>
                        <ul className='pb-2 w-full my-2 flex flex-col gap-2'>
                            {renderReceipts(receipts.slice(3))}
                        </ul>
                    </Collapse>
                    <div>
                        {
                            (receipts.length > 3) && <button
                                onClick={toggleShowAll}
                                className={`w-full text-[13px] text-primary ${!showAll ? 'mt-2' : ''}`}
                            >
                                {
                                    showAll
                                        ? 'Ver menos'
                                        : ' Ver mas'
                                }
                            </button>
                        }
                    </div>
                </div>
            </DetailAccordionItem>

            <SaleDetailModal
                onOpenChange={() => setReceiptSelected(null)}
                sale={receiptSelected as Receipt}
                open={!!receiptSelected}
                editMode={false}
            />
        </>
    )
}