import AudioInteraction from "./audio-interaction";
import ExternalAdReply from './ExternalAdReply';
import FileInteractionCard from "./file-interaccion-card";
import ImageInteractionChannel from "./image-interaction-channel";
import InteractionVideo from "./interaction-video";
import Sticker from "./Sticker";

export default function InteractionChannelContent({
  children,
  mediaType,
  media,
  description,
  channel,
  isReply,
  origin,
  date,
  listened,
  externalAdReply,
  status,
  fileName,
  sticker = undefined
}: any) {
  const props = { media, description, origin, date, status, channel, fileName }

  if (externalAdReply) return <ExternalAdReply externalAdReply={externalAdReply} >
    {children}
  </ExternalAdReply>

  if (mediaType === 'image') return <ImageInteractionChannel {...props} >
    {children}
  </ImageInteractionChannel  >

  if (mediaType === 'video') return <InteractionVideo {...props} >
    {children}
  </InteractionVideo>

  if (mediaType === 'file') return <FileInteractionCard {...props} fileName={fileName || description}>
    {children}
  </FileInteractionCard  >

  if (mediaType === 'audio') return <AudioInteraction {...props} listened={listened}>
    {children}
  </AudioInteraction  >

  if (mediaType == 'sticker') return <div className={`${isReply ? 'p-2' : ''}`}>
    <Sticker url={sticker?.url} />
  </div>

  return <div>
    {children}
  </div>
}
