import { createAsyncThunk } from "@reduxjs/toolkit"
import { ICreateInteraction } from "../interfaces"
import { createArchiving, createChannelMessage, createCloseCallPhone, createMeeting, createNote, createPersonalWpp, createPhoneCall, createVisit, createScheduledMessage, updateWhatsAppMessage} from "./functions/interaction"

const thunk = createAsyncThunk<void, any>(
    'crm/createInteraction',
    async (cb, options) => await cb(options)
)

export const createInteraction = async ({ dispatch, data, type, isRetry }: ICreateInteraction) => dispatch && dispatch(thunk((options) => {
    const props = { data, options, isRetry }
    
    const types = {
        'note': () => createNote(props),
        'visit': () => createVisit(props),
        'phonecall': () => createPhoneCall(props),
        'closeCallPhone': () => createCloseCallPhone(props),
        'meeting': () => createMeeting(props),
        'archiving': () => createArchiving(props),
        'unofficial-whatsapp': ()=> createPersonalWpp(props),
        'channel-message':  ()=> createChannelMessage(props),
        'scheduled-message': ()=> createScheduledMessage(props),
        'update-whatsapp-message': ()=> updateWhatsAppMessage(props)
    }

    if (!types[type]) return;
    return types[type]()
}))