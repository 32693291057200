import { toast } from "@/components/ui/use-toast"
import { createInteraction } from "../../Prospect/libs/helpers/interaction"
import { Dispatch } from "redux"

export interface MultimediaFile {
    name: string,
    path: string,
    type: string,
    size: number
}

interface ISendNoteWithFile {
    files: MultimediaFile[],
    action: string,
    contactId: string,
    dispatch: Dispatch,
    note: string
}

export const sendNoteWithFile = async ({
    files,
    action,
    contactId,
    dispatch,
    note
}: ISendNoteWithFile) => new Promise(async (resolve, reject) => {
    let filesToSave = files.map(({ name, path, type, size }) => ({
        name,
        url: path,
        mimeType: type,
        size,
    }))

    createInteraction({
        dispatch,
        type: 'note',
        data: {
            action,
            id: contactId,
            note,
            files: filesToSave,
        }
    })
        .then((result) => resolve(result))
        .catch((e) => {
            toast({
                title: 'Error',
                description: 'No se ha podido crear la nota',
                duration: 2000
            })
            reject(e)
        })
}) 