import { WhatsApp } from "@mui/icons-material";
import { Button, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import AdvancedModal from "../../core/AdvancedModal"


interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    phones: string[];
}

const WhatsappMultiSelect = ({open, setOpen, phones}: Props) => {


    return (
        <AdvancedModal
          open={open}
          title="Selecciona un Whatsapp"
          handleClose={() => setOpen(false)}
        >
          <List sx={{ width: '100%' }}>
            {phones.map((local_phone) => (
              <ListItem sx={{ width: '100%' }}>
                <ListItemButton onClick={() => window.open("https://wa.me/" + local_phone, "_blank")}>
                  <ListItemIcon>
                    <WhatsApp />
                  </ListItemIcon>
                  <ListItemText primary={local_phone} />
                </ListItemButton>
              </ListItem>
            ))
            }
          </List>
          <Grid container justifyContent={"center"}>
            <Button onClick={() => setOpen(false)}>Cerrar</Button>
          </Grid>
        </AdvancedModal>
    )
}

export default WhatsappMultiSelect