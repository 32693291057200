
interface Address {
        location?: boolean,
        street_number?: string,
        route?: string,
        neighborhood?: string,
        locality?: string,
        administrative_area_level_1?: string,
        administrative_area_level_2?: string,
        country?: string,
        postal_town?: string,
        postal_code?: string,
        formatted_address?: string,
        place_id?: string,
        geoLocation?: {
          type: "Point",
          coordinates: [number, number]
        },
        extraInfo?: string
      } 



 const transformAddress = (address, extraInfo = ""):Address => {
    
    let value:Address = {
        location: true,
        place_id: address.place_id,
        formatted_address: address.formatted_address,
        geoLocation: {
            type: "Point",
            coordinates: [address.geometry.location.lng(), address.geometry.location.lat()]
        },
        extraInfo
    }

    address.address_components.forEach(component => {
        component.types.filter((key) => key != "political").map((key) => {
            value[key] = component.long_name
        })
    })


    return value;

}

export default transformAddress;