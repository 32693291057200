import { isAuth } from '@/actions/auth'
import { IPost } from '@/actions/training'
import CardPostOfCoach from '@/components/training/CardPostOfCoach'
import { Button } from '@/components/ui/button'
import usePagination from '@/hooks/usePagination'
import usePostCoach from '@/hooks/usePostCoach'
import { useToggle } from '@/hooks/useToggle'
import { AccountExecutive } from '@/interfaces/Interface'
import { TitleCoach } from '@/pages/training'
import _ from 'lodash'
import { FilePlus2 } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { AlertDialogDelete } from '../AlertDialogDelete'
import Loading from '../core/Loading'
import { CardTitle } from '../ui/card'
import EmptyMessage from '../ui/complex/empty-action'
import { DialogAddPost } from './DialogAddPost'
import { ROLES, isTheRolEnabled } from '@/helpers/featureFlagHelpers'

interface Props {
    limitOfPost?: number
    title?: string
    isActiveAlertNotPost?: boolean
    setLengthOfPost?: (values: number) => void
    isActiveLoading?: boolean
    coach: AccountExecutive
    emptyMessage?: boolean
    filters?: any
    queryKey?: null | string[]
    openAddPost?: boolean
    dataPerDefault?: any
    onCLoseAdd?: () => boolean
    enabled?: boolean
    activeEditPost?: boolean
}

export default function ListOfPost({
    limitOfPost = 5,
    title = 'Publicaciones de tu entrenador',
    isActiveAlertNotPost = false,
    setLengthOfPost,
    isActiveLoading = false,
    emptyMessage,
    dataPerDefault,
    activeEditPost = false,
    enabled = true,
    filters = { limit: 5 },
    coach,
    queryKey,
    openAddPost,
    onCLoseAdd
}: Props) {
    const isAdmin = isTheRolEnabled(ROLES.ADMIN)
    const { page, handleNextPage, setLimitPage, limitOfPage, handleResetValues } = usePagination({ initialPage: 1 })
    const [openDeleteAlert, toggle, setOpenDelete] = useToggle<false | any>(false)
    const [openDialog, onToggle, setOpenDialog] = useToggle<any>(false)

    const {
        posts,
        refetch,
        countDocuments,
        handleDeletePost,
        isLoadingMutate,
        isLoading: isLoadingPost,
        isLoadingGetPost } =
        usePostCoach({ activePost: enabled, offset: page, limit: limitOfPost, ...filters, queryKey})

    const [allPosts, setAllPosts] = useState<IPost[]>([])

    useEffect(() => {
        if (page > 1) refetch()
    }, [page])

    useEffect(() => {
        setLengthOfPost && setLengthOfPost(posts.length)

        if (posts.length > 0) {
            setAllPosts(lastPosts => [...lastPosts, ...posts])
        }
    }, [posts])

    useEffect(() => {
        setLimitPage(Math.ceil(countDocuments / limitOfPost))
    }, [countDocuments])

    const handleDelete = useCallback(() => {
        if (!!openDeleteAlert) {
            const id = openDeleteAlert?._id
            handleDeletePost(id, (value) => {
                setAllPosts(lastValue => lastValue.filter(post => post._id !== value.delete._id))
            })
        }
    }, [openDeleteAlert, allPosts])

    if (!coach) return null
    return <section className='flex flex-grow flex-col'>
        {
            (isLoadingGetPost && isActiveLoading)
                ? <Loading />
                : allPosts?.length > 0 ? <section className='flex  flex-grow flex-col'>
                    <TitleCoach title={title} />
                    <ul className='flex flex-col gap-14 flex-grow mt-1 relative'>
                        {
                            allPosts?.map(({ _id, ...restPost }) => (
                                <CardPostOfCoach
                                    coach={coach}
                                    onDelete={activeEditPost && isAdmin && setOpenDelete}
                                    onEdit={activeEditPost && isAdmin && setOpenDialog}
                                    key={_id}
                                    information={{ _id, ...restPost }} />
                            ))
                        }
                    </ul>
                    {
                        page < limitOfPage && !isLoadingPost && <div className='flex items-center justify-center mt-6'>
                            <Button className='uppercase sm:text-[13px] text-[11px]' onClick={() => handleNextPage()}>
                                PUBLICACIONES ANTERIORES
                            </Button>
                        </div>
                    }
                    {
                        isLoadingPost && <Loading />
                    }
                </section>
                    : emptyMessage ? <div className='mt-10'>
                        <EmptyMessage
                            message="No se han configurado instancias. Debes crearlas para comenzar a administrarlas."
                            icon={
                                <FilePlus2 size={60} color="gray" />
                            }
                            buttonAction={() => onToggle()}
                            buttonText="Crear publicación"
                        />
                    </div> : isActiveAlertNotPost && <NotContent label={'Sin publicaciones'} />
        }
        <AlertDialogDelete
            description='¿Estás seguro? Esta acción es irreversible y no se puede deshacer.'
            loading={isLoadingMutate}
            onClose={() => setOpenDelete(null)}
            open={!!openDeleteAlert}
            title={`Eliminar publicación`}
            handleSubmit={handleDelete}
        />
        {
            (openAddPost || !!openDialog) && <DialogAddPost
                open={openAddPost || openDialog}
                refetch={() => {
                    handleResetValues()
                    setAllPosts([])
                    refetch()
                }}
                dataPerDefault={dataPerDefault}
                onClose={() => {
                    onCLoseAdd && onCLoseAdd()
                    setOpenDialog(false)
                }}
            />
        }

    </section>
}

export function NotContent({ label }) {
    return <div>
        <CardTitle className='text-sm mt-10 text-center'>{label}</CardTitle>
    </div>
}