import { getDocumentUrl } from "@/actions/aws/documents";
import { LoadingButton } from "@/components/buttons/loading-button";
import { buttonVariants } from "@/components/ui/button";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { cn } from "@/lib/utils";
import axios from "axios";
import { VariantProps } from "class-variance-authority";
import { Mic, Pause, SendHorizonal, Trash2 } from "lucide-react";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import VoiceAudioPlayer from "./VoiceAudioPlayer";

interface AudioRecorderProps {
    onSendAudio?: (value: string, mimetype: string) => void
}

export default function AudioRecorder({
    onSendAudio
}: AudioRecorderProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [duration, setDuration] = useState(0)
    const [openRecordingOptions, setRecordingOptions] = useState<boolean>(false)
    const [isPaused, setIsPaused] = useState<boolean>(false);

    const recorderControls = useAudioRecorder({
        noiseSuppression: true,
        echoCancellation: true
    })

    const isRecording = recorderControls.isRecording

    const durationFormatted = useMemo(() => {
        const durationInSeconds = duration;
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const formattedHours = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
    }, [duration]);

    const urlBlob = useMemo(() => {
        return recorderControls.recordingBlob ? URL.createObjectURL(recorderControls.recordingBlob) : null
    }, [recorderControls.recordingBlob])

    useEffect(() => {
        const recordingTime = recorderControls.recordingTime
        if (recordingTime) setDuration(recordingTime)
    }, [recorderControls.recordingTime])

    const onHandleSend = async () => {
        if (!urlBlob) recorderControls.stopRecording();
        const blob = recorderControls.recordingBlob;

        if (blob) {
            console.log('Blob type:', blob.type); 

            try {
                const newBlob = new Blob([blob], { type: 'audio/mpeg' });

                let pressignedURL = await getDocumentUrl({ name: 'voice-audio.mp3', mimeType: 'audio/mpeg' });
                const url = pressignedURL.url;

                await axios.put(url, newBlob, {
                    headers: {
                        'Content-Type': 'audio/mpeg',
                        'Access-Control-Allow-Origin': '*',
                        'x-amz-meta-duration': recorderControls.recordingTime,
                        'x-amz-meta-encoding': 'mp3',
                        'x-amz-meta-sample-rate': '44100',
                    },
                    fileName: 'voice-audio.mp3'
                });

                const splittedUrl = url.split('?')[0];
                onSendAudio && onSendAudio(splittedUrl, 'audio/mpeg');
            } catch (e) {
                console.log(e)
            }
        }

        onHandleDeleteRecordVoice()
    }

    const onHandleRecordButton = () => {
        if (!openRecordingOptions) {
            onHandleToggleRecording()
            return setRecordingOptions(true)
        }

        onHandleSend()
    }

    const onHandleToggleRecording = () => {
        if (!isRecording) {
            setDuration(0)
            return recorderControls.startRecording()
        }

        recorderControls.stopRecording()
        setIsPaused(!isPaused);
    }

    const onHandleDeleteRecordVoice = () => {
        setRecordingOptions(false)
        setIsPaused(false);
        setDuration(0)
        recorderControls.stopRecording()
    }

    return (
        <div className='relative flex items-center  gap-2'>
            {
                openRecordingOptions && (
                    <div className="flex items-center gap-2">
                        <AudioRecorderButton variant={'ghost'} onClick={onHandleDeleteRecordVoice}>
                            <Trash2 size={20} />
                        </AudioRecorderButton>
                        <div className={'min-w-[200px] flex items-center'}>
                            {
                                !(urlBlob && isPaused) && (
                                    <p className="font-medium text-[15px] text-gray-700">
                                        {durationFormatted}
                                    </p>
                                )
                            }
                            {
                                (urlBlob && isPaused) && (
                                    <VoiceAudioPlayer
                                        classNameContent={'bg-black/5 border-none'}
                                        url={urlBlob}
                                    />
                                )
                            }
                        </div>
                        <AudioRecorderButton
                            variant={'ghost'} onClick={onHandleToggleRecording}>
                            {
                                isRecording && !isPaused
                                    ? <Pause size={22} />
                                    : <Mic size={22} />
                            }
                        </AudioRecorderButton>
                    </div>
                )
            }
            <SimpleTooltip content={openRecordingOptions ? 'Enviar' : 'Grabar mensaje de voz'}>
                <AudioRecorderButton
                    disabled={isRecording}
                    loading={isLoading}
                    className={`${openRecordingOptions ? 'hover:bg-primary rounded-full text-white hover:text-white' : ''} `}
                    variant={!openRecordingOptions ? 'ghost' : 'default'} onClick={onHandleRecordButton}>
                    {
                        openRecordingOptions
                            ? <SendHorizonal size={20} />
                            : <Mic size={22} />
                    }
                </AudioRecorderButton>
            </SimpleTooltip>
        </div>
    );
};

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
    children: ReactNode,
    loading?: boolean
}

const AudioRecorderButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, children, ...props }, ref) => {
        return (
            <LoadingButton
                size={size || 'icon'}
                type={'button'}
                variant={variant}
                className={cn('hover:bg-transparent text-[#757575] hover:text-black/40 shrink-0', className)}
                {...props}
                ref={ref}
            >
                {children}
            </LoadingButton>
        )
    }
)