import getCompanyField from "./getCompanyField";
import phoneValidator, { CountryCode, PhoneNumber } from 'libphonenumber-js';

export function getBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result as string);
        };
        reader.onerror = function (error) {
            reject(error);
        };
    });
}

export const addStrToInput = (newStr: string, ref): string => {
    const { selectionStart, selectionEnd, value } = ref.current;

    const newValue =
        value.substring(0, selectionStart) + newStr + value.substring(selectionEnd);

    ref.current.value = newValue;
    ref.current.setSelectionRange(selectionStart + 1, selectionStart + 1);

    return newValue
}

export const validatePhoneNumber = (phone: string | number, defaultCountryCode?: string): PhoneNumber | undefined => {
    const phoneCode = (defaultCountryCode || getCompanyField<string>('country', 'UY')).toUpperCase()
    const stringValue = `${phone}`

    const firstNumberValidator = phoneValidator(stringValue, phoneCode as CountryCode)
    const secondValidator = phoneValidator(`+${stringValue}`, phoneCode as CountryCode)

    let returnData: PhoneNumber = firstNumberValidator as PhoneNumber
    
    if (firstNumberValidator?.isValid()) {
        returnData = firstNumberValidator
    } else if (secondValidator?.isValid()) {
        returnData = secondValidator
    }


    return returnData
} 

export const splitTextIntoLines = (text: string): string[] => {
    if (!text) return [];
    return text.split(/\r\n|\r|\n/);
  };
  