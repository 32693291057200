import { ICompanyGroup } from "@/interfaces/Interface";
import { ContactAvatar } from "../Prospect";
import RedirectToEditCompanyButton from "./RedirectToEditCompanyButton";

interface ContactsFieldItemProps {
    contacts: { firstName: string, lastName: string, photo: string, statusTag: string, _id: string }[],
    company: ICompanyGroup | undefined,
};

export default function ContactsFieldItem(props: ContactsFieldItemProps) {
    const { contacts = [], company } = props;

    return (
        <ul className="flex items-center flex-wrap gap-x-4 gap-1.5">
            {
                contacts.filter((contact) => contact && contact?._id).map((contact, inx) => {
                    const fullName = [contact?.firstName, contact?.lastName]
                        .filter(Boolean)
                        .join(' ');

                    return (
                        <li className="flex items-center" key={contact?._id || inx}>
                            <ContactAvatar
                                className="w-[19px] mr-1 border text-[11px] h-[19px]"
                                statusTag={contact?.statusTag}
                                firstName={contact?.firstName}
                                lastName={contact?.lastName}
                                photo={contact?.photo}
                            />
                            <p className="text-[13.5px] text-gray-700">
                                {fullName}
                            </p>
                        </li>
                    )
                })
            }
            <RedirectToEditCompanyButton companyId={company?._id || ''} />
        </ul>
    )
}
