import { Input } from '@/components/ui/input';
import EmojiMenu from '../ChatInput/EmojiMenu';
import { useToggle } from '@/hooks/useToggle';
import { Mood } from '@mui/icons-material';
import { Laugh } from 'lucide-react';

interface InputMultimediaProps {
    value: string
    handleChange: (e: any) => void
    placeholder?: string
}

export default function InputMultimedia({
    value,
    handleChange,
    placeholder = ''
}) {
    const [open, toggle] = useToggle()
    return <>
        <EmojiMenu
            addEmoji={(emoji) => handleChange(value.concat(emoji))}
            openEmojiMenu={open}
            setOpenEmojiMenu={toggle} />
        <div className='relative w-full'>
            <Input
                value={value}
                onChange={handleChange}
                className='w-full rounded-[4px] text-slate-600 pr-14 focus-visible:ring-0 focus-visible:ring-offset-0 h-11 bg-white'
                placeholder={placeholder || 'Escribe un mensaje...'}
            />
            <button
                type='button'
                onClick={toggle}
                className='absolute w-[2.3rem] flex items-center justify-center right-2 transition-[background-color] h-[2.3rem] hover:bg-slate-100 rounded-full [&>svg]:w-[22px] [&>svg]:h-[22px] [&>svg]:text-slate-600 top-0 bottom-0 m-auto'>
                <Laugh />
            </button>
        </div>
    </>

}
