"use client"

import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import * as React from "react"

import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { es } from "date-fns/locale"


interface DatePickerProps {
    value: Date | undefined,
    onChangeValue: (value: Date | undefined) => void,
    classNameTriggerButton?: string,
    extraTriggerContent?: React.ReactNode
    formatDateValue?: string,
    classNameIcon?: string
    isEditMode?: boolean
}

export function DatePicker(props: DatePickerProps) {
    const { onChangeValue, value, classNameTriggerButton, extraTriggerContent, isEditMode = true, classNameIcon, formatDateValue = 'PPP' } = props;

    const GetTrigger = () => {
        return (
            <Button
                variant={"outline"}
                className={cn(
                    "w-[280px] justify-start text-left font-normal",
                    !value && "text-muted-foreground"
                    , classNameTriggerButton)}
            >
                <CalendarIcon className={cn(`mr-2 h-4 shrink-0 w-4`, classNameIcon)} />
                {value ? format(value, formatDateValue, { locale: es }) : <span>Seleccionar fecha</span>}
                {extraTriggerContent}
            </Button>
        )
    };

    if (!isEditMode) return GetTrigger();

    return (
        <Popover>
            <PopoverTrigger asChild>
                {GetTrigger()}
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={value}
                    onSelect={onChangeValue}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}
