import { isAuth } from "@/actions/auth"
import { VariablesMenu } from "@/components/company/shortcuts/VariablesMenu"
import { Button } from "@/components/ui/button"
import { CustomFormField } from "@/components/ui/custom-form-field"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog"
import { Form } from "@/components/ui/form"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { addStrToInput } from "@/helpers"
import { ModalPropsBase } from '@/interfaces/Interface'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useRef } from "react"
import { useForm } from "react-hook-form"
import { defaultValues, SchemaEditReminder, SchemaEditReminderType, variablesOptions } from "./utils/constants"

interface EditReminderModalProps extends ModalPropsBase {
    onSaveChange: (value: SchemaEditReminderType) => void,
    defaultValue?: Partial<SchemaEditReminderType>
}

const notifyMediums = [
    {
        name: 'WhatsApp',
        code: 'whatsApp'
    },
    {
        name: 'Correo electrónico',
        code: 'email'
    }
];

export default function EditReminderModal({
    open,
    onOpenChange,
    onSaveChange,
    defaultValue
}: EditReminderModalProps) {
    const refTextarea = useRef<null | HTMLTextAreaElement>(null)

    const contextForm = useForm<SchemaEditReminderType>({
        resolver: zodResolver(SchemaEditReminder),
        defaultValues: defaultValues
    });

    const medium = contextForm.watch('medium');
    const user = isAuth();

    const enabledMediums = useMemo(() => {
        return notifyMediums.filter((medium) => {
            if (medium.code == 'email') {
                return (user?.emailProviders || []).length > 0 && user?.config?.enablePersonalEmail;
            };

            return true;
        })
    }, [user])

    const handleAddVariable = (variable: string) => {
        const inputElement = refTextarea.current
        if (inputElement) {
            const newTextValue = addStrToInput(variable, refTextarea)
            contextForm.setValue('messageContent.message', newTextValue)
        }
    };

    const onSubmit = (values: SchemaEditReminderType) => {
        onSaveChange(values)
        onOpenChange(false)
    };

    useEffect(() => {
        if (open && defaultValue) contextForm.reset({
            ...defaultValue,
            medium: defaultValue?.medium || 'whatsApp'
        })
    }, [open])

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[550px]">
                <DialogHeader>
                    <DialogTitle>Editar recordatorio</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-2">
                    <Form {...contextForm}>
                        <form
                            id='editReminderForm'
                            className="grid gap-4"
                            onSubmit={(e) => {
                                e.stopPropagation()
                                contextForm.handleSubmit(onSubmit)(e)
                            }}>
                            <CustomFormField
                                control={contextForm.control}
                                label="Medio por el cual enviar recordatorio"
                                name="medium"
                                help={medium == 'email' ? "Si el contacto al que se enviará el recordatorio no tiene una dirección de correo electrónico, el recordatorio no se enviará" : ''}
                                isRequired
                                className="mb-2"
                                fnElement={({ field }) => (
                                    <Select value={field.value} onValueChange={field.onChange}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Seleccionar" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    enabledMediums.map(({ code, name }) => (
                                                        <SelectItem value={code} key={code}>
                                                            {name}
                                                        </SelectItem>
                                                    ))
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                            <CustomFormField
                                control={contextForm.control}
                                label="Mensaje a enviar al cliente"
                                name="messageContent.message"
                                isRequired
                                fnElement={({ field }) => (
                                    <>
                                        <div className="w-full flex items-center justify-end ">
                                            <div className="relative mt-[-32px]">
                                                <VariablesMenu
                                                    withKeys={true}
                                                    classNameIcon=""
                                                    addValue={handleAddVariable}
                                                    variables={variablesOptions}
                                                    classNameTrigger="border border-input"
                                                />
                                            </div>
                                        </div>
                                        <Textarea
                                            {...field}
                                            ref={refTextarea}
                                            className="min-h-[200px]"
                                            style={{ whiteSpace: 'pre-line' }}
                                            placeholder={`*Recordatorio automático ⏰*
                                            Hola {{contact.firstName}} {{contact.lastName}}, 
                                            Queremos recordarte que tenemos una reunión programada el día de hoy {{meeting.currentDate}} a las {{meeting.meetingHour}} vía {{meeting.medium}}.
    `}
                                        />
                                    </>
                                )}
                            />
                        </form>
                    </Form>
                </div>
                <DialogFooter>
                    <Button
                        onClick={() => onOpenChange(false)}
                        variant="outline"
                        type="button"
                    >
                        Cancelar
                    </Button>
                    <Button type="submit" form="editReminderForm">
                        Guardar cambios
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
