import { Button } from "../ui/button";

export default function ButtonHeader({
    isCollapsedMenu,
    label,
    icon
}) {
    return (
        <Button
            variant={"ghost"}
            type="button"
            className={`flex w-full text-[13px] px-2 h-[34px] hover:bg-blue-100 justify-start ${isCollapsedMenu ? 'lg:h-[38px] lg:w-[42px] justify-center' : 'h-[30px]'} rounded-[4px] whitespace-nowrap  truncate items-center gap-2`}
        >
            <span className="shrink-0 [&>svg]:w-[17px] [&>svg]:h-[17px] [&>svg]:text-[#6B7280]">
                {
                    icon
                }
            </span>
            <span className={`${!isCollapsedMenu ? 'flex' : 'lg:hidden'}`}>
                {label}
            </span>
        </Button>
    )
}