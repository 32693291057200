import { Button } from '@/components/ui/button'
import dayjs from 'dayjs'
import { CalendarClock } from 'lucide-react'
import { ExpiredMeeting as IExpiredMeeting } from '../../../interfaces/crm/contact'
import { meetingTypes } from '../Modal/meeting/select-meeting-type'
import { formatDate, meetingStatusData } from './MeetingBubble'

interface ExpiredMeetingProps {
  interaction: IExpiredMeeting
}

export default function ExpiredMeeting({
  interaction
}: ExpiredMeetingProps) {
  const { content } = interaction || {}
  const { backgroundColor, textColor } = meetingStatusData['error']
  const { icon: iconMedium, label: mediumLabel } = meetingTypes[content.meetingType]

  const scrollIntoMeetingMessage = () => {
    const message = document.getElementById(content.meeting)
    if (message) message.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  return (
    <div className="w-full justify-center  flex items-center">
      <div className="bg-white shadow-bubble-phone flex rounded-md md:min-w-[380px] min-w-auto max-w-[380px] overflow-hidden border flex-col relative">
        <div className="flex items-center px-4  h-[35px] w-full" style={{ backgroundColor }}>
          <h6 className={"font-medium  flex items-center gap-2 text-[13px]"} style={{ color: textColor }}>
            <CalendarClock size={18} />
            Reunión expirada
          </h6>
        </div>
        <div className="flex p-4  flex-col gap-2 w-full">
          <p className="text-[13px] text-gray-500">
            {formatDate(dayjs(content.expiredDate))}
          </p>
          <div className="flex gap-2 items-center">
            <span className="[&>svg]:w-[20px] [&>svg]:h-[20px]">
              {iconMedium}
            </span>
            <h4 className="text-[17px] truncate line-clamp-2 font-medium text-gray-800">
              La reunión vía {mediumLabel} ha expirado
            </h4>
          </div>
          <div className="w-full flex justify-end mt-2">
            <Button
              variant="link"
              className="h-5 px-2 flex items-center gap-2 text-[13px]"
              onClick={scrollIntoMeetingMessage}
            >
              Ir a la reunión
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
