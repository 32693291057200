import styles from "./styles.module.scss";
import { Card, CardDescription, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Edit3, Trash2 } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

const STATUS_NAMES = {
  'pre-approved': "Pre-aprobada",
  'approved': "Aprobada",
  'rejected': "Rechazada"
}

const STATUS_COLORS = {
  'pre-approved': "success",
  'approved': "success",
  'rejected': 'destructive'
}

const TYPES_NAMES = {
  "ACCOUNT_UPDATE": "Actualización de cuenta",
  "CUSTOMER_FEEDBACK": "Feedback del cliente",
  "HUMAN_AGENT": "Agente humano",
  "CONFIRMED_EVENT_UPDATE": "Actualización de evento confirmado",
  "POST_PURCHASE_UPDATE": "Actualización de compra",
  "MARKETING": "Marketing",
  "UTILITY": "Utilidad",
  "AUTHENTICATION": "Autenticación"
}

export const BadgeTemplateStatus = ({ status }: { status: string }) => {
  return (
    <Badge variant={STATUS_COLORS[status.toLowerCase()] ?? "outline"}>
      {`${STATUS_NAMES[status.toLowerCase()] ?? status}`}
    </Badge>
  )
}

type variantTypeButton = "link" | "default" | "destructive" | "outline" | "secondary" | "ghost"

export const TemplateItemButton = ({ tooltip, icon, onClick, variant = 'outline', disabled = false, buttonClassName }: { tooltip: string, icon: React.ReactElement, onClick: boolean | undefined | null | (() => void), variant?: variantTypeButton, disabled?: boolean | null, buttonClassName?: string }) => {

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button className={cn(buttonClassName)} size="icon" type='button' variant={variant} onClick={onClick} disabled={disabled as boolean}>
          {icon}
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p>{tooltip}</p>
      </TooltipContent>
    </Tooltip>
  )

}

export const TemplateListItem = ({ template, onEdit, onDelete }: any) => {
  return (
    <Card key={template.id}>
      <div className="my-5 mx-4 grid grid-cols-6">
        <div className="col-span-5">
          <CardTitle className="flex gap-2">
            {template.name}
            <div className="text-gray-500 text-sm font-normal">{`${TYPES_NAMES[template?.tag] ?? template?.tag}`}</div>
          </CardTitle>

          <CardDescription className="mt-2">
            <BadgeTemplateStatus status={template.status} />
          </CardDescription>
        </div>
        <div className="col-span-1 flex align-middle justify-end gap-2">
          {!template.config && <TemplateItemButton tooltip="Editar" icon={<Edit3 className="h-5 w-5" />} onClick={onEdit(template)} />}
          <TemplateItemButton tooltip="Eliminar" icon={<Trash2 className="h-5 w-5" />} onClick={onDelete(template)} />
        </div>
      </div>
    </Card>
  );
};
