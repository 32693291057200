import { Button } from "@/components/ui/button"
import { CardDescription, CardTitle } from "@/components/ui/card"
import { cn } from "@/lib/utils"
import dayjs, { Dayjs } from "dayjs"
import { useMemo } from "react"

export function ManualDateButton({
  handleSelectedDate,
  title,
  format,
  field,
  type,
  date,
  currentDate,
  functionsToApply,
  handleEditDate,
  className = "",
}: any) {

  const converterDate = useMemo(
    () => {
      let newDate: Dayjs = date instanceof Date
        ? dayjs(date)
        : dayjs(currentDate).add(date[0], date[1])

      if (Array.isArray(date) && functionsToApply) {
        newDate = dayjs(functionsToApply.reduce((result, func) => func(result), newDate))
      }

      return newDate
    },
    [date])

  const isSelectedDate = useMemo(() => new Date(converterDate as any).toString() ===
    field.value.date.toString() && field.value.type === type,
    [converterDate, field.value.date]
  )

  return (
    <>
      <Button
        variant={"secondary"}
        className={cn(
          `sm:h-[65px] h-[60px] w-[100%] text-center px-[15px] flex justify-center items-center shrink-0 flex-col   ${isSelectedDate
            ? "bg-primary hover:bg-primary  text-white"
            : "bg-neutral-100 text-slate-600"
          }`,
          className
        )}
        type="button"
        onClick={() => {
          handleSelectedDate(converterDate, field, type)
          handleEditDate(converterDate)
        }}
      >
        <CardTitle className="sm:text-[16px] sm:mt-0 font-medium text-center w-full text-ellipsis whitespace-nowrap text-[14px] ">
          {title}
        </CardTitle>
        <CardDescription
          className={`sm:text-[11px] sm:mt-0  leading-[1] mt-[2px] text-[10px] ${isSelectedDate ? "text-white" : "text-slate-600"
            } w-full shrink-0 whitespace-break-spaces font-normal`}
        >
          {converterDate && converterDate.format(format)}
        </CardDescription>
      </Button>
    </>
  )
}
