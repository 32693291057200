import { Box, Menu } from '@mui/material';
import { Categories } from 'emoji-picker-react';
import dynamic from 'next/dynamic';

const EmojiPicker = dynamic(
  () => {
    return import('emoji-picker-react');
  },
  { ssr: false }
);



const emojiLocaleToEs = [
    {
        category: 'suggested',
        name: 'Recientes'
    },
    {
        category: 'smileys_people',
        name: 'Caras y personas'
    },
    {
        category: 'animals_nature',
        name: 'Animales y naturaleza'
    },
    {
        category: 'food_drink',
        name: 'Comida y bebida'
    },
    {
        category: 'travel_places',
        name: 'Viajes y lugares'
    },
    {
        category: 'activities',
        name: 'Actividades'
    },
    {
        category: 'objects',
        name: 'Objetos'
    },
    {
        category: 'symbols',
        name: 'Símbolos'
    },
    {
        category: 'flags',
        name: 'Banderas'
    }
    
] as any

interface EmojiMenuProps {
    addEmoji: (emoji: string) => void;
    openEmojiMenu: any;
    setOpenEmojiMenu: (open: any) => void;
}

const EmojiMenu = ({addEmoji, openEmojiMenu, setOpenEmojiMenu}:EmojiMenuProps) => {
    


    return (

        <Menu
            anchorEl={openEmojiMenu}
            open={!!openEmojiMenu}
            onClose={() => setOpenEmojiMenu(null)}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            sx={{p: 0, m: 0, background: "rgba(0,0,0,0)", border: '0px' }}
            MenuListProps={{ sx: { py: 0,  background: "rgba(0,0,0,0)", borderRadius: '100px' }  }}
            PaperProps={{ sx: { py: 0,  background: "rgba(0,0,0,0)", borderRadius: '10px' }  }}
        >
            <Box sx={{minWidth: '350px', minHeight: '450px', p:0}}>
                <EmojiPicker 
                searchPlaceHolder='Buscar' 
                categories={emojiLocaleToEs}
                previewConfig={
                    {
                        showPreview: false,
                    }
                }
                onEmojiClick={(emoji) => {
                    addEmoji(emoji.emoji)

                }}
                />
            </Box>
        </Menu>

    )
}

export default EmojiMenu