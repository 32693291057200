
import { formatFileSize } from '@/helpers/formatFileSize';
import React, { useMemo, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { FileIcon } from '../Bubble/Icons/Crm';
import ImageEditor from './image-editor';

function ElementBySelectedType({
    type,
    fileSelect,
    isEditMode,
    indexElement,
    realPath,
    editorRef,
    fnDebounce
}: {
    type: string,
    fileSelect: any,
    indexElement: number,
    realPath: string,
    editorRef: null | AvatarEditor,
    isEditMode?: boolean | null | undefined
    fnDebounce: (position: { y: number, x: number }, inx: number) => void
}) {
    const refContentImage = useRef<HTMLImageElement | null>(null)
    const currentType = useMemo(() => (type.includes('image') ? 'image' : type.includes('video') ? 'video' : 'file'), [type])

    return <div className='w-full overflow-hidden  relative flex items-center h-full justify-center'>
        {currentType == 'image'
            ? <>
                {
                    isEditMode
                        ? <ImageEditor
                            values={fileSelect || {}}
                            indexElement={indexElement}
                            ref={editorRef}
                            refContentImage={refContentImage}
                            fnDebounce={(position) => fnDebounce(position, indexElement)}
                        />
                        : <div className='md:max-w-[55.5%] max-w-[80.5%] shrink-0 h-full flex items-center justify-center'>
                            <div className={`relative h-full min-w-[40%]   m-auto `} style={{ boxShadow: '0 3px 12px rgba(11,20,26,.16)' }}>
                                <span className='bg-black/60 text-white px-2 p-1 rounded-sm absolute bottom-2 right-2 text-[12px]'>
                                    {formatFileSize(fileSelect?.size || 0)}
                                </span>
                                <img src={realPath} alt={fileSelect?.name || 'Image for upload'} className='w-full h-full object-cover' />
                            </div>
                        </div>
                }

                <div className='md:max-w-[55.5%]  max-w-[80.5%] shrink-0 h-full flex items-center -top-[1000%] opacity-0 pointer-events-none absolute justify-center'>
                    <div className={`relative h-full  min-w-[40%] m-auto `} style={{ boxShadow: '0 3px 12px rgba(11,20,26,.16)' }}>
                        <img src={realPath} ref={(ref) => refContentImage.current = ref} alt={fileSelect?.name || 'Image for upload'} className='w-full h-full object-cover' />
                    </div>
                </div>
            </>
            : currentType == 'video'
                ? <div className='w-[55%] min-h-max h-full flex items-center justify-center'>
                    <video controls className="w-full object-contain" src={fileSelect.path}></video>
                </div>
                : <>
                    <div className='md:w-[30%] w-[70%] text-center h-[100%] gap-2 overflow-hidden relative flex flex-col justify-center items-center'>
                        <FileIcon className='w-[100px] h-[102px]' />
                        <h4 className='md:text-[14px] text-[12px] font-semibold text-slate-600'>No hay vista previa disponible</h4>
                    </div>
                </>
        }
    </div>
}


export default React.memo(ElementBySelectedType)