import { CommentService, ICommentReply, PostService } from '@/actions/training'
import { CardTitle } from '@/components/ui/card'
import { arrayFunctions } from '@/helpers/arrayFunctions'
import useLoadingAndError from '@/hooks/useLoadingAndError'
import usePagination from '@/hooks/usePagination'
import { useToggle } from '@/hooks/useToggle'
import { AccountExecutive } from '@/interfaces/Interface'
import { AvatarUser } from '@/pages/training'
import dayjs from 'dayjs'
import { EyeOff } from 'lucide-react'
import { useEffect, useState } from 'react'
import { AlertDialogDelete } from '../AlertDialogDelete'
import { DivToSetInnerHtml } from '../ui/div-innerhtml'
import SimpleTooltip from '../ui/simple-tooltip'
import { ButtonViewMoreComment } from './CardPostOfCoach'
import { InputComment } from './InputComment'
import { OptionsComments } from './OptionsComments'

dayjs.locale('ES')

export function Comment({
    isAdmin,
    comment,
    postId,
    currentUser,
    handleDeleteComentParent,
    coach,
    handleDeleteReply,
    handleAddNewComment,
    parentComment }: {
        isAdmin: boolean,
        comment: ICommentReply,
        postId: string,
        handleDeleteReply?: () => void
        handleAddNewComment?: (newComment: ICommentReply) => void
        parentComment: string,
        currentUser: any,
        coach: AccountExecutive,
        handleDeleteComentParent?: (value) => void,
    }) {

    const { isLoading: isLoadingViewMore, setIsLoading: setIsLoadingViewMore } = useLoadingAndError()
    const { content, user, visibility, _id, createdAt, updatedAt, replies, reply_to, restOfCommentCount } = comment
    const [isVisibility, setVisibility] = useState(visibility)
    const [isOpenDeleteComment, toggleDelete, setOpenDeleteComment] = useToggle<any>(false)
    const [idsToDiscriminate, setIdsToDiscriminate] = useState<Array<string>>([])
    const [missingComments, setMissingComments] = useState(restOfCommentCount)
    const { handleNextPage, handleSetMaxPage, limitOfPage, page } = usePagination({ initialPage: 0 })
    const [allReplies, setAllReplies] = useState<Array<any>>([])
    const { setIsLoading, isLoading } = useLoadingAndError()
    const [isOpenResponsePost, toggle, setOpenResponsePost] = useToggle<any>(false)

    const isCoach = comment.coach
    const replyToIsCoach = reply_to?.coach

    const handleLoadComment = async () => {
        setIsLoadingViewMore(true)
        try {
            const query: any = { page, limit: 5, idComment: _id }
            if (idsToDiscriminate.length > 0) {
                query.idsToDiscriminate = idsToDiscriminate.join(',')
            }

            const { comments } = await CommentService.getComment(query)
            setMissingComments(lastCount => lastCount - comments.length)
            setAllReplies(lastComments => [...lastComments, ...comments])
        } catch {
        } finally {
            setIsLoadingViewMore(false)
        }
    }

    useEffect(() => {
        handleSetMaxPage(restOfCommentCount, 5)
    }, [restOfCommentCount])

    useEffect(() => {
        if (page > 0) handleLoadComment()
    }, [page])

    useEffect(() => {
        setAllReplies(replies || [])
    }, [replies])

    const handleComment = async (value: any) => {
        setIsLoading(true)
        try {
            const res: any = await PostService.comment({
                id: postId,
                content: value.content,
                parentComment,
                reply_to: isOpenResponsePost._id,
                coach: coach._id,
            });
            const comment = res.comment;

            if (comment) {
                setIdsToDiscriminate(lastIds => [...lastIds, comment._id])

                toggle()
                if (handleAddNewComment) {
                    handleAddNewComment(comment)
                } else {
                    setAllReplies(lastReplies => [...lastReplies, comment])
                    setIdsToDiscriminate(lastIds => [...lastIds, comment._id])

                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    const handleAddNewCommentToParent = (newComment) => {
        setAllReplies(lastComments => [...lastComments, newComment])
        setIdsToDiscriminate(lastIds => [...lastIds, comment._id])
    }

    const handleVisibility = (id) => {
        PostService.visibility({ id, state: !isVisibility })
            .then((res: any) => {
                setVisibility(res.comment.visibility)
            })
            .catch(e => console.log(e))
    }

    const handleDeleteComment = () => {
        if (isOpenDeleteComment) {
            const idOfComment = isOpenDeleteComment._id
            PostService.deleteComment({ id: idOfComment })
                .then((data) => {
                    if (!reply_to && handleDeleteComentParent) {
                        handleDeleteComentParent(data.delete)
                    } else {
                        if (handleDeleteReply) {
                            handleDeleteReply()
                        }
                    }
                })
                .catch(e => console.log(e))
        }
    }

    const handleDeleteReplyOfParent = (inx: number) => {
        setAllReplies(lastComments => arrayFunctions.removeBySplice(lastComments, inx))
    }

    return <>
        <li className={`md:px-4 px-1 py-3  relative `}>
            <OptionsComments
                _id={_id}
                comment={comment}
                currentUser={currentUser}
                handleDeleteComment={(value) => setOpenDeleteComment(value)}
                handleVisibility={handleVisibility}
                isAdmin={isAdmin}
                setOpenResponsePost={() => setOpenResponsePost(comment)}
                user={user}
                visibility={isVisibility} />
            <div>
                <div className={`flex flex-col gap-4 `}>
                    <div className='flex gap-3 relative'>
                        <AvatarUser
                            img={isCoach && coach.photoPath}
                            className='md:w-[30px] md:h-[30px] text-xs w-[25px] h-[25px]'
                            label={isCoach
                                ? `${coach.name[0]}${coach.lastname[0]}`
                                : currentUser._id === user._id ? 'Tu' : `${user.name[0]}${user.lastname[0]}`}>
                        </AvatarUser>
                        <div className='flex flex-col'>
                            <div className='flex sm:gap-3 sm:flex-row flex-col sm:items-center'>
                                <CardTitle style={{ overflowWrap: 'break-word' }} className={`sm:leading-[1.2]  ${reply_to ? 'text-[14px]' : 'text-[16px]'}`}>
                                    {isCoach
                                        ? `${coach.name} ${coach.lastname}`
                                        : currentUser._id === user._id ? 'Tu' : `${user.name}${user.lastname}`}
                                </CardTitle>
                                <span className='flex items-center gap-3'>
                                    <time className='text-[11px] text-slate-500'>
                                        {dayjs(createdAt).format('DD/MM/YYYY - HH:mm')}
                                    </time>
                                    {
                                        !isVisibility && isAdmin && <SimpleTooltip content={'Comentario invisible'}>
                                            <EyeOff className="mr-2 h-3 w-3" />
                                        </SimpleTooltip>
                                    }
                                </span>
                            </div>
                            {
                                reply_to && <div>
                                    <CardTitle className={`leading-[1.2] text-primary text-xs`} style={{ overflowWrap: 'break-word' }}>
                                        {
                                            replyToIsCoach
                                                ? `@${coach.name} ${coach.lastname}`
                                                : `@${reply_to.user.name} ${reply_to.user.lastname}`
                                        }
                                    </CardTitle>
                                </div>
                            }
                            <DivToSetInnerHtml content={content} />
                        </div>
                    </div>
                    {
                        !!isOpenResponsePost && <div className='w-full'>
                            <InputComment
                                coach={coach}
                                isReply={isOpenResponsePost}
                                onClose={toggle}
                                handleComment={handleComment}
                                isLoading={isLoading} />
                        </div>
                    }
                </div>
                {
                    allReplies && allReplies.length > 0 && <div className='py-2 ms:ml-[14px] relative ml-[5px]' style={{ overflowWrap: 'break-word' }}>
                        {allReplies.map((subComment, inx) => (
                            <Comment
                                coach={coach}
                                comment={subComment}
                                postId={postId}
                                handleDeleteReply={() => handleDeleteReplyOfParent(inx)}
                                currentUser={currentUser}
                                handleAddNewComment={handleAddNewCommentToParent}
                                isAdmin={isAdmin}
                                parentComment={parentComment}
                                key={subComment._id}
                            />
                        ))}
                    </div>
                }
                <ButtonViewMoreComment
                    loading={isLoadingViewMore}
                    active={page < limitOfPage}
                    className='text-[12px]'
                    countRestComments={missingComments}
                    handleNextPage={handleNextPage} />
            </div>
        </li>

        {
            isOpenDeleteComment &&
            <AlertDialogDelete
                description='¿Estás seguro? Esta acción es irreversible y no se puede deshacer.'
                loading={false}
                onClose={() => setOpenDeleteComment(false)}
                open={!!isOpenDeleteComment}
                title={`Eliminar comentario`}
                handleSubmit={() => handleDeleteComment()}
            />
        }
    </>
}
