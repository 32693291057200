import { Button } from "@/components/ui/button";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Box } from "@mui/material";
import { ChevronDown } from "lucide-react";

interface FloatingButtonsProps {
    bajarChat: () => void;
    isVisible: boolean;
    nextContactVisible: boolean;
    nextConctact?: () => void;
}

const FloatingButtons = ({ bajarChat, isVisible, nextContactVisible, nextConctact }: FloatingButtonsProps) => {
    const [refAuto] = useAutoAnimate()

    return (
        <div
            className="flex pr-5  gap-2 items-center mb-4 z-[5]"
            ref={refAuto}
        >
            <div>
                <Button size={'icon'} variant={'ghost'} className={`sm:w-[45px] w-[40px] h-[40px] transition-opacity  ease-linear sm:h-[45px] bg-white hover:bg-[#fafafa] rounded-[10px] ${!isVisible ? 'opacity-100' : 'opacity-0'}`}
                    style={{ boxShadow: '0px 6px 24px rgba(0 0 0 / 8%)' }}
                    onClick={bajarChat}>
                    <ChevronDown size={18} />
                </Button>
            </div>
            {nextContactVisible &&
                <Box>
                    <Button
                        className="ease-linear"
                        variant="default"
                        onClick={nextConctact}>
                        <KeyboardArrowRight />
                        Siguiente contacto
                    </Button>
                </Box>
            }
        </div>
    )
}

export default FloatingButtons