import { getFileWithBase64 } from '@/helpers/getFileWithBase64'
import { setLoadingUploadFile } from '@/redux/slices/crm'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import useLoadingAndError from './useLoadingAndError'

export interface IFileToReturn extends File {
    path: string
}

interface UseUploadFile {
    maxSize?: number,
    addFile?: (file: IFileToReturn) => Record<string, any>, accept?: string | Array<string>,
    maxFiles?: number,
    multiple?: boolean,
    onChangeValue?: (value: IFileToReturn[]) => void
}

//New hook more flexible... 
export default function useUploadFile({
    maxSize,
    addFile,
    accept = '',
    maxFiles = 50,
    multiple = true,
    onChangeValue
}: UseUploadFile = { maxSize: 999999 }) {
    const [files, setFiles] = React.useState<{ result: Array<any>, filesRemoves: number } | null>()
    const { isLoading, isError, setIsLoading } = useLoadingAndError()

    const dispatch = useDispatch()

    const handleLoadingGlobal = (vl: boolean) => dispatch(setLoadingUploadFile(vl))

    const getFileBase64 = async (acceptedFile): Promise<any> => await getFileWithBase64({ acceptedFile, fnForAddField: addFile, maxSize })

    const onDrop = useCallback(async (acceptedFile) => {
        if (acceptedFile.length == 0) return ''
        setIsLoading(true)
        handleLoadingGlobal(true)

        const dataTest = await getFileWithBase64({ acceptedFile, fnForAddField: addFile, maxSize })
        setIsLoading(false)

        handleLoadingGlobal(false)
        onChangeValue && onChangeValue(dataTest?.result || [])
        setFiles(dataTest)
    }, [onChangeValue])

    const dropZone = useDropzone({ onDrop, accept, maxFiles, multiple })
    return { ...dropZone, ...files, isLoading, getFileBase64 }
}
