import React, { useEffect, useState } from 'react'
import { Button } from "@/components/ui/button";
import { ChevronsUpDown } from "lucide-react";
import { cn } from '@/lib/utils';
import { Check } from 'lucide-react';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"

export default function AdditionalDataAutoComplete({ options, handleChange, label, defaultValue }) {
    const [open, setOpen] = useState(false)

    return <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
            <Button
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className="w-full justify-between"
            >
                {defaultValue
                    ? defaultValue.length > 33 ? defaultValue.slice(0, 33) + "..." : defaultValue
                    : label}
                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[320px] p-0">
            <Command>
                <CommandInput placeholder={`Busta por: ${label}`} />
                <CommandEmpty>No se encontro nada en la busqueda.</CommandEmpty>
                <CommandGroup className='py-0'>
                    {
                        options && options.map(option => <CommandItem key={option.place_id} onSelect={(value) => {
                            handleChange(value)
                            setOpen(false)
                        }}>
                            <div className='flex items-center'>
                                <Check
                                    className={cn(
                                        "mr-2 h-4 w-4",
                                        defaultValue === option ? "opacity-100" : "opacity-0"
                                    )}
                                />
                                <div>
                                    <span>{option}</span>
                                </div>
                            </div>
                        </CommandItem>)
                    }
                </CommandGroup>
            </Command>
        </PopoverContent>
    </Popover>
}
