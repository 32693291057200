import { ArchiveRestore } from "lucide-react"
import moment from "moment"
import { DividerProspectAction } from "./TransferBubble"
import { useMemo } from "react"
import { isAuth } from "@/actions/auth"

moment.locale('es')

interface ContactTakenBubble {
    date: Date,
    agent?: string | {
        _id: string,
        name: string,
        lastname: string,
        email: string
    }
}

const ContactTakenBubble = ({ date, agent }: ContactTakenBubble) => {

    const isAgent = typeof agent === 'string' ? agent : agent?._id

    const isMe = useMemo(() => {
        return !isAgent || isAgent === isAuth()._id
    }, [isAgent])

    return  <DividerProspectAction date={date} description={isMe ? "" : `Realizado por ${agent.name} ${agent.lastname}`}>
         <ArchiveRestore size={22}/> <p>Contacto tomado</p>
    </DividerProspectAction>
}

export default ContactTakenBubble