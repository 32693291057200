import { IFileToReturn } from "@/hooks/useUploadFile";
import { MEGABYTE_ACCEPTED } from "@/hooks/useUploadImage";
import { getBase64 } from ".";

export interface IFile {
    path: string,
    type: string,
    name: string,
    size: number
}

export function getFileWithBase64({
    acceptedFile,
    maxSize = MEGABYTE_ACCEPTED,
    fnForAddField = null
}: {
    acceptedFile: File[],
    maxSize?: number,
    fnForAddField?: ((file: IFileToReturn) => Record<string, any>) | null
}): Promise<{ result: Array<any>, filesRemoves: number }> | null {

    if (acceptedFile.length == 0) return null
    let filesRemoves = 0

    const promises = acceptedFile.map(file => {
        return new Promise((resolve, reject) => {
            if (file.size > maxSize) {
                filesRemoves++
                resolve(null);
            } else {
                getBase64(file)
                    .then(async (path) => {
                        const { name, size, type } = file || {}
                        let fileConstructor: any = { path: path, type, name, size };

                        if (fnForAddField) {
                            const result = await fnForAddField(fileConstructor);
                            fileConstructor = { ...fileConstructor, ...result };
                        }

                        resolve(fileConstructor);
                    })
                    .catch(() => reject(null))
            }
        });
    });

    return Promise.all(promises)
        .then(dataArray => {
            const result = dataArray.filter(data => data !== null);
            return { result, filesRemoves };
        })
        .catch(error => {
            console.error(error);
            return { result: [], filesRemoves };
        });
}

export const handleFormatFile = async (allFiles: Array<any>, refs) => {
    const files: any = [];

    await Promise.all(
        allFiles.map(async (file, index) => {

            if (file.type.includes('image')) {
                const canvas = refs.current[index]?.ref?.getImageScaledToCanvas();
                if (canvas) {
                    return new Promise((resolve, reject) => {
                        canvas.toBlob((blob) => {
                            getBase64(blob).then(res => {
                                files.push({ ...file, path: res });
                                resolve(blob);
                            }).catch(() => reject(null))
                        });
                    });
                }
            } else files.push(file)
        })
    );

    return files
}