import InputWithSelect from "@/components/ui/input-with-select";
import { searchRegex } from "@/helpers/regex";
import { useMemo, useState } from "react";

interface MultiselectFieldProps {
    onChangeValue: (value: string[]) => void,
    value: string[],
    options: string[],
    disabled?: boolean
}

export default function MultiselectField({
    onChangeValue,
    value = [],
    options,
    disabled
}: MultiselectFieldProps) {
    const [searchValue, setSearchValue] = useState<string>('');

    const formatOption = (op: string) => ({ value: op, label: op })

    const optionsUse = useMemo(() => {
        return options.filter(op => searchRegex(searchValue).test(op)).map(formatOption)
    }, [searchValue])

    if (options.length == 0) return null;

    return (
        <InputWithSelect
            multiple
            strict={true}
            options={optionsUse}
            onChangeValue={onChangeValue}
            disabled={disabled}
            value={value.map(formatOption)}
            placeholder="Seleccionar una opción"
            onChangeInputSearch={(value) => setSearchValue(value)}
            badgeClassName="hover:bg-blue-50/90 hover:text-blue-700 bg-blue-50 text-blue-800"
        />
    )
}