import { CloneContactsFormSchemaType } from "@/components/crm/CreateSubConversationDialog"
import { TransferContactsSchemaType } from "@/components/reports/contacts/TransferContactsModal/utils/types"
import { fetcher } from "@/helpers/fetcher"
import { formatSearchParams } from "@/helpers/formatSearchParams"
import { Receipt } from "@/interfaces/Interface"

export const getContactsList = (params: Record<string, any>) => {
    let queryParams: any = formatSearchParams(params, { deniedValue: ['all'] })
    return fetcher<{ contacts: any[], countDocuments: number }>({
        url: `/contacts?${queryParams}`,
        defaultContentType: true
    })
}

export const createAdvancedTransfer = async (body: TransferContactsSchemaType) => {
    return fetcher({
        url: '/contacts/actions/advancedTransfer',
        defaultContentType: true,
        method: 'POST',
        body
    })
}

export const createAdvancedClone = async (body: CloneContactsFormSchemaType) => {
    return fetcher({
        url: '/contacts/actions/advancedClone',
        defaultContentType: true,
        method: 'POST',
        body
    })
}

export const getSalesOfContact = (contactId: string) => {
    return fetcher<{ receipts: Receipt[] }>({ url: `/crm/contact/${contactId}/sales` })
}