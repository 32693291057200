import { getBase64 } from "@/helpers";
import { z } from "zod"

export const defaultValueForEditor = {
    allowZoomOut: true,
    caption: '',
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    disableHiDPIScaling: true,
    showGrid: false,
    rotateKey: 0
}

export const zContextSchema = z.object({
    multimedia: z.array(z.object({
        caption: z.string().optional(),
        path: z.any(),
        type: z.string(),
        size: z.number(),
        name: z.string().optional()
    })),
    noteBody: z.string().optional(),
})

export const ROTATE_OPTIONS = {
    '0': 0,
    '1': 90,
    '2': 180,
    '3': 270,
}

export enum ZOOM_VALUES {
    MAX_ZOOM = 7,
    MIN_ZOOM = 1,
    ZOOM_VALUE = 0.5
}

export const handleChangeFile = async (value, refEdit) => {
    const canvas = refEdit?.current?.getImageScaledToCanvas();

    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                reject(new Error("No se pudo obtener el blob."));
                return;
            }

            getBase64(blob)
                .then(result => {
                    const updatedValue = { ...value, path: result };
                    resolve(updatedValue);
                })
                .catch(error => {
                    reject(error);
                });
        });
    });
};