import { fetcher } from '../helpers/fetcher';

interface FileInfo {
    url: string;
    name: string;
    size: string;
    mimeType: string;
}

const downloadMultipleFiles = (filesInfo) => {
    filesInfo.forEach((file: FileInfo) => {
        fetcher<Blob>({
            url: file.url,
            format: 'blob',
            method: 'GET',
            isExternalURL: true,
            withToken: false
        })
            .then(fileBlob => {
                if ((navigator as any).msSaveBlob) {
                    (navigator as any).msSaveBlob(fileBlob, file.name);
                } else {
                    const url = window.URL.createObjectURL(fileBlob);
                    const link = document.createElement('a');

                    if ('download' in link) {
                        link.href = url;
                        link.download = file.name;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        window.location.href = url;
                    }
                    window.URL.revokeObjectURL(url);
                }
            })
            .catch(e => console.log(e))
    }
    )
};

export const downloadFile = (
    blob: Blob,
    name: string
) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(url);
}

export default downloadMultipleFiles;
