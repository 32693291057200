import { CommisionSections, CommisionSectionsType, UseFormReturnComission } from '..';
import General from './General';
import SplitDeal from './SplitDeal';

export interface SectionPropsBase {
    contextForm: UseFormReturnComission,
}

interface SectionsProps extends SectionPropsBase {
    sectionSelected: CommisionSectionsType
}

export default function Sections({
    contextForm,
    sectionSelected,
}: SectionsProps) {
    const defaultValues = { contextForm }

    switch (sectionSelected) {
        case CommisionSections.General: return <General {...defaultValues} />
        case CommisionSections.SplitDeal: return <SplitDeal  {...defaultValues} />
        default: return null
    }
}
