"use client";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  FormControl
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { es } from 'date-fns/locale';
import { Calendar as CalendarIcon } from "lucide-react";

import dayjs from "dayjs";
import { useState } from "react";
import { TimePicker } from "./time-picker";

interface DateTimePickerFormProps {
  value?: Date | null | undefined,
  onChangeValue?: (date: Date) => void,
  placeholder?: string,
  condition?: (date: Date) => boolean
  disabled?: boolean
}

export function DateTimePickerForm({
  value,
  onChangeValue,
  disabled,
  placeholder = 'Seleccionar fecha',
  condition
}: DateTimePickerFormProps) {
  const [date, setDate] = useState<null | Date>(null)

  const dateValue = value
  const onChangeToUse = onChangeValue || setDate

  const onHandleChangeDate = (date) => {
    if (!date) return;

    let dateToSet = date;
    const currentDate = dayjs().toDate();
    const lastDate = value || currentDate

    const setLastValues = (dateToSetLastValue: Date) => {
      return dayjs(dateToSetLastValue)
        .hour(lastDate.getHours())
        .minute(lastDate.getMinutes())
        .second(lastDate.getSeconds())
        .toDate();
    }

    if (date.getDate() !== lastDate.getDate()) dateToSet = setLastValues(date)
    if (condition && condition(dateToSet)) dateToSet = setLastValues(currentDate)

    onChangeToUse(dateToSet)
  }

  return (
    <Popover>
      <FormControl>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            disabled={disabled}
            className={cn(
              `w-full justify-start h-10 text-left font-normal ${disabled ? 'cursor-not-allowed' : ''}`,
              !dateValue && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateValue ? (
              format(dateValue, "PPP HH:mm", { locale: es })
            ) : (
              <span>{placeholder}</span>
            )}
          </Button>
        </PopoverTrigger>
      </FormControl>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={dateValue as Date}
          onSelect={onHandleChangeDate}
          initialFocus
        />
        <div className="p-3 border-t relative border-border">
          <TimePicker
            setDate={onHandleChangeDate}
            hidden={{
              seconds: true
            }}
            date={dateValue as Date}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}