

import { Button } from '@/components/ui/button';
import { SaleInteraction } from '@/interfaces/crm/contact';
import { Routes } from '@/routes';
import { useRouter } from 'next/router';
import SaleBubbleLayout from './SaleBubbleLayout';

interface StateChangeViewProps {
    interaction: SaleInteraction,
}

export default function StateChangeView(props: StateChangeViewProps) {
    const { interaction } = props;
    const sale = interaction?.content?.sale || {};
    const router = useRouter();

    const redirectToSale = () => {
        return router.push(Routes.SALE_REPORT + `?id=${sale._id}`)
    };

    return (
        <SaleBubbleLayout
            createdAt={interaction.createdAt}
            classNameHeaderContent='bg-yellow-100 text-yellow-600'
            title='Cambio de estado de venta'
            classNameContent="max-w-[440px]"
        >
            <div>
                <h3 className='text-[16px] font-medium mb-2 text-gray-700'>
                    {`Estado actualizado: ${interaction?.content?.status}`}
                </h3>
                <Button
                    variant={'link'}
                    onClick={redirectToSale}
                    className="mt-1 px-0 h-max"
                >
                    Ver venta
                </Button>
            </div>
        </SaleBubbleLayout>
    )
}
