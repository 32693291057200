import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { ReactNode } from "react";
import { proximityDescriptions } from "./utils/constants";

interface ProximityItemProps {
    proximityData: {
        color: string,
        label: string,
        icon: ReactNode
    }
}

export default function ProximityItem({ proximityData }: ProximityItemProps) {
    return (
        <HoverCard>
            <HoverCardTrigger asChild>
                <button className="text-[13px] w-max text-start hover:opacity-80 duration-100 flex gap-1.5 items-center">
                    <span
                        className="flex items-center gap-2"
                        style={{ color: proximityData.color }}
                    >
                        {proximityData.label}
                        {proximityData.icon}
                    </span>
                </button>
            </HoverCardTrigger>
            <HoverCardContent className="w-full p-4 max-h-[300px] modifyScroll overflow-y-auto max-w-[350px]">
                <Card className="p-0 border-none">
                    <CardHeader className="p-0">
                        <CardTitle className="text-gray-700">
                            Cercanía
                        </CardTitle>
                        <CardDescription className="text-[13px] text-gray-600">
                            La cercanía con un contacto se calcula en función de la frecuencia y naturaleza de las interacciones. Las comunicaciones iniciadas por el contacto tendrán mayor peso que las iniciadas por el agente. Además, las interacciones directas, como reuniones o llamadas, serán las más influyentes en este cálculo. Otro factor clave es la antigüedad de estas interacciones, ya que las más recientes tendrán un impacto más significativo en la evaluación de la cercanía.
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="mt-2 border-none p-0">
                        <ul className="grid gap-2">
                            {
                                proximityDescriptions.map(({ label, color, description, icon }, inx) => {
                                    return (
                                        <li key={inx}>
                                            <div className="flex items-center">
                                                <p className="text-[13px] text-gray-600">
                                                    <span style={{ color }} className="inline items-center font-medium mr-2">
                                                        <span className="inline [&>svg]:inline mr-1">
                                                            {icon}
                                                        </span>
                                                        {label}:
                                                    </span>
                                                    {description}
                                                </p>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </CardContent>
                </Card>
            </HoverCardContent>
        </HoverCard>
    )
};