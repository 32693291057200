import { Button } from "../button"
import { motion } from 'framer-motion'
import { Card, CardContent, CardDescription, CardTitle } from "../card"
import { Plus } from "lucide-react"


interface IEmptyMessageProps {
    message: string,
    icon: React.ReactElement,
    buttonText?: string | JSX.Element,
    buttonAction?: () => void,
    description?: string
    extraButton?: boolean,
    extraButtonText?: string | JSX.Element,
    extraButtonAction?: () => void
}


const EmptyMessage = ({ message, icon, buttonText, buttonAction, description, extraButton, extraButtonAction, extraButtonText }: IEmptyMessageProps) => {
    return (
        <Card className="shadow-none ">
            <CardContent className="flex h-full gap-3.5 pb-0 py-5 flex-col justify-center items-center ">
                <motion.div
                    initial={{ y: "30px", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", duration: 0.4, delay: 0 }}
                >
                    {icon}
                </motion.div>
                <motion.div
                    initial={{ y: "30px", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", duration: 0.4, delay: 0.15 }}
                    className="text-center text-gray-700 text-[15px] flex flex-col items-center gap-2"
                >
                    <CardTitle>
                        {message}
                    </CardTitle>

                    {description &&
                        <CardDescription>
                            {description}
                        </CardDescription>
                    }

                </motion.div>
                <div className="flex flex-row gap-2">
                    {
                        buttonText && (
                            <motion.div
                                initial={{ y: "30px", opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ type: "spring", duration: 0.4, delay: 0.3 }}
                            >
                                <Button variant="outline-primary" className="h-[34px] px-4 mt-2 text-[13px] flex gap-3 items-center" onClick={buttonAction}>
                                    <Plus size={16} />
                                    {buttonText}
                                </Button>
                            </motion.div>
                        )
                    }
                    {
                        extraButton && (
                            <motion.div
                                initial={{ y: "30px", opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ type: "spring", duration: 0.4, delay: 0.3 }}
                            >
                                <Button variant="outline-primary" className="h-[34px] px-4 mt-2 text-[13px] flex gap-3 items-center" onClick={extraButtonAction}>
                                    <Plus size={16} />
                                    {extraButtonText}
                                </Button>
                            </motion.div>
                        )
                    }
                </div>

            </CardContent>
        </Card>
    )
}


export default EmptyMessage