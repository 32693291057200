
import SimpleTooltip from "./simple-tooltip";

type TAction = {
    label: string,
    iconOrKey: string | JSX.Element,
    combinations?: Array<string>,
    action?: null | (() => void)
}

interface ToolTipAndActionProps {
    children: JSX.Element | JSX.Element[],
    alignOffset?: number,
    className?: string
    actions: TAction[]
}

export function ToolTipAndAction({ children, actions = [], alignOffset, className }: ToolTipAndActionProps) {
    return <SimpleTooltip
        delayDuration={0.4}
        className={className}
        alignOffset={alignOffset}
        sideOffset={-10}
        content={
            <div className="flex flex-col gap-1.5">
                {
                    actions.map((op) => <ActionDescription {...op} key={op.label} />)
                }
            </div>
        } >
        {children}
    </SimpleTooltip>
}

export function ActionDescription({ iconOrKey, label }: TAction) {
    return <p className="text-sm text-muted-foreground">
        {label}{" "}
        <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
            {iconOrKey}
        </kbd>
    </p>
}