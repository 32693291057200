export const ErrorsCodeTransfer = {
    InvalidData: 'ERROR_INVALID_DATA',
    GroupNotFound: 'ERROR_GROUP_NOT_FOUND',
    DoesNotBelongToTheGroup: 'ERROR_NOT_AUTHORIZED_GROUP',
    YourselfTransfer: 'ERROR_YOURSELF_TRANSFER',
    AlreadyBelongsToAgent: 'ERROR_ALREADY_BELONGS_TO_AGENT',
    UserProxy: 'ERROR_USER_PROXY'
} as const

export const InternalServerError = 'INTERNAL_SERVER_ERROR'

export const ErrorsCodeBot = {
    BotNotActive: 'BOT_NOT_ACTIVE',
    BotConfigNotFound: 'BOT_CONFIG_NOT_FOUND'
} as const

