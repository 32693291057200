import { getCompany } from "@/actions/auth";
import { updateSaleFields } from "@/actions/comercial/sale";
import { toastActionError } from "@/components/comercial/comercialManagment/comissions/CommisionModal";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import useGetUsersList from "@/hooks/useGetUsersList";
import { AdditionalSaleField, ModalPropsBase, Receipt, ReceiptUser } from "@/interfaces/Interface";
import { useMutation } from '@tanstack/react-query';
import dayjs from "dayjs";
import _ from "lodash";
import { ReactNode, useEffect, useState } from "react";
import DateSelect from "./DateSelect";
import InformationItem from "./InformationItem";
import ProductLine from "./ProductLine";
import SectionTitle from "./SectionTitle";
import { ChangeSaleStatus, StatusSelect } from "./StatusSelect";
import UserSelect from "./UserSelect";

interface SaleDetailModalProps extends ModalPropsBase {
    sale: Receipt;
    editMode?: boolean,
    onSuccessChangeProperty?: (receipt: Receipt) => void
}

export default function SaleDetailModal({
    onOpenChange,
    open,
    sale,
    editMode = true,
    onSuccessChangeProperty
}: SaleDetailModalProps) {
    const [receiptData, setReceiptData] = useState<Receipt>(sale || {});
    const { users } = useGetUsersList({ enabled: true, roles: ['user'], saveOnGlobalState: false });

    const {
        ticket_id,
        ticket_sale_id,
        type,
        date_sale,
        date,
        product,
        value,
        status,
        productImage,
        user_string,
        user_code,
        name,
        client_id,
        phone,
        saleStructureType = "simple",
        productLines = [],
        user,
        user_lock = false
    } = receiptData || {};

    const isMultipleProduct = saleStructureType === "multiline";
    const additionalSaleFields: AdditionalSaleField[] = _.get(getCompany(), 'additionalSaleFields', []).filter((filter) => filter.active) as AdditionalSaleField[];

    useEffect(() => {
        if (sale) setReceiptData(sale);
    }, [open, sale]);

    const { mutate } = useMutation({
        mutationFn: (fields: {
            status?: string,
            statusTitle?: string,
            user?: string,
            date_sale?: string
        }) => updateSaleFields(sale._id, fields),
        onError: toastActionError,
        onSuccess: ({ updatedProperties = {} }) => {
            const { user, ...rest } = updatedProperties || {};

            const newSaleValue = ({ ...receiptData, ...(rest || {}) } as Receipt)
            setReceiptData(newSaleValue);
            onSuccessChangeProperty && onSuccessChangeProperty(newSaleValue)
        }
    });

    const handleChangeUser = (user: ReceiptUser) => {
        mutate({ user: user._id });
        setReceiptData(lastValue => ({ ...lastValue, user }));
    };

    const handleChangeDate = (value: Date) => {
        mutate({ date_sale: value.toISOString() });
        setReceiptData(lastValue => ({ ...lastValue, date_sale: value }));
    };

    const handleChangeStatus = ({ status, title }: ChangeSaleStatus) => {
        mutate({ status: status || 'new', statusTitle: title });
        setReceiptData(lastValue => ({ ...lastValue, status: title }));
    };

    const formatDate = (date: string | Date | undefined) =>
        date ? dayjs(date).format("DD/MM/YYYY HH:mm") : "-";

    const renderInformationItems = (items: {
        title: string;
        value?: string,
        valueElement?: ReactNode,
        classNameContainer?: string,
        hidden?: boolean
    }[]) =>
        items.map(({ title, value, valueElement, classNameContainer, hidden = false }, index) => {
            if (hidden) return null;

            return (
                <InformationItem
                    key={index}
                    title={title}
                    value={value}
                    customValueElement={valueElement}
                    classNameContainer={classNameContainer}
                />
            )
        });

    const saleSummaryItems = [
        { title: "ID:", value: ticket_id || ticket_sale_id },
        { title: "Fecha:", value: date && formatDate(date) },
        {
            title: "Fecha de venta:",
            valueElement: (editMode || (!editMode && date_sale)) && <DateSelect
                onChangeValue={handleChangeDate}
                editMode={editMode}
                value={date_sale}
            />
        },
        { title: "Producto:", value: product, hidden: isMultipleProduct },
        { title: "Precio:", value: value ? String(value) : "-", hidden: isMultipleProduct },
        {
            title: 'Imagen del producto:',
            classNameContainer: 'items-start',
            valueElement: productImage && <div className="w-[100px] h-[60px] overflow-hidden rounded-sm relative">
                <img className="w-full h-full object-cover" src={productImage} />
            </div>,
            hidden: isMultipleProduct
        }
    ];

    const moreSaleSummaryItems = [
        { title: "Tipo:", value: type },
        {
            title: "Estado:",
            valueElement: (editMode || (!editMode && status)) && <StatusSelect
                onChangeValue={handleChangeStatus}
                editMode={editMode}
                value={status}
            />
        },
        {
            title: "Vendedor boleta:",
            value: Array.from(new Set([user_code, user_string]))
                .filter(Boolean)
                .join(" "),
        },
        {
            title: 'Vendedor:',
            valueElement: (editMode || (!editMode && user)) && <UserSelect
                editMode={editMode}
                onChangeValue={handleChangeUser}
                disabled={!!user_lock}
                value={user}
                users={users}
            />
        },
        ...additionalSaleFields.map((additionalData: AdditionalSaleField) => {
            let value = receiptData?.additionalData?.[additionalData.code] || '';
            if (value && additionalData.type == 'multiselect') value = [value].flat().join(', ');

            return {
                title: additionalData.name,
                value
            }
        })
    ];

    const clientSummeryItems = [
        { title: "Teléfono:", value: phone },
        { title: "Nombre:", value: name },
        { title: "ID de cliente:", value: client_id },
    ];

    return (
        <Dialog onOpenChange={onOpenChange} open={open}>
            <DialogContent className="sm:max-w-[900px]">
                <DialogHeader>
                    <DialogTitle>
                        Detalles de {ticket_id || ticket_sale_id}
                    </DialogTitle>
                </DialogHeader>
                <div className="w-full max-h-[490px] h-full py-2 pt-0 overflow-y-auto">

                    <div className="w-full mb-6">
                        <SectionTitle className="mb-2">Resumen de la venta</SectionTitle>
                        <div className="w-full grid md:grid-cols-2 gap-2 md:gap-6">
                            <ul className="flex flex-col w-full gap-1.5">
                                {renderInformationItems(saleSummaryItems)}
                            </ul>
                            <ul className="flex flex-col w-full gap-1.5">
                                {renderInformationItems(moreSaleSummaryItems)}
                            </ul>
                        </div>
                    </div>

                    <div className={`grid grid-cols-1 md:grid-cols-2 gap-6`}>
                        <div>
                            <SectionTitle className="mb-2">
                                Resumen del cliente
                            </SectionTitle>
                            <ul className="flex flex-col w-full gap-1.5">
                                {renderInformationItems(clientSummeryItems)}
                            </ul>
                        </div>
                        <div>
                            {
                                isMultipleProduct && (
                                    <>
                                        <SectionTitle className="mb-2">Productos</SectionTitle>
                                        <ul className="flex flex-col w-full gap-2">
                                            {
                                                productLines.map((product, index) => {
                                                    return (
                                                        <ProductLine
                                                            isLastElement={index == productLines.length - 1}
                                                            product={product}
                                                            index={index}
                                                            key={index}
                                                        />
                                                    )
                                                })
                                            }
                                        </ul>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}