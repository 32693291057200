import { CustomFormField } from "@/components/ui/custom-form-field";
import { Textarea } from "@/components/ui/textarea";
import { SectionPropsBase } from "../AddMeetingModal";
import { SelectMeetingType } from "../select-meeting-type";

interface NowReminderConfigProps extends SectionPropsBase { }

export default function NowReminderConfig({
    contextForm
}: NowReminderConfigProps) {
    return <div className="flex flex-col gap-6">
        <SelectMeetingType contextForm={contextForm} />
        <CustomFormField
            name='comment'
            label='Comentario'
            control={contextForm.control}
            fnElement={({ field }) => (
                <Textarea
                    {...field}
                    placeholder='Ingresa un comentario o nota que quieras dejar al cliente'
                    className='h-[100px]'
                    autoComplete='off'
                />
            )}
        />
    </div>
}
