import { X } from 'lucide-react';
import { FileIcon } from '../Bubble/Icons/Crm';

export function ItemMultimedia({ handleDelete, handleSelectFile, isSelect, file }) {
    return <li className='relative group'>
        <button
            type='button'
            onClick={handleDelete}
            className='absolute opacity-0 z-[2] group-hover:opacity-100 duration-100 text-white top-1 right-1'>
            <X size={16} />
        </button>
        <button
            type='button'
            onClick={handleSelectFile}
            className={`text-lg border-2 ${isSelect ? 'border-primary ' : 'border-transparent'} rounded-sm relative overflow-hidden w-[55px] h-[55px] flex justify-center items-center`}>
            {
                !file ? <></> : file?.type?.includes('image')
                    ? <>
                        <img
                            className='w-full h-full object-cover'
                            src={file.path}
                            alt="" />
                    </>
                    : file.type.includes('video')
                        ? <video src={file.path} className='w-full h-full object-cover'></video>
                        : <FileIcon className='w-[24px] h-[34px]' />
            }
            <span
                className='absolute opacity-0  group-hover:opacity-100 duration-100 ease-out right-0 top-0 w-full h-[40px]'
                style={{
                    background: 'linear-gradient(10deg,transparent,transparent 45%,rgba(11,20,26,.18) 75%,rgba(11,20,26,.4))'
                }}
            >
            </span>
        </button>
    </li>
}