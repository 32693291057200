import CreateReminders from '@/components/settings/meetings/CreateReminder'
import { CustomFormField } from '@/components/ui/custom-form-field'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { DateTimePickerForm } from '@/components/ui/Time-picker/popover-date-time-picker'
import dayjs from 'dayjs'
import { SectionPropsBase } from '../AddMeetingModal'
import { SelectMeetingType } from '../select-meeting-type'

interface ReminderMeetingProps extends SectionPropsBase { }

export default function ReminderMeeting({ contextForm }: ReminderMeetingProps) {

    const onHandleChangeDate = (date) => {
        if (!date) return;
        contextForm.clearErrors('schedule')

        let dateToSet = date;
        const currentDate = dayjs().startOf('day').toDate();
        const lastDate = contextForm.getValues('schedule') || currentDate

        const setLastValues = (dateToSetLastValue: Date) => {
            return dayjs(dateToSetLastValue)
                .hour(lastDate.getHours())
                .minute(lastDate.getMinutes())
                .second(lastDate.getSeconds())
                .toDate();
        }

        if (date.getDate() !== lastDate.getDate()) dateToSet = setLastValues(date)
        if (dateToSet < currentDate) dateToSet = setLastValues(currentDate)

        contextForm.setValue('schedule', dateToSet);
    }

    return (
        <div className='flex flex-col gap-6'>
            <SelectMeetingType contextForm={contextForm} />
            <CustomFormField
                name='summary'
                label='Asunto de la reunión'
                isRequired={true}
                control={contextForm.control}
                fnElement={({ field }) => (
                    <Input
                        placeholder='Ej: Ver asuntos pendientes'
                        autoComplete='off'
                        {...field}
                    />
                )}
            />
            <CustomFormField
                name='schedule'
                label='Fecha y hora'
                isRequired={true}
                control={contextForm.control}
                fnElement={({ field }) => (
                    <DateTimePickerForm
                        value={field.value || dayjs().toDate()}
                        onChangeValue={onHandleChangeDate}
                    />
                )}
            />
            <CustomFormField
                name='comment'
                label='Comentario'
                control={contextForm.control}
                fnElement={({ field }) => (
                    <Textarea
                        {...field}
                        placeholder='Ingresa un comentario o nota que quieras dejar al cliente'
                        className='h-[100px]'
                        autoComplete='off'
                    />
                )}
            />
            <CreateReminders contextForm={contextForm} />
        </div>
    )
}

