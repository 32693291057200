import React from 'react'
import { CardTitle } from '../ui/card'
import { FaCheckCircle } from "react-icons/fa";

export interface CardSuccessFullProps {
    title?: string,
    description?: string
}

export default function CardSuccessful({
    title,
    description
}: CardSuccessFullProps) {
    return <div className="flex gap-2 items-start">
        <FaCheckCircle className="w-[30px] shrink-0 h-[30px] relative text-green-500" />
        <div className="flex flex-col">
            <CardTitle className="text-[15px]">
                {title}
            </CardTitle>
            <p className="text-[15px] text-gray-600">
                {description}
            </p>
        </div>
    </div>
}
