import { Button } from "@/components/ui/button"
import { Phone, PhoneOff, Voicemail } from "lucide-react"
import moment from "moment"
import { useDispatch } from "react-redux"
import { createInteraction } from "../Prospect/libs/helpers/interaction"

moment.locale('es')

export type callState = 'calling' | 'done' | 'cancel' | 'voicemail'

interface PhoneResponse {
    phone?: string,
    id: string,
    interactionid: string,
    onCall: (state: callState) => void,
    fromBackOffice?: boolean
}

const PhoneResponse = ({ id, interactionid, phone, onCall, fromBackOffice }: PhoneResponse) => {
    const dispatch = useDispatch()

    const handleClick = (state: callState) => () => {
        createInteraction({
            dispatch,
            type: 'closeCallPhone',
            data: {
                state,
                interactionid,
                id,
                fromBackOffice,
                onSuccess: () => onCall(state),
            }
        })
    }

    return (
        <div className="flex flex-col w-full px-4 md:px-6 py-3 h-full">
            <p className="text-[14px] font-medium">Llamada a {phone}. ¿Pudiste comunicarte?</p>
            <div className="flex w-full flex-grow items-center justify-center gap-x-4 md:gap-x-6">
                <CallStatusButton
                    icon={<PhoneOff size={19} />}
                    label={'Sin responder'}
                    onClick={handleClick('cancel')}
                    color={'#fe4344'}
                />
                <CallStatusButton
                    icon={<Voicemail size={19} />}
                    label={'Correo de voz'}
                    onClick={handleClick('voicemail')}
                    color={'#f0ab03'}
                />
                <CallStatusButton
                    icon={<Phone size={19} />}
                    label={'Contesto'}
                    onClick={handleClick('done')}
                    color={'#5eb75b'}
                />
            </div>
        </div>
    )
}


export function CallStatusButton({
    color,
    icon,
    label,
    onClick
}) {

    return <Button onClick={onClick} variant={'ghost'} className="flex h-auto py-1 items-center text-slate-500 font-normal text-[14px] gap-2">
        <span
            style={{
                backgroundColor: color
            }}
            className={"w-[34px] h-[34px]  [&>svg]:shrink-0 text-white rounded-full flex items-center justify-center"}>
            {icon}
        </span>
        <span>{label}</span>
    </Button>
}

PhoneResponse.defaultProps = {
    onCall: () => { }
}

export default PhoneResponse