import { MeetingTabs, MeetingTabsType, SectionPropsBase } from "../AddMeetingModal"
import NowReminderConfig from "./NowReminderConfig"
import ReminderMeeting from "./ReminderMeeting"

interface SectionsProps extends SectionPropsBase {
    tabSelected: MeetingTabsType
}

export default function Sections({
    tabSelected,
    contextForm
}: SectionsProps) {
    const props = { contextForm }
    if (tabSelected === MeetingTabs.Now) return <NowReminderConfig {...props} />
    return <ReminderMeeting {...props} />
}
