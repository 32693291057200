import { Badge } from "../../ui/badge";
import BadgePill from "./BadgePill";

interface Props {
    prospect: any
}

const SubConversationPill = ({ prospect }: Props) => {
    const { subConversation } = prospect || {}
    if (!subConversation.contact) return null
    return <>
        <BadgePill>
            <span className="bg-purple-800 w-[13px] h-[13px] rounded-full shrink-0" />
            <span>Sub-Conversación</span>
        </BadgePill>
    </>
}

export default SubConversationPill