
import { uploadFileToS3ByObject } from '@/helpers/uploadFileToS3'
import useUploadFile, { IFileToReturn } from '@/hooks/useUploadFile'
import { setEnableSaveRequestSale } from '@/redux/slices/crm'
import { CloudUpload } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CardFile } from '../EmailInput/CardFile'

interface UploadMultipleFileInputProps {
    onChange: (value: any[]) => void,
    value: any[],
    title: string,
    disabled?: boolean,
    required?: boolean,
    requestData?: {
        requestsaleid: string,
        contactid: string,
        field: string,
        updatedAt: string
    }
}

const UploadMultipleFileInput = ({
    onChange,
    value = [],
    title,
    disabled,
    required,
}: UploadMultipleFileInputProps) => {
    const [error, setError] = useState<string | null>(null)

    const dispatch = useDispatch()

    const onHandleAddFile = async (files: IFileToReturn[]) => {
        if (!files.length) return setError('El tamaño del archivo es superior al permitido')
        if (disabled) return;
        const filesList = [...value, ...files];
        dispatch(setEnableSaveRequestSale(false))

        await uploadFileToS3ByObject(filesList, {
            getPreview: ({ index, file }) => {
                filesList[index] = file
                onChange(filesList)
            }
        })

        onChange(filesList.map(({ name, path, type, size }) => ({
            uploadedFrom: 'web',
            name,
            path,
            type,
            size
        })));

        dispatch(setEnableSaveRequestSale(true))
    }

    const { getInputProps, getRootProps, isDragActive, isFileDialogActive } = useUploadFile({
        onChangeValue: onHandleAddFile
    })

    const onHandleDeleteFile = (inx: number) => {
        const allValues = [...value]
        allValues.splice(inx, 1)
        onChange(allValues)
    };

    useEffect(() => {
        if (isFileDialogActive) {
            setError(null)
        }
    }, [isFileDialogActive])

    return (
        <div className="">
            <Typography fontSize={12} mb={0.5} color="#555">{title}{required ? '*' : ''}</Typography>
            <div {...(!disabled ? getRootProps() : {})} className={disabled ? 'pointer-events-none opacity-70' : ''}>
                <div className={`border-dashed border-2  rounded-md w-full py-7 flex-col flex items-center justify-center gap-2 ${isDragActive ? 'border-primary' : 'border-[#ccc]'}`}>
                    <input  {...(!disabled ? getInputProps() : {})} />
                    <CloudUpload sx={{ fontSize: '50px', mb: 0.5 }} />
                    <h5 className='font-medium text-gray-800 text-[13px]'>
                        {
                            isDragActive
                                ? 'Suelta el archivo aquí'
                                : 'Arrastra una imagen/archivo o presiona para seleccionar'
                        }
                    </h5>
                    <p className='text-gray-500 text-[12px]'>Debe ser menor a 50 MB</p>
                    {error && <p className='text-red-500 text-[12px]'>{error}</p>}
                </div>
            </div>
            <ul className='grid w-full gap-2 mt-2'>
                {
                    value.map((file, inx) => (
                        <div key={`file-${inx}`}>
                            <CardFile
                                handleDeleteFile={() => onHandleDeleteFile(inx)}
                                hiddenButtonDelete={disabled}
                                file={file as any}
                            />
                        </div>
                    ))
                }
            </ul>
        </div>
    )
}

export default UploadMultipleFileInput