import { ContactSearchParams, getCompanySectors } from "@/actions/comercial/companygroup"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"

export default function useGetSectors(companyId: string | null, enabled: boolean = true, defaultParams: Record<string, any> = {}) {
    const [sectors, setSectors] = useState<any[]>([])
    const [searchParams, setSearchParams] = useState<ContactSearchParams>({
        page: 1,
        limit: 50,
        search: ''
    })

    useEffect(() => {
        if (!companyId) setSectors([])
    }, [companyId])

    const { data, ...rest } = useQuery({
        queryKey: ['sectors-of-company', searchParams, companyId],
        queryFn: () => getCompanySectors(companyId as string, { ...searchParams, ...defaultParams }),
        enabled: enabled && !!companyId
    })

    const countDocuments = data?.countDocuments || 0

    useEffect(() => {
        const sectorsList = data?.sectors
        if (sectorsList) setSectors(sectorsList)
    }, [data])

    const onHandleChangePage = (page: number) => setSearchParams(lastValue => ({ ...lastValue, page }))
    const onHandleChangeFilters = (filters: ContactSearchParams) => {
        setSearchParams(lastValue => ({
            ...lastValue,
            ...filters,
            page: 1
        }))
    }

    const onHandleChangeSectors = (sectors: any[]) => setSectors(sectors)

    return {
        onHandleChangeFilters,
        onHandleChangeSectors,
        onHandleChangePage,
        countDocuments,
        searchParams,
        sectors,
        ...rest
    }
}
