import { Button } from '@/components/ui/button'
import { Command, CommandGroup, CommandItem, CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger, } from "@/components/ui/popover"
import { CornerDownLeft, Eye, EyeOff, MoreVertical, Trash2 } from 'lucide-react'


export function OptionsComments({
    isAdmin,
    handleVisibility,
    visibility,
    user,
    handleDeleteComment,
    setOpenResponsePost,
    currentUser,
    _id,
    comment }) {
    return <Popover>
        <PopoverTrigger asChild className='[&[data-state=open]>button]:bg-white'>
            {
                <Button
                    className='absolute z-[3] w-6 h-6 hover:bg-white right-2 top-3'
                    size={'icon'}
                    variant={'ghost'}>
                    <MoreVertical size={16} />
                </Button>
            }
        </PopoverTrigger>
        <PopoverContent className="w-48 -right-10 p-0 absolute">
            <Command className='p-0'>
                <CommandList>
                    <CommandGroup>
                        {
                            isAdmin && <CommandItem className='cursor-pointer'
                                onSelect={() => handleVisibility(_id)}>
                                {
                                    visibility
                                        ? <EyeOff className="mr-2 h-4 w-4" />
                                        : <Eye className="mr-2 h-4 w-4" />
                                }
                                <span>
                                    {
                                        visibility
                                            ? 'Invisible'
                                            : 'Visible'
                                    }
                                </span>
                            </CommandItem>
                        }
                        {
                            (isAdmin || user._id === currentUser._id) && <CommandItem className='cursor-pointer'
                                onSelect={() => handleDeleteComment(comment)}>
                                <Trash2 className="mr-2 h-4 w-4" />
                                <span>Eliminar</span>
                            </CommandItem>
                        }
                        <CommandItem className='cursor-pointer'
                            onSelect={setOpenResponsePost}>
                            <CornerDownLeft className="mr-2 h-4 w-4" />
                            <span>Responder</span>
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
}