import FileInsideBubble from "./FileInsideBubble"

export default function FileInteractionCard({
    origin,
    media,
    fileName,
    children
}) {
    return (
        <div className={`justify-center p-2 flex md:w-[var(--width-bubble-media-type)] flex-col flex-grow`}>
            <FileInsideBubble media={media} fileName={fileName} origin={origin} />
            {children}
        </div>
    )
}
