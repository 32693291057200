"use client"

import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { UseFormReturn } from "react-hook-form"
import { VisitSchemaType } from "../Modal/VisitModal"
import Chip, { TailwindColor } from "@/components/ui/chip"
import { UserAvatar } from "@/components/ui/user-avatar"
import { isAuth } from "@/actions/auth"

export function ShareSelector({ contextForm, users, fieldValue }: { contextForm: UseFormReturn<VisitSchemaType>, users: any[], fieldValue: string[] }) {
    const [open, setOpen] = React.useState(false)
    const [search, setSearch] = React.useState("") // Para manejar la búsqueda

    const RenderItem = ({ label, extras }: any) => {
        const { roles = [], photo, lastname, name } = extras || {}

        return (
            <div className='flex items-center gap-2'>
                <UserAvatar user={{ lastname, name, photo }} className='w-[20px] text-white h-[20px]' />
                <p>{label}</p>
                {GetItemRole(roles)}
            </div>
        )
    };

    const GetItemRole = (roles: string[]) => {
        let label: string = '', color: TailwindColor | '' = '';

        const isEnableRole = (role: string) => roles.includes(role)

        if (isEnableRole('super')) {
            label = 'Supervisor'
            color = 'purple'
        } else if (isEnableRole('comercial')) {
            label = 'Comercial'
            color = 'orange'
        } else if (isEnableRole('project_manager')) {
            label = 'Gestor de proyecto'
            color = 'blue'
        }

        if (!label || !color) return null;

        return <Chip color={color} withCircle={false} className='text-[11px] h-[17px]'>
            {label}
        </Chip>
    };

    const handleShared = (value: any) => {
        if (!value) return contextForm.setValue('shared', [])
        if (contextForm.getValues('shared')?.some((v: any) => v === value)) {
            contextForm.setValue('shared', contextForm.getValues('shared')?.filter((v: any) => v !== value))
        } else {
            contextForm.setValue('shared', [...(contextForm.getValues('shared') || []), value])
        }
    }

    const currentUser = React.useMemo(() => isAuth(), [])

    const filteredAgents = React.useMemo(() => {
        let users_exists = new Map()

        return users?.filter((agent) => {
            if (agent._id === currentUser._id) return false
            if (users_exists.has(agent._id)) return false
            users_exists.set(agent._id, true)
            return true
        })
    }, [users, currentUser]);

    const serialize = React.useCallback((agent) => ({ label: `${agent?.name} ${agent?.lastname}`, value: agent._id, extras: agent }), [users])
    const options = React.useMemo(() => filteredAgents.map(serialize), [filteredAgents])

    const filteredOptions = React.useMemo(() => {
        if (!search) return options;
        return options.filter((user) =>
            `${user.extras.name} ${user.extras.lastname}`
                .toLowerCase()
                .includes(search.toLowerCase())
        );
    }, [search, options]);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-full justify-between font-normal bg-[#F1F3F4] border-none"
                >
                    {fieldValue.length === 1 ? `${fieldValue.length} participante` : fieldValue.length > 1 ? `${fieldValue.length} participantes` : 'Seleccionar participantes'}
                    <ChevronsUpDown className="opacity-50" size={14} />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-full p-0">
                <Command>
                    <CommandInput
                        placeholder="Buscar agente"
                        value={search}
                        onValueChange={(value) => setSearch(value)}
                    />
                    <CommandList className="max-h-[250px] w-[300px] overflow-y-auto">
                        <CommandEmpty>No se encontró el agente.</CommandEmpty>
                        <CommandGroup>
                            {filteredOptions.map((user) => (
                                <CommandItem
                                    key={user.value}
                                    value={user.label}
                                    onSelect={() => handleShared(user.value)}
                                >
                                    <RenderItem label={user.label} extras={user.extras} />
                                    <Check
                                        className={cn(
                                            "ml-auto",
                                            fieldValue.some((v) => v === user.value) ? "opacity-100" : "opacity-0"
                                        )}
                                        size={12}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
