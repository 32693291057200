

import { deleteWhatsAppMessage } from '@/actions/crm/contact';
import { AlertDialogDelete } from '@/components/AlertDialogDelete';
import { ModalPropsBase } from '@/interfaces/Interface';
import { handleUpdateLocalInteraction } from '@/redux/slices/thunks/crm';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

interface ModalDeleteWhatsAppMessageProps extends ModalPropsBase {
    contactId: string,
    interaction: any,
    id: string
};

export default function ModalDeleteWhatsAppMessage(props: ModalDeleteWhatsAppMessageProps) {
    const { open, onOpenChange, contactId, id, interaction } = props;
    if (!open) return null;

    const dispatch = useDispatch();

    const updateDeletedField = (deleted: boolean) => {
        dispatch(handleUpdateLocalInteraction({
            values: {
                ...interaction.content,
                deleted
            },
            contact: interaction.contact,
            type: interaction.type,
            id: interaction._id
        }));
    };

    const { mutate, isPending } = useMutation({
        mutationFn: () => deleteWhatsAppMessage(contactId, id),
        onError: () => {
            updateDeletedField(false);
            onOpenChange(true);
        },
        onSuccess: () => onOpenChange(false)
    });

    const onDeleteMessage = () => {
        mutate();
        updateDeletedField(true);
    };

    if (!open) return null;
    
    return (
        <AlertDialogDelete
            description="Al eliminar este mensaje, se eliminará de la plataforma de WhatsApp."
            title="¿Estás seguro de que deseas eliminar este mensaje de WhatsApp?"
            onClose={() => onOpenChange(false)}
            handleSubmit={onDeleteMessage}
            loading={isPending}
            open={open}
        />
    )
}
