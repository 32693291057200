import { Skeleton } from "@/components/ui/skeleton";

export default function LoaderMultipleSkeleton() {
    return (
        <ul className="grid gap-2">
            {
                new Array(3).fill(null).map((_, index) => (
                    <li key={index} >
                        <Skeleton className="w-[50%] h-3" />
                    </li>
                ))
            }
        </ul>
    )
}