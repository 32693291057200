import SimpleTooltip from '@/components/ui/simple-tooltip'
import { UsersRound } from 'lucide-react'
import React from 'react'
import { ContactAvatar } from '../../Prospect'
import { Separator } from '@/components/ui/separator'
import { isAuth } from '@/actions/auth'

function SharedToolTip({ sharedUsers }: { sharedUsers: { name: string, lastname: string, photo?: string, _id: string }[] }) {
    return (
        <SimpleTooltip
            content={
                <div className="flex flex-col gap-2">
                    {sharedUsers.map((user, index) => {
                        const { name, lastname, photo, _id } = user
                        return (
                            <>
                                <div key={index} className="flex justify-between items-center">
                                    <div className="flex items-center gap-2">
                                        <ContactAvatar
                                            className="w-[21px] shadow-sm text-[10px] h-[21px]"
                                            bgColor={'#0080FF'}
                                            textColor={'#fff'}
                                            firstName={name}
                                            lastName={lastname}
                                            photo={photo}
                                        />
                                        <p className="text-[13px]">{`${name} ${lastname} ${_id === isAuth()._id ? ('(Tú)') : ''}`}</p>
                                    </div>
                                </div>
                                {index !== sharedUsers.length - 1 && <Separator />}
                            </>
                        )
                    })}
                </div>
            }
        >
            <div className="flex gap-1 items-center">
                <UsersRound className="w-3 h-3" />
                <p className="text-[11px] md:tex-[12px] lg:text-[13px] font-medium">{sharedUsers.length} {sharedUsers.length > 1 ? 'Participantes' : 'Participante'}</p>
            </div>
        </SimpleTooltip>
    )
}

export default SharedToolTip
