import { cn } from "@/lib/utils";
import { PencilLine } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import InformationLabel from "./InformationLabel";

interface InfomationItem {
    title: string,
    value?: string | null | undefined,
    classNameList?: string,
    onChangeValue?: (value: any) => void,
    hidden?: boolean,
    isNumber?: boolean
};

export function TextInfomationItem({
    title,
    value,
    classNameList,
    onChangeValue,
    hidden = false,
    isNumber = false
}: InfomationItem) {
    if (!value || hidden) return null;

    return (
        <li className={cn('w-full grid grid-cols-8 gap-1', classNameList)}>
            <div className="col-span-2">
                <InformationLabel title={title} />
            </div>
            <div className="col-span-6  w-full">
                <InformationValue
                    isNumber={isNumber}
                    defaultValue={value}
                    onChangeValue={onChangeValue}
                />
            </div>
        </li>
    )
}

interface InformationDescriptionProps {
    onChangeValue?: (value: string | number) => void,
    defaultValue: string | number,
    isNumber?: boolean
};

export function InformationValue({
    defaultValue,
    onChangeValue,
    isNumber
}: InformationDescriptionProps) {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [value, setValue] = useState<string | number>(defaultValue);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleChangeDefaultValue = () => {
        if (!value || `${value}`.trim() == '') {
            setValue(defaultValue);
        } else {
            onChangeValue && onChangeValue(isNumber ? Number(value) : value)
        };
    }

    useEffect(() => {
        if (!onChangeValue) return;

        function handleClickOutside(event: MouseEvent) {
            if (
                wrapperRef.current && !wrapperRef.current.contains(event.target as Node)
            ) {
                handleChangeDefaultValue();
                setEditMode(false);
            }
        }

        if (editMode) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [editMode, handleChangeDefaultValue]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;

        if (isNumber) {
            const numericValue = value.replace(/\D/g, '');
            setValue(numericValue);
        } else {
            setValue(value);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleChangeDefaultValue();
            setEditMode(false);
        }
    };

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        if (editMode && textareaRef.current) {
            adjustTextareaHeight();
            textareaRef.current.focus();
            textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length);
        }
    }, [editMode, value]);

    return (
        <div ref={wrapperRef} className="max-w-[100%] flex">
            {editMode ? (
                <textarea
                    ref={textareaRef}
                    className="text-[13.5px] outline-none w-full text-gray-700 resize-none overflow-hidden"
                    value={value}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onInput={adjustTextareaHeight}
                    autoFocus
                    rows={1}
                />
            ) : (
                <button
                    onClick={() => onChangeValue && setEditMode(true)}
                    className={`max-w-full hover:text-gray-800 inline-block ${!onChangeValue ? 'cursor-not-allowed' : ''} relative text-start text-[13.5px] group text-gray-700`}
                    style={{ whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}
                >
                    <span className="inline">
                        {value}
                    </span>
                    {
                        onChangeValue && (
                            <PencilLine
                                size={15}
                                className="group-hover:opacity-100 opacity-0 inline ml-1 duration-100"
                            />
                        )
                    }
                </button>
            )}
        </div>
    );
}
