import { Call, PhoneCall, PhoneMissed, Voicemail } from "../../Icons/Crm"

export const phoneState = {
    done: {
        icon: (
            <div>
                <Call className="stroke-green-500 w-[14px] h-[14px]" />
            </div>
        ),
        color: "#22C55E",
    },
    cancel: {
        icon: <PhoneMissed className="w-[14px] h-[14px]" />,
        color: "#EF4444",
    },
    voicemail: {
        icon: <Voicemail className="w-[16px] h-[16px]" />,
        color: "#FBBF24",
    },
    default: {
        icon: <PhoneCall className="w-[14px] h-[14px]" />,
        color: "",
    },
}