import { InstancesService } from '@/actions/training';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query'
interface Props {
    enabledQuery?: boolean
    type?: 'instance' | 'tutorial'
    queryKey?: Array<string>
    limit?: number
    page?: number
    company?: string | null | undefined
    lastInteraction?: boolean
    isAutoRefetch?: boolean
    isActiveFetch?: boolean
    returnNull?: boolean
}

export default function useGetInstance({
    enabledQuery = false,
    type = 'instance',
    queryKey,
    company,
    limit = 10,
    page = 1,
    returnNull = false,
    isAutoRefetch = false,
    lastInteraction = false }: Props) {

    const queryClient = useQueryClient()

    const { refetch, data, isLoading, isError } = useQuery({
        queryKey: queryKey as string[],
        queryFn: () => InstancesService.get({ type, size: limit, company, page, lastInteraction }),
        enabled: enabledQuery && !!queryKey
    });

    const { mutate, status, isError: isErrorMutate } = useMutation({
        mutationFn: ({ typeOfAction, payload, id }: any) => {
            const dictionaryOfFn = {
                'create': () => InstancesService.createOrUpdate({ body: payload, id }),
                'delete': () => InstancesService.delete(id),
                'update_lastInteraction': () => InstancesService.updateLastInteraction(id)
            }

            return dictionaryOfFn[typeOfAction]()
        },
        onSuccess: (_, { fnSuccess, queryKeyToInvalide }: any) => {
            fnSuccess && fnSuccess()
            queryClient.invalidateQueries(queryKeyToInvalide || queryKey)
            isAutoRefetch && refetch()
        }
    })

    const isLoadingMutate = status === 'pending'

    const handleActionMutate = useCallback(({ id, typeOfAction, payload, queryKeyToInvalide, fnSuccess }: any) => {
        mutate({
            id,
            typeOfAction,
            payload,
            fnSuccess,
            queryKeyToInvalide
        })
    }, [queryKey, page, limit])

    const handleDelete = (id, fnSuccess) => {
        handleActionMutate({ id, fnSuccess, typeOfAction: 'delete' })
    }

    const handleCreate = ({ payload, id, fnSuccess }: { payload: any, id: string, fnSuccess: () => void }) => {
        handleActionMutate({
            typeOfAction: 'create',
            payload,
            id,
            fnSuccess
        })
    }

    return {
        refetch,
        items: data?.instances || (returnNull ? null : []),
        countDocuments: data?.countDocuments || 0,
        isLoading,
        isError,
        isLoadingMutate,
        isErrorMutate,
        handleDelete,
        handleCreate
    }
}
