import { Label, TextSnippet, TransferWithinAStation } from "@mui/icons-material"
import { Box, DialogContent, DialogTitle, Grid, Input, Typography } from "@mui/material"
import moment from "moment"
import ActionAgentText from "./ActionAgentText"
import { AlarmClockOff, Expand, Info, PhoneMissed, PhoneOff, Tags } from "lucide-react"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Dialog, DialogDescription, DialogFooter, DialogHeader, DialogTrigger } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import ModalDisregarded from "./Disregarded/ModalDisregarded"
import { useState } from "react"

moment.locale('es')

interface DisregardedBubble {
    date: Date,
    agent?: string | {
        _id: string,
        name: string,
        lastname: string,
        email: string
    }
}


const DisregardedBubble = ({ date, agent }: DisregardedBubble) => {
    const [open, setOpen] = useState<string | boolean>(false)

    return (
        <>
            <div className="flex items-center justify-center w-full">
                <ModalDisregarded open={open} close={() => setOpen(false)} />
                <Grid container direction={"column"} justifyContent="center" alignItems={"center"}>
                    <Grid container justifyContent={"space-between"} alignItems="center" sx={{ background: "#fff", color: "red", py: '10px', px: '10px', width: { sm: "60%", xs: "100%" }, borderRadius: '10px', border: '1px solid #ddd' }}>
                        <Grid item><AlarmClockOff /></Grid>
                        <Grid item direction={'row'} className="flex items-center gap-1">
                            <Typography>Contacto desatendido</Typography>
                            <Info size={15} onClick={() => setOpen(true)} className="cursor-pointer" />
                        </Grid>
                        <Grid item><Typography>{moment(date).format('LT')}</Typography></Grid>
                    </Grid>
                    <ActionAgentText agent={agent} actionName="Desatendido" />
                </Grid>
            </div>
        </>

    )
}


export default DisregardedBubble