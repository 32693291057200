import { cn } from '@/lib/utils';
import React from 'react';

export type TailwindColor =
    | 'gray'
    | 'red'
    | 'yellow'
    | 'green'
    | 'blue'
    | 'primary'
    | 'grey'
    | 'amber'
    | 'indigo'
    | 'purple'
    | 'pink'
    | 'red-light'
    | 'yellow-light'
    | 'green-light'
    | 'blue-light'
    | 'indigo-light'
    | 'purple-light'
    | 'pink-light'
    | 'teal'
    | 'orange'
    | 'gray-dark'
    | 'red-dark'
    | 'yellow-dark'
    | 'green-dark'
    | 'blue-dark'
    | 'indigo-dark'
    | 'purple-dark'
    | 'pink-dark'
    | 'teal-dark'
    | 'orange-dark';

export const tailwindColors = {
    green: {
        color: '#166534',
        background: '#dcfce7',
        circle: '#4ade80'
    },
    amber: {
        color: '#92400e',
        background: '#fef3c7',
        circle: '#fbbf24'
    },
    red: {
        color: '#991b1b',
        background: '#fee2e2',
        circle: '#f87171'
    },
    orange: {
        color: '#9a3412',
        background: '#ffedd5',
        circle: '#fb923c'
    },
    grey: {
        color: '#1e293b',
        background: '#f1f5f9',
        circle: '#9ca3af'
    },
    blue: {
        color: '#1e40af',
        background: '#dbeafe',
        circle: '#60a5fa'
    },
    pink: {
        color: '#9d174d',
        background: '#fce7f3',
        circle: '#f472b6'
    },
    purple: {
        color: '#6b21a8', // 800
        background: '#f3e8ff', // 100
        circle: '#c084fc' // 400
    },
    primary: {
        color: '#15324e',
        background: '#c0dfff',
        circle: '#0080FF'
    },
    yellow: {
        color: '#854d0e', // 800
        background: '#fef9c3', // 100
        circle: '#facc15' // 400
    }
}

const generateColors = (color: TailwindColor) => {
    return tailwindColors[color] ?? tailwindColors.grey
}


export interface ChipProps
    extends React.HTMLAttributes<HTMLDivElement> {
    color?: TailwindColor
    withCircle?: boolean
    classNameCircle?: string,
    border?: boolean
}

export default function Chip({
    className,
    border,
    classNameCircle,
    color = 'green',
    children,
    withCircle = true,
    ...props
}: ChipProps) {
    const { color: colorText, background, circle } = generateColors(color) || {}

    const styled = border ? {
        borderColor: colorText,
        borderWidth: '1px',
        color: colorText
    } : {
        color: colorText,
        background
    }

    return <div
        style={styled}
        className={cn(`inline-flex  items-center rounded-full px-2.5 py-[1.1px] text-[12px] font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 gap-1.5`, className)} {...props}>
        {
            withCircle && <span
                style={{
                    background: circle
                }}
                className={cn(`w-[6px] h-[6px] rounded-full`, classNameCircle)} />
        }
        {children}
    </div>
}
