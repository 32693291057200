

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import moment from 'moment';

interface LastPreviusMessagesProps {
    onOpenChange: (value: boolean) => void,
    lastPreviusMessages?: {
        updatedAt: string,
        content: { text: string },
        waEventId: string
    }[]
}

export default function LastPreviusMessages({ onOpenChange, lastPreviusMessages = [] }: LastPreviusMessagesProps) {
    return (
        <Popover open={true} onOpenChange={onOpenChange}>
            <PopoverTrigger>
                <div></div>
            </PopoverTrigger>
            <PopoverContent className="w-[320px] p-0">
                <header className="border-b px-3 py-2">
                    <h3 className="text-[14px] font-medium text-gray-800">
                        Ediciones pasadas
                    </h3>
                </header>
                <ul className="grid max-h-[300px] overflow-y-auto">
                    {
                        lastPreviusMessages.map(({ content, updatedAt }, inx) => {
                            return (
                                <>
                                    <li key={inx} className={`px-3 py-2 ${(inx < lastPreviusMessages.length - 1) ? 'border-b' : ''}`}>
                                        <h5 className="text-[13px] font-medium text-gray-700">
                                            {moment(updatedAt).format('D [de] MMMM [de] YYYY HH:mm[hs]')}
                                        </h5>
                                        <p className="text-[12px] text-gray-500">
                                            {content.text}
                                        </p>
                                    </li>
                                </>
                            )
                        })
                    }
                </ul>
            </PopoverContent>
        </Popover>
    )
}