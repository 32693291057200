import { ErrorsCodeBot, ErrorsCodeTransfer, InternalServerError } from ".";
const {
    AlreadyBelongsToAgent,
    DoesNotBelongToTheGroup,
    GroupNotFound,
    InvalidData,
    UserProxy,
    YourselfTransfer
} = ErrorsCodeTransfer

export const InternalServerErrorDescription = `Se produjo un error en el servidor. Por favor, inténtentelo nuevamente. Si el problema persiste, pongase en contacto con soporte.`

export const TransferErrorsDescription = {
    [YourselfTransfer]: `No puedes transferirte tus propios contactos a ti mismo. Error ${YourselfTransfer}.`,
    [GroupNotFound]: `Grupo no encontrado. Error ${GroupNotFound}`,
    [InvalidData]: `Datos de trasferencia invalidos. Error ${InvalidData}`,
    [AlreadyBelongsToAgent]: `El contacto que intentas transferir ya está asignado al agente. Error ${AlreadyBelongsToAgent}`,
    [UserProxy]: `Error al transferir al usuario proxy. Error ${UserProxy}`,
    [DoesNotBelongToTheGroup]: `No perteneces al grupo del agente a transferir. Error ${DoesNotBelongToTheGroup}`,
    [InternalServerError]: InternalServerErrorDescription
}

export const errorsContactCampaign = {
    es: {
        'PERSONAL_WHATSAPP_404': 'No se encontró una sesión de WhatsApp Personal para el envío del mensaje.',
        'CONTACT_NOT_FOUND': 'No se encontró el contacto para la realización de la acción.',
        'CONTACT_WITHOUT_AGENT': 'El contacto no tiene un agente asignado para la realización de la acción',
        'SEND_MESSAGE_ERROR': 'Ocurrió un error con el envío de mensaje.',
        'MAXIMUN_MESSAGE_EXCEEDED': 'El máximo de mensajes por día fue superado',
        'NOTE_ERROR': 'Ocurrió un error al realizar la nota',
        'INTERNAL_SERVER_ERROR': "Se ha producido un error en la realización de la acción. Por favor, intenta nuevamente más tarde. Si el problema persiste, por favor contacta a nuestro equipo de soporte para recibir asistencia adicional.",
        'CHANNEL_ERROR': 'Se ha producido un error en el envío del mensaje.',
        'SMS_ERROR': 'Se ha producido un error en el envío del mensaje de texto.',
        'SESSION_NOT_FOUND': "La sesión no se encontró para la realización de la acción.",
        "CONTACT_WITHOUT_PHONE_NUMBER": "Contacto sin número de teléfono.",
        "CHANNEL_WITHOUT_SESSIONS": "Canal sin sesiones",
        "NO_VALID_SESSION": "La sesion con la que la acción se realizo no es valida",
        "CHANNEL_NOT_FOUND": "El canal no se ha encontrado",
        "MESSAGE_LIMIT_EXCEEDED": "Límite de mensajes por hora alcanzado. Esperando a que se restablezca el límite para continuar.",
        ...Object.fromEntries(Object.entries(TransferErrorsDescription).map(([key, value]) => [`TRANSFER_${key}`, value]))
    }
}

export const SectorErrorsType = {
    SectorNameReadyUse: 'SECTOR_NAME_READY_USE'
}

export const SectorMessageErrorByType = {
    [SectorErrorsType['SectorNameReadyUse']]: `El nombre del sector ya está en uso.\n Code error: ${SectorErrorsType['SectorNameReadyUse']}`,
    [InternalServerError]: InternalServerErrorDescription
}

export const BotErrorMessage = {
    [ErrorsCodeBot['BotNotActive']]: 'El BOT no se encuentra activo. Por favor, actívalo para poder empezar a utilizarlo.',
    [ErrorsCodeBot['BotConfigNotFound']]: 'No se encontró la configuración del BOT'
}

export const surveysError = {
    "NET_PROMOTER_SCORE_NOT_FOUND": "No se encontró la configuración del Net Promoter Score. Por favor, asegúrese de que el NPS esté configurado correctamente en el sistema.",
    "CONTACT_NOT_FOUND": "El contacto especificado no se encontró en la base de datos. Verifique que la información del contacto sea correcta.",
    "CONTACT_WITHOUT_PHONE_NUMBER": "El contacto no tiene un número de teléfono asociado. Es necesario un número de teléfono para enviar la encuesta por el canal seleccionado.",
    "ERROR_ON_SEND_MESSAGE": "Se produjo un error al intentar enviar el mensaje de encuesta. Por favor, vuelva a intentarlo.",
    "DISPATCH_SURVEY_ERROR": "Hubo un problema al enviar la encuesta al servidor. Por favor, intente nuevamente o contacte al soporte técnico si el problema persiste.",
}
