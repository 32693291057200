import { cn } from "@/lib/utils"
import React from "react"
interface IconsProps extends React.SVGProps<SVGSVGElement> { }

const Clock = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px]", className)}
        {...props}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.714 18.4229L5.28571 21M15.286 18.4229L16.7143 21M11 2.42853C6.26611 2.42853 2.42853 6.26611 2.42853 11C2.42853 15.7339 6.26611 19.5715 11 19.5715C15.7339 19.5715 19.5715 15.7339 19.5715 11C19.5715 6.26611 15.7339 2.42853 11 2.42853ZM11 2.42853L11 1M2.71534 8.71507C2.05729 8.22185 1.55504 7.54972 1.26853 6.77888C0.982009 6.00804 0.923321 5.17104 1.09946 4.36775C1.27561 3.56447 1.67915 2.82883 2.26192 2.24861C2.8447 1.66838 3.58211 1.26808 4.38616 1.09547C5.19021 0.922859 6.02694 0.985225 6.79652 1.27513C7.56609 1.56503 8.23601 2.07023 8.72634 2.73043M11 6.71429V11L14.0305 14.0305M19.2847 8.71507C19.9427 8.22185 20.445 7.54972 20.7315 6.77888C21.018 6.00804 21.0767 5.17104 20.9005 4.36776C20.7244 3.56447 20.3209 2.82883 19.7381 2.24861C19.1553 1.66838 18.4179 1.26808 17.6138 1.09547C16.8098 0.922859 15.9731 0.985225 15.2035 1.27513C14.4339 1.56503 13.764 2.07023 13.2737 2.73043"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )
  }
)

const Call = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px]", className)}
        {...props}
        viewBox="0 0 10 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.25678 5.09562C2.65332 6.96625 3.64281 8.67157 5.06299 9.95612L6.68562 9.0193C6.88476 8.90432 7.13146 8.91431 7.31635 9.03867C7.91018 9.42846 8.58286 9.7334 9.30415 9.92667C9.62409 10.0124 9.81571 10.3443 9.72998 10.6642L9.18602 12.6943C9.10029 13.0143 8.76839 13.2059 8.44846 13.1202C2.98636 11.6566 -0.254194 6.04379 1.20937 0.581693C1.2951 0.261762 1.627 0.0701389 1.94693 0.155864L3.98285 0.701389C4.30278 0.787114 4.49441 1.11901 4.40868 1.43895C4.21385 2.16606 4.14315 2.89527 4.18381 3.60443C4.19325 3.82517 4.08592 4.03956 3.87941 4.15879L2.25678 5.09562Z"
          fill="#22C55E"
        />
      </svg>
    )
  }
)

const PhoneMissed = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px]", className)}
        {...props}
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.71929 6.89857L8.72929 6.50571C8.58697 6.44906 8.4312 6.43524 8.28114 6.46595C8.13107 6.49666 7.99326 6.57056 7.88464 6.67857L7.07143 7.48786C6.29671 7.4046 5.56467 7.09127 4.96964 6.58821L11 0.553929L10.4461 0L0 10.4461L0.553929 11L3.66929 7.88857C4.94214 9.11429 6.76893 10.0493 9.38536 10.2143C9.49212 10.2202 9.59895 10.2042 9.69932 10.1674C9.7997 10.1305 9.89149 10.0736 9.96909 10C10.0467 9.92646 10.1085 9.83784 10.1506 9.73958C10.1928 9.64132 10.2144 9.53549 10.2143 9.42857V7.62536C10.2137 7.46867 10.1662 7.31574 10.078 7.18623C9.98977 7.05672 9.86486 6.95655 9.71929 6.89857ZM2.41607 6.36036L3.79893 4.9775C3.64998 4.642 3.54182 4.28984 3.47679 3.92857L4.29 3.11536C4.39801 3.00674 4.47191 2.86893 4.50262 2.71886C4.53334 2.5688 4.51951 2.41303 4.46286 2.27071L4.07 1.28071C4.01178 1.13452 3.911 1.00918 3.78071 0.920921C3.65043 0.832664 3.49665 0.785562 3.33929 0.785714H1.57143C1.46055 0.785041 1.35079 0.807842 1.24936 0.852618C1.14793 0.897394 1.05712 0.963131 0.982904 1.04551C0.90869 1.12789 0.852752 1.22504 0.818766 1.33058C0.78478 1.43612 0.773516 1.54765 0.785714 1.65786C0.97595 3.32636 1.53269 4.93217 2.41607 6.36036Z"
          fill="#EF4444"
        />
      </svg>
    )
  }
)

const PhoneCall = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px]", className)}
        {...props}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.063 5.88492L9.23163 5.72702C9.2941 5.39634 9.22282 5.05438 9.03344 4.77619C8.84406 4.49801 8.55205 4.30631 8.2215 4.24319L8.37939 3.41187C8.93027 3.51718 9.41688 3.83669 9.73249 4.30031C10.0481 4.76392 10.1669 5.33379 10.063 5.88492Z"
          fill="black"
        />
        <path
          d="M11.7247 6.20059L10.8934 6.04269C11.039 5.27111 10.8726 4.47327 10.4308 3.82419C9.98891 3.17512 9.30762 2.72781 8.53638 2.58041L8.69428 1.74909C9.68589 1.93858 10.5619 2.51367 11.13 3.3482C11.6981 4.18274 11.912 5.20855 11.7247 6.20059ZM7.86816 9.12092L8.97609 8.36668C9.1244 8.2657 9.29908 8.21044 9.47848 8.20778C9.65789 8.20511 9.83414 8.25514 9.98539 8.35167L11.0331 9.02095C11.1875 9.11956 11.3089 9.26218 11.3815 9.43042C11.4541 9.59865 11.4746 9.78478 11.4404 9.96479L11.0487 12.0269C11.0257 12.1495 10.978 12.2661 10.9083 12.3697C10.8387 12.4732 10.7487 12.5614 10.6438 12.6289C10.5389 12.6964 10.4213 12.7418 10.2982 12.7623C10.1752 12.7828 10.0492 12.778 9.9281 12.7481C2.07378 10.7438 1.74716 3.70687 1.93877 1.07234C1.94828 0.943565 1.98493 0.818264 2.04634 0.704676C2.10774 0.591087 2.19249 0.491786 2.29503 0.413302C2.39756 0.334819 2.51554 0.278932 2.64122 0.249318C2.7669 0.219703 2.89743 0.217033 3.02422 0.241482L5.04973 0.626202C5.22974 0.660386 5.39538 0.747723 5.52529 0.876946C5.65519 1.00617 5.74339 1.17135 5.77852 1.35118L6.01663 2.57124C6.05098 2.74734 6.03291 2.92966 5.96465 3.09559C5.89639 3.26153 5.78094 3.40379 5.63261 3.50474L4.52468 4.25897C4.52468 4.25897 4.35154 7.98658 7.86816 9.12092Z"
          fill="black"
        />
      </svg>
    )
  }
)

const Voicemail = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px]", className)}
        {...props}
        viewBox="0 0 22 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9583 0C14.1717 0 11.9167 2.23636 11.9167 5C11.9167 6.20909 12.3475 7.31818 13.0717 8.18182H8.92833C9.6525 7.31818 10.0833 6.20909 10.0833 5C10.0833 2.23636 7.82833 0 5.04167 0C2.255 0 0 2.23636 0 5C0 7.76364 2.255 10 5.04167 10H16.9583C19.745 10 22 7.76364 22 5C22 2.23636 19.745 0 16.9583 0ZM5.04167 8.18182C3.2725 8.18182 1.83333 6.75455 1.83333 5C1.83333 3.24545 3.2725 1.81818 5.04167 1.81818C6.81083 1.81818 8.25 3.24545 8.25 5C8.25 6.75455 6.81083 8.18182 5.04167 8.18182ZM16.9583 8.18182C15.1892 8.18182 13.75 6.75455 13.75 5C13.75 3.24545 15.1892 1.81818 16.9583 1.81818C18.7275 1.81818 20.1667 3.24545 20.1667 5C20.1667 6.75455 18.7275 8.18182 16.9583 8.18182Z"
          fill="#FBBF24"
        />
      </svg>
    )
  }
)

const Phone = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px]", className)}
        {...props}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.27131 2.18114H4.92292L5.77746 4.31783L4.40441 5.23322C4.32355 5.28717 4.25726 5.36024 4.21141 5.44595C4.16557 5.53167 4.14159 5.62737 4.14161 5.72458V5.73757C4.14196 5.76398 4.14314 5.79037 4.14515 5.8167C4.14869 5.86513 4.1546 5.93069 4.16582 6.01159C4.18885 6.17105 4.23314 6.39074 4.31937 6.64941C4.49299 7.16911 4.83315 7.84178 5.49576 8.5044C6.15837 9.16702 6.83102 9.50719 7.35012 9.68082C7.60937 9.76704 7.82847 9.81074 7.9891 9.83437C8.07977 9.84702 8.17104 9.85491 8.26253 9.85799L8.27021 9.85858H8.27493C8.38458 9.85852 8.49263 9.82794 8.58588 9.77026C8.67913 9.71258 8.75447 9.63008 8.80348 9.53199L9.19916 8.74063L11.8189 9.17765V11.7289C10.5722 11.909 7.20484 12.0868 4.55913 9.44104C1.91343 6.79528 2.09059 3.42725 2.27131 2.18114ZM5.36584 6.01159L6.43298 5.30054C6.65842 5.15017 6.8253 4.92684 6.90562 4.66802C6.98594 4.4092 6.97482 4.13063 6.87413 3.87904L6.01959 1.74235C5.9319 1.52319 5.78055 1.33534 5.58508 1.20302C5.3896 1.0707 5.15896 0.999984 4.92292 1H2.2406C1.70378 1 1.19885 1.37265 1.11086 1.95495C0.910066 3.27902 0.637819 7.18979 3.72408 10.2761C6.81035 13.3624 10.721 13.0896 12.0451 12.8894C12.6274 12.8008 13 12.2965 13 11.7596V9.17765C13 8.89805 12.9009 8.6275 12.7202 8.41414C12.5395 8.20078 12.289 8.05845 12.0132 8.01245L9.39345 7.57602C9.14429 7.53443 8.88836 7.57382 8.66324 7.6884C8.43811 7.80299 8.25565 7.98673 8.14265 8.21266L7.93831 8.62192C7.86606 8.60413 7.79454 8.58344 7.72394 8.55991C7.35779 8.43825 6.84932 8.18785 6.33081 7.66933C5.8123 7.15081 5.5619 6.64232 5.44025 6.27558C5.4112 6.18889 5.38597 6.10078 5.36584 6.01159Z"
          fill="#475569"
          stroke-width="0.2"
        />
      </svg>
    )
  }
)

const ListAlt = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px]", className)}
        {...props}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.11111 17.1111C6.45741 17.1111 6.74789 16.9938 6.98256 16.7591C7.21722 16.5244 7.33415 16.2344 7.33333 15.8889C7.33333 15.5426 7.216 15.2521 6.98133 15.0174C6.74667 14.7828 6.45659 14.6659 6.11111 14.6667C5.76482 14.6667 5.47433 14.784 5.23967 15.0187C5.005 15.2533 4.88808 15.5434 4.88889 15.8889C4.88889 16.2352 5.00622 16.5257 5.24089 16.7603C5.47556 16.995 5.76563 17.1119 6.11111 17.1111ZM6.11111 12.2222C6.45741 12.2222 6.74789 12.1049 6.98256 11.8702C7.21722 11.6356 7.33415 11.3455 7.33333 11C7.33333 10.6537 7.216 10.3632 6.98133 10.1286C6.74667 9.89389 6.45659 9.77696 6.11111 9.77778C5.76482 9.77778 5.47433 9.89511 5.23967 10.1298C5.005 10.3644 4.88808 10.6545 4.88889 11C4.88889 11.3463 5.00622 11.6368 5.24089 11.8714C5.47556 12.1061 5.76563 12.223 6.11111 12.2222ZM6.11111 7.33333C6.45741 7.33333 6.74789 7.216 6.98256 6.98133C7.21722 6.74667 7.33415 6.45659 7.33333 6.11111C7.33333 5.76482 7.216 5.47433 6.98133 5.23967C6.74667 5.005 6.45659 4.88808 6.11111 4.88889C5.76482 4.88889 5.47433 5.00622 5.23967 5.24089C5.005 5.47556 4.88808 5.76563 4.88889 6.11111C4.88889 6.45741 5.00622 6.74789 5.24089 6.98256C5.47556 7.21722 5.76563 7.33415 6.11111 7.33333ZM9.77778 17.1111H17.1111V14.6667H9.77778V17.1111ZM9.77778 12.2222H17.1111V9.77778H9.77778V12.2222ZM9.77778 7.33333H17.1111V4.88889H9.77778V7.33333ZM2.44445 22C1.77222 22 1.19656 21.7604 0.717446 21.2813C0.238335 20.8022 -0.00081274 20.227 2.07508e-06 19.5556V2.44445C2.07508e-06 1.77222 0.239558 1.19656 0.718669 0.717446C1.19778 0.238335 1.77304 -0.00081274 2.44445 2.07508e-06H19.5556C20.2278 2.07508e-06 20.8034 0.239558 21.2826 0.718669C21.7617 1.19778 22.0008 1.77304 22 2.44445V19.5556C22 20.2278 21.7604 20.8034 21.2813 21.2826C20.8022 21.7617 20.227 22.0008 19.5556 22H2.44445Z"
          fill="#6B7280"
        />
      </svg>
    )
  }
)
const DoorOpen = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] fill-current h-[22px]", className)}
        {...props}
        viewBox="0 0 19 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.525 13.125H16.15V3.32371C16.15 2.52507 15.5108 1.87498 14.725 1.87498H11.4V3.74998H14.25V15H18.525C18.7874 15 19 14.7902 19 14.5312V13.5937C19 13.3348 18.7874 13.125 18.525 13.125ZM9.26962 0.0295614L3.56962 1.48679C3.14658 1.5949 2.85 1.98337 2.85 2.42927V13.125H0.475C0.212563 13.125 0 13.3348 0 13.5937V14.5312C0 14.7902 0.212563 15 0.475 15H10.45V0.972044C10.45 0.339816 9.86931 -0.123955 9.26962 0.0295614ZM7.8375 8.43749C7.44414 8.43749 7.125 8.01766 7.125 7.49999C7.125 6.98231 7.44414 6.56248 7.8375 6.56248C8.23086 6.56248 8.55 6.98231 8.55 7.49999C8.55 8.01766 8.23086 8.43749 7.8375 8.43749Z"
          // fill="#1F2937"
        />
      </svg>
    )
  }
)

const Note = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px]", className)}
        {...props}
        viewBox="0 0 19 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.94444 5.05556V1.08333L11.9167 5.05556M1.44444 0C0.642778 0 0 0.642778 0 1.44444V11.5556C0 11.9386 0.152182 12.306 0.423068 12.5769C0.693954 12.8478 1.06135 13 1.44444 13H11.5556C11.9386 13 12.306 12.8478 12.5769 12.5769C12.8478 12.306 13 11.9386 13 11.5556V4.33333L8.66667 0H1.44444Z"
          fill="#1F2937"
        />
      </svg>
    )
  }
)

const NotCLock = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px] stroke-[#EF4444]", className)}
        {...props}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.922 3.922C3.40458 4.34724 2.98188 4.87605 2.68107 5.47443C2.38026 6.07282 2.208 6.72753 2.17537 7.39647C2.14274 8.06542 2.25046 8.73378 2.49161 9.3586C2.73275 9.98342 3.10198 10.5509 3.57556 11.0244C4.04914 11.498 4.61658 11.8672 5.2414 12.1084C5.86622 12.3495 6.53458 12.4573 7.20353 12.4246C7.87247 12.392 8.52718 12.2197 9.12557 11.9189C9.72395 11.6181 10.2528 11.1954 10.678 10.678M11.74 8.35C11.8581 7.60214 11.7976 6.83695 11.5635 6.11694C11.3294 5.39692 10.9282 4.7425 10.3929 4.20713C9.8575 3.67176 9.20308 3.27063 8.48307 3.0365C7.76305 2.80238 6.99786 2.74189 6.25 2.86M13 3.4L11.2 1.6M3.556 11.002L2.2 12.4M1 1L13 13M2.2 2.2L1 3.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )
  }
)

const NewPhone = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px]", className)}
        {...props}
        viewBox="0 0 5 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.16667 0H0.833333C0.61232 0 0.400358 0.0948211 0.244078 0.263604C0.0877973 0.432387 0 0.661305 0 0.9V8.1C0 8.33869 0.0877973 8.56761 0.244078 8.7364C0.400358 8.90518 0.61232 9 0.833333 9H4.16667C4.38768 9 4.59964 8.90518 4.75592 8.7364C4.9122 8.56761 5 8.33869 5 8.1V0.9C5 0.661305 4.9122 0.432387 4.75592 0.263604C4.59964 0.0948211 4.38768 0 4.16667 0ZM2.91667 8.55H2.08333V8.1H2.91667V8.55ZM4.16667 7.65H0.833333V1.35H4.16667V7.65Z"
          fill="black"
        />
      </svg>
    )
  }
)

const PecilEdit = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px] fill-[#1F2937]", className)}
        {...props}
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.37687 8.79667H0V6.46004L6.40572 0.161276C6.51077 0.058011 6.65323 0 6.80177 0C6.95031 0 7.09277 0.058011 7.19782 0.161276L8.78258 1.71903C8.8876 1.82233 8.9466 1.96241 8.9466 2.10847C8.9466 2.25453 8.8876 2.39461 8.78258 2.49791L2.37687 8.79667ZM0 9.89834H10.0833V11H0V9.89834Z"
        />
      </svg>
    )
  }
)

const SearchIcon = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px]", className)}
        {...props}
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.8528 9.50781L6.99041 6.84115M1.26563 4.61892C1.26562 5.02748 1.352 5.43204 1.51983 5.80949C1.68765 6.18695 1.93363 6.52992 2.24373 6.81881C2.55383 7.1077 2.92197 7.33687 3.32713 7.49322C3.73229 7.64956 4.16654 7.73003 4.60508 7.73003C5.04362 7.73003 5.47787 7.64956 5.88304 7.49322C6.2882 7.33687 6.65634 7.1077 6.96643 6.81881C7.27653 6.52992 7.52251 6.18695 7.69034 5.80949C7.85816 5.43204 7.94454 5.02748 7.94454 4.61892C7.94454 4.21037 7.85816 3.80581 7.69034 3.42835C7.52251 3.0509 7.27653 2.70793 6.96643 2.41904C6.65634 2.13014 6.2882 1.90098 5.88304 1.74463C5.47787 1.58828 5.04362 1.50781 4.60508 1.50781C4.16654 1.50781 3.73229 1.58828 3.32713 1.74463C2.92197 1.90098 2.55383 2.13014 2.24373 2.41904C1.93363 2.70793 1.68765 3.0509 1.51983 3.42835C1.352 3.80581 1.26562 4.21037 1.26563 4.61892Z"
          stroke="#6B7280"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )
  }
)

const Error = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px] fill-[#F54457]", className)}
        {...props}
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M579.6 507.6l91.7-91.8c20.3-20.3 20.2-53.1 0-73.4-20.3-20.3-53.1-20.3-73.4 0l-91.7 91.7-91.7-91.7c-20.3-20.3-53.1-20.3-73.4 0-20.3 20.3-20.3 53.1 0 73.4l91.7 91.8-91.7 91.7c-20.3 20.3-20.3 53.1 0 73.4 20.3 20.3 53.1 20.3 73.4 0l91.8-91.7 91.7 91.7c20.3 20.3 53.1 20.3 73.4 0 20.3-20.3 20.3-53.1 0-73.4l-91.8-91.7zM512 928.4c-229.2 0-415.1-185.8-415.1-415.1S282.8 98.2 512 98.2 927.1 284 927.1 513.3 741.2 928.4 512 928.4z" />
      </svg>
    )
  }
)

const Send = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px] fill-[#6B7280]", className)}
        {...props}
        viewBox="0 0 47 39" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 39V23.538L17.8151 19.5L0 15.462V0L47 19.5L0 39Z" />
      </svg>
    )
  }
)

const Cut = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px] fill-[#4B5563]", className)}
        {...props}
        viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.7273 45V36.8182H12.2727C11.1477 36.8182 10.185 36.418 9.38455 35.6175C8.58409 34.817 8.18318 33.8536 8.18182 32.7273V12.2727H0V8.18182H8.18182V0H12.2727V32.7273H45V36.8182H36.8182V45H32.7273ZM32.7273 28.6364V12.2727H16.3636V8.18182H32.7273C33.8523 8.18182 34.8157 8.58273 35.6175 9.38455C36.4193 10.1864 36.8195 11.1491 36.8182 12.2727V28.6364H32.7273Z" />
      </svg>

    )
  }
)

const Turn = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={cn("w-[22px] h-[22px] fill-[#4B5563]", className)}
        {...props}
        viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8737 45.6662C8.925 42.3512 3.9525 35.615 3.1875 27.625H0C1.08375 40.715 12.0275 51 25.3937 51C25.8825 51 26.3287 50.9575 26.7962 50.9362L18.7 42.8187L15.8737 45.6662ZM25.6063 0C25.1175 0 24.6713 0.0425 24.2038 0.085L32.3 8.18125L35.1263 5.355C42.075 8.64875 47.0475 15.385 47.8125 23.375H51C49.9162 10.285 38.9725 0 25.6063 0ZM34 29.75H38.25V12.75H21.25V17H34V29.75ZM17 34V8.5H12.75V12.75H8.5V17H12.75V38.25H34V42.5H38.25V38.25H42.5V34H17Z" fill="#4B5563" />
      </svg>
    )
  }
)

const FileIcon = React.forwardRef<SVGSVGElement, IconsProps>(
  ({ className, ...props }, ref) => {
    return (
      <svg
      ref={ref}
      className={cn("w-[22px] h-[22px]", className)}
      {...props}
      xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="48px" height="48px"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" ><g transform="scale(16,16)"><path d="M2,15v-15h7l5,5v10z" fill="#ededed"></path><path d="M9,0v5h5z" fill="#bebebe"></path></g></g></svg>
    )
  }
)



export {
  Clock,
  Call,
  Cut,
  Turn,
  PhoneMissed,
  Error,
  PhoneCall,
  NotCLock,
  SearchIcon,
  FileIcon,
  Voicemail,
  NewPhone,
  PecilEdit,
  Send,
  Phone,
  ListAlt,
  Note,
  DoorOpen,
}
