import { getCookie } from "./actions/auth";

export const API = process.env.API;
export const API_WPP = process.env.API_WPP ?? "https://api.getventia.com"

export const config = () => ({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "JWT " + getCookie("token"),
  },
});
