import { ContactService } from "@/actions/crm/contact-controller"
import { createLocalInteraction } from "@/helpers/crm"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { addInteraction, handleUpdate, setError, setIncrementalLocalId, setStatusTag, updateInteraction } from "../crm"

export const changeStatusTag = createAsyncThunk<
    void,
    { id: string, status: string }
>("crm/changeStatusTag", async ({ id, status }, { dispatch }) => {
    try {
        dispatch(setStatusTag(status))
        await ContactService.modifyTag({ id, statusTag: status })
    } catch (err) {
        dispatch(setError("Error al cambiar la etiqueta"))
    }
})

export const handleLocalInteractions = createAsyncThunk<void, any>(
    'crm/handleLocalInteractions',
    async (data, { dispatch, getState }) => {
        const { values, contact, type, externalAgent } = data || {}

        dispatch(setIncrementalLocalId())
        const state: any = getState()
        const id = state.crm.localInteractionId
        const contactId = contact || state.crm.contact._id

        dispatch(addInteraction(createLocalInteraction({ content: values, type, contact: contactId, id, externalAgent }).next().value))
    })

export const handleUpdateLocalInteraction = createAsyncThunk<void, any>(
    'crm/handleLocalInteractions',
    async (data, { dispatch }) => {
        const { values, contact, type, id } = data || {}
        dispatch(updateInteraction({ content: createLocalInteraction({ content: values, type, contact, id }).next().value }))
    })

export const handleApplyEvents = createAsyncThunk<void, any>(
    'crm/handleApplyEvents',
    async (events, { dispatch }) => {
        if (events) {
            for (let event of events) dispatch(handleUpdate(event))
        }
    })