import { Button, CircularProgress, Collapse, Drawer, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, ListItem, Modal, OutlinedInput, Paper, TextField, Typography } from "@mui/material"
import {Add as AddIcon, Camera, CropFree, Delete, QrCodeScanner} from '@mui/icons-material'
import { useCallback, useState } from "react"
import dynamic from 'next/dynamic'
// import BarCodeTest from './QRScanner'
import AdvancedModal from "../../../core/AdvancedModal"
import DrawerClose from "../../../core/DrawerClose"
import BarcodeScanner from "./QRScannerPRO"

const DynamicBaraCodeScanner = dynamic(() => import('./QRScanner'), { 
    ssr: false,
    loading: () => <CircularProgress />
})

interface subLineProps {
    fields: any[],
    values: any,
    setValues: (values:any) => void
}


const SubLine =  ({values, setValues}: subLineProps) => {

    const [modalScanner, setModalScanner] = useState<false | number>(false)
    const [code, setCode] = useState<string>()


    const handleChange = useCallback((newValue, index) => {
        setValues((prev) => {
            const newValues = [...prev]
            newValues[index] = newValue
            return newValues
        })
    }, [setValues])

    const handleAdd = useCallback(() => {
        setValues((prev) => {
            const newValues = [...prev]
            newValues.push({
                code: '',
            })
            return newValues
        })
    }, [setValues])

    const removeItemByIndex = useCallback((index) => {
        setValues((prev) => {
            const newValues = [...prev]
            newValues.splice(index, 1)
            return newValues
        })
    }, [setValues])

    const openCode = useCallback((num:number) => {
        setModalScanner(num)
    }, [setModalScanner])


    return(
        <Paper variant="outlined" sx={{ mb: 2, borderRadius: '8px', mt:0 }} >
            <DrawerClose title={"Codigo "+(typeof modalScanner === "number" ? modalScanner+1 : 1)} open={typeof modalScanner === "number"} setOpen={() => setModalScanner(false)} 
            
            bottomChildren={
                code ? (
                    <Button
                    variant="contained"
                    onClick={() => {
                        handleChange({code}, 0)
                        setModalScanner(false)
                        setCode(undefined)
                    }}
                    startIcon={<CropFree />}
                    fullWidth

                    >
                         ({code})
                    </Button>
                ) : (

                    <Button
                    variant="contained"
                    startIcon={<QrCodeScanner />}
                    disabled
                    fullWidth
                    >
                         Escanea un codigo
                    </Button>
                )
            }
            >
            <Grid container sx={{justifyContent: 'center', overflowY: 'hidden'}}>
                <Grid  item sx={{justifyContent: 'center', width: 640, heigth:480, alignItems: 'center', display: 'flex', mt: 2}}>
                    {/* <DynamicBaraCodeScanner
                        onCode={(code) => {
                            setCode(code)
                        }}
                    /> */}
                    <BarcodeScanner onCode={
                        (code) => {
                            setCode(code)
                        }
                    }/>
                </Grid>
            </Grid>
            </DrawerClose>
            <ListItem sx={{ py: 2 }}>
                <Grid container>
                    <Grid container item spacing={2}>
                       <Grid item xs={12} sx={{display: 'flex', direction: 'row'}} >
                        <Typography fontWeight={600} fontSize={20}>Prepagos</Typography>
                        <Button
                        sx={{ml: 2}}
                        endIcon={<AddIcon />}
                        onClick={handleAdd}
                        >
                            Agregar
                        </Button>
                       </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        {values.map((value, index) => {
                            return(
                                <Grid item xs={6} mt={1}>
                                   <FormControl variant="outlined" fullWidth required={true}>
                                        <InputLabel htmlFor="outlined-adornment-code">Codigo {index+1}</InputLabel>
                                        <OutlinedInput
                                        fullWidth
                                        id="outlined-adornment-code"
                                        label={`Codigo ${index+1}`}
                                        value={value.code}
                                        onChange={(e) => handleChange({code: e.target.value}, index)}
                                        name="code"
                                        required={true}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                onClick={() => openCode(index)}
                                                >
                                                    <QrCodeScanner />
                                                </IconButton>
                                                {index != 0 && (
                                                <IconButton
                                                onClick={() => removeItemByIndex(index)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                                )}
                                            </InputAdornment>
                                        }
                                        />
                                   </FormControl>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </ListItem>
            </Paper>
        
    )

}

export default SubLine