import { stringToUppercase } from "../..";
import { AppearanceConfig, getAppearance } from "@/helpers/featureFlagHelpers"

export const getProfile = ({ interaction, currentAgent, prospect }) => {
    const origin = interaction?.content?.origin || 'agent'
    if (!interaction?.agent && origin === 'agent') return 'bot'

    const { name, lastname, photo }: any = currentAgent || {}
    const { photo: photoAgent = '', name: nameAgent = '', lastname: lastnameAgent = '' } = interaction?.agent || {};

    const initialsOfagent = `${stringToUppercase(nameAgent[0])}${stringToUppercase(lastnameAgent[0])}`
    const initialCurrentAgent = `${stringToUppercase(name[0])}${stringToUppercase(lastname[0])}`

    const disableContactPhoto = getAppearance<boolean>(AppearanceConfig.DISABLE_CONTACT_PHOTO, false)

    if (origin === "agent") {
        return photoAgent
            || (!initialsOfagent && photo)
            || (initialsOfagent
                || initialCurrentAgent)
    }

    const { photo: photoUser, firstName, lastName } = prospect;

    if (photoUser && !disableContactPhoto) return photoUser;
    return `${stringToUppercase(firstName?.[0])}${stringToUppercase(lastName?.[0])}`
}
