import { fetcher } from "@/helpers/fetcher";
import { formatSearchParams } from "@/helpers/formatSearchParams";
import { role } from "@/models/User";

export interface GetUserValue {
    name: string,
    lastname: string,
    photo?: string,
    email: string,
    phone: string,
    _id: string,
    roles: role[]
};

export const getUsersList = (params: { [key: string]: any }) => {
    const searchParams = formatSearchParams(params);
    return fetcher<{ users: GetUserValue[] }>({
        url: `/company/users?${searchParams}`,
        defaultContentType: true
    })
}