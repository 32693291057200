import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useEffect, useState } from "react"
import { set } from 'lodash';

interface DialogSeeShortcutProps {
    open: null | string
    onClose: (open: boolean) => void
}

export const DialogSeeShortcut = ({ open, onClose }: DialogSeeShortcutProps) => {

    return (
        <Dialog open={!!open} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[850%] w-[85%] h-[85%]">
                <iframe src={open} className="w-full h-full rounded" />
            </DialogContent>
        </Dialog>
    )
}
