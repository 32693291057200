let defaultColors = [
    '#ef4444',
    '#84cc16',
    '#f59e0b',
    '#f97316',
    '#06b6d4',
    '#d946ef',
    '#f43f5e',
    '#8b5cf6',
    '#6d28d9',
    '#fde047',
    '#a3e635',
];

const getColorsFromTheAlphabet = ({ colors = defaultColors }: any = {}): Record<string, string> => {

    let dictionary: Record<string, string> = {};

    for (let i = 0; i < 26; i++) {
        let letter = String.fromCharCode(65 + i);
        let color = colors[i % colors.length];
        dictionary[letter] = color;
    }

    return dictionary
}

export const colorsByAlphabet = getColorsFromTheAlphabet()

export default getColorsFromTheAlphabet;
