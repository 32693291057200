import { Button } from "@/components/ui/button"
import { CardTitle } from "@/components/ui/card"
import { CircularProgress, Tooltip } from "@mui/material"
import { ArrowDownToLine, FileText } from "lucide-react"

export default function FileInsideBubble({ media, fileName, origin }) {
    return (
        <Tooltip title={fileName} placement="top">
            <a href={media ? media : undefined} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                <Button className={`w-full h-full py-3 ${origin === 'agent' ? ' bg-[#ffffff91] hover:bg-[#ffffff91]' : 'bg-white hover:bg-white'} text-[#6B7280]`}>
                    <div className="flex w-full items-center justify-between  gap-2">
                        <div className="text-start truncate w-full flex items-center gap-2">
                            <FileText size={23} className="shrink-0"/>
                            <CardTitle className="font-normal  text-ellipsis overflow-hidden whitespace-nowrap text-[16px]">{fileName || 'File'}</CardTitle>
                        </div>
                        <Button size={'icon'} variant={'ghost'} className="rounded-full hover:text-[#6B7280] w-[28px] h-[28px] border border-[#6B7280] b shrink-0">
                            {media
                                ? <ArrowDownToLine size={15} />
                                : <CircularProgress size={15} />}
                        </Button>
                    </div>
                </Button>
            </a>
        </Tooltip>
    )
}
