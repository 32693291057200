import { authenticate, getCookie, removeCookie, setCookie } from "@/actions/auth"
import { changeAccount, getLinkedAccounts } from "@/actions/user"
import { useRouter } from "next/router"
import { useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { isAuth } from "@/actions/auth"

export default function useChangeAccount(enabled: boolean) {
    const { data, isLoading } = useQuery(
        {
            enabled,
            queryKey: ['linked-accounts-change', isAuth()?._id],
            queryFn: getLinkedAccounts
        })
    const accounts = useMemo(() => (data && data.accounts) || [], [data])
    const route = useRouter()

    const handleChangeAccount = async (id: string) => {
        try {
            const result = await changeAccount(id)
            if (result) {
                if (result.isRealUser) {
                    removeCookie('real_user_token')
                } else {
                    setCookie("real_user_token", getCookie(getCookie('real_user_token') ? 'real_user_token' : 'token'));
                }

                authenticate(result, () => {
                    if (Object.keys(route.query).length > 0) {
                        route.push(route.pathname);
                        setTimeout(() => window.location.reload(), 100);
                        return;
                    }

                    route.push('/');
                    route.reload();
                });
            }
        } catch {
            console.log('Error')
        }
    }

    return {
        handleChangeAccount,
        accounts,
        isLoadingFetch: isLoading
    }
}
