import { Button } from '@/components/ui/button'
import validatePhoneNumber from '@/lib/validatePhoneNumber'
import { MessagesSquare } from 'lucide-react'
import { useRouter } from 'next/router'
import React, { ChangeEvent } from 'react'
import TransferContactDialog from '../Modal/TransferContactDialog'
import ContactPhoneInput from './ContactPhoneInput'
import { DialogTakeContact } from './DialogTakeContact'
import { UnifyContactAlert } from '../Modal/UnifyContactAlert'

export default function PhoneItem({
    field,
    index,
    contextForm,
    lastPhoneError,
    handleAddArray,
    placeholder,
    handleRemoveArray,
    user,
    markAsUpdatedFields,
    onCLoseSheet,
    state,
    handleSetPhoneIndex,
    handleErrorPhoneNumber,
    countryCode,
    isRequired = false,
    enableInternalPhoneNumbers = false,
    metadata
}) {
    const [createSubConversation, setCreateSubConversation] = React.useState<string | false>(false)
    const [openTakeContactModal, setOpenTakeContactModal] = React.useState<boolean>(false);
    const [openUnifyContact, setOpenUnifyContact] = React.useState<string | false>(false);

    const router = useRouter();
    const value = field.value;

    const realPhoneNumber = React.useMemo(() => {
        const validate = validatePhoneNumber(value, countryCode);
        if (!validate || !(validate && validate.valid)) return '';
        return !enableInternalPhoneNumbers ? validate.number.toString() : `${validate.number}-${metadata?.extensionNumber}`
    }, [value, countryCode]);

    const phoneServerStatus = lastPhoneError.get(realPhoneNumber) || {};
    const inputError = contextForm.getFieldState(`phones.${index}`).error?.message;

    const { itsMine, isObtainable, contactId, subConversation, unifyContacts } = phoneServerStatus

    const handleGoToContact = (id: string) => {
        onCLoseSheet && onCLoseSheet()
        router.push(`contact?inbox=me&&id=${id}`)
    }

    const handleCreateSubConversation = () => setCreateSubConversation(contactId);

    const handleUnifyContacts = () => setOpenUnifyContact(contactId);

    const onChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        markAsUpdatedFields();
        
        if (!!inputError) {
            contextForm.clearErrors(`phones.${index}`);
        };

        field.onChange(newValue)
        handleErrorPhoneNumber()
        handleSetPhoneIndex(index)
    }

    return (
        <>
            <div className="flex flex-col gap-2 items-center relative">
                <ContactPhoneInput
                    onChange={onChangeInputValue}
                    required={isRequired}
                    label={
                        index === 0
                            ? `${placeholder || 'Teléfono'} principal`
                            : `${placeholder || 'Teléfono'} ${index + 1}`
                    }
                    value={value}
                    removeIcon={index !== 0}
                    activeState={true}
                    state={state}
                    formControl={true}
                    onIconPress={
                        index === 0
                            ? handleAddArray
                            : handleRemoveArray
                    }
                />
                {
                    (isObtainable && inputError) && <PhoneActionButton
                        onClick={() => setOpenTakeContactModal(true)}
                    >
                        Obtener contacto
                    </PhoneActionButton>
                }
                {
                    (itsMine && inputError) && <PhoneActionButton
                        onClick={() => handleGoToContact(contactId)}
                    >
                        Ir a mi contacto
                    </PhoneActionButton>
                }
                {
                    subConversation && <PhoneActionButton
                        variant={'outline-primary'}
                        onClick={handleCreateSubConversation}
                    >
                        <MessagesSquare size={18} />
                        Crear sub conversación
                    </PhoneActionButton>
                }
                {unifyContacts && <PhoneActionButton variant='outline-primary' onClick={handleUnifyContacts}>
                    Unificar contacto
                </PhoneActionButton>
                }
            </div>
            {
                openTakeContactModal && <DialogTakeContact
                    contactId={contactId}
                    userId={user._id}
                    open={openTakeContactModal}
                    onClose={() => setOpenTakeContactModal(false)}
                />
            }
            {
                !!createSubConversation && <TransferContactDialog
                    type={'clone'}
                    open={createSubConversation}
                    currentUser={true}
                    onClose={() => setCreateSubConversation(false)}
                />
            }
            {!!openUnifyContact && <UnifyContactAlert open={openUnifyContact} onClose={() => setOpenUnifyContact(false)} />}
        </>
    )
}

export function PhoneActionButton({ onClick, children, variant = 'secondary', ...rest }) {
    return <Button
        type="button"
        {...rest}
        variant={variant as any}
        className={`flex items-center gap-2 w-full`} onClick={() => onClick()}>
        {children}
    </Button>
}