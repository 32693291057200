import {
    CommandGroup
} from "@/components/ui/command";
import PupoverWithSelectItem, { CustomCommandItem } from "@/components/ui/pupover-with-select-item";
import { getFilteredStatusTags } from "@/helpers/tagColorToPalette";
import { changeStatusTag } from "@/redux/slices/thunks/crm";
import "@/styles/scrollModify.css";
import { Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PecilEdit } from "../Bubble/Icons/Crm";
import StatusTagCircle from "./status-tag-circle";
import React from "react";

interface PuppoverStatusTagChangeProps {
    align?: 'start' | 'end' | 'center'
    statusTag: string
    CustomPopoverTrigger?: JSX.Element
    id: string
}

export default function PuppoverStatusTagChange({
    statusTag,
    id,
    align = 'end',
    CustomPopoverTrigger
}: PuppoverStatusTagChangeProps) {
    const [searchValue, setSearchValue] = useState("")
    const [lastTag, setLastTag] = useState<null | string>()
    const tags = getFilteredStatusTags();

    const dispatch = useDispatch()

    useEffect(() => {
        if (!lastTag) setLastTag(statusTag)
    }, [statusTag])

    const handleChangeTag = (code: string) => dispatch(changeStatusTag({ id, status: code }))

    return (
        <>
            <PupoverWithSelectItem
                handleSearch={(value: string) => setSearchValue(value)}
                searchValue={searchValue}
                align={align}
                emptyMessage='Sin etiquetas'
                placeholder='Etiqueta...'
                Trigger={
                    CustomPopoverTrigger || <button className="ml-1">
                        <PecilEdit className="w-[12px] h-[12px] fill-slate-500" />
                    </button>
                }
                CommandListContent={
                    <CommandGroup className="px-0">
                        {
                            statusTag && (
                                <CustomCommandItem
                                    itemLeft={<span>
                                        <Trash2 size={14} />
                                    </span>}
                                    onChange={() => handleChangeTag('')}
                                    label={'Eliminar etiqueta'}
                                    value={'delete-status-tag-button-99234'}
                                    isChecked={false}
                                />
                            )
                        }
                        {
                            tags.filter((tag) => tag.name.toLowerCase().includes(searchValue.toLowerCase()))
                                .map(({ code, name }) => (
                                    <CustomCommandItem
                                        value={code}
                                        itemLeft={(
                                            <div className="flex items-center gap-1">
                                                <StatusTagCircle code={code} />
                                            </div>
                                        )}
                                        onChange={() => handleChangeTag(code)}
                                        label={name}
                                        key={code}
                                        isChecked={statusTag == code}
                                    />
                                ))
                        }
                    </CommandGroup>
                }
            />
        </>
    )
}
