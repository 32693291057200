import { Alert, Avatar, Box, Button, Card, CardContent, CardHeader, Chip, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Modal, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import moment from "moment"
import ReminderModal from '../Modal/ManualReminder'
import TitleButton from "../../core/TitleButton"
import { useState } from "react"
import { CalendarMonth, Cancel, Close, Schedule } from "@mui/icons-material"
import { remind } from "../../../actions/crm/contact"
import mixpanel from "mixpanel-browser"
import { useRouter } from "next/router"

moment.locale('es')

interface ArchiveModalInterface{
    onClose: () => void,
    id: string,
    type: 'close' | 'long'
}



const ReminderFast = ({onClose, id, type = 'close'}:ArchiveModalInterface) => {
    const [modal, setModal] = useState<boolean>(false)
    const [error, setError] = useState<string>("")



    const handleSubmit = (date:Date) => () => {
        let value = date
        remind(id, value).then((response) => {
            if(!response) return onClose()
            if(response.error) {
                mixpanel.track('Reminder created Error', {contactId: id, date: value, error: response.error})
                return onClose()
            }else{
                mixpanel.track('Reminder created', {contactId: id, date: value})
            }
            setModal(false)
            onClose()
        })
    }

    const router = useRouter()

    return (
        <Grid container direction={"column"}>
            <Grid item container>
                <IconButton onClick={onClose} sx={{p:0.5}}>
                    <Close sx={{fontSize: '22px'}} />
                </IconButton>
                <Typography ml={1} mt={0.5}>Selecciona una fecha para recordarte el contacto</Typography>
                </Grid>
                <Grid container item direction={"row"} justifyContent={"center"} >
                    {type == 'close' ? (<>
                    <TitleButton onClick={handleSubmit(moment().add(30,'minute').toDate())} title={"30m"} subtitle={<>En 30min <br/>{moment().add(30,'minute').format('LT')}</>} sx={{width: '120px'}}/>
                    <TitleButton onClick={handleSubmit(moment().add(1,'hour').toDate())} title={"1h"} subtitle={<>En 1 hora <br/>{moment().add(1,'hour').format('LT')}</>} sx={{width: '120px'}}/>
                    <TitleButton onClick={handleSubmit(moment().add(3,'hour').toDate())} title={"3h"} subtitle={<>En 3 horas <br/>{moment().add(3,'hour').format('LT')}</>} sx={{width: '120px'}}/>
                    <TitleButton onClick={handleSubmit(moment().add(1,'day').toDate())} title={"1d"} subtitle={moment().add(1,'day').format('LLL')} sx={{width: '120px'}}/>
                    <TitleButton onClick={handleSubmit(moment().add(2,'day').toDate())} title={"2d"} subtitle={moment().add(2,'day').format('LLL')} sx={{width: '120px'}}/>
                    <TitleButton onClick={handleSubmit(moment().add(3,'day').toDate())} title={"3d"} subtitle={moment().add(3,'day').format('LLL')} sx={{width: '120px'}}/>
                    </>): (<>
                        <TitleButton onClick={handleSubmit(moment().add(30,'day').toDate())} title={"30d"} subtitle={`${moment().add(30,'day').format('LLL')}`} sx={{width: '120px'}}/>
                        <TitleButton onClick={handleSubmit(moment().add(60,'day').toDate())} title={"60d"} subtitle={`${moment().add(60,'day').format('LLL')}`} sx={{width: '120px'}}/>
                        <TitleButton onClick={handleSubmit(moment().add(90,'day').toDate())} title={"90d"} subtitle={`${moment().add(90,'day').format('LLL')}`} sx={{width: '120px'}}/>
                        <TitleButton onClick={handleSubmit(moment().add(6,'month').toDate())} title={"6m"} subtitle={moment().add(6,'month').format('LLL')} sx={{width: '120px'}}/>
                        <TitleButton onClick={handleSubmit(moment().add(1,'year').toDate())} title={"1y"} subtitle={moment().add(1,'year').format('LLL')} sx={{width: '120px'}}/>
                        <TitleButton onClick={handleSubmit(moment().add(2,'year').toDate())} title={"2y"} subtitle={moment().add(2,'year').format('LLL')} sx={{width: '120px'}}/>
                    </>)}
                    <TitleButton onClick={() => {
                        setModal(true)
                    }} title={<Schedule sx={{fontSize:"28px"}}/>} subtitle={"Asignar manualmente"} sx={{width: '100px'}}/>

                    <TitleButton onClick={() => {
                        router.push(`/contact/${id}/calendar`)
                    }} title={<CalendarMonth sx={{fontSize:"28px"}}/>} subtitle={"Asignar en calendario"} sx={{width: '100px'}}/>
            </Grid>

            <ReminderModal
                modal={modal && id}
                setModal={() => {
                    setModal(false)
                    onClose()
                }}
            />
        </Grid>
    )
}

export default ReminderFast