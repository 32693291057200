import { Button } from "@/components/ui/button";
import { MdOutlineSlowMotionVideo } from "react-icons/md";

export default function StartStep({ handleNextStep }) {
    return <>
        <div className="flex-1 py-16  flex flex-col gap-2 p-4 pl-16 justify-between h-full">
            <div>
                <img src="./logo.png" className="w-[90px] mb-6" />
                <h1 className="font-bold text-black flex-col flex leading-[42px] text-[40px]">
                    <span>Nueva</span>
                    <span>actualización</span>
                </h1>
                <p className="text-slate-500 mt-4 text-[15px] leading-[25px] font-sans max-w-[70%] font-medium">
                    Vuelve a descubrir Ventia con un nuevo diseño y funcionalidades para mejorar tu flujo de trabajo
                </p>
            </div>
            <div>
                <Button className="" onClick={() => handleNextStep()}>
                    <MdOutlineSlowMotionVideo size={24} className="mr-2.5" />
                    Ver video
                </Button>
            </div>
        </div>
        <div className="flex-1 hidden md:flex h-full items-center justify-center pl-10  pr-16">
            <div className="w-full h-[65%] bg-slate-200 rounded-[5px] overflow-hidden relative shadow-sm">
                <img src="./mockupOne.webp" alt="image resentation od new version" className="w-full h-full object-cover" />
            </div>
        </div>
    </>
}