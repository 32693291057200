

import { Button } from '@/components/ui/button';
import SimpleTooltip from '@/components/ui/simple-tooltip';
import { setAction, setEditInteraction } from '@/redux/slices/crm';
import { Pencil, XIcon } from 'lucide-react';
import { useDispatch } from 'react-redux';

interface EditMessageCardProps {
    interaction?: any
}

export default function EditMessageCard({ interaction }: EditMessageCardProps) {

    const dispatch = useDispatch();

    const scrollIntoMessage = () => {
        const message = document.getElementById(interaction._id)
        if (message) message.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    const onCancelEditMode = () => {
        dispatch(setAction("select"))
        dispatch(setEditInteraction(null))
    };

    return (
        <div
            className={`p-2.5 flex mx-3 justify-between bg-white items-center mb-3 border-l-4 rounded-lg border-l-blue-600 border cursor-pointer`}
            onClick={scrollIntoMessage}
        >
            <div className="flex gap-4 items-center">
                <Pencil size={20} className="text-blue-600" />
                <div>
                    <h5 className="font-medium text-gray-900 text-[11px]">Edita el mensaje</h5>
                    <p className="text-[12px] font-medium text-gray-600">
                        {interaction.content.message}
                    </p>
                </div>
            </div>

            <SimpleTooltip content="Descartar">
                <Button variant="outline" size={'icon'} className="w-7 h-7" onClick={onCancelEditMode}>
                    <XIcon size={14} />
                </Button>
            </SimpleTooltip>
        </div>
    )
}
