import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog";
import { AlertTriangle } from "lucide-react";
import { BtnNextStep } from "../buttons/BtnNextStep";
import { Button } from "../ui/button";
import { ReactNode } from "react";

export interface AlertInformationProps {
    open?: boolean | Record<string, any> | string | undefined | null,
    onOpenChange?: (value: boolean) => void
    title: string
    description?: string
    labelBtnDelete?: string | ReactNode
    labelBtnCancel?: string
    loading?: boolean
    icon?: JSX.Element
    handleConfirm: () => void
    handleCancel: () => void
    alertCircle?: boolean
}

export default function AlertInformation({
    open,
    onOpenChange,
    title,
    description,
    labelBtnCancel = 'Cancelar',
    labelBtnDelete = 'Continuar',
    icon,
    handleConfirm,
    handleCancel,
    alertCircle = true,
    loading
}: AlertInformationProps) {
    return (
        <AlertDialog open={!!open} onOpenChange={onOpenChange}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <div className="flex items-start gap-4">
                        {
                            alertCircle && <div className="w-[42px] hidden md:flex shrink-0 h-[42px] items-center justify-center bg-primary/20 rounded-full">
                                {
                                    icon || <AlertTriangle className="text-primary" size={25} />
                                }
                            </div>
                        }
                        <div className="flex flex-col gap-1">
                            <AlertDialogTitle className="text-gray-900 text-[19px]">
                                {title}
                            </AlertDialogTitle>
                            {
                                description && <AlertDialogDescription className="text-gray-500 text-[14px]">
                                    {description}
                                </AlertDialogDescription>
                            }
                        </div>
                    </div>
                </AlertDialogHeader>
                <AlertDialogFooter className="gap-y-2">
                    <Button variant={'outline-primary'} onClick={handleCancel}>
                        {labelBtnCancel}
                    </Button>
                    <BtnNextStep
                        variant={'default'}
                        className="bg-primary duration-75"
                        onClick={handleConfirm}
                        hiddenArrow
                        loading={loading}
                    >
                        {labelBtnDelete}
                    </BtnNextStep>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
