import userNotification from '@/actions/userNotification';
import { useToggle } from '@/hooks/useToggle';
import { ActionToTrack, NotificationMultimediaType, UserBannerNotification } from '@/interfaces/Interface';
import { useMutation } from '@tanstack/react-query';
import { motion } from "framer-motion";
import { CirclePlay, Minus } from 'lucide-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import ModalViewIframe from './ModalViewIframe';

interface BannerNotificationProps {
    notification: UserBannerNotification;
    onCloseNotification: () => void;
    isPreviewMode?: boolean
}

export default function BannerNotification(props: BannerNotificationProps) {
    const [openViewContentNotification, toggleOpenViewContentNotification] = useToggle<boolean>(false);
    const [isClosing, setIsClosing] = useState(false);

    const { notification, onCloseNotification, isPreviewMode = false } = props
    const { setting, viewed } = notification;
    const { action, metadata } = setting;
    const { title, multimediaType, subTitle, thumbnailURL } = metadata;
    const { redirectType, redirectURL } = action;

    const router = useRouter();

    const { mutate } = useMutation({
        mutationFn: (actions: ActionToTrack[]) =>
            userNotification.trackAction(notification._id, actions),
    });

    useEffect(() => {
        !isPreviewMode && !viewed && mutate(['viewed']);
    }, [])

    const handleClickNotification = () => {
        !isPreviewMode && mutate(['opened']);

        if (redirectType === 'redirect') {
            setIsClosing(true);
            return router.push(redirectURL);
        }
        toggleOpenViewContentNotification();
    };

    const handleCloseNotification = () => {
        !isPreviewMode && mutate(['closed']);
        setIsClosing(true);
    };

    const handleAnimationComplete = () => {
        if (isClosing) onCloseNotification();
    };

    const handleCloseIframeModal = () => {
        toggleOpenViewContentNotification();
        setIsClosing(true);
    };

    return (
        <>
            <ModalViewIframe
                contentUrl={redirectURL}
                open={openViewContentNotification}
                onOpenChange={handleCloseIframeModal}
            />
            <motion.div
                className="flex cursor-pointer items-center justify-between py-2 md:py-2.5 bg-gradient-to-r from-primary 2% via-primary 50% to-blue-200 106% md:px-5 px-2 hover:underline text-white"
                onClick={handleClickNotification}
                initial={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.2 }}
                onAnimationComplete={handleAnimationComplete}
                animate={!isClosing ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
            >
                <div className="bg-white hidden md:flex rounded-md  items-center justify-center h-8 w-8">
                    <img
                        src="/logo-min.png"
                        alt="Ventia logo minimized"
                        className={'w-7 shrink-0'}
                    />
                </div>
                <div className="flex md:items-center md:flex-row flex-col md:gap-3">
                    {thumbnailURL && (
                        <NotificationThumbnail multimediaType={multimediaType} url={thumbnailURL} />
                    )}
                    <h3 className="md:text-[16.5px] text-[13px] font-medium text-white">
                        {title}
                    </h3>
                    <h4 className="md:text-[13px] text-[11px] font-medium text-white">
                        {subTitle}
                    </h4>
                </div>
                <button
                    className="w-9 h-9 flex items-center rounded-md hover:bg-blue-100 duration-100 justify-center text-white"
                    onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        handleCloseNotification();
                    }}
                >
                    <Minus className='text-[20px] md:text-[33px]' />
                </button>
            </motion.div>
        </>
    );
}

export function NotificationThumbnail({
    url,
    multimediaType
}: {
    url: string,
    multimediaType?: NotificationMultimediaType
}) {
    return (
        <div className="w-[64px] hidden md:flex overflow-hidden h-[34px] relative items-center justify-center">
            {multimediaType == 'video' ? <CirclePlay size={20} className='text-white absolute' /> : null}
            <img src={url} className="w-full h-full object-cover" />
        </div>
    )
};