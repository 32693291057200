import { getCompanyUsers } from "@/actions/admin"
import { authenticate, getCompany, getCookie, isAuth, setCookie, signin_push } from "@/actions/auth"
import RolChip from "@/components/company/RolChip"
import { Avatar } from "@/components/ui/avatar"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { Loader2, User } from "lucide-react"
import "moment/locale/es"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"


interface CompanyModalProps {
    dialogTrigger: React.ReactElement
}

export const MyCompanyModal = ({ dialogTrigger }: CompanyModalProps) => {
    const [id, setId] = useState<string>()
    const [searchText, setSearchText] = useState('');
    const [open, setOpen] = useState(false)

    const userId = getCompany()._id

    const { data: allUsers, isLoading } = useQuery({
        queryFn: () => getCompanyUsers(userId),
        queryKey: ['get-my-company-users'],
        enabled: !!userId
    })

    const filteredUsers = searchText
        ? allUsers?.data.filter((user) => {
            const searchWords = searchText.toLowerCase().split(' ');

            return searchWords.every((word) =>
                [user.name, user.lastname, user.email].some((field) => field.toLowerCase().includes(word))
            );
        })
        : allUsers?.data;

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Dialog open={open} onOpenChange={(isOpen) => {
            setSearchText('')
            setOpen(isOpen)
        }}>
            <DialogTrigger asChild>
                {dialogTrigger}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Cambiar cuenta</DialogTitle>
                    <DialogDescription>
                        Selecciona el usuario al que deseas cambiar de cuenta
                    </DialogDescription>
                    <div className="flex gap-1 items-center pt-2">
                        <Input
                            type="text"
                            placeholder="Buscar contacto..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            className="p-2 border rounded focus-visible:ring-0 bg-[#F5F5F5] text-[12px]"
                        />
                    </div>
                    {isLoading ? <Loader2 className="mx-auto mt-5 animate-spin text-primary" size={30} /> : (
                        <div className="flex flex-col gap-2 max-h-[350px] overflow-y-auto border p-2 rounded">
                            {filteredUsers && filteredUsers.length > 0 ? (
                                filteredUsers.map((user, index) => {
                                    return (
                                        <div key={index}>
                                            <UserListItem key={index} user={user} onClose={handleClose} />
                                            {index !== filteredUsers.length - 1 && <Separator orientation="horizontal" className="w-full mt-2" />}
                                        </div>

                                    )
                                })
                            ) : (
                                <div className="flex flex-col items-center justify-center">
                                    <span className="text-[#77797d] text-[12px]">No se encontro el usuario que buscabas...</span>
                                </div>
                            )}
                        </div>
                    )}
                </DialogHeader>
            </DialogContent>
        </Dialog>

    )
}

export const UserListItem = ({ user, onClose }) => {

    var route = useRouter()

    const loginPush = (email) => (e) => {
        e.preventDefault();
        signin_push({ email })
            .then((res) => {
                if (res.error) return console.log("error haciendo login");
                if ((isAuth()?.roles ?? []).includes('admin')) setCookie("admin_token", getCookie("token"));
                authenticate(res, () => {
                    onClose();
                    if (Object.keys(route.query).length > 0) {
                        route.push(route.pathname);
                        setTimeout(() => {
                            window.location.reload();
                        }, 100);
                    } else {
                        route.push('/').then(() => {
                            route.reload();
                        });
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div
            className="flex gap-2 hover:bg-slate-100 items-center p-2 cursor-pointer rounded"
            onClick={loginPush(user.email)}
        >
            <Avatar className="flex h-7 w-7 border-2 border-primary items-center justify-center bg-primary">
                <User size={18} strokeWidth={2.5} className="text-white" />
            </Avatar>
            <div className="flex flex-col">
                <span>{`${user.name} ${user.lastname}`}</span>
                <span className="text-[12px] text-slate-400">{user.email}</span>
                <div>
                    {user?.roles?.length > 0 && user.roles.map((role, index) => (
                        <RolChip key={index} rol={role} userType={user?.userType} />
                    ))}
                </div>
            </div>
        </div>
    );
};