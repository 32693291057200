import { cn } from '@/lib/utils';
import dayjs from 'dayjs';
import es from 'dayjs/locale/es';
import { ReceiptText } from 'lucide-react';
import { PropsWithChildren } from 'react';
dayjs.locale(es);

interface SaleBubbleLayoutProps extends PropsWithChildren {
    classNameHeaderContent?: string
    classNameContent?: string
    createdAt: string,
    title: string,
};

export default function SaleBubbleLayout({
    createdAt,
    title,
    children,
    classNameHeaderContent,
    classNameContent
}: SaleBubbleLayoutProps) {
    const formatedCreatedAt = dayjs(createdAt).format('HH:mm');

    return (
        <div className='flex items-center justify-center w-full'>
            <div className={cn('shadow-bubble-phone w-full max-w-[440px] border rounded-md overflow-hidden', classNameContent)}>
                <div className={cn('w-full py-1.5 gap-10 flex items-center text-gray-600 justify-between bg-slate-100 px-4', classNameHeaderContent)}>
                    <ReceiptText size={17} />
                    <h4 className="text-[14.5px] font-medium">
                        {title}
                    </h4>
                    <p className="text-[13px] font-medium">
                        {formatedCreatedAt}
                    </p>
                </div>
                <div className="flex flex-col p-3 px-4 bg-white">
                    {children}
                </div>
            </div>
        </div>
    )
}