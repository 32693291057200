import { Ref, useEffect, useState } from "react"

export default function useOnScreen(ref, dps: Array<any> = []) {

  const [isIntersecting, setIntersecting] = useState(false)
  const [cargo, setCargo] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    if (process.browser && ref && ref.current) {
      setCargo(true)
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }
  }, [ref, ref.current, process.browser, ...dps])

  return isIntersecting ? true : false
}