import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select";
import { DateTimePickerForm } from "@/components/ui/Time-picker/popover-date-time-picker";
import { AdditionalCompanyField } from "@/interfaces/Interface";
import MultipleCompanyGroupField from "./MultipleCompanyGroupFiled";
import MultipleContactField from "./MultipleContactField";
import MultiselectField from "./MultiselectField";

interface AdditionalCompanyFieldItemProps {
    field: AdditionalCompanyField,
    onChangeValue: (value: string | number | Date | string[]) => void,
    value: string | number | Date | string[]
    disabledEdit?: boolean
}

export default function AdditionalCompanyFieldItem({
    field,
    onChangeValue,
    value,
    disabledEdit = false
}: AdditionalCompanyFieldItemProps) {
    const { type, name, code, options = [], required } = field || {}

    switch (type) {
        case 'string': return (
            <Input
                onChange={(e) => onChangeValue(e.target.value)}
                value={value as string}
                required={required}
                disabled={disabledEdit}
                placeholder={name}
            />
        )
        case 'number': return (
            <Input
                onChange={(e) => {
                    const { value } = e.target
                    onChangeValue(Number(value.replace(/[^\d+]/g, '')))
                }}
                value={String(value || '')}
                required={required}
                disabled={disabledEdit}
                placeholder={name}
            />
        )
        case 'select': return (
            <Select disabled={disabledEdit} onValueChange={onChangeValue} value={!value ? undefined : `${value}`}>
                <SelectTrigger className="w-full">
                    <SelectValue placeholder={name} />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        {
                            options.map((option, index) => {
                                return (
                                    <SelectItem value={option} key={`${option}${index}`}>
                                        {option}
                                    </SelectItem>
                                )
                            })
                        }
                    </SelectGroup>
                </SelectContent>
            </Select>
        )
        case 'date': return (
            <DateTimePickerForm
                disabled={disabledEdit as boolean}
                value={value ? new Date(value as Date) : undefined}
                onChangeValue={onChangeValue}
            />
        )
        case 'multiselect': return (
            <MultiselectField
                disabled={disabledEdit}
                onChangeValue={onChangeValue}
                value={value as string[]}
                options={options}
            />
        )
        case 'selectcontact':
        case 'multiplecontact': return (
            <MultipleContactField
                multiple={type === 'multiplecontact'}
                onChangeValue={onChangeValue}
                disabled={disabledEdit}
                value={value}
            />
        )
        case 'multiplecompany':
        case 'selectcompany': return (
            <MultipleCompanyGroupField
                multiple={type === 'multiplecompany'}
                onChangeValue={onChangeValue}
                disabled={disabledEdit}
                value={value}
                field={field}
            />
        )
        default: return <p>Campo adicional no reconocido</p>
    }
}

