import UsersSelect from '@/components/selects/users-select';
import { CustomFormField } from '@/components/ui/custom-form-field';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { UserAvatar } from '@/components/ui/user-avatar';
import useGetUsersList from '@/hooks/useGetUsersList';
import _ from 'lodash';
import { Percent } from 'lucide-react';
import { useMemo } from 'react';
import { SectionPropsBase } from '.';
import { ComissionSchemaType } from '..';
import SplitDealLoaderContent from '../SplitDealLoaderContent';

interface SplitDealProps extends SectionPropsBase { }

export default function SplitDeal({ contextForm }: SplitDealProps) {
    const { users, isLoading: isLoadingGetUsersList } = useGetUsersList({ enabled: true });

    const sellers = useMemo(() => users.filter(({ roles = [] }) => roles.includes('user')), [users]);
    const usersToMap = useMemo(() => _.keyBy(sellers, '_id'), [sellers]);

    const usersValues = contextForm.watch('splitDeal');
    const bounty = contextForm.watch('bounty');
    const bountyCurrency = contextForm.watch('bountyCurrency');

    const splitDealValues = useMemo(() => {
        return [...(usersValues || [])].map((value) => ({ ...value, user: usersToMap[value.user] })).filter((user) => user?.user);
    }, [usersValues, usersToMap, sellers]);

    const ajustSplitDeal = (
        updatedSplitDeal: ComissionSchemaType['splitDeal'],
        index: number = -1
    ) => {
        let finalTotal = updatedSplitDeal.reduce((total, deal) => total + deal.percentage, 0);

        if (index === -1) {
            const equalPercentage = 100 / updatedSplitDeal.length;

            updatedSplitDeal = updatedSplitDeal.map((deal) => ({
                percentage: equalPercentage,
                user: deal.user,
            }));
        } else {
            if (finalTotal > 100) {
                let excess = finalTotal - 100;

                for (let i = 0; i < updatedSplitDeal.length; i++) {
                    if (i !== index && excess > 0) {
                        const reduction = Math.min(updatedSplitDeal[i].percentage, excess);
                        updatedSplitDeal[i] = {
                            percentage: updatedSplitDeal[i].percentage - reduction,
                            user: updatedSplitDeal[i].user,
                        };
                        excess -= reduction;

                        if (excess <= 0) break;
                    }
                }
            }
            else if (finalTotal < 100) {
                let deficit = 100 - finalTotal;

                for (let i = 0; i < updatedSplitDeal.length; i++) {
                    if (i !== index && deficit > 0) {
                        const increase = Math.min(deficit, 100 - updatedSplitDeal[i].percentage);
                        updatedSplitDeal[i] = {
                            percentage: updatedSplitDeal[i].percentage + increase,
                            user: updatedSplitDeal[i].user,
                        };
                        deficit -= increase;

                        if (deficit <= 0) break;
                    }
                }
            }

            finalTotal = updatedSplitDeal.reduce((total, deal) => total + deal.percentage, 0);
            if (finalTotal < 100) {
                for (let i = 0; i < updatedSplitDeal.length; i++) {
                    if (i !== index) {
                        updatedSplitDeal[i] = {
                            percentage: updatedSplitDeal[i].percentage + (100 - finalTotal),
                            user: updatedSplitDeal[i].user,
                        };
                        break;
                    }
                }
            }
        }

        return updatedSplitDeal;
    };

    const onChangeUsers = (users: string[]) => {
        const currentSplitDeal = [...usersValues];

        const splitDealUserMap: Map<string, ComissionSchemaType['splitDeal'][number]> = new Map();
        currentSplitDeal.forEach((user) => splitDealUserMap.set(user.user, user));

        const newSplitDealValue: ComissionSchemaType['splitDeal'] = [];

        users.forEach((userId) => {
            if (splitDealUserMap.has(userId)) {
                newSplitDealValue.push(splitDealUserMap.get(userId)!);
                return;
            };

            newSplitDealValue.push({ user: userId, percentage: 100 });
        });

        if (usersValues.length >= 1) {
            newSplitDealValue.unshift(usersValues[0]);
        };

        contextForm.setValue('splitDeal', ajustSplitDeal(newSplitDealValue));
    };

    const onChangeSplitDealValue = (index: number, newValue: number) => {
        const updatedSplitDeal = [...usersValues];

        updatedSplitDeal[index] = {
            percentage: newValue,
            user: updatedSplitDeal[index].user
        };

        ajustSplitDeal(updatedSplitDeal, index);
        contextForm.setValue('splitDeal', updatedSplitDeal);
    };

    return (
        <div className="flex flex-col gap-4 min-h-[350px]">
            <CustomFormField
                name="splitDeal"
                className='h-max flex-[0]'
                label="Trato dividido con"
                control={contextForm.control}
                fnElement={({ field }) => (
                    <div>
                        <UsersSelect
                            value={(field.value.slice(1, field.value.length + 1)).map(({ user }) => user)}
                            externalUsers={sellers.filter((user) => user._id !== usersValues?.[0]?.user)}
                            externalLoading={isLoadingGetUsersList}
                            enableFetchDefaultData={false}
                            onChangeValue={onChangeUsers}
                        />
                    </div>
                )}
            />

            {
                isLoadingGetUsersList
                    ? <SplitDealLoaderContent />
                    : (
                        <div>
                            <Label className="text-gray-700 text-[16px]">
                                Desglose de la división del trato
                            </Label>

                            <ul className="flex flex-col gap-4 px-2 mt-4 w-full">
                                {
                                    splitDealValues.map(({ user, percentage }, inx) => {
                                        const fullName = [user.name, user.lastname].filter(Boolean).join(' ');
                               
                                        return (
                                            <li key={inx} className='w-full'>
                                                <div className="flex w-full items-center">
                                                    <div className="flex gap-2 mr-7 flex-1 items-center">
                                                        <UserAvatar user={user} className='w-[25px] h-[25px]' />
                                                        <h5 className="text-[14.2px] truncate font-medium text-gray-800">
                                                            {fullName}
                                                        </h5>
                                                        <div className="flex-1 h-[0.5px] border-gray-200 border mx-2 border-dashed" />
                                                        <span className="text-gray-600 text-[13px]">
                                                            {bounty > 0 ? Math.floor(((bounty * percentage) / 100)) : 0}
                                                            {bountyCurrency}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center relative gap-2">
                                                        <Input
                                                            type="text"
                                                            className="w-[80px] pl-2 h-[29px]"
                                                            value={usersValues?.[inx]?.percentage?.toFixed(0) || 0}
                                                            onChange={(e) => {
                                                                let { value } = e.target;
                                                                value = value.replace(/[^0-9]/g, '');
                                                                const currentValue = usersValues[inx].percentage
                                                                if (Number(value) > 100) return;

                                                                if (currentValue === 0 && value !== '') {
                                                                    return onChangeSplitDealValue(inx, Number(value));
                                                                };

                                                                onChangeSplitDealValue(inx, Number(value.replace(/^0+/, '') || 0));
                                                            }}
                                                        />
                                                        <Percent size={15} className="text-gray-400 absolute right-2.5" />
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <Separator className="w-full my-3" />
                            <div className="w-full flex items-center  justify-between">
                                <h5 className="text-[17px] truncate font-medium text-gray-800">
                                    Total
                                </h5>
                                <div className="flex-1 h-[0.5px] border-gray-200 border mx-4 border-dashed" />
                                <span className="text-gray-600 text-[13px]">
                                    {bounty || 0}
                                    {bountyCurrency}
                                </span>
                                <div className="w-[80px] flex ml-7 items-center justify-end">
                                    <p className="text-gray-600 text-[16px]">
                                        100%
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    )
};
