import { getRequestSaleList } from '@/actions/crm/request-sale'
import { setContactRequest } from '@/redux/slices/crm'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'

export default function useGetContactRequest() {
    const [lastContactId, setLastContactId] = useState('')
    const prospect = useSelector((state: any) => state.crm.contact)
    const requests = useSelector((state: any) => state.crm.contactRequest)
    const contactId = prospect._id

    const dispatch = useDispatch()

    let requestList = useQuery({
        queryKey: ['request-sale-list', contactId],
        queryFn: () => getRequestSaleList(contactId),
        enabled: (lastContactId !== contactId) || requests.length === 0
    })

    useEffect(() => {
        if (requestList.data?.list) {
            dispatch(setContactRequest(requestList.data?.list))
        }
    }, [requestList])

    useEffect(()=>{
        setLastContactId(prospect._id)
    },[prospect])

    return requests || []
}
