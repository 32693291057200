import { IInstance } from '@/actions/training'
import { CardDescription, CardTitle } from '@/components/ui/card'
import { Dialog, DialogContent } from "@/components/ui/dialog"
import { formatTime } from '@/helpers/formatSeconds'
import { useToggle } from '@/hooks/useToggle'
import { CardMedia } from '@mui/material'
import dayjs from 'dayjs'
import { Calendar, Clock3 } from 'lucide-react'
import React from 'react'
import { Skeleton } from '../ui/skeleton'
import useInstance from '@/hooks/useInstance'
import { IconPlayNotification } from '../Header/CardNotification'

function CardOfClass({ information }: { information: IInstance, type: 'tutorials' | 'instances' }) {
    const [value, _, setOpenView] = useToggle<any>(false)
    const { date, duration, title, description, miniature, type: typeOfInstance } = information

    const { handleUpdateLastInteraction } = useInstance({
        options: {
            enabled: false
        },
        type: information.type
    })

    const handleOpenAdnUpdateLastInteraction = () => {
        handleUpdateLastInteraction(information._id)
        setOpenView(information)
    }

    return <>
        <div onClick={() => handleOpenAdnUpdateLastInteraction()} className='cursor-pointer'>
            <figure className='h-full group flex flex-col hover:bg-slate-50 md:px-2 py-3 rounded-sm w-full'>
                <div className='relative cursor-pointer flex aspect-video items-center overflow-hidden '>
                    <img src={miniature} className='w-full h-full object-cover' alt={`miniature of intance ${title}`} />
                    <div className='group-hover:block hidden'>
                        <IconPlayNotification />
                    </div>
                </div>
                <figcaption className='mt-5 flex flex-col flex-grow justify-between'>
                    <div>
                        <CardTitle className='text-[16px] text-slate-800 leading-[1.3]'>{title}</CardTitle>
                        {description && <CardDescription className='leading-[1.7] text-ellipsis text-[14px] my-2'>{description.slice(0, 130)}</CardDescription>}
                    </div>
                    <div className='flex items-center gap-5 text-slate-800 leading-3 mt-3'>
                        {
                            typeOfInstance === 'instance' && <time className='flex items-center text-[13px] gap-1 [&>svg]:w-4 [&>svg]:h-4'>
                                <Calendar />
                                {dayjs(date).format('DD/MM/YYYY')}
                            </time>
                        }
                        <span className='flex items-center text-[13px] gap-1 [&>svg]:w-4 [&>svg]:h-4'>
                            <Clock3 />
                            {formatTime(duration)}
                        </span>
                    </div>
                </figcaption>
            </figure>
        </div>

        <ModalViewClass
            link={value?.linkOfClass}
            onClose={() => setOpenView(false)}
            open={!!value} />
    </>
}

export function ModalViewClass({ open,
    onClose, link }) {
    return open && <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[95%] p-0 h-[90vh] border-none">
            <CardMedia
                component="iframe"
                src={link}
                onKeyDown={(e) => console.log(e)}
                sx={{ border: 0, height: 1, borderRadius: '4px 4px 0px 0px' }} />
        </DialogContent>
    </Dialog>
}

export function CardSkeleton() {
    return <div className='w-full h-full justify-between flex flex-col gap-6 p-2 py-3'>
        <div >
            <Skeleton className='w-full mb-8  h-[130px] rounded-none' />
            <div className='flex flex-col gap-2'>
                <Skeleton className='w-[50%]  h-[6px]' />
                <Skeleton className='w-[70%] mb-4 h-[6px]' />
                <div className='flex flex-col gap-3'>
                    <Skeleton className='w-full  h-[5px]' />
                    <Skeleton className='w-full  h-[5px]' />
                    <Skeleton className='w-full  h-[5px]' />
                    <Skeleton className='w-full  h-[5px]' />
                </div>
            </div>
        </div>
        <div className='flex items-center gap-5'>
            <Skeleton className='w-[30%]  h-[8px]' />
            <Skeleton className='w-[30%]  h-[8px]' />
        </div>
    </div>
}

export default React.memo(CardOfClass)