import { updateContactBotStatus } from "@/actions/crm/contact"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { useToast } from "@/components/ui/use-toast"
import { Contact } from "@/interfaces/crm/contact"
import { cn } from "@/lib/utils"
import { useMutation } from "@tanstack/react-query"
import { Loader2 } from "lucide-react"

export function HandleBotStatusModal({ isActive, open, onClose, contact }: { isActive: boolean, open: boolean, onClose: () => void, contact: Contact }) {
    const { toast } = useToast()

    const { mutate, isPending } = useMutation({
        mutationFn: () => updateContactBotStatus(contact._id, !isActive),
        onSuccess: () => {
            onClose()
        },
        onError: () => {
            toast({
                title: 'Error',
                description: 'No se pudo cambiar el estado del bot',
                variant: 'destructive'
            })
        }
    })

    return (
        <AlertDialog open={open} onOpenChange={onClose}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{!isActive ? 'Activar' : 'Desactivar'} Bot</AlertDialogTitle>
                    <AlertDialogDescription>
                        {!isActive ? '¿Estás seguro de que deseas activar el bot para este contacto?' : '¿Estás seguro de que deseas desactivar el bot para este contacto?'}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancelar</AlertDialogCancel>
                    <AlertDialogAction className={cn(isActive ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600')} disabled={isPending} onClick={() => mutate()}>
                        {isPending && <Loader2 className="w-6 h-6 animate-spin" />}
                        {!isPending && !isActive ? 'Activar' : 'Desactivar'}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
