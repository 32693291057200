export const analyzePassword = (password, lengthGreater = 6) => {
    const result = {
        hasLengthGreaterThan: password.length > lengthGreater,
        hasUpperCase: /[A-Z]/.test(password),
        hasLowerCase: /[a-z]/.test(password),
        hasNumber: /\d/.test(password)
    };
    return {
        ...result,
        isValid: result.hasLengthGreaterThan && result.hasLowerCase && result.hasNumber && result.hasUpperCase
    };
}