import { BtnNextStep } from "@/components/buttons/BtnNextStep"
import { DateTimePickerContent } from "@/components/ui/Time-picker/date-time-picker"
import { Button } from "@/components/ui/button"
import dayjs from "dayjs"
import { Check } from "lucide-react"
import { useEffect, useState } from "react"

export function BottomSheetEditDate({
  isOpen,
  onChange,
  isLoadingBtn = false,
  onConfirmChange
}) {
  const [manualValue, setManualValue] = useState<null | Date>(new Date())
  const [invalidDate, setInvalidDate] = useState(false)

  useEffect(() => {
    setManualValue(new Date(dayjs(isOpen?.[0]).toDate()))
  }, [isOpen])

  const handleConfirmDate = () => {
    if (!invalidDate) {
      onConfirmChange([manualValue, ...isOpen.slice(1, isOpen.length)])
    }
  }

  return (
    <div className="w-full">
      {isOpen && (
        <div
          onClick={() => onChange(null)}
          className="absolute w-full h-full top-0 left-0"
        ></div>
      )}
      <div
        className={`w-full flex justify-between  z-[1] items-end gap-2 absolute py-7 pt-5 px-6 bg-white shadow-2xl rounded-t-2xl ${isOpen
          ? "bottom-0 transform  translate-y-0 bg-red-500"
          : "top-full transform translate-y-full "
          } left-0 transition-transform duration-200 ease-in-out`}
        style={{ boxShadow: "0px 2px 23px rgba(0, 0, 0, 0.2)" }}
      >
        {isOpen && (
          <>
            <DateTimePickerContent
              className='flex items-end gap-2'
              setInvalidDate={setInvalidDate}
              date={manualValue}
              classNameInputDate="flex-col-reverse"
              setDate={(value) => setManualValue(value)}
            />
            <BtnNextStep
              size={"icon"}
              type="button"
              disabled={invalidDate || isLoadingBtn}
              loading={isLoadingBtn}
              aria-label="buttonOfSaveChange"
              onClick={() => handleConfirmDate()}
              className="text-primary hover:text-primary"
              variant={"ghost"}
              hiddenArrow
            >
              <Check />
            </BtnNextStep>
          </>
        )}
      </div>
    </div>
  )
}
