'use client';
import {
    Command,
    CommandItem
} from '@/components/ui/command';
import { cn } from '@/lib/utils';
import '@/styles/scrollModify.css';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import * as React from 'react';
import { searchRegex } from '../../helpers/regex';
import { Badge } from './badge';
import InfiniteScroll from './infinite-scroll';
import { Input } from './input';
import { Skeleton } from './skeleton';
export interface IOption {
    value: any;
    label: string;
    extras?: any
}

export interface InputWithSelectProps {
    disabled?: boolean
    flatArray?: boolean
    placeholder?: string
    options: Array<IOption | string>
    strict?: boolean
    isLoading?: boolean
    value: Array<IOption> | IOption | string
    identifySelectedBy?: string
    className?: string
    infiniteScroll?: {
        hasMore: boolean,
        loader?: React.ReactNode,
        next: () => void,
        dataLength: number,
        loading: boolean
    },
    multiple?: boolean
    classNameInput?: string
    onChangeInputSearch: (string) => void
    onChangeValue: (value: IOption[] | IOption | string | string[] | undefined, realValue?: any[]) => void
    emptyIndicator?: React.ReactNode
    downElements?: {
        render: React.ReactNode,
        onSelectAction: () => void
    }[] | null | undefined | false
    maxElements?: number
    classNameContentList?: string,
    closeWhenSelected?: boolean
    classNameInputContent?: string,
    badgeClassName?: string
    classNameCommand?: string,
    renderValueItem?: (option: IOption) => React.ReactNode,
    autoFocus?: boolean,
    closeWithSelect?: boolean
    renderItem?: (option: IOption) => React.ReactNode
}


function InputWithSelect({
    disabled,
    flatArray = false,
    placeholder,
    options,
    strict = false,
    identifySelectedBy,
    isLoading,
    value,
    multiple = false,
    closeWithSelect = false,
    className,
    classNameCommand,
    infiniteScroll,
    onChangeInputSearch,
    onChangeValue,
    emptyIndicator,
    renderValueItem,
    maxElements = 0,
    closeWhenSelected = false,
    badgeClassName,
    downElements,
    autoFocus = false,
    classNameContentList,
    classNameInput,
    renderItem,
    classNameInputContent
}: InputWithSelectProps) {
    const [selectables, setSelectables] = React.useState<IOption[]>([])
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = React.useState<string>(() => !flatArray && (value as IOption)?.label || '');
    const [inputSearch, setInputSearch] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState<IOption[]>(() => (Array.isArray(value) && multiple) ? value : []);

    const maximumExceeded = multiple && maxElements > 0 && maxElements >= selected.length
    const realValueSelected = [(multiple && Array.isArray(value)) ? value : selected].flat()

    const realInputValue = typeof value === 'string' ? value : inputValue
    const handleChangeInputValue = typeof onChangeValue == 'function' ? onChangeValue : setInputValue

    const regex = searchRegex(realInputValue as string);

    React.useEffect(() => {
        setSelectables((flatArray
            ? Array.from(new Set(options)).filter((value: string) => regex.test(value))
            : options) as IOption[])
    }, [options, flatArray, regex])

    const filteredSelectables = selectables.filter(s => {
        return !realValueSelected.find((v) => identifySelectedBy ? v.value[identifySelectedBy] === s.value[identifySelectedBy] : v.value === s.value)
    })

    const EmptyItem = () => {
        if (!!downElements || !emptyIndicator || isLoading) return undefined;
        if (!isLoading && filteredSelectables.length === 0) return <div className="py-2 text-center text-sm">{emptyIndicator}</div>
    };

    const handleChangeInputSearch = (value: string) => {
        onChangeInputSearch && onChangeInputSearch(value)
        setInputSearch(value)
    }

    const handleOnBlur = () => {
        if (!multiple && value && strict) setInputValue((value as IOption).label || '')
        setOpen(false)
    }

    React.useEffect(() => {
        if (!value) setInputValue('')
    }, [value])

    const handleUnselect = (option: IOption) => {
        if (multiple) {
            let newValues = [...realValueSelected]
            let valueLength = newValues.length

            if (maximumExceeded) {
                newValues[valueLength == 0 ? 0 : valueLength - 1] = option
            } else {
                newValues.push(option)
            }

            handleChangeInputValue(newValues.map(op => op.value) as any, newValues)
            setSelected(newValues)
            setInputValue('')
            handleChangeInputSearch('')
        } else {
            handleChangeInputValue(option.value)
            setInputValue(option.label)
        }

        if (closeWhenSelected) {
            setOpen(false)
            inputRef.current && inputRef.current.blur();
        }
    }

    const deleteSelect = (inx: number) => {
        let values = [...realValueSelected]
        values.splice(inx, 1)
        handleChangeInputValue((values as IOption[]).map(op => op.value) as any, values)
        setSelected(values)
    }

    const handleBlur = () => {
        if (inputRef.current) {
            setOpen(true)
            inputRef.current.focus();
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (realInputValue.length === 0 && e.code === 'Backspace') {
            if (!multiple) return handleChangeInputValue(undefined as any)
            deleteSelect(realValueSelected.length - 1)
        }
    }

    const onHandlePreventDefault = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const { dataLength = 0, hasMore = false, loading, next, loader } = infiniteScroll || {}

    return (
        <Command className={cn('overflow-visible bg-transparent', classNameCommand)}>
            <div
                onClick={handleBlur}
                onMouseDown={handleBlur}
                className={cn(
                    `group rounded-md  border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring cursor-text focus-within:ring-offset-2 ${disabled ? 'cursor-not-allowed' : ''}`,
                    className,
                )}
            >
                <div className={cn('flex flex-wrap gap-1', classNameInputContent)} onClick={handleBlur}>
                    {
                        realValueSelected.map((option, inx) => {
                            if (renderValueItem) return renderValueItem(option)

                            return (
                                <Badge
                                    onMouseDown={onHandlePreventDefault}
                                    key={option?.value}
                                    className={cn(
                                        'data-[disabled]:bg-muted-foreground data-[disabled]:text-muted data-[disabled]:hover:bg-muted-foreground',
                                        'data-[fixed]:bg-muted-foreground data-[fixed]:text-muted data-[fixed]:hover:bg-muted-foreground cursor-default px-2 hover:bg-primary rounded-sm',
                                        badgeClassName
                                    )}
                                >
                                    {option?.label}
                                    {
                                        !disabled && (
                                            <button
                                                type='button'
                                                className={cn('ml-1 ')}
                                                onClick={() => deleteSelect(inx)}
                                                onMouseDown={onHandlePreventDefault}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') deleteSelect(inx)
                                                }}
                                            >
                                                <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                                            </button>
                                        )
                                    }
                                </Badge>
                            );
                        })
                    }
                    <Input
                        ref={inputRef}
                        value={realInputValue}
                        disabled={disabled}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                            const { value } = e.target
                            setInputValue(value)
                            handleChangeInputSearch(value)
                            flatArray && handleChangeInputValue(value);
                        }}
                        onFocus={() => {
                            if (disabled) return;
                            setOpen(true)
                        }}
                        onBlur={handleOnBlur}
                        placeholder={placeholder}
                        className={cn(
                            `bg-transparent ${multiple ? 'max-w-max' : 'w-full'} flex-1 border-none outline-none focus-visible:ring-0 h-[20px] px-0 focus-visible:ring-offset-0 min-w-[60px]  rounded-none placeholder:text-muted-foreground`, classNameInput
                        )}
                        autoFocus={autoFocus}
                    />
                </div>
            </div>
            <div className="relative mt-2">
                {
                    ((open && !disabled) && (!!downElements || (realInputValue === '' && filteredSelectables.length > 0) || (realInputValue !== ''))) && (
                        <InfiniteScroll
                            hasMore={hasMore}
                            dataLength={dataLength}
                            disabled={isLoading}
                            next={next}
                            loader={loader || <div className='mt-2'>
                                <LoadingList />
                            </div>
                            }
                            onMouseDown={onHandlePreventDefault}
                            className={cn('absolute popoverScroll top-0 p-1 z-10 w-full rounded-md border bg-popover text-popover-foreground grid shadow-md outline-none max-h-[270px] animate-in', classNameContentList)}
                        >
                            {EmptyItem()}
                            {
                                isLoading
                                    ? <LoadingList />
                                    : flatArray
                                        ? filteredSelectables.map((value: any, i) => (
                                            <SelectedAnimation key={i}>
                                                <CommandItem
                                                    value={value}
                                                    onSelect={() => {
                                                        handleChangeInputValue(value)
                                                        if (closeWithSelect) setOpen(false)
                                                    }}
                                                    onMouseDown={(e) => {
                                                        onHandlePreventDefault(e)
                                                        handleChangeInputValue(value)
                                                    }}

                                                    className={cn('cursor-pointer')}
                                                >
                                                    {value}
                                                </CommandItem>
                                            </SelectedAnimation>
                                        ))
                                        : filteredSelectables.map((option: any, i) => (
                                            <SelectedAnimation key={i}>
                                                <CommandItem
                                                    value={option.value}
                                                    disabled={option.disable}
                                                    onSelect={() => {
                                                        if (option) handleUnselect(option)
                                                        if (closeWithSelect) setOpen(false)
                                                    }}
                                                    onMouseDown={onHandlePreventDefault}
                                                    className={cn(
                                                        'cursor-pointer',
                                                        option.disable && 'cursor-default text-muted-foreground',
                                                    )}
                                                >
                                                    {
                                                        renderItem
                                                            ? renderItem(option)
                                                            : `${option.label}`
                                                    }
                                                </CommandItem>
                                            </SelectedAnimation>
                                        ))
                            }
                            {
                                downElements && downElements.map(({ render, onSelectAction }, index: number) => (
                                    <SelectedAnimation key={index}>
                                        <CommandItem
                                            onMouseDown={onHandlePreventDefault}
                                            onSelect={() => onSelectAction()}
                                            className={cn('cursor-pointer relative overflow-hidden ')}
                                        >
                                            {render}
                                        </CommandItem>
                                    </SelectedAnimation>
                                ))
                            }
                        </InfiniteScroll>
                    )
                }
            </div>
        </Command>
    );
}

export function LoadingList({ countList = 5 }) {
    return <div className='flex flex-col gap-2'>
        {
            new Array(countList).fill(null).map((_, inx) => (
                <Skeleton key={inx} className='w-full h-[20px] rounded-[3px]' />
            ))
        }
    </div>
}

export function SelectedAnimation({ children }: { children: React.ReactNode }) {
    return (
        <motion.div
            transition={{ duration: 0.2 }}
            whileTap={{ scale: 0.99 }}
        >
            {children}
        </motion.div>
    )
}

InputWithSelect.displayName = 'InputWithSelect';
export default InputWithSelect;
