import { cn } from '@/lib/utils'
import { Loader2 } from 'lucide-react'
import { forwardRef } from 'react'
import { Button, ButtonProps } from '../ui/button'

export interface BtnNextStepProps extends ButtonProps {
    loading?: boolean
    classNameArrow?: string,
    classNameIcon?: string
}


const LoadingButton = forwardRef<HTMLButtonElement, BtnNextStepProps>(({ 
    className, 
    classNameArrow = '', 
    variant = 'default', 
    loading = false, 
    children, 
    disabled, 
    classNameIcon, 
    ...props 
}, ref) => {
    return (
        <Button
            type="button"
            className={cn("group overflow-hidden relative shrink-0", className)}
            ref={ref}
            disabled={loading || disabled}
            variant={variant as 'default'}
            {...props}
        >
            {
                loading && (
                    <span className="absolute top-0 left-0 w-full flex items-center justify-center h-full bg-black/30">
                        <Loader2 strokeWidth={3} className={cn('h-[19px] shrink-0 w-[19px]   animate-spin', classNameIcon)} />
                    </span>
                )
            }
            {children}
        </Button>
    )
})

export { LoadingButton }