import { isAuth } from '@/actions/auth'
import { CommentService, IPost, PostService } from '@/actions/training'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Reactions, allTheReaction, reactions } from '@/constants/reactions'
import { arrayFunctions } from '@/helpers/arrayFunctions'
import useLoadingAndError from '@/hooks/useLoadingAndError'
import usePagination from '@/hooks/usePagination'
import usePostCoach from '@/hooks/usePostCoach'
import { useToggle } from '@/hooks/useToggle'
import { cn } from '@/lib/utils'
import { AccountExecutive } from '@/interfaces/Interface'
import { AvatarUser } from '@/pages/training'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import { CornerDownLeft, Edit3, Loader2, SmilePlus, Trash2 } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'
import { BtnNextStep } from '../buttons/BtnNextStep'
import { TemplateItemButton } from '../channels/templates/TemplateListItem'
import useDebounce from '../core/functions/useDebouce'
import { DivToSetInnerHtml } from '../ui/div-innerhtml'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../ui/hover-card'
import SimpleTooltip from '../ui/simple-tooltip'
import { Comment } from './Comment'
import { InputComment } from './InputComment'
import { ROLES, isTheRolEnabled } from '../../helpers/featureFlagHelpers';

export default function CardPostOfCoach({ information, onDelete, onEdit, coach }: { information: IPost, onEdit?: boolean | ((value: IPost) => void), onDelete?: boolean | ((value: IPost) => void), coach: AccountExecutive }) {
    const [idsToDiscriminate, setIdsToDiscriminate] = useState<Array<string>>([])
    const { handleNextPage, handleSetMaxPage, limitOfPage, page } = usePagination({ initialPage: 0 })
    const { agent, content, createdAt, title, _id, user, restOfCommentCount } = information
    const [missingComments, setMissingComments] = useState(restOfCommentCount || 0)
    const [parentComment, setParentComment] = useState<null | string>(null)
    const { setIsLoading, isLoading } = useLoadingAndError()
    const { isLoading: isLoadingViewMore, setIsLoading: setIsLoadingViewMore } = useLoadingAndError()
    const [reaction, setReaction] = useState<string>(user?.reaction || '')
    const [lastReaction, setLastReaction] = useState<string>(user?.reaction || '')
    const [comments, setComments] = useState<IPost['comments'] | any>(information?.comments || [])
    const [reactionType, setReactionType] = useState<Reactions | null | 'null'>(null)
    const [isOpenResponsePost, toggle] = useToggle<any>(false)

    const currentUser = useMemo(() => isAuth(), [])
    const isAdmin = isTheRolEnabled(ROLES.ADMIN)

    const {
        isErrorOfReaction,
        mutate,
        isLoadingMutate
    } = usePostCoach()

    useEffect(() => {
        handleSetMaxPage(restOfCommentCount || 0, 5)
    }, [restOfCommentCount])

    const reactionDebounce = useDebounce(reactionType ?? '', 500)

    const handleLoadComment = async () => {
        setIsLoadingViewMore(true)
        try {
            const query: any = { page, limit: 5, post_id: _id }
            if (idsToDiscriminate.length > 0) {
                query.idsToDiscriminate = idsToDiscriminate.join(',')
            }
            const { comments } = await CommentService.getComment(query)
            setMissingComments(lastCount => lastCount - comments.length)
            setComments(lastComments => [...lastComments, ...comments])
        } catch {
            console.log('wefwef')
        } finally {
            setIsLoadingViewMore(false)
        }
    }

    useEffect(() => {
        if (page > 0) handleLoadComment()
    }, [page])

    useEffect(() => {
        if (reactionDebounce !== null && reactionType !== null) {
            mutate({
                id: _id,
                action: {
                    type: 'reaction',
                    payload: reactionDebounce
                }
            })
        }
    }, [reactionDebounce])

    useEffect(() => {
        if (isErrorOfReaction !== null) {
            if (isErrorOfReaction) {
                setReaction(lastReaction)
            } else {
                setLastReaction(reaction)
            }
        }
    }, [isErrorOfReaction, isLoadingMutate])

    const handleReactionLocal = (_id: string, type: Reactions) => {
        const newReaction = reactions[type].icon
        setReactionType(newReaction === reaction ? "null" : type)
        setReaction(newReaction === reaction ? '' : newReaction)
    }

    const handleComment = async (value: any) => {
        setIsLoading(true)

        try {
            const res: any = await PostService.comment({
                id: _id,
                content: value.content,
                parentComment,
                reply_to: isOpenResponsePost?._id,
                coach: coach._id,
            });
            const comment = res.comment;

            if (comment) {
                toggle()
                setParentComment(null)
                setIdsToDiscriminate(lastIds => [...lastIds, comment._id])
                setComments([...comments, comment])
            }

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    const handleDeleteComentParent = (inx) => {
        setComments(lastComments => arrayFunctions.removeBySplice(lastComments, inx))
    }

    return <>
        <li className='w-full  max-w-[100%]'>
            <div className='flex max-w-[100%] w-full gap-2'>
                <AvatarUser
                    className={'md:flex hidden'}
                    img={coach.photoPath}
                    label={`${agent.name[0]}${agent.lastname[0]}`} />
                <Card className='flex relative w-full flex-col border-none rounded-[2px] md:px-8 px-4 py-4 bg-neutral-100'>
                    <CardHeader className='p-0'>
                        <div className='flex  sm:flex-row flex-col sm:items-center sm:gap-2'>
                            <CardTitle className='text-[18px]'>{title}</CardTitle>
                            <time className='text-primary sm:text-[13px] text-[11px]'>
                                {dayjs(createdAt).format('DD/MM/YYYY HH:mm')} Hs
                            </time>
                        </div>
                        <div className='absolute right-4 top-2 flex space-x-1 items-center'>
                            {
                                user && <HoverCard openDelay={0.5}>
                                    <HoverCardTrigger asChild>
                                        <motion.button
                                            className='hover:hover:bg-neutral-50 flex items-center justify-center w-[28px] h-[28px] shrink-0 rounded-full'
                                            whileTap={{ scale: 0.8 }}
                                            onClick={() => handleReactionLocal(_id, reaction
                                                ? allTheReaction[reaction] as Reactions
                                                : 'like')
                                            }
                                        >
                                            {reaction ? (
                                                <span className='text-xl'>{reaction}</span>
                                            ) : (
                                                <motion.div
                                                    initial={{ scale: 1.2 }}
                                                    animate={{ scale: 1 }}
                                                    whileTap={{ scale: 1 }}
                                                >
                                                    <SmilePlus size={20} />
                                                </motion.div>
                                            )}
                                        </motion.button>
                                    </HoverCardTrigger>
                                    <HoverCardContent sideOffset={-120} className='w-auto flex items-center py-1 px-4 mr-10'>
                                        <div className='flex items-center gap-1.5'>
                                            {
                                                Object.entries(reactions).map(([type, { icon, name }]) => (
                                                    <SimpleTooltip content={name} key={type} sideOffset={10}>
                                                        <motion.div
                                                            initial={{ scale: 0.8 }}
                                                            animate={{ scale: 1 }}
                                                            whileTap={{ scale: 0.8 }}
                                                            transition={{
                                                                duration: 0.2,
                                                                type: 'spring',
                                                                bounce: 0.2
                                                            }}
                                                            whileHover={{ scale: 1.2, y: -10 }}
                                                        >
                                                            <button onClick={() => handleReactionLocal(_id, type as Reactions)}>
                                                                <motion.span
                                                                    className='text-[23px]'
                                                                    whileTap={{ scale: 0.8 }}
                                                                >
                                                                    {icon}
                                                                </motion.span>
                                                            </button>
                                                        </motion.div>
                                                    </SimpleTooltip>
                                                ))
                                            }
                                        </div>
                                    </HoverCardContent>
                                </HoverCard>
                            }
                            {
                                onEdit && onDelete && <>
                                    <TemplateItemButton
                                        tooltip="Editar"
                                        variant='ghost'
                                        icon={<Edit3 className="h-4 w-4" />}
                                        onClick={() => onEdit && onEdit(information)} />

                                    <TemplateItemButton
                                        tooltip="Eliminar"
                                        variant='ghost'
                                        icon={<Trash2 className="h-4 w-4" />}
                                        onClick={() => onDelete && onDelete(information)} />
                                </>
                            }
                        </div>
                    </CardHeader>
                    <CardContent className='p-0 mt-1'>
                        <DivToSetInnerHtml content={content}
                            className='border-b border-slate-300 pb-5 text-[15px] flex flex-col gap-4' />
                        <>
                            {
                                comments && comments.length > 0 && <ul className='flex mt-3 relative  flex-col' style={{ overflowWrap: 'break-word' }}>
                                    {
                                        comments.map((comment, inx) => <Comment
                                            coach={coach}
                                            comment={comment}
                                            currentUser={currentUser}
                                            key={comment._id}
                                            handleDeleteComentParent={() => handleDeleteComentParent(inx)}
                                            postId={_id}
                                            parentComment={comment._id}
                                            isAdmin={isAdmin}
                                        />)
                                    }
                                </ul>
                            }
                            <div className='flex flex-col items-start justify-start mt-1'>
                                <ButtonViewMoreComment
                                    loading={isLoadingViewMore}
                                    active={(page < limitOfPage) && !isLoadingViewMore}
                                    countRestComments={missingComments}
                                    handleNextPage={handleNextPage} />
                                {
                                    (isAdmin || !!user) && <button className={` hover:bg-white px-3 py-2 rounded-sm`} onClick={() => {
                                        toggle()
                                        if (parentComment && !isOpenResponsePost) {
                                            setParentComment(null)
                                        }
                                    }}>
                                        <span className='flex items-center gap-2 font-medium sm:text-[14px] text-[11px]'>
                                            <CornerDownLeft className={`${isOpenResponsePost ? 'rotate-90' : ''} sm:w-5 sm:h-5 w-4 h-4 transition-transform`} />
                                            Responder publicación
                                        </span>
                                    </button>
                                }
                            </div>
                            {
                                !!isOpenResponsePost && <div>
                                    <InputComment
                                        onClose={toggle}
                                        handleComment={handleComment}
                                        isLoading={isLoading} />
                                </div>
                            }
                        </>
                    </CardContent>
                </Card>
            </div>
        </li >
    </>
}

export function ButtonViewMoreComment({ active, handleNextPage, countRestComments, className = '', loading = false }) {
    // if (loading) return <Loader2 className="h-4 w-4 text-primary animate-spin" />

    return (active || loading) ? <BtnNextStep
        variant={'link'} hiddenArrow classNameArrow='w-[19px] h-[19px]' loading={loading} className={cn('px-0', className)} onClick={handleNextPage}>
        Ver {countRestComments > 1
            ? `${countRestComments} respuestas`
            : 'respuesta'}
    </BtnNextStep> : null
}

