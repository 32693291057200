import { InstancesService } from '@/actions/training'
import { IconPlayNotification } from '@/components/Header/CardNotification'
import MenuTraining from '@/components/Menues/MenuTraining'
import Loading from '@/components/core/Loading'
import { NotContent } from '@/components/instance/ListOfPost'
import { ModalViewClass } from '@/components/training/CardOfClass'
import CarrouselOfClassVideo from '@/components/training/CarrouselOfClassVideo'
import { CardDescription, CardTitle } from '@/components/ui/card'
import { formatTime } from '@/helpers/formatSeconds'
import useInstance from '@/hooks/useInstance'
import usePagination from '@/hooks/usePagination'
import { useToggle } from '@/hooks/useToggle'
import dayjs from 'dayjs'
import { Calendar, Clock3, Loader2 } from 'lucide-react'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import { MainOfInstances } from './tutorials'

export const LIMIT_OF_INSTANCE = 5

export default function Instances() {
    const { handleNextPage, page, setLimitPage, limitOfPage } = usePagination()
    const router = useRouter()
    const [value, _, setOpenView] = useToggle<any>(false)
    const [allInstances, setAllInstances] = useState<Array<any>>([])
    const idOfInstance = router.query.id
    const manager = useSelector((state: any) => state.header.manager)

    const { instances: lastInteraction, isLoading: isLoadingview, refetch } = useInstance({
        page: 1,
        size: 1,
        lastInteraction: idOfInstance ? false : true,
        type: 'instance',
        externalQueryKey: ['last-instance-view']
    })

    const findTutorialByID = async (id) => {
        try {
            const instance = await InstancesService.get({
                type: 'instance',
                id,
                page: 1,
                size: 1
            })

            return instance
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (idOfInstance) {
            setOpenView(true)
            if (idOfInstance) {
                findTutorialByID(idOfInstance)
                    .then((res: any) => {
                        setOpenView(res?.instance)
                    })
                    .catch((e) => console.error(e))
            }
        }
    }, [idOfInstance])

    const { instances, refetch: refetchAllInstance, countDocuments, isLoading } =
        useInstance({
            id: undefined,
            page,
            size: LIMIT_OF_INSTANCE,
            type: 'instance',
        })

    useEffect(() => {
        if (page > 1) refetchAllInstance()
    }, [page])

    useEffect(() => {
        setAllInstances(lastInstances => [...lastInstances, ...instances])
        setLimitPage(Math.ceil(countDocuments / LIMIT_OF_INSTANCE))
    }, [instances])

    const handleCloseModal = () => {
        if (idOfInstance) {
            refetch()
            return router.replace(router.pathname, undefined, { shallow: true });
        }
        setOpenView(false)
    }

    const filterFirstInstance = useMemo(() => allInstances
        .filter((instance: any) => instance?._id !== lastInteraction?.[0]?._id), [allInstances, lastInteraction])

    return <MenuTraining slug={'instances'}>
        {
            limitOfPage === 0 && instances?.length == 0 && !isLoadingview && !isLoading
                ? <NotContent label={!manager ? 'Tu empresa no tiene un entrenador asignado' : 'Sin clases subidas.'} />
                : isLoadingview ? <Loading />
                    : <section className='flex flex-col md:px-8 px-4 py-5'>
                        {lastInteraction.length > 0 && <main className='mb-6'>
                            <CardTitle className='text-xl mb-4'>
                                Continuar viendo
                            </CardTitle>
                            <MainOfInstances
                                firstSection={<>
                                    <div className='min-h-[360px] block' onClick={() => setOpenView(lastInteraction[0])}>
                                        <div className='relative group mb-4 w-full h-full  flex-grow cursor-pointer flex aspect-video items-center overflow-hidden '>
                                            <img src={lastInteraction[0].miniature} className='w-full h-full object-cover' />
                                            <div className='group-hover:block hidden'>
                                                <IconPlayNotification className='w-8 h-8' />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                                secondSection={<>
                                    <div className='md:p-5'>
                                        <CardTitle className='text-[23px] text-primary'>
                                            {lastInteraction[0].title}
                                        </CardTitle>
                                        <CardDescription className='leading-6 text-[16px] my-2'>
                                            {lastInteraction[0].description}
                                        </CardDescription>
                                        <div className='flex flex-col gap-2 text-slate-800'>
                                            {
                                                <time className='flex items-center text-[13px] gap-1 [&>svg]:w-4 [&>svg]:h-4'>
                                                    <Calendar className='text-primary' />
                                                    {dayjs(lastInteraction[0].date).format('DD/MM/YYYY')}
                                                </time>
                                            }
                                            <span className='flex items-center text-[13px] gap-1 [&>svg]:w-4 [&>svg]:h-4'>
                                                <Clock3 className='text-primary' />
                                                {formatTime(lastInteraction[0].duration)}
                                            </span>
                                        </div>
                                    </div>
                                </>}
                            />
                        </main>
                        }
                        {
                            (!isLoadingview && filterFirstInstance.length > 0) && <InfiniteScroll
                                dataLength={allInstances.length}
                                hasMore={page < limitOfPage}
                                next={handleNextPage}
                                loader={<LoaderForInfinityScroll />}
                                style={{
                                    overflowY: 'hidden'
                                }}
                            >
                                <CarrouselOfClassVideo
                                    videos={allInstances
                                        .filter((instance: any) => instance?._id !== lastInteraction?.[0]?._id)
                                    }
                                    title='Todas las clases'
                                    type='instances' />
                                {
                                    isLoading && <LoaderForInfinityScroll />
                                }
                            </InfiniteScroll>
                        }
                    </section>
        }
        <ModalViewClass
            link={value?.linkOfClass}
            onClose={handleCloseModal}
            open={!!value} />
    </MenuTraining>
}

export function LoaderForInfinityScroll() {
    return <div className='w-full justify-center py-2 flex items-center'>
        <Loader2 className="h-6 text-primary w-6 animate-spin" />
    </div>
}