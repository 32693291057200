import { updateCompanyGroup } from "@/actions/comercial/companygroup";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { getCompanyGroupConfig } from "@/helpers/companyGroups";
import { AdditionalCompanyField, ICompanyGroup } from "@/interfaces/Interface";
import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { useMemo } from "react";
import AdditionalFieldItem from "./AdditionalFieldItem";
import DirectionItem from "./DirectionItem";
import SectionTitle from "./SectionTitle";
import { TextInfomationItem } from "./TextInfomationItem";
import CompanyGroupInformationLoader from "./Loaders/CompanyGroupInformationLoader";

interface CompanyGroupInfomationProps {
    company: ICompanyGroup | undefined,
    additionalCompanyFields: AdditionalCompanyField[],
    onHandleChangeCompany: (value: ICompanyGroup) => void,
    isLoading?: boolean
};

export default function CompanyGroupInfomation(props: CompanyGroupInfomationProps) {
    const { company, onHandleChangeCompany, additionalCompanyFields, isLoading } = props
    const { origin, displayName, taxCode, direction, description, additionalData = {} } = company || {};

    const { defaultFields } = getCompanyGroupConfig();
    const defaultFieldsToMap = _.keyBy(defaultFields, 'key');

    const { mutate } = useMutation({
        mutationFn: ({ data }: { data: Record<string, any> }) => {
            return updateCompanyGroup(data, company?._id || '')
        }
    });

    const onChangeValue = (key: string, value: any, isAdditionalData: boolean = false) => {
        if (!company) return;

        let objectToUpdate: { [key: string]: any } = { [key]: value };
        let updatedCompany = company;

        if (isAdditionalData) {
            if (additionalData?.[key] == value) return;

            if (!updatedCompany.additionalData) {
                updatedCompany.additionalData = {}
            }

            updatedCompany.additionalData[key] = value;
            objectToUpdate = { additionalData: { [key]: value } }
        } else {
            if (company[key] == value) return;
            updatedCompany[key] = value;
        }

        onHandleChangeCompany(updatedCompany);
        mutate({ data: objectToUpdate });
    };

    const sortedAdditionalData = useMemo(() => {
        const typesToMoveLast = ['select', 'multiselect', 'date', 'selectcontact', 'multiplecontact', 'selectcompany', 'multiplecompany'];

        return additionalCompanyFields.sort((a, b) => {
            const isAInTypes = typesToMoveLast.includes(a.type);
            const isBInTypes = typesToMoveLast.includes(b.type);

            if (isAInTypes && !isBInTypes) {
                return 1;
            } else if (!isAInTypes && isBInTypes) {
                return -1;
            }
            return 0;
        });
    }, [additionalCompanyFields]);

    if (isLoading) return <CompanyGroupInformationLoader />

    return (
        <div>
            <SectionTitle>
                Información general
            </SectionTitle>
            <ul className="grid gap-2 mt-4">
                <TextInfomationItem
                    value={displayName}
                    title="Razón Social:"
                    hidden={!!defaultFieldsToMap?.displayName?.hidden}
                    onChangeValue={(value) => onChangeValue('displayName', value)}
                />

                <TextInfomationItem
                    value={taxCode}
                    title="Número de RUT:"
                    hidden={!!defaultFieldsToMap?.taxCode?.hidden}
                    onChangeValue={(value) => onChangeValue('taxCode', value)}
                />

                <TextInfomationItem
                    value={origin}
                    title="Origen:"
                    hidden={!!defaultFieldsToMap?.origin?.hidden}
                />

                <TextInfomationItem
                    value={description}
                    title="Descripción:"
                    hidden={!!defaultFieldsToMap?.description?.hidden}
                    onChangeValue={(value) => onChangeValue('description', value)}
                />

                <DirectionItem
                    onChangeValue={(value) => onChangeValue('direction', value)}
                    hidden={!!defaultFieldsToMap?.direction?.hidden}
                    value={direction}
                />

                {
                    sortedAdditionalData.filter((field) => field.active).map((field) => {
                        const value = additionalData[field.code];

                        return <AdditionalFieldItem
                            onChangeValue={onChangeValue}
                            company={company}
                            key={field.code}
                            field={field}
                            value={value}
                        />
                    })
                }
            </ul>
            <Separator className="mt-5" />
        </div>
    )
}
