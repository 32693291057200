import { changePassword } from "@/actions/user";
import { useMutation } from "@tanstack/react-query";

type mutationProps = {
    body: {
        currentPassword: string,
        newPassword: string
    },
    onSuccess: (data: any) => void,
    onError: (data: any) => void,
}

export default function useChangePassword() {
    const { mutate, ...rest } = useMutation({
        mutationFn: ({ body }: mutationProps) => changePassword(body),
        onSuccess: (data, { onSuccess, onError }) => {
            if (!data.success) {
                return onError && onError(data)
            }
            onSuccess && onSuccess(data)
        },
        onError: (error, { onError }) => {
            onError && onError(error)
        }
    })

    const handleChangePassword = (data: mutationProps) => mutate(data)

    return {
        ...{ ...rest, isLoading: rest.status == 'pending' },
        handleChangePassword
    }
}
