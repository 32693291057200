import { AlarmClock, AlertCircle, Check, CheckCheck, Mic } from "lucide-react"
import { useMemo } from "react"

export type TickStatusType = 'sending' | 'sent' | 'received' | 'read' | 'error'

const TickStatus = ({
    status,
    listened,
    mediaType
}: {
    status: TickStatusType,
    listened?: boolean,
    mediaType?: string
}) => {
    const statusObjectContent = useMemo(() => ({
        sending: (<AlarmClock size={14} />),
        sent: (<Check size={14} />),
        received: (<CheckCheck size={14} />),
        read: (<CheckCheck color="#0080FF" size={14} />),
        error: (<AlertCircle size={14} color="#f00000" />),
        default: (<></>)
    }), [])

    return (
        <div className="ml-[2px] mt-[2px]">
            {statusObjectContent[(listened) ? 'read' : status] || statusObjectContent.default}
        </div>
    )
}

export default TickStatus