import {
  setCollapsedMenu,
  setControllSubMenues
} from "@/redux/slices/headers"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLocalStorage, setLocalStorage } from "@/actions/auth"
import { useRouter } from "next/router"
import { CODES_SUBMENUES, KEY_LOCAL_STORAGE } from "../interfaces-enums"

const defaultValuesLocalStorage = {
  'collapsed': true,
  'menues': {
    'collapsed': [],
    'expanded': [CODES_SUBMENUES?.BACKOFFICE, CODES_SUBMENUES.CHARTS, CODES_SUBMENUES.CONVERSATIONS]
  }
}

export default function useMenu() {
  const router = useRouter()
  const dispatch = useDispatch()
  const isCollapsed = useSelector((state: any) => state.header.isCollapsedMenu)
  const valueOfSubMenues = useSelector((state: any) => state.header.menuesControl)[isCollapsed ? 'collapsed' : 'expanded'] || []

  useEffect(() => {
    if (!getLocalStorage(KEY_LOCAL_STORAGE.MENU_CONTROL, true)) {
      setLocalStorage(KEY_LOCAL_STORAGE.MENU_CONTROL, defaultValuesLocalStorage)
    } else {
      dispatch(setControllSubMenues(getLocalStorage(KEY_LOCAL_STORAGE.MENU_CONTROL, true)?.menues))
    }

    dispatch(setCollapsedMenu(!!getLocalStorage(KEY_LOCAL_STORAGE.MENU_CONTROL, true)?.collapsed))
  }, [])

  useEffect(() => {
    if (!isCollapsed) {
      handleCollapsedMenu(false)
    }
  }, [router.pathname])

  const withCopyValue = (fn: (value: Record<string, any>) => void) => {
    let valueOfLocalStorage = getLocalStorage(KEY_LOCAL_STORAGE.MENU_CONTROL, true)

    if (
      !valueOfLocalStorage
      || typeof valueOfLocalStorage.collapsed !== 'boolean'
      || !valueOfLocalStorage.menues
      || !Array.isArray(valueOfLocalStorage?.menues?.collapsed)
      || !Array.isArray(valueOfLocalStorage?.menues?.expanded)
    ) {
      valueOfLocalStorage = defaultValuesLocalStorage
    }

    fn(valueOfLocalStorage)
  }

  const handleCollapsedMenu = (newValue: boolean | null = null) => {
    const value = newValue !== null ? newValue : !isCollapsed

    dispatch(setCollapsedMenu(value))

    withCopyValue((valueOfLocalStorage) => {
      setLocalStorage(KEY_LOCAL_STORAGE.MENU_CONTROL, {
        ...valueOfLocalStorage,
        collapsed: value
      })
    })
  }

  const handleChangeValue = (value: Array<string>) => {
    withCopyValue((valueOfLocalStorage) => {
      const newValue = {
        ...valueOfLocalStorage.menues,
        [isCollapsed ? 'collapsed' : 'expanded']: value
      }

      setLocalStorage(
        KEY_LOCAL_STORAGE.MENU_CONTROL, {
        ...valueOfLocalStorage,
        'menues': newValue
      })

      dispatch(setControllSubMenues(newValue))
    })
  }

  return {
    isCollapsed,
    valueOfSubMenues,
    handleCollapsedMenu,
    handleChangeValue
  }
}
