import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import { toast } from "@/components/ui/use-toast"
import { ArrowBack, CheckCircleOutline, Edit, Save, Share } from "@mui/icons-material"
import { Alert, Autocomplete, Button, Chip, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Modal, Paper, Select, Stack, SxProps, Tab, Tabs, TextField, Typography } from "@mui/material"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import phoneValidator from 'libphonenumber-js'
import _, { get } from 'lodash'
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { getCompany, isAuth } from "../../../actions/auth"
import { create } from "../../../actions/crm/contact"
import { changeStatus, commentRequestSale, getOneRequestSale, getRequestTemplate, getRequestTemplateById, getUsersForMod, modifyRequestSale, saveRequestSale, watchRequestSale } from '../../../actions/crm/request-sale'
import { isTheRolEnabled } from '../../../helpers/featureFlagHelpers'
import { useToggle } from '../../../hooks/useToggle'
import Loading from "../../core/Loading"
import ForceSaveAlert from "./ForceSaveAlert"
import ListFields, { evaluateRules } from "./ListFields"
import SubLine from "./SubLine"
import { ShareRequestSalePopover } from "./ShareRequestSalePopover"
moment.locale('es')

interface SelectActionInterface {
    setOpen: (open: boolean | string) => void,
    contactid: string,
    activeEditMode?: boolean
    setContactId?: (string) => void,
    presaleid?: true | string,
    templateid?: string,
    externalTitle?: (value: any) => string
    closeWhenChangeStatus?: boolean
    auditMode?: boolean,
    defaultAuditEnabled?: boolean
    sxProps?: SxProps
    open?: boolean
}

export type actions_crm = 'note' | 'whastapp' | 'visit' | 'more' | 'transfer' | 'form' | 'select' | 'sales'

const generatePreSaleColor = (status) => {
    const statusColors = {
        'draft': 'grey',
        'new': 'primary',
        'processing': 'purple',
        'processed': 'purple',
        'linked': 'green',
        'cancel': 'red',
        'resend': 'orange'
    };

    return statusColors[status] || statusColors['draft']
}

interface ForceStatusValue {
    _id: string,
    label: string
}

const RequestSaleAdd = ({
    setOpen,
    contactid,
    externalTitle,
    closeWhenChangeStatus,
    presaleid,
    templateid,
    auditMode,
    defaultAuditEnabled,
    sxProps,
    setContactId,
}: SelectActionInterface) => {

    const [viewRequirementsCounter, toggleViewRequirementsCounter] = useToggle<boolean>(false)
    const [editStatusMode, setEditStatusMode] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [values, setValues] = useState({})

    const [defaultValues, setDefaultValues] = useState({});

    const [newContactData, setNewContactData] = useState({ firstName: "", lastName: "", phone: "", detail: "" })
    const [contactAlert, setContactAlert] = useState<false | string>(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [tab, setTab] = useState(0)
    const [comment, setComment] = useState("")
    const [agent, setAgent] = useState<any>()
    const [auditEnabled, setAuditEnabled] = useState(defaultAuditEnabled)
    const [forceStatus, setForceStatus] = useState<ForceStatusValue | null>()
    const [lineValues, setLineValues] = useState<any>([{ code: '' }])
    const [sharedUsers, setSharedUsers] = useState<any>([])

    const [isForceSave, setIsForceSave] = useState(false);
    const [openForceSaveAlert, toggleForceSaveAlert] = useToggle<boolean>(false)

    const enableSaveRequestSale = useSelector((state: any) => state.crm.enableSaveRequestSale);
    const isComercialUser = isTheRolEnabled('comercial');
    const [sharedCanEdit, setSharedCanEdit] = useState(false);

    let requestSale = useQuery({
        queryKey: ['request-sale-one', contactid, presaleid],
        queryFn: () => getOneRequestSale(contactid, presaleid, true),
        enabled: typeof presaleid == 'string'
    })

    let template = useQuery({
        queryKey: ['request-sale-template', (templateid ?? requestSale?.data?.requestsale?.template?._id)],
        queryFn: (templateid || requestSale?.data?.requestsale?.template?._id) ? () => getRequestTemplateById(templateid ?? requestSale.data.requestsale.template?._id) : () => getRequestTemplate(),
        enabled: typeof presaleid == 'string' ? !!(templateid || requestSale.data) : true
    })

    let userList = useQuery({
        queryKey: ['list-users-request-sale'],
        queryFn: () => getUsersForMod(),
        enabled: isTheRolEnabled(['comercial', 'super'])
    })

    const queryClient = useQueryClient()

    let validateForm = () => {
        setIsForceSave(false);

        let newValid = true
        if (template.data?.body) {
            template.data.body.forEach((field) => {

                if (field.visibilityRules.length > 0) {
                    const visibilityRules = _.get(field, 'visibilityRules', []);
                    const visibility = evaluateRules(visibilityRules, values, {
                        ..._.get(requestSale, 'data.requestsale', {}),
                        status: forceStatus ? forceStatus.label : _.get(requestSale, 'data.requestsale.status', "")
                    });

                    if (!visibility) return '';
                };

                if (field.type == 'multiplefile') {
                    const value = values[field.field];
                    if (Array.isArray(value)) {
                        newValid = value.every(({ path }) => path.startsWith('http'));
                    }
                }

                if (field.editBackoffice && !_.get(isAuth(), 'roles', []).includes('comercial')) return ''
                if (field.maxLength > 0 && values[field.field] && (values[field.field] + "").length > 0 && (field.maxLength < (values[field.field] + "").length || field.minLength > (values[field.field] + "").length)) return newValid = false // Validación de largo
                if (field.regex && values[field.field] && field.regex.length > 0 && (values[field.field] + "").length > 0 && !new RegExp(field.regex).test(values[field.field])) return newValid = false // Validación de regex
                if (!field.required) return ''
                if (!field.active) return ''
                if (field.type == 'divider') return ''

                const value = values[field.field];

                switch (field.type) {
                    case 'string':
                    case 'select':
                    case 'selectmultiple':
                        if (!values[field.field] || values[field.field].length == 0) {
                            newValid = false
                        }
                        break;
                    case 'number':
                        if (!values[field.field]) {
                            newValid = false
                        }
                        break;
                    case 'multiplefile':
                    case 'multiselectarray': {
                        if (!Array.isArray(value) || (Array.isArray(value) && value.length == 0)) {
                            newValid = false
                        }
                        break;
                    }
                    case 'date':
                    case 'datetime':
                        if (!values[field.field]) {
                            newValid = false
                        }
                        break;
                    case 'image':
                        if (!values[field.field]) {
                            newValid = false
                        }

                }
            })

            if (template.data?.lineBody && template.data?.lineBody.length > 0) {
                if (lineValues.length == 0) {
                    newValid = false
                } else {
                    lineValues.map((line) => {
                        if (line.code == '') {
                            newValid = false
                        }
                    })
                }
            }
            setIsValid(newValid)
        }
    }

    let onChange = useCallback((type: string, name: string) => (e, fieldValue) => {
        setError(false);

        switch (type) {
            case 'string':
            case 'multiselectarray':
            case 'select':
                setValues((prev) => {
                    return { ...prev, [name]: e.target.value }
                })
                break;
            case 'number':
                setValues((prev) => {
                    const re = new RegExp('^[0-9]*$')
                    if ((typeof prev[name] == 'number' && isNaN(prev[name])) || prev[name] === 'NaN') {
                        return { ...prev, [name]: undefined }
                    } else if (e.target.value === "" || re.test(e.target.value)) {
                        return { ...prev, [name]: e.target.value === "" ? undefined : parseInt(e.target.value) }
                    } else {
                        return prev
                    }
                })
                break;
            case 'selectmultiple':
                setValues((prev) => {
                    return { ...prev, [name]: fieldValue ? fieldValue.id : undefined }
                })
                break;
            case 'date':
                setValues((prev) => {
                    return { ...prev, [name]: moment(e).format('YYYY/MM/DD') }
                })
                break;
            case 'multiplefile':
            case 'image':
                setValues((prev) => {
                    return { ...prev, [name]: e }
                })
                break;
        }
    }, [setValues])

    useEffect(() => {
        validateForm()
    }, [values, lineValues])

    const templateBody = useMemo(() => {
        return _.get(template, 'data.body', [])
    }, [template])

    useEffect(() => {
        if (requestSale.data) {

            const requestSaleValue = { ...values, ..._.get(requestSale, 'data.requestsale.content', {}) }

            setDefaultValues(requestSaleValue);
            setValues(requestSaleValue);

            setLineValues([..._.get(requestSale, 'data.requestsale.linesContent', [])]);

            setSharedUsers(_.get(requestSale, 'data.requestsale.shared', []).map(({ userId, permissions }) => ({ userId: userId._id, permissions })));


            if (requestSale.data.requestsale.shared && requestSale.data.requestsale.shared.length > 0) {
                const userHasPermissionToEdit = requestSale.data.requestsale.shared.some(({ userId, permissions }) => {
                    return userId._id === isAuth()._id && (permissions === 'write' || permissions === 'all');
                });

                if (userHasPermissionToEdit) {
                    setSharedCanEdit(true);
                }
            }

            let set_agent = _.get(requestSale, 'data.requestsale.creator', '')
            if (set_agent) {
                setAgent({
                    label: set_agent.name + " " + set_agent.lastname,
                    _id: set_agent._id
                })
            }
        }
        if (typeof presaleid != "string" && template.data) {
            let defaultModel = {}

            let bodyTemplate = _.get(template, 'data.body', [])


            bodyTemplate.map((temp) => {
                if (temp.default && temp.default.length > 0) {
                    if (temp.type == 'number') {
                        defaultModel[temp.field] = parseInt(temp.default)
                    } else {
                        defaultModel[temp.field] = temp.default
                    }
                }
            })
            setValues({ ...defaultModel })
            let set_agent = isAuth()
            if (set_agent) {
                setAgent({
                    label: set_agent.name + " " + set_agent.lastname,
                    _id: set_agent._id
                })
            }
        }
    }, [requestSale.data, presaleid, template.data])

    const onChangeAgent = (_, new_agent) => {
        setAgent(new_agent)
    }

    const onChangeStatus = (_, value) => {
        setForceStatus(value)
    }

    const saveAs = (status: string, forceSave?: boolean) => async () => {
        if (!isValid && !forceSave && isComercialUser) return toggleForceSaveAlert();
        if (isComercialUser && forceSave) setIsForceSave(false);

        setIsLoading(true)
        let local_contact_id = contactid

        const allValues = { ...values };

        if (contactid == 'generate') {
            let sendcontact: any = { ...newContactData }
            setIsLoading(false)
            if (newContactData.firstName.length > 32) {
                return setContactAlert("El tamaño maximo del nombre es 32 caracteres")
            }
            if (newContactData.lastName.length > 32) {
                return setContactAlert("El tamaño maximo del apellido es 32 caracteres")
            }
            if (newContactData.phone.length == 0) {
                return setContactAlert("Se requiere ingresar un teléfono")
            }
            let phoneCode = get(getCompany(), 'country', 'UY').toUpperCase()
            let firstValidator = phoneValidator(newContactData.phone, phoneCode)
            if (firstValidator && firstValidator.isValid()) {
                sendcontact.phones = [firstValidator.number]
            } else {
                let region = new Intl.DisplayNames(['es'], { type: 'region' })
                return setContactAlert(`El numero de teléfono tiene que ser un numero valido de ${region.of(phoneCode)} o de otro país con el codigo correspondiente`)
            }
            if (newContactData.detail.length > 255) {
                return setContactAlert("El detalle no puede tener mas de 255 caracteres")
            }
            sendcontact.origin = 'solicitud'

            let responseCreateContact = await create(sendcontact)
            if (responseCreateContact.error) return setContactAlert(responseCreateContact.error)
            setContactAlert(false)
            if (setContactId) setContactId(responseCreateContact._id)
            local_contact_id = responseCreateContact._id
        }

        let resultSave = typeof presaleid == 'string' ? await modifyRequestSale(local_contact_id, presaleid, status, allValues, agent, forceStatus?.label, lineValues, sharedUsers, sharedCanEdit) : await saveRequestSale(local_contact_id, template.data?._id, status, allValues, agent, forceStatus?.label, lineValues, sharedUsers, sharedCanEdit)
        queryClient.invalidateQueries({
            queryKey: ['request-sale-list']
        })
        let result = _.get(resultSave, 'result')
        if (resultSave.error) {
            setError(resultSave.error)
        } else {
            setError(false)
            if (typeof presaleid == 'string') {
                setOpen(result._id)
            } else {
                setOpen(result._id)
            }
        }
        queryClient.invalidateQueries({
            queryKey: ['request-sale-one']
        })
        queryClient.invalidateQueries({
            queryKey: ['table-requestsales']
        })
        queryClient.invalidateQueries({
            queryKey: ['request-sale-list']
        })
        setIsLoading(false)
    }

    var handleTab = useCallback((_, name) => {
        setTab(name)
    }, [])

    const { mutate, isPending: isPendingChangeFastStatus } = useMutation({
        mutationFn: (props: { status: string, statusType: string }) => (
            changeStatus(requestSale.data.requestsale._id as string, props.status, props.statusType)
        ),
        onSuccess: () => {
            toast({
                title: 'Cambio de estado',
                description: `El cambio de estado se aplicó correctamente. Su solicitud ha sido actualizada exitosamente.`,
                variant: 'success'
            })

            if (closeWhenChangeStatus) return setOpen(false)
        },
        onError: () => {
            toast({
                title: 'Error en cambiar estado de solicitud',
                description: `Hubo un error al cambiar el estado de la solicitud. Por favor, inténtelo de nuevo. Si el problema persiste, contacte con soporte.`,
                variant: 'destructive'
            })
        }
    })

    const onHandleChangeFastStatus = async (value: ForceStatusValue) => {
        setForceStatus(value);
        return mutate({
            status: value.label,
            statusType: value._id
        })
    }

    var sendComment = useCallback(async () => {
        await commentRequestSale(contactid, presaleid, comment)
        queryClient.invalidateQueries({
            queryKey: ['request-sale-one']
        })
    }, [contactid, presaleid, queryClient, comment])

    const onContactChange = useCallback((name) => (e) => {
        setNewContactData({ ...newContactData, [name]: e.target.value })
    }, [setNewContactData, newContactData])

    var markAsSeen = useCallback(async () => {
        await watchRequestSale(contactid, presaleid)
        queryClient.invalidateQueries({
            queryKey: ['request-sale-one']
        })
    }, [queryClient, contactid, presaleid])

    let cantEdit = useMemo(() => {
        return !auditEnabled && requestSale?.data && _.get(requestSale, 'data.requestsale.statusType') != 'draft' && _.get(requestSale, 'data.requestsale.statusType') != 'resend'
    }, [auditEnabled, requestSale])

    let isResend = useMemo(() => {
        return _.get(requestSale, 'data.requestsale.statusType', '') == 'resend'
    }, [requestSale])

    const updatedAtDate = useMemo(() => {
        return _.get(requestSale.data, 'requestsale.updatedAt', '')
    }, [requestSale.data])

    const allowAdvisorsToChangeStatus = template?.data?.allowAdvisorsToChangeStatus;

    const statusOptions = useMemo(() => {
        let fieldName: string = ''

        if (isComercialUser) {
            fieldName = 'statusOptions'
        } else if (allowAdvisorsToChangeStatus && !isComercialUser) {
            fieldName = 'stateslistEnabledToChange'
        }

        if (!fieldName) return [];
        return (
            [..._.get(template.data, fieldName, []).map(({ status, title }) => ({
                _id: status,
                label: title
            }))
            ]
        )
    }, [template, allowAdvisorsToChangeStatus, isComercialUser])

    const handleCloseModal = () => {
        const savedData = _.get(requestSale, 'data.requestsale.content', {})
        const hasChanges = !_.isEqual(savedData, values)
        if (hasChanges) {
            return window.confirm('¿Estás seguro que deseas salir sin guardar los cambios?')
                ? setOpen(false)
                : null
        }
        setOpen(false)
    }

    const handleChangeRequestSaleProperties = (properties: Record<string, any>) => {
        setValues(lastValue => ({ ...lastValue, ...properties }))
    }

    return (
        <>
            <ForceSaveAlert
                onOpenChange={toggleForceSaveAlert}
                open={openForceSaveAlert}
                onContinue={() => {
                    setIsForceSave(true);
                    toggleForceSaveAlert();
                    saveAs(_.get(requestSale, 'data.requestsale.statusType', 'new'), true)();
                }}
            />
            <Modal
                open={!!setOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    height: '80%',
                    width: '80%',
                    margin: 'auto',
                    overflowY: 'auto',
                    borderRadius: '12px',
                    zIndex: openForceSaveAlert ? 49 : 99
                }}
            >
                <div className="flex flex-col h-full w-full bg-[#f2f2f2]">
                    <Grid item container pl={1} pt={1} direction="row" justifyContent={"space-between"}>
                        <Grid item>
                            <Stack direction={"row"}>
                                <Grid item>
                                    <IconButton onClick={handleCloseModal}>
                                        <ArrowBack />
                                    </IconButton>
                                </Grid>
                                <Grid item pl={1} pt={1} >
                                    <Typography fontSize={18} fontWeight={500}>
                                        {
                                            !!externalTitle
                                                ? externalTitle(template.data)
                                                : typeof presaleid == "string"
                                                    ? `Modificar ${template.data?.title ?? 'Solicitud'}`
                                                    : `Crear ${template.data?.title ?? 'Solicitud'}`
                                        }
                                    </Typography>
                                </Grid>
                            </Stack>
                        </Grid>
                        {isLoading ? <Stack direction={"row"} justifyContent="center" alignItems={"center"} sx={{ mr: 2 }}>
                            <Grid item mr={1.5} fontSize={14} color="#1876D1">
                                CARGANDO
                            </Grid>
                            <Grid item>
                                <CircularProgress size={14} />
                            </Grid>
                        </Stack> :
                            <Grid
                                pr={1}
                                pt={1}
                                display="flex"
                                alignItems="center"
                                gap="4px"
                                sx={{ flexDirection: 'row' }}
                            >
                                <div className="flex items-center mr-2 gap-2">
                                    <Switch
                                        checked={!!viewRequirementsCounter}
                                        onCheckedChange={toggleViewRequirementsCounter}
                                        className="w-[40px] h-[20px] [&>span]:w-[16px] [&>span]:h-[16px] "
                                    />
                                    <Label className={'text-xs'}>Conteo de campos requeridos</Label>
                                </div>
                                {
                                    (auditEnabled && requestSale?.data) ? (
                                        <>
                                            <ShareRequestSalePopover requestSale={requestSale?.data?.requestsale} setValues={setSharedUsers} />
                                            <Button sx={{ mr: 1 }} size="small" onClick={saveAs(_.get(requestSale, 'data.requestsale.statusType', 'new'))} >Guardar cambios</Button>
                                        </>
                                    ) :
                                        cantEdit ?
                                            (
                                                <div className="flex items-center gap-3">
                                                    {
                                                        allowAdvisorsToChangeStatus && !isComercialUser && (
                                                            <FormControl style={{
                                                                width: '130px',
                                                                backgroundColor: '#fff',
                                                                height: '35px'
                                                            }}>
                                                                <InputLabel id="selectRequestStatus">
                                                                    Estado
                                                                </InputLabel>
                                                                <Select
                                                                    value={forceStatus ? forceStatus.label : _.get(requestSale, 'data.requestsale.status', "")}
                                                                    renderValue={(value) => value}
                                                                    disabled={isPendingChangeFastStatus}
                                                                    style={{ height: '35px' }}
                                                                    labelId="selectRequestStatus"
                                                                    id="selectRequestStatusId"
                                                                    label="Estado"
                                                                >
                                                                    {
                                                                        statusOptions.map((status) => (
                                                                            <MenuItem
                                                                                key={status.label}
                                                                                onClick={() => onHandleChangeFastStatus(status)}
                                                                                value={status.label}
                                                                            >
                                                                                {status.label}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        )
                                                    }
                                                    {(!auditEnabled && isComercialUser || sharedCanEdit) && requestSale?.data && (
                                                        <IconButton sx={{ mr: 1 }} disabled={!enableSaveRequestSale} size="small" onClick={() => setAuditEnabled(true)}><Edit /></IconButton>
                                                    )}

                                                    <Button
                                                        size="small"
                                                        endIcon={<CheckCircleOutline sx={{ color: "#1876D1" }} />}
                                                        disabled={true} sx={{ mr: 1 }}> <span style={{ color: "#1876D1" }}>Enviado</span></Button>
                                                </div>
                                            ) : (
                                                <>
                                                    <ShareRequestSalePopover requestSale={requestSale?.data?.requestsale} setValues={setSharedUsers} />
                                                    <IconButton sx={{ mr: 1 }} size="small" disabled={!enableSaveRequestSale} onClick={saveAs((isResend ? 'resend' : 'draft'), true)}><Save /></IconButton>
                                                    <Button variant="contained" size="small" onClick={saveAs('new')} disabled={!isValid || !enableSaveRequestSale}>Enviar</Button>
                                                </>
                                            )}
                            </Grid>
                        }
                        <Grid item width={"100%"}>
                            <Tabs value={tab} onChange={handleTab} centered>
                                <Tab label="Solicitud" />
                                <Tab label={<Grid item>Comentarios{_.get(requestSale, 'data.requestsale.history', []).length > 0 && <Chip label={_.get(requestSale, 'data.requestsale.history', []).length} sx={{ ml: 0.5, fontSize: 12, p: 0 }} size="small" />}</Grid>} disabled={typeof presaleid != 'string'} />
                            </Tabs>
                        </Grid>
                    </Grid>
                    {tab == 0 ?
                        <Grid container item direction={"row"} justifyContent={"center"} sx={{ backgroundColor: "#fcfcfc", overflowY: 'auto', pb: '75px' }} >
                            {template.isLoading || (typeof presaleid == 'string' && !requestSale.data) ? (
                                <Loading />
                            )
                                : (template.data?.error) ? (
                                    <Grid container>
                                        <Grid item width={"100%"}>
                                            <Alert severity="error">{template.data.error}</Alert>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <>
                                        {auditMode &&
                                            <Grid container>
                                                <Grid item width={"100%"}>
                                                    <Alert severity="info">Estas en modo de procesamiento de solicitud </Alert>
                                                </Grid>
                                            </Grid>}
                                        {error && <Grid container>
                                            <Grid item width={"100%"}>
                                                <Alert severity="error">{error}</Alert>
                                            </Grid>
                                        </Grid>}
                                        {contactid == 'generate' && (
                                            <List sx={{ px: 2, pb: 2, width: '100%' }}>
                                                <Paper variant="outlined" sx={{ mb: 2, borderRadius: '8px' }} >
                                                    <ListItem sx={{ py: 2 }}>
                                                        <Grid container>
                                                            <Typography width={"100%"} fontWeight={600} fontSize={20} mb={2}>Datos del cliente</Typography>
                                                            <Grid container item spacing={2}>
                                                                <Grid item xs={12} >
                                                                    {contactAlert ? <Alert severity="error">{contactAlert}</Alert> : ''}
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <TextField fullWidth label={"Nombre"} autoComplete="off"
                                                                        required={true}
                                                                        value={newContactData.firstName}
                                                                        onChange={onContactChange('firstName')}
                                                                    />

                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <TextField fullWidth label={"Apellido"} autoComplete="off"
                                                                        value={newContactData.lastName}
                                                                        onChange={onContactChange('lastName')}
                                                                    />

                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <TextField fullWidth label={"Teléfono"} autoComplete="off"
                                                                        required={true}
                                                                        value={newContactData.phone}
                                                                        onChange={onContactChange('phone')}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <TextField fullWidth label={"Nota"} autoComplete="off"
                                                                        maxRows={4}
                                                                        multiline
                                                                        value={newContactData.detail}
                                                                        onChange={onContactChange('detail')}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                </Paper>
                                            </List>
                                        )}

                                        <List sx={{ p: 2, width: '100%' }}  >
                                            <ListFields
                                                requestSale={{
                                                    ..._.get(requestSale, 'data.requestsale', {}),
                                                    status: forceStatus ? forceStatus.label : _.get(requestSale, 'data.requestsale.status', "")
                                                }
                                                }
                                                handleChangeRequestSaleProperties={handleChangeRequestSaleProperties}
                                                viewRequirementsCounter={viewRequirementsCounter}
                                                updatedAt={updatedAtDate}
                                                defaultValues={defaultValues}
                                                requestsaleid={presaleid}
                                                fields={templateBody}
                                                contactid={contactid}
                                                disabled={cantEdit}
                                                onChange={onChange}
                                                values={values}
                                            />
                                        </List>

                                        {_.get(template, 'data.lineBody', []).length > 0 && (
                                            <List sx={{ p: 2, width: '100%' }}>
                                                <SubLine fields={_.get(template, 'data.lineBody', [])} values={lineValues} setValues={setLineValues} />
                                            </List>
                                        )}
                                        {
                                            (isTheRolEnabled(['super', 'comercial'])) && <List sx={{ px: 2, pb: 2, width: '100%' }}>
                                                <Paper variant="outlined" sx={{ mb: 2, borderRadius: '8px' }} >
                                                    <ListItem sx={{ py: 2 }}>
                                                        <Grid container>
                                                            <Typography width={"100%"} fontWeight={600} fontSize={20} mb={2}>Configuración de Solicitud</Typography>
                                                            <Grid container item spacing={2}>
                                                                {
                                                                    isTheRolEnabled(['super', 'comercial']) && (
                                                                        <Grid item xs={12} sm={6}>
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                value={agent || ""}
                                                                                onChange={onChangeAgent}
                                                                                disabled={get(isAuth(), 'config.useFlow', []).length != 0}
                                                                                noOptionsText="Sin opciones"
                                                                                options={[..._.get(userList, 'data.users', []).map((u) => ({ label: u.name + " " + u.lastname, _id: u._id }))]}
                                                                                renderInput={(params) => <TextField autoComplete="off" {...params} label={"Vendedor"} fullWidth />}
                                                                            />
                                                                        </Grid>
                                                                    )
                                                                }
                                                                {
                                                                    (isTheRolEnabled(['comercial'])) && (
                                                                        <Grid item xs={12} sm={6}>
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                value={forceStatus ?? _.get(requestSale, 'data.requestsale.status', "")}
                                                                                onChange={onChangeStatus}
                                                                                options={statusOptions}
                                                                                noOptionsText="Sin opciones"
                                                                                disabled={get(isAuth(), 'config.useFlow', []).length != 0}
                                                                                renderInput={(params) => <TextField autoComplete="off" {...params} label={"Estado"} fullWidth />}
                                                                            />
                                                                        </Grid>
                                                                    )
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                </Paper>
                                            </List>
                                        }
                                    </>
                                )
                            }

                        </Grid>
                        : <Grid item direction={"row"} justifyContent={"top"} sx={{ backgroundColor: "#fcfcfc", overflowY: 'auto', pb: '75px', p: 3, height: '100%' }} >
                            <Grid item sx={{ width: '100%', mb: 3, pb: 5 }} xs={12}>
                                <TextField label={"Comentario"} size="small" fullWidth value={comment} onChange={(e) => setComment(e.target.value)} />
                                <Button variant="contained" size={"small"} onClick={sendComment} disabled={comment.length == 0} sx={{ mt: 2, float: 'right' }} >Enviar</Button>

                                {_.get(requestSale, 'data.requestsale.unseen_history', 0) > 0 && _.get(isAuth(), 'roles', []).includes('comercial') &&
                                    <Button variant="contained" size={"small"} onClick={markAsSeen} sx={{ mt: 2, float: 'right', mr: 2 }} >Marcar como visto</Button>
                                }
                                {/* </Paper> */}
                            </Grid>
                            {[..._.get(requestSale, 'data.requestsale.history', [])].reverse().map((data) => {
                                return <Grid item sx={{ width: '100%' }} >
                                    <Paper variant="outlined" sx={{ borderRadius: '8px', width: '100%', padding: 2, mb: 1.5 }} >
                                        <b>{_.get(data, 'user.name', '')} {_.get(data, 'user.lastname', '')}</b> <span> comento <span style={{ width: '100%', color: "#888", fontSize: '12px' }}>{moment(_.get(data, 'date', new Date())).calendar()}</span></span>
                                        <Divider sx={{ mb: 1 }} />
                                        {data.content}
                                    </Paper>
                                </Grid>
                            })}
                        </Grid>
                    }
                </div>
            </Modal>
        </>
    )
}

RequestSaleAdd.defaultProps = {
    onAction: () => { }
}

export default RequestSaleAdd