import axios from "axios"
import _ from "lodash"
import { getCookie } from "../auth"
import { fetcher } from "@/helpers/fetcher"
const API = process.env.API
const API_NEXT = process.env.API_NEXT


export const startCallAircall = async (config: { user_id: string, phone_number_id: string, base64_token: string }, callTo: string) => {
    // return axios.post(`https://api.aircall.io/v1/users/${_.get(config, 'user_id', '')}/calls`, {
    return axios.post(`/api/aircall/startcall`, {
        user_id: _.get(config, 'user_id', ''),
        number_id: parseInt(config.phone_number_id),
        to: callTo,
        key: _.get(config, 'base64_token', '')
    }, {
        headers: {
            'Authorization': `Basic ${_.get(config, 'base64_token', '')}`
        },
    })

}

export const callRegestry = async (config: { user_id: string, phone_number_id: string, base64_token: string }, order = 'desc', from = undefined) => {
    // return axios.get(`https://api.aircall.io/v1/calls?order=${order}${from ? `&from=${from}` : ''}`, {
    return axios.post(`/api/aircall/calls`, { base64_token: _.get(config, 'base64_token', ''), order, from }, {
        headers: {
            'Authorization': `Basic ${_.get(config, 'base64_token', '')}`
        },
    })

}


export const uploadCalls = async (calls) => {
    return axios.post(`${API}/calls`, calls, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "JWT " + getCookie("token"),
        },
    })
}

export const getNumbersAircall = async (key: string) => {
    // return axios.get(`https://api.aircall.io/v1/numbers`, {
    return axios.post(`/api/aircall/numbers`, { key }, {

        headers: {
            'Authorization': `Basic ${key}`
        }
    })
}

export const uploadCallsFromAircall = async (config) => {
    let result = await callRegestry(config)
    let callsToUpload = result.data.calls.filter((call) => call.user.id + "" === config.user_id)
        .map((call) => {
            let rawType = call.direction == "inbound" ? 1 : call.direction == "outbound" ? 2 : 3
            let type = call.direction == "inbound" ? "INCOMING" : call.direction == "outbound" ? "OUTGOING" : "MISSED"

            return {
                rawType,
                type,
                dateTime: new Date(call.started_at * 1000),
                timestamp: call.started_at * 1000,
                phoneNumber: (call.raw_digits as string).replaceAll(' ', ''),
                duration: call.duration,
                name: 'AIRCALL call'
            }
        })

    await uploadCalls(callsToUpload)
}



export const getUsersAircall = async (key: string) => {
    return axios.post(`/api/aircall/users`, { key }, {
        headers: {
            'Authorization': `Basic ${key}`
        }
    })
}

export const savePhoneProviderConfig = async (config: any[]) => {
    return axios.patch(`${API_NEXT}/settings/phoneProviders`, { config }, {
        headers: {
            'Authorization': `JWT ${getCookie('token')}`
        }
    })
}

export const deletePhoneProvider = async (id: string) => {
    return fetcher<any>({
        url: `/settings/phoneProviders/${id}`,
        defaultContentType: true,
        throwError: false,
        method: 'PATCH'
    })
}

export const getUserChannels = () => {
    return fetcher<{ channels: any[], sucess: boolean }>({
        url: `/settings/phoneProviders/channels`,
        defaultContentType: true,
        throwError: false,
        method: 'GET'
    })
}