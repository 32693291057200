import { getUsersList, GetUserValue } from "@/actions/company/users"
import { role } from "@/models/User"
import { setUsersList } from "@/redux/slices/config"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

export type UserToMapType = Record<string, GetUserValue>;

interface Props {
    enabled?: boolean,
    queryKey?: string[],
    roles?: role[],
    saveOnGlobalState?: boolean
};

export default function useGetUsersList({
    enabled = true,
    queryKey = ['company-user-list'],
    saveOnGlobalState = true,
    roles
}: Props) {
    const [localUsers, setLocalUsers] = useState<GetUserValue[]>([])
    const users = useSelector((state: any) => state.config.usersList);

    const usersToMap: UserToMapType = useMemo(() => (saveOnGlobalState
        ? users
        : localUsers
    ).reduce((p, c) => ({ ...p, [c._id]: c }), {}), [users, localUsers])

    const dispatch = useDispatch()

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn: () => getUsersList({ roles }),
        enabled: (enabled && ((saveOnGlobalState && users.length === 0)) || !saveOnGlobalState)
    });

    useEffect(() => {
        if (data?.users) {
            setLocalUsers(data.users || []);
            if (saveOnGlobalState) dispatch(setUsersList(data.users));
        }
    }, [data, saveOnGlobalState])

    return {
        ...rest,
        users: (saveOnGlobalState ? users || [] : localUsers) as GetUserValue[],
        usersToMap
    }
}
