import { isAuth } from "@/actions/auth"
import { archive, remind } from "@/actions/crm/contact"
import _ from "lodash"
import { AlarmClock, ArchiveRestore } from "lucide-react"
import mixpanel from "mixpanel-browser"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { Button } from "../ui/button"

export default function ActionsToRemember({
  interactions,
  status,
  reminder,
  archivingReason,
  prospectID,
}) {
  const [hiddenReminder, setHiddenReminder] = useState(false)
  const [hiddenArchiving, setHiddenArchiving] = useState(false)
  const contentReasonArchivings = useMemo(
    () => _.get(isAuth(), "group.archivingReasons", []),
    []
  )

  useEffect(() => {
    setHiddenReminder(false)
    setHiddenArchiving(false)
  }, [interactions, prospectID])

  const verifyReminder = useMemo(() => {

    if (interactions.length === 0) return false
    const lastInteraction = interactions[0]
    if (lastInteraction && lastInteraction.type === 'reminder' && !!lastInteraction.content.reminderDate) return false;


    const lastReminder = interactions.find(
      (interaction) =>
        interaction.type === "reminder" && interaction.content.reminderDate
    )

    if (!lastReminder) return false
    const dateLastReminder = new Date(lastReminder.content.reminderDate)

    if (
      lastReminder &&
      dateLastReminder > new Date() &&
      (status !== "reminder" || !reminder) &&
      !archivingReason
    )
      return lastReminder

    return false
  }, [interactions])

  const verifyArchiving = useMemo(() => {
    const lastInteraction = interactions[0]
    if (lastInteraction && lastInteraction.type === 'archiving' && lastInteraction.content.to !== '') return false;

    const lastArchiving = interactions.find(
      (interaction) => interaction.type === "archiving" && interaction?.content?.to !== ""
    )

    if (!lastArchiving || interactions.length == 0) return false

    const verifyReasons = contentReasonArchivings.find((reason) => reason.code === lastArchiving.content.to)

    return !archivingReason && verifyReasons ? verifyReasons : false
  }, [contentReasonArchivings, interactions])

  const handleReminder = () => {

    if (verifyReminder) {
      const date = verifyReminder.content.reminderDate

      remind(prospectID, date).then(() => {
        mixpanel.track("Reminder created", { contactId: prospectID, date })
        setHiddenReminder(true)
      })
    }
  }

  const handleArchiving = () => {
    if (verifyArchiving) {
      const archivingReason = verifyArchiving.code

      archive(prospectID, archivingReason).then((response) => {
        if (response.error) {
          mixpanel.track("Archive created Error", {
            contactId: prospectID,
            reason: archivingReason,
            error: response.error,
          })
        } else {
          mixpanel.track("Archive created", {
            contactId: prospectID,
            reason: archivingReason,
          })
          setHiddenArchiving(true)
        }
      })
    }
  }

  return (verifyReminder || verifyArchiving) ? (
    <div className="flex items-center gap-3 top-5 relative  flex-wrap justify-center w-full">
      {verifyReminder && !hiddenReminder && !reminder && (
        <ButtonAction
          description={moment(verifyReminder.content.reminderDate).format(
            "dddd DD/MM/YY, HH:mm"
          )}
          icon={<AlarmClock size={15} />}
          label={"Volver a recordar"}
          onAction={() => handleReminder()}
        />
      )}
      {verifyArchiving && !hiddenArchiving && (!archivingReason || archivingReason == "") && (
        <ButtonAction
          description={`Razón: ${verifyArchiving.name}`}
          icon={<ArchiveRestore size={15} />}
          label={"Volver a archivar"}
          onAction={() => handleArchiving()}
        />
      )}
    </div>
  ) : <></>
}

export function ButtonAction({ icon, label, description, onAction }) {
  return (
    <Button
      className="flex flex-col items-center bg-gray-100 font-medium hover:bg-gray-200/70 md:h-[60px] h-[54px] justify-center md:px-7 px-5 shadow-bubble-right"
      variant={"secondary"}
      onClick={onAction}
    >
      <span className="flex items-center text-slate-800 md:text-[13px]  text-[12px] gap-[6px]">
        {icon}
        {label}
      </span>
      <span className="text-slate-500 capitalize  text-[11px]">
        {description}
      </span>
    </Button>
  )
}
