import { getCompany } from '@/actions/auth'
import { Button } from '@/components/ui/button'
import { CustomFormField } from '@/components/ui/custom-form-field'
import { AdditionalCompanyField } from '@/interfaces/Interface'
import _ from 'lodash'
import { MinusCircle, PlusCircle } from 'lucide-react'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import InputPhoneNumber, { PhoneStatusType } from '../../../ui/input-phone-number'
import { CompanySchemaType } from '../add-company-modal'

interface PhoneNumberFieldProps {
    field: AdditionalCompanyField,
    contextForm: UseFormReturn<CompanySchemaType>
}

type AdditionalPhoneField = `additionalData.${number}`

export default function PhoneNumberField({ contextForm, field }: PhoneNumberFieldProps) {

    const companyCountry = useMemo(() => _.get(getCompany(), 'country', 'UY'), [getCompany()]);
    const formKey = `additionalData.${field.code}`;

    const values = (() => {
        const fieldValue = contextForm.watch(formKey as AdditionalPhoneField)
        if (!fieldValue || (fieldValue as string[]).length == 0) return ['']
        return fieldValue
    })();

    const onHandleAddElement = () => {
        contextForm.setValue(formKey as AdditionalPhoneField, [...values, ''])
    }

    const onHandleDeleteElement = (inx: number) => {
        const allCopyValue = [...values]
        allCopyValue.splice(inx, 1);
        contextForm.setValue(formKey as AdditionalPhoneField, allCopyValue)
    }

    const onChangeState = (key: AdditionalPhoneField) => (state: PhoneStatusType) => {
        if (state == 'error') {
            return contextForm.setError(key, {
                message: 'El número de teléfono tiene que ser un válido.'
            })
        }

        if (state !== 'loading' && state !== 'reading') contextForm.clearErrors(key)
    }

    return (
        <div className='flex flex-col gap-2'>
            {
                [values].flat().map((__, inx: number) => {
                    const childrenKey = `${formKey}.${inx}`;
                    const isFirstElement = inx == 0

                    return <CustomFormField
                        name={childrenKey}
                        key={childrenKey}
                        isRequired={field.required}
                        control={contextForm.control}
                        label={inx === 0 ? field.name : ''}
                        fnElement={({ field }) => (
                            <InputPhoneNumber
                                value={field.value}
                                onChangeValue={field.onChange}
                                defaultCountry={companyCountry}
                                placeholder={`Teléfono ${inx + 1}`}
                                getStatus={onChangeState(childrenKey as AdditionalPhoneField)}
                                rightElement={
                                    <Button
                                        variant={'ghost'}
                                        type="button"
                                        onClick={() => isFirstElement ? onHandleAddElement() : onHandleDeleteElement(inx)}
                                        className="flex justify-center w-[32px] h-[32px] shrink-0 [&>svg]:w-[32px] [&>svg]:h-[32px] items-center px-2 text-neutral-400 rounded-full cursor-pointer"
                                    >
                                        {
                                            !isFirstElement
                                                ? <MinusCircle />
                                                : <PlusCircle />
                                        }
                                    </Button>
                                }
                            />
                        )}
                    />
                })
            }
        </div>
    )
}


