import { Button } from "@/components/ui/button"
import { setToggleCreateContact } from "@/redux/slices/crm"
import "moment/locale/es"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"
import { AddUser } from "../crm/Bubble/Icons"
import CreateContact from "../crm/ContactDrawer/CreateContactDrawer"
import { SideBarTooltip } from "./NavLink"

export default function BtnCreateContact({ isCollapsedMenu }) {
    const dispatch = useDispatch()
    const setCreateContact = (value) => dispatch(setToggleCreateContact(value))

    const openCreateContact = useSelector((state: any) => state.crm.openCreateContact)

    return <>
        <SideBarTooltip content={'Crear contacto'} active={isCollapsedMenu}>
            <Button
                className={`flex w-full text-[12px] h-[34px] rounded-[5px] ${isCollapsedMenu ? 'lg:h-[38px] lg:w-[42px] lg:bg-gray-200 lg:text-slate-500 lg:hover:bg-gray-200/60 lg:duration-100 lg:rounded-[4px]' : ''} whitespace-nowrap  truncate items-center gap-2`}
                onClick={() => setCreateContact(true)}
            >
                <AddUser className={`w-[18px] h-[18px]  shrink-0`} />
                <span className={`${!isCollapsedMenu ? 'flex' : 'lg:hidden'}`}>
                    Crear contacto
                </span>
            </Button>
        </SideBarTooltip>
        <CreateContact
            open={openCreateContact}
            setOpen={setCreateContact}
            onSave={() => {
                setCreateContact(null)
            }}
        />
    </>
}
