import { isAuth } from "@/actions/auth"
import { getEmails, getPresignedUrl } from "@/actions/config/email"
import { sendEmailToProspects } from "@/actions/crm/contact"
import { formatFile } from "@/components/settings/templates/ModalAddTemplates"
import { Avatar } from "@/components/ui/avatar"
import { CustomFormField } from "@/components/ui/custom-form-field"
import { Form, FormControl } from "@/components/ui/form"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { useUploadFile } from "@/hooks/useUploadImage"
import { handleRepyMessage, setAction } from "@/redux/slices/crm"
import "@/styles/scrollModify.css"
import { zodResolver } from "@hookform/resolvers/zod"
import { useQuery } from "@tanstack/react-query"
import { Editor } from "@tinymce/tinymce-react"
import axios from "axios"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { z } from "zod"
import { generateRandomId } from '../../../helpers/generateRandomId'
import { FileCharacteristics } from "../ChatInput/UploadImageModal"
import ButtonBack from "./ButtonBack"
import { CardFile } from "./CardFile"
import EmailFieldContent from "./EmailFieldContent"
import Footer from "./Footer"
import InputEmail from './InputEmail'
import SubjectField from "./SubjectField"
import EmailTemplateSelect from "./TemplateSelect"

export const schemaSendTransmiter = z.object({
    email: z.string().email(),
    name: z.string()
})

const schemaEmail = z.object({
    subject: z.string().min(1, { message: 'Requerido' }),
    to: z.array(schemaSendTransmiter),
    from: z.array(z.object({
        email: z.string().email(),
        name: z.string()
    })),
    replyTo: z.array(schemaSendTransmiter).optional(),
    body: z.string().optional(),
    attachments: z.array(z.unknown().optional()),
    cc: z.array(schemaSendTransmiter).optional(),
    bcc: z.array(schemaSendTransmiter).optional(),
})

const defaultValues = {
    subject: '',
    to: [],
    from: [],
    replyTo: [],
    body: '',
    attachments: [],
    bcc: [],
    cc: []
}

export default function EmailInput({ goBack, prospect = {}, dataProvider }: any) {
    const { handleDeleteFile, onDrop, values: files } = useUploadFile({ openModal: false })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [inputEmailValue, setValueInputEmail] = useState<string>('')
    const [inputCcValue, setValueInputCc] = useState<string>('')
    const [inputCcoValue, setValueInputCco] = useState<string>('')
    const [openTemplates, setOpenTemplates] = useState(false)
    const [templateMessage, setTemplateMessage] = useState<boolean | object>(false)
    const [withCopyActive, setWithCopyActive] = useState({ cc: true, cco: true });
    const [scheduleDate, setScheduleDate] = useState<string>('')

    const editorRef = useRef<any>(null);
    const { cc, cco } = withCopyActive || {}

    const dispatch = useDispatch()
    const informationOfMessageToReply = useSelector((state: any) => state.crm.replyMessage)
    const chatInteractions = useSelector((state: any) => state.crm.contact.interactions)

    const { data = [] } = useQuery({
        queryKey: ['get-nylas-email'],
        queryFn: getEmails
    })

    const contextForm = useForm<z.infer<typeof schemaEmail>>({
        resolver: zodResolver(schemaEmail),
        defaultValues: {
            ...defaultValues
        }
    })

    const attachments = contextForm.watch('attachments')

    const user = useMemo(() => isAuth(), [isAuth])
    const userFullName = `${user.name} ${user.lastname}`

    const userEmailProviders = useMemo(() => {
        return data?.map((provider) => provider.email)
    }, [data])

    const emailTemplates = useMemo(() => {
        const templates = user.templates || []
        return templates.filter((template) => template.channel === 'email')
    }, [user])

    useEffect(() => {
        if (chatInteractions?.length > 0 && !informationOfMessageToReply) {
            const emailInteractions = chatInteractions.filter((interaction) => interaction.type === 'email' && interaction.content.origin === 'agent')
            const lastInteraction = emailInteractions[0]
            if (lastInteraction?.content?.from[0] && lastInteraction.content.from[0].email) {
                const { email, name } = lastInteraction.content.from[0]
                contextForm.setValue('from', [{ email, name }])
            }
        }

        if (informationOfMessageToReply) {
            const emailToMatch = informationOfMessageToReply.recipients.find((recipient) => userEmailProviders.includes(recipient.email));
            if (emailToMatch) contextForm.setValue('from', [{ email: emailToMatch.email, name: emailToMatch.name }])

            contextForm.setValue('subject', `RE: ${informationOfMessageToReply.subject}`)
            contextForm.setValue('to', informationOfMessageToReply.from)
        } else {
            contextForm.setValue('to', [
                {
                    email: prospect?.emails[0],
                    name: `${prospect?.firstName} ${prospect?.lastName}`
                }
            ])

            if (userEmailProviders.length > 0) {
                contextForm.setValue('from', [{
                    name: userFullName,
                    email: userEmailProviders[0]
                }])
            }
        }
    }, [open, informationOfMessageToReply, chatInteractions, userEmailProviders])

    const postFilesToS3 = useCallback(async (blobInfo) => {
        let data = {
            filename: blobInfo.filename(),
            contentType: blobInfo.blob().type
        }

        const preSignedUrlS3AWS = await getPresignedUrl(data)
        return preSignedUrlS3AWS
    }, []);

    const handleSelectedEmail = (type, email) => {
        const conentEmailsActive = [...contextForm.getValues(type)]
        const findIndexEmail = conentEmailsActive.findIndex(to => to.email === email)

        if (findIndexEmail !== -1) {
            conentEmailsActive.length > 1 && conentEmailsActive.splice(findIndexEmail, 1)
        } else {
            conentEmailsActive.push({
                email: email,
                name: `${prospect.firstName} ${prospect.lastName}`
            })
        }
        contextForm.setValue(type, conentEmailsActive)
    }

    const handleSendEmail = async (values: z.infer<typeof schemaEmail>) => {
        setIsLoading(true)
        const contentBodyEmail = editorRef.current
        const body = contentBodyEmail.getContent()

        if (body.length === 0) contextForm.setError('body', { message: 'Requerido' })

        const dataInput = {
            'to': inputEmailValue,
            'cc': inputCcValue,
            'bcc': inputCcoValue
        }

        let fieldToVerify = ['bcc', 'cc', 'to'].map((field: any) => {
            const isStringNull = dataInput[field]
            if (isStringNull !== '' && !schemaSendTransmiter.safeParse({ name: '', email: isStringNull }).success) {
                contextForm.setError(field, { message: `El email "${isStringNull}" no es valido` })
                return false
            }
            return [field, isStringNull !== ''
                ? [...values[field], { name: '', email: isStringNull }]
                : [...values[field]]]
        })

        if (fieldToVerify.includes(false)) return

        let allDataToSend: Record<string, any> = {
            ...values,
            body,
            ...Object.fromEntries(fieldToVerify as Iterable<readonly [PropertyKey, any]>),
        }

        if (informationOfMessageToReply) {
            allDataToSend = {
                ...allDataToSend,
                replyMessageId: informationOfMessageToReply.messageId
            }
        }

        if (editorRef.current) {
            try {
                await sendEmailToProspects({
                    body: allDataToSend,
                    contactId: prospect._id.toString(),
                    idProvider: dataProvider.find((provider) => provider.email === allDataToSend.from[0].email)._id,
                    scheduleDate
                })
            } finally {
                setIsLoading(false)
                goBack()
            }
        }
    }

    const handleDeleteFileAndId = (inx: number, file: FileCharacteristics) => {
        handleDeleteFile(file)
        const allIds = [...contextForm.getValues('attachments')]
        allIds.splice(inx, 1)
        contextForm.setValue('attachments', allIds)
    }

    const handleClose = () => {
        if (informationOfMessageToReply) {
            dispatch(handleRepyMessage(false))
        }
        dispatch(setAction('note'))
    }

    const selectedEmail = contextForm.watch('from')

    const selectedSignature = () => {
        if (!selectedEmail || !selectedEmail[0]) return;
        const matchedEmail = data.find((provider) => provider.email === selectedEmail[0].email);
        if (matchedEmail) {
            return matchedEmail.signature;
        }
    };

    const contentToShow = informationOfMessageToReply && selectedSignature()
        ? `${informationOfMessageToReply.body}\n\n${selectedSignature()}`
        : informationOfMessageToReply
            ? informationOfMessageToReply.body
            : selectedSignature()
                ? selectedSignature()
                : '';

    if (openTemplates) {
        return <EmailTemplateSelect
            goBack={() => setOpenTemplates(false)}
            templates={emailTemplates}
            send={(template) => {
                const { message, files, subject } = template || {}

                const newFiles = files.map(file => {
                    const formated = formatFile(file, 'normal')

                    return {
                        ...formated,
                        imageUrl: formated.path,
                        filename: formated.name,
                        content_type: formated.type,
                        id: generateRandomId()
                    }
                })

                contextForm.setValue('attachments', [...attachments, ...newFiles])
                contextForm.setValue('subject', subject)
                setTemplateMessage(message)
                setOpenTemplates(false)
            }}
        />
    }

    return (
        <div className="bg-white flex-grow flex flex-col relative z-10">
            <div className="flex flex-grow relative">
                <div className="flex flex-col absolute h-full md:p-6 md:py-4 p-4 w-full top-0 left-0 z-10 overflow-y-auto  modifyScroll">
                    <div className="mb-2">
                        <ButtonBack onClick={handleClose} />
                    </div>
                    <Form {...contextForm}>
                        <form onSubmit={contextForm.handleSubmit(handleSendEmail)} id="formOfSendEmail">
                            <div className="flex flex-col gap-1">
                                <CustomFormField
                                    name="from"
                                    control={contextForm.control}
                                    fnElement={({ field }) => (
                                        <EmailFieldContent label={'De:'}>
                                            <div className="w-max relative">
                                                <Select
                                                    onValueChange={(value) => field.onChange([{
                                                        email: value,
                                                        name: userFullName
                                                    }])}
                                                    value={field.value[0]?.email}
                                                >
                                                    <FormControl className="w-fit">
                                                        <SelectTrigger className="px-[3px] py-[3px] pr-1 hover:bg-gray-100 hover:text-gray-700 duration-150 bg-gray-50 border-gray-200 text-gray-800 text-[12px] flex items-center gap-1 rounded-full focus:ring-0 focus:ring-offset-0 h-fit font-semibold" disabled={informationOfMessageToReply && field.value}>
                                                            <Avatar className="flex uppercase items-center text-[10px] justify-center text-white font-medium w-[19px] h-[19px] bg-primary">
                                                                {field.value[0]?.email ? field.value[0]?.email[0] : ''}
                                                            </Avatar>
                                                            <SelectValue className="text-gray-800" placeholder="Seleccioná un email" />
                                                        </SelectTrigger>
                                                    </FormControl>
                                                    <SelectContent>
                                                        {userEmailProviders?.map((email) => (
                                                            <SelectItem key={email} value={email}>
                                                                {email}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                        </EmailFieldContent>
                                    )} />

                                {
                                    informationOfMessageToReply && (
                                        <EmailFieldContent label={'Responder a:'} classNameContenChildren="py-3">
                                            <p className="text-[15px] font-semibold leading-none text-gray-800 tracking-tight">
                                                {informationOfMessageToReply.from[0].name}
                                            </p>
                                        </EmailFieldContent>
                                    )
                                }
                                <CustomFormField
                                    name="to"
                                    control={contextForm.control}
                                    fnElement={({ field }) => (
                                        <InputEmail
                                            handleSelectedEmail={(type, value) => handleSelectedEmail(type, value)}
                                            onChangeWithCopyActive={values => setWithCopyActive(values)}
                                            onChangeInputValue={(value) => setValueInputEmail(value)}
                                            withCopyActive={withCopyActive}
                                            prospectEmails={prospect.emails}
                                            inputValue={inputEmailValue}
                                            contextForm={contextForm}
                                            emails={field.value}
                                            label={'Para:'}
                                            type={'to'}
                                        />
                                    )}
                                />
                                <CustomFormField
                                    control={contextForm.control}
                                    name="cc"
                                    active={!cc}
                                    fnElement={({ field }) => (
                                        <InputEmail
                                            emails={field.value}
                                            inputValue={inputCcValue}
                                            onChangeInputValue={setValueInputCc}
                                            contextForm={contextForm}
                                            label={'Cc:'}
                                            type={'cc'}
                                        />
                                    )}
                                />
                                <CustomFormField
                                    control={contextForm.control}
                                    name="bcc"
                                    active={!cco}
                                    fnElement={({ field }) => (
                                        <InputEmail
                                            emails={field.value}
                                            inputValue={inputCcoValue}
                                            onChangeInputValue={setValueInputCco}
                                            contextForm={contextForm}
                                            label={'Cco:'}
                                            type={'bcc'}
                                        />
                                    )}
                                />
                                <CustomFormField
                                    control={contextForm.control}
                                    name="subject"
                                    fnElement={({ field }) => <SubjectField {...field} />}
                                />
                                {
                                    attachments.length > 0 && (
                                        <EmailFieldContent
                                            label={'Datos adjuntos:'}
                                            classNameContenChildren="border-none border-b-none"
                                        >
                                            {
                                                attachments.map((file, inx) => (
                                                    <div key={`${file}${inx}`}>
                                                        <CardFile
                                                            file={file as any}
                                                            handleDeleteFile={() => handleDeleteFileAndId(inx, file as any)} />
                                                    </div>
                                                ))
                                            }
                                        </EmailFieldContent>
                                    )
                                }
                            </div>
                            <div className="flex-grow w-full min-h-[500px] mt-4 relative">
                                <Editor
                                    onInit={(_, editor) => editorRef.current = editor}
                                    apiKey={process.env.NEXT_PUBLIC_TINY_EDITOR_API_KEY}
                                    initialValue={templateMessage ? `${templateMessage}\n\n${contentToShow}` : contentToShow}
                                    init={{
                                        width: '100%',
                                        language: "es",
                                        height: 500,
                                        images_upload_handler: (blobInfo, progress) => new Promise(async (resolve, reject) => {
                                            try {
                                                const preSignedUrl = await postFilesToS3(blobInfo);
                                                const file = await blobInfo.blob()
                                                const options = {
                                                    headers: { 'Content-Type': file.type },
                                                    onUploadProgress: (e) => {
                                                        progress(e.loaded / e.total * 100);
                                                    },
                                                };

                                                await axios.put(preSignedUrl, file, options);
                                                const imageUrl = preSignedUrl.split('?')[0];
                                                return resolve(imageUrl)
                                            } catch (error) {
                                                reject('Ocurrió un error al subir la imagen al servidor');
                                            }
                                        }),
                                        style_formats_autohide: true,
                                        style_formats_merge: true,
                                        menubar: false,
                                        plugins: 'anchor autolink codesample emoticons image link lists media table',
                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons | removeformat',
                                        tinycomments_mode: 'embedded',
                                        branding: false,
                                        welcome: false,
                                        statusbar: false,
                                        draggable_modal: false,
                                        inline_styles: true,
                                    }}
                                />
                            </div>
                        </form>
                    </Form>
                </div>
            </div>
            <Footer
                onChangeScheduleDate={(date) => setScheduleDate(date)}
                scheduleDate={scheduleDate}
                onHandleSaveFile={(res) => (
                    contextForm.setValue('attachments', [...contextForm.getValues('attachments'), ...res])
                )}
                onOpenTemplateSelected={setOpenTemplates}
                emailTemplates={emailTemplates}
                handleClose={handleClose}
                isLoading={isLoading}
                onDrop={onDrop}
                user={user}
            />
        </div>
    )
}
