import { getCompanyGroupList } from "@/actions/comercial/companygroup";
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from "react";

export interface IPreviewCompanyGroup {
    displayName: string,
    businessName: string,
    taxCode: string,
    _id: string
}

export default function useGetCompanyGroups({
    externalSearchParams,
    enabled = true,
    queryKey = ['get-companygroups-list'],
}: {
    externalSearchParams?: Record<string, any>,
    enabled?: boolean,
    queryKey?: string[],
}) {
    const [countDocuments, setCountDocuments] = useState<number>(0)
    const [companyGroups, setCompanyGroups] = useState<IPreviewCompanyGroup[]>([])
    const [searchParams, setSearchParams] = useState({
        limit: 50,
        page: 1
    })

    const searchParamUse = externalSearchParams || searchParams

    const { data, ...rest } = useQuery({
        queryFn: () => getCompanyGroupList(searchParamUse),
        queryKey: [...queryKey, searchParamUse],
        enabled
    })

    useEffect(() => {
        const { companyGroups, countDocuments } = data || {};
        if (companyGroups) setCompanyGroups(companyGroups)
        if (typeof countDocuments == 'number') setCountDocuments(countDocuments)
    }, [data])

    return {
        countDocuments,
        companyGroups,
        data,
        ...rest
    }
}
