import { useState } from "react";
import { useZxing } from "react-zxing";

export const BarcodeScanner = ({onCode}) => {
  const [result, setResult] = useState("");
  const { ref } = useZxing({
    onResult(result) {
      onCode(result.getText())
    },
  });

  return (
    <>
      <video ref={ref} />
    </>
  );
};

export default BarcodeScanner;