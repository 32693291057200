import { getCompany } from "@/actions/auth"
import { AddCompanyModal } from "@/components/comercial/company-groups/add-company-modal"
import useDebounce from "@/components/core/functions/useDebouce"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import InputWithSelect from "@/components/ui/input-with-select"
import { Label } from "@/components/ui/label"
import SimpleTooltip from "@/components/ui/simple-tooltip"
import { getCompanyGroupConfig } from "@/helpers/companyGroups"
import { Feature, isEnabledRolForCreateCompanyGroup, isFeatureEnabled } from "@/helpers/featureFlagHelpers"
import { useToggle } from "@/hooks/useToggle"
import { AdditionalCompanyField } from "@/interfaces/Interface"
import _ from "lodash"
import { Plus, Trash2 } from "lucide-react"
import { useCallback, useEffect, useMemo, useState } from "react"
import CompanyInformation from "./company-information"
import useGetCompanyGroups from "./hooks/useGetCompanyGroups"
import SectorSelector from "./sector-selector"

interface AffiliatedCompanyFieldProps {
    companyGroupsSelected: string[],
    onChange: (value: CompanyGroupFieldValue) => void,
    value: any,
    placeholder?: string,
    contact?: any,
    onDelete?: () => void,
    queryKey: string
}

export type CompanyGroupFieldValue = {
    companyGroup: {
        _id: string,
        displayName: string,
        businessName: string,
        taxCode: string
    } | undefined,
    role?: string | undefined,
    sector?: {
        name: string,
        _id: string
    } | undefined
}

interface SearchParams {
    page: number,
    limit: number,
    search: string
}

export default function AffiliatedCompanyField({
    companyGroupsSelected = [],
    onChange,
    value,
    placeholder,
    contact,
    onDelete,
    queryKey
}: AffiliatedCompanyFieldProps) {
    const [companiesList, setCompaniesList] = useState<any[]>([])
    const [hasMore, setHasMore] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useState<SearchParams>({ page: 1, limit: 20, search: '' });
    const [openAdd, toggleAdd] = useToggle<boolean>(false)
    const [searchValue, setSearchValue] = useState<any>('')
    const [localValue, setValue] = useState<any>(null);

    const debounceSearch = useDebounce(searchValue, 500);
    const { businessName, sector, sectors } = localValue || {};

    const { data: companiesData, isLoading, onHandleChangeCompanies } = useGetCompanyGroups({
        queryKey: [queryKey, searchParams],
        externalSearchParams: {
            ...searchParams,
            search: searchParams.search,
            not_id: companyGroupsSelected
        }
    })

    useEffect(() => {
        if (!companiesData) return;
        setHasMore(companiesData.length >= searchParams.limit);
        if (searchParams.page > 1) return setCompaniesList(lastValue => [...lastValue, ...companiesData]);
        return setCompaniesList(companiesData)
    }, [companiesData])

    useEffect(() => {
        if (!debounceSearch && !searchParams.search) return;
        setSearchParams(lastValue => ({ ...lastValue, page: 1, search: debounceSearch as string }))
    }, [debounceSearch])

    useEffect(() => {
        if (value.companyGroup) {
            setValue({ ...value, ...((value)?.companyGroup || {}), })
        }
    }, [value])

    const handleChangeValue = useCallback((newValue: any) => {
        if (!newValue) {
            onChange({
                companyGroup: undefined,
                sector: undefined,
                role: ''
            });
        }

        onChange({
            companyGroup: newValue,
            role: ""
        })

    }, [setValue, onChange, localValue])

    const handleChangeRole = (rol: string) => {
        if (!localValue) return;

        onChange({
            companyGroup: value?.companyGroup,
            sector: value?.sector,
            role: rol || ''
        })
    }

    const onHandleNextPage = () => {
        setSearchParams(lastValue => ({ ...lastValue, page: lastValue.page + 1 }))
    }

    const onHandleChangeSector = (sector) => {
        onChange({
            companyGroup: value?.companyGroup,
            sector: sector || undefined,
            role: value?.role || '',
        })
    }

    const addCompanyOption = useMemo(() => isEnabledRolForCreateCompanyGroup() && [
        {
            onSelectAction: toggleAdd,
            render: (
                <div
                    className='text-primary text-[13px] w-full h-full flex items-center font-medium'
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        toggleAdd()
                    }}
                >
                    <Plus className='mr-1' size={17} />
                    Añadir {getCompanyGroupConfig().title.singular.toLowerCase()}
                </div>
            )
        }
    ], [])

    const additionalCompanyFields: AdditionalCompanyField[] = useMemo(() => {
        return _.get(getCompany(), "additionalCompanyFields", [])
            .filter(field => field.active) as AdditionalCompanyField[]
    }, [])

    const companiesListUse = useMemo(() => {
        return companiesList.filter(({ _id: companyGroupId }) => {
            return !companyGroupsSelected.includes(companyGroupId)
        })
    }, [companiesList, companyGroupsSelected])

    return (
        <>
            <div className="w-full flex flex-col gap-2">
                <div className="flex items-center gap-2">
                    <InputWithSelect
                        multiple
                        strict={true}
                        maxElements={1}
                        isLoading={searchParams.page == 1 && isLoading}
                        infiniteScroll={{
                            loading: ((searchParams.page > 1) && isLoading),
                            dataLength: companiesListUse.length,
                            next: onHandleNextPage,
                            hasMore
                        }}
                        identifySelectedBy={'_id'}
                        downElements={addCompanyOption}
                        placeholder={`${placeholder || 'Seleccionar una opción'}`}
                        onChangeInputSearch={(value) => setSearchValue(value)}
                        onChangeValue={(value) => handleChangeValue((value as any[])[0])}
                        badgeClassName="hover:bg-blue-50/90 hover:text-blue-700 bg-blue-50 text-blue-800"
                        value={!!localValue ? [{ label: businessName, value: localValue }] : []}
                        options={companiesListUse.map((company) => ({ label: company.businessName, value: company }))}
                    />
                    <SimpleTooltip content={'Eliminar'}>
                        <Button
                            size={'icon'}
                            className="mb-2"
                            variant={'outline'}
                            onClick={onDelete}
                            type="button"
                        >
                            <Trash2 size={16} />
                        </Button>
                    </SimpleTooltip>
                </div>
                {
                    localValue && (
                        <>
                            {
                                (isFeatureEnabled(Feature.ENABLE_COMPANY_GROUP_SECTORS) && sectors) && (
                                    <div className="grid gap-2">
                                        <Label>
                                            Sector
                                        </Label>
                                        <SectorSelector
                                            companyGroup={localValue}
                                            sectorSelected={sector}
                                            onHandleChangeSector={onHandleChangeSector}
                                        />
                                    </div>
                                )
                            }

                            <div className="grid gap-2">
                                <Label>
                                    Rol
                                </Label>
                                <Input
                                    placeholder="Rol en la empresa"
                                    onChange={(e) => handleChangeRole(e.target.value)}
                                    value={value?.role || ''}
                                />
                            </div>
                        </>
                    )
                }
                <CompanyInformation
                    additionalCompanyFields={additionalCompanyFields}
                    companyGroup={localValue}
                />
            </div>
            <AddCompanyModal
                onClose={toggleAdd}
                open={openAdd as boolean}
                onSuccess={(value) => {
                    onHandleChangeCompanies([value, ...companiesList])
                    handleChangeValue(value)
                    setSearchValue('')
                }}
                templateField={{ contact }}
                defaultValues={{
                    businessName: searchValue
                }}
            />
        </>
    )
}