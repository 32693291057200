import { Skeleton } from "@/components/ui/skeleton";
import { CompanyGroupContact, ICompanyGroup } from "@/interfaces/Interface";
import { useMemo } from "react";
import { SectorSelected } from ".";
import ContactSkeleton from "./ContactSkeleton";
import ContactsListItem from "./ContactsListItem";
import SectionTitle from "./SectionTitle";
import ContactsAndSectorLoader from "./Loaders/ContactsAndSectorLoader";

interface ContactsAndSectorsListProps {
    onOpenAddContact: (sector?: SectorSelected | undefined) => void,
    company: ICompanyGroup | undefined,
    addedContactLoader?: [string | undefined, number] | undefined,
    onHandleChangeCompany: (value: ICompanyGroup) => void,
    isLoading?: boolean,
    currentUser: any,
}

export default function ContactsAndSectorsList(props: ContactsAndSectorsListProps) {
    const { company, onOpenAddContact, currentUser, addedContactLoader = [], onHandleChangeCompany, isLoading } = props;
    const { contacts = [], sectorsList = [] } = company || {};

    const { contactsWithOutSector, sectorsWithContacts }: {
        contactsWithOutSector: (CompanyGroupContact | 'loader')[],
        sectorsWithContacts: {
            contacts: (CompanyGroupContact | 'loader')[],
            name: string,
            id: string
        }[]
    } = useMemo(() => {
        const loaders: 'loader'[] = Array.from({ length: (addedContactLoader[1] || 0) as number }).map(() => 'loader')
        const sortedContacts: any[] = contacts.sort((a, b) => b.proximity - a.proximity);

        const disabledSectors = !company?.sectors || sectorsList.length == 0;

        if (addedContactLoader.length > 1 && !addedContactLoader[0] && disabledSectors) {
            sortedContacts.push(...loaders);
        };

        if (disabledSectors) return {
            contactsWithOutSector: sortedContacts,
            sectorsWithContacts: []
        };

        let contactsWithOutSector = sortedContacts.filter((contact) => !contact?.affiliatedCompanyGroup?.sector);
        let sectorsWithContacts: { id: string, name: string, contacts: (CompanyGroupContact | 'loader')[] }[] = [];

        if (addedContactLoader.length > 1 && !addedContactLoader[0]) contactsWithOutSector.push(...loaders);

        sectorsList.forEach((sector) => {
            const sectorContacts: (CompanyGroupContact | 'loader')[] = sortedContacts.filter((contact) => contact.affiliatedCompanyGroup.sector == sector._id);

            if (addedContactLoader[0] == sector._id && addedContactLoader[1]) {
                sectorContacts.push(...loaders);
            }

            sectorsWithContacts.push({
                id: sector._id,
                name: sector.name,
                contacts: sectorContacts
            })
        });

        return { contactsWithOutSector, sectorsWithContacts };
    }, [contacts, addedContactLoader]);

    const withSector = (company?.sectors && sectorsWithContacts.length > 0);

    const onChangeContactValue = (contact: CompanyGroupContact) => {
        const index = contacts.findIndex((c) => c._id == contact._id);
        if (index == -1) return;

        const allContacts = contacts;
        allContacts[index] = contact;
        onHandleChangeCompany({ ...company, contacts: allContacts } as ICompanyGroup);
    };


    if (isLoading) return <ContactsAndSectorLoader />;

    return (
        <div>
            {
                withSector && (
                    <SectionTitle className="mb-4">
                        Sectores
                    </SectionTitle>
                )
            }
            <div className="flex flex-col gap-5">
                {
                    sectorsWithContacts.map((sector) => <ContactsListItem
                        onOpenAddContact={() => onOpenAddContact({ id: sector.id, name: sector.name })}
                        onChangeContactValue={onChangeContactValue}
                        contacts={sector.contacts || []}
                        currentUser={currentUser}
                        title={sector.name}
                    />)
                }
                {
                    contactsWithOutSector.length > 0 && (
                        <ContactsListItem
                            currentUser={currentUser}
                            contacts={contactsWithOutSector}
                            onOpenAddContact={onOpenAddContact}
                            onChangeContactValue={onChangeContactValue}
                            title={withSector ? 'Contactos sin sectores' : 'Contactos'}
                            classNameTitle={withSector ? '' : 'text-gray-900 font-medium text-[18px]'}
                        />
                    )
                }
            </div>
        </div>
    )
}