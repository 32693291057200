import { InstancesService, TTypesOfIntences } from "@/actions/training";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
interface Props {
    type: TTypesOfIntences,
    options?: any,
    id?: string | null | undefined,
    lastInteraction?: boolean,
    onSuccessFn?: () => void,
    externalQueryKey?: Array<string>
    page?: number,
    size?: number,
    company?: null | string
}

type TActions = 'update_lastInteraction' | 'update' | 'create' | 'delete'
export default function useInstance({ type = 'instance', options = {}, id = '', lastInteraction = false, onSuccessFn, page, size, externalQueryKey, company = null }: Props) {

    const queryKey = externalQueryKey || [`all-instances-${type}`];
    const queryClient = useQueryClient()

    const { data, isLoading, isError, refetch } = useQuery({
        queryKey,
        queryFn: () => InstancesService.get({ type, id, lastInteraction, page, size, company }),
        ...options,
    });

    const { mutate, status, isError: isErrorMutation } = useMutation({
        mutationFn: ({ values, type, id }:
            { values?: any, type: TActions, id?: string }) => {

            const dictionaryOfFn = {
                'update': () => InstancesService.createOrUpdate({ body: values, id }),
                'create': () => InstancesService.createOrUpdate({ body: values }),
                'delete': () => InstancesService.delete(id),
                'update_lastInteraction': () => InstancesService.updateLastInteraction(id)
            }

            return dictionaryOfFn[type]()
        },
        onSuccess: () => {
            onSuccessFn && onSuccessFn()
            queryClient.invalidateQueries({
                queryKey
            })
        }
    })

    const isLoadingMutation = status === 'pending'

    const handleDeleteInstance = (id: string) => {
        mutate({
            id: id,
            type: 'delete'
        })
    }

    const handleUpdateLastInteraction = (id: string) => {
        mutate({
            id: id,
            type: 'update_lastInteraction'
        })
    }

    return {
        instances: data?.instances || [],
        isLoading,
        refetch,
        countDocuments: data?.countDocuments || 0,
        mutate,
        handleDeleteInstance,
        handleUpdateLastInteraction,
        isLoadingMutation,
        isErrorMutation,
        isError,
    }
}
