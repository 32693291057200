import { z } from "zod"

export const messageServerError = {
    1: 'La contraseña no debe ser un texto vacio',
    2: "La contraseña escrita no coincide con la actual",
    3: 'La contraseña es igual a la anterior'
}

export const changePasswordSchema = z.object({
    currentPassword: z.string().min(6, { message: 'La contraseña debe de contener por lo menos 6 caracteres' }),
    newPassword: z.string().min(6, { message: 'La contraseña debe de contener por lo menos 6 caracteres' }).regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/, { message: 'La contraseña no es válida' }),
    repeatPassword: z.string().min(1, { message: 'Debe de ingresar la nueva contraseña' })
})

export const defaultValuesChangePasswordSchema = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: ''
}

export type ChangePasswordSchema = z.infer<typeof changePasswordSchema>
