import { SmsForm } from "@/components/channels/AddChannel/libs/constants"
import { fetcher } from "@/helpers/fetcher"
import { SmsChannel } from "@/models/channels/types"

export type SmsTest = { phone: string | number, pin: string, accessToken: string }
export type TypeOfMsm = 'send' | 'reception'

interface SmsTestProps {
    body: Record<string, any>
    provider: string
}

export enum SmsProvider {
    SmsMensajeros = 'mensajeroAutomatico'
}

interface SmsChannelResponse extends SmsChannel {
    _id: string
}

const createSmsChannel = (config: any, provider: SmsProvider) => {
    return fetcher<{ channel: SmsChannelResponse }>({
        url: '/channels/sms/config',
        defaultContentType: true,
        method: 'POST',
        body: { ...config.agent, config: { ...config.config }, provider }
    })
}

const sendSmsWithMensajeros = ({ body, provider }: SmsTestProps) => {
    return fetcher<{ success: boolean, phone: string, data: Array<any> }>({
        url: `/channels/sms/message?provider=${provider}`,
        defaultContentType: true,
        method: 'POST',
        body
    })
}

const getSmsWithMensajeros = ({ accessToken, pin, provider }: { accessToken: string, pin: string, provider: string }) => {
    return fetcher<{ success: boolean, phone: string, data: Array<any> }>({
        url: `/channels/sms/message?provider=${provider}&accessToken=${accessToken}&pin=${pin}`,
        defaultContentType: true,
        method: 'GET',
    })
}

export {
    createSmsChannel,
    getSmsWithMensajeros,
    sendSmsWithMensajeros
}

