import useDebounce from '@/components/core/functions/useDebouce'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'
import validatePhoneNumber from '@/lib/validatePhoneNumber'
import { AlertCircleIcon, CheckCircle2, Loader2 } from 'lucide-react'
import React, { ReactNode, useEffect, useState } from 'react'

export const phoneStatus = {
    'success': <CheckCircle2 className="text-green-400 w-[15px] h-[15px]" />,
    'error': <AlertCircleIcon className="text-destructive w-[13px] h-[13px]" />,
    'loading': <Loader2 className="h-[15px] w-[15px] animate-spin" />
} as const

export type PhoneStatusType = keyof typeof phoneStatus | 'idle' | 'reading';

interface InputPhoneNumberProps {
    className?: string,
    defaultCountry?: string,
    timeout?: number,
    getStatus?: (value: PhoneStatusType) => void,
    onChangeValue?: (value: string) => void,
    value?: string | null,
    rightElement?: ReactNode,
    placeholder?: string
}

export default function InputPhoneNumber({
    className,
    defaultCountry = 'UY',
    timeout = 320,
    getStatus,
    onChangeValue,
    value = null,
    rightElement,
    placeholder = ''
}: InputPhoneNumberProps) {
    const [inputValue, setInputValue] = useState<string>('')
    const [status, setStatus] = useState<PhoneStatusType>('idle');

    const valueToUse = (value !== null) ? value : inputValue
    const debounceInputValue = useDebounce(valueToUse, 400);

    const [isError, isSuccess, isIdle, isLoading, isReading] = [
        status == 'error',
        status == 'success',
        status == 'idle',
        status == 'loading',
        status == 'reading',
    ]

    const onChangeValueToUse = onChangeValue || setInputValue

    const onHandleChangeStatus = (status: PhoneStatusType) => {
        getStatus && getStatus(status)
        setStatus(status);
    }

    useEffect(() => {

        const validatePhoneNumberState = async () => {
            if (!debounceInputValue) return onHandleChangeStatus('idle');
            setStatus('loading');

            await new Promise((resolve) => setTimeout(resolve, timeout))
            let statusToChange: PhoneStatusType = 'success';

            const validateNumber = validatePhoneNumber(valueToUse, defaultCountry);

            if (!validateNumber || !validateNumber?.isValid()) {
                statusToChange = 'error';
            } else {
                statusToChange = 'success'
            }

            onHandleChangeStatus(statusToChange);
        }

        validatePhoneNumberState()
    }, [debounceInputValue])

    const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        onHandleChangeStatus('reading')
        onChangeValueToUse(value)
    }

    return (
        <div className="w-full flex items-center justify-end relative">
            <Input
                type="text"
                placeholder={placeholder}
                className={cn(className, `${isError ? 'border-destructive' : ''} ${(!isIdle && !isReading) ? 'pl-8' : ''}`)}
                onChange={onHandleChange}
                value={valueToUse}
            />
            {
                (!isReading && !isIdle) && <div className={cn('absolute left-[10px]')}>
                    {phoneStatus[status] || null}
                </div>
            }
            {
                rightElement && (
                    <div className='absolute right-2'>
                        {rightElement}
                    </div>
                )
            }
        </div>
    )
}