"use client"
import useGetUnopenedNotifications from "@/hooks/user/useGetUnopenedNotifications";
import { UserBannerNotification, UserRightBottomNotification } from "@/interfaces/Interface";
import { setPreviewNotifications } from "@/redux/slices/global";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import BannerNotification from "./Cards/BannerNotification";
import RightBottomNotification from "./Cards/RightBottomNotification";
import dynamic from "next/dynamic";

const PortalNotifications = dynamic(() => import('./PortalNotifications'), {
    ssr: false
});

export default function NotificationsContainer() {
    const { unviewedNotifications, onChangeNotificationsList } = useGetUnopenedNotifications();

    const previewNotifications = useSelector((state: any) => state.global?.previewNotifications || []);
    const isPreviewMode = previewNotifications.length > 0;
    const dispatch = useDispatch();

    const { bannerNotifications, rightBottomNotifications } = useMemo(() => {
        let bannerNotifications: UserBannerNotification[] = [];
        let rightBottomNotifications: UserRightBottomNotification[] = [];

        (previewNotifications.length ? previewNotifications : unviewedNotifications).forEach((notification) => {
            if (notification.setting.type == 'banner') {
                bannerNotifications.push(notification);
            } else if (notification.setting.type == 'right-bottom-notification') {
                rightBottomNotifications.push(notification);
            }
        });

        bannerNotifications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        rightBottomNotifications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        return { rightBottomNotifications, bannerNotifications };
    }, [unviewedNotifications, previewNotifications]);

    const handleDeleteNotification = (index: number, type: 'banner' | 'right-bottom') => {
        let notificationsList = [...(type == 'banner' ? bannerNotifications : rightBottomNotifications)];
        notificationsList.splice(index, 1);

        const newNotificationList = [...(type == 'banner' ? rightBottomNotifications : bannerNotifications), ...notificationsList]

        if (isPreviewMode) return dispatch(setPreviewNotifications(newNotificationList));
        onChangeNotificationsList(newNotificationList);
    }

    return (
        <PortalNotifications>
            {
                bannerNotifications.length > 0 && (
                    <div className="fixed z-[49] top-0 left-0 w-full">
                        {
                            bannerNotifications.slice(0, 1).map((notification, index) => {
                                return (
                                    <BannerNotification
                                        isPreviewMode={isPreviewMode}
                                        notification={notification as UserBannerNotification}
                                        onCloseNotification={() => handleDeleteNotification(index, 'banner')}
                                        key={`${notification._id}${index}`}
                                    />
                                )
                            })
                        }
                    </div>
                )
            }
            {
                rightBottomNotifications.length > 0 && (
                    <div className="fixed z-[49] p-4 bottom-0 right-0 ">
                        <ul className="grid" style={{ gridTemplateColumns: "1fr" }}>
                            {
                                rightBottomNotifications.slice(0, 1).map((notification, index) => {
                                    return (
                                        <li key={`${notification._id}${index}`} style={{ gridRowStart: 1, gridColumnStart: 1 }}>
                                            <RightBottomNotification
                                                isPreviewMode={isPreviewMode}
                                                onCloseNotification={() => handleDeleteNotification(index, 'right-bottom')}
                                                notification={notification as UserRightBottomNotification}
                                                key={index}
                                            />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                )
            }
        </PortalNotifications>
    )
}