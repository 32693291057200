

import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle
} from "@/components/ui/sheet";
import { ModalPropsBase } from '@/interfaces/Interface';
import { Routes } from '@/routes';
import CreateContact from '../ContactDrawer/CreateContactDrawer';
import AddContactAlert from './AddContactAlert';
import CompanyGroupInfomation from './CompanyGroupInfomation';
import ContactsAndSectorsList from './ContactsAndSectorsList';
import HeaderLoader from './Loaders/HeaderLoader';
import SelecteableContactsSheet from './SelecteableContactsSheet';
import useCompanyGroupDrawer from './utils/hooks/useCompanyGroupDrawer';

interface CompanyGroupDrawerProps extends ModalPropsBase { id: string };
export type SectorSelected = { id: string, name: string };

export default function CompanyGroupDrawer({ id, open, onOpenChange }: CompanyGroupDrawerProps) {
    const {
        currentUser,
        onCloseContactCreationModal,
        onOpenAddContact,
        onSaveContactToCompany,
        serializeCompanyGroupValue,
        company,
        addExistingContact,
        addNewContact,
        addedContactLoader,
        isLoading,
        toggleAddNewContact,
        openAskAddContact,
        additionalCompanyFields,
        onHandleChangeCompany,
        toggleAddExistingContact,
        toggleAskContact,
        sectorSelectedForCreation,
        onSaveContactFromContactDrawer
    } = useCompanyGroupDrawer(id);

    const { businessName, displayName } = company || {};

    return (
        <>
            <SelecteableContactsSheet
                onSelected={onSaveContactToCompany}
                open={addExistingContact}
                companyGroupId={id}
                onOpenChange={() => {
                    toggleAddExistingContact();
                    toggleAskContact();
                }}
            />

            <AddContactAlert
                onAddExistingContact={toggleAddExistingContact}
                open={openAskAddContact && !(addExistingContact || addNewContact)}
                onAddNewContact={toggleAddNewContact}
                sector={sectorSelectedForCreation}
                onOpenChange={toggleAskContact}
            />

            <CreateContact
                open={addNewContact}
                redirectToContact={false}
                setOpen={onCloseContactCreationModal}
                onSave={onSaveContactFromContactDrawer}
                defaultPropertiesValues={{
                    affiliatedCompanyGroup: [
                        {
                            companyGroup: serializeCompanyGroupValue,
                            sector: sectorSelectedForCreation ? {
                                name: sectorSelectedForCreation.name,
                                _id: sectorSelectedForCreation.id
                            } : undefined
                        }
                    ]
                } as any}
            />

            <Sheet open={open && !(addExistingContact || addNewContact)} onOpenChange={onOpenChange} >
                <SheetContent
                    className='md:w-full shadow-none rounded-l-xl md:max-w-[740px] p-0 flex-col flex md:min-w-[740px]'
                    classNameCloseButton="right-7 top-5 [&>svg]:h-5 [&>svg]:w-5"
                    classNameOverlay='bg-black/50'
                >
                    <div className='flex flex-col flex-grow w-full relative'>
                        <div className='absolute top-0 left-0  w-full h-full px-6 pt-6 pb-4 overflow-y-auto'>
                            <SheetHeader className='pb-6'>
                                {
                                    isLoading
                                        ? <HeaderLoader />
                                        : (
                                            <div className="flex items-center flex-wrap gap-4">
                                                <div className="flex items-center gap-2 flex-wrap">
                                                    <SheetTitle className="text-[19px] inline">
                                                        {businessName}
                                                    </SheetTitle>
                                                    {displayName && (
                                                        <p className='text-[14px] inline text-gray-500'>
                                                            {displayName}
                                                        </p>
                                                    )}
                                                </div>
                                                <a
                                                    className='text-primary truncate text-[13px] hover:underline inline font-medium'
                                                    href={`${Routes.BUSSINES}/${company?._id}`}
                                                >
                                                    Ver empresa
                                                </a>
                                            </div>
                                        )
                                }
                            </SheetHeader>
                            <section className="grid gap-5">
                                <CompanyGroupInfomation
                                    additionalCompanyFields={additionalCompanyFields}
                                    onHandleChangeCompany={onHandleChangeCompany}
                                    isLoading={isLoading}
                                    company={company}
                                />
                                <ContactsAndSectorsList
                                    onOpenAddContact={(sector) => onOpenAddContact(sector)}
                                    onHandleChangeCompany={onHandleChangeCompany}
                                    addedContactLoader={addedContactLoader}
                                    currentUser={currentUser}
                                    isLoading={isLoading}
                                    company={company}
                                />
                            </section>
                        </div>
                    </div>
                </SheetContent>
            </Sheet>
        </>
    )
};