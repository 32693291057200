import { TrendingDown, TrendingUp, TrendingUpDown } from "lucide-react";

export const proximityData = {
    'veryLow': {
        label: 'Muy baja',
        color: '#ef4444',
        icon: <TrendingDown size={16} />
    },
    'low': {
        label: 'Baja',
        color: '#fb923c',
        icon: <TrendingDown size={16} />
    },
    'medium': {
        label: 'Media',
        color: '#fcd34d',
        icon: <TrendingUpDown size={16} />
    },
    'high': {
        label: 'Alta',
        color: '#a3e635',
        icon: <TrendingUp size={16} />
    },
    'veryHigh': {
        label: 'Muy alta',
        color: '#a3e635',
        icon: <TrendingUp size={16} />
    }
};

export const proximityDescriptions = [
    {
        ...proximityData['veryLow'],
        description: 'Comunicación mínima, casi inexistente, con muy poca cercanía.'
    },
    {
        ...proximityData['low'],
        description: 'Comunicación esporádica, con bajo nivel de interacción y cercanía limitada.'
    },
    {
        ...proximityData['medium'],
        description: 'Comunicación regular, con una cercanía moderada y contacto consistente.'
    },
    {
        ...proximityData['high'],
        description: 'Comunicación frecuente, con una buena cercanía y alto nivel de interacción.'
    },
    {
        ...proximityData['veryHigh'],
        description: 'Comunicación constante, con una cercanía muy fuerte y presencia continua.'
    }
];

export const getProximityData = (proximity: number) => {
    if (proximity <= 0.2) return proximityData['veryLow']
    if (proximity > 0.2 && proximity < 0.4) return proximityData['low'];
    if (proximity > 0.4 && proximity < 0.6) return proximityData['medium'];
    if (proximity > 0.6 && proximity < 0.9) return proximityData['high'];
    return proximityData['veryHigh'];
}