import { cn } from '@/lib/utils';
import React from 'react';
import sanitizeHtml, { IOptions } from 'sanitize-html';

export interface IFormatHtmlProps {
    sanatizeOptions?: IOptions;
    className?: string;
    children: string;
}

const FormatHtml = React.forwardRef<HTMLDivElement, IFormatHtmlProps>(({
    sanatizeOptions,
    className,
    children,
    ...props
}) => {
    const defaultSanitizeOptions: IOptions = {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'p', 'strong', 'b', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'span', 'ul', 'ol', 'li', 'colgroup', 'col', 'table', 'tbody', 'tr', 'td', 'br', 'video', 's', 'em', 'source']),
        allowedAttributes: {
            ...sanitizeHtml.defaults.allowedAttributes,
            img: ['src', 'alt', 'width', 'height'],
            a: ['href', 'title', 'data-mce-selected'],
            video: ['src', 'alt', 'width', 'height', 'controls', 'poster'],
            source: ['src', 'type'],
            '*': ['class', 'style', 'data-mce-style', 'id']
        },
    };

    const finalSanitizeOptions = sanatizeOptions ? { ...defaultSanitizeOptions, ...sanatizeOptions } : defaultSanitizeOptions;
    const sanatizeString = sanitizeHtml(children.replace(/\n/g, '<br />'), finalSanitizeOptions);
    return <div {...props} dangerouslySetInnerHTML={{ __html: sanatizeString }} className={cn(className)} />;
});

export default FormatHtml;
