import { getContactsByPhone } from '@/actions/comercial/sale'
import useDebounce from '@/components/core/functions/useDebouce'
import InputWithSelect from '@/components/ui/input-with-select'
import React, { useEffect, useState } from 'react'

export default function GetContactInput({ contextForm, field, disabled = false }) {
    const [matchedContacts, setMatchedContacts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectContactName, setSelectContactName] = useState(contextForm.watch('phone'))

    const phoneValue = contextForm.watch('phone')
    const inputValueDebounce = useDebounce(phoneValue as string, 500)

    useEffect(() => {
        if (inputValueDebounce !== null && selectContactName !== inputValueDebounce) {
            const getContacts = async () => {
                setIsLoading(true)
                await getContactsByPhone(inputValueDebounce).then((resultado) => {
                    setMatchedContacts(resultado.contacts)
                }).catch((error) => {
                    console.log(error)
                }).finally(() => {
                    setIsLoading(false)
                })
            }
            getContacts()
        }
    }, [inputValueDebounce])

    const handleOnSelectContact = (contact) => {
        if (contact) {
            const { firstName, phone, govId, _id } = contact
            contextForm.setValue('phone', phone)
            contextForm.setValue('name', firstName)
            govId && contextForm.setValue('gov_id', govId)
            setSelectContactName(phone)
        }
    }

    return (
        <InputWithSelect
            disabled={disabled}
            strict={true}
            maxElements={1}
            isLoading={isLoading}
            identifySelectedBy={'_id'}
            placeholder={'Teléfono'}
            onChangeInputSearch={(value) => {
                setSelectContactName('')
                field.onChange(value)
            }}
            onChangeValue={(value) => handleOnSelectContact(value)}
            value={selectContactName || []}
            options={matchedContacts.map((contact: any) => ({ label: contact.firstName, value: contact }))}
            closeWhenSelected={true}
        />
    )
}
