import { CardTitle } from '@/components/ui/card'
import Link from 'next/link'
import { IInstance } from '@/actions/training'
import React, { Suspense } from 'react'
import { cn } from '@/lib/utils'
import './index.css'
import { CardSkeleton } from './CardOfClass'
const CardOfClass = React.lazy(() => import('./CardOfClass'))

export default function CarrouselOfClassVideo({ title, hrefViewMore, videos = [], type, className = '' }: { title?: string, hrefViewMore?: string, videos: Array<IInstance>, type: 'tutorials' | 'instances', className?: string }) {
    return <section className={cn(className)}>
        {
            (title || hrefViewMore) && <div className='w-full justify-between flex items-center mb-2'>
                {
                    title && <CardTitle className='text-[17px] text-primary'>
                        {title}
                    </CardTitle>
                }
                {
                    hrefViewMore && <Link href={hrefViewMore} className='text-[12px] text-primary font-medium'>
                        Ver todos
                    </Link>
                }
            </div>
        }

        <ul className='content-items'>
            {
                videos.map(data => (
                    <li key={data.title}>
                        <Suspense fallback={<CardSkeleton />} >
                            <CardOfClass
                                information={data}
                                type={type} />
                        </Suspense>
                    </li>
                ))
            }
        </ul>
    </section>
}