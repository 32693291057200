import { ItemField } from "@/components/grupos/notification/DialogAddNewNotification"
import { Button, buttonVariants } from "@/components/ui/button"
import { CustomFormField } from "@/components/ui/custom-form-field"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog"
import { Form } from "@/components/ui/form"
import { DateTimePicker } from "@/components/ui/Time-picker/date-time-picker"
import { DateTimePickerForm } from "@/components/ui/Time-picker/popover-date-time-picker"
import { ModalPropsBase } from '@/interfaces/Interface'
import { zodResolver } from "@hookform/resolvers/zod"
import { VariantProps } from 'class-variance-authority'
import dayjs, { Dayjs } from "dayjs"
import { useForm } from "react-hook-form"
import { z } from "zod"
import ButtonReminderOption from "../BottomActions/Reminder/ButtonReminderOption"
import { CalendarDays, MessageCircle } from "lucide-react"
import { TitleQuestion } from "@/components/QuestionTitle"
import { Card, CardDescription, CardTitle } from "@/components/ui/card"
import { defaultDates } from "../BottomActions/Reminder/defaultValuesOfDate"
import { useCallback, useMemo, useState } from "react"
import { ManualDateButton } from "../BottomActions/Reminder/ManualDateButton"

export enum TYPES_OF_SELECTION {
    MANUAL = "manual",
    PREDETERMINED = "predetermined",
    CALENDAR = "calendar",
}

interface ScheduledModalProps extends ModalPropsBase {
    onSuccess?: (date: string) => void
}

const SchemaScheduledMessage = z.object({
    programmedDate: z.object({
        date: z.date(),
        type: z.string()
    })
})

type SchemaScheduledMessageType = z.infer<typeof SchemaScheduledMessage>

export default function ScheduledModal({
    onOpenChange,
    open,
    onSuccess
}: ScheduledModalProps) {
    const [optionsDate, setOptionsDate] = useState(defaultDates)
    const [manualValue, setManualValue] = useState<null | Date>(null)
    const [isOpenEditDate, setEditDate] = useState<
        [Date, number, number] | null | any
    >(null)

    const contextForm = useForm<SchemaScheduledMessageType>({
        resolver: zodResolver(SchemaScheduledMessage),
        defaultValues: {
            programmedDate: {
                date: new Date(),
                type: TYPES_OF_SELECTION.MANUAL
            },
        }
    })

    const currentDate: Dayjs = useMemo(() => dayjs(), [])

    const onSubmit = (values: SchemaScheduledMessageType) => {
        const { date } = values.programmedDate
        if (date.getTime() <= new Date().getTime()) return contextForm.setError('programmedDate', {
            message: 'La fecha seleccionada tiene que ser superior a la actual'
        })

        onSuccess && onSuccess(date.toISOString())
        handleClose()
    }

    const handleSelectedDate = useCallback((date: Dayjs | Date, field: any, type) => {
        contextForm.clearErrors()
        field.onChange({
            date: dayjs(date).toDate(),
            type
        })
    }, [])

    const handleClose = () => {
        contextForm.reset({
            programmedDate: {
                date: new Date(),
                type: TYPES_OF_SELECTION.MANUAL
            }
        })
        onOpenChange(false)
    }

    return (
        <Dialog open={open} onOpenChange={() => handleClose()}>
            <DialogContent className="sm:max-w-[580px] flex flex-col min-h-[50vh] p-0">
                <DialogHeader className="px-5 pt-5">
                    <div className="flex flex-row gap-1 items-center">
                        <MessageCircle size={14} />
                        <DialogTitle>
                            Programar mensaje
                        </DialogTitle>
                    </div>
                </DialogHeader>
                <div className="grid gap-4 flex-grow py-4 px-5">
                    <Form {...contextForm}>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                contextForm.handleSubmit(onSubmit)(e)
                            }}
                            id="scheduledMessage"
                        >
                            <CustomFormField
                                isRequired
                                name="programmedDate"
                                label="Fecha y hora"
                                control={contextForm.control}
                                classNameMessageError="hidden"
                                fnElement={({ field }) => (
                                    <ItemField>
                                        <div className="flex flex-col gap-7">
                                            <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                                                <DateTimePicker
                                                    date={manualValue || new Date()}
                                                    setDate={(date) => {
                                                        handleSelectedDate(dayjs(date), field, TYPES_OF_SELECTION.MANUAL)
                                                        setManualValue(date)
                                                    }}
                                                    elementTigger={
                                                        <div className="w-full">
                                                            <ButtonReminderOption onAction={() => {
                                                                manualValue && handleSelectedDate(
                                                                    manualValue,
                                                                    field,
                                                                    TYPES_OF_SELECTION.MANUAL
                                                                )
                                                            }}
                                                                className={`${field.value.type === TYPES_OF_SELECTION.MANUAL
                                                                    ? "bg-primary hover:bg-primary  text-white hover:text-white"
                                                                    : ""
                                                                    }`
                                                                }
                                                            >
                                                                <CalendarDays />
                                                                {manualValue
                                                                    ? dayjs(manualValue).format("DD/MM/YYYY HH:mm")
                                                                    : "Asignar manualmente"}
                                                            </ButtonReminderOption>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            <div className="flex flex-col gap-4">
                                                <TitleQuestion>
                                                    Horarios predeterminados
                                                </TitleQuestion>
                                                <div className="flex flex-col gap-2">
                                                    {
                                                        optionsDate.map(({ isCurrentDay, options, title, format }, inxContainer) => (
                                                            <div className="flex items-center gap-2" key={title}>
                                                                <Card className="text-center h-[60px] flex flex-col justify-center items-center w-[50px] md:w-[70px] shrink-0 border-none shadow-none">
                                                                    <CardTitle className="font-medium text-[15px] md:leading-[1.2] leading-[1]">
                                                                        {title}
                                                                    </CardTitle>
                                                                    {
                                                                        isCurrentDay && (
                                                                            <CardDescription className="text-[10px]">
                                                                                {currentDate.format("DD/MM/YYYY")}
                                                                            </CardDescription>
                                                                        )
                                                                    }
                                                                </Card>
                                                                <div
                                                                    className="pl-2 grid  gap-2"
                                                                    style={{ gridTemplateColumns: 'repeat(4,1fr)' }}>
                                                                    {
                                                                        options.map(({ date, ...rest }, inxOption) => (
                                                                            <ManualDateButton
                                                                                format={format}
                                                                                key={rest.title}
                                                                                handleEditDate={(date: Date) => setEditDate([date, inxContainer, inxOption])}
                                                                                className={`${inxOption > 2 ? "sm:flex hidden" : ""}`}
                                                                                date={date}
                                                                                currentDate={currentDate}
                                                                                field={field}
                                                                                handleSelectedDate={handleSelectedDate}
                                                                                functionsToApply={rest.functionsToApply}
                                                                                title={rest.title}
                                                                                type={TYPES_OF_SELECTION.PREDETERMINED}
                                                                            />
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </ItemField>
                                )}
                            />
                        </form>
                    </Form>
                </div>
                <CustomDialogFooter
                    onSuccess={() => onSubmit(contextForm.getValues())}
                    onCancel={() => handleClose()}
                    label='Programar'
                    options={{
                        buttonSize: 'xs',
                        disabledCancelButton: false,
                        buttonType: 'button'
                    }}
                />
            </DialogContent>
        </Dialog>
    )
}


interface CustomDialogFooterProps {
    label: string,
    onCancel?: () => void,
    onSuccess?: () => void,
    form?: string,
    options: Partial<{
        disabledCancelButton?: boolean,
        buttonSize: VariantProps<typeof buttonVariants>['size'],
        buttonType: 'submit' | 'button'
    }>
}

export function CustomDialogFooter({
    options,
    label,
    onCancel,
    onSuccess,
    form,
}: CustomDialogFooterProps) {
    const { disabledCancelButton, buttonSize = 'default', buttonType = 'submit' } = options || {}
    return <DialogFooter className='p-0 bg-gray-50 border-t space-y-2 px-5 py-3 border-t-gray-300'>
        {
            !disabledCancelButton && (
                <Button
                    type="button"
                    size={buttonSize}
                    variant={'outline-primary'}
                    onClick={() => onCancel && onCancel()}
                >
                    Cancelar
                </Button>
            )
        }
        <Button
            form={form}
            type={buttonType}
            size={buttonSize}
            onClick={(e) => {
                e.stopPropagation();
                onSuccess && onSuccess()
            }}
        >
            {label}
        </Button>
    </DialogFooter>
}