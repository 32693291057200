
import { getCompany } from "@/actions/auth";
import { createCompanyGroup, updateCompanyGroup } from "@/actions/comercial/companygroup";
import { getCompanyGroupConfig } from "@/helpers/companyGroups";
import { Feature, isFeatureEnabled } from "@/helpers/featureFlagHelpers";
import { AdditionalCompanyField } from "@/interfaces/Interface";
import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { CompanySchemaType } from "../../add-company-modal";

interface UseCreateCompanyGroupProps {
    contextForm: UseFormReturn<CompanySchemaType>,
    isModifyBussiness?: boolean,
    onSuccess: (data: any) => void
    companyGroupId?: string
}

export default function useCreateCompanyGroup({
    contextForm,
    isModifyBussiness,
    onSuccess,
    companyGroupId
}: UseCreateCompanyGroupProps) {
    const disableMandatoryFieldsInCompanies = isFeatureEnabled(Feature.DISABLE_MANDATORY_FIELDS_IN_COMPANIES)

    const { mutate, ...rest } = useMutation({
        onSuccess: onSuccess,
        mutationFn: ({ type, data }: { type: 'update' | 'created', data: Record<string, any> }) => {
            if (type === 'created') return createCompanyGroup([data].flat())
            return updateCompanyGroup(data, companyGroupId as string)
        }
    })

    const { defaultFields } = getCompanyGroupConfig();

    const validateIfValidForm = (values: CompanySchemaType): boolean => {
        const requiredFields: Set<string> = new Set(['displayName', 'taxCode']);

        let valid: boolean = true;
        for (let { hidden, key } of defaultFields) {
            if (requiredFields.has(key) && (hidden || disableMandatoryFieldsInCompanies)) requiredFields.delete(key);

            if (hidden || disableMandatoryFieldsInCompanies) continue;

            const value = values[key];
            if (value) continue;

            valid = false
            break
        }

        if (!disableMandatoryFieldsInCompanies) {
            Array.from(requiredFields.values()).forEach((key: string) => {
                const value = values[key];
                if (!value) return valid = false;
            })
        }

        return valid
    }

    const additionalCompanyFields: AdditionalCompanyField[] = useMemo(() => {
        return _.get(getCompany(), "additionalCompanyFields", [])
            .filter(field => field.active) as AdditionalCompanyField[]
    }, [])

    const handleSubmit = async (values: CompanySchemaType) => {
        contextForm.clearErrors()
        let newValues = { ...values }
        let additionalData = { ...(newValues.additionalData || {}) }

        const documentsFields = ['selectcontact', 'multiplecontact', 'selectcompany', 'multiplecompany']
        for (let { type, code } of additionalCompanyFields) {
            if (!additionalData[code]) continue;
            if (documentsFields.includes(type)) {
                additionalData[code] = (type === 'selectcontact' || type === 'selectcompany')
                    ? additionalData[code]._id
                    : additionalData[code].map(op => op._id);
            }
        }

        newValues['additionalData'] = additionalData

        let hasError: boolean = false;
        const requiredFields: Set<string> = new Set(['displayName', 'taxCode']);

        for (let { key, hidden } of defaultFields) {
            if (requiredFields.has(key) && hidden) requiredFields.delete(key);
            if (hidden) continue;

            const value = newValues[key];

            if (!value) {
                hasError = true
                contextForm.setError(key, {
                    message: 'Este campo es requerido'
                })
            }
        }

        if (!disableMandatoryFieldsInCompanies) {
            Array.from(requiredFields.values()).forEach((key: string) => {
                const value = newValues[key];
                if (!value) {
                    hasError = true
                    contextForm.setError(key as 'taxCode', {
                        message: 'Este campo es requerido'
                    })
                }
            })
        }

        if (hasError) return;

        mutate({
            type: isModifyBussiness ? 'update' : 'created',
            data: newValues
        })
    }

    return {
        handleSubmit,
        additionalCompanyFields,
        validateIfValidForm,
        ...rest
    }
}
