import { Button } from "@/components/ui/button"
import { Building2, RefreshCcwDot } from "lucide-react"
import "moment/locale/es"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"
import { Separator } from "../ui/separator"
import { MyCompanyModal } from "./ChangeAccount/MyCompanyModal"
import { OtherCompanyModal } from "./ChangeAccount/OtherCompanyModal"
import { SideBarTooltip } from "./NavLink"

export default function ChangeAccountBtn({ isCollapsedMenu }) {

    return (
        <SideBarTooltip content={'Cambiar cuenta'} active={isCollapsedMenu}>
            <Popover>
                <PopoverTrigger className="w-full">
                    <Button
                        variant={"ghost"}
                        type="button"
                        className={`flex w-full text-[13px]  px-2 h-[34px] hover:bg-blue-100 justify-start ${isCollapsedMenu ? 'lg:h-[38px] lg:w-[42px] justify-center' : 'h-[30px]'} rounded-[4px] whitespace-nowrap  truncate items-center gap-2`}
                    >
                        <RefreshCcwDot className="shrink-0 text-[#6B7280]" size={17} />
                        <span className={`${!isCollapsedMenu ? 'flex' : 'lg:hidden'}`}>
                            Cambiar cuenta (Admin)
                        </span>
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-2" side="right">
                    <div className="flex flex-col gap-1">
                        <MyCompanyModal dialogTrigger={
                            <Button
                                variant="ghost"
                                className="flex items-center justify-start gap-2 hover:text-primary"
                            >
                                <Building2 size={14} />
                                <span className="text-[13px]">Empresa actual</span>
                            </Button>
                        }
                        />
                        <Separator orientation="horizontal" className="w-full" />
                        <OtherCompanyModal dialogTrigger={
                            <Button
                                variant="ghost"
                                className="flex items-center justify-start gap-2 hover:text-primary"
                            >
                                <Building2 size={14} />
                                <span className="text-[13px]">Otra empresa</span>
                            </Button>
                        }
                        />
                    </div>
                </PopoverContent>
            </Popover>
        </SideBarTooltip>
    )
}
