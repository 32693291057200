import { isAuth } from '@/actions/auth'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import _ from 'lodash'
import { ArrowRight, MessagesSquare } from 'lucide-react'
import { useRouter } from 'next/router'

export default function SubConversationCreatedBubble({ interaction, prospect }) {
  const { subConversation } = prospect || {}
  const { contact } = subConversation || {}
  const { content, agent } = interaction || {}
  const { body, toAgent, contact: subConversationContact } = content || {}

  return <>
    <CardSubConversation
      agent={agent}
      body={body}
      toAgent={toAgent}
      cardHeader={
        (
          <div className={`flex gap-6 w-full ${contact ? 'justify-center' : 'justify-between'} items-center `}>
            <SubConversationTitle />
            {
              !contact && <ViewSubConversationButton
                contactId={subConversationContact}
              />
            }
          </div>
        )
      }
    />
  </>
}

export function ViewSubConversationButton({ contactId }) {
  const router = useRouter()

  const handleCLick = () => {
    const { query: existingQuery } = router;
    const newQuery = { id: contactId }

    router.push({
      pathname: '/contact',
      query: { ...existingQuery, ...newQuery },
    });
  }

  if (!isAuth().roles.includes('comercial') || !isAuth().roles.includes('super')) return null

  return <button
    onClick={handleCLick}
    className='flex text-[13px] sm:text-[14px] items-center group font-medium text-primary px-0 h-auto gap-1'
  >
    Ver
    <ArrowRight size={17} className='group-hover:translate-x-[1px] duration-75' />
  </button>
}

export function SubConversationTitle({ label = "Sub-Conversación" }: { label?: string }) {
  return <CardTitle className='flex text-[13px] sm:text-[14px] items-center gap-2 text-gray-700'>
    <MessagesSquare className='w-[19px] h-[19px]' strokeWidth={2} />
    {label}
  </CardTitle>
}

export function CardSubConversation({
  cardHeader,
  agent,
  toAgent,
  body
}: {
  cardHeader: JSX.Element,
  agent: Record<string, any>,
  toAgent?: Record<string, any> | undefined,
  body?: string | undefined
}) {
  const getFullName = (obj) => `${_.get(obj, 'name', '')} ${_.get(obj, 'lastname', '')}`

  return <div className='w-full flex items-center justify-center'>
    <Card className='min-w-[80%] md:min-w-[60%] shadow-bubble-phone lg:min-w-[45%] sm:max-w-[900px] lg:max-w-[900px] max-w-[900px] sm:min-w-[50%] bg-white border-gray-300'>
      <CardHeader className='py-[4px] w-full px-3.5'>
        {cardHeader}
      </CardHeader>
      <CardContent className='pt-0 px-3.5 pb-2'>
        <Separator className='w-full bg-gray-300' />
        <div className='flex flex-col py-2 gap-2'>
          <DescriptionItem
            description={getFullName(agent)}
            label={'Creada por: '}
          />
          {
            toAgent && <DescriptionItem
              description={getFullName(toAgent)}
              label={'Asignada a: '}
            />
          }
        </div>
        <SubConversationNote note={body} />
      </CardContent>
    </Card>
  </div>
}

export function SubConversationNote({ note }: { note: string | undefined | null }) {
  if (!note) return null

  return <>
    <Separator className='w-full bg-gray-300' />
    <div className='pt-2'>
      <DescriptionItem
        description={note}
        label={'Nota:'}
      />
    </div>
  </>
}

export function DescriptionItem({ label, description }) {
  return <p className='text-gray-700 flex items-start gap-1 font-medium sm:text-[14px] text-[13px]'>
    {label} <span className='font-normal'>{description}</span>
  </p>
}