import { useRef, useState } from 'react'
import EmailFieldContent from './EmailFieldContent'

export default function SubjectField(field: any) {
  const [focus, setFocus] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleBlur = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  return (
    <EmailFieldContent
      label={'Asunto:'}
      onMouseDown={handleBlur}
      classNameContenChildren={`w-full cursor-text flex border-b ${focus ? 'border-b-primary' : 'border-b-gray-200'} duration-200 h-full  flex-grow`}
    >
      <input
        {...field}
        autoFocus
        type="text"
        autoComplete="off"
        ref={inputRef}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        className="outline-none px-1 text-[16px] w-full font-medium placeholder:font-normal text-gray-800 flex-1 focus:outline-none border-none flex-grow h-full"
      />
    </EmailFieldContent>
  )
}
