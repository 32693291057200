'use client'
import useGetArchivingReason from "@/hooks/useGetArchivingReason"
import { Archive } from "lucide-react"
import mixpanel from "mixpanel-browser"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { createInteraction } from "../Prospect/libs/helpers/interaction"
import BadgePill from "./BadgePill"

interface ArchivePill {
    reason: string
    contactId: string
}

const ArchivePill = ({ reason, contactId }: ArchivePill) => {
    const { reason: reasonData } = useGetArchivingReason({ code: reason })

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatch = useDispatch()

    const cancelArchive = () => {
        setIsLoading(true)
        createInteraction({
            dispatch,
            type: 'archiving',
            data: {
                id: contactId,
                onSuccess: () => {
                    mixpanel.track("UnArchive created", { contactId, reason: "" })
                    setIsLoading(false)
                },
                values: {
                    from: reasonData.code || 'unarchive',
                    to: ''
                }
            }
        })
    }

    if (Object.keys(reasonData || {}).length === 0) return null;

    return (
        <BadgePill isLoading={isLoading} onClickBtn={cancelArchive} withActionButton>
            <Archive size={15} className="mr-1" />
            <span>{reasonData?.name || reason}</span>
        </BadgePill>
    )
}

export default ArchivePill