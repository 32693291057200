import { getSalesOfContact } from "@/actions/contacts"
import { Receipt } from "@/interfaces/Interface"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"

export default function useGetSaleOfContact({ contactId }: { contactId: string }) {
    const [receipts, setReciepts] = useState<Receipt[]>([])

    const { data, isLoading } = useQuery({
        queryFn: () => getSalesOfContact(contactId),
        queryKey: ['contact-sales', contactId],
        enabled: !!contactId
    });

    useEffect(() => {
        if (!data) return;
        setReciepts(data?.receipts || []);
    }, [data]);

    return {
        isLoading,
        receipts
    }
}
