import { fetcher } from "@/helpers/fetcher";

interface DefaultProps {
    id: string
    via?: string
    content: {
        message: string
    }
}

interface SendSmsProps extends DefaultProps {
    provider: 'mensajeroAutomatico'
    config: {
        accessToken: string,
        pin: string | number
    }
}

const sendSms = ({
    id,
    provider,
    config,
    via = 'web',
    content
}: SendSmsProps) => {
    return fetcher({
        url: `/crm/interaction/sms?id=${id}&provider=${provider}`,
        body: {
            content,
            via,
            config
        },
        defaultContentType: true,
        method: 'POST'
    })
}

export { sendSms };

