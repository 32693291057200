

import { ICompanyGroup } from '@/interfaces/Interface';
import RedirectToEditCompanyButton from './RedirectToEditCompanyButton';

interface CompaniesFieldItemProps {
    company: ICompanyGroup | undefined,
    companiesGroups: { businessName: string, _id: string }[]
}

export default function CompaniesFieldItem(props: CompaniesFieldItemProps) {
    const { company, companiesGroups = [] } = props;

    return (
        <ul className="flex items-center flex-wrap gap-x-4 gap-1.5">
            {
                companiesGroups.filter((companyGroup) => companyGroup && companyGroup?._id).map((companyGroup, inx) => {
                    return (
                        <li className="flex items-center" key={companyGroup?._id || inx}>
                            <p className="text-[13.5px] text-gray-700">
                                {companyGroup?.businessName}
                            </p>
                        </li>
                    )
                })
            }
            <RedirectToEditCompanyButton companyId={company?._id || ''} />
        </ul>
    )
}
