import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import _ from 'lodash'
import { useState } from 'react'
import ProspectSubMenu from '../ProspectSubMenu'
import { DescriptionItem, SubConversationTitle, ViewSubConversationButton } from './SubConversationCreatedBubble'

export default function SubConversationClosedBubble({ interaction }) {
    const [viewSubConversation, setViewSubConversation] = useState<false | string>(false)
    const { content, agent } = interaction || {}
    const { body, subConversationId, archivingReasonText } = content || {}
    const getFullName = (obj) => `${_.get(obj, 'name', '')} ${_.get(obj, 'lastname', '')}`

    return <>
        <div className='w-full flex items-center justify-center'>
            <Card className='min-w-[80%] md:min-w-[60%] lg:min-w-[45%] sm:max-w-[900px] lg:max-w-[900px] max-w-[900px] sm:min-w-[50%] bg-gray-50 border-gray-300'>
                <CardHeader className='py-[4px] w-full px-3.5'>
                    <div className='flex items-center gap-4 justify-between w-full'>
                        <SubConversationTitle label={"Sub-Conversación finalizada"} />
                        <ViewSubConversationButton
                            contactId={subConversationId}
                        />
                    </div>
                </CardHeader>
                <CardContent className='pt-0 px-3.5 pb-2'>
                    <Separator className='w-full bg-gray-300' />
                    <div className='flex flex-col pt-2 gap-2'>
                        <DescriptionItem
                            description={archivingReasonText}
                            label={'Razon de finalización: '}
                        />
                        <DescriptionItem
                            description={getFullName(agent)}
                            label={'Realizado por: '}
                        />
                        {
                            !!body && <DescriptionItem
                                description={body}
                                label={'Nota: '}
                            />
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
        <ProspectSubMenu
            onClose={() => setViewSubConversation(false)}
            open={viewSubConversation}
        />
    </>
}