import { namesOfOptions } from "@/constants/modalReminder"
import { ReminderData } from "@/models/crm/Contact"
import { AlarmClock } from "lucide-react"
import moment from "moment"
import { useMemo, useState } from "react"
import BadgePill from "./BadgePill"
import mixpanel from "mixpanel-browser"
import { cancelreminder } from "@/actions/crm/contact"
import { useDispatch } from "react-redux"
import { handleUpdate } from "@/redux/slices/crm"

moment.locale('es')

interface ReminderPill {
    reminder: ReminderData & ({ reminder: string }),
    contactId: string
}

const ReminderPill = ({ reminder, contactId }: ReminderPill) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { actionType, reminderDate, comment, reminder: reminderLegacy } = reminder || {}
    const dataOfReminder = useMemo(() => namesOfOptions?.[actionType as any], [reminder, namesOfOptions, actionType])

    const dispatch = useDispatch()

    const cancelReminder = async () => {
        setIsLoading(true)
        await cancelreminder(contactId)
            .then((result) => {
                if ('events' in result) {
                    for (let event of result.events) dispatch(handleUpdate(event))
                }
            })
            .finally(() => setIsLoading(false))

        mixpanel.track("Reminder cancel", { contactId: contactId })
    }

    if (!reminder.reminder) return null;

    return (
        <BadgePill isLoading={isLoading} onClickBtn={cancelReminder} withActionButton>
            <AlarmClock size={16} />
            <span>{reminderDate ? moment(reminderDate).format('LLL') : moment(reminderLegacy).format('LLL')}</span>
            {actionType && (
                <span className="[&>svg]:w-[15px] [&>svg]:h-[15px] flex gap-1 items-center">
                    <span className="lg:block hidden">- </span>{" "}
                    {actionType !== "other" && dataOfReminder?.icon}{" "}
                    {dataOfReminder?.name || ""}
                </span>
            )}
        </BadgePill>
    )
}

ReminderPill.defaultProps = {
    onClick: () => { }
}

export default ReminderPill