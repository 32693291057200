interface StickerProps {
    url: string,
}

export default function Sticker({ url }: StickerProps) {
    return <div className={`md:w-[200px] md:h-[200px] w-[180px] h-[180px] overflow-hidden relative rounded-[8px]`}>
        <img
            src={url}
            alt="Sticker"
            className="object-cover w-full h-full"
        />
    </div>
}