import { fetcher } from "@/helpers/fetcher"
import { ReactionType, Reactions } from '../constants/reactions';
import { IAccountManager } from "@/models/AccountManager";


interface UserPost {
    user: string,
    lastInteraction,
    reaction: string
    viewed: boolean
}

export interface IComment {
    _id: string,
    content: string,
    user: IPost['agent'],
    coach?: IAccountManager,
    createdAt: string | Date
    visibility: boolean
    replies: Array<IComment>
    updatedAt: string | Date
}

export interface ICommentReply extends IComment {
    reply_to: ICommentReply
    parentComment: string
    restOfCommentCount: number
    
}
export interface IPost extends IDateData {
    _id: string,
    agent: {
        _id: string,
        name: string,
        lastname: string,
        email: string
    },
    comments?: Array<IComment>
    restOfCommentCount?: number
    user: UserPost
    reactions: UserPost[]
    users: UserPost[]
    content: string
    title: string
}

export interface IInstance extends IDateData {
    agent: IPost['agent']
    description: string
    duration: number
    date: Date | string
    linkOfClass: string
    type: 'tutorial' | 'instance'
    miniature: string
    title: string
    countViewed: number
    countNotViewed: number
    countUsers: number
    users: [
        {
            name: string,
            lastname: string,
            viewed: boolean,
            lastInteraction?: string | null
        }
    ]
    _id: string
}

interface IDateData {
    readonly createdAt: string | Date
    updatedAt: string | Date
}

class HeadersDefault {
    headers() {
        return ({
            "Content-type": "application/json"
        })
    }
}

export class Comment extends HeadersDefault {
    public readonly url: string = '/coach/post'

    public getComment({ page, limit, idComment, post_id, idsToDiscriminate }) {
        const url = `${this.url}/comment?limit=${limit}&page=${page}&${idComment
            ? `&idComment=${idComment}`
            : `&post_id=${post_id}`}${idsToDiscriminate
                ? `&idsToDiscriminate=${idsToDiscriminate}`
                : ''}`

        return fetcher<{ comments: Array<ICommentReply | IComment>, documentCount: number }>({
            url,
            options: {
                method: 'GET',
            }
        })
    }

    public comment({ id, content, reply_to, coach, parentComment }) {
        return fetcher({
            url: `${this.url}/comment?id=${id}${reply_to ? `&reply_to=${reply_to}` : ''}&coach=${coach}${parentComment ? `&parentComment=${parentComment}` : ''}`,
            options: {
                method: 'POST',
                body: JSON.stringify({ content }),
                headers: this.headers()
            }
        })
    }

    public deleteComment({ id }) {
        return fetcher<{ delete: ICommentReply }>({
            url: `${this.url}/comment?id=${id}`,
            options: {
                method: 'DELETE'
            }
        })
    }

    public visibility({ id, state }) {
        return fetcher({
            url: `${this.url}/comment?id=${id}&visibility=${state}`,
            options: {
                method: 'PATCH'
            }
        })
    }

}

export class PostController extends Comment {
    public readonly url: string = '/coach/post'

    public getByCoacher({ offset = 1, limit = 10, company }) {
        return fetcher<{ posts: IPost[], countDocuments: number }>({
            url: `${this.url}/interaction?offset=${offset}&limit=${limit}${company ? `&company=${company}` : ''}`,
            options: {
                method: 'GET'
            }
        })
    }

    public reaction({ id, reactionType }: { id: string, reactionType: string }) {
        return fetcher({
            url: `${this.url}/interaction?id=${id}&reaction=${reactionType}`,
            options: {
                method: 'PATCH'
            }
        })
    }

    public createOrUpdate({ body, id }) {
        return fetcher({
            url: `${this.url}${id ? `?id=${id}` : ''}`,
            options: {
                method: id ? 'PATCH' : 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        })
    }

    public delete(id: string) {
        return fetcher({
            url: `${this.url}?id=${id}`,
            options: {
                method: "DELETE",
            }
        })
    }
}

export type TTypesOfIntences = 'instance' | 'tutorial'
export class InstancesController<T> extends HeadersDefault {
    readonly url: string

    constructor(url: string) {
        super()
        this.url = url
    }

    public get({ type = 'instance', id, lastInteraction, size = 10, page = 1, company = null }: { type: TTypesOfIntences, lastInteraction?: boolean, id?: string | null | undefined, page?: number, size?: number, company?: string | null | undefined | any  }) {

        return fetcher<T>({
            url: `${this.url}/interaction?limit=${size}&page=${page}&type=${type}${id ? `&id=${id}` : ''}${lastInteraction ? '&lastInteraction=true' : ''}${company ? `&company=${company}` : ''}`,
            options: {
                method: 'GET'
            }
        })
    }

    public createOrUpdate<T>({ body, id }: { body: T, id?: string }) {
        return fetcher({
            url: `${this.url}${id ? `?id=${id}` : ''}`,
            options: {
                method: id ? 'PATCH' : 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        })
    }

    public delete(id) {
        return fetcher({
            url: `${this.url}?id=${id}`,
            options: {
                method: "DELETE"
            }
        })
    }

    public updateLastInteraction(id) {
        return fetcher({
            url: `${this.url}/interaction?id=${id}`,
            options: {
                method: "PATCH"
            }
        })
    }
}

export const PostService = new PostController()
export const InstancesService = new InstancesController<{ instances: IInstance[], countDocuments: number }>('/coach/instance')
export const CommentService = new Comment()