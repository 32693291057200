import { WhatsApp } from "@mui/icons-material";
import { Button, Chip, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import AdvancedModal from "../../core/AdvancedModal"


interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const SelectChannel = ({open, setOpen}: Props) => {


    return (
        <AdvancedModal
          open={open}
          title="Canales disponibles"
          handleClose={() => setOpen(false)}
        >
          <List sx={{ width: '100%' }}>
            <ListItem sx={{ width: '100%' }}>
              <ListItemButton onClick={() => window.open("https://wa.me/573003000000", "_blank")}>
                <ListItemIcon>
                  <WhatsApp />
                </ListItemIcon>
                <ListItemText primary="Ventas" />
                <ListItemSecondaryAction>
                  <Chip color="primary" label="Activo" />
                </ListItemSecondaryAction>
              </ListItemButton>
            </ListItem>
          </List>
          <Grid container justifyContent={"center"}>
            <Button onClick={() => setOpen(false)}>Cerrar</Button>
          </Grid>
        </AdvancedModal>
    )
}

export default SelectChannel