import { Button } from "@/components/ui/button";
import { CardDescription } from "@/components/ui/card";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { channelConfig } from "@/helpers/getTypeNote";
import downloadMultipleFiles from "@/hooks/useDownloadMultipleFiles";
import { INoteBubble, channelConfigTypes } from "@/interfaces/Interface";
import { cn } from "@/lib/utils";
import { LocalStatus } from "@/redux/libs/ts/interfaces";
import { handleSetLocalStatus } from "@/redux/slices/crm";
import { Download, FileBarChart2, UsersRound } from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { StyledLinkifyerLines } from "../../StyledLinkifyer";
import { createInteraction } from "../Prospect/libs/helpers/interaction";
import { ICreateInteraction } from "../Prospect/libs/interfaces";
import { IconCircle } from "./Icons";
import InteractiveMessageCard, { InteractonStatusType } from "./InteractiveMessageCard";
import SharedToolTip from "./visit/SharedToolTip";

export const NoteBubble = ({
  channel,
  profile,
  noteType = 'note',
  interaction,
  subTitle,
  shared = [],
}: INoteBubble) => {
  const [openDialogImageFullScreen, setOpenDialogImageFullScreen] = useState<boolean | string>(false)
  const { backgroundHeader, backgroundContent, icon, label } =
    channelConfig[channel as channelConfigTypes] || channelConfig.default;

  const {
    content,
    createdAt,
    agent,
    localStatus,
    _id: interactionId,
    contact,
  } = interaction || {};

  const { body, comment, action, files } = content || {};
  const date = new Date(createdAt);

  const dispatch = useDispatch();

  const handleRetry = () => {
    dispatch(
      handleSetLocalStatus({
        localId: (interactionId as any),
        status: LocalStatus.LOADING,
      })
    );

    const objRetry: ICreateInteraction = {
      dispatch,
      type: noteType,
      isRetry: true,
      data: {
        action,
        localId: interactionId,
        id: contact,
        note: body,
        files
      },
    }

    if (noteType === 'visit') {
      objRetry.data = { values: content, localId: interactionId, id: contact }
    }

    createInteraction(objRetry);
  };

  const handleDownloadFiles = (files) => downloadMultipleFiles(files)

  return (
    <InteractiveMessageCard
      status={(localStatus as InteractonStatusType) || 'idle'}
      retryFn={handleRetry}
      profile={profile}
      origin="agent"
      agent={agent}
      messageError="Se ha producido un error"
      className={cn('md:max-w-[53%] lg:max-w-[55%]   2xl:max-w-[35%]')}
      styleContent={{
        backgroundColor: backgroundContent
      }}
    >
      <DialogImageFullScreen open={openDialogImageFullScreen} onClose={() => setOpenDialogImageFullScreen(false)} />
      <div className={` flex flex-col flex-grow `}>
        <div
          className="text-[#1F2937] font-medium  justify-between flex items-start md:items-center gap-10 flex-grow overflow-hidden py-2 px-3"
          style={{ backgroundColor: backgroundHeader }}
        >
          <div className="flex flex-col md:flex-row md:items-center max-w-[100%] overflow-hidden">
            <div className="flex items-center gap-1">
              <span className="[&>svg]:w-[13px] [&>svg]:h-[13px] sm:[&>svg]:w-4 sm:[&>svg]:h-4">
                {icon}
              </span>
              <span className="text-[12px] md:text-[13px]">
                {interaction?.content?.title || label}
              </span>
            </div>
            {
              subTitle && (
                <>
                  <span className="text-[13px] hidden md:block mx-1">
                    <IconCircle className="text-gray-600" />
                  </span>
                  <span className="text-[10px] truncate md:text-[11px] text-gray-600">{subTitle}
                  </span>
                </>
              )
            }
          </div>
          <span className="text-[11px] md:text-[12px]">
            {moment(date).format("LT")}
          </span>
        </div>
        <div
          className="pt-2 pb-4 px-4 flex  flex-col flex-grow gap-1 md:gap-2"
          style={{
            overflowWrap: "break-word",
            backgroundColor: backgroundContent,
          }}
        >
          <CardDescription className="text-neutral-800 sm:text-[14px] text-[12px] font-medium ">
            <StyledLinkifyerLines text={body || subTitle} />
          </CardDescription>

          {files && files.length > 0 && (
            <React.Fragment>
              <div className="flex flex-col lg:flex-row items-start lg:justify-between lg:items-center">
                <p className="text-[11px] md:tex-[12px] lg:text-[13px] font-medium">{files.length} {files.length > 1 ? 'Archivos adjuntos' : 'Archivo adjunto'}</p>
                <Button variant={'link'} size={'xs'} className="gap-1 flex items-center text-[11px] md:tex-[12px] lg:text-[13px]" type="button" onClick={() => handleDownloadFiles(files)}><Download size={14} strokeWidth={2} />Descargar todo</Button>
              </div>
              <div className={"grid grid-cols-3 gap-2"}>
                {files.map((file, index) => (
                  <AttachedFileCard key={index} file={file} setOpenDialogImageFullScreen={setOpenDialogImageFullScreen} />
                ))}
              </div>
            </React.Fragment>
          )}
          {comment && (
            <p className="text-neutral-800 text-[12px] md:text-[13px] font-normal">
              <StyledLinkifyerLines text={comment} />
            </p>
          )}
          {shared && shared.length > 0 && (
            <div className="flex w-full justify-end items-center">
              <SharedToolTip sharedUsers={shared} />
            </div>
          )}
        </div>
      </div>
    </InteractiveMessageCard>
  );
};

interface IFile {
  file: {
    name: string;
    url: string;
    mimeType: string;
    size: number;
    _id: string;
  }
  setOpenDialogImageFullScreen: (value: boolean | string) => void
}
const AttachedFileCard = ({ file, setOpenDialogImageFullScreen }: IFile) => {
  const kbSize = file?.size / 1024;

  const handleViewFile = () => {
    window.open(file?.url, "_blank");
  };

  const handleViewImage = (url: string) => {
    setOpenDialogImageFullScreen(url)
  }

  return (
    <div className="flex flex-col items-center border rounded h-26 w-[74px] md:h-28 md:w-[76px] lg:h-32 lg:w-28 bg-white p-2 cursor-pointer hover:bg-gray-50" onClick={file?.mimeType.includes("image") ? () => handleViewImage(file?.url) : handleViewFile}>
      <div className="border rounded p-1">
        {file?.mimeType.includes("image") ? (
          <img
            className="w-12 h-12 md:w-12 md:h-14 lg:w-16 lg:h-16 object-cover"
            src={file?.url}
            alt={file?.name.split(".")[0]}
          />
        ) : (
          <FileBarChart2 strokeWidth={1.2} className="w-12 h-12 md:w-12 md:h-14 lg:w-16 lg:h-16" />
        )}
      </div>
      <div className="flex flex-col overflow-hidden max-w-[100%] items-center justify-center">
        <p className="text-[10px] max-w-[100%] md:text-[11px] lg:text-[13px] truncate">{file?.name.split(".")[0]}</p>
        <p className="text-[10px] md:text-[11px] lg:text-[12px] text-gray-400">{kbSize.toFixed(2)}KB</p>
      </div>
    </div>
  );
};

const DialogImageFullScreen = ({ open, onClose }: { open: string | boolean, onClose: () => void }) => {
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false)
  const [url, setUrl] = useState<string>('')

  useEffect(() => {
    if (typeof open == 'string') {
      setIsLoadingImage(true)
      setUrl(open)
    }
  }, [open])

  const handleClose = () => {
    setUrl('')
    onClose()
  }

  return (
    <Dialog open={!!open} onOpenChange={handleClose}>
      <DialogContent className="min-w-fit max-w-fit flex items-center justify-center border-0 shadow-none p-0 focus-visible:outline-none bg-transparent" overlayType="transparent" showCloseButton={false}>
        <img src={url} style={{
          width: 'auto',
          height: 'auto',
          objectFit: 'cover',
        }} onLoad={() => {
          setIsLoadingImage(false)
        }}
          className="rounded"
        />
      </DialogContent>
    </Dialog>
  );
}

