import { FileCharacteristics } from "@/components/crm/ChatInput/UploadImageModal"
import { getBase64 } from "@/helpers"
import { useCallback, useEffect, useState } from "react"

export const MEGABYTE_ACCEPTED = 55777216

export function useUploadFile({ openModal, getValues }: { openModal?: boolean, getValues?: (values: FileCharacteristics[]) => void }) {
    const [values, setValues] = useState<Array<FileCharacteristics>>([])
    const [fileSelected, setFileSelected] = useState(0)
    const [error, setError] = useState<any>()

    const onDrop = useCallback(acceptedFile => {
        if (acceptedFile.length == 0) return ''
        setError(null)
        let filesRemoves = 0

        const promises = acceptedFile.map(file => {
            return new Promise((resolve, reject) => {
                if (file.size > MEGABYTE_ACCEPTED) {
                    filesRemoves++

                    setError(`${filesRemoves}${filesRemoves >= 2 ? ' archivos han sido removidos' : ' archivo ha  sido removido'} debido a que su tamaño supero el limite`)
                    resolve(null);
                } else {
                    getBase64(file)
                        .then((result) => {
                            const fileConstructor: FileCharacteristics = {
                                path: result,
                                type: file.type,
                                name: file.name,
                                size: file.size
                            };
                            resolve(fileConstructor)
                        })
                }
            });
        });

        Promise.all(promises)
            .then(dataArray => {
                const filteredDataArray = dataArray.filter(data => data !== null);
                const result = [...values, ...filteredDataArray]
                setValues(result);
                getValues && getValues(result)
            })
            .catch(error => {
                console.log(error)
            });
    }, [values])

    const handleDeleteFile = useCallback((file: any) => {
        const copyArray: any = [...values]

        const inx = copyArray.indexOf(file)
        if (inx !== -1 || !isNaN(file)) {
            copyArray.splice(!isNaN(file) ? file : inx, 1)
            setValues(copyArray);
        }
        if (error) {
            setError(null)
        }
        setFileSelected(0)
    }, [values])

    useEffect(() => {
        if (openModal !== null) {
            setValues([])
            setError(null)
        }
    }, [openModal])

    return { handleDeleteFile, onDrop, values, error, fileSelected, setFileSelected, setValues }
}