import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { ModalPropsBase } from "@/interfaces/Interface";
import { XIcon } from "lucide-react";
import { useMemo } from 'react';

interface AddContactAlertProps extends ModalPropsBase {
    onAddExistingContact: () => void,
    onAddNewContact: () => void,
    sector?: {
        name: string,
        id: string
    }
}

export default function AddContactAlert(props: AddContactAlertProps) {
    const { onOpenChange, open, sector, onAddExistingContact, onAddNewContact } = props;

    const titleText = useMemo(() => {
        if (sector?.name) return `Agregar contacto a ${sector.name}`;
        return 'Agregar contacto a la empresa';
    }, [sector, open]);

    return (
        <AlertDialog open={open} onOpenChange={() => onOpenChange(false)}>
            <AlertDialogContent overlayType="black-blur">
                <AlertDialogHeader>
                    <button
                        onClick={() => onOpenChange(false)}
                        className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
                        <XIcon className="w-4 h-4" />
                        <span className="sr-only">Close</span>
                    </button>
                    <AlertDialogTitle>{titleText}</AlertDialogTitle>
                    <AlertDialogDescription className="flex flex-col gap-3">
                        ¿Desea agregar uno nuevo o uno ya existente?
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <Button
                        variant={'outline-primary'}
                        onClick={onAddExistingContact}
                    >
                        Agregar existente
                    </Button>
                    <Button
                        variant={'california'}
                        onClick={onAddNewContact}
                        className="bg-primary hover:bg-blue-500">
                        Agregar nuevo
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
