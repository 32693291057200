import PlusButton from "@/components/buttons/plus-button"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"
import { getCompanyGroupConfig } from "@/helpers/companyGroups"
import { useMemo, useState } from "react"
import AffiliatedCompanyField, { CompanyGroupFieldValue } from "./AffiliatedCompanyField"

type ValueType = any
interface CompanyFieldProps {
    value: Array<ValueType | {}>,
    onChange: (value: ValueType) => void
    placeholder?: string,
    contact: any
}

const CompanyGroupsField = ({
    value = [],
    onChange,
    placeholder,
    contact
}: CompanyFieldProps) => {
    const { title } = getCompanyGroupConfig();

    const [localValue, setLocalValue] = useState<any[]>(() => {
        let defaultValue = value;

        if (!Array.isArray(defaultValue) || defaultValue?.length == 0) {
            defaultValue = [{
                companyGroup: undefined,
                sector: '',
                role: ''
            }]
        }

        return defaultValue
    });

    const allCompanyGroupsIds = useMemo(() => {
        return value.map(({ companyGroup }) => {
            if (typeof companyGroup == 'string') return companyGroup;
            return companyGroup?._id || ''
        }).filter(Boolean)
    }, [value])

    const shadowCopy = (fn: (values: any[], localValue: any[]) => void) => {
        const allValues = [...value];
        const allLocalValues = [...localValue];

        fn(allValues, allLocalValues)

        setLocalValue(allLocalValues)
        onChange(allValues);
    }

    const onHandleChangeValue = (inx: number) => (newValue: CompanyGroupFieldValue) => {
        shadowCopy((allValues, allLocalValues) => {
            allLocalValues[inx] = newValue;
            allValues[inx] = {
                companyGroup: newValue?.companyGroup?._id,
                role: newValue.role,
                sector: newValue?.sector?._id
            }
        })
    }

    const onHandleAddComapnyGroup = () => {
        const newValue = {
            companyGroup: undefined,
            sector: '',
            role: ''
        }

        shadowCopy((allValues, allLocalValues) => {
            allValues.push(newValue)
            allLocalValues.push(newValue)
        })
    }

    const handleDeleteComapnyGroup = (inx: number) => {
        shadowCopy((allValues, allLocalValues) => {
            allValues.splice(inx, 1);
            allLocalValues.splice(inx, 1);
        })
    }

    return (
        <div className="flex flex-col gap-2">
            <div className="w-full justify-between flex">
                <Label className="text-gray-700 ">
                    {title.plural}
                </Label>
                <PlusButton
                    onClick={onHandleAddComapnyGroup}
                    className="h-max py-0 px-0"
                    type={'button'}
                >
                    Agregar
                </PlusButton>
            </div>
            <ul className="flex flex-col">
                {
                    localValue.map((companyGroup, inx) => {
                        const isLastValue = (value.length - 1) == inx;

                        return (
                            <li key={inx}>
                                <AffiliatedCompanyField
                                    companyGroupsSelected={allCompanyGroupsIds as string[]}
                                    onDelete={() => handleDeleteComapnyGroup(inx)}
                                    queryKey={`select-companygroup-${inx}`}
                                    onChange={onHandleChangeValue(inx)}
                                    placeholder={placeholder}
                                    value={companyGroup}
                                    contact={contact}
                                />
                                {!isLastValue && <Separator className="my-2 mb-4" />}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

CompanyGroupsField.displayName = 'CompanyGroupsField'
export default CompanyGroupsField;