import { fetcher } from "@/helpers/fetcher";

export const getContactId = (
    phoneNumber: string,
) => {
    return fetcher<{ success: boolean, contactId?: string }>({
        method: 'GET',
        defaultContentType: true,
        url: `/ucontact/getContactId?phone=${phoneNumber}`,
        throwError: false,
    })
};