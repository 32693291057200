import { cn } from '@/lib/utils';
import styled from '@emotion/styled'

const StyledDiv = styled.div`
  & img {
    max-width: 100% !important;
    height: auto !important;
  }
  & p {
    color: rgb(71 85 105 / 1);
    overflow-wrap: break-word;
  }
`;

export function DivToSetInnerHtml({ content, className = '' }) {
    return <StyledDiv dangerouslySetInnerHTML={{ __html: content }}
        className={cn('text-[14px] w-full', className)}
        style={{ overflowWrap: 'break-word' }} />
}