
type VariableOption = {
    code: string,
    name: string
}

export const getContactVariables = (additionalFields: Array<VariableOption> = []): VariableOption[] => [
    {
        name: "ID",
        code: "contact._id"
    },
    {
        name: 'Nombre',
        code: 'contact.firstName'
    },
    {
        name: 'Apellido',
        code: 'contact.lastName'
    },
    {
        name: 'Primer teléfono',
        code: 'contact.phones[0]'
    },
    {
        name: 'Segundo teléfono',
        code: 'contact.phones[1]'
    },
    {
        name: 'Correo electrónico',
        code: 'contact.emails[0]'
    },
    {
        name: 'Etiqueta',
        code: 'contact.statusTag'
    },
    {
        name: 'Detalle',
        code: 'contact.detail'
    },
    {
        name: 'Origen (Código)',
        code: 'contact.origin'
    },
    {
        name: 'Origen (Nombre)',
        code: 'contact.originText'
    },
    ...additionalFields
]


export const getGroupVariables = (additionalFields: Array<VariableOption> = []): VariableOption[] => [
    {
        name: "ID",
        code: "group._id"
    },
    {
        name: 'Nombre',
        code: 'group.displayName'
    },
    {
        name: 'País',
        code: 'group.country'
    },
    ...additionalFields
]

export const getUserVariables = (additionalFields: Array<VariableOption> = []): VariableOption[] => [
    {
        name: "ID",
        code: "user._id"
    },
    {
        name: 'Nombre',
        code: 'user.name'
    },
    {
        name: 'Apellido',
        code: 'user.lastname'
    },
    {
        name: 'Correo electrónico',
        code: 'user.email'
    },
    {
        name: 'Teléfono',
        code: 'user.phone_work'
    },
    ...additionalFields
]