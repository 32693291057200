import { Pause, PlayArrow } from "@mui/icons-material"
import { Chip, IconButton, LinearProgress, Stack, linearProgressClasses, styled } from "@mui/material"
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { AiFillAudio } from "react-icons/ai";
import moment from "moment"
import { Button } from "@/components/ui/button"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  width: '100%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#9CA3AF',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#6B7280' : 'blue',
  },
}));

interface AudioPlayerProps {
  url: string,
  disableChip?: boolean
  interaction?: boolean
  listened?: boolean
}

const getSecondsToMinutesAndSeconds = time => {
  if (time === 0 || typeof time !== 'number' || isFinite(time) === false || isNaN(time)) {
    return '0:00';
  }
  return moment(time * 1000).format('m:ss');
};

const AudioPlayer = ({ url, disableChip, listened, interaction = false }: AudioPlayerProps) => {
  let audioPlayer;

  const [loadingBack, setLoadingBack] = useState(true)
  const [speed, setSpeed] = useState(1)

  const toggleSpeed = useCallback(() => {
    if (speed >= 3) {
      setSpeed(1)
      if (audioPlayer) audioPlayer.playbackRate = 1
    } else {
      setSpeed(speed + 1)
      if (audioPlayer) audioPlayer.playbackRate = speed + 1
    }
  }, [speed, audioPlayer])

  const loading = useMemo(() => {
    return loadingBack
  }, [loadingBack])

  const [animationParent] = useAutoAnimate()
  const [currentTrackDuration, setCurrentTrackDuration] = useState<string>();
  const [currentTrackMoment, setCurrentTrackMoment] = useState<string>();
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const initPlayer = () => {
    audioPlayer = document.getElementById("audioPlayer" + url);
  };

  const handlePlay = () => {
    if (!audioPlayer) return ''
    if (audioPlayer.paused || audioPlayer.ended) {
      audioPlayer.play();
      setIsPlaying(true);
    } else {
      audioPlayer.pause();
      setIsPlaying(false);
    }
  };

  const handleMetadata = () => {
    setLoadingBack(false)
    const duration = Math.floor(audioPlayer.duration);
    setCurrentTrackDuration(getSecondsToMinutesAndSeconds(duration));
  };

  const handleTimeupdate = playNext => {
    setCurrentTrackMoment(getSecondsToMinutesAndSeconds(Math.floor(audioPlayer.currentTime)));
    setProgressBarWidth(
      Math.floor((audioPlayer.currentTime / audioPlayer.duration) * 100)
    );
    if (audioPlayer.currentTime === audioPlayer.duration) {
      playNext();
    }
  };

  useEffect(
    () => {
      setLoadingBack(true)
      if (!audioPlayer.duration) setCurrentTrackDuration("0:00");
      setCurrentTrackMoment("0:00");
      setProgressBarWidth(0);
    },
    [url]
  );

  useLayoutEffect(() => {
    if (!audioPlayer) {
      initPlayer()
    }
  })

  return (
    <div key={url} className="px-1">
      <audio
        key={url}
        id={"audioPlayer" + url}
        src={url}
        onLoadedMetadata={handleMetadata}
        onTimeUpdate={() => handleTimeupdate(() => setIsPlaying(false))}
      >
        Navegador no compatible
      </audio>

      <Stack ref={animationParent} spacing={2} direction={"row"} justifyItems={"center"} display="flex" alignItems={"center"} >
        <Button
          variant={'ghost'}
          size={'icon'}
          className=" text-[#6B7280] relative w-9 h-9 hover:bg-transparent hover:text-[#494c53] text-xl"
          onClick={() => {
            handlePlay();
          }}
        >
          {isPlaying ? <Pause style={{ fontSize: '32px' }} /> : <PlayArrow style={{ fontSize: '32px' }} />}
          {
            interaction && (
              <span className="absolute -bottom-1 -right-[5px]">
                <AiFillAudio
                  className={`${listened ? 'text-primary' : ''}`}
                  size={17}
                />
              </span>
            )
          }
        </Button>
        <div className="flex-grow">
          <BorderLinearProgress variant="determinate" value={progressBarWidth} />
        </div>
        {
          !disableChip && <Button
            size='icon'
            onClick={toggleSpeed}
            className="text-[12px] rounded-full h-[22px] bg-[#6B7280]"
            style={{ fontWeight: speed == 1 ? 500 : 600 }}>
            {
              (loading && speed == 1) ? '1x'
                : speed > 1 ? speed.toString().concat('x')
                  : isPlaying ? currentTrackMoment
                    : currentTrackDuration
            }
          </Button>
        }
      </Stack>
    </div>
  )
}

export default AudioPlayer