import { OperationType } from "@/hooks/crm/useCreateContactInExternalSystem";
import { cn } from "@/lib/utils";
import { CircleCheck, CircleX, Ear, Loader2, Router } from 'lucide-react';
import { PropsWithChildren } from "react";

interface ExternalSystemStateItemProps {
    isLoadingValidation: boolean,
    operationType: OperationType,
    isLoadingOperation: boolean,
    isValidOperation: boolean,
    error?: string,
    integrationName?: string
}

export default function ExternalSystemStateItem({
    isLoadingValidation,
    isLoadingOperation,
    isValidOperation,
    integrationName,
    operationType,
    error
}: ExternalSystemStateItemProps) {

    const hasError = !isValidOperation && error;

    const Description = ({ children, className }: PropsWithChildren & { className?: string }) => {
        return (
            <p className={cn('flex items-center text-gray-600 text-[12.5px] gap-2', className)}>
                {children}
            </p>
        )
    };

    const GetState = () => {

        if (isLoadingValidation || isLoadingOperation) {
            return (
                <Description>
                    <Loader2 strokeWidth={2} size={16} className={'shrink-0 animate-spin'} />
                    {
                        isLoadingOperation
                            ? operationType == 'creation' ? 'Creando contacto...' : 'Actualizando contacto...'
                            : 'Validando campos del contacto...'
                    }
                </Description>
            )
        };

        if (hasError) {
            return (
                <Description className="text-red-500 items-start">
                    <CircleX strokeWidth={2} size={16} className={'shrink-0 mt-[2px]'} />
                    {
                        error
                            ? error
                            : `Los campos proporcionados no son válidos para ${operationType === 'creation' ? 'crear' : 'actualizar'} el contacto. Verifica la información e inténtalo nuevamente.`
                    }
                </Description>
            )
        }

        if (isValidOperation) {
            return <Description className="text-green-500 items-start">
                <CircleCheck strokeWidth={2} size={16} className={'shrink-0 mt-[2px]'} />
                {
                    (operationType == 'creation')
                        ? 'Se creará el contacto correctamente.'
                        : 'Se modificará el contacto correctamente.'
                }
            </Description>
        };

        return (
            <Description>
                <Ear strokeWidth={2} size={16} className={'shrink-0'} />
                Escuchando cambios...
            </Description>
        )
    }

    return (
        <div className={`w-full p-2 border ${hasError ? 'bg-red-50 border-red-300' : isValidOperation ? 'bg-green-50 border-green-300' : 'bg-gray-50'} rounded-md`}>
            <h5 className={`text-[12px] flex items-center gap-2 mb-2 font-medium ${hasError ? 'text-red-800' : isValidOperation ? 'text-green-800' : 'text-gray-800'}`}>
                <Router size={17} />
                {
                    hasError
                        ? `Respuesta del sistema ${integrationName || 'externo'}:`
                        : `Estado del sistema ${integrationName || 'externo'}:`
                }
            </h5>
            {GetState()}
        </div>
    )
}
