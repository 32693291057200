import BadgePill from "./BadgePill";
import { useState } from "react";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { HandleBotStatusModal } from "../Modal/HandleBotStatusModal";
import { Contact } from "@/interfaces/crm/contact";
import { cn } from '../../../lib/utils';
import { X } from "lucide-react";

interface BotStatusProps {
    isActive?: boolean
    contact: Contact
}

const BotStatusPill = ({ isActive, contact }: BotStatusProps) => {
    const [open, setOpen] = useState(false);

    if (!isActive) return null;

    return (
        <>
            <BadgePill className="hover:bg-slate-50 hover:cursor-pointer" onClick={() => setOpen(true)}>
                <div className="flex flex-row gap-1 items-center">
                    <span className={cn('rounded-full h-2.5 w-2.5', isActive ? 'bg-green-500' : 'bg-red-500')} />
                    <p>{isActive ? 'Bot activo' : 'Bot inactivo'}</p>
                    {isActive && <X size={12} />}
                </div>
            </BadgePill>
            <HandleBotStatusModal
                open={open}
                onClose={() => setOpen(false)}
                isActive={isActive}
                contact={contact}
            />
        </>
    )
}

export default BotStatusPill