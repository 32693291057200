import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select";
import tagColorToPalette, { getFilteredStatusTags } from "@/helpers/tagColorToPalette";
import { ModalPropsBase } from '@/interfaces/Interface';
import { changeStatusTag } from "@/redux/slices/thunks/crm";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

interface ChangeStatusTagProps extends ModalPropsBase {
    contactId: string,
    discriminateStatusTag?: string
}

export default function ChangeStatusTag({ onOpenChange, open, contactId, discriminateStatusTag }: ChangeStatusTagProps) {
    const [tagSelected, setTagSelected] = useState<string | undefined>(undefined);
    const tags = useMemo(() => getFilteredStatusTags().filter(({ code }) => code !== discriminateStatusTag), [discriminateStatusTag]);

    const dispatch = useDispatch();

    const handleChangeTag = () => {
        if (!tagSelected) return;
        dispatch(changeStatusTag({ id: contactId, status: tagSelected }));
        onOpenChange(false)
    }

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[425px] p-0">
                <DialogHeader className="px-5 pt-5">
                    <DialogTitle>Cambiar etiqueta</DialogTitle>
                    <DialogDescription>
                        Cambia las etiquetas de tus contactos para organizarlos y diferenciarlos de manera efectiva.
                    </DialogDescription>
                </DialogHeader>
                <div className="py-2 grid px-5">
                    <div className="grid gap-1.5">
                        <Label className="text-gray-700 ">Etiqueta <span className="text-primary"> *</span></Label>
                        <Select onValueChange={(value) => setTagSelected(value)} value={tagSelected}>
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Seleccionar..." />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    {
                                        tags.map(({ code, name, color }) => (
                                            <SelectItem value={code}>
                                                <div className="flex gap-2 items-center">
                                                    <div
                                                        style={{
                                                            backgroundColor: tagColorToPalette[color].primary,
                                                        }}
                                                        className="w-[18px] h-[18px] rounded-full flex justify-center items-center relative text-[#fff]"
                                                    >
                                                    </div>
                                                    <span>{name}</span>
                                                </div>
                                            </SelectItem>
                                        ))
                                    }
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <DialogFooter className='flex px-5 bg-gray-100 border-t-gray-200 border-t py-4'>
                    <div className='w-full flex items-center gap-2 justify-end'>
                        <Button
                            variant={'outline-primary'}
                            onClick={() => onOpenChange(false)}
                            size={'xs'}
                        >
                            Cancelar
                        </Button>
                        <Button type="button" onClick={handleChangeTag} disabled={!tagSelected}>
                            Cambiar
                        </Button>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
