import tagColorToPalette from "@/helpers/tagColorToPalette";

export default function InteractionChannel({ channel }) {
    const { name, color } = channel || {}

    if (!name) return null;

    return (
        <span
            className="flex text-[12px] items-center gap-1"
            style={{ color: color ? tagColorToPalette[color].primary : "GrayText", fontSize: '12px' }}>
            {name || ''}
        </span>
    )
}
