export const searchRegex = (searchText: string): RegExp => {
    const regexString = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toLowerCase();
    const regexStringWithSpaces = regexString.replace(/\s/g, '\\s*');
    const regex = new RegExp(regexStringWithSpaces, "i");
    return regex;
}

export const deepMatchRegex = (searchText: string): RegExp => {
    const regexString = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toLowerCase();

    const regexStringWithSpaces = regexString.replace(/\s+/g, '\\s*');

    const regexWithWildcard = regexStringWithSpaces.split(' ').map(word => `.*${word}.*`).join('.*');

    const regex = new RegExp(regexWithWildcard, 'i');
    return regex;
}
