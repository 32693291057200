import { cn } from "@/lib/utils"
import { ReactNode } from "react"

interface EmailFieldContentProps {
    label: string | ReactNode,
    classNameContent?: string,
    classNameContenChildren?: string
    children: ReactNode
    onMouseDown?: any
}

export default function EmailFieldContent({
    label,
    children,
    classNameContent,
    classNameContenChildren,
    onMouseDown
}: EmailFieldContentProps) {
    return (
        <div className={cn("flex items-center", classNameContent)}>
            <div className="w-[130px]">
                <p className="text-[14.3px] flex items-center font-medium text-gray-600">
                    {label}
                </p>
            </div>
            <div
                onMouseDown={(e) => onMouseDown && onMouseDown(e)}
                className={cn("py-2.5 border-b min-h-[50px] px-3 border-b-gray-200 w-full flex items-center gap-3 flex-wrap", classNameContenChildren)}>
                {children}
            </div>
        </div>
    )
}