import { Badge } from '@/components/ui/badge'
import { cn } from '@/lib/utils'
import { Loader2, X } from 'lucide-react'
import React, { ReactNode } from 'react'

export default function BadgePill({
    children,
    withActionButton,
    onClickBtn,
    onClick,
    isLoading,
    className,
    iconBtn
}: {
    children?: ReactNode,
    withActionButton?: boolean,
    onClickBtn?: () => void
    onClick?: () => void,
    isLoading?: boolean
    className?: string
    iconBtn?: JSX.Element
}) {

    const handleClickBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        e.preventDefault()
        onClickBtn && onClickBtn()
    }

    return (
        <Badge variant={'outline'} onClick={() => onClick && onClick()} className={cn(`relative ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}  w-max z-[1] bg-white`, className)}>
            <div className="flex items-center truncate gap-2 py-1">
                <div className="flex  items-center gap-1">
                    {children}
                </div>
                {
                    withActionButton && (
                        <button onClick={handleClickBtn} className={`${isLoading ? 'cursor-not-allowed' : ''}`}>
                            {
                                isLoading
                                    ? <Loader2 size={13} className="h-3  shrink-0 w-3 animate-spin" />
                                    : iconBtn || <X size={13} />
                            }
                        </button>
                    )
                }
            </div>
        </Badge>
    )
}
