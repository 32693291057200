import { createCompanySector } from '@/actions/comercial/companygroup'
import { useMutation } from '@tanstack/react-query'
import { SectorSchemaType } from '../../modal-add-sectors'

interface UseCreateSectorProps {
    onError: (error: any) => void,
    onSuccess: (data: any) => void,
    companyId: string
}

export default function useCreateSector({
    onError,
    onSuccess,
    companyId
}: UseCreateSectorProps) {
    const { mutate, ...rest } = useMutation({
        mutationFn: ({ body }: { body: SectorSchemaType }) => createCompanySector(companyId, body),
        onSuccess: onSuccess,
        onError: onError
    })

    const onHandleSubmit = (values: SectorSchemaType) => {
        mutate({ body: values })
    }

    return {
        onHandleSubmit,
        ...rest
    }
}
