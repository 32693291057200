'use client'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import usePostCouch from "@/hooks/usePostCoach"
import '@/styles/scrollModify.css'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo } from "react"
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { BtnNextStep } from "../buttons/BtnNextStep"
import { ItemField } from "../grupos/notification/DialogAddNewNotification"
import { Form, FormField } from "../ui/form"

import dynamic from 'next/dynamic'

import { useRouter } from "next/router"
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'
import { useSelector } from "react-redux"
import { Input } from "../ui/input"
import { SelectCompanyAndUser } from "../admin/SelectCompanyAndUser"
import { CardDescription } from "../ui/card"

const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        [{ 'font': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image'],
        ['clean']
    ],
};

const formats = [
    'header',
    'font',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link',
    'image'
];

const zSchemaOfInstance = z.object({
    title: z.any().optional(),
    content: z.any().optional(),
    users: z.array(z.any()),
    company: z.string(),
    roles: z.array(z.any()).optional(),
    agent: z.any(),
    media: z.any().optional()
})

type TZodSchema = z.infer<typeof zSchemaOfInstance>

export function DialogAddPost({ open, onClose, dataPerDefault, refetch }: any) {
    const query = useRouter().query
    const managers = useSelector((state: any) => state.header.managers)

    const { handleCreate, isLoadingMutate } = usePostCouch()

    const isModify = useMemo(() => typeof open != 'boolean', [open])
    const contextForm = useForm<TZodSchema>({
        resolver: zodResolver(zSchemaOfInstance),
        defaultValues: {
            users: [],
            roles: []
        }
    })

    const [roles, users]: any = ['roles', 'users'].map(field => contextForm.watch(field as 'roles' | 'users'))

    useEffect(() => {
        if (isModify) {
            const usersId = open.users.map(user => user.user._id)
            const users = dataPerDefault?.users.filter(user => usersId.includes(user._id))
            const { title, content, company, agent } = open

            contextForm.reset({
                users: users,
                title: title,
                content: content || '',
                company: company,
                roles: Array.from(new Set(users.map(user => user.roles).flat())),
                agent: agent._id
            })
        }

        else {
            if (dataPerDefault) {
                contextForm.reset({ ...dataPerDefault })
            }
        }
    }, [isModify, open])

    const allIdsOfUsersSelected = useMemo(() => {
        if (roles?.length === 0) return users.map(user => user._id)

        return users.filter(obj =>
            obj?.roles.some(role => roles?.includes(role))
        ).map(user => user._id)
    }, [users, roles])

    const onSubmit = (values: TZodSchema) => {
        const data = { ...values }
        data.users = allIdsOfUsersSelected

        const onSuccess = () => {
            onClose()
            refetch()
        }

        handleCreate({ ...data }, onSuccess, open?._id)
    }

    return <Dialog open={!!open} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[600px] gap-0 flex h-[95vh] p-0 flex-col">
            <DialogHeader className="p-6 pb-0">
                <DialogTitle className="flex-grow text-start">
                    {isModify ? 'Modificar' : 'Crear'} publicación
                </DialogTitle>
            </DialogHeader>
            <div className='flex-grow py-4 flex px-6 flex-col modifyScroll'>
                <Form {...contextForm}>
                    <form onSubmit={contextForm.handleSubmit(onSubmit)} id="formCrateANewIntance" className='flex flex-col flex-grow gap-4'>
                        <FormField
                            control={contextForm.control}
                            name='title'
                            render={({ field }) => (
                                <ItemField className="flex-[0]" label='Título'>
                                    <Input
                                        {...field}
                                        placeholder='Añade un título que describa la instancia'
                                    />
                                </ItemField>
                            )} />
                        <div className="flex-grow border-b border-b-neutral-300 pb-1 relative overflow-hidden">
                            <FormField
                                control={contextForm.control}
                                name='content'
                                render={({ field }) => (
                                    <ItemField >
                                        <div className="flex-grow w-full h-full flex absolute overflow-y-hidden">
                                            <ReactQuill
                                                {...field}
                                                modules={modules}
                                                style={{ width: '100%' }}
                                                formats={formats}
                                            />
                                        </div>
                                    </ItemField>
                                )} />
                        </div>
                        <SelectCompanyAndUser
                            contextForm={contextForm}
                            allManagers={managers}
                            onChange={(name: any, value) => contextForm.setValue(name, value)}
                            usersCompany={dataPerDefault?.users || []}
                        />
                    </form>
                </Form>
            </div>
            <DialogFooter className='sticky bottom-0 px-6 mt-0 border-t'>
                <div className="flex relative items-center h-[55px] justify-between w-full shrink-0">
                    <CardDescription>
                        {
                            allIdsOfUsersSelected.length > 0 && `Se notificará a ${allIdsOfUsersSelected.length} usuarios`
                        }
                    </CardDescription>
                    <BtnNextStep
                        variant={isLoadingMutate ? 'outline' : 'default'}
                        type='submit'
                        form='formCrateANewIntance'
                        className='h-9 rounded-sm uppercase'
                        hiddenArrow
                        loading={isLoadingMutate}

                    >
                        {isModify ? 'Guardar' : 'Crear'}
                    </BtnNextStep>
                </div>
            </DialogFooter>
        </DialogContent>
    </Dialog>
}
