import { ContactService } from "@/actions/crm/contact-controller"
import useDebounce from "@/components/core/functions/useDebouce"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { toast } from "@/components/ui/use-toast"
import { cn } from "@/lib/utils"
import { setDisabledCreateContactButton } from "@/redux/slices/crm"
import { AlertCircleIcon, CheckCircle2, Loader2 } from "lucide-react"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { useDispatch } from "react-redux"
import { CreateContactFormType } from "./CreateContactDrawer"

export const phoneStatus = {
    'success': <CheckCircle2 className="text-green-400 w-[15px] h-[15px]" />,
    'error': <AlertCircleIcon className="text-destructive w-[13px] h-[13px]" />,
    'loading': <Loader2 className="h-[15px] w-[15px] animate-spin" />
} as const

export type PhoneStatusType = keyof typeof phoneStatus | 'idle';

interface ValidateUniqueValueInputProps {
    contextForm: UseFormReturn<CreateContactFormType>,
    onChangeValue: (value: string | number) => void,
    value: string | number | null,
    onCloseModal?: () => void,
    type: 'text' | 'number',
    placeholder?: string,
    validate?: boolean,
    fieldCode: string,
    markAsUpdatedFields?: ()=> void
    required?: boolean
}

export default function ValidateUniqueValueInput({
    validate = false,
    required = false,
    onChangeValue,
    onCloseModal,
    markAsUpdatedFields,
    value = null,
    contextForm,
    placeholder,
    fieldCode,
    type,
}: ValidateUniqueValueInputProps) {
    const [firstValue] = useState(value)
    const [contactId, setContactId] = useState<string>('')
    const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

    const debounceInputValue = useDebounce(value, 500);
    const router = useRouter();
    const dispatch = useDispatch()

    const [isError, isSuccess, isIdle, isLoading] = [
        status == 'error',
        status == 'success',
        status == 'idle',
        status == 'loading',
    ];

    const onHandleChangeStatus = (status: PhoneStatusType) => setStatus(status);
    const onDisabledCreateContactButton = (value: boolean): void => {
        dispatch(setDisabledCreateContactButton(value))
    }

    const isStatusActive = (validate && value && !isIdle)
    const formKey = `additionalData.${fieldCode}`

    useEffect(() => {
        if (!validate || debounceInputValue == null || !debounceInputValue) {
            return onDisabledCreateContactButton(false);
        };

        if ((value == firstValue || debounceInputValue == firstValue)) {
            onDisabledCreateContactButton(false)
            return onHandleChangeStatus('idle')
        }

        const validatePhoneNumberState = async () => {
            if (!debounceInputValue) return onHandleChangeStatus('idle');
            onHandleChangeStatus('loading');

            if (contactId) setContactId('')
            let statusToChange: PhoneStatusType = 'success';

            try {
                const { success, contactId, error, itsMine } = await ContactService.validateAdditionalDataField(
                    fieldCode,
                    value as string
                ) || {};

                if (!success) {
                    statusToChange = 'error';
                    if (itsMine) setContactId(contactId);
                    contextForm.setError(formKey as 'additionalData', { message: error })
                } else {
                    statusToChange = 'success';
                    contextForm.clearErrors(formKey as 'additionalData')
                }
            } catch (e) {
                statusToChange = 'success';
                // toast({
                //     title: 'Error al validar el campo único.',
                //     description: 'Se ha producido un error al validar el campo único. Por favor, inténtelo de nuevo. Si el problema persiste, póngase en contacto con soporte técnico.',
                //     variant: 'destructive'
                // })
            }

            onDisabledCreateContactButton(statusToChange == 'error');
            onHandleChangeStatus(statusToChange);
        }

        validatePhoneNumberState()
    }, [debounceInputValue])

    const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        markAsUpdatedFields && markAsUpdatedFields()
        onChangeValue(value)
    }

    const onRedirectToContact = () => {
        onCloseModal && onCloseModal()
        const query = { ...router.query, id: contactId };
        return router.push({ pathname: '/contact', query })
    }

    return (
        <div className="flex flex-col w-full gap-2">
            <div className="relative  flex items-center justify-start flex-col">
                {
                    isStatusActive && <div className={cn('absolute h-full flex items-center justify-center left-[10px]')}>
                        {phoneStatus[status] || null}
                    </div>
                }
                <Input
                    type={type}
                    required={required}
                    value={value || ''}
                    placeholder={placeholder}
                    onChange={onHandleChange}
                    className={`${isError ? 'border-destructive' : ''} ${isStatusActive ? 'pl-8' : ''}`}
                />
            </div>
            {
                contactId && (
                    <Button
                        type={'button'}
                        variant={'secondary'}
                        className="mt-2"
                        onClick={onRedirectToContact}
                    >
                        Ir al contacto
                    </Button>
                )
            }
        </div >
    )
}