

import { DatePicker } from '@/components/ui/date-picker';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select";
import { AdditionalCompanyField, ICompanyGroup } from '@/interfaces/Interface';
import { ChevronDown } from 'lucide-react';
import AdditionalFieldMultiSelect from './AdditionalFieldMultiSelect';
import AdditionalFieldPhoneNumber from './AdditionalFieldPhoneNumber';
import CompaniesFieldItem from './CompaniesFieldItem';
import ContactsFieldItem from './ContactsFieldItem';
import InformationLabel from './InformationLabel';
import { TextInfomationItem } from './TextInfomationItem';

interface AdditionalFieldItemProps {
    field: AdditionalCompanyField,
    value: any,
    company: ICompanyGroup | undefined,
    onChangeValue: (code: string, value: any, isAdditionalField?: boolean) => void
};

export default function AdditionalFieldItem(props: AdditionalFieldItemProps) {
    const { value, field, onChangeValue, company } = props;

    const type = field.type;

    if (type == 'number' || type == 'string') {
        return <TextInfomationItem
            value={value}
            title={field.name.concat(':')}
            isNumber={field.type == 'number'}
            onChangeValue={(value) => onChangeValue(field.code, value, true)}
        />
    };

    const GetFieldContent = () => {
        switch (field.type) {
            case 'date': {
                return (
                    <DatePicker
                        value={value ? new Date(value as Date) : undefined}
                        onChangeValue={(value) => onChangeValue(field.code, value, true)}
                        classNameTriggerButton="w-max group truncate h-max text-[13.5px] [&>svg]:w-3 [&>svg]:w-3 rounded-full text-gray-700 hover:text-gray-800 border-none hover:bg-transparent p-0"
                        extraTriggerContent={<ChevronDown size={20} className='ml-1 text-gray-400' />}
                    />
                )
            }
            case 'select': {
                return (
                    <Select
                        onValueChange={(value) => onChangeValue(field.code, value, true)}
                        value={!value ? undefined : `${value}`}
                    >
                        <SelectTrigger
                            svgClassName="w-3 h-3"
                            className="w-max rounded-full border-none px-0 p-0 hover:text-gray-800 h-max outline-none text-gray-700 text-[13.5px] ring-offset-0 focus:ring-0 focus:ring-offset-0"
                        >
                            <SelectValue placeholder={field.name} className="text-[11px] text-gray-700 h-auto" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    (field.options || []).map((option, index) => {
                                        return (
                                            <SelectItem value={option} key={`${option}${index}`}>
                                                {option}
                                            </SelectItem>
                                        )
                                    })
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                )
            }
            case 'selectcontact':
            case 'multiplecontact': {
                const contacts = [value].flat();

                return <ContactsFieldItem
                    company={company}
                    contacts={contacts}
                />
            }
            case 'selectcompany':
            case 'multiplecompany': {
                const companiesGroups = [value].flat();

                return (
                    <CompaniesFieldItem
                        companiesGroups={companiesGroups}
                        company={company}
                    />
                )
            }
            case 'phonenumbers': {
                const phoneNumbers = [value].flat();

                return <AdditionalFieldPhoneNumber
                    value={phoneNumbers}
                    company={company}
                />
            }
            case 'multiselect': {
                const flattedValue = [value].flat()

                return <AdditionalFieldMultiSelect
                    onChangeValue={(values) => onChangeValue(field.code, values, true)}
                    options={field?.options || []}
                    value={flattedValue}
                />
            }
        }

        return null
    }

    const element = GetFieldContent();
    if (!element) return null;

    return (
        <li className='w-full grid grid-cols-8  gap-1'>
            <div className="col-span-2">
                <InformationLabel title={field.name.concat(':')} />
            </div>
            <div className="col-span-6 w-full">
                {element}
            </div>
        </li>
    )
}
