import { TemplateItemButton } from '@/components/channels/templates/TemplateListItem'
import { Badge } from '@/components/ui/badge'
import { Card, CardDescription, CardTitle } from '@/components/ui/card'
import { INotificationAdmin } from '@/pages/grupos/platformNotifications'
import { Check, Edit3, Eye, Loader2, Newspaper, Trash2, User2, X, YoutubeIcon } from 'lucide-react'
import Link from 'next/link'

export type FunctionVoid = () => void

interface Props {
    notification: INotificationAdmin
    handleDeleteNotification: FunctionVoid
    toggleNotification: FunctionVoid
    handleEditNotification: FunctionVoid
    isLoading: boolean | null | undefined
}

export default function CardNotification({ notification, handleDeleteNotification, toggleNotification, handleEditNotification, isLoading }: Props) {
    const { description, title, type, performance, _id, active } = notification

    const iconType = {
        video: <YoutubeIcon className='w-5 h-5 text-sky-500' />,
        article: <Newspaper className='w-5 h-5 text-slate-400' />
    }

    const actionOfCard = [
        {
            tooltip: 'Editar',
            icon: <Edit3 className="h-4 w-4" />,
            action: handleEditNotification
        },
        {
            tooltip: 'Eliminar',
            icon: <Trash2 className="h-4 w-4" />,
            action: handleDeleteNotification
        },
        {
            tooltip: active ? 'Desactivar' : 'Activar',
            icon: isLoading ? <Loader2 className="h-4 w-4 animate-spin" />
                : active ? <X className="h-4 w-4" /> : <Check className="h-4 w-4" />,
            action: toggleNotification
        }
    ]

    return <Card className='w-full flex  flex-col shadow-none  p-0 relative hover:bg-slate-50 cursor-pointer'>
        <Link href={`/grupos/platformNotifications/${_id}`} className='flex-grow p-4 relative pb-24 py-5'>
            <div className='flex'>
                <div className='w-[40px] border shrink-0 h-[40px] rounded-full flex justify-center items-center mr-3'>
                    {iconType[type]}
                </div>
                <div className='flex-grow relative'>
                    <CardTitle className='mb-1'>{title}</CardTitle>
                    <CardDescription className='overflow-hidden text-ellipsis ' style={{ overflowWrap: 'break-word' }}>{description}</CardDescription>
                </div>
            </div>
        </Link>
        <div className='col-span-1 w-full flex absolute bottom-4  justify-between gap-2 px-4'>
            <CardPerformance performance={performance} />
            <div className='space-x-2'>
                {
                    actionOfCard.map(({ action, icon, tooltip }) => (
                        <TemplateItemButton
                            disabled={isLoading}
                            key={tooltip}
                            tooltip={tooltip}
                            icon={icon}
                            onClick={action} />
                    ))
                }
            </div>
        </div>
    </Card>
}

export function CardPerformance({ performance }) {
    const { totalViewed, totalUser, rejected, view } = performance

    const performanceData = [
        {
            icon: <User2 className='w-[14px] h-[14px] mr-1' />,
            label: `${totalViewed}/${totalUser}`
        },
        {
            icon: <X className='w-[14px] h-[14px] mr-1' />,
            label: rejected
        },
        {
            icon: <Eye className='w-[14px] h-[14px] mr-1' />,
            label: view
        }
    ]

    return <div className='flex items-center gap-1 mt-1'>
        {
            performanceData.map(({ icon, label }, inx) => (
                <Badge className='text-[11px] hover:bg-slate-100' variant={'outline'} key={inx}>
                    {icon}
                    {label}
                </Badge>
            ))
        }
    </div>
}