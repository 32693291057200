import AccountManager from '@/actions/manager/index'
import { AccountExecutive } from '@/interfaces/Interface'
import { setManagerAssigned } from '@/redux/slices/headers'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function useGetManager() {
    const [isFirstFetch, setFirstFetch] = useState<boolean>(false)

    const dispatch = useDispatch()
    const manager: AccountExecutive | undefined = useSelector((state: any) => state.header.manager)

    const { data, isLoading, isError } = useQuery({
        queryKey: ['get-manager-company'],
        queryFn: AccountManager.getByCompany,
        enabled: !manager && !isFirstFetch
    })

    useEffect(() => {
        if (data?.manager) dispatch(setManagerAssigned(data.manager));
        setFirstFetch(true)
    }, [data])

    return { manager, isLoading, isError, isFirstFetch }
}
