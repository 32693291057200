import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import moment from 'moment'
import { useEffect, useState } from 'react'

interface IModalViewEmail {
    open: any
    close: () => void
}

export default function ModalViewThread({ open, close }: IModalViewEmail) {
    const [threads, setThreads] = useState<any>(null)

    useEffect(() => {
        if (open) {
            setThreads(open)
        }
    }, [open])

    if(!open) return null;

    return (  
        <Dialog onOpenChange={close} open={!!open}>
            <DialogContent className="max-h-[80%] h-auto min-w-[80%] flex flex-col overflow-auto">
                <DialogHeader className='flex flex-col pb-2'>
                    <div className='flex flex-row  items-center'>
                        <div className='flex flex-col space-y-1 w-full'>
                            <DialogTitle className='font-semibold pb-1 text-xl border-b-2 text-primary'>Hilo</DialogTitle>
                            {threads && threads.map((thread) => (
                                <div className='p-4' key={thread.id} >
                                    <Card className='max-h-[350px]'>
                                        <CardHeader>
                                            <CardTitle className='flex justify-between'>
                                                <span className='flex gap-2 '>
                                                    {thread.subject}
                                                </span>
                                                <CardDescription className='font-normal'>{moment.unix(thread.date).format('lll')}</CardDescription>
                                            </CardTitle>
                                            <CardDescription>
                                                {thread.from.map((sender, index) => (
                                                    <span key={index}>
                                                        <span>{`De: <${sender.email}>`}</span>
                                                        {index < thread.from.length - 1 && ", "}
                                                    </span>
                                                ))}
                                            </CardDescription>
                                            <CardDescription className='flex sm:items-start'>
                                                {thread.to.map((recipient, index) => (
                                                    <span key={index}>
                                                        <span>{`Para: <${recipient.email}>`}</span>
                                                        {index < thread.to.length - 1 && ", "}
                                                    </span>
                                                ))}
                                            </CardDescription>
                                            {/* {
                                                thread.cc && thread.content.cc.length > 0 && (
                                                    <CardDescription className='flex sm:items-start'>
                                                        Cc: {thread.content.cc.map(user => user.email).join(', ')}
                                                    </CardDescription>
                                                )
                                            } */}
                                        </CardHeader>
                                        <CardContent className='overflow-y-auto overflow-auto max-h-[200px]'>
                                            <div dangerouslySetInnerHTML={{ __html: thread.body }} className='w-full flex-grow overflow-y-auto' />
                                        </CardContent>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>
                </DialogHeader>
            </DialogContent>
        </Dialog >
    )

}
