import notificationService from '@/actions/notifications';
import Link from 'next/link';
import { Card, CardDescription, CardTitle } from '../ui/card';
import { INotification } from './NavBar';
import { useCallback, useMemo } from 'react';
import { cn } from '@/lib/utils';
import "moment/locale/es";
import useLoadingAndError from '@/hooks/useLoadingAndError';
import { CardPerformance, FunctionVoid } from '../grupos/notification/CardNotification';
import { INotificationAdmin } from '@/pages/grupos/platformNotifications';
import { Check, Edit3, Loader2, Trash2, X } from 'lucide-react';
import { TemplateItemButton } from '../channels/templates/TemplateListItem';
import Loading from '../ui/loading';
import { setCountNotification } from '@/redux/slices/headers';
import { useDispatch, useSelector } from 'react-redux';

interface IPropsCartNotification {
    className?: string
    notification: INotificationAdmin
    handleDeleteNotification?: FunctionVoid
    toggleNotification?: FunctionVoid
    handleEditNotification?: FunctionVoid
    style?: Object
    refetch: () => void
    groupCard?: boolean
    activeFns?: boolean
    activeRep?: boolean
    isLoading?: boolean
    headerMode?: boolean
    externalURL?: string
}

export function CardNotification({
    handleDeleteNotification,
    toggleNotification,
    handleEditNotification,
    className = '',
    notification,
    style,
    refetch,
    groupCard,
    activeFns = true,
    externalURL,
    activeRep = true,
    headerMode = true,
    isLoading }: IPropsCartNotification) {
    const {
        isError: isErrorDelete,
        isLoading: isLoadingDelete,
        setError: setErrorDelete,
        setIsLoading: setIsLoadingDelete } = useLoadingAndError()

    const countNotification = useSelector(
        (state: any) => state.header.countNotififcation
    )

    const { isError, isLoading: isLoadingInteraction, setError, setIsLoading } = useLoadingAndError()
    const { description, image, redirectURL, title, type, active, performance } = notification

    const dispatch = useDispatch()

    const handleInteractionUser = useCallback(async (isRejected: boolean) => {
        if (isRejected) setIsLoadingDelete(true)
        else setIsLoading(true)

        handleDeleteNotification && handleDeleteNotification()
        dispatch(setCountNotification(countNotification - 1))

        notificationService
            .modifyTheInteractionByTheUser({ id: notification._id, isRejected })
            .then(() => {
                refetch && refetch()
                if (isRejected) setIsLoadingDelete(false)
                else setIsLoading(false)
            })
            .catch(e => {
                if (isRejected) setIsLoadingDelete(false)
                else setIsLoading(false)
                setError(e)
            })
    }, [notification])

    const actionOfCard = useMemo(() => [
        {
            tooltip: 'Editar',
            icon: <Edit3 className="h-4 w-4" />,
            action: handleEditNotification
        },
        {
            tooltip: 'Eliminar',
            icon: <Trash2 className="h-4 w-4" />,
            action: handleDeleteNotification
        },
        {
            tooltip: active ? 'Desactivar' : 'Activar',
            icon: isLoading ? <Loader2 className="h-4 w-4 animate-spin" />
                : active ? <X className="h-4 w-4" /> : <Check className="h-4 w-4" />,
            action: toggleNotification
        }
    ], [isLoading, active])

    return <div style={groupCard ? { gridRowStart: 1, gridColumnStart: 1 } : {}} className='relative'>
        <Card
            className={cn('h-full bg-slate-50 border-[#C4C4C4] flex flex-col shadow-none relative justify-between w-full p-3 border-subtle  rounded-[4px] border', className)} style={style}>
            <div className='flex-grow overflow-hidden w-full' style={{ overflowWrap: 'break-word' }}>
                <CardTitle className='leading-[1.2] text-slate-500 text-sm line-clamp-3' style={{ overflowWrap: 'break-word' }}>
                    {title}
                </CardTitle>
                <CardDescription className={`text-neutral-900 text-[14px] text-default ${headerMode ? 'line-clamp-5' : 'line-clamp-6'} mt-1`} style={{ overflowWrap: 'break-word' }}>
                    {description}
                </CardDescription>
            </div>
            {image && image !== '' && <Link onClick={() => activeFns && handleInteractionUser(false)} href={externalURL || redirectURL} className='group relative my-3 flex aspect-video items-center overflow-hidden rounded'>
                <div className='absolute inset-0 bg-neutral-500 bg-opacity-50 transition-opacity group-hover:bg-opacity-40'>
                </div>
                <img src={image} />
                {activeRep && type == 'video' && <IconPlayNotification />}
            </Link>}
            {
                activeFns ? <div className='mt-2 h-[20px] flex items-center justify-between'>
                    <Link href={externalURL || redirectURL} onClick={() => handleInteractionUser(false)} className='text-primary text-[13px] hover:text-[rgb(105,165,221)] font-medium '>
                        Más información
                    </Link>
                    {
                        isLoadingDelete
                            ? <Loading className='w-3 h-3' />
                            : <button
                                disabled={isLoadingDelete}
                                className='text-primary text-sm hover:text-[rgb(105,165,221)]'
                                onClick={() => handleInteractionUser(true)}>
                                Borrar
                            </button>
                    }
                </div>
                    : <div className='w-full flex items-end justify-end gap-2 flex-col mt-2'>
                        <div className='w-full justify-end flex gap-2'>
                            {
                                actionOfCard.map(({ action, icon, tooltip }) => (
                                    <TemplateItemButton
                                        disabled={isLoading}
                                        key={tooltip}
                                        tooltip={tooltip}
                                        icon={icon}
                                        onClick={action as FunctionVoid} />
                                ))
                            }
                        </div>
                        <CardPerformance performance={performance} />
                    </div>
            }
        </Card >
    </div >
}

export function IconPlayNotification({ className = '' }) {
    return <svg className={cn("text-inverted absolute left-1/2 top-1/2 h-7 w-7 -translate-x-1/2 -translate-y-1/2 transform rounded-full shadow-lg hover:-mt-px", className)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="white"></path>
        <path d="M12.1667 8.5L23.8334 16L12.1667 23.5V8.5Z" fill="#111827" stroke="#111827" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
}