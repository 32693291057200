import InputDate from '@/components/crm/Modal/InputDate'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog"
import { Form } from '@/components/ui/form'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { AlertCircleIcon, Loader2 } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from "zod"
import { isAuth } from '../../../actions/auth'
import { addrecepit, addrecepitadmin } from '../../../actions/comercial/sale'
import { getUsersGroup } from '../../../actions/group'
import { CustomFormField } from '../../ui/custom-form-field'
import { Input } from '../../ui/input'
import { saleDefaultValues, saleFormSchema } from '../helpers/form'
import GetContactInput from './GetContactInput'
import { isTheRolEnabled } from '@/helpers/featureFlagHelpers'


export default function AddModal({ open, close }) {
    const [statusList, setStatusList] = useState([])
    const [showInactive, setShowInactive] = useState(false)
    const [error, setError] = useState<boolean | string>(false)
    const [message, setMessage] = useState<boolean | string>(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [isManualSale, setIsManualSale] = useState(false)

    const manualSalesConfig = useMemo(() => {
        return _.get(isAuth(), 'group.crmConfig.manualSales', [])
    }, [])

    const disabledFields = useMemo(() => {
        return isManualSale && !isTheRolEnabled(['comercial'])
    }, [isManualSale])


    let groupQuery = useQuery({
        queryFn: () => localgetUsersGroup(showInactive),
        queryKey: ['users-autocomplete-sale-label', !showInactive],
        enabled: !!open
    })

    const contextForm = useForm<z.infer<typeof saleFormSchema>>({
        resolver: zodResolver(saleFormSchema),
        defaultValues: saleDefaultValues
    })

    const sold = contextForm.watch('sold')
    let users = groupQuery.data ?? []

    useEffect(() => {
        if (isAuth().group.sale_status) setStatusList(isAuth().group.sale_status)
        if (open && typeof open === 'object' && open.manualSale) {
            setIsManualSale(true)
            const { contact, userId } = open || {}
            contextForm.setValue('type', 'Venta manual')
            contextForm.setValue('ticket_id', contact.id)
            contextForm.setValue('status', manualSalesConfig?.saleStatus)
            contextForm.setValue('phone', contact.phone)
            contextForm.setValue('name', contact.name)
            contextForm.setValue('user', userId)
        }
    }, [open])

    function onSubmit(values: z.infer<typeof saleFormSchema>) {
        setLoadingSave(true)
        let newValues = { ...values }

        if (!values.sold) {
            newValues.date_sale = undefined
            newValues.ticket_sale_id = undefined
            newValues.sold = false
        }

        if (isManualSale) {
            addrecepit(newValues).then((resultado) => {
                if (resultado.data.error) return setError(resultado.data.error)
                handleClose()
            })
                .catch((error) => { })
                .finally(() => {
                    setLoadingSave(false)
                })
            return
        }


        addrecepitadmin(newValues).then((resultado) => {
            if (resultado.data.error) return setError(resultado.data.error)
            handleClose()
        })
            .catch((error) => { })
            .finally(() => {
                setLoadingSave(false)
            })
    }

    const localgetUsersGroup = async (hideInactive) => {
        let data = await getUsersGroup()
        let users: any[] = []
        if (data) {
            if (!data.error) {
                data.map((group) => {
                    users = [..._.get(group, 'users', []).filter((u) => u.active || hideInactive).map((user) => ({ _id: user._id, id: user._id, label: `${user.name} ${user.lastname}` })), ...users]
                })
            }
        }
        return users.sort((a, b) => a.label > b.label ? 1 : -1)
    }

    const handleClose = () => {
        close()
        contextForm.reset(saleDefaultValues)
        setMessage(false)
        setError(false)
        contextForm.clearErrors()
    }

    const handleChangeStaus = (statusTitle) => {
        if (!statusTitle) return
        const actualStatus: any = statusList.find((s: any) => s.title + '' == statusTitle + '')
        if (actualStatus) {
            const { status, title } = actualStatus
            contextForm.setValue('status', title)
            if (status == 'sold') {
                contextForm.setValue('sold', true)
                contextForm.setValue('date_sale', new Date())
                contextForm.setValue('ticket_sale_id', '')
            } else {
                contextForm.setValue('sold', false)
                contextForm.setValue('date_sale', undefined)
                contextForm.setValue('ticket_sale_id', undefined)
            }
        }
    }

    return (
        <Dialog open={open} onOpenChange={handleClose} >
            <DialogContent className="sm:max-w-[425px] md:max-w-[620px]">
                <DialogHeader>
                    <DialogTitle>Agregar Venta</DialogTitle>
                    <DialogDescription>
                        Complete los siguientes campos para agregar una nueva venta
                    </DialogDescription>
                    {error && <Alert variant={'destructive'}>
                        <AlertCircleIcon className="h-4 w-4" />
                        <AlertDescription>
                            {error}
                        </AlertDescription>
                    </Alert>
                    }
                    {message && <Alert variant={'default'}>
                        <AlertCircleIcon className="h-4 w-4 text-yellow-500" />
                        <AlertDescription>
                            {message}
                        </AlertDescription>
                    </Alert>
                    }
                </DialogHeader>
                <Form {...contextForm}>
                    <form onSubmit={contextForm.handleSubmit(onSubmit)} id='formCreateSale' className="grid grid-cols-4 items-start gap-4 p-2">
                        <CustomFormField
                            label='Estado'
                            name="status"
                            className='col-span-2'
                            control={contextForm.control}
                            isRequired
                            disabled={disabledFields}
                            fnElement={({ field }) => (
                                <Select value={field.value} onValueChange={(value) => handleChangeStaus(value)} disabled={disabledFields}>
                                    <SelectTrigger className='w-full'>
                                        <SelectValue placeholder="Seleccionar estado" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem value={''} key={''}>{'--- No cambiar ---'}</SelectItem>
                                            {statusList.map(({ _id, title }, index) => (
                                                <SelectItem value={title} key={_id + '' + index}>{title}</SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            )}
                        />
                        <CustomFormField
                            label='Id'
                            name="ticket_id"
                            className='col-span-2'
                            control={contextForm.control}
                            isRequired
                            disabled={disabledFields}
                            fnElement={({ field }) => (
                                <Input {...field} placeholder='ID' disabled={disabledFields} />
                            )}
                        />
                        <CustomFormField
                            label='Id de Venta'
                            name="ticket_sale_id"
                            className='col-span-2'
                            control={contextForm.control}
                            isRequired={sold}
                            disabled={!sold || disabledFields}
                            fnElement={({ field }) => (
                                <Input {...field} placeholder='ID de Venta' disabled={!sold || disabledFields} />
                            )}
                        />

                        <CustomFormField
                            label='Fecha de Venta'
                            name="date_sale"
                            className='col-span-2'
                            control={contextForm.control}
                            isRequired={sold}
                            disabled={!sold || disabledFields}
                            fnElement={({ field }) => (
                                <InputDate
                                    date={field.value}
                                    errorHandler={false}
                                    className='w-full'
                                    handleInvalidDate={(invalid) => {
                                        if (invalid) {
                                            contextForm.setError('date_sale', {
                                                type: 'manual',
                                                message: 'La fecha tiene que ser válida con el formato DD/MM/YYYY'
                                            }
                                            )
                                        } else {
                                            contextForm.clearErrors('date_sale')
                                        }
                                    }}
                                    setDate={(date) => field.onChange(date)}
                                    disabled={!sold || disabledFields}
                                />
                            )}
                        />

                        <CustomFormField
                            label='Fecha de Ingreso'
                            name="date"
                            className='col-span-2'
                            control={contextForm.control}
                            disabled={disabledFields}
                            fnElement={({ field }) => (
                                <InputDate
                                    date={field.value}
                                    className='w-full'
                                    handleInvalidDate={(invalid) => {
                                        if (invalid) {
                                            contextForm.setError('date', {
                                                type: 'manual',
                                                message: 'La fecha tiene que ser válida con el formato DD/MM/YYYY'
                                            }
                                            )
                                        } else {
                                            contextForm.clearErrors('date')
                                        }
                                    }}
                                    disabled={disabledFields}
                                    setDate={(date) => field.onChange(date)}
                                />
                            )}
                        />

                        <CustomFormField
                            label='Tipo'
                            name="type"
                            className='col-span-2'
                            control={contextForm.control}
                            disabled={disabledFields}
                            fnElement={({ field }) => (
                                <Input {...field} placeholder='Tipo' disabled={disabledFields} />
                            )}
                        />
                        <CustomFormField
                            label='Teléfono'
                            name="phone"
                            className='col-span-2'
                            control={contextForm.control}
                            isRequired
                            disabled={disabledFields}
                            fnElement={({ field }) => (
                                <GetContactInput contextForm={contextForm} field={field} disabled={disabledFields} />
                            )}
                        />
                        <CustomFormField
                            label='Nombre'
                            name="name"
                            className='col-span-2'
                            control={contextForm.control}
                            isRequired
                            disabled={disabledFields}
                            fnElement={({ field }) => (
                                <Input {...field} placeholder='Nombre' disabled={disabledFields} />
                            )}
                        />

                        <CustomFormField
                            label='Producto'
                            name="product"
                            className='col-span-2'
                            control={contextForm.control}
                            fnElement={({ field }) => (
                                <Input {...field} placeholder='Producto' />
                            )}
                        />

                        <CustomFormField
                            label='CI/DNI'
                            name="gov_id"
                            className='col-span-2'
                            control={contextForm.control}
                            fnElement={({ field }) => (
                                <Input {...field} placeholder='CI/DNI' />
                            )}
                        />
                        <CustomFormField
                            label='Vendedor'
                            name="user"
                            className='col-span-2'
                            control={contextForm.control}
                            isRequired
                            disabled={disabledFields}
                            fnElement={({ field }) => (
                                <Select value={field.value} onValueChange={field.onChange} disabled={disabledFields}>
                                    <SelectTrigger className='w-full'>
                                        <SelectValue placeholder="Seleccionar vendedor" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem value={''} key={''}>{'--- Sin Vendedor ---'}</SelectItem>
                                            {users.map(({ id, _id, label }, index) => (
                                                <SelectItem value={_id} key={id + '' + index}>{label}</SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </form>
                </Form>
                <DialogFooter>
                    <Button variant={'outline-destructive'} onClick={handleClose}>Cancelar</Button>
                    <Button type="submit" form='formCreateSale' disabled={loadingSave}>
                        {loadingSave ? <Loader2 className='animate-spin h-4 w-4' /> : 'Guardar'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
