import { isAuth } from '@/actions/auth'
import { getUnassignedCount } from '@/actions/crm/contact'
import { setInboxCount } from '@/redux/slices/headers'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

export default function useGetUnassignedCount() {
    const user = useMemo(() => isAuth(), [])
    const dispatch = useDispatch()

    const { data, ...rest } = useQuery({
        queryKey: ['unassigned-contact-count'],
        queryFn: getUnassignedCount,
        enabled: (_.get(user, 'productType', '') == "ventia_freelancer" ||
            _.get(user, "jailUrl", []).length > 0),
        refetchInterval: 60000
    })

    const contactCount = useMemo(() => data?.count || 0, [data])

    useEffect(() => {
        if (!data) return;
        const count = data.count || 0
        dispatch(setInboxCount(count))
    }, [data])

    return {
        contactCount,
        data,
        ...rest
    }
}
