import { MapIcon } from 'lucide-react'
import { Button, buttonVariants } from '../ui/button'
import { cn } from '@/lib/utils'
import { VariantProps } from 'class-variance-authority'

export default function ViewOnMapButton({
    address,
    placeId,
    variant = 'ghost',
    className
}: {
    address: string,
    placeId: string,
    variant?:  VariantProps<typeof buttonVariants>['variant'],
    className?: string
}) {
    return <a
        className="flex w-full items-center"
        target="_blank"
        href={`https://www.google.com/maps/search/?api=1&query=${address}&query_place_id=${placeId}`}
    >
        <Button
            type="button"
            className={cn("h-8 flex text-slate-800 items-center gap-1.5 text-sm hover:bg-none w-full", className)}
            variant={variant}
        >
            Ver en mapa
            <MapIcon className="w-[13px] h-[13px]" />
        </Button>
    </a>
}
