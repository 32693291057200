import { useEffect, useState } from 'react';
import { ModalPropsBase } from '../../../interfaces/Interface';
import RequestSaleAdd from './RequestSaleAdd';

interface AddMasiveRequestSaleProps extends ModalPropsBase {
    requestSales: string[],
    contactId: string
}

export default function AddMasiveRequestSale({
    open,
    onOpenChange,
    requestSales = [],
    contactId
}: AddMasiveRequestSaleProps) {
    const [requestSaleList, setRequestSaleList] = useState<string[]>(requestSales);

    useEffect(() => {
        if (open) setRequestSaleList(requestSales);
    }, [open])

    const firstRequestSale = requestSaleList[0];

    const onCloseRequestSale = () => {
        const requestSales = [...requestSaleList];
        requestSales.splice(0, 1);
        setRequestSaleList(requestSales)
        if (requestSales.length <= 0) return onOpenChange(false);
    }

    const requestNumber = (requestSales.length - requestSaleList.length) + 1

    return (
        <RequestSaleAdd
            externalTitle={(data) => data ? `Modificar solicitud Nº ${requestNumber}. ${data.title}` : ''}
            closeWhenChangeStatus={true}
            key={firstRequestSale}
            setOpen={onCloseRequestSale}
            presaleid={firstRequestSale}
            contactid={contactId}
            open={open}
        />
    )
}
