import NavBarOfTraining from '@/components/training/NavBarOfTraining'
import { Avatar } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { CardDescription, CardTitle } from '@/components/ui/card'
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog"
import { SLUG_HEADER } from '@/constants/list-of-routes'
import useGetManager from '@/hooks/useGetManager'
import { useToggle } from '@/hooks/useToggle'
import { AccountExecutive } from '@/interfaces/Interface'
import { CardMedia } from '@mui/material'
import { CalendarPlus, PanelRight } from 'lucide-react'
import Header from '../Header'
import Private from '../Private'

export default function MenuTraining({ children, slug }) {
    const [isOpenMenu, toggle] = useToggle()
    const { manager } = useGetManager()

    return <Private roles={['all']}>
        <Header slug={SLUG_HEADER.TRAINING}>
            <header
                className='md:w-full sticky bg-white lg:top-0 top-[50px]   flex items-center md:justify-between h-[64px] md:py-0 py-3 px-5 border-b md:px-[20px] z-10 right-0'>
                <div className='md:flex hidden h-full'>
                    <NavBarOfTraining slug={slug} />
                </div>
                <div className='flex items-center w-full justify-between md:justify-end'>
                    <div>
                        {
                            manager && <>
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <Button className='text-[12px] md:flex text-white shrink-0 hidden h-8 mr-3'>
                                            <CalendarPlus size={17} className='mr-2' />
                                            Agendar reunión
                                        </Button>
                                    </DialogTrigger>
                                    <DialogContent className="min-h-[95vh] p-0">
                                        <CardMedia
                                            component="iframe"
                                            src={manager.meetLink}
                                            sx={{ border: 0, height: 1 }} />
                                    </DialogContent>
                                </Dialog>
                                <ProfileOfCoach manager={manager} />
                            </>
                        }
                    </div>
                    <button className="text-[#6B7280] hover:text-[#8f95a3] md:hidden relative" onClick={toggle}>
                        <PanelRight size={17} />
                    </button>
                </div>
            </header>
            <div
                className={`w-full z-[20] md:hidden h-[100%] bg-[#000000]/30  absolute top-0 ${isOpenMenu ? 'block' : 'hidden'} lg:hidden left-0`}
                onClick={toggle}>
            </div>
            <div className={`w-[240px] md:hidden px-4 py-3 modifyScrollChatCrm lg:right-0 ${isOpenMenu ? 'right-0' : '-right-full'} flex bg-white overflow-hidden border-l  flex-col overflow-y-auto fixed transition-all duration ease-out z-50 top-0 h-[100vh]`}>
                <div>
                    <div className='justify-end flex w-full mt-4'>
                        <NavBarOfTraining slug={slug} />
                    </div>
                </div>
                <Dialog>
                    <DialogTrigger asChild>
                        <Button className='text-[12px] w-full mt-2 md:block h-8 mr-3'>
                            <CalendarPlus size={17} className='mr-2' />
                            Agendar reunión
                        </Button>
                    </DialogTrigger>
                    <DialogContent className="min-h-[95vh] p-0">
                        <CardMedia
                            component="iframe"
                            src={manager?.meetLink}
                            sx={{ border: 0, height: 1 }} />
                    </DialogContent>
                </Dialog>
            </div>
            {children}
        </Header>
    </Private>
}

export function ProfileOfCoach({ manager }: { manager: AccountExecutive }) {
    const { _id, lastname, meetLink, name, photoPath } = manager
    return <div className='md:border-l flex gap-2 md:pl-3'>
        <Avatar className='bg-slate-100 w-9 h-9'>
            <img src={photoPath} alt={`image profile of manager ${name} ${lastname}`} />
        </Avatar>
        <div className='flex justify-center md:text-start  flex-col'>
            <CardTitle className='text-[14px] leading-3'>
                {name} {lastname}
            </CardTitle>
            <CardDescription className='text-[13px]'>
                Entrenador
            </CardDescription>
        </div>
    </div>
}