import { isAuth } from '@/actions/auth';
import { generateUContactCallTracing } from '@/actions/crm/contact';
import { getContactId } from '@/actions/ucontact';
import { callState } from '@/components/crm/BottomActions/PhoneResponse';
import { createInteraction } from '@/components/crm/Prospect/libs/helpers/interaction';
import { setAction, setOpenReminderCall } from '@/redux/slices/crm';
import { setIncomingUContactPhoneNumber, setInUContactCall, setOpenUContact, setUContactError } from '@/redux/slices/headers';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useEventObserver = (userPhoneProviderConfig) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const isInCall = useSelector((state: any) => state.header.isInUContactCall);
    const isOpen = useSelector((state: any) => state.header.isOpenUContact);
    const error = useSelector((state: any) => state.header.uContactError);
    const prospect = useSelector((state: any) => state.crm.contact)

    const lastInteraction = useMemo(() => {
        if (!prospect) return null;
        return prospect.interactions.find(({ type }) => type !== 'contactTaken')
    }, [prospect])

    const handleOnCall = (callResult: callState) => {
        if (callResult === "cancel") dispatch(setOpenReminderCall(prospect._id))
        else if (callResult === 'done') dispatch(setAction("note"))
    }

    useEffect(() => {
        const eventObserver = async (e) => {
            if (e?.data?.action === 'gettingCall' && userPhoneProviderConfig?.enableShowDialerOnIncomingCall) {
                if (!isOpen) {
                    dispatch(setOpenUContact(true));
                }

                if (!isInCall) {
                    const { number } = e?.data?.params || {};
                    dispatch(setInUContactCall(true));
                    dispatch(setIncomingUContactPhoneNumber(number));

                    if (number) {
                        try {
                            const res = await getContactId(number);

                            if (!res.success) {
                                dispatch(setUContactError('No se encontró el contacto'));
                            } else if (res.success && res.contactId) {
                                dispatch(setUContactError(''));
                                dispatch(setIncomingUContactPhoneNumber(''));
                                const currentUrl = router.asPath;
                                const targetUrl = `/contact?id=${res.contactId}`;

                                if (currentUrl !== targetUrl) {
                                    router.push(targetUrl);
                                }
                            }
                        } catch (err) {
                            console.error(err);
                        }
                    }
                }
            } else if (e?.data?.action === 'finishedCall') {
                dispatch(setInUContactCall(false));
                dispatch(setUContactError(''));
                if (e?.data?.params?.duration > 0 && e?.data?.params?.direction === 'outgoing') {
                    try {
                        const res = await getContactId(e?.data?.params?.number);
                        if (!res.success) {
                            dispatch(setUContactError('No se encontró el contacto'));
                        } else if (res.success && res.contactId) {
                            dispatch(setUContactError(''));

                            await createInteraction({
                                dispatch,
                                type: 'closeCallPhone',
                                data: {
                                    state: 'done',
                                    interactionid: lastInteraction._id,
                                    id: prospect._id,
                                    onSuccess: () => handleOnCall('done'),
                                }
                            })


                            await generateUContactCallTracing({
                                timestamp: new Date(),
                                phoneNumber: e?.data?.params?.number,
                                duration: e?.data?.params?.duration,
                                user: isAuth()?._id,
                            })


                        }
                    } catch (err) {
                        console.error(err);
                    }
                }
            }
        };

        window.addEventListener('message', eventObserver);
        return () => {
            window.removeEventListener('message', eventObserver);
        };
    }, [isOpen, isInCall]);

    return { error };
};

export default useEventObserver;
