

import { getSaleBubbleTemplates } from '@/helpers/crm';
import { replaceObjectValues } from '@/helpers/replaceObjectValues';
import { SaleInteraction } from '@/interfaces/crm/contact';
import { SaleBubbleTemplate } from '@/interfaces/Interface';
import { useMemo } from 'react';
import CancellationView from './CancellationView';
import MultilineView from './MultilineView';
import SimpleSaleView from './SimpleSaleView';
import StateChangeView from './StateChangeView';

interface SaleBubbleProps { interaction: SaleInteraction }

export default function SaleBubble(props: SaleBubbleProps) {
    const { interaction } = props;
    const sale = interaction?.content?.sale || {};

    const saleBubbleTemplates = useMemo(() => getSaleBubbleTemplates(), []);
    const defaultSaleBubbleTemplate = saleBubbleTemplates.find((template) => {
        return template.default && ((sale.saleStructureType == 'simple' || !sale.saleStructureType)
            ? template.structureType == 'simple'
            : template.structureType == 'multiline'
        )
    });

    const useSaleBubbleTemplate: SaleBubbleTemplate | null = useMemo(() => {
        let useTemplate: SaleBubbleTemplate | null = null;

        if (sale?.displayTemplate) {
            useTemplate = saleBubbleTemplates.find((template) => {
                return template.code == sale.displayTemplate;
            }) as SaleBubbleTemplate;
        };

        if (!useTemplate && defaultSaleBubbleTemplate) {
            useTemplate = defaultSaleBubbleTemplate;
        }

        return useTemplate;
    }, [saleBubbleTemplates, defaultSaleBubbleTemplate]);

    const saleTitle = useMemo(() => {
        let title = sale?.ticket_id || '';

        if (useSaleBubbleTemplate && useSaleBubbleTemplate.saleTitle) {
            title = replaceObjectValues(useSaleBubbleTemplate.saleTitle, {
                sale: {
                    ticket_sale_id: sale.ticket_sale_id,
                    ticket_id: sale.ticket_id,
                    category: sale.category,
                    product: sale.product
                }
            })
        };

        return title;
    }, [useSaleBubbleTemplate, sale])

    const type = interaction.content.type;

    if (type == 'cancellation') {
        return <CancellationView interaction={interaction} />
    }

    if(type == 'state-change'){
        return <StateChangeView interaction={interaction}/>
    }

    if (useSaleBubbleTemplate?.structureType == 'multiline' || sale.saleStructureType == 'multiline') {
        return <MultilineView
            template={useSaleBubbleTemplate}
            interaction={interaction}
            saleTitle={saleTitle}
        />
    }

    return <SimpleSaleView
        template={useSaleBubbleTemplate}
        interaction={interaction}
        saleTitle={saleTitle}
    />
}

// TODO: Pasos para mañana 30/10/2024.
// 1. Crear endpoint para que todos los usuarios puedan obtener las plantillas de su empresa CHECK
// 2. Cada vez que se renderice una interacción de tipo venta buscar la plantilla por defecto que se esta usando CHECK
// 3. Una vez encontrado, validar que tipo de estructura está usando, si simple o multiple linea CHECK
// 4. Si es simple, solo retornaremos el componente ya creado actualmente CHECK
// 5. Si es multiple linea, retornaremos un nuevo componente que crearemmos mañana. CHECK
// 6. Crear 1 burbuja para especificar que se cambio el estado CHECK
// 7. Crear 1 burbuja para especificar que se cancelo la venta con x reazon. CHECK
// 8. Por ultimo, en el backend investigar y agregar codigo para la creación de las interacciones de ventas.