import { AgentOrNull } from "@/interfaces/Interface"
import { Email, Facebook, Instagram, Whatsapp } from "./Icons"
import InteractiveMessageCard from "./InteractiveMessageCard"
import { TickStatusType } from "./TickStatus"
import { IAgentData } from "./TransferBubble"
import InteractionChannelContent from "./interaction-channel-content"
import MessageChannel from "./message-channel"

type TMedium = "fb" | "ig" | "leads" | "wpp"

interface IChannelMessageBubble {
  message: { type: string; media?: string; text?: string }
  date: string | Date
  medium?: TMedium
  origin?: "contact" | "agent"
  deleted?: boolean
  profile: string | null
  status?: "sending" | "sent" | "received" | "read"
  customStyle?: string
  agent?: AgentOrNull | IAgentData
}

export const channelIcons = {
  wpp: <Whatsapp />,
  fb: <Facebook />,
  ig: <Instagram />,
  email: <Email />
}

const ChannelMessageBubble = ({
  message,
  date,
  medium,
  origin,
  agent,
  deleted,
  status,
  profile,
}: IChannelMessageBubble) => {
  if (deleted) return <></>
  const { media = '', type, text = '', fileName = '' } = message || {}

  const mediumIcon = medium && channelIcons[medium]
  const interactionMessage = typeof message == 'string'
    ? message
    : text

  const isMedia = type == 'image' || type === 'video' || type === 'file' || type === 'audio'
  return (
    <InteractiveMessageCard
      mediumIcon={mediumIcon}
      profile={profile}
      origin={origin}
      agent={agent}
    >
      <InteractionChannelContent
        media={media}
        mediaType={type}
        description={text}
        origin={origin}
        date={date}
        status={status}
        fileName={fileName}
      >
        <MessageChannel
          date={date}
          origin={origin}
          deleted={deleted}
          mediaType={type}
          message={interactionMessage as any}
          status={status as TickStatusType}
          className={`${isMedia ? 'p-0 pt-2' : 'p-3'}`}
        />
      </InteractionChannelContent>
    </InteractiveMessageCard>
  )
}

export default ChannelMessageBubble