import { Loader2, Plus } from "lucide-react";

export function BtnAddFile({ open, isLoading }) {
    return <li>
        <button
            type='button'
            disabled={!!isLoading}
            onClick={() => open()}
            className='border-dashed hover:bg-slate-100 duration-100 bg-white text-slate-500 text-[20px] border rounded-sm w-[55px] h-[55px] border-slate-500 flex justify-center items-center'>
            {
                isLoading
                    ? <Loader2 className="h-4 w-4 animate-spin" />
                    : <Plus />
            }
        </button>
    </li>
}