import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import * as React from "react"
import { Check, ChevronsUpDown, Info, Loader2 } from "lucide-react"
import { cn } from "@/lib/utils"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { deepSearchEntities, unifyContact } from "@/actions/crm/contact"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useDebounce } from "@/components/ui/multiselector"
import { CircularProgress, Grid } from "@mui/material"
import { useSelector } from "react-redux"
import { ContactAvatar } from "../Prospect"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"

interface Props {
    open: boolean
    onClose: () => void
}

export function DialogUnifyContact({ open, onClose }: Props) {
    const [openCombobox, setOpenCombobox] = React.useState(false)
    const [inputValue, setInputValue] = React.useState<string>("")
    const [contacts, setContacts] = useState<any>([]);
    const [value, setValue] = useState<any | null>(null);
    const [currentSelected, setCurrentSelected] = useState<string>("")
    const currentContact = useSelector((state: any) => state.crm.contact)?._id
    const [error, setError] = useState<string | null>(null)

    const [searchParams, setSearchParams] = useState<{ [key: string]: any }>({
        entities: 'contacts',
        contactFilters: {},
        listType: 'all',
        search: '',
        user: 'me',
        limit: 10,
        page: 1,
    });

    const inputValueWithDebounce = useDebounce(inputValue, 400);

    const { data } = useQuery({
        queryKey: ['unify-entities-list', searchParams],
        queryFn: () => deepSearchEntities({ ...searchParams }),
        enabled: !!inputValueWithDebounce
    });

    useEffect(() => {
        if (!inputValueWithDebounce && !inputValue) return;
        setSearchParams(lastValue => ({ ...lastValue, search: inputValue }));
    }, [inputValueWithDebounce])

    useEffect(() => {
        if (data) {
            const contactsArray = Array.isArray(data.contacts.documents) ? data.contacts.documents : [];
            setContacts(contactsArray.filter((contact: any) => contact._id !== currentContact));
        }
    }, [data]);

    const { mutate, isPending } = useMutation({
        mutationFn: () => unifyContact(currentContact, value._id),
        onSuccess: () => {
            handleClose()
        },
        onError: (error) => {
            setError('Intenta nuevamente. Si el problema persiste, contacta a soporte.')
        },
    })

    const handleClose = () => {
        setError(null)
        setValue(null)
        setContacts([])
        setInputValue("")
        setOpenCombobox(false)
        onClose()
    }

    return (
        <Dialog open={open} onOpenChange={handleClose}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    {error && <Alert className="border-red-500">
                        <Info className="h-4 w-4" color="red" />
                        <AlertTitle className="text-red-500">Ocurrió un error</AlertTitle>
                        <AlertDescription className="text-red-500">
                            {error}
                        </AlertDescription>
                    </Alert>
                    }
                    <DialogTitle>Unificar contacto</DialogTitle>
                    <DialogDescription>
                        Busca el contacto antiguo con el que deseas unificar la información. Recuerda que esta acción no se puede deshacer.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex flex-col gap-2 py-2">
                    <Popover open={openCombobox} onOpenChange={setOpenCombobox}>
                        <PopoverTrigger asChild>
                            <Button
                                variant="outline"
                                role="combobox"
                                aria-expanded={openCombobox}
                                className="w-full justify-between"
                            >
                                {value ? <div className="flex items-center gap-2">
                                    <ContactAvatar className='h-6 text-xs w-6' statusTag={value.statusTag} photo={value.photo} firstName={value.firstName} lastName={value.lastName} />
                                    {`${value.firstName} ${value.lastName}`}
                                </div>
                                    : "Buscar contacto..."
                                }
                                <ChevronsUpDown className="opacity-50" size={13} />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[300px] p-0">
                            <Command>
                                <CommandInput placeholder="Buscar contacto..." onValueChange={(value) => {
                                    if (!value) setContacts([]);
                                    setInputValue(value)
                                }} />
                                <CommandList>
                                    <CommandEmpty>No se encontró el contacto.</CommandEmpty>
                                    <CommandGroup className="flex flex-col overflow-y-auto">
                                        {contacts.map((contact) => (
                                            <CommandItem
                                                className="flex items-center gap-2"
                                                key={contact._id}
                                                value={`${contact.firstName} ${contact.lastName}`}
                                                onSelect={() => {
                                                    setValue({
                                                        _id: contact._id,
                                                        firstName: contact.firstName,
                                                        lastName: contact.lastName,
                                                        statusTag: contact.statusTag,
                                                        photo: contact.photo
                                                    })
                                                    setOpenCombobox(false)
                                                }}
                                            >
                                                <ContactAvatar className='h-6 text-xs w-6' statusTag={contact.statusTag} photo={contact.photo} firstName={contact.firstName} lastName={contact.lastName} />
                                                {`${contact.firstName} ${contact.lastName}`}
                                                <Check
                                                    className={cn(
                                                        "ml-auto",
                                                        value?._id === contact._id ? "opacity-100" : "opacity-0"
                                                    )}
                                                    size={12}
                                                />
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </PopoverContent>
                    </Popover>
                </div>
                <DialogFooter>
                    <Button type="submit" disabled={!value} onClick={() => mutate()}>{isPending ? <Loader2 className="animate-spin h-4 w-4" /> : 'Unificar'}</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
