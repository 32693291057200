import { getCompanyGroups } from "@/actions/companies"
import { ICompanyGroup } from "@/interfaces/Interface"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"

interface UseGetCompanyGroupsProps {
    enabled?: boolean,
    queryKey?: any[],
    externalSearchParams?: Record<string, any>
}

export default function useGetCompanyGroups({
    enabled = true,
    queryKey = ['company-groups-list'],
    externalSearchParams = {}
}: UseGetCompanyGroupsProps) {
    const [companies, setCompanies] = useState<ICompanyGroup[]>([])

    const { data, isLoading, ...rest } = useQuery({
        enabled,
        queryKey,
        queryFn: () => getCompanyGroups(externalSearchParams)
    })

    useEffect(() => {
        if (data) setCompanies(data)
    }, [data])

    const onHandleChangeCompanies = (values: ICompanyGroup[]) => setCompanies(values)

    return {
        companies,
        onHandleChangeCompanies,
        isLoading,
        data,
        ...rest
    }
}
