import { DatePicker } from "@/components/ui/date-picker";
import { ChevronDown } from "lucide-react";

interface DateSelectProps {
    value?: Date,
    onChangeValue: (value: Date) => void,
    editMode?: boolean
}

export default function DateSelect({
    value, 
    onChangeValue, 
    editMode = true
}: DateSelectProps) {
    return (
        <DatePicker
            classNameIcon="hidden"
            onChangeValue={onChangeValue}
            formatDateValue="dd/mm/yyy hh:mm"
            isEditMode={editMode}
            value={value ? new Date(value as Date) : undefined}
            classNameTriggerButton="w-max group truncate h-max text-[13.5px] text-gray-700 border-none bg-transparent hover:bg-transparent p-0 max-w-full flex items-center hover:text-gray-800 text-start text-[13.5px]"
            extraTriggerContent={editMode && <ChevronDown size={16} className="opacity-50 ml-2 text-gray-800" />}
        />
    )
}