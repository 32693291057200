import { CompanyGroupContact } from "@/interfaces/Interface";
import { cn } from "@/lib/utils";
import ContactItem from './ContactItem';
import ContactSkeleton from "./ContactSkeleton";

interface ContactsListItemProps {
    onChangeContactValue: (value: CompanyGroupContact) => void,
    contacts: (CompanyGroupContact | 'loader')[],
    onOpenAddContact: () => void,
    classNameTitle?: string,
    title?: string,
    currentUser: any
}

export default function ContactsListItem({
    contacts,
    title,
    classNameTitle,
    onOpenAddContact,
    currentUser,
    onChangeContactValue
}: ContactsListItemProps) {
    return (
        <div>
            <div className={`mb-4 flex items-center ${!title ? 'justify-end' : 'justify-between'}`}>
                {title && (
                    <h5 className={cn('font-medium text-gray text-[15px] text-gray-900', classNameTitle)}>
                        {title}
                    </h5>
                )}
                <button
                    className="text-primary text-[12px] font-medium hover:opacity-70 duration-100"
                    onClick={() => onOpenAddContact()}
                >
                    Agregar contacto
                </button>
            </div>
            <ul className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {
                    contacts.map((contact) => {
                        if (contact == 'loader') return <ContactSkeleton />;
                        return (
                            <li key={contact._id} className="h-full">
                                <ContactItem
                                    contact={contact}
                                    currentUser={currentUser}
                                    onChangeContactValue={onChangeContactValue}
                                />
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
};
