import { toastActionError } from "@/components/comercial/comercialManagment/comissions/CommisionModal";
import useDebounce from "@/components/core/functions/useDebouce";
import { fetcher } from "@/helpers/fetcher";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

interface SearchFieldProps {
    onSelectOption?: (option: SearchOption | null) => void,
    onSearchValue?: (value: string) => void
    inputValue?: string,
    disabled?: boolean,
    field: {
        type: 'search',
        endpointUrl: string,
        title: string,
        field: string,
        required?: boolean
    }
}

interface SearchOption {
    value: string;
    title: string;
    values: [string, string][]; // [Código del campo, Valor del campo][]
}

export default function SearchField(props: SearchFieldProps) {
    const [options, setOptions] = useState<SearchOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<SearchOption | null>(null);

    const { onSelectOption, inputValue, disabled, onSearchValue } = props;
    const { endpointUrl, title, field: fieldCode, required } = props?.field || {};
    const [searched, setSearched] = useState<boolean>(false);

    const debouncedSearchValue: string = useDebounce(inputValue, 400) as string;

    const fetchFn = async (url: string, search: string) => {
        try {
            const value = await fetcher<SearchOption[]>({
                url: `${url}?search=${search}`,
                defaultContentType: true,
                isExternalURL: true,
                withToken: true,
                method: 'GET'
            });

            return Array.isArray(value) ? value : [];
        } catch {
            toastActionError();
            return [];
        }
    }

    const { data, isLoading } = useQuery({
        queryKey: [`search-field-${fieldCode}`, debouncedSearchValue],
        enabled: searched && !!debouncedSearchValue && !!endpointUrl && (!selectedOption || selectedOption && (selectedOption.title !== inputValue && selectedOption.value !== inputValue)),
        queryFn: () => fetchFn(endpointUrl, debouncedSearchValue)
    });

    useEffect(() => {
        if (data) setOptions(data);
    }, [data]);

    const onInputChange = (_: any, value: string) => {
        if (selectedOption && selectedOption.title == value) return;
        
        onSearchValue && onSearchValue(value);
        if (!searched) setSearched(true);
    };

    const handleChange = (_: any, value: SearchOption | null) => {
        setSelectedOption(value);
        onSelectOption && onSelectOption(value);
    };

    return (
        <Autocomplete
            freeSolo
            options={options}
            getOptionLabel={(option) => (option as SearchOption)?.title || ""}
            onInputChange={onInputChange}
            filterOptions={(x) => x}
            loading={isLoading}
            disabled={disabled}
            onChange={handleChange}
            inputValue={inputValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={title}
                    required={required}
                    fullWidth
                    variant="outlined"
                    disabled={disabled}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}