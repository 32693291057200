import { IInstance, InstancesService } from '@/actions/training'
import { IconPlayNotification } from '@/components/Header/CardNotification'
import MenuTraining from '@/components/Menues/MenuTraining'
import Loading from '@/components/core/Loading'
import { NotContent } from '@/components/instance/ListOfPost'
import { ModalViewClass } from '@/components/training/CardOfClass'
import CarrouselOfClassVideo from '@/components/training/CarrouselOfClassVideo'
import { CardDescription, CardTitle } from '@/components/ui/card'
import { formatTime } from '@/helpers/formatSeconds'
import useInstance from '@/hooks/useInstance'
import usePagination from '@/hooks/usePagination'
import { useToggle } from '@/hooks/useToggle'
import { Clock3 } from 'lucide-react'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TitleCoach } from '.'
import { LIMIT_OF_INSTANCE, LoaderForInfinityScroll } from './instances'
import { useSelector } from 'react-redux'

export default function Tutorials() {
    const router = useRouter()
    const idOfInstance = router.query.id

    const [allInstances, setAllInstances] = useState<Array<any>>([])
    const { handleNextPage, page, setLimitPage, limitOfPage } = usePagination()
    const [value, _, setOpenView] = useToggle<any>(false)
    const manager = useSelector((state: any) => state.header.manager)

    const { instances: lastInteraction, isLoading: isLoadingLastInteractions, refetch: refetchLastInteraction } = useInstance({
        type: 'tutorial',
        page: 1,
        size: 5,
        externalQueryKey: ['tutorial-view-user'],
        lastInteraction: idOfInstance ? false : true
    })

    const restOfLastInteraction = useMemo(() => lastInteraction.splice(1, lastInteraction.length - 1), [lastInteraction])
    const idsOfLastTutorials = useMemo(() => [...restOfLastInteraction, ...lastInteraction].map(t => t._id), [lastInteraction, restOfLastInteraction])

    const allInstancesFiltered = useMemo(() => allInstances.filter(tutorial => !idsOfLastTutorials.includes(tutorial._id)), [allInstances, idsOfLastTutorials])

    const { instances: tutorials, refetch, countDocuments, isLoading, handleUpdateLastInteraction } = useInstance({
        size: LIMIT_OF_INSTANCE,
        externalQueryKey: ['lis-of-tutorial'],
        type: 'tutorial',
        page
    })

    useEffect(() => {
        if (page > 1) refetch()
    }, [page])

    const findTutorialByID = async (id) => {
        try {
            const tutorial = await InstancesService.get({
                type: 'tutorial',
                id,
                page: 1,
                size: 1
            })

            return tutorial
        } catch {

        }
    }

    useEffect(() => {
        if (idOfInstance) {
            setOpenView(true)
            if (idOfInstance) {
                findTutorialByID(idOfInstance)
                    .then((res: any) => {
                        setOpenView(res?.instance)
                    })
            }
        }
    }, [idOfInstance])

    useEffect(() => {
        setAllInstances(lastInstances => [...lastInstances, ...tutorials])
        setLimitPage(Math.ceil(countDocuments / LIMIT_OF_INSTANCE))
    }, [tutorials])

    const handleOpenAdnUpdateLastInteraction = (information) => {
        handleUpdateLastInteraction(information._id)
        setOpenView(information)
    }

    const handleCloseModal = () => {
        if (idOfInstance) {
            refetchLastInteraction()
            return router.replace(router.pathname, undefined, { shallow: true });
        }

        setOpenView(false)
    }

    return <MenuTraining slug={'tutorials'}>
        {
            limitOfPage === 0 && (tutorials.length === 0 && lastInteraction.length === 0) && !isLoadingLastInteractions && !isLoading
                ? <NotContent label={!manager ? 'Tu empresa no tiene un entrenador asignado' : 'Sin publicaciones.'} />
                : isLoadingLastInteractions
                    ? <Loading />
                    : <div className='md:px-8 px-4 py-5 m-auto w-full flex flex-grow justify-start flex-col'
                        style={{
                            maxWidth: "calc(1280px + 700px)"
                        }}>

                        {
                            lastInteraction.length > 0 && <>
                                <TitleCoach title={'Continuar viendo'} />
                                <MainOfInstances
                                    secondSection={<>
                                        <ul className='block mt-3'>
                                            {
                                                restOfLastInteraction.map(instance => (
                                                    <CardLastInstanceViewed
                                                        onAction={() => handleOpenAdnUpdateLastInteraction(instance)}
                                                        instance={instance} key={instance._id} />
                                                ))
                                            }
                                        </ul>
                                    </>}
                                    activeSecondary={restOfLastInteraction.length > 0}
                                    firstSection={<>
                                        <div className='min-h-[360px] block'>
                                            <div
                                                className='relative w-full h-full block group cursor-pointer object-cover items-center overflow-hidden'
                                                onClick={() => handleOpenAdnUpdateLastInteraction(lastInteraction[0])}
                                                style={{
                                                    maxHeight: "calc(100vh - 56px - 24px - 136px)",
                                                }}
                                            >
                                                <img src={lastInteraction[0].miniature} className='w-full h-full object-cover' />
                                                <div className='group-hover:block hidden'>
                                                    <IconPlayNotification className='w-8 h-8' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between mt-3'>
                                            <CardTitle className='text-[17px] text-slate-800 leading-[1.3]'>
                                                {lastInteraction[0]?.title}
                                            </CardTitle>
                                            <CardDescription className='flex items-center text-[13px] [&>svg]:w-[15px] [&>svg]:h-[15px] gap-1'>
                                                <Clock3 />
                                                {formatTime(lastInteraction[0]?.duration)}
                                            </CardDescription>
                                        </div>
                                    </>
                                    }
                                />
                            </>
                        }
                        <section>
                            {
                                (!isLoadingLastInteractions && allInstancesFiltered.length > 0) && <InfiniteScroll
                                    dataLength={allInstances.length}
                                    hasMore={page < limitOfPage}
                                    next={handleNextPage}
                                    loader={<LoaderForInfinityScroll />}
                                    style={{
                                        overflowY: 'hidden'
                                    }}
                                >
                                    <article key={'listOfTutorials'} className={`${allInstances.length && lastInteraction.length > 0 ? 'mt-7' : ''}`}>
                                        <CarrouselOfClassVideo
                                            videos={allInstancesFiltered}
                                            title='Todos los tutoriales'
                                            type='instances' />
                                    </article>
                                    {
                                        isLoading && <LoaderForInfinityScroll key={'loading'} />
                                    }
                                </InfiniteScroll>
                            }
                        </section>
                    </div>
        }
        <ModalViewClass
            link={value?.linkOfClass}
            onClose={handleCloseModal}
            open={!!value} />
    </MenuTraining>
}

export function CardLastInstanceViewed({ instance, onAction }: { instance: IInstance, onAction: () => void }) {
    const { miniature, title, duration } = instance

    return <>
        <li className='cursor-pointer block group hover:bg-slate-50' onClick={onAction}>
            <li className='w-full '>
                <div className='w-full flex gap-4 border-b md:px-2 '>
                    <div className='relative my-3 w-[100px] cursor-pointer h-[50px] flex aspect-video items-center overflow-hidden '>
                        <div className='absolute inset-0 bg-neutral-800 bg-opacity-50 transition-opacity group-hover:bg-opacity-40'>
                        </div>
                        <img src={miniature} alt={`miniature of intance ${title}`} />
                        <div className='group-hover:block hidden'>
                            <IconPlayNotification className='w-6 h-6' />
                        </div>
                    </div>
                    <div className='justify-center flex items-start flex-col overflow-hidden'>
                        <CardTitle className='text-[14px] text-slate-800 leading-[1.3] text-ellipsis whitespace-nowrap overflow-hidde'>
                            {title}
                        </CardTitle>
                        <CardDescription className='mt-1 text-ellipsis whitespace-nowrap overflow-hidden flex items-center text-[12px] [&>svg]:w-[15px] [&>svg]:h-[15px] gap-1'>
                            <Clock3 />
                            {formatTime(duration)}
                        </CardDescription>
                    </div>
                </div>
            </li>
        </li>
    </>
}

export function MainOfInstances({ firstSection, secondSection, activeSecondary = true }) {
    return <section className='block'>
        <div className='flex md:flex-row flex-col min-h-[410px] m-auto'>
            <div className='flex-[2] md:pr-[24px] block basis-[0.000000001px]'>
                {firstSection}
            </div>
            {
                activeSecondary && <div className='block flex-1 relative'>
                    {
                        secondSection
                    }
                </div>
            }
        </div>
    </section>
}