import { cn } from "@/lib/utils"
import { LocalStatus } from "@/redux/libs/ts/interfaces"
import { AlertTriangle } from "lucide-react"

export function InteractionStatus({
    status,
    handleRetry,
    className,
    messageError,
}: {
    status: LocalStatus | null | undefined,
    handleRetry: () => void,
    className?: string
    messageError: string
}) {
    if (status === LocalStatus.ERROR) return <p className={cn("flex items-center gap-1.5  text-[14px] text-red-600", className)}>
        <AlertTriangle size={17} className="shrink-0"/>
        {messageError}
        <button onClick={handleRetry} className="font-medium shrink-0 underline">
            Volver a intentar.
        </button>
    </p>

    return null
}