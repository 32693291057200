import { isActiveSharedInbox } from "@/actions/crm/sharedInbox";
import { setSharedInboxState } from "@/redux/slices/headers";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useActiveSharedInbox() {
    const sharedInboxState = useSelector((state: any) => state.header.sharedInboxState);
    const dispatch = useDispatch();

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const { data } = useQuery({
        queryKey: ['active-shared-inbox'],
        queryFn: () => isActiveSharedInbox(timeZone),
        enabled: !sharedInboxState,
        retry: false
    })

    useEffect(() => {
        if (!data) return;

        dispatch(setSharedInboxState({
            active: !!data?.active,
            activeNow: !!data?.activeNow
        }))
    }, [data])

    return {
        activeNow: !!sharedInboxState?.activeNow,
        active: !!sharedInboxState?.active
    }
}
