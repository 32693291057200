import { CardDescription } from "@/components/ui/card"
import { cn } from "@/lib/utils"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { SaleUpdate } from "../../../interfaces/crm/contact"
import { setRequestSaleId } from "../../../redux/slices/crm"
import { ListAlt } from "./Icons/Crm"

moment.locale("es")

interface ISaleUpdateBubble {
  interaction: SaleUpdate
  customStyle: string | null | undefined
  date: Date
}

const getHeader = (header: string, date: Date) => {
  return (
    <div className="bg-white  hover:bg-slate-100 transition-[background-color] py-2.5 px-4 relative text-[#6B7280] gap-3 rounded-[10px] flex items-center justify-center border-none shadow-bubble-right">
      <ListAlt className="w-[17px] h-[17px]" />
      <p
        className={`flex items-center  text-center text-[14px] font-medium gap-2 `}
      >
        {header}
      </p>
      <span className="text-[13px] text-[#6B7280]">
        {moment(date).format("LT")}
      </span>
    </div>
  )
}

const getContent = (interaction: SaleUpdate, date: Date) => {
  switch (interaction?.content?.type) {
    case "comment":
      return (
        <>
          {getHeader("Comentario en solicitud", date)}
          <CardDescription>{interaction.content.comment}</CardDescription>
        </>
      )
    case "created":
      return getHeader(`Se creo una nueva solicitud`, date)
    case "state-change":
      return getHeader(`Cambio estado ${interaction.content.state}`, date)
    case "updated":
      return getHeader(`Se modificó una solicitud`, date)
    default:
      return getHeader(`Solicitud en ${interaction.content.state}`, date)
  }
}

const SaleUpdateBubble = ({
  interaction,
  date,
  customStyle,
}: ISaleUpdateBubble) => {
  const action = useSelector((state: any) => state.crm.action)
  const dispatch = useDispatch()

  return (
    <div className="flex items-center justify-center w-full">
      <button
        // className={cn("xl:w-[40%] lg:w-[55%] sm:w-[60%]  w-[90%]", customStyle)}
        className={cn("max-w-[90%] w-[450px]", customStyle)}

        onClick={() => {
          dispatch(setRequestSaleId(interaction.content.requestsaleid))
        }}
      >
        {getContent(interaction, date)}
      </button>
    </div>
  )
}

export default SaleUpdateBubble
