import { NetPromoterScoreType } from "@/components/company/netprometerscore/NetPromoterScoreModal";
import { fetcher } from "@/helpers/fetcher";
import { formatSearchParams } from "@/helpers/formatSearchParams";
import { ActionSurveyStatusType, INetPromoterScore, INetPromoterScoreSurvey, NetPromoterScoreStats } from '../../interfaces/Interface';
import { Period, PeriodValue } from "@/hooks/company/useGetNewNpsStats";

const getNpsList = () => {
    return fetcher<{ netPromoterScores: INetPromoterScore[] }>({
        url: `/company/netPromoterScores`,
        method: 'GET',
        defaultContentType: true
    })
}

const deleteNpsById = (id: string) => {
    return fetcher<{ success: boolean }>({
        url: `/company/netPromoterScores/${id}`,
        method: 'DELETE',
        defaultContentType: true
    })
}

const updateNpsById = (id: string, body: NetPromoterScoreType) => {
    return fetcher<{
        success: boolean,
        netPromoterScore: INetPromoterScore
    }>({
        url: `/company/netPromoterScores/${id}`,
        defaultContentType: true,
        method: 'PATCH',
        body: body
    })
}

const createNps = (body: NetPromoterScoreType) => {
    return fetcher<{
        success: boolean,
        netPromoterScore: INetPromoterScore
    }>({
        url: `/company/netPromoterScores`,
        defaultContentType: true,
        method: 'POST',
        body: body
    })
}

const getNetPromoterScoreStats = (params: { gte: string, lte: string }) => {
    const queryParams = formatSearchParams(params);

    return fetcher<{
        success: boolean,
        stats: NetPromoterScoreStats
    }>({
        url: `/company/netPromoterScores/statistics/old?${queryParams}`,
        defaultContentType: true,
        method: 'GET'
    })
};

export const getNewNetPromoterScoreStats = (params: { period: Period }) => {
    const queryParams = formatSearchParams(params);

    return fetcher<{
        success: boolean,
        stats: PeriodValue[]
    }>({
        url: `/company/netPromoterScores/statistics?${queryParams}`,
        defaultContentType: true,
        method: 'GET'
    })
};

const getNetPromoterScoreSurveys = (params: { [key: string]: any }) => {
    const queryParams = formatSearchParams(params);
    return fetcher<{
        success: boolean,
        surveys: INetPromoterScoreSurvey[],
        countDocuments: number
    }>({
        url: `/company/netPromoterScores/surveys?${queryParams}`,
        defaultContentType: true,
        method: 'GET'
    })
};

const sendSurveyToContactsList = (netPromoterScore: string, contacts: string[]) => {
    return fetcher<{ success: boolean }>({
        url: `/company/netPromoterScores/${netPromoterScore}/sendSurvey`,
        defaultContentType: true,
        body: { contacts },
        method: 'POST'
    });
}

const changeActionStatus = (id: string, status: ActionSurveyStatusType) => {
    return fetcher<{ success: boolean }>({
        url: `/company/netPromoterScores/surveys/${id}/changeActionStatus?status=${status}`,
        defaultContentType: true,
        method: 'PUT'
    });
};

const cancelSurvey = (id: string, isCanceled: boolean) => {
    return fetcher<{ success: boolean }>({
        url: `/company/netPromoterScores/surveys/${id}/cancel?isCanceled=${isCanceled}`,
        defaultContentType: true,
        method: 'PUT'
    });
};

export {
    cancelSurvey, changeActionStatus, createNps, deleteNpsById, getNetPromoterScoreStats,
    getNetPromoterScoreSurveys, getNpsList, sendSurveyToContactsList,
    updateNpsById
};

