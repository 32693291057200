export type ReactionType = {
    name: string;
    icon: string;
};

export type Reactions = 'like' | 'love' | 'checked' | 'applaud' | 'strength' | 'celebration'

export const reactions: Record<Reactions, ReactionType> = {
    like: {
        name: "Recomendar",
        icon: '👍'
    },
    love: {
        name: "Encantar",
        icon: '❤️'
    },
    applaud: {
        name: "Aplaudir",
        icon: '👏'
    },
    strength: {
        name: "Fuerza",
        icon: '💪'
    },
    celebration: {
        name: "Celebración",
        icon: '🙌'
    },
    checked: {
        name: 'Checkeado',
        icon: '✅'
    }
};

export const allTheReaction = Object.fromEntries(Object.entries(reactions).map(([key, values]) => [values.icon, key]))