import Link from "next/link"
// import { NotificationNumber } from "../Header/NavBarLink"
import { useRouter } from "next/router"
import { useMemo } from "react"
import NavLink from "../Header/NavLink"


export default function NavBarOfTraining({ slug }) {

    const listOfLinks = [
        {
            href: '/training',
            notifications: 0,
            slug: 'training',
            label: 'Todos'
        },
        {
            href: '/training/publications',
            notifications: 0,
            slug: 'publications',
            label: 'Publicaciones'
        },
        {
            href: '/training/tutorials',
            notifications: 0,
            slug: 'tutorials',
            label: 'Tutoriales'
        },
        {
            href: '/training/instances',
            notifications: 0,
            slug: 'instances',
            label: 'Clases grabadas'
        }
    ]

    return <nav className='h-full md:w-max w-full'>
        <ul className='flex items-center w-full md:flex-row md:gap-0 gap-[2px] flex-col h-full'>
            {
                listOfLinks.map(({ href, notifications, slug: slugOfLink, label }) => (
                    <li className='h-full w-full' key={href}>
                        <Link href={href} className={`md:hover:text-primary md:justify-center md:w-max w-full md:border-b-[3px] flex md:items-center h-full gap-2 md:text-[14px] shrink-0 md:hover:bg-transparent hover:bg-blue-100 font-medium px-3 py-1 md:rounded-none rounded-[4px] text-[13px] ${slugOfLink === slug ? 'border-primary md:bg-transparent bg-blue-100 md:text-primary' : 'border-transparent'}`}>
                            {label}
                        </Link>
                    </li>
                ))
            }
        </ul>
    </nav>
}

export function NavBarForAddNewInstance({ slug }) {
    const query = useRouter().query
    const idOfCompany = query.comercial

    const listOfLinks = [
        {
            href: `/comercial/${idOfCompany}/instances`,
            slug: 'instance',
            label: 'Instancias'
        },
        {
            href: `/comercial/${idOfCompany}/publications`,
            slug: 'publication',
            label: 'Publicaciones'
        },
    ]

    return <nav className='h-full md:mt-0 mt-2'>
        <ul className='flex flex-row h-full'>
            {
                listOfLinks.map(({ href, slug: slugOfLink, label }) => (
                    <li className='h-full' key={href}>
                        <Link href={href} className={`hover:text-primary border-b-[3px] flex relative md:py-0 py-4 items-center h-full gap-2 md:text-[14px] text-[12px] font-medium px-3 ${slugOfLink === slug ? 'border-primary  text-primary' : 'border-transparent'}`}>
                            {label}
                        </Link>
                    </li>
                ))
            }
        </ul>
    </nav>
}