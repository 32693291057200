import CardError from '@/components/toaster/card-error'
import { useToast } from '@/components/ui/use-toast'
import { useSelector } from 'react-redux'

export default function useGetIsBlockNumber() {
    const { toast } = useToast()

    const phoneNumberStates = useSelector((state: any) => state?.crm?.contact?.phoneNumberStates) || []

    const findNumberState = (number: string): undefined | {
        phoneNumber: string,
        disableCall: boolean,
        disableCreation: boolean,
        disableChat: boolean
    } => {
        return phoneNumberStates.find((config) => config.phoneNumber === number)
    }

    const handlePressBlockNumber = (phoneNumberState: Record<string, any> = {}) => {
        toast({
            duration: 3000,
            className: "p-3 py-4",
            customElement: (
                <CardError
                    title='Numero de telefono restringido '
                    description={
                        phoneNumberState.blockReason || `La función de llamadas ha sido restringida para el número de teléfono ${phoneNumberState.phoneNumber}.`
                    }
                />
            )
        })
    }

    const someBlockedSetting = (field: 'disableCall' | 'disableCreation' | 'disableChat', defaultNumber) => {
        const findSetting = phoneNumberStates.some(op => op[field] === true)
        if (!findSetting) return false
        const findDefaultValue = findNumberState(defaultNumber)
        return findDefaultValue
    }

    return {
        findNumberState,
        someBlockedSetting,
        handlePressBlockNumber
    }
}
