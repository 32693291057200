import { downloadFiles, getFiles, getThreadEmails } from '@/actions/crm/contact'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card'
import { handleRepyMessage, setAction } from '@/redux/slices/crm'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import ModalViewThread from './ModalViewThread'
import { isAuth } from '@/actions/auth'
import { Skeleton } from '@/components/ui/skeleton'
import { CardDescription, CardTitle } from '@/components/ui/card'
import { File } from 'lucide-react'
import FormatHtml from '@/components/format-html'

export default function ModalViewEmail({ open, close }) {
    const dispatch = useDispatch()
    const [thread, setThread] = useState<any>(null)
    const [files, setFiles] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const { data: emailsThreadData, isLoading: loadingEmails } = useQuery({
        queryKey: ['get-emails-thread'],
        queryFn: () => getThreadEmails(open.threadId),
        enabled: !!open,
    });

    const user = isAuth()

    const handleReplyMessage = () => {
        dispatch(handleRepyMessage(open))
        dispatch(setAction("email"));
        close()
    }

    useEffect(() => {
        if (open.files && open.files.length > 0) {
            handleGetFiles()
        } else if (open.attachments && open.attachments.length > 0) {
            setFiles(open.attachments)
        }
    }, [open])

    const handleGetFiles = async () => {
        setLoading(true)
        const providerId = user.emailProviders[0]
        await getFiles(providerId, open.files).then((result) => {
            setFiles(result)
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDownloadFile = async (file) => {
        if (open.files && open.files.length > 0) {
            try {
                const download = downloadFiles(user.emailProviders[0], file.id).then((result) => {
                    const blobUrl = URL.createObjectURL(result as Blob)

                    const downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;
                    downloadLink.download = file.filename;

                    downloadLink.click();
                }).catch((error) => {
                    console.log('Error al descargar el archivo desde Nylas:', error)
                })
            } catch (error) {
                console.log('Error al descargar el archivo desde Nylas:', error)
            }
        } else {
            const downloadLink = document.createElement('a');
            downloadLink.href = file.imageUrl;
            downloadLink.download = file.filename;
            downloadLink.click();
        }
    }

    const hasAttachedFiles = useMemo(() => {
        return open.files && open.files.length > 0 || open.attachments && open.attachments.length > 0
    }, [open])


    return !!open && (
        <>
            <ModalViewThread open={thread} close={() => setThread(false)} />
            <Dialog onOpenChange={close} open={!!open}>
                <DialogContent className="h-[90%]   xl:min-w-[1000px] flex flex-col">
                    <DialogHeader className='flex flex-col border-b pb-2'>
                        <div className='flex flex-row  items-center border-b-1'>
                            <div className='flex flex-col space-y-1 w-full'>
                                <DialogTitle className='font-normal pb-1 text-xl'>{open.subject}</DialogTitle>
                                <DialogDescription style={{ lineHeight: 1 }} className='pb-2'>
                                    {open.from.map((sender, index) => (
                                        <span key={index}>
                                            <span className='text-[16px] text-neutral-800'>{sender.name}</span>{" "}
                                            <span>{`<${sender.email}>`}</span>
                                            {index < open.from.length - 1 && ", "}
                                        </span>
                                    ))}
                                </DialogDescription>
                                <div className='flex justify-between items-center'>
                                    <div className='space-x-6'>
                                        <HoverCard openDelay={100} closeDelay={100}>
                                            <HoverCardTrigger asChild>
                                                <span className='text-primary text-[14px] cursor-pointer hover:text-blue-400'>Más detalles</span>
                                            </HoverCardTrigger>
                                            <HoverCardContent className="max-w-[400px] w-full">
                                                <div className="flex flex-col">
                                                    <div className='flex space-x-1 items-start'>
                                                        <span className="text-base">Para: </span>
                                                        <span className='text-base text-gray-600'>
                                                            {(() => {
                                                                const recipients = open.recipients.map(recipient => recipient.email).join(', ')
                                                                return recipients
                                                            })()}
                                                        </span>
                                                    </div>
                                                    {
                                                        open.cc && open.cc.length > 0 && <div className='flex space-x-1 items-end'>
                                                            <span className="text-sm text-primary">Cc:</span>
                                                            <span className='text-sm text-gray-600'>
                                                                {open.cc.length > 0 && (() => {
                                                                    const cc = open.cc.map(user => user.email).join(', ')
                                                                    return cc
                                                                })()}
                                                            </span>
                                                        </div>
                                                    }
                                                    {
                                                        open.cc && open.cc.length > 0 && <div className='flex space-x-1 items-end'>
                                                            <span className="text-sm text-primary">Cco:</span>
                                                            <span className='text-sm text-gray-600'>
                                                                {open.bcc.length > 0 && (() => {
                                                                    const bcc = open.bcc.join(', ')
                                                                    return bcc
                                                                })()}
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </HoverCardContent>
                                        </HoverCard>
                                        {emailsThreadData && emailsThreadData.total > 0 &&
                                            <Button className='text-primary hover:text-primary' variant={'ghost'} size={'sm'} type='submit' onClick={() => setThread(emailsThreadData.thread)}>{`Ver Hilo (${emailsThreadData.total})`}</Button>
                                        }
                                    </div>
                                    <DialogDescription className='' style={{ lineHeight: 1 }}>
                                        {moment(open.sentDate).format('lll')}
                                    </DialogDescription>
                                </div>
                            </div>
                        </div>
                    </DialogHeader>
                    <FormatHtml className='w-full flex-grow overflow-y-auto'>
                        {open.body || ''}
                    </FormatHtml>
                    {hasAttachedFiles && (
                        <div className='flex flex-col border-t-2 py-4 gap-2'>
                            <DialogDescription className='font-semibold' style={{ lineHeight: 1 }}>
                                Archivos adjuntos:
                            </DialogDescription>
                            <div className='p-2'>
                                {loading ? (
                                    <div className='flex flex-row gap-2'>
                                        {open.files ? open.files.map((_, index) => (
                                            <div className="flex items-center border border-input h-12 py-2 px-3 rounded-md gap-2 max-w-fit" key={index}>
                                                <div className="flex items-center gap-2">
                                                    <Skeleton className='w-6 h-6' />
                                                    <div>
                                                        <Skeleton className='w-24 h-3' />
                                                        <Skeleton className='w-16 h-2' />
                                                    </div>
                                                </div>
                                            </div>
                                        )) : open.attachments ? (
                                            open.attachments.map((_, index) => (
                                                <div className="flex items-center border border-input h-12 py-2 px-3 rounded-md gap-2 max-w-fit" key={index}>
                                                    <div className="flex items-center gap-2">
                                                        <Skeleton className='w-6 h-6' />
                                                        <div>
                                                            <Skeleton className='w-24 h-3' />
                                                            <Skeleton className='w-16 h-2' />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className='overflow-x-auto'>
                                        {files?.length > 0 ? files.map((file) => {
                                            return <div className="flex items-center border border-input py-2 px-3 rounded-md gap-2 max-w-fit cursor-pointer hover:bg-slate-100" key={file.id} onClick={() => handleDownloadFile(file)}>
                                                <div className="flex items-center gap-2">
                                                    <div className="text-muted-foreground">
                                                        <File size={25} />
                                                    </div>
                                                    <div>
                                                        <CardTitle className="text-[14px] mb-[4px]" style={{ lineHeight: 1 }}>{file.filename}</CardTitle>
                                                        <CardDescription className="text-[12px]" style={{ lineHeight: 1 }}>
                                                            {file.size} B
                                                        </CardDescription>
                                                    </div>
                                                </div>
                                            </div>
                                        }) : open.attachments ? open.attachments.map((attachment) => (
                                            <div className="flex items-center border border-input py-2 px-3 rounded-md gap-2 max-w-fit cursor-pointer hover:bg-slate-100" key={attachment.attachmentId} onClick={() => handleDownloadFile(attachment)}>
                                                <div className="flex items-center gap-2">
                                                    <div className="text-muted-foreground">
                                                        <File size={25} />
                                                    </div>
                                                    <div>
                                                        <CardTitle className="text-[14px] mb-[4px]" style={{ lineHeight: 1 }}>{attachment.filename}</CardTitle>
                                                        <CardDescription className="text-[12px]" style={{ lineHeight: 1 }}>
                                                            {attachment.size} B
                                                        </CardDescription>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <DialogFooter className='mt-2'>
                        {/* {open.origin == 'contact' && ( */}
                        <Button
                            onClick={handleReplyMessage}
                            className='rounded-3xl border-gray-500'
                            variant={'outline'}>Responder</Button>
                        {/* )} */}
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )

}
