import { Card, CardContent, CardDescription, CardTitle } from "@/components/ui/card";
import { Receipt } from "@/interfaces/Interface";

interface SaleItemProps {
    receipt: Receipt,
    onSelect: (reciept: Receipt) => void
}

export default function SaleItem({ receipt, onSelect }: SaleItemProps) {
    const {
        product, ticket_id, ticket_sale_id,
        productImage, saleStructureType
    } = receipt || {};

    const description = saleStructureType == 'multiline' ? 'Multiple productos' : product || ''

    return (
        <Card
            onClick={() => onSelect(receipt)}
            className='flex hover:bg-accent cursor-pointer items-center w-full hadow-none rounded-sm pb-0 mb-0 m-0 p-0'
        >
            <CardContent className="flex flex-col overflow-hidden p-2 px-3 w-full">
                <div className="flex flex-col">
                    {
                        productImage && (
                            <div className="w-[85px] h-[48px] mb-2 shrink-0 overflow-hidden rounded-sm relative">
                                <img className="w-full h-full object-cover" src={productImage} />
                            </div>
                        )
                    }
                    <CardTitle className='text-[14px] truncate max-w-[100%] text-gray-800'>
                        {ticket_id || ticket_sale_id}
                    </CardTitle>
                    <CardDescription>
                        {description}
                    </CardDescription>
                </div>
            </CardContent>
        </Card>
    )
};