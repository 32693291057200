import { z } from "zod"

export const saleDefaultValues = {
    ticket_id: '',
    date: new Date(),
    date_sale: new Date(),
    ticket_sale_id: '',
    phone: '',
    name: '',
    status: '',
    type: '',
    gov_id: '',
    product: '',
    sold: false
}

export const saleFormSchema = z.object({
    ticket_id: z.string(),
    ticket_sale_id: z.string().optional(),
    date: z.date(),
    date_sale: z.date().optional(),
    phone: z.string(),
    name: z.string(),
    status: z.string(),
    type: z.string().optional(),
    gov_id: z.string().optional(),
    product: z.string().optional(),
    user: z.string(),
    sold: z.boolean(),
})