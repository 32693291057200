import { isAuth } from '@/actions/auth'
import { createCloneContact } from '@/actions/crm/contact'
import AlertInformation from '@/components/AlertDialogs/alert-information'
import { useToast } from '@/components/ui/use-toast'
import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import { MessagesSquare } from 'lucide-react'

export default function CreateSubConversationAlert({
    open,
    onClose,
    contactId,
    body = {},
    currentUser = false,
    onSuccess,
    onError
}: {
    open: boolean | undefined | null | string,
    onClose: () => void,
    contactId: string | string[],
    body?: {
        agent?: string,
        description?: string
    },
    currentUser?: boolean
    onError?: (data: any) => void
    onSuccess?: (data: any) => void
}) {
    const currentUserId = _.get(isAuth(), '_id', '')

    const { toast } = useToast()

    const { status, mutate } = useMutation({
        mutationFn: createCloneContact,
        onError: (error) => onError && onError(error),
        onSuccess: (data) => {
            onSuccess && onSuccess(data)
            onClose && onClose()
        }
    })
    const isLoading = status === 'pending'

    const handleSubmit = () => {
        const arrayObject = [contactId].flat()

        const allPromises = arrayObject.map((id) => {
           return new Promise((resolve, reject) => {
                createCloneContact({
                    id,
                    body: {
                        description: body.description || '',
                        agent: currentUser ? currentUserId : body.agent
                    }
                })
                    .then((data) => {
                        if (data.error) return reject(data.error)
                        resolve(data)
                    })
                    .catch((error) => reject(error))
            })
        })

        Promise.all(allPromises)
            .then((data) => {
                if (data.includes(undefined)) {
                    toast({
                        variant: 'destructive',
                        title: 'Error',
                        description: 'Hubo un error, asegúrese de que el contacto no sea una sub-conversación',
                        duration: 3000
                    })
                } else {
                    onSuccess && onSuccess(data)
                }
                onClose && onClose()

            })
            .catch((error) => toast({
                variant: 'destructive',
                title: 'Error',
                description: error,
                duration: 3000
            }))
    }

    return (
        <AlertInformation
            handleCancel={onClose}
            handleConfirm={handleSubmit}
            icon={<MessagesSquare className="text-primary" size={25} />}
            title='Está creando una Sub-Conversación'
            labelBtnCancel='Volver'
            labelBtnDelete='Confirmar'
            description={
                currentUser
                    ? 'Tenga en cuenta que, al crearla, se le hará una copia del contacto.'
                    : 'Tenga en cuenta que, al crearla, se le hará una copia del contacto a quien se le haya transferido.'
            }
            loading={isLoading}
            open={!!open}
            onOpenChange={onClose}
        />
    )
}
