import Link from "next/link";
import { BiMessageSquareDetail } from "react-icons/bi";
import { Link2 } from 'lucide-react';

export default function ExternalAdReply({
    externalAdReply,
    children
}: any) {
    const { thumbnailUrl, sourceUrl, title, renderLargerThumbnail, showAdAttribution } = externalAdReply || {}
    const sourceDomain = getDomainFromUrl(sourceUrl);

    return (
        <div className={`justify-center relative flex md:w-[var(--width-bubble-media-type)] flex-col flex-grow`}>
            {
                showAdAttribution && (
                    <div className="px-2 pt-2">
                        <p className="flex items-center text-[11px] text-gray-500 gap-1">
                            <BiMessageSquareDetail size={16} />
                            <span>
                                Mensaje a partir de un anuncio
                            </span>
                        </p>
                    </div>
                )
            }
            <Link href={sourceUrl} target="_blank">
                <div className={`mx-1.5 h-auto flex mt-1.5 bg-[#cbdbf0] rounded-t-md hover:bg-[#c2d4ec] duration-75 cursor-pointer ${renderLargerThumbnail ? 'flex-col' : 'items-center'}`}>
                    {
                        (thumbnailUrl || (!renderLargerThumbnail && !thumbnailUrl)) && (
                            <div
                                className={`${renderLargerThumbnail ? 'w-full h-[170px] rounded-t-sm' : 'w-[80px] rounded-l-sm h-[80px]'}  relative overflow-hidden bg-neutral-950 flex items-center justify-center`}
                            >
                                {
                                    thumbnailUrl
                                        ? <img
                                            src={thumbnailUrl}
                                            className="w-full h-full object-cover"
                                        />
                                        : <Link2 size={26} className="text-gray-400" />
                                }
                            </div>
                        )
                    }
                    <div className="flex flex-col h-full px-3 py-4">
                        <h4 className="text-[13px] font-medium text-gray-800">
                            {title}
                        </h4>
                        {
                            sourceDomain && (
                                <p className="text-gray-500 text-[11px]">
                                    {sourceDomain}
                                </p>
                            )
                        }
                    </div>
                </div>
            </Link>
            {children}
        </div>
    )
}

const getDomainFromUrl = (url: string): string | null => {
    try {
        const parsedUrl = new URL(url);
        return parsedUrl.hostname;
    } catch (error) {
        return null;
    }
}