
import React from 'react'
import InformationLabel from './InformationLabel'
import AddressInput from '@/components/crm/Inputs/AddressInput';
import { ICompanyGroup } from '@/interfaces/Interface';

interface DirectionItemProps {
    onChangeValue: (value: any) => void,
    value: ICompanyGroup['direction'],
    hidden?: boolean
}

export default function DirectionItem({ onChangeValue, value, hidden }: DirectionItemProps) {
    if (hidden) return null;

    return (
        <li className='w-full grid grid-cols-8  gap-1'>
            <div className="col-span-2">
                <InformationLabel title={'Dirección'} />
            </div>
            <div className="col-span-6 w-full">
                <AddressInput
                    address={value}
                    placeholder={'Selecciona una dirección...'}
                    onAddressChange={onChangeValue}
                    classNameTriggerBtn="w-max truncate h-max p-0 border-none hover:text-gray-800 hover:bg-transparent text-[13.5px] [&>svg]:w-3 [&>svg]:w-3 rounded-full text-gray-700"
                    askExtraInfo={false}
                    required={false}
                />
            </div>
        </li>
    )
}
