import { icons } from "lucide-react"
import { AnyError } from "mongodb"
import { useMemo } from "react"

interface IconButtonTitleInterfaceProps {
    icon?: JSX.Element,
    title: string,
    iconColor?: string,
    onClick?: () => void,
    inverse?: boolean
    disabled?: boolean
    isActive?: boolean
    rounded?: boolean
    image?: string
    avatar?: any
}

const IconButtonTitle = (props: IconButtonTitleInterfaceProps) => {
    let { icon, title, iconColor, onClick, inverse, disabled, isActive, rounded, image, avatar } = props
    const Icon = useMemo(() => {
        if (avatar?.type == 'icon') {
            return icons[avatar.url]
        }
        return undefined
    }, [icon])

    return (
        <div onClick={() => onClick && onClick()} className={`cursor-pointer px-8 py-2 flex space-x-2 items-center min-h-full hover:bg-slate-50 ${isActive ? 'font-bold' : 'font-normal'} ${disabled ? 'opacity-50' : ''}`} style={isActive ? { boxShadow: 'inset 0px 0px 6px -2px rgba(0, 0, 0, 0.8)', borderRadius: '4px 4px 0px 0px' } : {}}  >
            {Icon ? (
                <Icon size={20} color={inverse ? iconColor : '#fff'} />
            ) : ((avatar && avatar.type == 'link') || image) ? (
                <div className='rounded-full border w-6 h-6 flex items-center justify-center' style={{ background: inverse ? 'inhert' : iconColor, color: inverse ? iconColor : '#fff' }}>
                    <img src={avatar?.url ?? image} alt="icon" className="h-auto w-auto rounded-full" />
                </div>
            ) : (
                <div className={rounded ? "rounded-full p-1.5" : ''} style={{ background: inverse ? 'inhert' : iconColor, color: inverse ? iconColor : '#fff' }}>{icon}</div>
            )}
            <p className={`text-[14px] truncate text-center`} style={{ color: isActive ? iconColor : '#666' }}>{title}</p>
        </div>
    )
}

IconButtonTitle.defaultProps = {
    sx: {},
    onClick: () => { },
}

export default IconButtonTitle;
